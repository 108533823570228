import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import commonImages from "../images/common";
import images from "../images/footer";
import {
  Typography,
  Link,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import CustomTooltip from "./CustomTooltip";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    // height: "215px", // 215px -> 23.5vh
    borderTop: "1px solid #E0E0E0",
    padding: "25px 80px", // 25px-> 2.7vh
    boxSizing: "content-box",
    [theme.breakpoints.down('sm')]: {
      padding: "5px 18px"
    }
  },
  footerRoot: {
    maxWidth: "1280px", // 1440 - (padding of 80*2)
    width: "100%",
    margin: "auto",
  },
  alDetailsDiv: {
    borderBottom: "1px solid #8C8C8C",
    paddingBottom: "69px",
    display: "flex",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    }
  }, // al- after login.
  alLogo: {
    width: "196px",
    height: "56px !important",
  },
  alLocationContactDiv: {
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
    padding: "0 54px",
  },
  alLocationDiv: {
    display: "flex",
    justifyConten: "center",
    alignItems: "center",
    height: "21px",
    width: "160px",
  },
  alContactDiv: {
    display: "flex",
    justifyConten: "center",
    alignItems: "center",
    height: "21px",
    width: "160px",
  },
  alLocationContactText: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#717171",
    marginLeft: "6px",
    whiteSpace: "nowrap",
  },
  alLinksDiv: {
    display: "flex",
    columnGap: "54px",
  },
  alLinkSet: {
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  alLink: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#717171 !important",
    whiteSpace: "nowrap",
    cursor: "pointer",
    textDecoration: "none",
  },
  alnewsLetterDiv: {
    marginLeft: "155px",
    border: "1px solid #E0E0E0",
    padding: "12px 0px",
    maxWidth: "401px",
    width: "100%",
    [theme.breakpoints.down("1400")]: {
      marginLeft: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      padding: "12px 0px",
      width: "100%",
      padding: "12px 0px",
    },
  },
  alNewsLetterHeading: {
    fontSize: "10px",
  },
  alNewsLetterBody: {
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: "16px",
  },
  alEmailFormControl: {
    width: "100%",
  },
  alEmailInput: {
    maxWidth: "369px",
    width: "100%",
    height: "40px",
    backgroundColor: "#F7F7F7",
    borderRadius: "0",
    "& .MuiInputBase-input": {
      padding: "9px 12px",
      fontSize: "14px",
      [theme.breakpoints.down("1250")]: {
        fontSize: "10px",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0",
    },
  },
  alOtherInfoDiv: {
    display: "flex",
    justifyContent: "space-between",
    color: "#8C8C8C",
  },
  otherLinksDiv: {
    fontSize: "14px",
    display: "flex",
    columnGap: "33px",
  },
  otherLink: {
    cursor: "pointer",
  },
  newsLetterButton: {
    "&:disabled": {
      backgroundColor: `${theme.palette.secondary.light} !important`,
      borderRadius: 0,
    },
  },
}));
const Footer = () => {
  const classes = useStyles();
  const history = useHistory();
  const pathName = window?.location?.pathname;
  const view = pathName.split("/")?.[1];
  const date = new Date();
  const year = date.getFullYear();

  const DetailsDiv = () => {
    const LocationContactDiv = () => {
      return (
        <div className={classes.alLocationContactDiv}>
          <div className={classes.alLocationDiv}>
            <img src={commonImages.location_icon} alt="location-icon" />
            <Typography
              variant="caption"
              className={classes.alLocationContactText}
            >
              Based in the USA
            </Typography>
          </div>
          <div className={classes.alContactDiv}>
            <img src={commonImages.mail_icon} alt="location-icon" />
            <Typography
              variant="caption"
              className={classes.alLocationContactText}
              component="a"
              href="mailto:info@findpros.ai"
            >
              info@findpros.ai
            </Typography>
          </div>
        </div>
      );
    };
    const LinksDiv = () => {
      const linkSets = [
        [
          {
            text: "Home",
            url: "https://findpros.ai",
            newTab: true,
          },
          {
            text: "How We Work",
            url: "https://findpros.ai",
            newTab: true,
          },

          {
            text: "Features",
            url: "https://findpros.ai/features",
            newTab: true,
          },
        ],
        [
          {
            text: "Contact Us",
            url: "https://findpros.ai/contact-us",
            newTab: true,
          },
          // {
          //   text: "Blog",
          //   url: "",
          // },
          // {
          //   text: "Careers at FindPro",
          //   url: "",
          // },
        ],
      ];
      return (
        <div className={classes.alLinksDiv}>
          {linkSets.map((linkSet, idxLinkSet) => (
            <div key={idxLinkSet} className={classes.alLinkSet}>
              {linkSet.map((linkInfo, idxLinkInfo) => (
                <Link
                  key={idxLinkInfo}
                  href={linkInfo.url}
                  target={linkInfo?.newTab ? "_blank" : "_self"}
                  className={classes.alLink}
                >
                  {linkInfo.text}
                </Link>
              ))}
            </div>
          ))}
        </div>
      );
    };

    const NewsLetterDiv = () => {
      return (
        <CustomTooltip
          title={"This feature will be available in future release."}
        >
          <div className={classes.alnewsLetterDiv}>
            <Typography className={classes.alNewsLetterHeading}>
              BE THE FIRST TO KNOW
            </Typography>
            <Typography className={classes.alNewsLetterBody}>
              Sign up to our newsletter
            </Typography>
            <FormControl
              variant="outlined"
              className={classes.alEmailFormControl}
            >
              <OutlinedInput
                id="outlined-adornment-password"
                className={classes.alEmailInput}
                disabled={true}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      //   onClick={handleClickShowPassword}
                      //   onMouseDown={handleMouseDownPassword}
                      edge="end"
                      disabled={true}
                      className={classes.newsLetterButton}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="eg:yourmail@mail.com"
              />
            </FormControl>
          </div>
        </CustomTooltip>
      );
    };

    return (
      <div className={classes.alDetailsDiv}>
        <img
          src={commonImages.logo_v2}
          alt="Findpro logo"
          className={classes.alLogo}
          onClick={() => {
            history.push(`/${view}/dashboard`);
          }}
        />
        {/* </div> */}
        <LocationContactDiv />
        <LinksDiv />

        <NewsLetterDiv />
      </div>
    );
  };

  const OtherInfoDiv = () => {
    return (
      <div className={classes.alOtherInfoDiv}>
        <Typography variant="caption">
          Copyright {year} findpros.ai. All Rights Reserved.
        </Typography>
        <div className={classes.otherLinksDiv}>
          <Link
            className={classes.otherLink}
            href="https://findpros.ai/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </Link>
          <Link
            className={classes.otherLink}
            href="https://findpros.ai/terms-conditions"
            target="_blank"
          >
            Terms of Use
          </Link>
        </div>
      </div>
    );
  };
  return (
    <div className={classes.footerContainer}>
      <div className={classes.footerRoot}>
        <DetailsDiv />
        <OtherInfoDiv />
      </div>
    </div>
  );
};

Footer.propTypes = {};

export default Footer;
