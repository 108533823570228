import "@adyen/adyen-web/dist/adyen.css";
import { Close } from "@mui/icons-material";
import { CircularProgress, Container, Fab, Zoom } from "@mui/material";
import FooterComponent from "app/layout/footer";
import HeaderComponent from "app/layout/header";
import { useSnackbar } from "notistack";
import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getAccountType } from "../login/login.service";
import storageUtil from "../utils/storageUtil";
import { postSubscribePayment } from "./subscribe.service.component";
import IncompleteProfileAlertBox from "../components/IncompleteProfileAlertBox";
import TitleCard from "app/layout/title-card";

const CheckoutComponent = React.lazy(() =>
  import("app/payment/CheckoutComponent")
);
const PayPalBtn = React.lazy(() => import("./paypalSubscriptionComponent"));

const SubscribePaymentPage = (props) => {
  const { amount, planId, autoRenew, _id } = props.location.state;
  const [showLoader, setShowLoader] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isProfileComplete = useSelector(
    (state) => state?.login_info?.value?.isProfileComplete
  );

  useEffect(() => {
    if (localStorage["reload-page"] === "true") {
      localStorage.setItem("reload-page", null);
      window.location.reload();
    }
  }, []);

  const onAuthorised = (response) => {
    let payload = {
      planId: _id,
      transactionId: response?.reference,
    };

    if (autoRenew) {
      payload = {
        ...payload,
        transactionId: response.orderID,
        subscriptionId: response.subscriptionID,
        autoPay: true,
        billingCycle: amount === 99 ? "monthly" : "quarterly",
      };
    }

    setShowLoader(true);

    postSubscribePayment(payload)
      .then((res) => {
        if (res) {
          enqueueSnackbar("Payment successful", {
            variant: "success",
          });
          const user = storageUtil.getItem("user");
          user.isSubscribed = true;
          storageUtil.setItem("user", user);
          // if(getAccountType() === "employers") {
          //   history.push("/myaccount/subscription-details");
          // }
          // else history.push("/");
          history.push("/");
        } else {
          enqueueSnackbar("Payment delayed/failed. Contact admin!", {
            variant: "warning",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("Problem in subscribing to plan. Contact admin!", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          TransitionComponent: Zoom,
          persist: true,
          action: (key) => {
            <Fab
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              <Close />
            </Fab>;
          },
        });
        history.push("/");
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const onPaymentError = (error) => {
    enqueueSnackbar(error.message, {
      variant: "error",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      TransitionComponent: Zoom,
      persist: true,
      action: (key) => {
        <Fab
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <Close />
        </Fab>;
      },
    });
    history.push("/subscribe");
  };

  // paypal-subscription changes
  const paypalSubscribe = (data, actions) => {
    return actions.subscription.create({
      plan_id: planId,
      auto_renewal: autoRenew,
    });
  };
  const paypalOnError = (err) => {
    enqueueSnackbar("Problem in subscribing to plan. Contact admin!", {
      variant: "error",
    });
    // console.log("Error");
  };
  const paypalOnApprove = (data, detail) => {
    // call the backend api to store transaction details
    // console.log("Payapl approved");
    //// console.log(data);
    onAuthorised(data);
  };

  return (
    localStorage["reload-page"] !== "true" && (
      <div>
        <HeaderComponent menuList={props.menuList || []} />
        {isProfileComplete === false && <IncompleteProfileAlertBox />}
        <TitleCard autoRenew={autoRenew} />

        <Container
          className="text-center mt-5"
          style={{
            minHeight: "54vh",
          }}
        >
          {!showLoader ? (
            <Suspense fallback="">
              {!autoRenew && (
                <CheckoutComponent
                  checkoutTitle={"Subscription Payment"}
                  amount={amount}
                  onAuthorised={onAuthorised}
                  onPaymentError={onPaymentError}
                />
              )}
              {autoRenew && (
                <PayPalBtn
                  amount={amount}
                  currency="USD"
                  createSubscription={paypalSubscribe}
                  onApprove={paypalOnApprove}
                  catchError={paypalOnError}
                  onError={paypalOnError}
                  onCancel={paypalOnError}
                />
              )}
            </Suspense>
          ) : (
            <CircularProgress
              sx={{
                display: "flex",
                margin: "auto",
                height: "100%",
                minHeight: "68vh",
                marginTop: "-6rem",
              }}
            />
          )}
        </Container>
        <FooterComponent />
      </div>
    )
  );
};

export default SubscribePaymentPage;
