import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { ShareSVG } from "app/v2/Pages/SVGs/Share";
import JobStatus from "app/v2/Pages/common/Status/Job";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getContractTypeLevel,
  getExperience,
} from "app/v2/Pages/common/getIdTypeLevel";
import {
  fetchContractTypesThunk,
  fetchExperiencesThunk,
} from "app/v2/store/features/misc/miscThunk";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { bookmarkApiForJobCard } from "../../../agencyApiServices/agency.services";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    maxWidth: "317px",
    width: "100%",
    "&:hover": {
      boxShadow: "0px 4px 36px rgba(0, 0, 0, 0.12)",
    },
  },
  skillsChipsDiv: {
    overflowX: "auto",
    width: "269px",
    paddingBottom: "10px",
  },

  hideActions: {
    display: "none",
  },
  sectionHeadingText: {
    fontSize: "10px",
    // color: theme.palette.secondary.light,
    fontfamily: "Plus Jakarta Sans",
    fontWeight: 600,
    color: "#717171",
    whiteSpace: "nowrap",
  },
  orgImage: {
    width: "100px",
    height: "100px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
}));

const FeatureJobGridCard = ({ data, jobID }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  // const cardData = {
  //   image: commonImages.user_profile,
  //   name: "Lacy Clark",
  //   role: "Sr. Product Owner",
  //   matchRate: "86%",
  //   availability: "Part Time",
  //   level: "Expert",
  //   location: "Singapore",
  //   bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
  //   skills: ["React", "NodeJS", "AngularJS"],
  // };
  const [salaryType, setSalaryType] = useState();
  const [salaryValue, setSalaryValue] = useState();
  const { experiences, contractTypes } = useSelector((state) => state.misc);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const cardData = {
    image: data?.organizationDetails?.profilePhoto,
    name: data?.jobTitle,
    role: data?.jobNo,
    createdAtReceived: data?.createdAt
      ? moment(data?.createdAt).fromNow()
      : "-",
    openPositions: data?.openPositions,
    matchRate: data.openPositions,
    availability: "Part Time",
    workType: data?.workType,
    salaryType: salaryType,
    salaryValue: salaryValue,
    location: `${data?.country || "-"}`,
    bio: data?.organizationDetails?.companyDesc?.text,
    skills: data?.skillsRequired?.map((skill) => skill?.skill),
  };
  // const cardData = data;
  const [isHovered, setIsHovered] = useState(false);

  function salaryRate(data) {
    const salaryDetails = data?.salary;
    const salaryTypeReceived = salaryDetails?.type;
    const salaryRangeReceived = salaryDetails?.range;
    const salaryValueReceived = salaryRangeReceived
      ? `Min- $ ${salaryRangeReceived?.min ?? "-"}, Max - $ ${
          salaryRangeReceived?.max ?? "-"
        }`
      : `$ ${salaryDetails?.fixedRate ?? "-"}`;
    setSalaryType(salaryTypeReceived);
    setSalaryValue(salaryValueReceived);
  }
  const agencyId = localStorage.getItem("orgId");

  const handleBookMarkClick = () => {
    const request = {
      jobId: data?._id,
      agencyId: agencyId,
    };

    bookmarkApiForJobCard(request)
      .then((res) => {
        if (res) {
          setIsBookMarkClicked((prev) => !prev);

          const key = enqueueSnackbar(`Successfull`, {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        }
      })
      .catch((error) => {
        const key = enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
      });
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [isBookMarkClicked, setIsBookMarkClicked] = useState(false);

  useEffect(() => {
    salaryRate(data);
  }, []);
  useEffect(() => {
    setIsBookMarkClicked(data?.isFavorite);
  }, []);

  useEffect(() => {
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
  }, [dispatch]);

  return (
    <div className="h-[540px] flex items-center">
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={clsx([
          classes.cardContainer,
          " flex flex-col p-6 items-start justify-center gap-[19px] text-left text-3xs text-neutral-500 font-button-text-button-10 border-[1px] border-solid border-neutral-50",
        ])}
      >
        <div className="self-stretch flex flex-row items-start justify-start gap-[19px] text-lg text-black h-fit">
          <img
            className={classes.orgImage}
            alt={`org_${cardData?._id}_image`}
            src={cardData?.image ? cardData.image : "/org_placeholder.svg"}
          />
          <div className="flex flex-col items-start justify-start gap-[2px]">
            <div className="relative leading-[130%] font-semibold inline-block w-[150px] ">
              {cardData?.name}
              {/* Developer Needed */}
            </div>
            <div className="relative text-xs font-poppins text-neutral-500 inline-block w-[150px]">
              JOB NO: {cardData?.role}
              {/* Sr.Product Owner */}
            </div>
            <div className="relative text-xs font-poppins text-neutral-500 inline-block w-[150px]">
              Posted {cardData?.createdAtReceived}
              {/* Sr.Product Owner */}
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[8px]">
          <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-[6px] text-neutral-800">
            {/* <div className="text-gray-700 text-xs font-jakarta-sans font-normal leading-5 mt-[-5px]">
              Active 3 hours Ago
            </div> */}
            {/* <div
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              AVAILABILITY
            </div> */}
            {/* <div className="relative text-sm leading-[130%] font-semibold text-neutral-900 text-center">
              {cardData?.availability}
            </div> */}
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-between text-center">
        {/* <div className="flex-1 flex flex-col items-start justify-center gap-[6px]">
            <b
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              {cardData?.salaryType}{" "}
            </b>
            <div className="relative text-sm leading-[130%] font-semibold text-neutral-900">
              {cardData?.salaryValue}
            </div>
          </div> */}
          <div className="flex-1 flex flex-col items-start justify-center gap-[6px]">
            <b
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              OPEN POSITIONS
            </b>
            <div className="relative text-sm leading-[130%]  font-semibold text-neutral-900">
              {cardData?.openPositions}
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-[6px] text-neutral-800 mt-[-8px]">
            <div className="relative uppercase font-semibold inline-block w-[53px]"></div>
            <b
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              CONTRACT TYPE
            </b>
            <div className="relative text-sm leading-[130%] font-semibold text-neutral-900">
              {getContractTypeLevel(data?.contractType, contractTypes)}
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[8px] text-center">
          {/* <div className="flex-1 flex flex-col items-start justify-center gap-[6px]">
            <b
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              Experience Level
            </b>
            <div className="relative text-sm leading-[130%] font-semibold text-neutral-900">
              {getExperience(data?.experienceLevelId, experiences)}
            </div>
          </div> */}
          <div className="flex-1 flex flex-col items-start justify-center gap-[6px]">
            <b
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              Work Type
            </b>
            <div className="relative text-sm leading-[130%] font-semibold text-neutral-900">
              {cardData?.workType}
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-[6px] text-neutral-800 mt-[-8px]">
            <div className="relative uppercase font-semibold inline-block w-[53px]"></div>
            <b
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              Location
            </b>
            <div className="relative text-sm leading-[130%] font-semibold text-neutral-900">
              {cardData?.location}
            </div>
          </div>
        </div>
        <div className="self-stretch relative text-xs leading-[136%] font-poppins [display:-webkit-inline-box] overflow-auto text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical]">
          {cardData?.bio}
        </div>

        <div
          className={clsx([
            "self-stretch flex flex-row items-start justify-start gap-[19px]",
            !isHovered && classes.hideActions,
          ])}
        >
          <button
            onClick={() => {
              history.push({
                pathname: `/agency/job-detail/${data?._id}`,
                state: {
                  jobDetails: data,
                },
              });
            }}
            className="cursor-pointer [border:none] py-2.5 pr-[15px] pl-5 bg-neutral-900 flex-1 h-12 flex flex-row box-border items-center justify-center gap-[10px]"
          >
            <div className="relative text-sm font-semibold font-button-text-button-10 text-neutral-white text-center whitespace-nowrap">
              View
            </div>
            <img
              className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
              alt=""
              src="/iconlightup-right.svg"
            />
          </button>
          <button
            className={`cursor-pointer ${
              isBookMarkClicked
                ? "bg-neutral-900 border-none h-10"
                : "border-[1px] border-solid border-neutral-50"
            } p-2.5 flex flex-row box-border items-center justify-center`}
            onClick={handleBookMarkClick}
          >
            <img
              className="relative w-5 h-5 overflow-hidden shrink-0"
              alt=""
              src={
                isBookMarkClicked
                  ? "/iconlightbookmark.svg"
                  : "/icondarkbookmark.svg"
              }
            />
          </button>
        </div>
        <JobStatus type={data?.status} />
      </div>
    </div>
  );
};

FeatureJobGridCard.propTypes = {};

export default FeatureJobGridCard;
