import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";

import { Typography } from "@mui/material";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomLabel from "app/v2/components/filters/CustomLabel";
import CustomTooltip from "app/v2/components/CustomTooltip";
import SectionTitleAndIcon from "./SectionTitleAndIcon";
import SalaryPicker from "../../../common/SalaryPicker";
import LeftRightSelect from "app/v2/components/filters/LeftRightSelect";
import LocationSelector from "app/v2/components/filters/LocationSelector";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

import { messages } from "app/v2/Pages/Utils/constants";

const useStyles = makeStyles((theme) => ({
  sectionSubHeading: {
    fontSize: "18px",
    fontWeight: 600,
    marginBottom: "16px",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  uploadOrAddButton: {
    columnGap: "8px",
    border: "1px solid #EDEDED",
    padding: "6px 8px",
    fontSize: "14px",
    fontWeight: 600,
  },
  summary: {
    "& .MuiInputBase-inputMultiline": {
      fontSize: "14px !important",
      color: "#717171 !important",
    },
  },
  userImage: {
    width: "120px",
    height: "120px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
  infoIcon: {
    width: "20px",
    height: "20px",
    position: "relative",
    left: "4px",
    top: "1px",
  },
}));

const Overview = ({
  overviewData,
  socialLinks,
  experiences,
  contractTypes,
  onOverviewEntryChange,
  onSocialLinksEntryChange,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const uploadImageRef = React.createRef();

  const [experienceTypes, setExperienceTypes] = useState([]);
  const [contractLevels, setContractLevels] = useState([]);

  const onProfilePhotoUpload = (event) => {
    const file = event?.target?.files?.[0];

    if (file) {
      const localFileUrl = URL.createObjectURL(file);
      const higherFileSize = file.size > 1048576 * 1; // 1 MB
      const isAnImage = file.type.startsWith("image/");
      // console.log({ higherFileSize, isAnImage });
      // First we check if the uploaded element is actually an image and does not exceed the expected file size.
      if (higherFileSize || !isAnImage) {
        // enqueueSnackbar(
        // higherFileSize
        //   ? messages.EXPECTED_IMG_FILE_SIZE_WARNING
        //   : messages.UPLOAD_VALID_IMG_WARNING,
        // {
        //   variant: "error",
        // }
        // );
        const key = enqueueSnackbar(
          higherFileSize
            ? messages.EXPECTED_IMG_FILE_SIZE_WARNING
            : messages.UPLOAD_VALID_IMG_WARNING,
          {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
        return;
      }

      // Next we check if the image meets the expected dimensions,the logic below is specifically for images hence we had to add the isAnImage check above first.
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          const width = this.width;
          const height = this.height;
          // console.log({ width, height });

          // Check if the image meets your criteria
          if (width >= 200 || height >= 200) {
            onOverviewEntryChange("displayPicture", {
              file,
              localFileUrl,
              prevFileUrl: overviewData?.displayPicture,
            });
          } else {
            // enqueueSnackbar(messages.IMG_UPLOAD_INCORRECT_RESOLUTION, {
            //   variant: "error",
            // });
            const key = enqueueSnackbar(
              messages.IMG_UPLOAD_INCORRECT_RESOLUTION,
              {
                variant: "error",
                autoHideDuration: 3000,
                action: (key) => (
                  <CloseIcon
                    onClick={() => closeSnackbar(key)}
                    style={{
                      cursor: "pointer",
                      fontSize: "15",
                      marginTop: "-1px",
                    }}
                  />
                ),
              }
            );
          }
        };
        // The onload event handler for the img element is not explicitly called. It will be automatically triggered when the image has finished loading(i.e, when the src attribute is set).
      };
      reader.readAsDataURL(file);
      // This method instructs the FileReader to read the contents of the specified File object (file) as a data URL.
      // When the reading operation is complete, the onload event handler (defined as reader.onload = function(e) { ... }) will be triggered, allowing you to access and work with the data URL.
    }
  };

  const onSalaryChange = (salaryDetailsReceived) => {
    onOverviewEntryChange("salaryDetails", salaryDetailsReceived);
  };

  const getArrayBasedIndex = (data, entryIDOrArrayIndex) => {
    const indexOrID = Number(entryIDOrArrayIndex);
    const isArrayIndex = indexOrID >= 0 && indexOrID < data?.length;
    if (isArrayIndex) return indexOrID;
    else {
      const arrayIndex = data?.findIndex(
        (entry) => entry?._id === entryIDOrArrayIndex
      );

      return arrayIndex !== -1 ? arrayIndex : null;
    }
  };

  const getEntryID = (data, arrayIndex) => {
    return data?.[arrayIndex]?._id;
  };

  const onExperienceChange = (id, value) => {
    // const experienceID = getEntryID(experiences, value); // add this instead once the change is done from update settings api end as well(to receive this as a string).
    onOverviewEntryChange(id, value);
  };

  const onContractChange = (id, value) => {
    // const contractTypeID = getEntryID(contractTypes, value); // add this instead once the change is done from update settings api end as well(to receive this as a string).
    onOverviewEntryChange(id, value);
  };

  useEffect(() => {
    const experienceData = experiences?.map(
      (expEntry) =>
        `${expEntry?.level} (${expEntry?.minExp} - ${expEntry?.maxExp} years)`
    );

    setExperienceTypes(experienceData);
  }, [experiences]);

  useEffect(() => {
    const contractData = contractTypes?.map(
      (contractEntry) => contractEntry?.level
    );
    setContractLevels(contractData);
  }, [contractTypes]);

  return (
    <div className="py-[36px] border-b-[1px] border-solid border-[#EDEDED] ">
      <SectionTitleAndIcon title="Consultant Overview" iconPath="/file.svg" />
      <div className="p-[16px] flex flex-col gap-y-[36px] sm:p-[0px]">
        <div className="flex gap-x-[24px]">
          <div className="flex-1">
            <CustomTextfield
              // id="firstName"
              id="first_name"
              label="First Name"
              placeholder="Kyla"
              // value={overviewData?.firstName}
              value={overviewData?.first_name}
              onChange={onOverviewEntryChange}
              showMandatorySymbol={true}
              
            />
          </div>
          <div className="flex-1">
            <CustomTextfield
              // id="lastName"
              id="last_name"
              label="Last Name"
              placeholder="Andrews"
              // value={overviewData?.lastName}
              value={overviewData?.last_name}
              onChange={onOverviewEntryChange}
              showMandatorySymbol={true}
            />
          </div>
        </div>
        <div>
          <CustomLabel label="profile photo *" />
          <CustomTooltip
            title={
              <>
                <div>1. {messages.IMG_UPLOAD_RESOLUTION_INFO}</div>
                <div>2. {messages.IMG_UPLOAD_EXTENSION_INFO}</div>
              </>
            }
            arrow
            placement="top"
          >
            <InfoIcon className={classes.infoIcon} />
          </CustomTooltip>

          <div className="flex flex-row items-center justify-start gap-x-[36px]">
            <img
              className={classes.userImage}
              alt={`org_${overviewData?._id}_image`}
              src={
                overviewData?.displayPicture?.localFileUrl
                  ? overviewData?.displayPicture?.localFileUrl
                  : overviewData?.displayPicture
                  ? overviewData?.displayPicture
                  : "/user_placeholder.svg"
              }
            />

            <>
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={onProfilePhotoUpload}
                ref={uploadImageRef}
              />
              <button
                className={clsx([classes.button, classes.uploadOrAddButton])}
                onClick={() => uploadImageRef.current.click()}
              >
                <div>Upload new</div>
                <FileUploadOutlinedIcon fontSize="small" />
              </button>
            </>
          </div>
        </div>
        <CustomTextfield
          id="jobTitle"
          label="JOB TITLE"
          placeholder="Project Manager "
          value={overviewData?.jobTitle}
          onChange={onOverviewEntryChange}
          showMandatorySymbol={true}
        />
        <div>
          <Typography className={classes.sectionSubHeading}>
            Expected Salary *
          </Typography>
          <div className="p-[16px] ">
            <SalaryPicker
              heading="Select salary type"
              data={overviewData?.salaryDetails}
              setData={onSalaryChange}
            />
          </div>
        </div>
        <div className="flex gap-x-[24px]">
          <div className="flex-1">
            <Typography className={classes.sectionSubHeading}>
              Work Experience *
            </Typography>
            <div className="p-[16px]">
              <LeftRightSelect
                id="experienceLevel"
                data={experienceTypes}
                selectedIndex={
                  Boolean(overviewData?.experienceLevel) ||
                  overviewData?.experienceLevel === 0
                    ? getArrayBasedIndex(
                        experienceTypes,
                        overviewData?.experienceLevel
                      )
                    : null
                } // Passing null if the value does not exist to show a '-' in the component.
                onChange={onExperienceChange}
              />
            </div>
          </div>
          <div className="flex-1">
            <Typography className={classes.sectionSubHeading}>
              Availability *
            </Typography>
            <div className="p-[16px]">
              <LeftRightSelect
                id="availability"
                data={contractLevels}
                selectedIndex={
                  Boolean(overviewData?.availability) ||
                  overviewData?.availability === 0
                    ? getArrayBasedIndex(
                        contractTypes,
                        overviewData?.availability
                      )
                    : null
                } // Passing null if the value does not exist to show a '-' in the component.
                onChange={onContractChange}
              />
            </div>
          </div>
        </div>
        <div>
          <Typography className={classes.sectionSubHeading}>
            Location *
          </Typography>
          <div className="p-[16px]">
            <LocationSelector
              defaultCountry={overviewData?.country}
              defaultState={overviewData?.state}
              defaultCity={overviewData?.city}
              defaultTimezone={overviewData?.timezone}
              onChange={onOverviewEntryChange}
              showMandatorySymbol={true}
            />
          </div>
        </div>
        <div className="flex flex-col gap-x-[24px]">
          <Typography className={classes.sectionSubHeading}>
            Social Links
          </Typography>
          <div className="flex gap-y-[8px]">
            <div className="flex-1">
              <div className="p-[16px]">
                <CustomTextfield
                  id="linkedin"
                  label="Linkedin Profile URL"
                  placeholder="www.linkedin.com"
                  value={socialLinks?.linkedin}
                  onChange={onSocialLinksEntryChange}
                  showMandatorySymbol={false}
                  type="url"
                  noErrorMessage={true}
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="p-[16px]">
                <CustomTextfield
                  id="facebook"
                  label="Facebook Profile URL"
                  placeholder="www.facebook.com"
                  value={socialLinks?.facebook}
                  onChange={onSocialLinksEntryChange}
                  showMandatorySymbol={false}
                  type="url"
                  noErrorMessage={true}

                />
              </div>
            </div>
            <div className="flex-1">
              <div className="p-[16px]">
                <CustomTextfield
                  id="website"
                  label="Website"
                  value={socialLinks?.website}
                  onChange={onSocialLinksEntryChange}
                  showMandatorySymbol={false}
                  type="url"
                  noErrorMessage={true}

                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Typography className={classes.sectionSubHeading}>
            Summary to highlight your personality or work experience *
          </Typography>
          <div className="px-[16px]">
            <CustomTextfield
              id="summary"
              value={overviewData?.summary}
              customClasses={{
                root: classes.summary,
              }}
              onChange={onOverviewEntryChange}
              multiline={true}
              minRows={8}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Overview.propTypes = {};

export default Overview;
