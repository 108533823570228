import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Alert } from "@mui/material";

import AnalyticCard from "./AnalyticCard";

import {
  isValidObject,
  camelCaseToTitleCase,
  isValidArray,
} from "../../Utils/utilFunctions";
import { messages } from "../../Utils/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "40px",
    padding: "40px 0",
  },
}));

const AnalyticsTabView = ({ tabTitle, data }) => {
  //// console.log({ data });
  const classes = useStyles();
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    if (isValidObject(data)) {
      const cardLabels = Object.keys(data);
      const cardData = [];
      cardLabels.forEach((label) => {
        const labelInTitleCase = camelCaseToTitleCase(label);
        const cardEntry = {
          title: `${labelInTitleCase} ${tabTitle ?? ""}`,
          data: data[label],
        };
        cardData.push(cardEntry);
      });
      setCardData(cardData);
    }
  }, [data]);
  return (
    <>
      {isValidArray(cardData) ? (
        <div className={classes.container}>
          {cardData.map((cardEntry) => (
            <AnalyticCard title={cardEntry?.title} data={cardEntry?.data} />
          ))}
        </div>
      ) : (
        <Alert severity="info"> {messages?.NO_DATA_FOUND} </Alert>
      )}
    </>
  );
};

AnalyticsTabView.propTypes = {};

export default AnalyticsTabView;
