import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { NoProfileImageSVG } from "app/v2/Pages/SVGs/NoProfile";
import { ShareSVG } from "app/v2/Pages/SVGs/Share";
import { favUnfavSelection } from "app/v2/services/client/common.service";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { isFunction } from "app/v2/Pages/Utils/utilFunctions";
import CloseIcon from "@mui/icons-material/Close";

import {
  fetchExperiencesThunk,
  fetchContractTypesThunk,
  fetchIndustryThunk,
  fetchSkillsThunk,
  fetchSkillLevelsThunk,
} from "app/v2/store/features/misc/miscThunk";
import { getSkillLevelName, getSkillName } from "app/v2/Pages/common/getIdTypeLevel";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    maxWidth: "317px",
    width: "100%",
    transition: "transform 0.3s", // Add a transition for smooth movement
    "&:hover": {
      transform: "translateY(-10px)", // Move the card up on hover
      boxShadow: "0px 4px 36px rgba(0, 0, 0, 0.12)",
    },
  },
  skillsChipsDiv: {
    overflowX: "auto",
    width: "269px",
    paddingBottom: "10px",
  },

  hideActions: {
    display: "none",
  },
  sectionHeadingText: {
    fontSize: "10px",
    color: theme.palette.secondary.light,
  },
  userImage: {
    width: "100px",
    height: "100px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
}));

const Consultant = ({ data  }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  console.log("cardDatea",data)
//   const cardData = {
//     image: commonImages.user_profile,
//     name: "Lacy Clark",
//     role: "Sr. Product Owner",
//     matchRate: "86%",
//     availability: "Part Time",
//     level: "Expert",
//     location: "Singapore",
//     bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
//     skills: ["React", "NodeJS", "AngularJS"],
//   };
  const { experiences, contractTypes, skillLevels, skills, industryData } =
    useSelector((state) => state.misc);
   

    const transformData = (data, contractTypes, experiences, skillLevels, skills) => ({
      _id: data?._id,
      image: data?.consultantDetails?.overview?.displayPicture,
      name: data?.consultantDetails?.overview?.first_name,
      role: data?.consultantDetails?.overview?.jobTitle,
      matchRate: data?.name ? data?.matchRate : "-",
      availability: contractTypes[data?.consultantDetails?.overview?.availability]?.level ?? data?.consultantDetails?.overview?.availability ?? "-",
      level: experiences[data?.consultantDetails?.overview?.experienceLevel]?.level ?? getSkillLevelName(data?.consultantDetails?.overview?.experienceLevel, skillLevels),
      location: data?.consultantDetails?.overview?.country,
      bio: data?.consultantDetails?.overview?.summary,
      skills: data?.consultantDetails?.skills?.map((skill) => getSkillName(skill?.skillID, skills)) ?? data?.skills,
    });

  
  const [cardData, setCardData] = useState(() => transformData(data, contractTypes, experiences, skillLevels, skills));


  

  console.log("cardData",cardData)

  // const cardData = data;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

//   const [isBookMarkClicked, setIsBookMarkClicked] = useState(false);

  // const handleBookMarkClick = () => {
  //   setIsBookMarkClicked(!isBookMarkClicked);
  // };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
//   const [isFavorite, setIsFavorite] = useState(false);
//   useEffect(() => {
//     setIsFavorite(data.isFavorite);
//   }, [data]);

//   const handlesavedData = () => {
//     if (isFunction(setUpdateApiOfConsultan))
//       setUpdateApiOfConsultan((prevState) => !prevState);
//   };
//   const handleBookMarkClick = async (consultantId, favouriteType) => {
//     try {
//       const requestData = {
//         favoriteId: consultantId,
//         favoriteModel: favouriteType,
//       };
//       const response = await favUnfavSelection(requestData);

//       if (response?.message) {
//         setIsFavorite(response?.data?.status);
//         // enqueueSnackbar(`Successfull`, { variant: "success" });

//         const key = enqueueSnackbar("Successfull", {
//           variant: "success",
//           autoHideDuration: 3000,
//           action: (key) => (
//             <CloseIcon
//               onClick={() => closeSnackbar(key)}
//               style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
//             />
//           ),
//         });
//         handlesavedData();
//       }
//     } catch (error) {
//       // enqueueSnackbar( error, { variant: "error" } );

//       const key = enqueueSnackbar("error ", {
//         variant: "error",
//         autoHideDuration: 3000,
//         action: (key) => (
//           <CloseIcon
//             onClick={() => closeSnackbar(key)}
//             style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
//           />
//         ),
//       });
//     }
//   };


useEffect(() => {
  setCardData(transformData(data, contractTypes, experiences, skillLevels, skills));
}, [data, contractTypes, experiences, skillLevels, skills]);

useEffect(() => {
  if (!experiences?.length) dispatch(fetchExperiencesThunk());
  if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
  if (!skillLevels?.length) dispatch(fetchSkillsThunk());
  if (!skills?.length) dispatch(fetchSkillLevelsThunk());
}, [dispatch]);

  return (
    <div className=" h-[440px] flex items-center ml-[10px] mb-[10px]">
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={clsx([
          classes.cardContainer,
          " flex flex-col p-6 items-start justify-center gap-[19px] text-left text-3xs text-neutral-500 font-button-text-button-10 border-[1px] border-solid border-neutral-50",
        ])}
      >
        <div className="self-stretch flex flex-row items-start justify-start gap-[19px] text-lg text-black h-fit">
          <img
            className={classes.userImage}
            alt={`"user_${cardData?._id}_image"`}
            src={cardData?.image ? cardData.image : "/user_placeholder.svg"}
          />
          <div className="flex flex-col items-start justify-start gap-[2px]">
            <div className="relative leading-[130%] font-semibold inline-block w-[150px]">
              {cardData?.name}
            </div>
            <div className="relative text-xs font-poppins text-neutral-500 inline-block w-[150px]">
              {cardData?.role}
            </div>
          </div>
        </div>
        <div className="self-stretch h-[50px] flex flex-row items-start justify-start gap-[8px]">
          {/* <div className="flex-1 flex flex-col items-start justify-center gap-[6px]">
            <b
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              Match rate
            </b>
            <div className="relative text-sm leading-[130%] font-semibold text-neutral-900 text-center">
              {cardData?.matchRate}
            </div>
          </div> */}
          <div className=" flex-1 flex flex-col items-start justify-center gap-[6px]">
            <b
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              availability
            </b>
            <div className="relative text-sm leading-[130%] font-semibold text-neutral-900 text-start">
              {cardData?.availability}
            </div>
          </div>
        </div>
        <div className="self-stretch h-[50px] flex flex-row items-start justify-start gap-[8px]">
          <div className="flex-1 flex flex-col items-start justify-center gap-[6px]">
            <b
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              Level
            </b>
            <div className="relative text-sm leading-[130%] font-semibold text-neutral-900 text-center">
              {cardData?.level}
            </div>
          </div>
          <div className=" flex-1 flex flex-col items-start justify-center gap-[6px] ">
            <b
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              Location
            </b>
            <div className="relative text-sm leading-[130%] font-semibold text-neutral-900 text-start">
              {cardData?.location}{" "}
            </div>
          </div>
        </div>
        
        <div className=" h-[50px] w-full items-start justify-start relative text-xs leading-[136%] font-poppins [display:-webkit-inline-box] overflow-scroll   [-webkit-box-orient:vertical]">
          {cardData?.bio}
        </div>
        <div className="self-stretch flex flex-col items-start justify-center gap-[8px] text-center">
          <b
            className={clsx([classes.sectionHeadingText, "relative uppercase"])}
          >
            technology Skills
          </b>

          <div
            className={clsx([
              classes.skillsChipsDiv,
              "flex flex-row items-start justify-start gap-[8px] text-xs font-poppins",
            ])}
          >
            {cardData?.skills?.map((skillName) => (
              <div className="rounded-md flex flex-row py-1.5 px-2 items-start justify-start border-[1px] border-solid border-neutral-50">
                <div className="relative leading-[136%] font-medium">
                  {skillName}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className={clsx([
            "self-stretch flex flex-row items-start justify-start gap-[19px]",
            !isHovered && classes.hideActions,
          ])}
        >
          <button
            onClick={() => {
              
                history.push(`/admin/consultant-detail/${data._id}`);
                // history.push({
                //   pathname: `/admin/consultant-detail/${data._id}`,
                //   state: { cardData }
                // });
              
              // window.scrollTo(0, 0);
            }}
            className="cursor-pointer [border:none] py-2.5 pr-[15px] pl-5 bg-neutral-900 flex-1 h-10 flex flex-row box-border items-center justify-center gap-[10px]"
          >
            <div className="relative text-sm font-semibold font-button-text-button-10 text-neutral-white text-center whitespace-nowrap">
              View Profile{" "}
            </div>
            <img
              className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
              alt=""
              src="/iconlightup-right.svg"
            />
          </button>
          {/* <button
            className={`cursor-pointer ${
              // isBookMarkClicked
              isFavorite
                ? "bg-neutral-900 border-none h-10"
                : "border-[1px] border-solid border-neutral-50"
            } p-2.5 flex flex-row box-border items-center justify-center`}
            // onClick={handleBookMarkClick}
            onClick={() => handleBookMarkClick(data._id, "Consultants")}
          >
            <img
              className="relative w-5 h-5 overflow-hidden shrink-0"
              alt=""
              src={
                // isBookMarkClicked
                isFavorite ? "/iconlightbookmark.svg" : "/icondarkbookmark.svg"
              }
            />
          </button> */}
        </div>
      </div>
    </div>
  );
};

Consultant.propTypes = {};

export default Consultant;
