import React, { useEffect, useState } from "react";
// import Billing from "./Billing";
// import Invoice from "./Invoice";
import { useDispatch } from "react-redux";
import PlanDetails from "./PlanDetails";

const SubscriptionBody = () => {
  const dispatch = useDispatch();
  const [subscriptionData, setSubscriptionData] = useState({});

  // useEffect(() => {
  //   dispatch(getEmployerInfoThunk())
  //     .unwrap()
  //     .then((res) => {
  //       if (res) {
  //         setSubscriptionData(res?.data?.clientDetail?.orgDetails?.subscription);
  //       }
  //     });
  // }, []);
  return (
    <div className="flex flex-col gap-[36px] ">
      <PlanDetails />
      {/* <hr />
      <Billing />
      <hr />
      <Invoice /> */}
    </div>
  );
};

export default SubscriptionBody;
