import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RolePicker from "../../common/RolePicker";
import SkillsPicker from "../../common/SkillsPicker";
import WorkExperienceDetails from "../../common/WorkExperienceDetails";
import SalaryPicker from "../../common/SalaryPicker";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { isValidArray, isValidObject } from "../../Utils/utilFunctions";
import { defaultSkillList, messages } from "app/v2/Pages/Utils/constants";
import { saveConsultantQuestionnaire } from "app/v2/utils/onboardingUtils";
import configs from "../../Utils/configs";
import fetchWrapper from "../../Utils/fetchWrapper";

const useStyles = makeStyles((theme) => ({
  employeeDetailsContainer: {},
  rolePickerDiv: {},
  detailsDiv: {},
  title: {
    fontWeight: 600,
    fontSize: "36px",
    marginBottom: "48px",
  },
  description: {
    fontWeight: 700,
    fontSize: "14px",
    color: theme.palette.secondary.light,
    marginBottom: "48px",
  },
  jobTitle: {
    fontWeight: 600,
    fontSize: "12px",
    marginBottom: "12px",
  },
  rolePickerFormControlDiv: {
    width: "100%",
  },
  stepText: {
    fontWeight: 600,
    fontSize: "12px",
  },
  stepTitle: {
    fontWeight: 600,
    fontSize: "24px",
  },
  stepDescription: {
    fontWeight: 700,
    fontSize: "14px",
    color: theme.palette.secondary.light,
    marginTop: "10px",
    marginBottom: "24px",
  },
  accordion: {
    boxShadow: "none",
  },
  accordionTitle: {
    padding: "16px",
    marginTop: "24px",
  },
  accordionDetails: {
    paddingLeft: "12px",
    paddingRight: "12px",
  },
  actionDiv: {
    marginTop: "48px",
  },
  checkIcon: {
    color: theme.palette.success.main,
    position: "relative",
    bottom: "2px",
  },

  assesButtonLoader: {
    height: "35px",
    width: "15px",
  },
}));

const AgencyDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const initialRoles = [
    "UI Designer",
    "ReactJS Developer",
    "Nodejs Developer",
    "Tester",
  ];
  const [selectedRole, setSelectedRole] = useState();
  const [employeeData, setEmployeeData] = useState({
    roleSelected: "",
    skills: defaultSkillList,
    workExperience: "",
    contractType: "",
    location: { country: "", city: "", timezone: "" },
    salaryDetails: {}, // {type,fixedRate,range}
  });
  /* {
    roleSelected: "",
    skills: [],
    workExperience: "",
    contractType: "",
    location: { country: "", city: "",timezone:"" },
    salaryDetails: {
      type: "",
      fixedRate: "",
      range: "",
    },
  } */

  const setSalaryDetails = (salaryDetails) => {
    setEmployeeData((prevData) => ({
      ...prevData,
      salaryDetails,
    }));
  };

  const setSkillsData = (id, skills) => {
    setEmployeeData((prevData) => ({
      ...prevData,
      skills,
    }));
  };
  const [loading, setLoading] = useState(false);

  const consultantDetailsComponentsData = [
    {
      type: "skillPicker",
      title: "What skills do your employees have?",
      componentDesc: "Rate how well they should fare on each skill below.",
      component: (
        <SkillsPicker
          id="agencyOnboardingSP" // SP - Skills Picker
          data={employeeData?.skills}
          setData={setSkillsData}
        />
      ),
    },
    {
      type: "experienceDetails",
      title: "How much experience do your employees have?",
      component: <WorkExperienceDetails setData={setEmployeeData} />,
    },
    {
      type: "salaryPicker",
      title: "How much is your employees’ expected salary?",
      componentDesc:
        "How much would the salary be for this specific consultant?",
      component: <SalaryPicker setData={setSalaryDetails} />,
    },
  ];

  const noRoleSelected = () => selectedRole === null;
  const onRoleDetailsChange = (roleDetails) => {
    setSelectedRole(roleDetails?.roleSelection);
    setEmployeeData((prevEmployeeData) => ({
      ...prevEmployeeData,
      roleSelected: roleDetails?.roleSelection,
    }));
  };

  const onFinish = () => {
    // localStorage.setItem("questionnaireData", JSON.stringify(employeeData));
    // history.push("/agency/sign-up");
    setLoading(true);
    saveConsultantQuestionnaire(employeeData);

    const formattedEmployerData = {
      ...employeeData,
      selectedJobTitle: employeeData.roleSelected,
      experience: employeeData.workExperience,
      salary: employeeData.salaryDetails,
      skills: employeeData?.skills,
      onboardingType: { type: "employer", seekingFor: "consultant" },
    };
    setTimeout(async () => {
      try {
        const responseData = await fetchWrapper(
          `${configs.APIUrl}/misc/employer-onboarding`,
          {
            // will need to be refactored
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: formattedEmployerData,
          }
        );

        saveConsultantQuestionnaire({
          ...formattedEmployerData,
          consultantMatched: responseData.consultantMatched,
          onBoardingId: responseData.onBoardingId,
        });
        setLoading(false);
        history.push("/agency/sign-up");
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    }, 3000);
  };

  const areSkillsSelected = () => {
    const skillsData = employeeData?.skills;
    // const defaultSkills = skillsData?.defaultSkills;
    // const selectedCustomSkills = skillsData?.selectedCustomSkills;

    return isValidArray(skillsData);
  };

  const areExperienceDetailsSelected = () => {
    const location = employeeData?.location;
    const city = location?.city;
    const isWorkExperienceSelected = Boolean(employeeData?.workExperience);
    const isContractTypeSelected = Boolean(employeeData?.contractType);
    const isCountrySelected = Boolean(location?.country);
    const isCityEntered = Boolean(city) && city.length > 0;
    const isTimezoneSelected = Boolean(location?.timezone);
    return (
      // isWorkExperienceSelected &&
      // isContractTypeSelected &&
      isCountrySelected && isCityEntered && isTimezoneSelected
    );
  };

  const areSalaryDetailsEntered = () => {
    const salaryDetails = employeeData?.salaryDetails;
    const salaryRange = salaryDetails?.range;
    const fixedSalary = salaryDetails?.fixedRate;
    let isSalarySelected = isValidObject(salaryDetails);
    if (isSalarySelected) {
      const isValidFixedSalary = !isNaN(fixedSalary);
      const isValidSalaryRange =
        !isNaN(salaryRange?.min) &&
        !isNaN(salaryRange?.max) &&
        salaryRange?.min < salaryRange?.max;
      isSalarySelected &&= isValidFixedSalary || isValidSalaryRange;
    }
    return isSalarySelected;
  };

  const activateFinishButton = () => {
    const isRoleSelected = Boolean(employeeData?.roleSelected);
    return (
      isRoleSelected &&
      areSkillsSelected() &&
      areExperienceDetailsSelected() &&
      areSalaryDetailsEntered()
    );
  };

  const getCompletionStatusIcon = (componentType) => {
    let icon, status;
    switch (componentType) {
      case "skillPicker":
        status = areSkillsSelected();
        break;
      case "experienceDetails":
        status = areExperienceDetailsSelected();
        break;
      case "salaryPicker":
        status = areSalaryDetailsEntered();
        break;
      default:
      // default case
    }
    if (status) icon = <CheckCircleOutlineIcon className={classes.checkIcon} />;
    return icon;
  };

  React.useEffect(() => {
    // console.log({ employeeData });
  }, [employeeData]);

  return (
    <>
      <div className={classes.employeeDetailsContainer}>
        <Typography className={classes.title}>
          Start your job search.
        </Typography>
        <Typography className={classes.description}>
          Tell us about your skills and we’ll find you a match.
        </Typography>
        <div className={classes.rolePickerDiv}>
          <Typography className={classes.jobTitle}>JOB TITLE</Typography>
          <FormControl
            error={noRoleSelected()}
            className={classes.rolePickerFormControlDiv}
          >
            <RolePicker
              id="role-picker"
              rolesData={initialRoles}
              roleSelection={selectedRole}
              onChange={onRoleDetailsChange}
              error={noRoleSelected()}
            />
            {noRoleSelected() && (
              <FormHelperText>{messages.SELECT_ROLE}</FormHelperText>
            )}
          </FormControl>
        </div>
        <div className={classes.detailsDiv}>
          {consultantDetailsComponentsData.map((componentData, index) => (
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordionTitle}
              >
                <div>
                  <Typography className={classes.stepText}>
                    STEP {index + 1}
                  </Typography>
                  <Typography className={classes.stepTitle}>
                    {componentData?.title}{" "}
                    {getCompletionStatusIcon(componentData?.type)}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                {componentData?.componentDesc && (
                  <Typography className={classes.stepDescription}>
                    {componentData?.componentDesc}
                  </Typography>
                )}
                {componentData?.component}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
      <div className={classes.actionDiv}>
        <button
          onClick={() => {
            onFinish();
          }}
          disabled={!activateFinishButton()}
          className="cursor-pointer [border:none] py-3 px-7 bg-[transparent] [background:radial-gradient(50%_50%_at_50%_50%,_#1e93ff,_#1e5dff)] flex flex-row items-center justify-center gap-[10px]"
        >
          <div className="relative  font-semibold text-neutral-white ">
            Assess Matches
          </div>
          <img
            className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
            alt=""
            src="/iconlightarrow-right.svg"
          />
          <div className={classes.assesButtonLoader}>
            {loading && <CircularProgress />}
          </div>
        </button>
      </div>
    </>
  );
};

AgencyDetails.propTypes = {};

export default AgencyDetails;
