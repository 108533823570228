import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";

export const getEmployerInfo = async (clientID) => {
  return fetchWrapper(
    `${configs.APIUrl}/client/info${clientID ? `/${clientID}` : ``}`
  );
};

export const getEmployeeDetail = async (inviteeID) => {
  // // console.log(
  //   `${configs.APIUrl}/client/getInvitedEmployee${
  //     inviteeID ? `/${inviteeID}` : ``
  //   }`
  // );
  return fetchWrapper(
    `${configs.APIUrl}/client/getInvitedEmployee${
      inviteeID ? `/${inviteeID}` : ``
    }`
  );
};

export const updateClientOrganizationInfo = async (request) => {
  // return fetchWrapper(`${configs.APIUrl}/employers/update-info`, {
  return fetchWrapper(`${configs.APIUrl}/client/update-info`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const updateEmployeeInfo = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/updateEmployee`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const updateEmployerItAssessmentTokens = async (request) => {
  // return fetchWrapper(`${configs.APIUrl}/employers/update-it-aasessment-token`, {
  return fetchWrapper(`${configs.APIUrl}/client/update-it-aasessment-token`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const getItAssessmentTokens = async () => {
  // return fetchWrapper(`${configs.APIUrl}/employers/it-aasessment-tokens`);
  return fetchWrapper(`${configs.APIUrl}/client/it-aasessment-tokens`);
};

export const getEmployerSubsInfo = async (email) => {
  // return fetchWrapper(`${configs.APIUrl}/employers/subscriber-details`);
  return fetchWrapper(`${configs.APIUrl}/client/subscriber-details`);
};

export const updateHackerRankAPIKey = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/updateIntegrationConfigs`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: request, // {testConfigs:{hackerRankApiKey}}
  });
  // return fetchWrapper(`${configs.APIUrl}/client/updateIntegrationConfigs`, {
  //   method: "PUT",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: request, // {testConfigs:{hackerRankApiKey}}
  // });
};
