import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import LeftRightSelect from "app/v2/components/filters/LeftRightSelect";
import LocationSelector from "app/v2/components/filters/LocationSelector";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
// import fetchWrapper from "../Utils/fetchWrapper";
// import configs from "../Utils/configs";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchExperiencesThunk,
  fetchContractTypesThunk,
} from "app/v2/store/features/misc/miscThunk";

const useStyles = makeStyles((theme) => ({
  expContractDiv: {
    display: "flex",
    columnGap: "10px",
    marginBottom: "24px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    }
  },
  expDiv: { flex: "1 0 0" },
  selectTitle: {
    fontWeight: 700,
    fontSize: "18px",
    marginBottom: "24px",
  },
  contractDiv: { flex: "1 0 0" },
}));

// const experienceTypes = [
//   "Entry Level (1-2 years)",
//   "Junior (3-6 years)",
//   "Senior (7-14 years)",
//   "Expert (15+ years)",
// ];
// const contractTypes = [
//   "Part time",
//   "Full time",
//   "Project- Fixed term",
//   "Contractual",
// ];
const WorkExperienceDetails = ({ setData }) => {
  const dispatch = useDispatch();
  const { experiences, contractTypes } = useSelector((state) => state.misc);
  const experiencesData = useMemo(
    () =>
      experiences?.map(
        (expEntry) =>
          `${expEntry?.level} (${expEntry?.minExp} - ${expEntry?.maxExp} years)`
      ),
    [experiences]
  );
  const contractTypesData = useMemo(
    () => contractTypes?.map((contractEntry) => contractEntry?.level),
    [contractTypes]
  );

  useEffect(() => {
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
  }, [dispatch]);

  const classes = useStyles();
  // const [experienceTypes, setExperienceTypes] = useState([]);
  const [experienceTypes, setExperienceTypes] = useState(experiencesData);
  // const [contractTypes_, setContractTypes_] = useState([]);
  const [contractTypes_, setContractTypes_] = useState(contractTypesData);
  // const [selectedExp, setSelectedExp] = useState(0);
  // const [selectedExp, setSelectedExp] = useState(experiencesData?.[0]);
  const [selectedExp, setSelectedExp] = useState(1);
  // const [selectedContract, setSelectedContract] = useState(0);
  // const [selectedContract, setSelectedContract] = useState( contractTypesData?.[0] );
  const [selectedContract, setSelectedContract] = useState(1);

  const onLocationChange = (id, value) => {
    // id is defined within the LocationSelector component -> for country,city,timezone.
    setData((prevData) => ({
      ...prevData,
      location: {
        ...prevData?.location,
        [id]: value,
      },
    }));
  };

  const onExperienceChange = (id, value) => {
    setSelectedExp(value);
  };

  const onContractChange = (id, value) => {
    setSelectedContract(value);
  };

  useEffect(() => {
    // setSelectedContract(contractTypesData?.[0]);
    setSelectedContract(0);
    setContractTypes_(contractTypesData);
    // setContractTypes_(0); // initial state

    // setSelectedExp(experiencesData?.[0]);
    setSelectedExp(0); // initial state
    setExperienceTypes(experiencesData);
  }, [experiencesData, contractTypesData]);

  useEffect(() => {
    // setData((prevData) => ({
    //   ...prevData,
    //   workExperience: selectedExp,
    //   contractType: selectedContract,
    // }));
    setData((prevData) => ({
      ...prevData,
      workExperience: selectedExp,
      contractType: selectedContract,
    }));
    // console.log(
    //   "selectedExp ",
    //   selectedExp,
    //   " experiencesData ",
    //   experiencesData
    // );
  }, [selectedExp, selectedContract]);
  return (
    <div>
      <div className={classes.expContractDiv}>
        <div className={classes.expDiv}>
          <Typography className={classes.selectTitle}>
            Work Experience
          </Typography>
          <LeftRightSelect
            id="experienceType"
            data={experienceTypes}
            onChange={onExperienceChange}
          />
        </div>
        <div className={classes.contractDiv}>
          <Typography className={classes.selectTitle}>Contract Type</Typography>
          <LeftRightSelect
            id="contractType"
            data={contractTypes_}
            onChange={onContractChange}
          />
        </div>
      </div>
      <hr />
      <div>
        <Typography className={classes.selectTitle}>Location</Typography>
        <LocationSelector onChange={onLocationChange} />
      </div>
    </div>
  );
};

WorkExperienceDetails.propTypes = {};

export default WorkExperienceDetails;
