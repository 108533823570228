import React, { useEffect, useState } from "react";
import PlanDetails from "./PlanDetails";
import Billing from "./Billing";
import Invoice from "./Invoice";
import { getEmployerInfoThunk } from "app/v2/store/features/employer/profile-settings/profile-settings.thunk";
import { useDispatch } from "react-redux";

const SubscriptionBody = () => {
  const dispatch = useDispatch();
  const [subscriptionData, setSubscriptionData] = useState({});

  // useEffect(() => {
  //   dispatch(getEmployerInfoThunk())
  //     .unwrap()
  //     .then((res) => {
  //       if (res) {
  //         setSubscriptionData(res?.data?.clientDetail?.orgDetails?.subscription);
  //       }
  //     });
  // }, []);
  return (
    <div className="flex flex-col gap-[36px] ">
      <PlanDetails />
      {/* <hr />
      <Billing />
      <hr />
      <Invoice /> */}
    </div>
  );
};

export default SubscriptionBody;
