import React, { useState } from "react";
import PropTypes from "prop-types";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomDialog from "app/v2/components/CustomDialog";
import FilePreview from "app/v2/Pages/common/FilePreview";
import DocumentIcon from "app/v2/Pages/common/DocumentIcon";

const useStyles = makeStyles((theme) => ({}));

const PandaDocDetails = ({ data }) => {
  const [actionDialogOpen, setActionDialogOpen] = useState(false);

  // console.log("dataaaaaaa", data);
  const classes = useStyles();


  const getFileTypeFromUrl = (url) => {
    const fileName = url.split('/').pop(); // Extract file name from URL
    const fileExtension = fileName.split('.').pop().toLowerCase(); // Extract file extension
    return fileExtension;
  };

  // Determine file type based on URL
  const fileType = getFileTypeFromUrl(data?.file);

  return (
    <div className="self-stretch flex flex-row items-center justify-start gap-[36px] text-left text-base text-black font-body-text-regular-12 py-6">
      <div className="flex-1 flex flex-row items-center justify-start gap-[24px]">
      <DocumentIcon fileType={fileType} />

        <div className="flex-1 flex flex-col items-start justify-start">
          <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
            <button
              onClick={() => {
                setActionDialogOpen(true);
              }}
            >
              <div className="self-stretch relative [text-decoration:underline] leading-[130%] font-semibold inline-block overflow-hidden text-ellipsis whitespace-nowrap h-[21px] shrink-0">
                <p className="m-0">{data?.fileName??"-"}</p>
              </div>
            </button>
            {/* <div className="self-stretch relative text-xs leading-[120%] uppercase font-semibold text-neutral-900">
              {data?.docType ?? "-"}
            </div> */}
          </div>
          <div className="flex flex-row items-center justify-start gap-[12px] text-sm font-semibold text-neutral-500">
            <div className="relative leading-[180%]">
              Created Date : {data?.createdAt ? new Date(data?.createdAt).toLocaleDateString(): "-"}
            </div>
            <div className="relative leading-[180%]">
              Document No : {data?.documentNo ?? "-"}
            </div>
          </div>
        </div>
      </div>
      {data?.tags?.map((tag)=>
      <div className="flex flex-row items-start justify-start ">
        {/* <div className="flex flex-row p-2.5 items-center justify-center border-[1px] border-solid border-neutral-50">
          <button>
            {" "}
            <img
              className="relative w-[23.49px] h-5 overflow-hidden shrink-0"
              alt=""
              src="/logosdropbox.svg"
            />
          </button>
        </div> */}
        {/* <div className="flex flex-row p-2.5 items-center justify-center border-[1px] border-solid border-neutral-50">
          <button>
            <img
              className="relative w-5 h-[17.89px] overflow-hidden shrink-0"
              alt=""
              src="/logosgoogledrive.svg"
            />
          </button>
        </div> */}

        <div className="flex flex-row p-2 items-center text-sm font-semibold justify-center border-[2px] border-solid ">
          {/* <button> */}
            {/* <img
              className="relative w-5 h-5 overflow-hidden shrink-0"
              alt=""
              src="/logosmicrosoftonedrive.svg"
            /> */}
            {tag}
          {/* </button> */}
        </div>
      </div>
      )}
      <CustomDialog
        open={actionDialogOpen}
        setOpen={setActionDialogOpen}
        title={
          <div className="text-[16px] font-semibold text-center">
            {data?.fileName}
          </div>
        }
        content={<div className="h-[440px] w-[650px] p-2 "><FilePreview fileUrlOrContent={data?.file} /></div>}
      />
    </div>
  );
};

PandaDocDetails.propTypes = {};

export default PandaDocDetails;
