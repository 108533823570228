import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Geosuggest from "@ubilabs/react-geosuggest";
import {
  DndContext,
  DragOverlay,
  DragStartEvent,
  DragEndEvent,
  DragOverEvent,
  useSensors,
  useSensor,
  PointerSensor,
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { makeStyles, styled } from "@mui/styles";
import {
  CircularProgress,
  Typography,
  Alert,
  FormControl,
  FormHelperText,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Tooltip,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CallMadeOutlinedIcon from "@mui/icons-material/CallMadeOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ContentLoader from "react-content-loader";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

import Title from "../../common/Title";
import CustomButton from "app/v2/components/CustomButton";
import CustomAutocomplete from "app/v2/components/filters/CustomAutocomplete";
import KanbanColumn from "./KanbanColumn";
import UserCard from "./UserCard";
import CustomSelect from "app/v2/components/filters/CustomSelect";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomDialog from "app/v2/components/CustomDialog";
import CustomTooltip from "app/v2/components/CustomTooltip";
import CustomLabel from "app/v2/components/filters/CustomLabel";
import TwoLabelSwitch from "app/v2/components/filters/TwoLabelSwitch";
import CustomTimeRangePicker from "../../common/CustomTimeRangePicker";

import { dummyColumnsData } from "./data.dummy";
import TimezoneList from "app/markup/timezone.json";
import { messages } from "app/v2/Pages/Utils/constants";
import {
  isUndefined,
  isValidArray,
  isValidObject,
  toCamelCase,
  isValidString,
  isValidISODateFormat,
  isDateStringValidFormat,
  isTimeStringValidFormat,
} from "../../Utils/utilFunctions";

import {
  getHiringBoardOfJob,
  moveConsultant,
  moveConsultant2,
  getHackerRankTests,
  inviteConsultantForTest,
  updateConsultantTestInvite,
  cancelConsultantTestInvite,
  setHiringBoardStageData,
  scheduleMeeting,
  reScheduleMeeting,
  makeBid,
  activeConsultantsByAcceptingTheBid,
  getJobProposalDetails,
} from "app/v2/services/client/hiringBoard.service.js";
import { fetchExperiencesThunk } from "app/v2/store/features/misc/miscThunk";
import { getMyJobsThunk } from "app/v2/store/features/employer/jobFeed/jobFeed.thunk";
import { getJobDetail } from "app/v2/services/job/jobDetail.service";
import { getEmployerInfoThunk } from "app/v2/store/features/employer/profile-settings/profile-settings.thunk";
import { setLocale } from "yup";

import "./styles.scss";

dayjs.extend(utc);
dayjs.extend(timezone); // Extend dayjs with timezone plugin

const useStyles = makeStyles((theme) => ({
  hiringBoardContainer: {
    minHeight: "calc(100vh - 382px)", // total viewport height minus navbar and footer's heights, minHeight:100% did not work,hence had to set it as pre parent div defined in EmployerRoute.
  },
  board: {
    marginBottom: "87px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  containerLoading: {
    alignItems: "center", // adding this in above container, stretches its content and exceeds its width. So added this specifically for loading state.
  },
  backActionDiv: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    marginBottom: "10px",
    cursor: "pointer",
  },
  backText: {
    fontWeight: 600,
    fontSize: "14px",
    color: theme.palette.secondary.main,
  },
  backIcon: { color: theme.palette.secondary.main },
  jobActions: {
    display: "flex",
    columnGap: "18px",
    alignItems: "center",
  },
  jobDetailsButton: {
    fontWeight: 600,
    fontSize: "14px",
    whiteSpace: "nowrap",
    display: "flex",
    columnGap: "10px",
    alignItems: "center",
  },
  jobApplicantsDetails: {
    maxWidth: "389px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    columnGap: "36px",
    backgroundColor: "#F7F7F7",
  },
  jADetailType: {
    fontWeight: 700,
    fontSize: "10px",
    color: theme.palette.secondary.light,
    textTransform: "uppercase",
  }, // jA - job applicant
  jADetailValue: {
    fontWeight: 500,
    fontSize: "16px",
  }, // jA - job applicant
  aROrALButton: {
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    fontSize: "12px",
    width: "113px",
    // width: "100%",
    padding: "5px 12px",
  }, // aROrAL - Add Round or Activity log
  addRoundButton: {
    marginBottom: "6px",

    whiteSpace: "nowrap",
  },
  activityLogButton: {
    border: "1px solid #EDEDED",
  },
  hiringBoardBody: {
    margin: "12px 16px",
    padding: "20px",
    border: "1px solid #EDEDED",
  },
  kanbanView: {
    display: "flex",
    columnGap: "12px",
    overflowX: "auto",
    paddingBottom: "20px",
    scrollBehavior: "smooth",
  },
  yesOrNoButton: {
    fontSize: "12px !important",
    padding: "12px",
  },
  noButton: {
    border: "1px solid #EDEDED !important",
  },
  dateOrTimePicker: {
    width: "100% !important",
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        fontSize: "16px",
      },
    },
  },
  dateOrTimePickerError: {
    "& .MuiInputBase-root": {
      border: "1px solid red",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        border: "1px solid red",
      },
    },
  },
  latestBidRow: {
    backgroundColor: "#BAC8F5 !important",
    fontWeight: 600,
  },
  offerButton: {
    fontSize: "16px",
    fontWeight: `${600} !important`,
    padding: "12px 7px",
    columnGap: "8px",
  },
  infoIcon: {
    width: "20px",
    height: "20px",
  },
}));

const timezones = TimezoneList.map((timezoneInfo) => timezoneInfo?.name);
const salaryTypes = ["Hourly", "Monthly", "Annually"];
const salaryNameDescMapper = {
  hourly: "hour",
  monthly: "month",
  annually: "annually",
};

const getIANAOfTimezone = (timezoneName) => {
  const matchingEntry = TimezoneList.find(
    (entry) => entry.name === timezoneName
  );
  return matchingEntry ? matchingEntry.iana : null;
};

const JobSelector = ({ activeJobs, selectedJob, onJobChange }) => {
  const classes = useStyles();

  const [jobSelectorData, setJobSelectorData] = useState([]); // array of jobNoAndTile values.
  const [selectedJobNoAndTitle, setSelectedJobNoAndTitle] = useState();

  const getActiveJobEntry = (jobNoAndTitle) => {
    return activeJobs?.find(
      (activeJobEntry) => activeJobEntry?.jobNoAndTitle === jobNoAndTitle
    );
  };

  const onChange = (id, value) => {
    setSelectedJobNoAndTitle(value);
    const selectedActiveJob = getActiveJobEntry(value);
    onJobChange(selectedActiveJob);
  };

  useEffect(() => {
    // No valid array checks required here as we show this component only if activeJobs array is valid.
    const activeJobsNoAndTitleData = activeJobs.map(
      (job) => job?.jobNoAndTitle
    ); // active jobs- job number and title string data.
    setJobSelectorData(activeJobsNoAndTitleData);
    setSelectedJobNoAndTitle(selectedJob?.jobNoAndTitle);
  }, [activeJobs, selectedJob]);

  return (
    <CustomSelect
      id={"job-posts-autocomplete"}
      data={jobSelectorData}
      defaultSelection={selectedJobNoAndTitle}
      placeholder={"Select Job"}
      onChange={onChange}
      customClasses={"w-[524px]"}
      // disabled={disableRoleAutocomplete}
    />
  );
};

const AddColumnDialogContent = ({
  onAddColumn,
  columnNames,
  onDialogClose,
}) => {
  const classes = useStyles();
  const customColumnTypes = ["Test", "Meeting"];
  const [customColumnType, setCustomColumnType] = useState("Test");
  const [columnName, setColumnName] = useState("");
  const [columnInfo, setColumnInfo] = useState("");
  const [activateYesButton, setActivateYesButton] = useState(false);
  const [dCNErrorMessage, setDCNErrorMessage] = useState(null); // DCN - duplicate column name
  const subscriptionPlan = localStorage.getItem("planName"); //here we need to add the free plan Id from info api
  const [isTrial, setIsTrial] = useState(subscriptionPlan === "free");
  const isTrialByadmin = localStorage.getItem("isSubscribedByAdmin"); //here we need to add the free plan Id from info api
  const [isSubscribedByAdmin, setIsSubscribedByAdmin] = useState(isTrialByadmin == "true");

  const newColumnNameChange = (id, value) => {
    if (columnNames?.includes(value))
      setDCNErrorMessage(
        "Stage name already exists.Please enter a new stage name."
      );
    else setDCNErrorMessage(null);
    setColumnName(value);
  };

  const isTestCustomColumnType = () => customColumnType === "Test";
  
  // console.log("isTrial from hiring board", isTrial);

  useEffect(() => {
    const isValidColumnName =
      columnName?.length > 0 && dCNErrorMessage === null;
    const isValidColumnInfo = columnInfo?.length > 0;
    setActivateYesButton(isValidColumnName && isValidColumnInfo);
  }, [columnName, columnInfo]);
  return (
    <div className="p-[36px] flex flex-col gap-y-[24px] w-[739px]">
      <div className="flex flex-col gap-y-[24px]">
        <CustomSelect
          id="customColumnType"
          data={customColumnTypes}
          defaultSelection={customColumnType}
          label="stage type"
          onChange={(id, value) => {
            setCustomColumnType(value);
          }}
        />
        <CustomTextfield
          id="columnName"
          label="stage name"
          value={columnName}
          onChange={newColumnNameChange}
          customErrorMessage={dCNErrorMessage}
        />
        <CustomTextfield
          id="columnInfo"
          label="stage info"
          value={columnInfo}
          onChange={(id, value) => {
            setColumnInfo(value);
          }}
          multiline={true}
          minRows={2}
        />
      </div>
      <div className="flex gap-x-[16px] justify-end">
        <CustomButton
          customClasses={clsx([classes.yesOrNoButton, classes.noButton])}
          onClick={onDialogClose}
        >
          No
        </CustomButton>
        <CustomTooltip
          title={!activateYesButton && "Fill in the details."}
          arror
          placement="top"
        >
          <div>
            <CustomButton
              type="button1"
              customClasses={classes.yesOrNoButton}
              onClick={() => {
                onAddColumn(
                  columnName,
                  columnInfo,
                  customColumnType.toLowerCase()
                );
                onDialogClose();
              }}
              disabled={!activateYesButton || (isTrial && !isSubscribedByAdmin)}
            >
              Yes
            </CustomButton>
          </div>
        </CustomTooltip>
      </div>
    </div>
  );
};

const FetchTestsErrorContent = ({ history }) => {
  return (
    <span>
      Could not fetch the tests. Please check you have a valid HackerRank API
      Key in{" "}
      <span
        onClick={() => {
          history.push({
            pathname: "/client/settings",
            state: {
              parentSettingIndex: 4,
            },
          });
        }}
        className="cursor-pointer underline text-[#0096FF]"
      >
        Settings -&gt; Integrations
      </span>
    </span>
  );
};

const TestInviteContent = ({
  consultantID,
  jobID,
  testDetails,
  currentStageType,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [isEditMode, setIsEditMode] = useState(false);
  const [testAgents, setTestAgents] = useState(["HackerRank"]);
  const [tests, setTests] = useState([]);
  const [selectedTestAgent, setSelectedTestAgent] = useState(testAgents[0]);
  const [selectedTest, setSelectedTest] = useState("");
  const [dueDate, setDueDate] = useState(); // ISO format -"2023-12-11T05:00:00.000Z"
  const [activateInviteButton, setActivateInviteButton] = useState(true);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onAgentChange = (id, value) => {};
  const onTestChange = (id, value) => {
    setSelectedTest(value);
  };
  const isDateInvalid = () => {
    const currentDate = dayjs().format("YYYY-MM-DD");
    /**
     * Condition 1-> (!isUndefined(startDate) && startDate === "")
     * 11. On initial load it'll be undefined,and we dont have to show error message immediately,
     * only when there's a change in date and it is invalid(in case of invalid dueDate gets set
     * as '',check date picker's onChange logic),then we have to show the message.
     * Condition 2 -> dueDate < currentDate
     * 1. This has been added as when the component receives an older date it shows the older date (which is a possible scenario,
     * For eg: I set the date for a future dat.And when the future date has passed when i try to view the component it should show the
     * older date but for updating it should be the current or future date,hence in this case also we show of the error message) but for updating
     * it should be current or a future date hence we added the past check to show the error message.
     */
    return (!isUndefined(dueDate) && dueDate === "") || dueDate < currentDate;
  };
  const handleDateChange = (date) => {
    let formattedDateInput = "";
    try {
      formattedDateInput = dayjs(date).format("YYYY-MM-DD"); // sets date in ISO format specifically with EOD timestamp eg: "2023-12-12T23:59:59.999Z"

      if (formattedDateInput === "Invalid Date") formattedDateInput = "";
      else {
        const currentDate = dayjs().format("YYYY-MM-DD");

        // Check if the entered date is in the past. (the user can type in the date as well(through selection it is disabled by the time picker)).
        if (formattedDateInput < currentDate) formattedDateInput = "";
      }
    } catch (error) {
      formattedDateInput = "";
    }
    setDueDate(formattedDateInput);
  };
  const getSelectedTestIDFromName = () => {
    // testStringFormat -> 'Test {testID}: {testName}';

    const idMatch = selectedTest.match(/Test\s(\d+):/);
    let extractedID;

    if (idMatch && idMatch[1]) {
      extractedID = idMatch[1];
    }
    return extractedID;
  };

  const onInvite = () => {
    const inviteAPIToCall = isEditMode
      ? updateConsultantTestInvite
      : inviteConsultantForTest;
    const testID = getSelectedTestIDFromName();

    enqueueSnackbar(
      `Your action to ${
        isEditMode ? "edit" : "send"
      } the test invite is in progress. Please wait. `,
      {
        variant: "info",
        autoHideDuration: 3000,
        action: (key) => (
          <CloseIcon
            onClick={() => closeSnackbar(key)}
            style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
          />
        ),
      }
    );

    inviteAPIToCall({
      consultantId: consultantID,
      jobId: jobID,
      testId: testID,
      dueDate,
      enteredStageTitle: currentStageType,
    })
      .then((res) => {
        enqueueSnackbar(
          `You have ${
            isEditMode ? "updated" : "sent"
          } the test invitation successfully. `,
          {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000); // temp
      })
      .catch((err) => {
        enqueueSnackbar(` ${err?.message} `, {
          variant: "error",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
      });
  };

  const validateEntries = () => {
    const currentDate = dayjs().format("YYYY-MM-DD");
    const isValidDateString =
      isDateStringValidFormat(dueDate, "YYYY-MM-DD") && dueDate >= currentDate; // This format even works if date received is in ISO format.Also making sure its not a past date.
    if (!isValidDateString) {
      setActivateInviteButton(false);
      return;
    }
    const isValidTest = isValidString(selectedTest);
    setActivateInviteButton(isValidTest);
  };
  const subscriptionPlan = localStorage.getItem("planName"); //here we need to add the free plan Id from info api
  const [isTrial, setIsTrial] = useState(subscriptionPlan === "free");
  const isTrialByadmin = localStorage.getItem("isSubscribedByAdmin"); //here we need to add the free plan Id from info api
  const [isSubscribedByAdmin, setIsSubscribedByAdmin] = useState(isTrialByadmin == "true");
  // console.log("isTrial from hiring board", isTrial);

  useEffect(() => {
    // console.log("getHackerRankTests");
    getHackerRankTests()
      .then((res) => {
        if (isValidArray(res?.data)) setTests(res.data);
      })
      .catch(() => {
        enqueueSnackbar(<FetchTestsErrorContent history={history} />, {
          variant: "error",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
      });

    if (isValidObject(testDetails)) {
      setIsEditMode(true);
      setSelectedTest(
        `Test ${testDetails?.id ?? ""}: ${testDetails?.name ?? ""}`
      );
      setDueDate(
        isValidISODateFormat(testDetails?.testDueDate) ||
          isDateStringValidFormat(testDetails?.testDueDate, "YYYY-MM-DD")
          ? dayjs(testDetails?.testDueDate).format("YYYY-MM-DD")
          : null
      ); // Converting to expected format(If date received from the api is in ISO format).
      // set other values based on key names in testDetails object.
    }
  }, []);

  useEffect(() => {
    validateEntries();
  }, [dueDate, selectedTest]);

  return (
    <div className="w-[739px] p-[36px] flex flex-col gap-y-[16px]">
      <CustomSelect
        id="agent"
        data={testAgents}
        label={`Select Test Agent`}
        placeholder={"Select"}
        onChange={onAgentChange}
        defaultSelection={selectedTestAgent}
      />
      <CustomSelect
        id="testName"
        data={tests?.map(
          (testEntry) => `Test ${testEntry?.id ?? ""}: ${testEntry?.name ?? ""}`
        )}
        label={`Select Test`}
        placeholder={"Select"}
        onChange={onTestChange}
        defaultSelection={selectedTest}
      />

      <div className="flex flex-col">
        <CustomLabel label="due date" />
        <FormControl error={isDateInvalid()}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disablePast={true}
              className={clsx([
                classes.dateOrTimePicker,
                isDateInvalid() && classes.dateOrTimePickerError,
              ])}
              value={
                isDateStringValidFormat(dueDate, "YYYY-MM-DD")
                  ? dayjs(dueDate)
                  : null
              }
              onChange={(date) => {
                handleDateChange(date);
              }}
            />
          </LocalizationProvider>
          {isDateInvalid() && (
            <FormHelperText className="ml-0">
              Please enter a valid date. You can enter today's date or any date
              in the future.
            </FormHelperText>
          )}
        </FormControl>
      </div>
      <CustomTooltip
        title={
          !activateInviteButton
            ? "Please enter the above entries correctly."
            : null
        }
      >
        <div className="flex justify-center">
          <CustomButton
            type="button1"
            customClasses="text-[16px] font-semibold py-3 px-7 flex gap-x-[8px]"
            onClick={onInvite}
            disabled={!activateInviteButton || (isTrial && !isSubscribedByAdmin)}
          >
            {isEditMode ? "Edit Invite" : "Invite"}

            <img
              className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
              alt=""
              src="/iconfilledsend.svg"
            />
          </CustomButton>
        </div>
      </CustomTooltip>
    </div>
  );
};

const CancelTestInviteContent = ({
  currentStageType,
  consultantID,
  jobID,
  consultantName,
  testDetails,
  closeDialog,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onCancelInvite = () => {
    enqueueSnackbar(
      `Your action to cancel the test invite is in progress. Please wait. `,
      {
        variant: "info",
        autoHideDuration: 3000,
        action: (key) => (
          <CloseIcon
            onClick={() => closeSnackbar(key)}
            style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
          />
        ),
      }
    );

    cancelConsultantTestInvite({
      consultantId: consultantID,
      jobId: jobID,
      enteredStageTitle: currentStageType,
    })
      .then((res) => {
        enqueueSnackbar(
          `You have cancelled
           the test invitation successfully. `,
          {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000); // temp
      })
      .catch((err) => {
        enqueueSnackbar(` ${err?.message} `, {
          variant: "error",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
      });
  };
  return (
    <div className="w-[739px] p-[36px] flex flex-col gap-y-[16px]">
      <div>
        Are you sure you want to cancel the test <b>{testDetails?.name}</b> for
        <b>{consultantName}</b>?
      </div>
      <div className="flex justify-end gap-[8px]">
        <CustomButton
          customClasses={clsx([classes.yesOrNoButton, classes.noButton])}
          onClick={() => {
            closeDialog();
          }}
        >
          No
        </CustomButton>
        <CustomButton
          type="button1"
          customClasses={clsx([classes.yesOrNoButton])}
          onClick={() => {
            onCancelInvite();
          }}
        >
          Yes
        </CustomButton>
      </div>
    </div>
  );
};

const MeetingInviteContent = ({ interviewDetails, _isEditMode }) => {
  const classes = useStyles();

  const [isEditMode, setIsEditMode] = useState(_isEditMode);
  const [loading, setLoading] = useState(false);
  const [_interviewDetails, set_InterviewDetails] = useState({
    ...interviewDetails,
  });
  const [timeExceeded, setTimeExceeded] = useState();

  const [virtualOrInPersonSwitch, setVirtualOrInPersonSwitch] = useState(false); //  false -> virtual, true-> inPerson.
  const [activateInviteButton, setActivateInviteButton] = useState(true);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const isDateInvalid = () => {
    const currentDate = dayjs().format("YYYY-MM-DD");
    /**
     * Condition 1 -> !isUndefined(_interviewDetails?.interviewDate) &&
      _interviewDetails?.interviewDate === ""
     * 1. In non-edit case  i.e, initialMeetingInvite case-> the interviewDate will be undefined in the object,
     * and we dont have to show error message immediately,only when there's a change in date and it is invalid
     * or an older date is typed(both invalid and typing older date is handled-check date picker's onChange logic,
     * selecting older date isa disabled in the picker itself),then we have to show the message.
     * 2. But for edit case we specifically set it to null if its not a valid truthy value(since we dont show error if value is undefined in point 1).
     * * Condition 2 -> startDate < currentDate
     * 1. This has been added as when the component receives an older date it shows the older date (which is a possible scenario,
     * For eg: I set the date for a future dat.And when the future date has passed when i try to view the component it should show the
     * older date but for updating it should be the current or future date,hence in this case also we show of the error message) but for updating
     * it should be current or a future date hence we added the past check to show the error message.
     */
    return (
      (!isUndefined(_interviewDetails?.interviewDate) &&
        _interviewDetails?.interviewDate === "") ||
      _interviewDetails?.interviewDate < currentDate
    );
  };

  const onInvite = () => {
    setLoading(true);
    enqueueSnackbar(
      `Your action to ${
        !isEditMode ? "schedule" : "reschedule"
      } the meeting is in progress. Please wait  `,
      {
        variant: "info",
        autoHideDuration: 3000,
        action: (key) => (
          <CloseIcon
            onClick={() => closeSnackbar(key)}
            style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
          />
        ),
      }
    );

    if (!isEditMode) {
      scheduleMeeting({
        ..._interviewDetails,
        // applicationType: "consultant", //"Agency",
        // userId: consultantID, // based on the user
        // jobId: jobID,
        // interviewDate: interviewDate,
        // dueDate,
      })
        .then((res) => {
          enqueueSnackbar(` ${res?.message} `, {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
          setLoading(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000); // temp
        })
        .catch((err) => {
          enqueueSnackbar(` ${err?.message} `, {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
          setLoading(false);
        });
    } else {
      reScheduleMeeting({
        ..._interviewDetails,
        // applicationType: _interviewDetails?.consultant,
        // userId: _interviewDetails?.userId,
        // jobId: _interviewDetails.jobID,
        // interviewDate: currentMeeting.interviewDate,
        // dueDate: _interviewDetails?.dueDate,
        // time: currentMeeting?.time
      })
        .then((res) => {
          enqueueSnackbar(` ${res?.message} `, {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
          setLoading(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000); // temp
        })
        .catch((err) => {
          enqueueSnackbar(` ${err?.message} `, {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
          setLoading(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000); // temp
        });
    }
  };

  const handleDateChange = (date) => {
    let formattedDateInput = "";
    try {
      formattedDateInput = dayjs(date).format("YYYY-MM-DD"); // sets date in ISO format specifically with EOD timestamp eg: "2023-12-12T23:59:59.999Z"

      if (formattedDateInput === "Invalid Date") formattedDateInput = "";
      else {
        const currentDate = dayjs().format("YYYY-MM-DD");

        // Check if the entered date is in the past. (the user can type in the date as well(through selection it is disabled by the time picker)).

        if (formattedDateInput < currentDate) formattedDateInput = "";
      }
    } catch (error) {
      formattedDateInput = "";
    }

    set_InterviewDetails({
      ..._interviewDetails,
      interviewDate: formattedDateInput,
    });
  };

  const hasTimeExceeded = () => {
   // // console.log({ _interviewDetails });
    try {
      const date = isDateStringValidFormat(
        _interviewDetails?.interviewDate,
        "YYYY-MM-DD"
      )
        ? dayjs(_interviewDetails?.interviewDate).format("YYYY-MM-DD")
        : null; // For edit case we get the date initially in complete ISO format instead of "YYYY-MM-DD" format. But the onInvite fn works fine if we pass the date in "YYYY-MM-DD" format. Same for the date element in Test Invite dialog.
      const endTime = _interviewDetails?.time?.end;
      const timezone = _interviewDetails?.time?.timezone;
      const isValidTimeZone = isValidString(timezone);

      if (!date || !endTime || !isValidTimeZone) {
        return false; // Invalid entries
      }

      const timezoneIANAIdentifier = getIANAOfTimezone(timezone);
      const interviewEndDateTime = dayjs.tz(
        `${date} ${endTime}`,
        timezoneIANAIdentifier
      );
      const currentDateTimeInTimeZone = dayjs().tz(timezoneIANAIdentifier);

      return currentDateTimeInTimeZone.isAfter(interviewEndDateTime);
    } catch (error) {
      console.error(error);
      return true;
    }
  };

  const isStartTimeBeforeEndTime = (startTime, endTime) => {
    const format = "HH:mm:ss";
    const start = dayjs(startTime, format);
    const end = dayjs(endTime, format);

    return start.isBefore(end);
  };

  const validateEntries = (isTimeExceeded) => {
    const currentDate = dayjs().format("YYYY-MM-DD");
    const isValidDateString =
      isDateStringValidFormat(_interviewDetails?.interviewDate, "YYYY-MM-DD") &&
      _interviewDetails?.interviewDate >= currentDate; // Validating as per format used in handleDateChange, This format even works if date received is in ISO format.Also making sure its not a past date.
    if (!isValidDateString) {
      setActivateInviteButton(false);
      return;
    }
    const isValidTimeZone = isValidString(_interviewDetails?.time?.timezone);
    if (!isValidTimeZone) {
      setActivateInviteButton(false);
      return;
    }
    const isStartTimeStringValid = isTimeStringValidFormat(
      _interviewDetails?.time?.start,
      "HH:mm:ss"
    );
    if (!isStartTimeStringValid) {
      setActivateInviteButton(false);
      return;
    }
    const isEndTimeStringValid = isTimeStringValidFormat(
      _interviewDetails?.time?.end,
      "HH:mm:ss"
    );
    if (!isEndTimeStringValid) {
      setActivateInviteButton(false);
      return;
    }
    const validTimeRange = isStartTimeBeforeEndTime(
      _interviewDetails?.time?.start,
      _interviewDetails?.time?.end
    );
    if (!validTimeRange) {
      setActivateInviteButton(false);
      return;
    }

    // Need to add in person location selection validation as well.
    setActivateInviteButton(!isTimeExceeded);

    // // console.log({
    //   isValidDateString,
    //   isValidTimeZone,
    //   isStartTimeStringValid,
    //   isEndTimeStringValid,
    //   startBeforeEnd: isStartTimeBeforeEndTime(
    //     _interviewDetails?.time?.start,
    //     _interviewDetails?.time?.end
    //   ),
    //   validTimeRange,
    // });
  };
  const subscriptionPlan = localStorage.getItem("planName"); //here we need to add the free plan Id from info api
  const [isTrial, setIsTrial] = useState(subscriptionPlan === "free");
  // console.log("isTrial from hiring board", isTrial);
  const isTrialByadmin = localStorage.getItem("isSubscribedByAdmin"); //here we need to add the free plan Id from info api
  const [isSubscribedByAdmin, setIsSubscribedByAdmin] = useState(isTrialByadmin == "true");

  useEffect(() => {
    if (isEditMode) {
      let currentMeeting =
        isValidArray(_interviewDetails?.meetings) &&
        _interviewDetails?.meetings
          .filter(
            (meetingItem) =>
              meetingItem?.enteredStageType ===
              _interviewDetails?.enteredStageTitle
          )
          .map((meetingItem) => ({
            ...meetingItem,
          }))[0];

      if (currentMeeting) {
        set_InterviewDetails((prevDetails) => ({
          ...prevDetails,
          time: { ...currentMeeting?.time },
          interviewDate:
            isValidISODateFormat(currentMeeting?.interviewDate) ||
            isDateStringValidFormat(currentMeeting?.interviewDate, "YYYY-MM-DD")
              ? dayjs(currentMeeting?.interviewDate).format("YYYY-MM-DD")
              : null, // Reason to set this to null is mentioned in isDateInvalid function's logic.
        }));
      }
    }
  }, []);

  useEffect(() => {
    const isTimeExceeded = hasTimeExceeded();
    setTimeExceeded(isTimeExceeded);
    validateEntries(isTimeExceeded);
  }, [_interviewDetails]);

  return (
    <div className="w-[739px] p-[36px] flex flex-col gap-y-[16px]">
      <div>
        <CustomLabel label="type of meeting" />
        <div className="flex gap-x-[8px]">
          <TwoLabelSwitch
            id="switchSelector"
            label1="Virtual"
            label2="In Person"
            defaultSelection={virtualOrInPersonSwitch} //  false -> label1, true-> label2
            onChange={(id, value) => {
              // value = false -> left i.e, label1 i.e, virtual
              // value = true -> right i.e, label2 i.e, in person
              setVirtualOrInPersonSwitch(value);
            }}
            disabled={true}
          />
          <CustomTooltip
            title={
              "Scheduling in-person interviews will be available in the future release."
            }
            arrow
            placement="top"
          >
            <InfoIcon className={classes.infoIcon} />
          </CustomTooltip>
        </div>
        {virtualOrInPersonSwitch ? (
          <div className="mt-[8px] px-[16px]">
            <CustomLabel label="Select location" />
            <Geosuggest
              onChange={(value) => {
                // // console.log({ value });
              }}
              className="w-full"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="flex flex-col">
        <CustomLabel label="date" />
        <FormControl error={isDateInvalid()}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disablePast={true}
              value={
                isDateStringValidFormat(
                  _interviewDetails?.interviewDate,
                  "YYYY-MM-DD"
                )
                  ? dayjs(_interviewDetails?.interviewDate)
                  : null
              }
              onChange={(date) => {
                handleDateChange(date);
              }}
              className={clsx([
                classes.dateOrTimePicker,
                isDateInvalid() && classes.dateOrTimePickerError,
              ])}
            />
          </LocalizationProvider>
          {isDateInvalid() && (
            <FormHelperText className="ml-0">
              Please enter a valid date. You can enter today's date or any date
              in the future.
            </FormHelperText>
          )}
        </FormControl>
      </div>

      <CustomTimeRangePicker
        ampm={false}
        start={_interviewDetails?.time?.start}
        end={_interviewDetails?.time?.end}
        onChange={(formattedStartTime, formattedEndTime) => {
          // // console.log({
          //   formattedStartTime,
          //   formattedEndTime,
          // });
          set_InterviewDetails({
            ..._interviewDetails,
            time: {
              ..._interviewDetails?.time,
              start: formattedStartTime,
              end: formattedEndTime,
            },
          });
        }}
      />

      <CustomSelect
        id="timezone"
        data={timezones}
        label={`timezone`}
        placeholder={"Select"}
        defaultSelection={
          _interviewDetails?.time?.timezone
            ? _interviewDetails?.time?.timezone
            : null
        }
        onChange={(id, value) => {
          set_InterviewDetails({
            ..._interviewDetails,
            time: {
              ..._interviewDetails?.time,
              [id]: value,
            },
          });
        }}
      />

      {timeExceeded && (
        <div className="text-[12px] text-red-500">
          Selected interview time has already passed.Please choose a future
          time.
        </div>
      )}

      <CustomTooltip
        title={
          !activateInviteButton
            ? "Please enter the above entries correctly."
            : null
        }
      >
        <div className="flex justify-center">
          <CustomButton
            type="button1"
            customClasses="text-[16px] font-semibold py-3 px-7 flex gap-x-[8px]"
            onClick={onInvite}
            // disabled={loading}
            disabled={!activateInviteButton || (isTrial && !isSubscribedByAdmin)}
          >
            {isEditMode ? "Edit Invite" : "Invite"}

            <img
              className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
              alt=""
              src="/iconfilledsend.svg"
            />
          </CustomButton>
        </div>
      </CustomTooltip>
    </div>
  );
};

const OffersTable = ({ offers }) => {
  const classes = useStyles();
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  let latestSortedOffers = [...offers].reverse();

  const getDateAsPerLocal = (dateString) => {
    const localDate = dayjs.utc(dateString).local(); // Convert UTC to local time

    const formattedDate = localDate.format("MMMM D, YYYY h:mm:ss A"); // // Output: December 20, 2023 12:51:47 PM
    return formattedDate;
  };
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
      <Table
        aria-label="customized table"
        stickyHeader
        className="min-w-[700px] mb-0"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Bid Amount</StyledTableCell>
            <StyledTableCell align="center">Salary Type</StyledTableCell>
            <StyledTableCell align="center">Date</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {latestSortedOffers.map((offer, index) => (
            <CustomTooltip
              title={index === 0 ? "This is your latest/current bid." : null}
            >
              <StyledTableRow
                key={offer?._id}
                className={index === 0 && classes.latestBidRow}
              >
                <StyledTableCell align="center">
                  <span className="font-semibold">$</span>{" "}
                  {offer?.bidAmount ?? "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {" "}
                  {offer?.salaryType ?? "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {isValidISODateFormat(offer?.bidDate)
                    ? getDateAsPerLocal(offer?.bidDate)
                    : "-"}
                </StyledTableCell>
              </StyledTableRow>
            </CustomTooltip>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const OfferContent = ({ userData, jobId }) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const offers = userData?.offers; // currently all offers/bid are of userType:"client" only. Later if counter offer option is provided from consultant's end as well, we have to filter this out here to show only client's offer/bid history.
  const [offerSwitch, setOfferSwitch] = useState(false); //  false -> accept consultant's bid, true-> raise counter bid.
  const [proposalOfConsultant, setProposalOfConsultant] = useState({});
  const [reviseOffer, setReviseOffer] = useState({
    consultantId: userData.consultantId,
    jobId: jobId,
  });
  const [activateOfferButton, setActivateOfferButton] = useState(false);

  const validateEntries = () => {
    const isValidSalaryType = reviseOffer?.salaryType?.length > 0;
    const isValidBidAmount = reviseOffer?.bidAmount > 0;

    setActivateOfferButton(isValidSalaryType && isValidBidAmount);
  };

  useEffect(() => {
    getJobProposalDetails({
      jobId,
      consultantId: userData?.consultantId,
    })
      .then((res) => {
        setProposalOfConsultant(res?.data);
      })
      .catch((err) => {
        enqueueSnackbar(` ${err?.message} `, {
          variant: "error",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
      });
  }, []);

  useEffect(() => {
    validateEntries();
  }, [reviseOffer]);
  return (
    <div className="w-[739px] p-[36px] flex flex-col gap-y-[16px] flex-wrap content-center">
      <div className="flex gap-x-[12px] text-[16px] font-semibold">
        <div className="">CONSULTANT's BID - </div>
        <div>
          <span className="font-semibold">$</span>{" "}
          {proposalOfConsultant?.salaryValue ?? "-"} /{" "}
          {isValidString(proposalOfConsultant?.salaryType)
            ? salaryNameDescMapper[
                proposalOfConsultant.salaryType.toLowerCase()
              ] ?? "-"
            : "-"}
        </div>
      </div>
      {isValidArray(offers) ? (
        <div className="flex flex-col gap-y-[12px] text-[16px] font-semibold">
          <div className="">Your BID(s) - </div>
          <OffersTable offers={offers} />
        </div>
      ) : (
        <></>
      )}
      <div className="flex justify-center">
        <TwoLabelSwitch
          id="switchSelector"
          label1="Accept Consultant's Bid"
          label2="Raise Counter Bid"
          defaultSelection={offerSwitch} //  false -> label1, true-> label2
          onChange={(id, value) => {
            // value = false -> left i.e, label1 i.e, accept current bid
            // value = true -> right i.e, label2 i.e, raise counter bid
            setOfferSwitch(value);
          }}
        />
      </div>

      {offerSwitch ? (
        <>
          <div className="flex gap-x-[44px]">
            <div className="flex-1">
              <CustomSelect
                id="salaryType"
                data={salaryTypes}
                label="salary type"
                placeholder={"Select"}
                onChange={(id, value) => {
                 // console.log("innnn true")

                  setReviseOffer({
                    ...reviseOffer,
                    [id]: value,
                  });
                }}
                // defaultSelection={proposalDetails?.salaryType}
              />
            </div>
            <div className="flex-1">
              <CustomTextfield
                id="salaryValue"
                type="number"
                inputProps={{ step: "0.01", min: 0 }}
                label="salary value"
                onChange={(id, value) => {
                  // console.log("innnn", offers)
                  setReviseOffer({
                    ...reviseOffer,
                    // 'bidValue': value
                    bidAmount: value,
                  });
                }}
                // value={proposalDetails?.salaryValue}
                customErrorMessage={
                  reviseOffer?.bidAmount <= 0
                    ? "Please enter a value greater than 0."
                    : ""
                }
                startAdornment={"$"}
              />
            </div>
          </div>
          <CustomTooltip
            title={
              !activateOfferButton ? "Please fill the above details." : null
            }
          >
            <div className="flex justify-center">
              <CustomButton
                type="button1"
                customClasses={classes.offerButton}
                onClick={() => {
                  makeBid({
                    ...reviseOffer,
                  })
                    .then((res) => {
                      enqueueSnackbar(` ${res?.message} `, {
                        variant: "success",
                        autoHideDuration: 3000,
                        action: (key) => (
                          <CloseIcon
                            onClick={() => closeSnackbar(key)}
                            style={{
                              cursor: "pointer",
                              fontSize: "15",
                              marginTop: "-1px",
                            }}
                          />
                        ),
                      });
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000); // temp
                    })
                    .catch((err) => {
                      enqueueSnackbar(` ${err?.message} `, {
                        variant: "error",
                        autoHideDuration: 3000,
                        action: (key) => (
                          <CloseIcon
                            onClick={() => closeSnackbar(key)}
                            style={{
                              cursor: "pointer",
                              fontSize: "15",
                              marginTop: "-1px",
                            }}
                          />
                        ),
                      });
                    });
                }}
                disabled={!activateOfferButton}
              >
                Raise Counter Bid
                <img
                  className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
                  alt=""
                  src="/iconfilledsend.svg"
                />
              </CustomButton>
            </div>
          </CustomTooltip>
        </>
      ) : (
        <>
          {" "}
          <div className="flex justify-center">
            <CustomButton
              type="button1"
              customClasses={classes.offerButton}
              onClick={() => {
             // console.log("Accept Current Bid", offers);
                // const currectBidAmount = offerDetails[0].bidAmount;
                // const currectBidAmount = offerDetails[offerDetails.length - 1]?.bidAmount;
                const currectBidAmount = offers[offers.length - 1].bidAmount;
          
                // // console.log(
                //   {
                //     ...reviseOffer,
                //     onboardingDetails: {
                //       onboardingStatus: "Documents under Assessment",
                //       workingTime: "--:-- am - --:-- pm",
                //       timezone: "America/New_York",
                //       startDate: "202x-xx-xxT00:00:00.000+00:00",
                //       acceptedRate: currectBidAmount //"50"
                //     }
                //   }
                // );

                activeConsultantsByAcceptingTheBid({
                  ...reviseOffer,
                  onboardingDetails: {
                    onboardingStatus: "Documents under Assessment",
                    workingTime: { start: "--:-- am", end: "--:-- pm" },
                    timezone: "America/New_York",
                    startDate: "2023-11-11T00:00:00.000+00:00",
                    acceptedRate: currectBidAmount, //"50"
                  },
                })
                  .then((res) => {
                    enqueueSnackbar(
                      `You have accepted the consultant's bid. `,
                      {
                        variant: "success",
                        autoHideDuration: 3000,
                        action: (key) => (
                          <CloseIcon
                            onClick={() => closeSnackbar(key)}
                            style={{
                              cursor: "pointer",
                              fontSize: "15",
                              marginTop: "-1px",
                            }}
                          />
                        ),
                      }
                    );
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000); // temp
                  })
                  .catch((err) => {
                    enqueueSnackbar(` ${err?.message} `, {
                      variant: "error",
                      autoHideDuration: 3000,
                      action: (key) => (
                        <CloseIcon
                          onClick={() => closeSnackbar(key)}
                          style={{
                            cursor: "pointer",
                            fontSize: "15",
                            marginTop: "-1px",
                          }}
                        />
                      ),
                    });
                  });
              }}
            >
              Accept Consultant's Bid
              <img
                className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
                alt=""
                src="/iconfilledsend.svg"
              />
            </CustomButton>
          </div>
        </>
      )}
    </div>
  );
};

const RejectConfirmationDialog = ({ userData, onDialogClose, onReject }) => {
  //// console.log({ userData });
  const classes = useStyles();

  let userName;
  if (userData?.firstName) userName = userData?.firstName;
  if (userData?.lastName) userName += userData?.lastName;

  if (userName)
    return (
      <div className=" w-[739px] p-[36px] flex flex-col gap-y-[16px]">
        <div>
          Are you sure you want to reject{" "}
          {userName ? (
            <span className="font-semibold">{userName}</span>
          ) : (
            "the consultant"
          )}
          ?
        </div>

        <div className="flex gap-x-[16px] justify-end">
          <CustomButton
            customClasses={clsx([classes.yesOrNoButton, classes.noButton])}
            onClick={onDialogClose}
          >
            No
          </CustomButton>
          <CustomButton
            type="button1"
            customClasses={classes.yesOrNoButton}
            onClick={() => {
              onReject();
              onDialogClose();
            }}
          >
            Yes
          </CustomButton>
        </div>
      </div>
    );
};

const CardActionDialogContent = ({
  setUserCardActionDialogOpen,
  actionType,
  userData,
  jobID,
  onReject,
}) => {
  const closeDialog = () => {
    setUserCardActionDialogOpen(false);
  };
  switch (actionType) {
    case "initialTestInvite":
      return (
        <TestInviteContent
          currentStageType={userData?.currentStageType}
          consultantID={userData?.consultantId}
          jobID={jobID}
        />
      );
    case "editTestInvite":
      return (
        <TestInviteContent
          currentStageType={userData?.currentStageType}
          consultantID={userData?.consultantId}
          jobID={jobID}
          testDetails={
            isValidArray(userData?.hr)
              ? userData?.hr
                  .filter(
                    (hrItem) =>
                      hrItem?.enteredStageType?.trim().toLowerCase() ===
                      userData.currentStageType.trim().toLowerCase()
                  )
                  .map((hrItem) => ({
                    ...hrItem?.testDetails,
                  }))[0]
              : null
          }
        />
      );
    case "cancelTestInvite":
      return (
        <CancelTestInviteContent
          consultantName={`${userData?.firstName ?? ""} ${
            userData?.lastName ?? ""
          }`}
          currentStageType={userData?.currentStageType}
          consultantID={userData?.consultantId}
          jobID={jobID}
          testDetails={
            isValidArray(userData?.hr)
              ? userData?.hr
                  .filter(
                    (hrItem) =>
                      hrItem?.enteredStageType?.trim().toLowerCase() ===
                      userData.currentStageType.trim().toLowerCase()
                  )
                  .map((hrItem) => ({
                    ...hrItem?.testDetails,
                  }))[0]
              : null
          }
          closeDialog={closeDialog}
        />
      );
    case "initialMeetingInvite":
      // return <MeetingInviteContent interviewDetails={{ jobId:jobID, userId:userData?.consultantId, applicationType:"consultant", enteredStageTitle: "Screening" }} />
      return (
        <MeetingInviteContent
          interviewDetails={{
            jobId: jobID,
            userId: userData?.consultantId,
            applicationType: "consultant",
            enteredStageTitle: userData?.currentStageType,
          }}
          _isEditMode={false}
        />
      );
    case "editMeetingInvite":
      return (
        // <MeetingInviteContent interviewDetails={userData?.interviewDetails} />
        <MeetingInviteContent
          interviewDetails={{
            jobId: jobID,
            userId: userData?.consultantId,
            applicationType: "consultant",
            enteredStageTitle: userData?.currentStageType,
            meetings: userData?.meeting,
          }}
          _isEditMode={true}
        />
      );
    case "makeAnOffer":
      return <OfferContent userData={userData} jobId={jobID} />;
    case "reviseOffer":
      // return <OfferContent offerDetails={userData?.interviewDetails} />;
      return <OfferContent userData={userData} jobId={jobID} />;
    case "reject":
      return (
        <RejectConfirmationDialog
          onDialogClose={closeDialog}
          onReject={onReject}
          userData={userData}
        />
      );
    default:
      return <></>;
  }
};

const HiringProcessKanbanBoard = ({ jobID }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { experiences } = useSelector((state) => state.misc);
  const jobsBuf = useSelector((state) => state?.jobs?.jobs?.data);
  const personalDataBuf = useSelector(
    (state) => state.clientProfileInfo.clientInfo.employer
  );

  const userCardActionDialogTitles = {
    initialTestInvite: "Send Test Invite",
    editTestInvite: "Edit Test Invite",
    cancelTestInvite: "Cancel Test Invite",
    initialMeetingInvite: "Send Interview Invite",
    editMeetingInvite: "Edit Interview Invite",
    makeAnOffer: "Make an Offer",
    reviseOffer: "Revise Offer",
    reject: "Reject Consultant",
  };

  const [columnsData, setColumnsData] = useState([]);
  /**
   * columnsData - array of objects
   * object structure :
   * {
      type: `column${acceptedColumnIndex + 1}`,
      title: `Column ${acceptedColumnIndex + 1}`,      
      users: [],
      showMenu: true,
      movable: true,
      moveCardFromAndTo: false, // This value decides if cards within a stage can be moved to other stages and vice-versa.
      info: "Lorem ipsum invited",
      defaultOrCustomStage: "default", // default or custom
      customStageVariant: "", // This attribute is valid if defaultOrCustomStage is 'custom'. 'test'/'meeting' for custom defaultOrCustomStage,"" for default defaultOrCustomStage.
    }
   */
  const [columnNames, setColumnNames] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [postedJobs, setPostedJobs] = useState([]);
  const [activeJobs, setActiveJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState();
  const [jobData, setJobData] = useState();
  const [activeColumnData, setActiveColumnData] = useState({}); // object
  const [activeCardData, setActiveCardData] = useState({}); // object
  const [totalApplicants, setTotalApplicants] = useState();
  const [addColumnDialogOpen, setAddColumnDialogOpen] = useState(false);
  const [addColumnIndex, setAddColumnIndex] = useState();
  const [userCardActionDialogOpen, setUserCardActionDialogOpen] =
    useState(false);
  const [userCardActionType, setUserCardActionType] = useState();
  const [userCardDataForAction, setUserCardDataForAction] = useState({});
  const [hRAPIKeyExists, setHRAPIKeyExists] = useState(false);

  const subscriptionPlan = localStorage.getItem("planName"); //here we need to add the free plan Id from info api
  const [isTrial, setIsTrial] = useState(subscriptionPlan === "free");
  const isTrialByadmin = localStorage.getItem("isSubscribedByAdmin"); //here we need to add the free plan Id from info api
  const [isSubscribedByAdmin, setIsSubscribedByAdmin] = useState(isTrialByadmin == "true");
  // console.log("isTrial from hiring board", isTrial);

  const columnTypes = useMemo(
    () =>
      isValidArray(columnsData)
        ? columnsData?.map((column) => {
            return column?.type;
          })
        : [],
    [columnsData]
  );
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 3 } })
  ); // only after moving the column till 3px the drag event starts,thus allowing other actions on the column to work asusual(else the drag feature was affecting it).

  const onJobChange = (selectedActiveJob) => {
    history.replace(`/client/hiring-board/${selectedActiveJob?._id}`);
  };

  const moveUserCard = (
    userData,
    currentUserCardColumnIndex,
    expectedColumnIndex,
    overUserCardIndex = null
  ) => {
    const updatedColumnsData = [...columnsData];
    const userID = userData?.consultantId;
    const isExpectedSameAsCurrentColumn =
      currentUserCardColumnIndex === expectedColumnIndex;
    const noOverUserCard = overUserCardIndex === null;

    let currentColumnData, expectedColumnData;

    if (isExpectedSameAsCurrentColumn && noOverUserCard) return; // if card is not moved over another card then no change, it'll remain in its original position.

    /** STEP 1- Can call it as either:
     * 1. Removing user from current column. - Moving card to other column or over card of other column case.
     *                OR
     *  2. Removing user from its current position in its column. - Moving over other card in its own column case.
     */

    currentColumnData = updatedColumnsData[currentUserCardColumnIndex];
    currentColumnData.users = currentColumnData?.users?.filter(
      (userData) => userData?.consultantId !== userID
    );

    /** STEP 2- Can call it as either:
     * 1. Adding user in expected column - a).Moving card to other column or b). over card of other column case.
     *                OR
     *  2. Adding user to expected position in its column. - Moving over other card in its own column case.
     */
    expectedColumnData = updatedColumnsData[expectedColumnIndex];
    if (!noOverUserCard) {
      // 1b or 2nd point of step 2
      // Adding it before the hovered over card

      expectedColumnData?.users?.splice(overUserCardIndex, 0, userData);
    } else {
      // 1a point of step 2
      // Adding it in the beginning
      expectedColumnData?.users?.unshift(userData);
    }

    //// console.log("updatedColumnsData++", updatedColumnsData);
    updatedColumnsData[currentUserCardColumnIndex] = currentColumnData;
    updatedColumnsData[expectedColumnIndex] = expectedColumnData;
    setColumnsData(updatedColumnsData);
  };

  const moveConsultantAPICall = (
    userData,
    currentUserCardColumnIndex,
    expectedColumnIndex,
    overUserCardIndex = null
  ) => {
    //// console.log("userData--", userData);

    const stageType = columnsData?.[expectedColumnIndex]?.type;
    //// console.log("moveConsultantAPICall--", stageType);

    enqueueSnackbar(
      `Your action to move the consultant to the ${stageType} stage is in progress.Please wait.  `,
      {
        variant: "info",
        autoHideDuration: 3000,
        action: (key) => (
          <CloseIcon
            onClick={() => closeSnackbar(key)}
            style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
          />
        ),
      }
    );
    moveConsultant({
      consultantId: userData?.consultantId,
      jobId: jobID,
      stage: stageType,
    })
      .then(() => {
        moveUserCard(
          userData,
          currentUserCardColumnIndex,
          expectedColumnIndex,
          overUserCardIndex
        );
        // testAssesment
        if (stageType == "testAssesment") {
          getHackerRankTests()
            .then((res) => {
              // if (isValidArray(res?.data)) setTests(res.data);
            })
            .catch(() => {
              enqueueSnackbar(<FetchTestsErrorContent history={history} />, {
                variant: "error",
                autoHideDuration: 3000,
                action: (key) => (
                  <CloseIcon
                    onClick={() => closeSnackbar(key)}
                    style={{
                      cursor: "pointer",
                      fontSize: "15",
                      marginTop: "-1px",
                    }}
                  />
                ),
              });
            });
        }

        enqueueSnackbar(
          `Your action to move the consultant to the ${stageType} stage was successful.`,
          {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
      })
      .catch(() => {
        enqueueSnackbar(
          `Your action to move the consultant to the ${stageType} stage failed.Please try again later.`,
          {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
      });
  };

  const onUserCardActionClick = (
    actionType,
    currentUserCardColumnIndex,
    userData
  ) => {
    let updatedColumnIndex;

    {
      /**Actions related to direct movement to any other column/stage in the board->
       * 1). next
       * 2). previous
       */
    }
    {
      /** Other actions ->
       * 1). Test stage
       *    a). initialTestInvite
       *    b). editTestInvite
       *    c). cancelTestInvite
       * 2). Meeting stage
       *    a). initialMeetingInvite
       *    b). editMeetingInvite
       *
       * 3). Last test/meeting stage
       *    a). makeAnOffer
       * 4). reviseOffer (Offered stage/column)
       * 5). reject (Move to Rejected stage/column after confirmation).
       */
    }
    const isDirectMoveAction =
      actionType === "next" || actionType === "previous";

    if (isDirectMoveAction) {
      updatedColumnIndex =
        actionType === "next"
          ? currentUserCardColumnIndex + 1
          : currentUserCardColumnIndex - 1;

      moveConsultantAPICall(
        userData,
        currentUserCardColumnIndex,
        updatedColumnIndex
      );
    } else {
      if (actionType === "reject") updatedColumnIndex = columnsData?.length - 2; // reject - since reject is the last second stage.
      setUserCardActionType(actionType);

      setUserCardDataForAction({
        ...userData,
        currentStageType: columnTypes[currentUserCardColumnIndex],
        currentUserCardColumnIndex,
        updatedColumnIndex,
      });
      setUserCardActionDialogOpen(true);
    }
  };

  const onRejectConsultant = () => {
    if (isValidObject(userCardDataForAction)) {
      moveConsultantAPICall(
        { ...userCardDataForAction },
        userCardDataForAction?.currentUserCardColumnIndex,
        userCardDataForAction?.updatedColumnIndex
      );
    }
  };

  const updateHiringBoardStageAPI = (updatedStagesData, actionMessage) => {
    enqueueSnackbar(
      `Your action to ${actionMessage} is in progress.Please wait.`,
      {
        variant: "info",
        autoHideDuration: 3000,
        action: (key) => (
          <CloseIcon
            onClick={() => closeSnackbar(key)}
            style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
          />
        ),
      }
    );
    setHiringBoardStageData({ jobID, request: updatedStagesData })
      .then(() => {
        setColumnsData(updatedStagesData);
       // // console.log("updatedStagesData--", updatedStagesData);
        enqueueSnackbar(`Your action to ${actionMessage} was successful.`, {
          variant: "success",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
      })
      .catch(() => {
        enqueueSnackbar(
          `Your action to ${actionMessage} failed.Please try again later.`,
          {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
      });
  };

  const onMoveColumn = (column1Index, column2Index, columnToMove) => {
    // columnToMove-> 1 or 2 to indicate column1Index or column2Index is the column that's being moved.
    const columnToMoveTitle =
      columnsData?.[columnToMove === 1 ? column1Index : column2Index]?.title;
    let updatedColumnsData = [...columnsData];
    updatedColumnsData = arrayMove(
      updatedColumnsData,
      column1Index,
      column2Index
    ); // swaps both columns
    updateHiringBoardStageAPI(
      updatedColumnsData,
      `move stage ${columnToMoveTitle}`
    );
  };

  const onColumnEdit = (columnIndex, newColumnName, newColumnInfo) => {
    const newColumnType = toCamelCase(newColumnName);
    const updatedColumnsData = [...columnsData];

    updatedColumnsData[columnIndex].type = newColumnType;
    updatedColumnsData[columnIndex].title = newColumnName;
    updatedColumnsData[columnIndex].info = newColumnInfo;
    updateHiringBoardStageAPI(
      updatedColumnsData,
      `edit stage ${newColumnName}`
    );
  };

  const onColumnDelete = (columnIndex) => {
    const stageTitle = columnsData?.[columnIndex]?.title;
    let updatedColumnsData = [...columnsData];
    updatedColumnsData = updatedColumnsData?.filter(
      (column, index) => index !== columnIndex
    );
    updateHiringBoardStageAPI(updatedColumnsData, `delete stage ${stageTitle}`);
  };

  const showAddColumn = (type, movable) => {
    return type === "shortlisted" || movable;
  };

  const onAddColumn = (title, info, customStageVariant) => {
    let updatedColumnsData = [...columnsData];
    updatedColumnsData?.splice(addColumnIndex, 0, {
      type: toCamelCase(title),
      title,
      users: [],
      showMenu: true,
      movable: true,
      moveCardFromAndTo: true,
      info,
      defaultOrCustomStage: "custom",
      customStageVariant,
    });
    updateHiringBoardStageAPI(updatedColumnsData, `add stage ${title}`);
  };

  const getAcceptedUsersCount = () => {
    const acceptedStageData = columnsData?.find(
      (column) => column?.type === "accepted"
    );
    return isValidArray(acceptedStageData?.users)
      ? acceptedStageData?.users?.length
      : 0;
  };

  const dndOnDragStart = (event: DragStartEvent) => {
    const { sortable, elementType, ...elementProps } =
      event?.active?.data?.current;
    // // console.log({ event, elementProps });
    if (elementType === "column") {
      setActiveCardData(null);
      setActiveColumnData(elementProps);
    } else {
      setActiveCardData(elementProps);
      setActiveColumnData(null);
    }
  };

  const dndOnDragEnd = (event: DragEndEvent) => {
    setActiveColumnData(null);
    setActiveCardData(null);

    // console.log("dndOnDragEnd");
    const { active, over } = event;
    // // console.log({ active, over });
    if (!over) return; // This means we aren't dragging over a valid element(outside board) hence no change to be done.

    const activeElementData = active.data.current;
    const overElementData = over.data.current;
    const activeElementType = activeElementData?.elementType;
    const overElementType = overElementData?.elementType;
    const isActiveElementAColumn = activeElementType === "column"; // card or column
    const isOverElementAColumn = overElementType === "column"; // card or column

    let activeColumnIndex,
      overColumnIndex,
      activeUCData,
      activeUCColumnIndex,
      overUCColumnIndex,
      overUCIndex,
      overColumnMovable,
      activeColumnTitle,
      overColumnTitle,
      canMoveUCToOverColumn,
      overUCColumnData,
      overUCColumnTitle,
      canMoveUCToOverUCColumn,
      differentColumns,
      isOverColumnReject,
      isOverUCColumnReject; // UC - user card

    if (isActiveElementAColumn && isOverElementAColumn) {
      // Moving a column over a column
      activeColumnIndex = activeElementData?.columnIndex;
      overColumnIndex = overElementData?.columnIndex;
      overColumnMovable = overElementData?.columnData?.movable;

      if (activeColumnIndex === overColumnIndex || !overColumnMovable) {
        if (!overColumnMovable) {
          activeColumnTitle = activeElementData?.columnData?.title;
          overColumnTitle = overElementData?.columnData?.title;
          enqueueSnackbar(
            `You cannot move ${activeColumnTitle} stage over ${overColumnTitle} stage, as ${overColumnTitle} is a fixed stage.`,
            {
              variant: "info",
              autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon
                  onClick={() => closeSnackbar(key)}
                  style={{
                    cursor: "pointer",
                    fontSize: "15",
                    marginTop: "-1px",
                  }}
                />
              ),
            }
          );
        }

        return; // no change if active column is at its original position(no need of snackbar for this like above) or overColumn is not movable.
      }
      onMoveColumn(activeColumnIndex, overColumnIndex, 1); // moves active column data after over column data.
    } else if (!isActiveElementAColumn) {
      // moving a user card
      activeUCData = activeElementData?.userData;
      activeUCColumnIndex = activeElementData?.columnIndex;

      if (isOverElementAColumn) {
        // moving the card over a column.
        // console.log("isOverElementAColumn--");
        overColumnIndex = overElementData?.columnIndex;

        overColumnTitle = overElementData?.columnData?.title;
        canMoveUCToOverColumn = overElementData?.columnData?.moveCardFromAndTo;
        differentColumns = activeUCColumnIndex !== overColumnIndex; // Condition to check if active user card's column is different from over column.
        isOverColumnReject = overColumnIndex === columnsData?.length - 2; // reject - since reject is the last second stage.
        if (differentColumns) {
          // Allowing card movement only if its to a different column.
          if (canMoveUCToOverColumn) {
            if (isOverColumnReject) {
              // For reject we cant directly drop we first ask for confirmation.
              setUserCardActionType("reject");
              setUserCardDataForAction({
                ...activeUCData,
                currentStageType: columnTypes[activeUCColumnIndex],
                currentUserCardColumnIndex: activeUCColumnIndex,
                updatedColumnIndex: overColumnIndex,
              });
              setUserCardActionDialogOpen(true);
            } else {
              moveConsultantAPICall(
                activeUCData,
                activeUCColumnIndex,
                overColumnIndex
              );
            }
          } else {
            enqueueSnackbar(
              `You cannot move the consultant to ${overColumnTitle} stage.`,
              {
                variant: "info",
                autoHideDuration: 3000,
                action: (key) => (
                  <CloseIcon
                    onClick={() => closeSnackbar(key)}
                    style={{
                      cursor: "pointer",
                      fontSize: "15",
                      marginTop: "-1px",
                    }}
                  />
                ),
              }
            );
          }
        }
      } else {
        // console.log("isOverElementAColumn else");
        // moving the card over a card- (in different or same column. same column card reordering is not allowed for now as support is not added in backend logic).
        overUCIndex = overElementData?.userIndex;
        overUCColumnIndex = overElementData?.columnIndex;
        overUCColumnData = columnsData?.[overUCColumnIndex];
        overUCColumnTitle = overUCColumnData?.title;
        canMoveUCToOverUCColumn = overUCColumnData?.moveCardFromAndTo;
        differentColumns = activeUCColumnIndex !== overUCColumnIndex;
        isOverUCColumnReject = overUCColumnIndex === columnsData?.length - 2; // reject - since reject is the last second stage.

        if (differentColumns) {
          // Allowing card movement only if its to a different column.
          if (canMoveUCToOverUCColumn) {
            if (isOverUCColumnReject) {
              // For reject we cant directly drop we first ask for confirmation.
              setUserCardActionType("reject");
              setUserCardDataForAction({
                ...activeUCData,
                currentStageType: columnTypes[activeUCColumnIndex],
                currentUserCardColumnIndex: activeUCColumnIndex,
                updatedColumnIndex: overUCColumnIndex,
              });
              setUserCardActionDialogOpen(true);
            } else {
              moveConsultantAPICall(
                activeUCData,
                activeUCColumnIndex,
                overUCColumnIndex,
                overUCIndex // passing over card index as well to indicate that the active card has to be moved over below this card in the over column.
              );
            }
          } else {
            enqueueSnackbar(
              `You cannot move the consultant to ${overUCColumnTitle} stage.`,
              {
                variant: "info",
                autoHideDuration: 3000,
                action: (key) => (
                  <CloseIcon
                    onClick={() => closeSnackbar(key)}
                    style={{
                      cursor: "pointer",
                      fontSize: "15",
                      marginTop: "-1px",
                    }}
                  />
                ),
              }
            );
          }
        }
      }
    }
  };

  useEffect(() => {
    if (!jobsBuf?.length) {
      dispatch(getMyJobsThunk())
        .unwrap()
        .then((res) => {
          if (res && isValidArray(res?.data)) setPostedJobs(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    } else setPostedJobs(jobsBuf);

    getJobDetail(jobID)
      .then((res) => {
        setJobData(res?.data);
      })
      .catch((error) => {
        // console.log(error, "Error in fetching job details");
      });

    // write the logic to check if data already exists in redux store after the state values are corrected(Above value accessed in personalDataBuf).
    // dispatch(getEmployerInfoThunk())
    //   .unwrap()
    //   .then((res) => {
    //     const hRAPIKey =
    //       res?.data?.clientDetail?.orgDetails?.testConfigs?.hackerRankApiKey;

    //     if (isValidString(hRAPIKey)) setHRAPIKeyExists(true);
    //   })
    //   .catch((err) => {});

    getHackerRankTests()
      .then((res) => {
        // if (isValidArray(res?.data)){
        setHRAPIKeyExists(true);
        // }
      })
      .catch((err) => {});

    getHiringBoardOfJob(jobID)
      .then((response) => {
        let columnsDataReceived = response?.data;
        if (isValidArray(columnsDataReceived)) {
          let totalUsers = 0;
          columnsDataReceived?.forEach((column) => {
            const columnUserData = isValidArray(column?.users)
              ? column?.users
              : [];
            totalUsers += columnUserData?.length;
          });

          setColumnsData(columnsDataReceived);
          setTotalApplicants(totalUsers);
        } else setError(true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });

    /** Using dummy data- START*/
    // let columnsDataReceived = dummyColumnsData;
    // let totalUsers = 0;
    // columnsDataReceived?.forEach((column) => {
    //   const columnUserData = isValidArray(column?.users) ? column?.users : [];
    //   totalUsers += columnUserData?.length;
    // });
    // setLoading(false);

    // setColumnsData(columnsDataReceived);
    // setTotalApplicants(totalUsers);
    /** Using dummy data- END*/
  }, []);

  useEffect(() => {
    const allColumnNames = columnsData?.map(
      (columnData, columnIndex) => columnData?.title
    );
    setColumnNames(allColumnNames);
    // // console.log({ columnsData });
  }, [columnsData]);

  useEffect(() => {
    // // console.log({ activeColumnData, activeCardData });
  }, [activeColumnData, activeCardData]);

  useEffect(() => {
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
  }, [dispatch]);

  useEffect(() => {
    if (isValidArray(postedJobs)) {
      const activeJobsWithJobNoAndTitle = [];
      let selectedJob;
      postedJobs.forEach((jobEntry) => {
        if (jobEntry?.status === "active") {
          activeJobsWithJobNoAndTitle.push({
            ...jobEntry,
            jobNoAndTitle: `Job ${jobEntry?.jobNo}: ${jobEntry?.jobTitle}`,
          });
        }
      });

      if (isValidArray(activeJobsWithJobNoAndTitle)) {
        selectedJob = activeJobsWithJobNoAndTitle.find(
          (jobEntry) => jobEntry?._id === jobID
        );
        setActiveJobs(activeJobsWithJobNoAndTitle);
        setSelectedJob(selectedJob);
      }
    }
  }, [postedJobs]);

  return (
    <div className={classes.hiringBoardContainer}>
      <Title title="Hiring Board" />
      <div
        className={clsx([classes.board, loading && classes.containerLoading])}
      >
        {loading ? (
          <ContentLoader
            speed={2}
            width={1400}
            height={500}
            viewBox="0 0 1400 500"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="3" ry="3" width="1400" height="70" />
            <rect x="0" y="90" rx="3" ry="3" width="262" height="410" />
            <rect x="287" y="90" rx="3" ry="3" width="262" height="410" />
            <rect x="574" y="90" rx="3" ry="3" width="262" height="410" />
            <rect x="861" y="90" rx="3" ry="3" width="262" height="410" />
            <rect x="1148" y="90" rx="3" ry="3" width="262" height="410" />
          </ContentLoader>
        ) : (
          <>
            {error ? (
              <Alert severity="error" className="w-full">
                {messages?.GENERIC_ERROR_MESSAGE}{" "}
              </Alert>
            ) : (
              <div>
                <div className="py-[20px] pl-[24px] pr-[60px]">
                  <div className={classes.backActionDiv}>
                    <ArrowBackIcon
                      className={classes.backIcon}
                      onClick={() => {
                        history.goBack();
                      }}
                    />
                    <Typography className={classes.backText}>Back</Typography>
                  </div>
                  <div className="flex gap-x-[106px]">
                    <div className={classes.jobActions}>
                      {isValidArray(activeJobs) ? (
                        <JobSelector
                          activeJobs={activeJobs}
                          selectedJob={selectedJob}
                          onJobChange={onJobChange}
                        />
                      ) : (
                        <></>
                      )}

                      <CustomButton
                        customClasses={classes.jobDetailsButton}
                        onClick={() => {
                          history.push(`/client/job-detail/${jobID}`);
                        }}
                      >
                        <span>Job Details</span>{" "}
                        <CallMadeOutlinedIcon fontSize="small" />
                      </CustomButton>
                    </div>
                    <div className={classes.jobApplicantsDetails}>
                      <div>
                        <Typography className={classes.jADetailType}>
                          OPEN POSITIONS
                        </Typography>
                        <Typography className={classes.jADetailValue}>
                          {jobData?.openPositions ?? "-"}
                        </Typography>
                      </div>
                      <div>
                        <Typography className={classes.jADetailType}>
                          TOTAL APPLICANTS
                        </Typography>
                        <Typography className={classes.jADetailValue}>
                          {totalApplicants ?? "-"}
                        </Typography>
                      </div>
                      <div>
                        <Typography className={classes.jADetailType}>
                          ACCEPTED
                        </Typography>
                        <Typography className={classes.jADetailValue}>
                          {getAcceptedUsersCount()}
                        </Typography>
                      </div>
                    </div>
                    <div>
                      <CustomTooltip
                        title="This button adds a stage before the Offered stage."
                        arrow
                        placement="bottom"
                      >
                        <div>
                          <CustomButton
                            type="button1"
                            customClasses={clsx([
                              classes.aROrALButton,
                              classes.addRoundButton,
                            ])}
                            onClick={() => {
                              const offeredColumnIndex = columnsData?.findIndex(
                                (columnData) => columnData?.type == "offered"
                              );
                              setAddColumnDialogOpen(true);
                              setAddColumnIndex(offeredColumnIndex);
                              // Adding before offered column for now
                            }}
                            disabled={(isTrial && !isSubscribedByAdmin)}
                          >
                            <AddOutlinedIcon fontSize="small" />
                            Add Stage
                          </CustomButton>
                        </div>
                      </CustomTooltip>
                      <CustomTooltip
                        title="Feature available in next release."
                        arrow
                        placement="bottom"
                      >
                        <div>
                          <CustomButton
                            customClasses={clsx([
                              classes.aROrALButton,
                              classes.activityLogButton,
                            ])}
                            disabled={true}
                          >
                            Activity Log
                          </CustomButton>
                        </div>
                      </CustomTooltip>
                    </div>
                  </div>
                </div>
                <CustomTooltip
                  title={
                    (isTrial && !isSubscribedByAdmin)
                      ? "Access to this feature is exclusive to our premium subscription plans. Upgrade now to enjoy all the benefits!"
                      : ""
                  }
                  arrow
                >
                  <div>
                    <DndContext
                      onDragStart={dndOnDragStart}
                      onDragEnd={dndOnDragEnd}
                      sensors={(isTrial && !isSubscribedByAdmin) ? [] : sensors}
                    >
                      <div className={classes.hiringBoardBody}>
                        <SortableContext items={columnTypes}>
                          <div className={classes.kanbanView}>
                            {columnsData?.map((column, index) => (
                              <>
                                <KanbanColumn
                                  key={index}
                                  columnData={column}
                                  columnIndex={index}
                                  onMoveColumn={onMoveColumn}
                                  onColumnEdit={onColumnEdit}
                                  onColumnDelete={onColumnDelete}
                                  onUserCardActionClick={onUserCardActionClick}
                                  otherColumnNames={columnNames?.filter(
                                    (columnName) => columnName !== column?.title
                                  )}
                                  isPreviousColumnMovable={
                                    columnsData?.[index - 1]?.movable
                                  } // useful for the move stage actions.
                                  isNextColumnMovable={
                                    columnsData?.[index + 1]?.movable
                                  } // useful for the move stage actions.
                                  nextColumnType={
                                    columnsData?.[index + 1]?.type
                                  } // Specifically useful to identify last meeting/test stage.
                                  additionalData={{
                                    experiences,
                                    jobData,
                                    hRAPIKeyExists,
                                  }}
                                />
                                {showAddColumn(
                                  column?.type,
                                  column?.movable
                                ) && (
                                  <div
                                    className={`bg-[black] ${
                                      (isTrial && !isSubscribedByAdmin)
                                        ? "cursor-not-allowed"
                                        : "cursor-pointer"
                                    } h-fit`}
                                    onClick={() => {
                                      if (!(isTrial && !isSubscribedByAdmin)) {
                                        setAddColumnDialogOpen(true);
                                        setAddColumnIndex(index + 1);
                                      }
                                    }}
                                  >
                                    <AddOutlinedIcon
                                      fontSize="small"
                                      className="text-[white]"
                                    />
                                  </div>
                                )}
                              </>
                            ))}
                          </div>
                        </SortableContext>
                      </div>
                      <DragOverlay>
                        {activeColumnData && (
                          <KanbanColumn {...activeColumnData} />
                        )}
                        {activeCardData && <UserCard {...activeCardData} />}
                      </DragOverlay>
                    </DndContext>
                  </div>
                </CustomTooltip>
                <CustomDialog
                  open={addColumnDialogOpen}
                  setOpen={setAddColumnDialogOpen}
                  title={
                    <div className="text-[16px] font-semibold text-center">
                      Add Stage
                    </div>
                  }
                  content={
                    <AddColumnDialogContent
                      onAddColumn={onAddColumn}
                      columnNames={columnNames}
                      onDialogClose={() => {
                        setAddColumnDialogOpen(false);
                      }}
                    />
                  }
                />
                <CustomDialog
                  open={userCardActionDialogOpen}
                  setOpen={setUserCardActionDialogOpen}
                  title={
                    <div className="text-[16px] font-semibold text-center">
                      {userCardActionDialogTitles[userCardActionType] ??
                        "Action"}
                    </div>
                  }
                  content={
                    <CardActionDialogContent
                      setUserCardActionDialogOpen={setUserCardActionDialogOpen}
                      actionType={userCardActionType}
                      userData={userCardDataForAction}
                      jobID={jobID}
                      onReject={onRejectConsultant}
                    />
                  }
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

HiringProcessKanbanBoard.propTypes = {};

export default HiringProcessKanbanBoard;
