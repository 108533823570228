import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Pagination,
  FormControl,
  FormHelperText,
  IconButton,
  Menu,
  MenuItem,
  Fade,
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
  Input,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DatePicker from "react-datepicker";
import ContentLoader from "react-content-loader";
import clsx from "clsx";

import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InfoIcon from "@mui/icons-material/Info";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

import Title from "../../common/Title";

import CustomButton from "app/v2/components/CustomButton";
import CustomDialog from "app/v2/components/CustomDialog";
import CustomAutocomplete from "app/v2/components/filters/CustomAutocomplete";
import CustomTooltip from "app/v2/components/CustomTooltip";

import DialogLoadingBody from "../../common/DialogLoadingBody";
import DialogSuccessBody from "../../common/DialogSuccessBody";
import DialogErrorBody from "../../common/DialogErrorBody";

import {
  isValidArray,
  isValidObject,
  isValidString,
} from "../../Utils/utilFunctions";
import { messages, validDocFileMimeTypes } from "../../Utils/constants";

// import {
//   updateDocuments,
// addDocuments,
// deleteDocument,
// getDocumentTypes,
// getDocuments,
// } from "./Document.service";

import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import { useSnackbar } from "notistack";
import { s3Upload } from "app/v2/services/miscService";
import useFetch from "app/common/utils/useFetch";
import FilePreview from "../../common/FilePreview";
import CustomSelect from "app/v2/components/filters/CustomSelect";
import FilePreview2 from "../../common/FilePreview2";
import {
  updateDocuments,
  addDocuments,
  deleteDocument,
  getDocumentTypes,
  getDocuments,
} from "../agencyApiServices/agency.services";

const useStyles = makeStyles((theme) => ({
  checkboxLabel: {
    fontSize: "1rem", // Adjust the font size as needed
    fontWeight: "bold",
    padding: "0.5rem", // Adjust the padding as needed
  },
  searchBoxOuterDiv: {
    maxWidth: "477px",
    width: "100%",
    display: "flex",
    columnGap: "12px",
    alignItems: "center",
  },
  searchBox: {
    width: "100%",
  },
  tags: {
    width: "100%",
    // height: "90%",
  },
  userImage: {
    width: "36px",
    height: "36px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
  consultantCell: {
    display: "flex !important",
    columnGap: "8px",
    alignItems: "center",
  },
  viewDetailsButton: {
    fontWeight: 600,
    fontSize: "14px",
  },
  paginationContainer: {
    float: "right",
    marginBottom: "16px",
    paddingRight: "38px",
  },
  actionMenu: {
    "& .MuiPaper-root": {
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
  },
  actionMenuItem: {
    fontWeight: 600,
    fontSize: "14px",
  },
  dialogBody: {
    boxSizing: "content-box",
    minWidth: "486px",
    padding: "48px 84px",
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
  },
  dialogActionButton: {
    fontWeight: 600,
    padding: "16px 28px",
  },
  viewButton: {
    fontWeight: 600,
    textWrap: "nowrap",
  },
  dateOrTimePicker: {
    width: "100% !important",
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        fontSize: "16px",
      },
    },
  },
  dateOrTimePickerError: {
    "& .MuiInputBase-root": {
      border: "1px solid red",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        border: "1px solid red",
      },
    },
  },

  tableHead: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: "#F7F7F7",
  },
  uploadButton: {
    backgroundColor: "#07121f !important",
    width: "fit-content",
    color: "white",
    padding: theme.spacing(1), // Adjust padding as needed
    fontSize: "16px", // Adjust font size as needed
  },
  submitUploadButton: {
    backgroundColor: "#07121f !important",

    fontWeight: 600,
    margin: "auto",
  },
  fileUploadInput: {
    display: "none",
  },
  uploadingButton: {
    marginLeft: theme.spacing(2),
  },
  docButton: {
    padding: "10px",
    border: "1px solid #EDEDED",
  },
}));

const Documents = () => {
  const classes = useStyles();

  const uploadRef = useRef(null);
  const [searchTextTags, setSearchTextTags] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [get] = useFetch();
  const [contractDialogOpen, setContractDialogOpen] = useState(false);
  const [actionType, setActionType] = useState("");
  const [updateContractDetails, setUpdateContractDetails] = useState({});
  const [actionEntryData, setActionEntryData] = useState({});
  const [documentTypeData, setDocumentTypeData] = useState([]);
  const [updateGetDocumentsApi, setUpdateGetDocumentsApi] = useState(true);

  const contractColumns = [
    "FileName",
    "DocumentType",
    "File",
    "Tags",
    "Action",
  ];
  const dummyContractData = [
    {
      id: "123456789",
      fileName: "Contract-1",
      file: "texctDocument.pdf",
      tags: ["React", "Node"],
      visibility: ["client", "consultant"],
    },
    {
      id: "000000000",

      fileName: "Contract-2",
      file: "texctDocument2.pdf",
      tags: ["React", "Node"],
      visibility: ["client", "consultant,agency"],
    },
    {
      id: "78678678",

      fileName: "Contract-3",
      file: "texctDocument3.pdf",
      tags: ["React", "Node", "tag3", "tag4", "tag5"],
      visibility: ["client", "consultant,agency"],
    },
  ];

  // const documentTypeData = [
  //   "Contract",
  //   "Aggrement",
  //   "Employee",
  //   "HandBook",
  //   "Id",
  // ];
  const getFullName = (consultantDetails) => {
    const firstName = consultantDetails?.firstName;
    const lastName = consultantDetails?.lastName;
    let fullName = "";
    if (firstName) fullName += firstName + " ";
    if (lastName) fullName += lastName;
    return fullName;
  };
  const AddUpdateContract = ({ selectedContractId, actionType }) => {
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [documentType, setDocumentType] = useState(
      updateContractDetails?.documentType ?? ""
    );

    const [filename, setFilename] = useState(
      updateContractDetails?.fileName ?? ""
    );
    const [tags, setTags] = useState(updateContractDetails?.tags ?? []);
    const [visibilityOptions, setVisibilityOptions] = useState(
      updateContractDetails?.visibility ?? []
    );
    const [selectedFile, setSelectedFile] = useState(
      updateContractDetails?.file ?? ""
    );
    const [isSubmitDisabled, setSubmitDisabled] = useState(true);
    const handleCheckboxChange = (value) => {
      const updatedCheckboxes = [...selectedCheckboxes];
      const index = updatedCheckboxes.indexOf(value);

      if (index === -1) {
        updatedCheckboxes.push(value);
      } else {
        updatedCheckboxes.splice(index, 1);
      }

      setSelectedCheckboxes(updatedCheckboxes);
    };

    const handleVisibilityCheckboxChange = (option) => {
      setVisibilityOptions((prevOptions) => {
        if (prevOptions.includes(option)) {
          return prevOptions.filter((item) => item !== option);
        } else {
          return [...prevOptions, option];
        }
      });
    };

    const onFileUploadToS3 = async (file) => {
      let s3UploadedFileDetails;
      let errorInUpload = false;
      const formData = new FormData();
      formData.append("file", file);
      try {
        s3UploadedFileDetails = await get(s3Upload(formData));
      } catch (err) {
        errorInUpload = true;
      }

      return {
        uploadedUrl: s3UploadedFileDetails?.[0]?.location,
        errorInUpload,
      };
    };

    const handleDocumentType = (id, value) => {
      setDocumentType(value);
    };
    const handleFileUpload = async (event) => {
      let file = event.target.files[0];

      if (file) {
        // const localFileUrl = URL.createObjectURL(file);
        const higherFileSize = file.size > 1048576 * 5; // 5 mb
        const isValidDoc = validDocFileMimeTypes.includes(file.type);
        if (higherFileSize || !isValidDoc) {
          // enqueueSnackbar(
          // higherFileSize
          //   ? messages.DOC_OR_VIDEO_FILE_SIZE_WARNING_OR_INFO
          //   : messages.RESUME_DOC_UPLOAD_EXTENSION_WARNING,
          //   {
          //     variant: "error",
          //   }
          // );

          const key = enqueueSnackbar(
            higherFileSize
              ? messages.DOC_OR_VIDEO_FILE_SIZE_WARNING_OR_INFO
              : messages.RESUME_DOC_UPLOAD_EXTENSION_WARNING,
            {
              variant: "error",
              autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon
                  onClick={() => closeSnackbar(key)}
                  style={{
                    cursor: "pointer",
                    fontSize: "15",
                    marginTop: "-1px",
                  }}
                />
              ),
            }
          );
          return;
        } else {
          let result = await onFileUploadToS3(file);
          setSelectedFile(result?.uploadedUrl);
        }
      }
    };

    const handleCancelUpload = () => {
      setSelectedFile("");
      if (uploadRef.current) {
        uploadRef.current.value = null;
      }
    };

    const handleFilenameChange = (id, value) => {
      setFilename(value);
    };

    const handleTagsChange = (id, value) => {
      setTags(value);
    };

    useEffect(() => {
      const isFormValid =
        // selectedCheckboxes.length > 0 &&
        filename.trim() !== "" &&
        tags.length > 0 &&
        // visibilityOptions.length > 0 &&
        selectedFile !== "" &&
        (actionType === "add" ? documentType !== "" : true);

      setSubmitDisabled(!isFormValid);
    }, [
      documentType,
      selectedCheckboxes,
      filename,
      tags,
      visibilityOptions,
      selectedFile,
    ]);

    useEffect(() => {
      setSubmitDisabled(true);
    }, []);

    const handleSubmission = () => {
      // const formData = new FormData();

      // // Add selected checkboxes to formData
      // selectedCheckboxes.forEach((checkbox) => {
      //   formData.append("selectedCheckboxes", checkbox);
      // });

      // // Add other data to formData
      // formData.append("filename", filename);
      // formData.append("tags", tags);

      // // Add visibility options to formData
      // visibilityOptions.forEach((option) => {
      //   formData.append("visibilityOptions[]", option);
      // });

      // // Add the selected file to formData
      // if (selectedFile) {
      //   formData.append("file", selectedFile);
      // }

      const key = enqueueSnackbar(`Your action in progress.Please wait.  `, {
        variant: "info",
        autoHideDuration: 3000,
        action: (key) => (
          <CloseIcon
            onClick={() => closeSnackbar(key)}
            style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
          />
        ),
      });
      // const activeconsultantId = actionEntryData?._id ?? "";
      // const clientId = actionEntryData?.orgId ?? "";
      // const consultantId = actionEntryData?.consultantId ?? "";

      // const selectedIds = [];

      // if (selectedCheckboxes.includes("client")) {
      //   selectedIds.push(clientId);
      // }

      // if (selectedCheckboxes.includes("consultant")) {
      //   selectedIds.push(consultantId);
      // }

      // if (selectedCheckboxes.includes("clientConsultant")) {
      //   selectedIds.push(clientId, consultantId);
      // }
      // if (selectedCheckboxes.includes("consultantAgency")) {
      //   selectedIds.push(clientId, consultantId);
      // }

      const requestBody = {
        documentType: documentType,
        file: selectedFile || "",
        fileName: filename,
        tags: tags,
        // activeWorkId: activeconsultantId,
        // userIds: selectedIds,
        // userIds: [clientId, consultantId],

        // visibility: visibilityOptions,
      };
      addDocuments(requestBody)
        .then((res) => {
          if (res?.success === true) {
            const key = enqueueSnackbar("Your action was successfull", {
              variant: "success",
              autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon
                  onClick={() => closeSnackbar(key)}
                  style={{
                    cursor: "pointer",
                    fontSize: "15",
                    marginTop: "-1px",
                  }}
                />
              ),
            });
            setSubmitDisabled(true);
            setUpdateGetDocumentsApi((prev) => !prev);
            setContractDialogOpen(false);

            // setDialogOpen(false);
            // setCurrentPage(1);
            // fetchConsultants(1);
          }
        })
        .catch(() => {
          const key = enqueueSnackbar(
            `Your action was not successful. Please try again later.`,
            {
              variant: "error",
              autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon
                  onClick={() => closeSnackbar(key)}
                  style={{
                    cursor: "pointer",
                    fontSize: "15",
                    marginTop: "-1px",
                  }}
                />
              ),
            }
          );
        });
    };

    const handleUpdateDocument = (documentId) => {
      // const formData = new FormData();

      // // Add selected checkboxes to formData
      // selectedCheckboxes.forEach((checkbox) => {
      //   formData.append("selectedCheckboxes", checkbox);
      // });

      // // Add other data to formData
      // formData.append("filename", filename);
      // formData.append("tags", tags);

      // // Add visibility options to formData
      // visibilityOptions.forEach((option) => {
      //   formData.append("visibilityOptions[]", option);
      // });

      // // Add the selected file to formData
      // if (selectedFile) {
      //   formData.append("file", selectedFile);
      // }

      const key = enqueueSnackbar(`Your action in progress.Please wait.  `, {
        variant: "info",
        autoHideDuration: 3000,
        action: (key) => (
          <CloseIcon
            onClick={() => closeSnackbar(key)}
            style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
          />
        ),
      });

      // const activeconsultantId = actionEntryData?._id;
      // const clientId = actionEntryData?.orgId;
      // const consultantId = actionEntryData?.consultantId;

      // const selectedIds = [];

      // if (selectedCheckboxes.includes("client")) {
      //   selectedIds.push(clientId);
      // }

      // if (selectedCheckboxes.includes("consultant")) {
      //   selectedIds.push(consultantId);
      // }

      // if (selectedCheckboxes.includes("clientConsultant")) {
      //   selectedIds.push(clientId, consultantId);
      // }
      // if (selectedCheckboxes.includes("consultantAgency")) {
      //   selectedIds.push(clientId, consultantId);
      // }

      const requestBody = {
        file: selectedFile || "",
        fileName: filename,
        tags: tags,
        documentType: documentType,
        // activeWorkId: activeconsultantId,
        // userIds: selectedIds,
        // userIds: [clientId, consultantId],

        // visibility: visibilityOptions,
      };
      updateDocuments(requestBody, documentId)
        .then((res) => {
          if (res?.success === true) {
            const key = enqueueSnackbar("Your action was successfull", {
              variant: "success",
              autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon
                  onClick={() => closeSnackbar(key)}
                  style={{
                    cursor: "pointer",
                    fontSize: "15",
                    marginTop: "-1px",
                  }}
                />
              ),
            });
            setUpdateGetDocumentsApi((prev) => !prev);

            setSubmitDisabled(true);
            // setDialogOpen(false);
            setContractDialogOpen(false);
            // setCurrentPage(1);
            // fetchConsultants(1);
          }
        })
        .catch(() => {
          setContractDialogOpen(false);
          const key = enqueueSnackbar(
            `Your action was not successful. Please try again later.`,
            {
              variant: "error",
              autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon
                  onClick={() => closeSnackbar(key)}
                  style={{
                    cursor: "pointer",
                    fontSize: "15",
                    marginTop: "-1px",
                  }}
                />
              ),
            }
          );
        });
    };

    const handleDeleteDocument = (documentId) => {
      const key = enqueueSnackbar(`Your action in progress.Please wait.  `, {
        variant: "info",
        autoHideDuration: 3000,
        action: (key) => (
          <CloseIcon
            onClick={() => closeSnackbar(key)}
            style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
          />
        ),
      });

      // const activeconsultantId = actionEntryData?._id;
      // const clientId = actionEntryData?.orgId;
      // const consultantId = actionEntryData?.consultantId;

      // const requestBody = {
      //   file: selectedFile || "",
      //   fileName: filename,
      //   tags: tags,
      //   activeWorkId: activeconsultantId,
      //   // userIds: selectedIds,
      //   userIds: [clientId, consultantId],

      //   visibility: visibilityOptions,
      // };
      deleteDocument(documentId)
        .then((res) => {
          if (res?.success === true) {
            const key = enqueueSnackbar("Your action was successfull", {
              variant: "success",
              autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon
                  onClick={() => closeSnackbar(key)}
                  style={{
                    cursor: "pointer",
                    fontSize: "15",
                    marginTop: "-1px",
                  }}
                />
              ),
            });
            setUpdateGetDocumentsApi((prev) => !prev);
            setContractDialogOpen(false);

            // setDialogOpen(false);
            // setCurrentPage(1);
            // fetchConsultants(1);
          }
        })
        .catch(() => {
          // setDialogOpen(false);

          const key = enqueueSnackbar(
            `Your action was not successful. Please try again later.`,
            {
              variant: "error",
              autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon
                  onClick={() => closeSnackbar(key)}
                  style={{
                    cursor: "pointer",
                    fontSize: "15",
                    marginTop: "-1px",
                  }}
                />
              ),
            }
          );
          setContractDialogOpen(false);
        });
    };

    return (
      <div className="flex flex-col gap-y-[16px] flex-wrap ">
        {actionType === "delete" && (
          <>
            Are you sure you want to delete
            {getFullName(actionEntryData?.consultantDetails)} contract
            <div className="flex gap-y-[8px] justify-end mt-[16px]">
              <CustomButton
                customClasses={classes.dialogActionButton}
                onClick={() => {
                  setContractDialogOpen(false);
                  setActionType("");
                }}
              >
                No
              </CustomButton>

              <CustomButton
                type="button1"
                customClasses={classes.dialogActionButton}
                onClick={() => {
                  handleDeleteDocument(updateContractDetails?._id);
                  setActionType("");
                }}
              >
                Yes
              </CustomButton>
            </div>
          </>
        )}{" "}
        {actionType === "update" && (
          <>
            <CustomSelect
              id="documentType"
              name="documentType"
              data={documentTypeData}
              label="Document Type"
              // value={documentType}
              onChange={handleDocumentType}
              defaultSelection={documentType}
            />
            <div className=" flex  justify-center">
              <input
                type="file"
                accept=".pdf, .txt"
                className={classes.fileUploadInput}
                onChange={handleFileUpload}
                ref={uploadRef}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.uploadButton}
                onClick={() => uploadRef.current.click()}
              >
                <div>Upload File</div>
                <FileUploadOutlinedIcon fontSize="small" />
              </Button>
            </div>
            <div className="flex justify-center">
              {selectedFile && (
                <div className="flex  flex-col items-center gap-x-2">
                  <CloseIcon
                    style={{ cursor: "pointer", marginLeft: "auto" }}
                    onClick={handleCancelUpload}
                  />
                  <div>
                    {" "}
                    <FilePreview fileUrlOrContent={selectedFile} />
                  </div>
                </div>
              )}
            </div>

            <div className="flex space-x-2">
              <div className="w-full ">
                <CustomTextfield
                  id="fileName"
                  label="Filename"
                  value={filename}
                  onChange={handleFilenameChange}
                />
              </div>
              <div className="w-full h-full">
                <label className="text-xs font-montserrat font-semibold  uppercase">
                  Tags
                </label>
                <CustomAutocomplete
                  id="tags"
                  data={tags}
                  placeholder="Tags"
                  defaultSelection={tags}
                  allowMultipleSelection={true}
                  allowCustomUserInput={true}
                  onChange={(e, val) => {
                    setTags(val);
                  }}
                  customClasses={classes.tags}
                  // showSearchIcon={true}
                />
              </div>
            </div>

            {/* Visibility Checkboxes */}
            {/* <label className="text-xl font-bold">Visibility:</label>

            <div className=" ">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={visibilityOptions.includes("client")}
                    onChange={() => handleVisibilityCheckboxChange("client")}
                  />
                }
                label="Client"
                classes={{ label: classes.checkboxLabel }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={visibilityOptions.includes("consultant")}
                    onChange={() =>
                      handleVisibilityCheckboxChange("consultant")
                    }
                  />
                }
                label="Consultant"
                classes={{ label: classes.checkboxLabel }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={visibilityOptions.includes("agency")}
                    onChange={() => handleVisibilityCheckboxChange("agency")}
                  />
                }
                label="Agency"
                classes={{ label: classes.checkboxLabel }}
              />
            </div> */}

            {/* Update Button */}
            <div className="flex justify-center">
              <CustomButton
                type="button1"
                customClasses={`text-[16px] font-semibold py-2 px-2 flex gap-x-[8px] ${
                  isSubmitDisabled
                    ? "cursor-not-allowed opacity-50"
                    : "cursor-pointer"
                }`}
                onClick={() => handleUpdateDocument(updateContractDetails?._id)}
                disabled={isSubmitDisabled}
              >
                Update{" "}
              </CustomButton>
            </div>
          </>
        )}
        {actionType === "add" && (
          <>
            <CustomSelect
              id="documentType"
              name="documentType"
              data={documentTypeData}
              label="Document Type"
              // value={documentType}
              onChange={handleDocumentType}
              // defaultSelection={}
            />
            <div className=" flex  justify-center">
              <input
                type="file"
                accept=".pdf, .txt"
                className={classes.fileUploadInput}
                onChange={handleFileUpload}
                ref={uploadRef}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.uploadButton}
                onClick={() => uploadRef.current.click()}
              >
                <div>Upload File</div>
                <FileUploadOutlinedIcon fontSize="small" />
              </Button>
            </div>
            <div className="flex justify-center">
              {selectedFile && (
                <div className="flex  flex-col items-center gap-x-2">
                  <CloseIcon
                    style={{ cursor: "pointer", marginLeft: "auto" }}
                    onClick={handleCancelUpload}
                  />
                  <div>
                    {" "}
                    <FilePreview2 fileUrlOrContent={selectedFile} />
                  </div>
                </div>
              )}
            </div>

            <div className="flex space-x-2">
              <div className="w-full ">
                <CustomTextfield
                  id="fileName"
                  label="Filename"
                  value={filename}
                  onChange={handleFilenameChange}
                />
              </div>
              <div className="w-full h-full">
                <label className="text-xs font-montserrat font-semibold  uppercase">
                  Tags
                </label>
                <CustomAutocomplete
                  id="tags"
                  data={tags}
                  placeholder="Tags"
                  defaultSelection={tags}
                  allowMultipleSelection={true}
                  allowCustomUserInput={true}
                  onChange={(e, val) => {
                    setTags(val);
                  }}
                  customClasses={classes.tags}
                  // showSearchIcon={true}
                />
              </div>
            </div>

            {/* Visibility Checkboxes */}
            {/* <label className="text-xl font-bold">Visibility:</label>

            <div className=" ">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={visibilityOptions.includes("client")}
                    onChange={() => handleVisibilityCheckboxChange("client")}
                  />
                }
                label="Client"
                classes={{ label: classes.checkboxLabel }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={visibilityOptions.includes("consultant")}
                    onChange={() =>
                      handleVisibilityCheckboxChange("consultant")
                    }
                  />
                }
                label="Consultant"
                classes={{ label: classes.checkboxLabel }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={visibilityOptions.includes("agency")}
                    onChange={() => handleVisibilityCheckboxChange("agency")}
                  />
                }
                label="Agency"
                classes={{ label: classes.checkboxLabel }}
              />
            </div> */}

            {/* Submit Button */}
            <div className="flex justify-center">
              <CustomButton
                type="button1"
                customClasses={`text-[16px] font-semibold py-2 px-2 flex gap-x-[8px] ${
                  isSubmitDisabled
                    ? "cursor-not-allowed opacity-50"
                    : "cursor-pointer"
                }`}
                onClick={handleSubmission}
                disabled={isSubmitDisabled}
              >
                Add
              </CustomButton>
            </div>
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    getDocumentTypes().then((res) => {
      const values = Object.values(res?.data);
      setDocumentTypeData(values);
    });
  }, []);

  useEffect(() => {
    getDocuments()
      .then((res) => {
        if (res?.success) {
          setActionEntryData(res?.data);
        }
      })
      .catch(() => {
        const key = enqueueSnackbar(
          `Problem in retrieving list of documents. Please try again later.`,
          {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{
                  cursor: "pointer",
                  fontSize: "15",
                  marginTop: "-1px",
                }}
              />
            ),
          }
        );
      });
  }, [updateGetDocumentsApi]);
  //// console.log("actionEntryData", actionEntryData);

  return (
    <div className="flex flex-col gap-y-[16px]   flex-wrap ">
      <div className="flex justify-between w-full">
        <h2 className="text-xl font-bold ">Documents</h2>
        {/* <div className="flex gap-[16px]">
          <button className={classes.docButton}>
            <img alt="" src="/logosdropbox.svg" />
          </button>
          <button className={classes.docButton}>
            <img alt="" src="/logosgoogledrive.svg" />
          </button>
          <button className={classes.docButton}>
            <img alt="" src="/logosmicrosoftonedrive.svg" />
          </button>
          <button
            onClick={() => {
              setContractDialogOpen(true);
              setActionType("add");
              setUpdateContractDetails({});
            }}
            className={classes.docButton}
          >
            <img alt="Local File" src="/folder-file.svg" />
          </button>
        </div> */}
        <CustomButton
          onClick={() => {
            setContractDialogOpen(true);
            setActionType("add");
            setUpdateContractDetails({});
          }}
          type="button1"
          customClasses=" py-2 mb-2 mx-auto w-full !important "
          // disabled={isSubmitDisabled}
        >
          Add new document
        </CustomButton>
      </div>
      <TableContainer
        sx={{
          width: "auto",
          maxHeight: 440,
          margin: "0 24px 24px 24px",
        }}
      >
        <Table stickyHeader aria-label="sticky table " >
          <TableHead>
            <TableRow>
              {contractColumns.map((column, index) => (
                <TableCell
                  key={`single-column-${index}`}
                  sx={{
                    backgroundColor: "#F7F7F7",
                    fontWeight: 600,
                    zIndex:0
                  }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {actionEntryData && actionEntryData?.length > 0 ? (
            actionEntryData?.map((contractValues) => (
              <React.Fragment key={contractValues?._id}>
                {isValidObject(contractValues) ? (
                  <TableRow sx={{ backgroundColor: "#F7F7F7" }}>
                    <TableCell>{contractValues?.fileName ?? "-"}</TableCell>
                    <TableCell>{contractValues?.documentType ?? "-"}</TableCell>

                    <TableCell>
                      {`${contractValues?.fileName.substring(
                        0,
                        10
                      )}.${contractValues?.file
                        .split("/")
                        .pop()
                        .split(".")
                        .pop()}`}
                    </TableCell>

                    {/* <TableCell>
                      {contractValues?.visibility &&
                        contractValues.visibility.join(", ")}
                    </TableCell> */}
                    <TableCell>
                      {contractValues?.tags &&
                      contractValues.tags.length > 3 ? (
                        <Tooltip title={contractValues.tags.join(", ")}>
                          <span>
                            {contractValues.tags.slice(0, 3).join(", ")}
                            {contractValues.tags.length > 3 ? "..." : ""}
                          </span>
                        </Tooltip>
                      ) : (
                        contractValues?.tags && contractValues.tags.join(", ")
                      )}
                    </TableCell>
                    <TableCell>
                      <div className="flex flex-row space-x-2">
                        <CustomButton
                          onClick={() => {
                            setContractDialogOpen(true);
                            setActionType("update");
                            setUpdateContractDetails(contractValues);
                          }}
                          type="button1"
                          customClasses=" py-2 mb-2 mx-auto w-full !important "
                          // disabled={isSubmitDisabled}
                        >
                          Update
                        </CustomButton>
                        <CustomButton
                          onClick={() => {
                            setActionType("delete");
                            setContractDialogOpen(true);
                            setUpdateContractDetails(contractValues);
                          }}
                          type="button1"
                          customClasses=" py-2 mb-2 mx-auto w-full !important "
                          // disabled={isSubmitDisabled}
                        >
                          Delete
                        </CustomButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  <React.Fragment />
                )}
              </React.Fragment>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5}>No data available</TableCell>
            </TableRow>
          )}
        </Table>
      </TableContainer>
      {/* <label className="text-xl font-bold">Document Type:</label> */}
      {/* Checkboxes */}
      {/* <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedCheckboxes.includes("client")}
            onChange={() => handleCheckboxChange("client")}
          />
        }
        label="Client"
        classes={{ label: classes.checkboxLabel }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedCheckboxes.includes("consultant")}
            onChange={() => handleCheckboxChange("consultant")}
          />
        }
        label="Consultant"
        classes={{ label: classes.checkboxLabel }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedCheckboxes.includes("agency")}
            onChange={() => handleCheckboxChange("agency")}
          />
        }
        label="Agency"
        classes={{ label: classes.checkboxLabel }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedCheckboxes.includes("clientConsultant")}
            onChange={() => handleCheckboxChange("clientConsultant")}
          />
        }
        label="Client and Consultant"
        classes={{ label: classes.checkboxLabel }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedCheckboxes.includes("consultantAgency")}
            onChange={() => handleCheckboxChange("consultantAgency")}
          />
        }
        label="Consultant and Agency"
        classes={{ label: classes.checkboxLabel }}
      />
    </div> */}
      <CustomDialog
        open={contractDialogOpen}
        setOpen={setContractDialogOpen}
        // noCloseAction={contractActionLoading}
        title={
          <div className="text-[16px] font-semibold text-center">
            {actionType === "add" && "Add Document"}
            {actionType === "update" && "Update Document"}
            {actionType === "delete" && "Delete Document"}
          </div>
        }
        content={
          <div className={classes.dialogBody}>
            <AddUpdateContract
              selectedContractId={""}
              actionType={actionType}
            />
          </div>
        }
      />{" "}
    </div>
  );
};

export default Documents;

// import React from "react";
// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles((theme) => ({
//   docButton: {
//     padding: "10px",
//     border: "1px solid #EDEDED",
//   },
// }));

// const Documents = () => {
//   const classes = useStyles();
//   return (
//     <div className="flex gap-[16px]">
//       <button className={classes.docButton}>
//         <img alt="" src="/logosdropbox.svg" />
//       </button>
//       <button className={classes.docButton}>
//         <img alt="" src="/logosgoogledrive.svg" />
//       </button>
//       <button className={classes.docButton}>
//         <img alt="" src="/logosmicrosoftonedrive.svg" />
//       </button>
//     </div>
//   );
// };

// export default Documents;
