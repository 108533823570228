import React from "react";
import PropTypes from "prop-types";
import { IconDot } from "app/v2/Pages/SVGs/IconDot";
import { Typography } from "@mui/material";

import { capitalizeFirstLetter, isValidString } from "../../Utils/utilFunctions";

const Proposal = ({ type }) => {
  const statusTypeInfo = {

    invited: {
      text: "Invited you to apply",
      textColor: "#1E5DFF",
      divColor: "#F4F8FF",
    },
    applied: {
      text: "Applied",
      textColor: "#1E5DFF",
      divColor: "#F4F8FF",
    }, // blue
    shortlisted: {
      text: "Application Shortlisted",
      textColor: "#FBBC05",
      divColor: "#FFF5D9",
    }, // yellow
    pending: {
      text: "Selection Pending",
      textColor: "#FBBC05",
      divColor: "#FFF5D9", // yellow
    },
    offered: {
      text: "Job Offered",
      textColor: "#14C042",
      divColor: "#EBFFF0",
    }, // green
    accepted: {
      text: "Job Offer Accepted",
      textColor: "#14C042",
      divColor: "#EBFFF0", // green
    },
    withdrew: {
      text: "Job Offer Withdrawn",
      textColor: "#D80027",
      divColor: "#FFECEA", // red
    },
    rejected: {
      text: "Job Offer Rejected",
      textColor: "#D80027",
      divColor: "#FFECEA", // red
    },
    custom: {
      // text:"", Add text of the custom stage(done below).
      text:capitalizeFirstLetter(type),
      textColor: "#533A71",
      // divColor: "#B760E7",
    },

    // testSent: {
    //   text: "Assessment Test Sent",
    //   textColor: "#FBBC05",
    //   divColor: "#FFF5D9",
    //   icon: <ErrorOutlineRoundedIcon className={classes.testSentIcon} />,
    // }, // yellow

    // draft: {
    //   text: "Draft",
    //   textColor: "#717171",
    //   divColor: "#F4F8FF",
    //   borderColor: "#717171",ccddd
    // },
  };
  let statusInfo;
  if (isValidString(type))
    statusInfo = statusTypeInfo[type] ?? statusTypeInfo["custom"];

  return (
    <>
      {statusInfo ? (
        <div
          className={`flex gap-x-[5px] items-center px-[8px] bg-[${
            statusInfo?.divColor
          }] ${
            statusInfo?.borderColor &&
            `border-[1px] border-solid border-[${statusInfo?.borderColor}]`
          }`}
        >
          {statusInfo?.icon ?? (
            <IconDot
              fillColor={statusInfo?.dotColor ?? statusInfo?.textColor}
            />
          )}
          <Typography
            style={{
              color: statusInfo?.textColor,
              fontSize: "12px",
              fontWeight: 700,
            }}
          >
            {statusInfo?.text}
          </Typography>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Proposal;
