import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";

export const getAllEmployeeInfo = async () => {
  return fetchWrapper(
    `${configs.APIUrl}/client/employee`
  );
};

export const getRoles = async () => {
  return fetchWrapper(
    `${configs.APIUrl}/client/role`
  );
};

export const reSendEmployeeInvitation = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/resendInvite`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const sendEmployeeInvitation = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/inviteEmployee`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const UpdateEmployeeDetails = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/updateEmployee`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

// export const updateEmployeeInfo_ = async (request) => {
//   return fetchWrapper(`${configs.APIUrl}/client/updateEmployee`, {
//     method: "PUT",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: request,
//   });
// };

// export const updateEmployerItAssessmentTokens_ = async (request) => {
//   // return fetchWrapper(`${configs.APIUrl}/employers/update-it-aasessment-token`, {
//   return fetchWrapper(`${configs.APIUrl}/client/update-it-aasessment-token`, {
//     method: "PUT",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: request,
//   });
// };
