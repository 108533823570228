import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import useToggleLoader from "../../Utils/useToggleLoader";
import configs from "../../Utils/configs";
import storageUtil from "../../Utils/storageUtil";
import { getAccountType, handleLinkedInAuth } from "./login.service";
import { loginUser } from "./login.thunk";
import { Dropdown, DropdownButton, Button, Form } from "react-bootstrap";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import banner from "app/v2/Pages/images/login-signup-banner.jpg";
import CloseIcon from "@mui/icons-material/Close";
import { NotificationContext } from "app/v2";

import {
  fetchExperiencesThunk,
  fetchContractTypesThunk,
  fetchIndustryThunk,
  fetchSkillsThunk,
  fetchSkillLevelsThunk,
} from "app/v2/store/features/misc/miscThunk";
import { setupNotificationHandling } from "app/v2";

const useStyles = makeStyles((theme) => ({
  leftView: {
    width: "617px",
    background: `url(${banner})`,
    boxShadow: "inset -11px 4px 36px rgba(218, 229, 255, 0.3)",
    borderRadius: 0,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
  },
  rightView: {},
  loginContainer: {
    // width: "508px",
    margin: "116px auto",
  },
  loginAsDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: "48px",
  },
  loginText: {
    fontSize: "16px",
    fontWeight: 500,
  },
  asAnText: {},
  menuTitle: {
    "& .dropdown-toggle": {
      textTransform: "capitalize",
    },
  },
  welcomeBackText: {
    fontSize: "36px",
    fontWeight: 600,
    marginBottom: "11px",
  },
  loginForgotPasswordDiv: {
    textAlign: "center",
  },
  loginButton: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    width: "100%",
    padding: "12px 28px",
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: "16px",
    "&:disabled": {
      backgroundColor: "grey !important",
    },
  },
  forgotPasswordLink: {
    fontSize: "14px",
    fontWeight: 600,
    color: theme.palette.common.black,
  },
  orTextAndSSODiv: {
    // width: "508px",
    margin: "auto",
  },
  orTextDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
  },
  line: {
    width: "100%",
    borderTop: "1px solid #EDEDED",
  },
  orText: {
    padding: "10px",
  },
  singleSignOnDiv: {
    display: "flex",
    justifyContent: "center",
    columnGap: "24px",
    marginBottom: "16px",
  },
  ssoButton: {
    width: "305px",
    padding: "12px 18px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "10px",
    border: "1px solid #EDEDED",
  },
  continueLoginText: {
    fontSize: "16px",
  },
  signupText: {
    fontSize: "14px",
    textAlign: "center",
    marginTop: "48px",
    fontWeight: 400,
  },
  signupLink: {
    textDecoration: "underline",
    textDecorationColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  element: {
    boxSizing: "border-box",
    border: 0,
    borderBottom: " 0.7px solid #717171",
  },
}));

const googleSSOApiInitialPath = {
  // consultant: "candidates",
  consultant: "consultant",
  // client: "employers",
  client: "client",
  // agency: "vendors",
  agency: "agency",
}; // v1 google auth api paths.

const Login = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const [notificationDetails, setNotificationDetails] =
    useContext(NotificationContext);
  const {
    experiences,
    contractTypes: contractTypesReceived,
    skillLevels,
    skills,
    industryData,
  } = useSelector((state) => state.misc);
  const pathName = location?.pathname?.split("/");
  const accountType = pathName[1];

  const isConsultantView = accountType === "consultant";
  const isEmployerView = accountType === "client";
  const isAgencyView = accountType === "agency";
  // // console.log({ location, pathName, accountType });
  const [formValidated, setFormValidated] = useState(false);
  const [password, setPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(true);

  const enableLoader = useToggleLoader();

  useEffect(() => {
    // enableLoader(true);
    // const timer1 = setTimeout(() => {
    const checkSession = () => {
      const user = storageUtil.getItem("user");
      if (user === undefined) {
        // enqueueSnackbar("Your session has expired. Please login again", {
        //   variant: "error",
        // });

        const key = enqueueSnackbar(
          "Your session has expired. Please login again",
          {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
        // storageUtil.removeItem("user");

        // enableLoader(false);
      } else {
        // enableLoader(false);
      }
    };

    const timer1 = setInterval(checkSession, 2000);

    return () => {
      // clearTimeout(timer1);
      clearInterval(timer1);
    };
  }, []);

  const fetchMiscData = () => {
    // console.log("fetching misc data");
    dispatch(fetchSkillLevelsThunk());
    dispatch(fetchSkillsThunk());
    dispatch(fetchExperiencesThunk());
    dispatch(fetchContractTypesThunk());
    dispatch(fetchIndustryThunk());
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    const data = new FormData(event.target);
    if (!formValidated) setFormValidated(true);
    if (form.checkValidity() === false) return;

    // @ts-ignore
    dispatch(
      loginUser({
        email: data.get("email"),
        password: data.get("password"),
      })
    )
      // @ts-ignore
      .unwrap()
      .then((res) => {
        //console.log("here --", res)
        if (res._id) {

          localStorage.setItem("empIdOrLoginId",res?._id)
          const unsubscribe = setupNotificationHandling(
            enqueueSnackbar,
            closeSnackbar
          );
          setNotificationDetails({ unsubscribe });
          fetchMiscData();
          // enqueueSnackbar("Login successful!", { variant: "success" });

          // enqueueSnackbar("Login successful!", {
          //   variant: "success",
          //   autoHideDuration: 3000,
          //   action: (key) => (
          //     <CloseIcon
          //       onClick={() => closeSnackbar(key)}
          //       style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
          //     />
          //   ),
          // });


          const jobid =  localStorage.getItem("currentJobID");

          if(res&& res["role"] === "consultant" && jobid!==null){
            localStorage.removeItem("currentJobID");
            history.push(`/consultant/job-detail/${jobid}`);
          }
          else{
            history.push(`/${accountType}/dashboard`);
          }
  
          }
        // const jobid =  localStorage.getItem("currentJobID");

        // if(res&& res["role"] == "consultant" && jobid!=''){}
        // }
        // else{
        //   history.push(`/${accountType}/dashboard`);

        // }
        // dispatch(loggedIn(res));
        form.reset();
      })
      .catch((err) => {
        // // console.log("err", err);
        if (err.message) {
          // enqueueSnackbar(err.message, { variant: "error" });

          const key = enqueueSnackbar(err.message, {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        } else {
          // enqueueSnackbar("Error trying to connect! Check network!", {
          //   variant: "error",
          // });
          const key = enqueueSnackbar(
            "Error trying to connect! Check network!",
            {
              variant: "error",
              autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon
                  onClick={() => closeSnackbar(key)}
                  style={{
                    cursor: "pointer",
                    fontSize: "15",
                    marginTop: "-1px",
                  }}
                />
              ),
            }
          );
        }
        console.error(err);
      });
  };

  const checkIsFormValid = () => {
    const form = document.getElementById("login-form");
    if (!formValidated) return false;
    return !form?.checkValidity();
  };

  return (
    <Grid className="box-border flex flex-wrap w-full flex-row sm:flex-col m-auto sm:p-4 ">
      <Grid item xs={5.2} className={classes.leftView}></Grid>
      <Grid item xs={6.8} className={`${classes.rightView} m-auto sm:w-[100%] `} >
        <div className={classes.loginContainer}>
          <div className={classes.loginAsDiv}>
            <Typography className={classes.loginText}>
              LOGIN <span className={classes.asAnText}>as an</span>
            </Typography>
            <DropdownButton title={accountType} className={`${classes.menuTitle} `}>
              <Dropdown.Item
                onClick={() => {
                  if (!isConsultantView) {
                    // localStorage.removeItem("isRecruiter");
                    history.push("/consultant/login");
                  }
                }}
              >
                Consultant
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  if (!isEmployerView) {
                    // localStorage.removeItem("isRecruiter");
                    history.push("/client/login");
                  }
                }}
              >
                {/* Employer */}
                Client
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  if (!isAgencyView) {
                    // localStorage.removeItem("isRecruiter");
                    history.push("/agency/login");
                  }
                }}
              >
                Agency
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <Form
            id="login-form"
            noValidate
            validated={formValidated}
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Typography className={classes.welcomeBackText}>
              Welcome Back!
            </Typography>
            <Form.Group className="mb-4" controlId="formFirstName">
              <Form.Label>Enter your email id</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email Id"
                name="email"
                required
                className={classes.element}
              />
              <Form.Control.Feedback type="invalid">
                Invalid Email address
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-12" controlId="formFirstName">
              <Form.Label>Enter your password</Form.Label>
              <Form.Control
                type={hidePassword ? "password" : "text"}
                placeholder="Password"
                name="password"
                required
                onChange={({ target }) => setPassword(target.value)}
                className={classes.element}
              />
              {password.length > 0 && (
                <>
                  {!hidePassword && (
                    <BsFillEyeFill
                      style={{
                        position: "relative",
                        left: "30rem",
                        bottom: "2rem",
                        cursor: "pointer",
                      }}
                      onClick={() => setHidePassword(true)}
                    />
                  )}
                  {hidePassword && (
                    <BsFillEyeSlashFill
                      style={{
                        position: "relative",
                        left: "30rem",
                        bottom: "2rem",
                        cursor: "pointer",
                      }}
                      onClick={() => setHidePassword(false)}
                    />
                  )}
                </>
              )}
              <Form.Control.Feedback type="invalid">
                Password is required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className={`mb-12 ${classes.loginForgotPasswordDiv}`}
              controlId="formBasicConfirmPassword"
            >
              <Button
                variant="light"
                className={classes.loginButton}
                type="submit"
                // type="button"
                disabled={checkIsFormValid()}
                // onClick={() => {
                //   history.push({
                //     pathname: "agency/dashboard",
                //     state: { incomplete: false },
                //   });
                // }}
              >
                Login
              </Button>
              <br />
              <Link
                to={`/${accountType}/forgot-password`}
                className={classes.forgotPasswordLink}
              >
                Forgot your password?
              </Link>
            </Form.Group>
          </Form>
          <div className={`${classes.orTextAndSSODiv}  sm:flex-col sm:items-center sm:w-[100%] sm:justify-center `}>
            <div className={classes.orTextDiv}>
              <div className={classes.line}></div>
              <span className={classes.orText}> Or</span>
              <div className={classes.line}></div>
            </div>
            <div className={`${classes.singleSignOnDiv} sm:flex-col sm:items-center sm:justify-center  `}>
              <Button
                variant="light"
                className={classes.ssoButton}
                onClick={() => {
                  window.location.href =
                    configs.APIUrl + `/${accountType}/auth/google`;
                  // configs.APIUrl + `/${accountType}/auth/google?signUp=true&company=Edwards Corp`;
                }}
              >
                <img src="/google-logo-black.png" alt="Google Signon" />
                <Typography className={classes.continueLoginText}>
                  {" "}
                  Continue with Google
                </Typography>
              </Button>
              <Button
                variant="light"
                className={classes.ssoButton}
                onClick={handleLinkedInAuth}
              >
                <img src="/linkedin-logo-black.png" alt="Linkedin Signon" />
                <Typography className={classes.continueLoginText}>
                  Continue with Linkedin
                </Typography>
              </Button>
            </div>
          </div>
          <Typography className={classes.signupText}>
            Don't have an account?{" "}
            <Link
              to={`/${accountType}/onboarding`}
              className={classes.signupLink}
            >
              Signup
            </Link>
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};
export default Login;
