import React from "react";
import PropTypes from "prop-types";
import JobStatus from "../../../common/Status/Job";
import { IconDot } from "app/v2/Pages/SVGs/IconDot";
import { Typography, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";

import CustomTooltip from "app/v2/components/CustomTooltip";
import CustomLabel from "app/v2/components/filters/CustomLabel";

import { isValidArray, isValidString } from "app/v2/Pages/Utils/utilFunctions";
import { messages } from "app/v2/Pages/Utils/constants";
import {
  getExperience,
  getContractTypeLevel,
} from "app/v2/Pages/common/getIdTypeLevel";

const useStyles = makeStyles((theme) => ({
  jOSet1: {
    flex: 1,
    display: "flex",
    columnGap: "24px",
    borderBottom: "1px solid #EDEDED",
  }, // jO - job overview.
  jOSet1Element: {
    flex: 1,
    height: "36px",
    margin: "24px 0",
    boxSizing: "content-box",
  },
  jOSetElementTitle: {
    flex: 1,
    fontSize: "12px",
    fontWeight: 600,
    color: "#717171",
  },
  jOSet1ElementValue: {
    fontSize: "16px",
    fontWeight: 700,
  },
  jOSet3And4Element: {
    flex: 1,
  },
  jOSet3And4ElementValue: {
    fontSize: "16px",
  },
  orgImage: {
    width: "120px",
    height: "120px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
}));

export const SectionTitleIcon = ({ title, icon }) => (
  <div className="text-[24px] font-semibold mb-[24px] flex items-center gap-x-[8px]">
    {icon} {title}
  </div>
);

export const JobHeader = ({ data }) => {
  const classes = useStyles();
  const clientData = data?.companyDetail;
  let createdAt = data?.createdAt ? moment(data?.createdAt).fromNow() : "-";
  createdAt = createdAt === "Invalid date" ? "-" : createdAt;
  let updatedAt = data?.updatedAt ? moment(data?.updatedAt).fromNow() : "-";
  updatedAt = updatedAt === "Invalid date" ? "-" : updatedAt;
  return (
    <div className="flex gap-x-[24px] mb-[36px]">
      <div>
        <img
          className={classes.orgImage}
          alt={`org_${data?._id}_image`}
          src={
            clientData?.profilePhoto
              ? clientData?.profilePhoto
              : "/org_placeholder.svg"
          }
        />
      </div>
      <div className="flex-1 flex flex-col gap-y-[12px]">
        <div className="flex justify-between items-center">
          <div className="flex gap-x-[12px]">
            <span className="text-[12px] text-[#717171]">
              Posted {createdAt}
            </span>
            <span className="text-[12px] text-[#717171]">
              Last Updated {updatedAt}
            </span>
          </div>
          <div>
            <JobStatus type={data?.status} />
          </div>
        </div>
        <div className="text-[36px] font-semibold sm:text-[12px]">{data?.jobTitle ?? "-"}</div>
        <div className="flex gap-x-[36px]">
          <span className="text-[14px] font-semibold text-[#8C8C8C]">
            Job no. <span className="text-[black]">{data?.jobNo ?? "-"}</span>
          </span>
          <span className="text-[14px] font-semibold text-[#8C8C8C]">
            Created by{" "}
            <span className="text-[black]">{data?.createdBy ?? "-"}</span>
          </span>
          <span className="text-[14px] font-semibold text-[#8C8C8C]">
            Updated by{" "}
            <span className="text-[black]">{data?.updatedBy ?? "-"}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export const JobOverview = ({ data, experiencesData, contractTypesData }) => {
  const classes = useStyles();
  const location =
    (data?.country ?? "") +
    ", " +
    (data?.state ?? "") +
    ", " +
    (data?.city ?? "");

  const salaryDetails = data?.salaryDetails ?? data?.salary;
  const salaryRange = salaryDetails?.range;
  const salaryValue = salaryRange
    ? `Min- $ ${salaryRange?.min ?? "-"}, Max - $ ${salaryRange?.max ?? "-"}`
    : `$ ${salaryDetails?.fixedRate ?? "-"}`;
  let salaryType = salaryDetails?.type;
  salaryType = salaryType?.toUpperCase();
  return (
    <div className="flex flex-col gap-y-[36px]">
      <div className={classes.jOSet1}>
        <div className={classes.jOSet1Element}>
          <div className={classes.jOSetElementTitle}>TOTAL APPLICANTS</div>
          <div className={classes.jOSet1ElementValue}>
            {data?.totalApplicants ?? "-"}
          </div>
        </div>
        <div className={classes.jOSet1Element}>
          <div className={classes.jOSetElementTitle}>TOTAL OPENINGS</div>
          <div className={classes.jOSet1ElementValue}>
            {data?.openPositions ?? "-"}
          </div>
        </div>
        <div className={classes.jOSet1Element}>
          <div className={classes.jOSetElementTitle}>ACCEPTED APPLICANTS</div>
          <div className={classes.jOSet1ElementValue}>
            {data?.acceptedApplicants ?? "-"}
          </div>
        </div>
        <div className={classes.jOSet1Element}>
          <div className={classes.jOSetElementTitle}>REJECTED APPLICANTS</div>
          <div className={classes.jOSet1ElementValue}>
            {data?.rejectedApplicants ?? "-"}
          </div>
        </div>
      </div>
      <div className="p-[16px] bg-[#F7F7F7] flex flex-col gap-y-[12px]">
        <div className="text-[12px] font-semibold text-[#717171]">TAGS</div>
        <div className="flex gap-x-[12px]">
          {isValidArray(data?.tags) ? (
            <>
              {" "}
              {data?.tags?.map((tag) => (
                <span className="text-[12px] font-bold py-[2px] px-[8px] bg-[#EDEDED]">
                  {tag}
                </span>
              ))}
            </>
          ) : (
            "-"
          )}
        </div>
      </div>
      <div className="flex gap-x-[16px]">
        <div className={classes.jOSet3And4Element}>
          <div className={classes.jOSetElementTitle}>
            {salaryType ?? ""} RATE
          </div>
          <div className={classes.jOSet3And4ElementValue}>
            {salaryValue ?? "-"}
          </div>
        </div>
        <div className={classes.jOSet3And4Element}>
          <div className={classes.jOSetElementTitle}>LEVEL</div>
          <div className={classes.jOSet3And4ElementValue}>
            {getExperience(data?.experienceLevelId, experiencesData)}
          </div>
        </div>
        <div className={classes.jOSet3And4Element}>
          <div className={classes.jOSetElementTitle}>CONTRACT</div>
          <div className={classes.jOSet3And4ElementValue}>
            {getContractTypeLevel(data?.contractType, contractTypesData)}
          </div>
        </div>
        <div className={classes.jOSet3And4Element}>
          <div className={classes.jOSetElementTitle}>WORK TYPE</div>
          <div className={classes.jOSet3And4ElementValue}>
            {data?.workType ?? "-"}
          </div>
        </div>
      </div>
      <div className="flex gap-x-[16px]">
        <div className={classes.jOSet3And4Element}>
          <div className={classes.jOSetElementTitle}> LOCATION</div>
          <div className={classes.jOSet3And4ElementValue}>
            {location ?? "-"}
          </div>
        </div>
        <div className={classes.jOSet3And4Element}>
          <div className={classes.jOSetElementTitle}>TIMEZONE</div>
          <div className={classes.jOSet3And4ElementValue}>
            {data?.timezone ?? "-"}
          </div>
        </div>
      </div>
    </div>
  );
};

export const TextSection = ({ data }) => {
  return (
    <div className="text-[16px] text-[#717171]">
      {isValidString(data) ? (
        <iframe srcDoc={data} className="w-full"></iframe>
      ) : (
        <Alert severity="info"> {messages?.NO_DATA_FOUND} </Alert>
      )}
    </div>
  );
};

export const AboutCompany = ({ profileData, cultureData }) => {
  return (
    <div className="text-[16px] text-[#717171]">
      {isValidString(profileData) || isValidString(cultureData) ? (
        <>
          {isValidString(profileData) ? (
            <>
              <CustomLabel label="company profile" />
              <iframe
                srcDoc={profileData}
                className="w-full"
                title="company profile"
              ></iframe>
              <br />
            </>
          ) : (
            <></>
          )}
          {isValidString(cultureData) ? (
            <>
              <CustomLabel label="company culture" />
              <iframe
                srcDoc={cultureData}
                className="w-full"
                title="company culture"
              ></iframe>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        "-"
      )}
    </div>
  );
};
