import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";




export const getRoles = async () => {
    return fetchWrapper(
      `${configs.APIUrl}/agency/role`
    );
  };


  
export const getAgencyInfo = async () => {
    return fetchWrapper(
      `${configs.APIUrl}/agency/info`
    );
  };

export const getEmployeeList = async (ordId) => {
  return fetchWrapper(`${configs.APIUrl}/agency/employees/${ordId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const inviteEmployee = async (request) => {
    return fetchWrapper(`${configs.APIUrl}/agency/inviteEmployee`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: request,
    });
  };

  export const updateEmployee = async (request) => {
    return fetchWrapper(`${configs.APIUrl}/agency/updateEmployee`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: request,
    });
  };

  export const resendInvitation = async (request) => {
    return fetchWrapper(`${configs.APIUrl}/agency/resendInvite`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: request,
    });
  };

  export const changeStatusOfEmployee = async (request) => {
    return fetchWrapper(`${configs.APIUrl}/agency/changeStatus`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: request,
    });
  };

  export const deleteAgencyEmployeeById = async (emailId) => {
    return fetchWrapper(`${configs.APIUrl}/agency/deactivation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: emailId,
    });
  };