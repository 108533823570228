import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepIcon from "@mui/material/StepIcon";

import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import "./customverticalstepper.scss";
import { getHistoryActiveConsultant } from "../services/client/consultants.service";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { UserContext } from "..";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { isValidArray } from "../Pages/Utils/utilFunctions";
import {
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Pagination,
  Menu,
  MenuItem,
  IconButton,
  Fade,
  FormControl,
  Select,
  CircularProgress,
} from "@mui/material";
import CustomTooltip from "./CustomTooltip";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CustomDateRangePicker from "./filters/CustomDateRangePicker";
import CustomButton from "./CustomButton";

// const steps = [
//   {
//     label: "Select campaign settings",
//     description: `For each ad campaign that you create, you can control how much
//               you're willing to spend on clicks and conversions, which networks
//               and geographical locations you want your ads to show on, and more.`,
//   },
//   {
//     label: "Create an ad group",
//     description:
//       "An ad group contains one or more ads which target a shared set of keywords.",
//   },
//   {
//     label: "Create an ad",
//     description: `Try out different ad text to see what brings in the most customers,
//               and learn how to enhance your ads using features like ad extensions.
//               If you run into any problems with your ads, find out how to tell if
//               they're running and how to resolve approval issues.`,
//   },
// ];

const useStyles = makeStyles((theme) => ({
  stepLabel: {
    fontWeight: "700 !important",
    fontSize: "14px !important",
    color: "#717171",
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
  activeStepLabel: {
    color: theme.palette.common.black,
    fontWeight: "700 !important",
    fontSize: "14px !important",
  },
  completedStepLabel: {
    color: "#E0E0E0 !important",
    fontWeight: "700 !important",
    fontSize: "14px !important",
  },
  labelDiv: {
    position: "relative",
    color: "inherit",
    fontWeight: "inherit",
    fontSize: "inherit",
  },
  leftLabelDiv: {
    position: "absolute",
    left: "-185px",
    color: "#000000DE",
    fontWeight: "500",
    fontSize: "14px",
    marginTop: "5px",
  },
  rightLabelDiv: {
    color: "#000000DE",
    fontWeight: "inherit",
    fontSize: "inherit",
    display: "flex",
    justifyContent: "space-between",
    margin: "10px",
  },
  labeltext: {
    marginTop: "5px",
    maxWidth: "70%",
  },
  continueBackButtonsDiv: {
    padding: "10px",
  },
  stepsCompletedText: {
    fontWeight: "700",
    fontSize: "14px",
  },
  paginationContainer: {
    float: "right",
    margin: "20px 0",
  },

  sortAndSearchDiv: {
    display: "flex",
    // padding: "0 24px 16px 24px",
    marginBottom: "20px",
  },
  sortBySelect: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "2px bold #EDEDED",
    },
    "& .MuiSelect-select": {
      fontSize: "12px",
      fontWeight: "700 ",
      display: "flex",
      columnGap: "8px",
    },
  },
  sortByMenuItem: {
    fontSize: "13px",
    fontWeight: "600",
    display: "flex",
    columnGap: "8px",
  },
  sortMenuItemIcon: {
    width: "17px",
    height: "17px",
  },
}));

export default function CustomVerticalStepper({}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [initialRender, setInitialRender] = useState(true);
  const [drawerDetails, setDrawerDetails] = useContext(UserContext);
  const [sortByValue, setSortByValue] = useState("All");

  const activeconsultantId = useState(drawerDetails);
  //// console.log("activeConsultantId", activeconsultantId[0].contractActivity?._id);

  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataError, setDataError] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [historyListApi, setHistoryListApi] = useState(false);
  const[defaultDates,setDefaultDates]=useState(false)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const invoicesPerScroll = 10;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const onPageChange = (event, value) => {
    setCurrentPage(value);
    fetchHistory(value);

  };

  const handleStartDateChange = (date) => {
    setStartDate(date);

    if (date && endDate && date > endDate) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const handleSubmitOftimesheetRange = () => {
    setHistoryListApi((prev) => !prev);
  };

  const SortBySelect = ({ selectedValue, onChanges }) => {
    const classes = useStyles();

    const handleChange = (event) => {
      onChanges(event.target.value);
    };

    return (
      <div className="flex gap-y-[8px] items-center text-[14px] text-[#8C8C8C]w-full ml-auto mt-[15px]">
        <div className="font-semibold text-md">Filter By Category:</div>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={selectedValue}
            onChange={handleChange}
            className={classes.sortBySelect}
          >
            <MenuItem value="All" className={classes.sortByMenuItem}>
              All
            </MenuItem>
            <MenuItem value="timesheets" className={classes.sortByMenuItem}>
              Timesheet
            </MenuItem>
            <MenuItem value="activeworks" className={classes.sortByMenuItem}>
              Activework
            </MenuItem>
            <MenuItem value="invoices" className={classes.sortByMenuItem}>
              Invoice
            </MenuItem>
            <MenuItem value="documents" className={classes.sortByMenuItem}>
              Documents
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  };

  const fetchHistory = async (currentPage) => {
    // const isPageSelectionAction = action === "page";
    // const pageNumberForAPI = isPageSelectionAction ? currentPage - 1 : 0; // Going back to first page if sort/filter was updated.

    setLoading(true);

    let data = {
      limit: invoicesPerScroll,
      offset: currentPage - 1,
      id: activeconsultantId[0]?.contractActivity?._id,
      filterBy: sortByValue === "All" ? "" : sortByValue,
      startDate: startDate,
      endDate: endDate,
    };
    //// console.log("getHistoryActiveConsultant", data);
    getHistoryActiveConsultant(data)
      .then((response) => {
        let clientsReceived = response?.data;
        let pageCount;
        if (isValidArray(clientsReceived)) {
          setLoading(false);

          const totalClientCount = response?.count 
          pageCount =
            totalClientCount > 0
              ? Math.ceil(totalClientCount / invoicesPerScroll 
            )
              : 0;

              setHistoryData(clientsReceived);
              setTotalPagesCount(pageCount);
              setInitialRender(false);
              setLoading(false);
        } else {
          setHistoryData([]);
          
          setLoading(false);
          return;
        }

       
      })
      .catch(() => {
        const key = enqueueSnackbar(
          `Your action was not successful. Please try again later.`,
          {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{
                  cursor: "pointer",
                  fontSize: "15",
                  marginTop: "-1px",
                }}
              />
            ),
          }
        );
      });
  };

  // useEffect(() => {
  //   const currentDate = new Date();
  //   const currentDayOfWeek = currentDate.getDay();
  //   const diff =
  //     currentDate.getDate() -
  //     currentDayOfWeek +
  //     (currentDayOfWeek === 0 ? -6 : 1);
  //   const startOfWeek = new Date(currentDate.setDate(diff));
  //   const endOfWeek = new Date(startOfWeek);
  //   endOfWeek.setDate(startOfWeek.getDate() + 6);

  //   setStartDate(startOfWeek);
  //   setEndDate(endOfWeek);

  //   setHistoryListApi((prev) => !prev);
  // }, [defaultDates]);

  useEffect(() => {
    // Get the current 
    const currentDate = new Date();
  
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDate.getDay() + 1); // Adjust to Monday
  
    const endOfWeek = new Date(startOfWeek); 
    endOfWeek.setDate(startOfWeek.getDate() + 6); // Adjust to Sunday
  
    setStartDate(startOfWeek);
    setEndDate(endOfWeek);
  
    setHistoryListApi((prev) => !prev);
  }, [defaultDates]);
  
  useEffect(() => {
    if(startDate && endDate){
    fetchHistory(currentPage);
    }else{
      setDefaultDates((prev) => !prev);
      setCurrentPage(1)

    }
  }, [defaultDates,historyListApi]);

  // useEffect(() => {
  //   if (!initialRender) {
  //     // // console.log({ currentPage });
  //     fetchHistory("page");
  //   }
  // }, [currentPage]);

  useEffect(() => {
    if (!initialRender) {
      setCurrentPage(1);
      fetchHistory(1);
    }
  }, [sortByValue]);

  // useEffect(() => {
  //   if (!initialRender) {
  //     // // console.log({
  //     //   sortSelectionValue,
  //     //   selectedCSChipRange,
  //     //   checkedCountryNames,
  //     //   checkedIndustryNames,
  //     // });
  //     fetchClients("sort-filter");
  //   }
  // }, [
  //   sortSelectionValue,
  //   selectedCSChipRange,
  //   checkedCountryNames,
  //   checkedIndustryNames,
  // // ]);
  // function formatTimestampToDate(timestamp) {
  //   // Create a new Date object using the timestamp (in milliseconds, so multiply by 1000)
  //   const date = new Date(timestamp * 1000);

  //   // Get the month, day, and year
  //   const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
  //     date
  //   );
  //   const day = date.getDate();
  //   const year = date.getFullYear();

  //   // Format the date
  //   const formattedDate = `${month} ${day} ${year}`;

  //   return formattedDate;
  // }

  const formatDateAndTime = (date) => {
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const year = d.getFullYear();
    const hours = String(d.getHours()).padStart(2, "0");
    const minutes = String(d.getMinutes()).padStart(2, "0");
    const seconds = String(d.getSeconds()).padStart(2, "0");
    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const CustomStepIcon = (props) => {
    return (
      <StepIcon
        {...props}
        icon={
          <div
            style={{
              width: 26,
              height: 26,
              borderRadius: "50%",
              backgroundColor: "black",
              // You can customize the color of the circle
            }}
          />
        }
      />
    );
  };

  // useEffect(() => {

  //   fetchHistory("page");
  // }, []);

  return (
    <>
      <Box>
        <div className="flex justify-between  space-x-6">
          <div className="flex space-x-6 mb-8 ">
            <CustomDateRangePicker
              selectedDate={startDate}
              onDateChange={handleStartDateChange}
              placeholderText="Start Date"
              minDate={null}
              maxDate={new Date()}
              value={startDate}
            />
            <CustomDateRangePicker
              selectedDate={endDate}
              onDateChange={handleEndDateChange}
              placeholderText="End Date"
              minDate={startDate}
              // maxDate={new Date()}
              value={endDate}
            />
          </div>

          <div className="mb-8">
            <label className="block text-sm font-medium text-gray-700">
              &nbsp;
            </label>

            <CustomButton
              onClick={handleSubmitOftimesheetRange}
              type="button1"
              customClasses="w-full  px-4 py-2  mx-auto !important "
              // disabled={isSubmitDisabled}
            >
              Submit
            </CustomButton>
          </div>

          <div className={classes.sortAndSearchDiv}>
            <SortBySelect
              selectedValue={sortByValue}
              onChanges={(value) => setSortByValue(value)}
            />
          </div>
        </div>
        {historyData && historyData?.length > 0 ? (
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            className="stepperContainer"
          >
            {loading ? (
              <CircularProgress
                sx={{ display: "flex", margin: "auto", height: "100%" }}
              />
            ) : historyData && (
              historyData?.map((step, index) => (
                <Step key={step?._id}>
                  <StepLabel
                    StepIconComponent={CustomStepIcon}
                    classes={{
                      root: classes.stepLabel,
                      active: classes.activeStepLabel,
                      completed: classes.completedStepLabel,
                      disabled: classes.stepLabel,
                    }}
                    className="stepLabelContainer"
                    style={{ marginLeft: "40px" }}
                  >
                    <div className={classes.labelDiv}>
                      <div className={classes.leftLabelDiv}>
                        {formatDateAndTime(step?.createdAt)}
                        {/* {step?.leftLabelIcon} */}
                      </div>
                      <div className={classes.rightLabelDiv}>
                        <div className={classes.labeltext}>
                          {step?.action?.text}
                        </div>
                        {/* {step?.rightlabelIcon} */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            maxWidth: "30%",
                          }}
                        >
                          <div
                            style={{
                              border: "2px solid black",
                              padding: "5px",
                              width: "150px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              height: "30px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {step?.actionCategory}
                          </div>
                          <div
                            style={{
                              border: "2px solid black",
                              padding: "5px",
                              width: "150px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              height: "30px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {step?.action?.category}
                          </div>
                        </div>
                      </div>
                    </div>
                  </StepLabel>
                  {/* <StepContent>
      <Typography>{step?.description}</Typography>
      <Box sx={{ mb: 2 }}>
        <div>{step?.componentData?.component}</div>
        <div className={classes.continueBackButtonsDiv}>
          <Button
            variant="contained"
            onClick={handleNext}
            sx={{ mt: 1, mr: 1 }}
          >
            {index === stepperData?.length - 1 ? "Finish" : "Continue"}
          </Button>
          <Button
            disabled={index === 0}
            onClick={handleBack}
            sx={{ mt: 1, mr: 1, color: "white" }}
          >
            Back
          </Button>
        </div>
      </Box>
    </StepContent> */}
                </Step>
              ))
            ) 
            }
          </Stepper>
        ) : (
          <Alert severity="info">
            "No record found for the selected criteria"
          </Alert>
        )}

        {/* {activeStep === stepperData?.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography className={classes.stepsCompletedText}>
            All steps completed - you&apos;re finished
          </Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1, color: "white" }}>
            Reset
          </Button>
        </Paper>
      )} */}
      </Box>
      <Pagination
        color="primary"
        count={totalPagesCount}
        page={currentPage}
        onChange={onPageChange}
        classes={{ ul: classes.paginationContainer }}
      />
    </>
  );
}
