import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ContentLoader from "react-content-loader";
import { Typography, Link, Grid, Alert } from "@mui/material";
import CustomButton from "app/v2/components/CustomButton";
import SkillsPicker from "app/v2/Pages/common/SkillsPicker";
import {
  JobHeader,
  JobOverview,
  TextSection,
  AboutCompany,
} from "./components";

import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import CallMadeOutlinedIcon from "@mui/icons-material/CallMadeOutlined";
import { IconLightUser } from "app/v2/Pages/SVGs/IconLightUser";
import { IconLightFile } from "app/v2/Pages/SVGs/IconLightFile";
import { IconLightGrid } from "app/v2/Pages/SVGs/IconLightGrid";
import { IconLightAward } from "app/v2/Pages/SVGs/IconLightAward";
import img_icon_light_arrow_top from "../../images/img_icon_light_arrow_top.svg";

import { messages } from "app/v2/Pages/Utils/constants";
import { scrollToTop } from "app/v2/Pages/Utils/utilFunctions";
import { JobDetailObj } from "./JobDetail.dummy";
import { isValidObject } from "app/v2/Pages/Utils/utilFunctions";

import { getPublicJobDetail } from "app/v2/services/job/jobDetail.service";
import { getEmployerInfo } from "app/v2/services/client/profileSettings.service";
import {
  fetchExperiencesThunk,
  fetchContractTypesThunk,
} from "app/v2/store/features/misc/miscThunk";

const useStyles = makeStyles((theme) => ({
  leftView: {
    padding: "24px 48px",
    width: "291px",
    boxSizing: "content-box",
    position: "fixed",
  },
  knowMoreButton: {
    width: "100%",
    marginBottom: "24px",
    padding: "12px",
    fontSize: "16px",
    fontWeight: 600,
  },
  navItemsDiv: {
    display: "flex",
    flexDirection: "column",
    rowGap: "26px",
    marginTop: "36px",
  },
  navItemLinkDiv: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    columnGap: "12px",
  },
  navItemTitle: {
    fontSize: "16px",
    fontWeight: 600,
  },
  selectedNavItem: {
    color: `${theme.palette.common.black} !important`,
  },
  unselectedNavItem: {
    color: `${theme.palette.secondary.light} !important`,
  },
  selectedNavItemForTeamIcon: {
    color: `${theme.palette.primary.main} !important`,
  },
  unselectedNavItemForTeamIcon: {
    color: `${theme.palette.success.main} !important`,
  },
  rightView: {
    borderLeft: "1px solid #EDEDED",
    marginLeft: "387px", // left view's total width since its fixed.
  },
  rightArrowIcon: {
    marginLeft: "10px",
  },
}));

const leftNavData = [
  {
    icon: <IconLightUser />,
    title: "Job Overview",
    component: ({ jobData, experiencesData, contractTypesData }) => (
      <JobOverview
        jobData={jobData}
        experiencesData={experiencesData}
        contractTypesData={contractTypesData}
      />
    ),
  },
  {
    icon: <IconLightFile />,
    title: "Job Description",
    component: ({ jobData }) => (
      <TextSection
        data={jobData?.jobDescription?.htmlString ?? jobData?.jobDescription}
      />
    ),
  },
  {
    icon: <IconLightGrid />,
    title: "Qualifications",
    component: ({ jobData }) => (
      <SkillsPicker
        id="publicJobDetailSP" // SP - Skills Picker
        data={jobData?.skillsRequired}
        readOnly={true}
      />
    ),
  },
  {
    icon: <IconLightAward />,
    title: "Responsibilities",
    component: ({ jobData }) => (
      <TextSection
        data={
          jobData?.jobResponsibilities?.htmlString ??
          jobData?.jobResponsibilities
        }
      />
    ),
  },
  {
    icon: <VerifiedUserOutlinedIcon />,
    title: "About Company",
    component: ({ jobData }) => (
      <AboutCompany
        profileData={
          jobData?.companyDetail?.profileDesc?.htmlString ??
          jobData?.companyDetail?.profileDesc
        }
        cultureData={
          jobData?.companyDetail?.culture?.htmlString ??
          jobData?.companyDetail?.culture
        }
      />
    ),
    withGrayBg: true,
  },
];

const JobDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { experiences, contractTypes: contractTypesReceived } = useSelector(
    (state) => state.misc
  );

  const currentURL = window.location.href;
  const pathArray = new URL(currentURL).pathname.split("/");
  const currentJobID = pathArray[pathArray.length - 1];
  // // console.log({ currentURL, pathArray, currentJobID });
  // const jobData = JobDetailObj;
  const [jobData, setJobData] = useState({});
 // const [currentJobId, setCurrentJobId] = useState(jobId);
  const [experienceTypes, setExperienceTypes] = useState([]);
  const [contractTypes, setContractTypes] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);
  const { jobId } = useParams();
  const [selectedNavItemIndex, setSelectedNavItemIndex] = useState(
    "nav-item-component-0"
  );

  const onNavItemClick = (navItemIndex) => {
    // sectionRefs.current[navItemIndex].scrollIntoView({ behavior: 'smooth' });

    const element = sectionRefs.current[navItemIndex];
    const elementTop = element.getBoundingClientRect().top + window.pageYOffset;
    const container = window;
    const offset = -180;
    container.scrollTo({ top: elementTop + offset, behavior: "smooth" });
  };

  const isLastNavItemComponent = (index) => {
    return index === leftNavData?.length - 1;
  };

  ///////// scroll
  const sectionRefs = useRef([]);
  const handleScroll = useCallback(() => {
    const pageYOffset = window.pageYOffset + 180;

    sectionRefs.current.forEach((section) => {
      const sectionOffsetTop = section?.offsetTop;
      const sectionHeight = section?.offsetHeight;

      if (
        pageYOffset >= sectionOffsetTop &&
        pageYOffset < sectionOffsetTop + sectionHeight
      )
        setSelectedNavItemIndex(section.id);
    });
  }, [selectedNavItemIndex]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  ///////// scroll

  useEffect(() => {
    getPublicJobDetail(currentJobID)
      .then((res) => {
        const jobDataReceived = res?.data;
        // console.log({ jobDataReceived });
        if (isValidObject(jobDataReceived)) {
          setJobData(jobDataReceived);
          setDataLoading(false);
        } else {
          setDataLoading(false);
          setDataError(true);
        }
      })
      .catch((error) => {
        setDataLoading(false);
        setDataError(true);
      });
  }, []);

  useEffect(() => {
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
  }, [dispatch]);

  useEffect(() => {
    const experienceData = experiences?.map(
      (expEntry) =>
        `${expEntry?.level} (${expEntry?.minExp} - ${expEntry?.maxExp} years)`
    );

    setExperienceTypes(experienceData);
  }, [experiences]);

  useEffect(() => {
    const contractData = contractTypesReceived?.map(
      (contractEntry) => contractEntry?.level
    );
    setContractTypes(contractData);
  }, [contractTypesReceived]);

  return (
    <>
      <>
        {dataLoading ? (
          // <CircularProgress />
          <ContentLoader
            speed={2}
            width={1400}
            height={800}
            viewBox="0 0 1400 800"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="100" rx="3" ry="3" width="280" height="605" />
            <rect x="335" y="100" rx="3" ry="3" width="1200" height="605" />
          </ContentLoader>
        ) : (
          <>
            {dataError ? (
              <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
            ) : (
              <>
                <Grid container className={classes.body}>
                  <Grid item xs={3} className={classes.leftView}>
                    <div>
                      <CustomButton
                        type="button1"
                        customClasses={classes.knowMoreButton}
                        onClick={() => {
                          localStorage.setItem("currentJobID", currentJobID);
                          history.push(`/consultant/job-detail/${currentJobID}`);
                        }}
                      >
                        Apply Now
                        <CallMadeOutlinedIcon
                          fontSize="small"
                          className={classes.rightArrowIcon}
                        />
                      </CustomButton>
                    </div>
                    <hr />
                    <div className={classes.navItemsDiv}>
                      {leftNavData?.map((navItemDetails, index) => (
                        <Link
                          key={"l" + index}
                          className={clsx([
                            classes.navItemLinkDiv,
                            selectedNavItemIndex ===
                            `nav-item-component-${index}`
                              ? classes.selectedNavItem
                              : classes.unselectedNavItem,
                          ])}
                          onClick={() => {
                            onNavItemClick(index);
                          }}
                        >
                          {navItemDetails?.icon}

                          <Typography className={classes.navItemTitle}>
                            {navItemDetails?.title}
                          </Typography>
                        </Link>
                      ))}
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    id="sectionContent"
                    className={classes.rightView}
                  >
                    <div className="p-[48px]">
                      <JobHeader jobData={jobData} />
                      {leftNavData?.map((navItemDetails, index) => (
                        <>
                          <div key={"g" + index}>
                            <div
                              ref={(el) => (sectionRefs.current[index] = el)}
                              className={`${
                                navItemDetails?.withGrayBg
                                  ? "bg-[#F7F7F7] p-[36px]"
                                  : "px-[24px]"
                              }`}
                              id={`nav-item-component-${index}`}
                            >
                              <div className="text-[24px] font-semibold mb-[24px]">
                                {navItemDetails?.title}
                              </div>
                              {navItemDetails?.component({
                                jobData,
                                experiencesData: experienceTypes,
                                contractTypesData: contractTypes,
                              })}
                            </div>
                            {!isLastNavItemComponent(index) && (
                              <hr className="bg-[#E0E0E0] w-full my-[36px]" />
                            )}
                          </div>
                        </>
                      ))}
                      <div
                        className="flex items-center gap-x-[10px] pt-[36px] cursor-pointer"
                        onClick={() => {
                          scrollToTop();
                        }}
                      >
                        <img src={img_icon_light_arrow_top} />
                        <div className="font-semibold leading-[normal] text-base text-center text-gray-900">
                          Back to top
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </>
    </>
  );
};

JobDetail.propTypes = {};

export default JobDetail;
