import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoggedIn } from "../utils/loginUtil";


const PrivateRoute = ({ component: Component, render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        console.log("PrivateRoute render:", props); // Logging the props
        const segments = props?.location?.pathname?.split('/');

        // Fetch the client ID which is the second segment after '/client/'
        const clientId = segments[1];
        console.log("PrivateRoute render12:", clientId); // Logging the props

        return isLoggedIn() ? (
          Component ? <Component {...props} /> : render(props)
        ) : (
          <Redirect to={`/${clientId}/login`} />
        );
      }}
    />
  );
};


export default PrivateRoute;



// const PrivateRoute = ({ component: Component, ...rest }) => {
//   return (
//     // Show the component only when the user is logged in
//     // Otherwise, redirect the user to /login page
//     <Route
//       {...rest}
//       render={(props) =>
//         // isLoggedIn() ? <Component {...props} /> : <Redirect to="/login" />
//         isLoggedIn() ? <Component {...props} /> : <Redirect to="login" />
//       }
//     />
//   );
// };

// export default PrivateRoute;
