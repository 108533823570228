import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { logout } from "app/v2/Pages/common/login/login.service";

import {
  Avatar,
  Typography,
  Divider,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { UserContext } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import { getEmployerInfoThunk } from "app/v2/store/features/employer/profile-settings/profile-settings.thunk";
import { useSnackbar } from "notistack";
import { NoProfileImageSVG } from "app/v2/Pages/SVGs/NoProfile";
import storageUtil from "../Utils/storageUtil";
import CloseIcon from "@mui/icons-material/Close";

import { NotificationContext } from "app/v2";
import { isFunction } from "../Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  profileRoot: {
    margin: "24px",
    // width: "417px",
    width: "max-content!important",
  },
  personalDetails: {
    marginBottom: "42px",
  },
  name: {
    fontSize: "24px",
    fontWeight: 600,
  },
  companyName: {
    fontWeight: 700,
    color: "#8C8C8C",
  },
  divider: {
    marginBottom: "16px",
  },
  otherLinksDiv: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "6px",
  },
  settingsDiv: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "6px",
  },
  otherLink: {
    fontSize: "18px",
    fontWeight: 600,
    color: theme.palette.common.black,
    margin: "16px 0",
    cursor: "pointer",
  },
  settingTitle: {
    fontSize: "12px",
    fontWeight: 600,
    margin: "16px 0",
  },
  settingLink: {
    fontSize: "16px",
    fontWeight: 600,
    margin: "12px 0",
    color: theme.palette.common.black,
    cursor: "pointer",
  },
  pLAccordionRoot: {
    boxShadow: "none",
  }, // pL - parent link
  plAccordionSummary: {
    padding: 0,
    borderBottom: "1px solid #EDEDED",
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
  },
  pLAccordionDetails: {
    display: "flex",
    flexDirection: "column",
  },
  logoutLink: {
    fontSize: "18px",
    fontWeight: 600,
    color: "#A8C1FF !important",
    padding: "16px 0",
    cursor: "pointer",
  },
  orgImage: {
    width: "56px",
    height: "56px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
    marginBotom: "12px",
    "& .MuiAvatar-img": {
      objectFit: "scale-down",
    },
  },
}));
const ProfileMenu = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const [drawerDetails, setDrawerDetails] = useContext(UserContext);
  const [notificationDetails, setNotificationDetails] =
    useContext(NotificationContext);
  const [pSExpandedState, setPSExpandedState] = useState(false);

  const personalDataBuf = useSelector(
    // (state) => state.clientProfileInfo.clientInfo.employer
    (state) => state?.clientProfileInfo?.clientInfo?.data
  );
  const [personalData, setPersonalData] = useState(personalDataBuf);

  useEffect(() => {
    if (!personalDataBuf?.length) {
      dispatch(getEmployerInfoThunk())
        .unwrap()
        .then((res) => {
          if (res) {
            // const personalDataReceived = res?.employer;
            const personalDataReceived = res?.data.clientDetail;

            // // console.log({ personalDataReceived });
            // setPersonalData(res.employer);
            setPersonalData(personalDataReceived);
          }
          // setIsLoading(false);
        })
        .catch((err) => {
          // if (err.message) { }
          // enqueueSnackbar("Problem in fetching profile data!", {
          //   variant: "error",
          // });

          const key = enqueueSnackbar("Problem in fetching profile data!", {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
          console.error(err);
          // setIsLoading(false);
        });
    } else {
      setPersonalData(personalDataBuf);
    }
  }, []);

  const otherLinksData = [
    {
      label: "Connections",
      url: "/client/connections",
    },
    {
      label: "Favorites",
      url: "/client/favorites",
    },
    {
      label: "Active Consultants",
      url: "/client/active-consultant-feed",
    },
  ];
  const settingsData = [
    {
      label: "Subscription",
    },
    {
      label: "DocuCenter",
    },
    {
      label: "Profile Settings",
      subMenu: [
        {
          label: "Organization Details",
        },
        {
          label: "Personal Details",
        },
      ],
    },
    {
      label: "Employees",
    },
    {
      label: "Integrations",
    },
    {
      label: "Help & Support",
    },
  ];

  const closeDrawer = () => {
    setDrawerDetails({
      showDrawer: false,
      componentType: "",
      showHeading: true,
    });
  };

  const onOtherLinkClick = (url) => {
    closeDrawer();
    history.push(url);
  };
  const onSettingsLinkClick = (parentSettingIndex) => {
    closeDrawer();
    history.push({
      pathname: "/client/settings",
      state: { parentSettingIndex },
    });
  };

  const onSettingSubMenuLinkClick = (parentSettingIndex, childSettingIndex) => {
    closeDrawer();
    history.push({
      pathname: "/client/settings",
      state: { parentSettingIndex, childSettingIndex },
    });
  };

  useEffect(() => {
    // // console.log({ pSExpandedState });
  }, [pSExpandedState]);

  const onPSAccordionChange = () => {
    setPSExpandedState((prevState) => !prevState);
  };

  const onLogout = () => {
    logout()
      .then((res) => {
        const unsubscribeNotification = notificationDetails?.unsubscribe;
        // console.log({ unsubscribeNotification });
        if (res.message) {
          // enqueueSnackbar(res.message, { variant: "success" });

          const key = enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        }
        storageUtil.removeItem("user");
        closeDrawer();

        localStorage.removeItem("selectedFilters");
        if (isFunction(unsubscribeNotification)) unsubscribeNotification();

        history.push("/client/login");
      })
      .catch((err) => {
        if (err.message) {
          // enqueueSnackbar(err.message, { variant: "error" });
          const key = enqueueSnackbar(err.message, {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        }
        console.error(err);
      });
  };

  return (
    <div className={classes.profileRoot}>
      <Avatar
        alt="Profile icon"
        src={
          personalData?.orgDetails?.profilePhoto
            ? personalData?.orgDetails?.profilePhoto
            : "/org_placeholder.svg"
        }
        className={classes.orgImage}
        variant="square"
      />

      <div className={classes.personalDetails}>
        <Typography className={classes.name}>
          {(personalData?.firstName ?? "-") +
            " " +
            (personalData?.lastName ?? "-")}
        </Typography>
        <Typography variant="caption" className={classes.companyName}>
          {personalData?.orgDetails?.company ?? "-"}
        </Typography>
      </div>
      <div className={classes.otherLinksDiv}>
        {otherLinksData.map((otherLink, idx) => (
          <Link
            key={idx}
            onClick={() => {
              onOtherLinkClick(otherLink?.url);
            }}
            className={classes.otherLink}
          >
            {otherLink?.label}
          </Link>
        ))}
      </div>
      <Divider className={classes.divider} />
      <div className={classes.settingsDiv}>
        <Typography className={classes.settingTitle}>SETTINGS</Typography>
        {settingsData?.map((settingInfo, settingIndex) => (
          <>
            {settingInfo?.subMenu ? (
              <Accordion
                key={settingIndex}
                expanded={pSExpandedState}
                onChange={onPSAccordionChange}
                className={classes.pLAccordionRoot}
              >
                <AccordionSummary
                  expandIcon={pSExpandedState ? <RemoveIcon /> : <AddIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={classes.plAccordionSummary}
                >
                  <Typography className={classes.settingLink}>
                    {settingInfo?.label}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.pLAccordionDetails}>
                  {settingInfo?.subMenu.map((subMenuItem, subMenuItemIndex) => (
                    <Link
                      key={subMenuItemIndex}
                      onClick={() => {
                        onSettingSubMenuLinkClick(
                          settingIndex,
                          subMenuItemIndex
                        );
                      }}
                      className={classes.settingLink}
                    >
                      {subMenuItem?.label}
                    </Link>
                  ))}
                </AccordionDetails>
              </Accordion>
            ) : (
              <Link
                key={settingIndex}
                onClick={() => {
                  onSettingsLinkClick(settingIndex);
                }}
                className={classes.settingLink}
              >
                {settingInfo?.label}
              </Link>
            )}
          </>
        ))}
        <Link onClick={onLogout} className={classes.logoutLink}>
          Logout
        </Link>
      </div>
    </div>
  );
};

ProfileMenu.propTypes = {};

export default ProfileMenu;
