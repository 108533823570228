import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import ContentLoader from "react-content-loader";
import {
  Alert,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Pagination,
} from "@mui/material";
import clsx from "clsx";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Title from "../../common/Title";
import CustomRadioGroup from "app/v2/components/CustomRadioGroup";
import CustomCheckboxGroup from "app/v2/components/CustomCheckboxGroup";
import JobListCard from "./JobListCard";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomTooltip from "app/v2/components/CustomTooltip";

import { messages } from "../../Utils/constants";
import {
  isValidArray,
  isValidString,
  capitalizeFirstLetter,
} from "../../Utils/utilFunctions";

import {
  fetchContractTypesThunk,
  fetchExperiencesThunk,
} from "app/v2/store/features/misc/miscThunk";

import { dummyJobData } from "./jobData";
import { getClientJobList } from "app/v2/services/admin/manageClients.service";

const useStyles = makeStyles((theme) => ({
  jobFeedContainer: {
    minHeight: "calc(100vh - 382px)", // total viewport height minus navbar and footer's heights, minHeight:100% did not work,hence had to set it as pre parent div defined in EmployerRoute.
  },

  leftView: {
    padding: "24px 48px",
    width: "291px",
    boxSizing: "content-box",
    // maxHeight:"300px",
    overflowY: "auto",
    maxHeight: "110vh",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  rightView: {
    borderLeft: "1px solid #EDEDED",
    padding: "48px",
    width: "956px",
    boxSizing: "content-box",
    [theme.breakpoints.down("1440")]: {
      width: "900px",
    },
    [theme.breakpoints.down("1385")]: {
      width: "800px",
    },
    [theme.breakpoints.down("1285")]: {
      width: "700px",
    },
    [theme.breakpoints.down("1185")]: {
      width: "600px",
    },
  },

  jobPostsCountText: {
    fontWeight: 600,
    fontSize: "14px",
    textTransform: "uppercase",
    // marginBottom: "5px",
  },
  sortOrFilterText: {
    fontWeight: 600,
    fontSize: "16px",
    // marginBottom: "5px",
  },
  sortRadioGroupDiv: {
    marginLeft: "24px",
  },
  sortTextAndIconDiv: {
    display: "flex",
    alignItems: "center",
    columnGap: "8px",
  },
  accordion: {
    boxShadow: "none",
  },
  accordionTitle: {
    fontWeight: 600,
    fontSize: "16px",
  },

  paginationContainer: {
    float: "right",
  },
  disabledLeftView: {
    pointerEvents: "none",
    opacity: 0.5,
  },
}));

const ClientJobs = ({ empId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { experiences, contractTypes } = useSelector((state) => state.misc);

  const jobsPerPage = 8;
  const sortOptions = [
    {
      label: "Relevance",
      value: "Relevance",
    },
    {
      label: "Date Posted",
      value: "Date Posted",
    },
    {
      label: (
        <div className={classes.sortTextAndIconDiv}>
          # of Open Positions <img src="/iconlightarrow-top.svg" />
        </div>
      ),
      value: "# of Open Positions",
    },
    {
      label: (
        <div className={classes.sortTextAndIconDiv}>
          # of Applicants <img src="/iconlightarrow-top.svg" />
        </div>
      ),
      value: "# of Applicants",
    },
  ];

  const [jobs, setJobs] = useState([]);
  const [sortedAndFilteredJobs, setSortedAndFilteredJobs] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [initialRender, setInitialRender] = useState(true);

  // #region Filters states
  const [sortSelectionValue, setSortSelectionValue] = useState(
    sortOptions[0].value
  );
  const [statusFiltersData, setStatusFiltersData] = useState([]);
  const [checkedStatusNames, setCheckedStatusNames] = useState([]);
  const [jobNameSearchText, setJobNameSearchText] = useState("");
  // #endregion Filters states

  // #region pagination states
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  // #endregion pagination states

  const onSortChange = (id, currentValue) => {
    setSortSelectionValue(currentValue);
  };

  const onStatusFilterChange = (checkboxValue, checkedState) => {
    setStatusFiltersData((prevItems) => {
      const updatedItems = prevItems?.map((item) => {
        if (item.value === checkboxValue) {
          return { ...item, checked: checkedState };
        }
        return item;
      });

      return updatedItems;
    });

    setCheckedStatusNames((prevState) =>
      checkedState
        ? [...prevState, checkboxValue]
        : prevState.filter((statusName) => statusName !== checkboxValue)
    );
  };

  const isLastJob = (index) => index === sortedAndFilteredJobs.length - 1;

  const getStatusFilters = (jobData) => {
    let filters = [];
    let statusAndCountMap = new Map();
    jobData?.forEach((jobEntry) => {
      let status = jobEntry?.status;
      if (isValidString(status)) {
        const statusCountInMap = statusAndCountMap.get(status);
        statusAndCountMap.set(
          status,
          statusCountInMap ? statusCountInMap + 1 : 1
        );
      }
    });
    statusAndCountMap.forEach((statusEntryCount, status) => {
      filters.push({
        label: `${capitalizeFirstLetter(status)} (${statusEntryCount})`,
        value: `${status}`,
        checked: false,
      });
    });
    return filters;
  };

  const filterByJobName = (jobDataReceived) => {
    jobDataReceived = jobDataReceived.filter((jobEntry) => {
      const jobTitle = jobEntry.jobTitle;
      return isValidString(jobTitle)
        ? jobTitle.toLowerCase().includes(jobNameSearchText.toLowerCase())
        : false;
    });
    return jobDataReceived;
  };

  const sortBySortSelection = (jobDataReceived) => {
    switch (sortSelectionValue) {
      case "Relevance": // Pinned job comes first then for remaining jobs it sorts as per job status in the order given below.
        jobDataReceived.sort((jobEntry1, jobEntry2) => {
          if (jobEntry1.is_pinned && !jobEntry2.is_pinned) {
            return -1; // jobEntry1 comes before jobEntry2
          } else if (!jobEntry1.is_pinned && jobEntry2.is_pinned) {
            return 1; // jobEntry2 comes before jobEntry1
          }

          const statusOrder = {
            active: 1,
            inactive: 2,
            draft: 3,
            closed: 4,
          };

          return statusOrder[jobEntry1.status] - statusOrder[jobEntry2.status];
        });
        break;
      case "Date Posted":
        jobDataReceived.sort((jobEntry1, jobEntry2) => {
          const date1 = jobEntry1.createdAt
            ? new Date(jobEntry1.createdAt)
            : new Date(0); // Treats undefined createdAt dates as oldest possible date
          const date2 = jobEntry2.createdAt
            ? new Date(jobEntry2.createdAt)
            : new Date(0);
          return date2 - date1;
        });
        break;
      case "# of Open Positions":
        jobDataReceived.sort((jobEntry1, jobEntry2) => {
          return jobEntry2.openPositions - jobEntry1.openPositions;
        });
        break;
      case "# of Applicants":
        jobDataReceived.sort((jobEntry1, jobEntry2) => {
          return jobEntry2.totalApplicants - jobEntry1.totalApplicants;
        });
        break;
      default:
        break;
    }

    return jobDataReceived;
  };

  const filterByStatus = (jobDataReceived) => {
    if (isValidArray(checkedStatusNames)) {
      jobDataReceived = jobDataReceived.filter((jobEntry) =>
        checkedStatusNames.includes(jobEntry?.status)
      );
    }

    return jobDataReceived;
  };

  const updateFilteredJobData = () => {
    let filteredJobData = JSON.parse(JSON.stringify(jobs));
    filteredJobData = filterByJobName(filteredJobData);
    filteredJobData = sortBySortSelection(filteredJobData);
    filteredJobData = filterByStatus(filteredJobData);
    setSortedAndFilteredJobs(filteredJobData);
    setTotalPagesCount(Math.ceil(filteredJobData.length / jobsPerPage));
    setCurrentPage(1);
  };

  const onPageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
  }, [dispatch]);

  useEffect(() => {
    // const receivedJobData = sortBySortSelection(dummyJobData);
    // const statusFilters = getStatusFilters(dummyJobData);
    // setStatusFiltersData(statusFilters);
    // setJobs(receivedJobData);
    // setSortedAndFilteredJobs(receivedJobData);
    // setTotalPagesCount(Math.ceil(receivedJobData.length / jobsPerPage));
    // setInitialRender(false);
    // setDataLoading(false);
    // console.log("receivedJobData12")

    /** Remove above logic and uncomment below part once api is ready  */
    getClientJobList(empId)
    .then((res) => {
      if (isValidArray(res?.data)) {
        let receivedJobData = JSON.parse(JSON.stringify(res?.data));
        //// console.log("receivedJobData12",receivedJobData)
        receivedJobData = sortBySortSelection(receivedJobData);
        const statusFilters = getStatusFilters(receivedJobData);
        const totalJobCount = receivedJobData?.length;
          const pageCount =
            totalJobCount > 0
              ? Math.ceil(totalJobCount / jobsPerPage)
              : 0;
              setTotalPagesCount(pageCount);
        setStatusFiltersData(statusFilters);
        setJobs(receivedJobData);
        setSortedAndFilteredJobs(receivedJobData);
        setInitialRender(false);
      } else {
        setJobs([]);
        setSortedAndFilteredJobs([]);
        setStatusFiltersData([]);
      }
      setDataLoading(false);
    })
    .catch((err) => {
      setDataLoading(false);
      setDataError(true);
    });
  }, []);

  useEffect(() => {
    if (!initialRender) updateFilteredJobData();
  }, [jobNameSearchText, sortSelectionValue, checkedStatusNames]);

  return (
    <div className={classes.jobFeedContainer}>
      <Title title="Findpro's Posted Jobs" />

      {dataError ? (
        <Alert severity="error" className="w-full h-fit mt-[16px]">
          {messages?.GENERIC_ERROR_MESSAGE}{" "}
        </Alert>
      ) : (
        <Grid container>
          <CustomTooltip
            title={
              initialRender && !isValidArray(sortedAndFilteredJobs)
                ? "Since no data is available,the sort and filter options are disabled."
                : null
            }
          >
            <div>
              <Grid
                item
                className={clsx([
                  classes.leftView,
                  initialRender && !isValidArray(sortedAndFilteredJobs)
                    ? classes.disabledLeftView
                    : "",
                ])}
              >
                <Typography className={classes.jobPostsCountText}>
                  {sortedAndFilteredJobs?.length ?? 0} total unique jobs posted
                </Typography>

                <CustomTextfield
                  id="searchText"
                  value={jobNameSearchText}
                  placeholder="Search by job name"
                  onChange={(id, value) => {
                    setJobNameSearchText(value);
                  }}
                  noErrorMessage={true}
                />

                <div>
                  <Typography className={classes.sortOrFilterText}>
                    Sort{" "}
                  </Typography>
                  <div className={classes.sortRadioGroupDiv}>
                    <CustomRadioGroup
                      id="sort-radio-group"
                      name="sort-radio-group"
                      defaultValue={sortSelectionValue}
                      data={sortOptions}
                      onChange={onSortChange}
                    />
                  </div>
                </div>

                <div>
                  <Typography className={classes.sortOrFilterText}>
                    Filter
                  </Typography>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.accordionTitle}>
                        Status
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {" "}
                      <CustomCheckboxGroup
                        id="status-checkbox-group"
                        data={statusFiltersData}
                        onChange={onStatusFilterChange}
                      />
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Grid>
            </div>
          </CustomTooltip>
          <Grid item className={classes.rightView}>
            {dataLoading ? (
              <ContentLoader
                speed={2}
                width={1400}
                height={800}
                viewBox="0 0 1400 800"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="5" y="50" rx="3" ry="40" width="250" height="700" />
                <rect x="280" y="50" rx="3" ry="40" width="1300" height="700" />
              </ContentLoader>
            ) : (
              <>
                {isValidArray(sortedAndFilteredJobs) ? (
                  <>
                    {sortedAndFilteredJobs
                      .slice(
                        currentPage * jobsPerPage - jobsPerPage,
                        currentPage * jobsPerPage
                      )
                      .map((postedJob, idx) => (
                        <>
                          <JobListCard
                            key={idx}
                            data={postedJob}
                            experiencesData={experiences}
                            contractTypesData={contractTypes}
                          />
                          {!isLastJob(idx) && <hr className="my-[20px]" />}
                        </>
                      ))}
                    <Pagination
                      color="primary"
                      count={totalPagesCount}
                      page={currentPage}
                      onChange={onPageChange}
                      classes={{ ul: classes.paginationContainer }}
                    />
                  </>
                ) : (
                  <Alert severity="info" className="w-full h-fit mt-[16px]">
                    {" "}
                    {messages?.NO_DATA_FOUND}{" "}
                  </Alert>
                )}
              </>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

ClientJobs.propTypes = {};

export default ClientJobs;
