import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import CustomSearch from "./CustomSearch";
import CustomSelect from "./CustomSelect";
import CustomSwitch from "./CustomSwitch";
import CustomAutocomplete from "./CustomAutocomplete";
import MinMaxCustomslider from "./MinMaxCustomslider";

import CustomAccordion from "./CustomAccordion";
import TwoLabelSwitch from "./TwoLabelSwitch";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import LocationSelector from "./LocationSelector";
import JobPreference from "./JobPreference";
import SearchIcon from "@mui/icons-material/Search";
import CustomButton from "../CustomButton";
import { getAccountType, isValidArray } from "../../Pages/Utils/utilFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSkillsThunk,
  fetchExperiencesThunk,
  fetchContractTypesThunk,
} from "app/v2/store/features/misc/miscThunk";
import { Input } from "app/v2/Pages/common/Input";
import {
  getSkillName,
  getExperience,
  getContractTypeLevel,
} from "app/v2/Pages/common/getIdTypeLevel";
import { isValidString, isValidObject } from "../../Pages/Utils/utilFunctions";

const componentData = {
  select: CustomSelect,
  switch: CustomSwitch,
  twoLabelSwitch: TwoLabelSwitch,
  autocomplete: CustomAutocomplete,
  slider: MinMaxCustomslider,
  locationSelector: LocationSelector,
  jobPreference: JobPreference,
};

const useStyles = makeStyles((theme) => ({
  filtersAndSearchRoot: {
    padding: "45px",
    [theme.breakpoints.down('sm')]: {
      padding: "25px",
      width: "380px"
    },
  },
  filtersContainer: {
    maxHeight: "80vh",
    overflow: "auto",
    marginBottom: "16px",
    width: "500px", //remove this widht when you add all the seacrh filter
    [theme.breakpoints.down('sm')]: {
      width: "auto",
    },
  },
  filterComponentDiv: {
    display: "flex",
    flexDirection: "column",
    rowGap: "12px",
    justifyContent: "center",
  },
  rowFlexBox: {
    flexDirection: "row",
  },
  searchBox: {
    width: "100%",
    fontSize: "16px",
    fontWeigt: 600,
  },
  searchIcon: {
    marginLeft: "12px",
    width: "18px",
    height: "18px",
  },
  clearFilterButtonDiv: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const GeneratedComponent = ({ componentType, onChange, ...otherProps }) => {
  const Component = componentData[componentType];

  return Component ? <Component onChange={onChange} {...otherProps} /> : null;
};

const ClientFilters = ({ data, selectedFilters, onChange }) => {
  const classes = useStyles();

  const {
    experiences,
    experiencesData,
    skills,
    skillNames,
    contractTypes,
    contractLevels,
  } = data;

  let selectedExperience = getExperience(
    selectedFilters?.experience,
    experiences
  );
  let selectedContractLevel = getContractTypeLevel(
    selectedFilters?.contractType,
    contractTypes
  );
  selectedExperience = selectedExperience === "-" ? "" : selectedExperience;
  selectedContractLevel =
    selectedContractLevel === "-" ? "" : selectedContractLevel;
  const selectedPriceRange =
    isValidArray(selectedFilters?.priceRange) &&
    selectedFilters?.priceRange.length === 2
      ? selectedFilters?.priceRange
      : [];
  const selectedSkillIDs = selectedFilters?.skills;
  let selectedSkillNames = [];
  if (isValidArray(selectedSkillIDs)) {
    selectedSkillIDs.forEach((skillID) => {
      const skillName = getSkillName(skillID, skills);
      if (isValidString(skillName) && skillName !== "-")
        selectedSkillNames.push(skillName);
    });
  }

  const filters = [
    {
      heading: "SORT BY",
      componentData: {
        flexDirection: "column", // default(by default entire component div for each filter section will be a flexbox with column as direction).
        components: [
          {
            id: "sortBy",
            componentType: "select",
            data: ["A to Z", "Z to A", "Latest", "Low Rate", "High Rate"],
            label: "",
            placeholder: "Select",
            defaultSelection: selectedFilters?.sortBy ?? "",
          },
        ],
        expandedState: isValidString(selectedFilters?.sortBy),
      },
    },
    {
      heading: "SEARCH TYPE",
      componentData: {
        flexDirection: "row",
        components: [
          {
            id: "searchTypeAgencies",
            componentType: "twoLabelSwitch",
            label1: "",
            label2: "Consultants",
            defaultSelection: true,
            disabled: true,
          },
        ],
      },
    },
    {
      heading: "LOCATION",
      componentData: {
        flexDirection: "column",
        components: [
          {
            // id: "location",
            componentType: "locationSelector", // This component does not require id as it passes conutry/state/city/timezone as id from the component itself.
            defaultCountry: selectedFilters?.country ?? "",
            defaultState: selectedFilters?.state ?? "",
            defaultCity: selectedFilters?.city ?? "",
            defaultTimezone: selectedFilters?.timezone ?? "",
          },
        ],
        expandedState:
          isValidString(selectedFilters?.country) ||
          isValidString(selectedFilters?.state) ||
          isValidString(selectedFilters?.city) ||
          isValidString(selectedFilters?.timezone),
      },
    },
    {
      heading: "SKILLS",
      componentData: {
        flexDirection: "column",
        components: [
          {
            componentType: "autocomplete",
            id: "skills",
            data: skillNames,
            defaultSelection: selectedSkillNames,
            allowMultipleSelection: true,
          },
        ],
        expandedState: isValidArray(selectedSkillNames),
      },
    },
    {
      heading: "WORK HISTORY",
      componentData: {
        flexDirection: "column",
        components: [
          {
            componentType: "select", // shows data based on api's data or data passed.
            label: "EXPERIENCE",
            data: experiencesData,
            defaultSelection: selectedExperience,
            id: "experience",
          },
        ],
        expandedState: isValidString(selectedExperience),
      },
    },
    {
      heading: "OTHER PREFERENCES",
      componentData: {
        flexDirection: "column",
        components: [
          {
            componentType: "select", // shows data based on api's data or data passed.
            label: "CONTRACT TYPE",
            data: contractLevels,
            defaultSelection: selectedContractLevel,
            id: "contractType",
          },
          {
            componentType: "slider", // shows data based on api's data or data passed.
            label: "PRICE RANGE",
            min: 1,
            max: 200,
            selectedRange: selectedPriceRange,
            startText: "$", // if any to show before the selected range. Eg: currency for price range.
            id: "priceRange",
          },
        ],
        expandedState:
          isValidString(selectedContractLevel) ||
          isValidArray(selectedPriceRange),
      },
    },
    // {
    //   heading: "BACKGROUND",
    //   componentData: {
    //     flexDirection: "column",
    //     components: [
    //       {
    //         componentType: "select", // shows data based on api's data or data passed.
    //         label: "Language Proficient in",
    //         data: ["English", "Hindi", "Telugu"],
    //         placeholder: "Select",
    //         id: "language",
    //         // defaultSelection: "5+ Years", // should match one of the values in data,else no option is shown as default selection.
    //       },
    //       {
    //         id: "background-check",
    //         componentType: "switch",
    //         label: "Background Check",
    //         defaultSelection: true,
    //       },
    //     ],
    //   },
    // },
  ];

  return (
    <>
      {filters.map((filterInfo) => (
        <CustomAccordion
          key={filterInfo.heading}
          title={filterInfo.heading}
          expandedState={filterInfo.componentData?.expandedState ?? false}
        >
          <div
            className={clsx([
              classes.filterComponentDiv,
              filterInfo.componentData.flexDirection === "row" &&
                classes.rowFlexBox,
            ])}
          >
            {filterInfo.componentData.components.map((componentInfo) => (
              <GeneratedComponent
                key={componentInfo.id}
                {...componentInfo}
                onChange={onChange}
              />
            ))}
          </div>
        </CustomAccordion>
      ))}
    </>
  );
};

const ConsultantOrAgencyFilters = ({ data, selectedFilters, onChange }) => {
  const classes = useStyles();

  const {
    experiences,
    experiencesData,
    skills,
    skillNames,
    contractTypes,
    contractLevels,
  } = data;

  let selectedExperience = getExperience(
    selectedFilters?.experience,
    experiences
  );
  let selectedContractLevel = getContractTypeLevel(
    selectedFilters?.contractType,
    contractTypes
  );
  selectedExperience = selectedExperience === "-" ? "" : selectedExperience;
  selectedContractLevel =
    selectedContractLevel === "-" ? "" : selectedContractLevel;
  const selectedPriceRange =
    isValidArray(selectedFilters?.priceRange) &&
    selectedFilters?.priceRange.length === 2
      ? selectedFilters?.priceRange
      : [];
  const selectedSkillIDs = selectedFilters?.skills;
  let selectedSkillNames = [];
  if (isValidArray(selectedSkillIDs)) {
    selectedSkillIDs.forEach((skillID) => {
      const skillName = getSkillName(skillID, skills);
      if (isValidString(skillName) && skillName !== "-")
        selectedSkillNames.push(skillName);
    });
  }

  const filters = [
    {
      heading: "SORT BY",
      componentData: {
        flexDirection: "column", // default(by default entire component div for each filter section will be a flexbox with column as direction).
        components: [
          {
            id: "sortBy",
            componentType: "select",
            data: ["A to Z", "Z to A", "Latest", "Low Rate", "High Rate"],
            label: "",
            placeholder: "Select",
            defaultSelection: selectedFilters?.sortBy ?? "",
          },
        ],
        expandedState: isValidString(selectedFilters?.sortBy),
      },
    },
    {
      heading: "LOCATION",
      componentData: {
        flexDirection: "column",
        components: [
          {
            // id: "location", // This component does not require id as it passes conutry/state/city/timezone as id from the component itself.
            componentType: "locationSelector",
            defaultCountry: selectedFilters?.country ?? "",
            defaultState: selectedFilters?.state ?? "",
            defaultCity: selectedFilters?.city ?? "",
            defaultTimezone: selectedFilters?.timezone ?? "",
            noErrorMessage: true,
          },
        ],
        expandedState:
          isValidString(selectedFilters?.country) ||
          isValidString(selectedFilters?.state) ||
          isValidString(selectedFilters?.city) ||
          isValidString(selectedFilters?.timezone),
      },
    },
    {
      heading: "SKILLS",
      componentData: {
        flexDirection: "column",
        components: [
          {
            componentType: "autocomplete",
            id: "skills",
            data: skillNames,
            defaultSelection: selectedSkillNames,
            allowMultipleSelection: true,
          },
        ],
        expandedState: isValidArray(selectedSkillNames),
      },
    },
    {
      heading: "WORK HISTORY",
      componentData: {
        flexDirection: "column",
        components: [
          {
            componentType: "select", // shows data based on api's data or data passed.
            label: "EXPERIENCE",
            data: experiencesData,
            defaultSelection: selectedExperience,
            id: "experience",
          },
        ],
        expandedState: isValidString(selectedExperience),
      },
    },
    {
      heading: "CONSULTANT PREFERENCES",
      componentData: {
        flexDirection: "column",
        components: [
          {
            componentType: "select", // shows data based on api's data or data passed.
            label: "CONTRACT TYPE",
            data: contractLevels,
            defaultSelection: selectedContractLevel,
            id: "contractType",
          },
          {
            componentType: "slider", // shows data based on api's data or data passed.
            label: "PRICE RANGE",
            min: 1,
            max: 200,
            selectedRange: selectedPriceRange,
            startText: "$", // if any to show before the selected range. Eg: currency for price range.
            id: "priceRange",
          },
          {
            componentType: "select", // shows data based on api's data or data passed.
            label: "JOB MODE",
            data: ["Onsite", "Hybrid", "Remote"],
            defaultSelection: selectedFilters?.jobMode,
            id: "jobMode",
          },
        ],
        expandedState:
          isValidString(selectedContractLevel) ||
          isValidArray(selectedPriceRange) ||
          isValidString(selectedFilters?.jobMode),
      },
    },
    // {
    //   heading: "BACKGROUND",
    //   componentData: {
    //     flexDirection: "column",
    //     components: [
    //       {
    //         componentType: "select", // shows data based on api's data or data passed.
    //         label: "Language Proficient in",
    //         data: ["English", "Hindi", "Telugu"],
    //         placeholder: "Select",
    //         id: "language",
    //         // defaultSelection: "5+ Years", // should match one of the values in data,else no option is shown as default selection.
    //       },
    //     ],
    //   },
    // },
  ];

  return (
    <>
      {filters.map((filterInfo) => (
        <CustomAccordion
          key={filterInfo.heading}
          title={filterInfo.heading}
          expandedState={filterInfo.componentData?.expandedState ?? false}
        >
          <div
            className={clsx([
              classes.filterComponentDiv,
              filterInfo.componentData.flexDirection === "row" &&
                classes.rowFlexBox,
            ])}
          >
            {filterInfo.componentData.components.map((componentInfo) => (
              <GeneratedComponent
                key={componentInfo.id}
                {...componentInfo}
                onChange={onChange}
              />
            ))}
          </div>
        </CustomAccordion>
      ))}
    </>
  );
};

const Filters = ({ drawerDetails, setDrawerDetails }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const accountType = getAccountType();
  const { experiences, skills, contractTypes } = useSelector(
    (state) => state.misc
  );

  const [experiencesData, setExperiencesData] = useState([]);
  const [contractLevels, setContractLevels] = useState([]);
  const [skillNames, setSkillNames] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(() => {
    const savedFilters = localStorage.getItem("selectedFilters");
    return savedFilters ? JSON.parse(savedFilters) : {};
  });
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    if (!skills?.length) dispatch(fetchSkillsThunk());
    if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
  }, [dispatch]);

  useEffect(() => {
    if (isValidArray(experiences)) {
      const experiencesData = experiences.map(
        (experienceEntry) =>
          `${experienceEntry?.level ?? ""} (${
            experienceEntry?.minExp ?? ""
          } - ${experienceEntry?.maxExp ?? ""} years)`
      );
      setExperiencesData(experiencesData);
    }
  }, [experiences]);

  useEffect(() => {
    if (isValidArray(contractTypes)) {
      const contractLevels = contractTypes.map(
        (contractType) => contractType?.level
      );
      setContractLevels(contractLevels);
    }
  }, [contractTypes]);

  useEffect(() => {
    if (isValidArray(skills)) {
      const skillNames = skills.map((skill) => skill?.name);
      setSkillNames(skillNames);
    }
  }, [skills]);

  useEffect(() => {
    // // console.log({ selectedFilters });
  }, [selectedFilters]);

  const onChange = (filterId, updatedValue) => {
    // // console.log({ filterId, updatedValue });

    if (filterId === "experience") {
      const experienceEntryIndex = experiencesData?.indexOf(updatedValue);
      const experienceID = experiences?.[experienceEntryIndex]?._id;
      updatedValue = experienceID;
    } else if (filterId === "contractType") {
      const contractTypeEntry = contractLevels?.indexOf(updatedValue);
      const contractTypeID = contractTypes?.[contractTypeEntry]?._id;
      updatedValue = contractTypeID;
    } else if (filterId === "skills") {
      let skillIDs = [];
      if (isValidArray(updatedValue)) {
        updatedValue.forEach((skillName) => {
          const skillEntry = skills.find((skill) => skill?.name === skillName);
          if (skillEntry?._id) skillIDs.push(skillEntry?._id);
        });
      }
      updatedValue = skillIDs;
    }

    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterId]: updatedValue ? updatedValue : "",
    }));
  };

  const onSearch = () => {
    const finalFilters = selectedFilters;
    const queryParams = new URLSearchParams();
    let destinationURL;

    for (const key in finalFilters) {
      if (finalFilters.hasOwnProperty(key)) {
        queryParams.set(key, finalFilters[key]);
      }
    }
    queryParams.set("hideComponent", "true");

    if (finalFilters && finalFilters.searchTypeAgencies === false) {
      if (accountType === "consultant") {
        destinationURL = "/consultant/job-feed";
      } else if (accountType === "client") {
        destinationURL = "/client/agency-feed";
      } else if (accountType === "agency") {
        destinationURL = "/agency/agency-feed";
      }
    } else {
      if (accountType === "consultant") {
        destinationURL = "/consultant/job-feed";
      } else if (accountType === "client") {
        destinationURL = "/client/consultant-feed";
      } else if (accountType === "agency") {
        destinationURL = "/agency/job-feed";
      }
    }

    setDrawerDetails({ showDrawer: false, componentType: "" });
    localStorage.setItem("selectedFilters", JSON.stringify(selectedFilters));
    history.push(`${destinationURL}?${queryParams.toString()}`);
  };

  const onClearFilters = () => {
    localStorage.removeItem("selectedFilters");
    setSelectedFilters({});
    setRerender(!rerender);
  };

  return (
    <div className={classes.filtersAndSearchRoot}>
      {isValidObject(selectedFilters) ? (
        <div className={classes.clearFilterButtonDiv}>
          <CustomButton
            type="button1"
            customClasses="text-[14px]"
            onClick={onClearFilters}
          >
            Clear Filters
          </CustomButton>
        </div>
      ) : (
        <></>
      )}

      <CustomSearch
        id="jobTitle"
        tittle={"Search All Jobs"}
        onChange={onChange}
        value={selectedFilters?.jobTitle}
      />
      <div className={classes.filtersContainer}>
        {accountType === "client" ? (
          <ClientFilters
            data={{
              experiences,
              experiencesData,
              skills,
              skillNames,
              contractTypes,
              contractLevels,
            }}
            selectedFilters={selectedFilters}
            onChange={onChange}
            key={rerender}
          />
        ) : (
          <ConsultantOrAgencyFilters
            data={{
              experiences,
              experiencesData,
              skills,
              skillNames,
              contractTypes,
              contractLevels,
            }}
            selectedFilters={selectedFilters}
            onChange={onChange}
            key={rerender}
          />
        )}
      </div>
      <CustomButton
        type="button1"
        customClasses={classes.searchBox}
        onClick={onSearch}
      >
        Search <SearchIcon fontSize="small" className={classes.searchIcon} />{" "}
      </CustomButton>
    </div>
  );
};

Filters.propTypes = {};

export default Filters;
