import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import {
  deleteJobByIdThunk,
  pinJobThunk,
  setStatusJobByIdThunk,
} from "app/v2/store/features/employer/jobFeed/jobFeed.thunk";
import { EditSVG, DeactivateSVG, DeleteSVG } from "app/v2/Pages/SVGs/Index";
import { PinSVG } from "app/v2/Pages/SVGs/Pin";
import { CheckSVG } from "app/v2/Pages/SVGs/Check";
import SocialShare from "app/v2/Pages/common/SocialShare";
import CustomTooltip from "app/v2/components/CustomTooltip";
import CustomButton from "app/v2/components/CustomButton";

import { isFunction } from "app/v2/Pages/Utils/utilFunctions";
import JobStatus from "../../../Pages/common/Status/Job";
// import { JobDetailObj } from "../../../Details/job/JobDetail.dummy";
import configs from "app/v2/Pages/Utils/configs";
import { messages } from "app/v2/Pages/Utils/constants";
import {
  getContractTypeLevel,
  getExperience,
} from "app/v2/Pages/common/getIdTypeLevel";
import CloseIcon from "@mui/icons-material/Close";
import DeleteJobDialog from "../../Employer/common/DeleteJobDialog";
import { Pagination } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  jobDiv: {
    width:"100%",
    "&:hover": {
      boxShadow: "0px 4px 36px rgba(0, 0, 0, 0.12)",
      transform: "translateY(-25px)",
    },
    padding: "36px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  draftJobDiv: {
    backgroundColor: "#F7F7F7",
  },
  pinnedJobDiv: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  pinnedIconButton: {
    backgroundColor: "#F4F8FF ",
  },
  postedUpdatedText: {
    fontSize: "14px",
    color: "#717171",
  },
  actionButton: {
    padding: "10px 20px 10px 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "20px",
    boxSizing: "content-box",
    border: "1px solid #363636",
    columnGap: "10px",
    fontSize: "14px",
    fontWeight: 600,
    "&:disabled": {
      backgroundColor: `${theme.palette.secondary.light} !important`,
      color: "black !important",
    },
  },
  iconActionButton: {
    padding: "10px",
    border: "1px solid #EDEDED",
    width: "20px",
    height: "20px",
    boxSizing: "content-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:disabled": {
      backgroundColor: `${theme.palette.secondary.light} !important`,
    },
  },
 
}));

const DetailSection = ({ type, value, center = false }) => {
  value = value === "" ? "-" : value;
  return (
    <div className={`flex-1 ${center ? "text-center" : ""}`}>
      <div className="uppercase text-[12px] font-semibold text-[#717171]">
        {type}
      </div>
      <div className="text-[18px] sm:text-[11px] font-bold">{value ?? "-"}</div>
    </div>
  );
};

const Actions = ({ jobData, onJobDelete, isProfileComplete, isSameUserId }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const homeUrl = configs?.homeUrl;
  const publicJobDetailURL = homeUrl + `job-detail/${jobData?._id}`;

  const jobStatus = jobData?.status;
  const [deleteJobDialogOpen, setDeleteJobDialogOpen] = useState(false);
  const [deleteActionLoading, setDeleteActionLoading] = useState(false);
  const [deleteActionCompleted, setDeleteActionCompleted] = useState(false);
  const [deleteActionError, setDeleteActionError] = useState(false);

  const onDeleteClick = () => {
    setDeleteActionLoading(true);

    // setTimeout(() => {
    //   /** Confirm case */
    //   setDeleteActionLoading(false);
    //   setDeleteActionCompleted(true);
    //   /** Error case */
    //   // setDeleteActionLoading(false);
    //   // setDeleteActionError(true);
    // }, 2000);

    dispatch(deleteJobByIdThunk(jobData?._id))
      .unwrap()
      .then((result) => {
        setDeleteActionLoading(false);
        setDeleteActionCompleted(true);
        isFunction(onJobDelete) && onJobDelete(jobData?._id);
      })
      .catch((error) => {
        setDeleteActionLoading(false);
        setDeleteActionError(true);
      });
  };

  const onReactivateClick = () => {
    // enqueueSnackbar(messages.ACTION_LOADING_MESSAGE, {
    //   variant: "info",
    // });

    const key = enqueueSnackbar(messages.ACTION_LOADING_MESSAGE, {
      variant: "info",
      autoHideDuration: 3000,
      action: (key) => (
        <CloseIcon
          onClick={() => closeSnackbar(key)}
          style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
        />
      ),
    });
    dispatch(
      setStatusJobByIdThunk({
        jobID: jobData?._id,
        status: "active",
      })
    )
      .unwrap()
      .then((result) => {
        // // console.log("activated:", result);
        // enqueueSnackbar("Your job has been activated successfully!", {
        //   variant: "success",
        // });
        const key = enqueueSnackbar(
          "Your job has been activated successfully!",
          {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
        history.replace("/client/job-feed");
      })
      .catch((error) => {
        console.error("Error activate job:", error);
        // enqueueSnackbar(messages?.GENERIC_ERROR_MESSAGE, {
        //   variant: "error",
        // });

        const key = enqueueSnackbar(messages?.GENERIC_ERROR_MESSAGE, {
          variant: "error",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
      });
  };

  const onDeactivateClick = () => {
    // enqueueSnackbar(messages.ACTION_LOADING_MESSAGE, {
    //   variant: "info",
    // });

    const key = enqueueSnackbar(messages.ACTION_LOADING_MESSAGE, {
      variant: "info",
      autoHideDuration: 3000,
      action: (key) => (
        <CloseIcon
          onClick={() => closeSnackbar(key)}
          style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
        />
      ),
    });
    dispatch(
      setStatusJobByIdThunk({
        jobID: jobData?._id,
        status: "inactive",
      })
    )
      .unwrap()
      .then((result) => {
        // enqueueSnackbar("Your job has been deactivated successfully!", {
        //   variant: "success",
        // });

        const key = enqueueSnackbar(
          "Your job has been deactivated successfully!",
          {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
        history.replace("/client/job-feed");
      })
      .catch((error) => {
        // enqueueSnackbar(messages?.GENERIC_ERROR_MESSAGE, {
        //   variant: "error",
        // });
        const key = enqueueSnackbar(messages?.GENERIC_ERROR_MESSAGE, {
          variant: "error",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
      });
  };

  const onPinClick = () => {
    const isPinned = jobData?.is_pinned;
    // enqueueSnackbar(messages.ACTION_LOADING_MESSAGE, {
    //   variant: "info",
    // });

    const key = enqueueSnackbar(messages.ACTION_LOADING_MESSAGE, {
      variant: "info",
      autoHideDuration: 3000,
      action: (key) => (
        <CloseIcon
          onClick={() => closeSnackbar(key)}
          style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
        />
      ),
    });
    dispatch(pinJobThunk(jobData?._id))
      .unwrap()
      .then((result) => {
        // enqueueSnackbar(
        //   `Your job has been ${isPinned ? "unpinned" : "pinned"} successfully!`,
        //   {
        //     variant: "success",
        //   }
        // );

        const key = enqueueSnackbar(
          `Your job has been ${isPinned ? "unpinned" : "pinned"} successfully!`,
          {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
        history.replace("/client/job-feed");
      })
      .catch((error) => {
        console.error("Error pin job:", error);
      });
  };

  const onJobEditClick = () => {
    history.push(`/client/edit-job/${jobData?._id}`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (!deleteJobDialogOpen) {
      // Setting back to default states- used when the dialog is closed.
      setDeleteActionLoading(false);
      setDeleteActionCompleted(false);
      setDeleteActionError(false);
    }
  }, [deleteJobDialogOpen]);

  return (
    <>
      {/* <CustomTooltip
        title={
          isSameUserId
            ? ""
            : "You do not have the necessary permissions to access it"
        }
        arrow
      > */}
      <div className="flex justify-between">
        <div className="flex gap-x-[12px]">
          <>
            <>
              <CustomButton
                onClick={() => {
                  // history.push(`/client/hiring-board/${jobData?._id}`);
                }}
                type="button1"
                customClasses={classes.actionButton}
                disabled={!isSameUserId}
              >
                Check Consultant Activity{" "}
                <img src="/iconlightup-right.svg" alt="arrow-icon" />
              </CustomButton>
            </>

            {/* {jobStatus === "active" && (
                <>
                  <CustomButton
                    onClick={() => {
                      history.push(`/client/hiring-board/${jobData?._id}`);
                    }}
                    type="button1"
                    customClasses={classes.actionButton}
                    disabled={!isSameUserId}
                  >
                    View Job Hiring Board
                    <img src="/board.svg" />
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      // history.push("/client/consultant-feed/" + jobData?._id);
                      history.push("/client/" + jobData?._id + "/consultants");
                    }}
                    customClasses={classes.actionButton}
                    disabled={!isSameUserId}
                  >
                    Find Consultants
                  </CustomButton>
                </>
              )} */}

            {/* Inactive status actions */}

            {/* {jobStatus === "inactive" && (
                <>
                  <CustomButton
                    customClasses={classes.actionButton}
                    onClick={onReactivateClick}
                    disabled={!isSameUserId}
                  >
                    <CheckSVG fillColor="#000" />
                    Reactivate
                  </CustomButton>
                </>
              )} */}

            {/* closed status actions */}
            {/* 
              {jobStatus === "closed" && (
                <CustomButton
                  type="button1"
                  customClasses={classes.actionButton}
                  disabled={!isSameUserId}
                >
                  Check Consultant Activity
                </CustomButton>
              )} */}

            {/* common actions(edit,deactivate,pin,share) for active,inactive,closed status- except for deactivate which is shown only for active,closed */}

            {/* <button
                onClick={onJobEditClick}
                className={classes.iconActionButton}
                disabled={!isSameUserId}
              >
                <img src="/edit.svg" />
              </button> */}
            {jobStatus !== "inactive" && (
              <button
                className={classes.iconActionButton}
                //   onClick={onDeactivateClick}
                //   disabled={!isSameUserId}
              >
                <DeactivateSVG />
              </button>
            )}
            {/* <CustomTooltip
                  title={
                    !isProfileComplete
                      ? "You cannot pin the job since your profile is incomplete."
                      : null
                  }
                  arrow
                  placement="bottom"
                > */}
            {/* <div>
                <button
                  className={clsx([
                    classes.iconActionButton,
                    jobData?.is_pinned && classes.pinnedIconButton,
                  ])}
                  onClick={onPinClick}
                  disabled={!isProfileComplete || !isSameUserId}
                >
                  <PinSVG fillColor={jobData?.is_pinned ? "#1E5DFF" : ""} />
                </button>
              </div> */}
            {/* </CustomTooltip> */}
            <CustomTooltip
              arrow
              title={
                isSameUserId ? (
                  <SocialShare shareLink={publicJobDetailURL} />
                ) : (
                  " "
                )
              }
              bg="white"
            >
              <button
                className={classes.iconActionButton}
                disabled={!isSameUserId}
              >
                <img src="/share.svg" />
              </button>
            </CustomTooltip>
          </>
        </div>

        {/* common delete action for any type of job status */}
        <CustomButton
          onClick={() => {
            setDeleteJobDialogOpen(true);
          }}
          disabled={!isSameUserId}
        >
          <DeleteSVG />
        </CustomButton>
      </div>
      {/* </CustomTooltip> */}
      {deleteJobDialogOpen && (
        <DeleteJobDialog
          open={deleteJobDialogOpen}
          setOpen={setDeleteJobDialogOpen}
          jobDetails={{
            jobNo: jobData?.jobNo,
            jobName: jobData?.jobTitle,
          }}
          onDeleteClick={onDeleteClick}
          deleteLoading={deleteActionLoading}
          deleteCompleted={deleteActionCompleted}
          deleteError={deleteActionError}
        />
      )}
    </>
  );
};

const JobListCard = ({
  data,
  onJobDelete,
  experiencesData,
  contractTypesData,
  isProfileComplete,
  handlePageChange,
  currentPage,
  totalPagesCount,
  createdat,
  updatedat
}) => {
  const classes = useStyles();
  const history = useHistory();

  const salaryTypeTextMapper = {
    Hourly: "hr",
    Monthly: "month",
    Annual: "yr",
  };
  const salary = data?.salary;
  const salaryRange = salary?.range;
  const location =
    [data?.country, data?.state, data?.city].filter(Boolean).join(", ") || "-";

  let createdAt = createdat ? moment(data?.createdAt).fromNow() : "-";
  createdAt = createdAt === "Invalid date" ? "-" : createdAt;
  let updatedAt = updatedat ? moment(data?.updatedAt).fromNow() : "-";
  updatedAt = updatedAt === "Invalid date" ? "-" : updatedAt;
  const isDraftJob = data?.status === "draft";

  const [isHovered, setIsHovered] = useState(false);

  const onJobTitleClick = () => {
    history.push(`/client/job-detail/${data?._id}`);
    window.scrollTo(0, 0);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const storedUserData = localStorage.getItem("user");
  const user_id = JSON.parse(storedUserData);
  const userId = user_id._id;

  const role_Id = localStorage.getItem("roleId");
  const roleName = localStorage.getItem("roleName");
  console.log("dataaaa2",data?.experienceLevelId)

  return (
    <>
      <div
        className={clsx([
          `flex flex-col gap-y-[36px]`,
          // data?.is_pinned && classes.pinnedJobDiv,
          // isDraftJob && classes.draftJobDiv,
          classes.jobDiv,
        ])}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="flex flex-col gap-y-[24px]">
          <div className="flex gap-x-[19px] sm:gap-x-[1px]">
            <div className="flex flex-col gap-y-[6px] flex-[3]">
              <button
                className="text-[24px] sm:text-[14px] font-semibold w-fit"
                onClick={onJobTitleClick}
              >
                {data?.jobTitle ?? ""}
              </button>
              <div className="flex gap-x-[12px]">
                <span className={classes.postedUpdatedText}>
                  Posted {createdAt}
                </span>
                <span className={classes.postedUpdatedText}>
                  Last Updated {updatedAt}
                </span>
                <span className="text-[14px] text-[#717171] font-semibold">
                  JOB NO. {data?.jobNo ?? "-"}
                </span>
              </div>
              {/* <div>Hiring Manager - </div> */}
            </div>
            <div className="self-start">
              <JobStatus type={data?.status} />
            </div>
          </div>
          <div className="flex gap-x-[16px]">
            {/* <DetailSection type="Open Positions" value={data?.openPositions} /> */}
            <DetailSection
              type="Experience"
              value={getExperience(data?.experienceLevelId, experiencesData)}
            />
            <DetailSection
              type="Contract type"
              value={getContractTypeLevel(
                data?.contractType,
                contractTypesData
              )}
            />
            <DetailSection type="Work Type" value={data?.workType} />
            <DetailSection
              type={salaryRange ? "Salary Range" : `${salary?.type ?? ""} Rate`}
              value={
                <span>
                  ${" "}
                  {salaryRange
                    ? `${salaryRange?.min} - $ ${salaryRange?.max}/${
                        salaryTypeTextMapper?.[salary?.type] ?? "-"
                      }`
                    : `${salary?.fixedRate ?? "-"}`}
                </span>
              }
            />
          </div>
          <div className="flex gap-x-[16px]">
            <div className="flex gap-x-[16px] flex-[0.8]">
              <DetailSection type="location" value={location} />
              <DetailSection type="timezone" value={data?.timezone} />
            </div>
            <div className="p-[12px] bg-[#F7F7F7] flex gap-x-[10px] flex-[0.3]">
              <DetailSection
                type="Total Applicants"
                value={data?.totalApplicants ?? 0}
                center={true}
              />
              <DetailSection
                type="Accepted"
                value={data?.acceptedApplicants ?? 0}
                center={true}
              />
            </div>
          </div>
        </div>

        {/* {isHovered && (
          <Actions
            jobData={data}
            onJobDelete={onJobDelete}
            isProfileComplete={isProfileComplete}
            isSameUserId={
              userId === data?.emp_id || roleName?.includes("Admin")
            }
          />
        )} */}
      </div>

    
    </>
  );
};

JobListCard.propTypes = {};

export default JobListCard;
