import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";

export const getCandidateList = async (limit, offset) => {
  return fetchWrapper(
    `${configs.APIUrl}/client/consultants` + `?limit=${limit}&offset=${offset}`
  );
};

export const inviteConsultantForJob = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/consultant-request`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const activeConsultants = async () => {
  return fetchWrapper(`${configs.APIUrl}/client/activeConsultants`);
};

export const getHistoryActiveConsultant = async (data) => {
  return fetchWrapper(`${configs.APIUrl}/client/histories?id=${data["id"]}&cat=${data["filterBy"]}&limit=${data["limit"]}&offset=${data["offset"]}&startDate=${data["startDate"]}&endDate=${data["endDate"]}`);
};


