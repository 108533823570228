import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { Typography, CircularProgress, Alert } from "@mui/material";
import CustomButton from "app/v2/components/CustomButton";
import CustomTooltip from "app/v2/components/CustomTooltip";
import CustomDialog from "app/v2/components/CustomDialog";
import DialogSuccessBody from "app/v2/Pages/common/DialogSuccessBody";
import DialogErrorBody from "app/v2/Pages/common/DialogErrorBody";
import DialogLoadingBody from "app/v2/Pages/common/DialogLoadingBody";
import ProfileView from "./ProfileView";
import ProfileEdit from "./ProfileEdit";
import SocialShare from "app/v2/Pages/common/SocialShare";
import CloseIcon from "@mui/icons-material/Close";

import {
  defaultSkillList,
  messages,
  URL_PATTERN,
  DEFAULT_EXPERIENCE_ID,
  DEFAULT_CONTRACT_ID,
} from "app/v2/Pages/Utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  isValidArray,
  isValidObject,
  isValidString,
  isValidArrayIndex,
  isValidAlphaNumericID,
} from "../../Utils/utilFunctions";
import { getCandidateInfo } from "app/v2/services/consultant/profileSettings.service";
import { useSnackbar } from "notistack";
import {
  candidateInfoThunk,
  updateCandidateInfoThunk,
} from "app/v2/store/features/consultant/consultantProfile.thunk";
import {
  fetchExperiencesThunk,
  fetchContractTypesThunk,
  fetchIndustryThunk,
  fetchSkillsThunk,
  fetchSkillLevelsThunk,
} from "app/v2/store/features/misc/miscThunk";
import useFetch from "app/common/utils/useFetch";
import { s3Upload } from "app/v2/services/miscService";
import configs from "app/v2/Pages/Utils/configs";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "956px",
    boxSizing: "content-box",
    position: "relative",
    [theme.breakpoints.down("1440")]: {
      width: "900px",
    },
    [theme.breakpoints.down("1385")]: {
      width: "800px",
    },
    [theme.breakpoints.down("1285")]: {
      width: "700px",
    },
    [theme.breakpoints.down("1185")]: {
      width: "600px",
    },
  },
  containerLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  profileTextActionsDiv: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "36px",

    // position: "fixed",
    // width: "inherit",
    paddingTop: "48px",
    backgroundColor: theme.palette.common.white,
    zIndex: 100,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },
  },
  yourProfileText: {
    fontSize: "32px",
    fontWeight: 600,
  },
  actionButton: {
    fontSize: "16px",
    fontWeight: 600,
    padding: "12px 28px",
  },
  shareProfileButton: {
    border: "1px solid #EDEDED",
    display: "flex",
    columnGap: "10px",
  },
  editorCancelButton: {
    backgroundColor: `#EDEDED !important`,
    color: `${theme.palette.common.black} !important`,
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "center",
  },
}));

const userprofileDummyData = {
  overview: {
    first_name: "Lacy",
    last_name: "Clark",
    jobTitle: "FrontEnd Developer",
    displayPicture: "/user_image.png",
    status: "available",
    salaryDetails: {
      type: "Hourly",
      fixedRate: 24,
    },
    experienceLevel: 0, // experienceLevel index as per experienceLevel data(we fetch that data below).
    availability: 0, // contractType index as per contractTypes data(we fetch that data below).
    country: "Singapore",
    city: "Singapore City",
    state: "Central Singapore",
    timezone: "Singapore Standard Time (GMT + 8.0)",
    summary:
      "Nullam neque donec quam auctor porttitor. Tortor aliquet sit aliquet vel morbi commodo adipiscing. Enim turpis nisl rhoncus lectus cursus justo placerat magna. Morbi elementum scelerisque felis eros amet vel pellentesque et. Urna curabitur nisi consequat mollis arcu eu nunc dictum. Dignissim id aliquam justo ut ultrices pharetra. Sit est suspendisse laoreet sed. Tristique ut pulvinar sagittis ultricies. Diam ut vestibulum egestas ipsum elementum mi in posuere neque felis risus in egestas rhoncus.",
    backgroundCheck: true,
    // _id: "64ec30dbc03303acde7624b6",
  },
  socialLinks: {
    website: "https://www.google.com",
    facebook: "https://www.facebook.com",
    linkedin: "https://www.linkedin.com",
  },
  skills: [
    {
      skillID: "646f1c2f96656ed53aa3250d",
      skillLevelID: "649a7fce75ed0920604c595d",
    },
    {
      skillID: "646f1c2f96656ed53aa3238b",
      skillLevelID: "649a7fa875ed0920604c595b",
    },
    {
      skillID: "646f1c2f96656ed53aa324a3",
      skillLevelID: "649a7fbd75ed0920604c595c",
    },
  ], // or use defaultSkillList
  activity: {
    activeTime: "2023-09-07T08:49:11.669Z",
    updatedAt: "2023-09-07T08:49:11.669Z",
  },
  consultantPreferences: {
    WorkPreference: "“Remote",
    NoticePeriod: "2 Weeks",
    jobFunction: "Preferred Title",
    additionalRequirements: "Stock options, 401k, relocation",
  },
  workHistory: [
    {
      projectName: "Navigation App UI and Development 1",
      clientName: "Fiat Australia", // client or company name
      projectBrief:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque.",
      position: "Frontend Web Developer",
      employmentType: 0, // employmentType/contractType index as per contractTypes data(we fetch that data below).
      country: "Singapore",
      city: "Singapore City",
      state: "Central Singapore",
      timezone: "Singapore Standard Time (GMT + 8.0)",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "March",
          year: "2023",
        },
        isCurrentlyEmployed: false, // if true,end key will contain empty values for month and year.for month and year.
      },
      industry: "Hospitality",
      skills: [
        {
          skillID: "646f1c2f96656ed53aa3250d",
          skillLevelID: "649a7fce75ed0920604c595d",
        },
        {
          skillID: "646f1c2f96656ed53aa3238b",
          skillLevelID: "649a7fa875ed0920604c595b",
        },
        {
          skillID: "646f1c2f96656ed53aa324a3",
          skillLevelID: "649a7fbd75ed0920604c595c",
        },
      ],
    },
    {
      projectName: "Navigation App UI and Development 2",
      clientName: "Fiat Australia", // client or company name
      projectBrief:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque.",
      position: "Frontend Web Developer",
      employmentType: 0, // employmentType/contractType index as per contractTypes data(we fetch that data below).
      country: "Singapore",
      city: "Singapore City",
      state: "Central Singapore",
      timezone: "Singapore Standard Time (GMT + 8.0)",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "March",
          year: "2023",
        },
        isCurrentlyEmployed: false, // if true,end key will contain empty values for month and year.
      },
      industry: "Hospitality",
      skills: [
        {
          skillID: "646f1c2f96656ed53aa3250d",
          skillLevelID: "649a7fce75ed0920604c595d",
        },
        {
          skillID: "646f1c2f96656ed53aa3238b",
          skillLevelID: "649a7fa875ed0920604c595b",
        },
        {
          skillID: "646f1c2f96656ed53aa324a3",
          skillLevelID: "649a7fbd75ed0920604c595c",
        },
      ],
    },
    {
      projectName: "Navigation App UI and Development 3",
      clientName: "Fiat Australia", // client or company name
      projectBrief:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque.",
      position: "Frontend Web Developer",
      employmentType: 0, // employmentType/contractType index as per contractTypes data(we fetch that data below).
      country: "Singapore",
      city: "Singapore City",
      state: "Central Singapore",
      timezone: "Singapore Standard Time (GMT + 8.0)",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "",
          year: "",
        },
        isCurrentlyEmployed: true, // if true,end key will contain empty values for month and year.
      },
      industry: "Hospitality",
      skills: [
        {
          skillID: "646f1c2f96656ed53aa3250d",
          skillLevelID: "649a7fce75ed0920604c595d",
        },
        {
          skillID: "646f1c2f96656ed53aa3238b",
          skillLevelID: "649a7fa875ed0920604c595b",
        },
        {
          skillID: "646f1c2f96656ed53aa324a3",
          skillLevelID: "649a7fbd75ed0920604c595c",
        },
      ],
    },
    {
      projectName: "Navigation App UI and Development 4",
      clientName: "Fiat Australia", // client or company name
      projectBrief:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque.",
      position: "Frontend Web Developer",
      employmentType: 0, // employmentType/contractType index as per contractTypes data(we fetch that data below).
      country: "Singapore",
      city: "Singapore City",
      state: "Central Singapore",
      timezone: "Singapore Standard Time (GMT + 8.0)",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "March",
          year: "2023",
        },
        isCurrentlyEmployed: false, // if true,end key will contain empty values for month and year.
      },
      industry: "Hospitality",
      skills: [
        {
          skillID: "646f1c2f96656ed53aa3250d",
          skillLevelID: "649a7fce75ed0920604c595d",
        },
        {
          skillID: "646f1c2f96656ed53aa3238b",
          skillLevelID: "649a7fa875ed0920604c595b",
        },
        {
          skillID: "646f1c2f96656ed53aa324a3",
          skillLevelID: "649a7fbd75ed0920604c595c",
        },
      ],
    },
    {
      projectName: "Navigation App UI and Development 5",
      clientName: "Fiat Australia", // client or company name
      projectBrief:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque.",
      position: "Frontend Web Developer",
      employmentType: 0, // employmentType/contractType index as per contractTypes data(we fetch that data below).
      country: "Singapore",
      city: "Singapore City",
      state: "Central Singapore",
      timezone: "Singapore Standard Time (GMT + 8.0)",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "March",
          year: "2023",
        },
        isCurrentlyEmployed: false, // if true,end key will contain empty values for month and year.
      },
      industry: "Hospitality",
      skills: [
        {
          skillID: "646f1c2f96656ed53aa3250d",
          skillLevelID: "649a7fce75ed0920604c595d",
        },
        {
          skillID: "646f1c2f96656ed53aa3238b",
          skillLevelID: "649a7fa875ed0920604c595b",
        },
        {
          skillID: "646f1c2f96656ed53aa324a3",
          skillLevelID: "649a7fbd75ed0920604c595c",
        },
      ],
    },
  ],
  education: [
    {
      certificateTitle: "Post-Graduate Certificate",
      universityName: "Mt. Albany University",
      courseName: "Information & Human Design",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "March",
          year: "2023",
        },
        isCurrentlyPursuing: false, // if true,end key will contain empty values for month and year.
      },
    },
    {
      certificateTitle: "Bachelor’s Diploma",
      universityName: "Mt. Albany University",
      courseName: "Information & Technology",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "",
          year: "",
        },
        isCurrentlyPursuing: true, // if true,end key will contain empty values for month and year.
      },
    },
    {
      certificateTitle: "High School Diploma",
      universityName: "Mt. Albany University",
      courseName: "Information & Technology",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "March",
          year: "2023",
        },
        isCurrentlyPursuing: false, // if true,end key will contain empty values for month and year.
      },
    },
  ],
  certification: [
    {
      certificateTitle: "CISCO Computer Component Build Certification",
      issuedBy: "Mt. Albany University",
      courseName: "Information & Human Design",
      issuedOn: { month: "May", year: "2022" },
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vulputate, nunc quis bibendum blandit, mi lacus consequat arcu, ut scelerisque ex velit ac sapien. Integer facilisis quam et massa dignissim, in blandit tortor aliquam. Sed at libero a nunc tincidunt gravida. Ut at scelerisque ipsum. Duis sit amet quam vel mauris aliquet.",
      _id: "64ec30dbc03303acde7624b5",
    },
  ],
  languages: [
    {
      languageCertificateTitle: "English Level9",
      issuedBy: "Princeton English Examination",
      issuedOn: {
        month: "April",
        year: "2022",
      },
    },
    {
      languageCertificateTitle: "English Level9",
      issuedBy: "Princeton English Examination",
      issuedOn: {
        month: "April",
        year: "2022",
      },
    },
  ],
  awards: [
    {
      awardTitle: "English Level9",
      issuedBy: "Princeton English Examination",
      issuedOn: {
        month: "April",
        year: "2022",
      },
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vulputate, nunc quis bibendum blandit, mi lacus consequat arcu, ut scelerisque ex velit ac sapien. Integer facilisis quam et massa dignissim, in blandit tortor aliquam. Sed at libero a nunc tincidunt gravida. Ut at scelerisque ipsum. Duis sit amet quam vel mauris aliquet.",
    },
    {
      awardTitle: "English Level9",
      issuedBy: "Princeton English Examination",
      issuedOn: {
        month: "April",
        year: "2022",
      },
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vulputate, nunc quis bibendum blandit, mi lacus consequat arcu, ut scelerisque ex velit ac sapien. Integer facilisis quam et massa dignissim, in blandit tortor aliquam. Sed at libero a nunc tincidunt gravida. Ut at scelerisque ipsum. Duis sit amet quam vel mauris aliquet.",
    },
  ],
  testimonials: [
    {
      testimony:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque. Pretium habitant sollicitudin semper eu laoreet nulla diam. Urna massa ultrices tortor et pulvinar egestas nibh.",
      givenBy: "Ericka Santiago",
      role: "Supervisor",
      projectName: "Project Alpha - Telecommunications Inc.",
    },
    {
      testimony:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque. Pretium habitant sollicitudin semper eu laoreet nulla diam. Urna massa ultrices tortor et pulvinar egestas nibh.",
      givenBy: "Ericka Santiago",
      role: "Supervisor",
      projectName: "Project Alpha - Telecommunications Inc.",
    },
    {
      testimony:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque. Pretium habitant sollicitudin semper eu laoreet nulla diam. Urna massa ultrices tortor et pulvinar egestas nibh.",
      givenBy: "Ericka Santiago",
      role: "Supervisor",
      projectName: "Project Alpha - Telecommunications Inc.",
    },
    {
      testimony:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque. Pretium habitant sollicitudin semper eu laoreet nulla diam. Urna massa ultrices tortor et pulvinar egestas nibh.",
      givenBy: "Ericka Santiago",
      role: "Supervisor",
      projectName: "Project Alpha - Telecommunications Inc.",
    },
  ],
  resumeDoc:
    "https://findpro-s3.s3.us-east-2.amazonaws.com/Metkel%20Isak%20Resume_1656535496051.docx", // https://findpro-s3.s3.us-east-2.amazonaws.com/1641827500408.pdf
  videoResume:
    "https://findpro-s3.s3.us-east-2.amazonaws.com/1641827327575.mp4",
  job_invited: [],
  isVerified: true,
  isSubscribed: false,
  subscriptionStatus: "FREE_TRIAL",
  isProfileComplete: true,
  _id: "644de357e1be8cf7b7f45bc3",
};

const SaveDialog = ({
  saveDialogOpen,
  setSaveDialogOpen,
  saveActionLoading,
  saveActionCompleted,
  saveActionError,
  failedS3UploadValues,
}) => {
  const classes = useStyles();

  const Title = () => {
    return (
      <Typography className={classes.dialogTitle}>
        {saveActionLoading && "Action in progress"}
        {saveActionError && "Error"}
        {saveActionCompleted && "Changes Saved"}
      </Typography>
    );
  };

  const DialogBody = () => {
    if (saveActionLoading) return <DialogLoadingBody />;
    else if (saveActionCompleted)
      return (
        <DialogSuccessBody
          content={{
            title: `Your profile details have been saved successfully.`,
            desc: (
              <b>
                {`${
                  isValidArray(failedS3UploadValues)
                    ? `However, there was an issue with uploading your ${failedS3UploadValues.join(
                        ","
                      )}.Please try again later.`
                    : ""
                }
                `}
              </b>
            ),
          }}
        />
      );
    else if (saveActionError)
      return (
        <DialogErrorBody
          content={{ title: messages.GENERIC_ERROR_MESSAGE, desc: "" }}
        />
      );
    else return <></>;
  };

  return (
    <CustomDialog
      open={saveDialogOpen}
      setOpen={setSaveDialogOpen}
      noCloseAction={saveActionLoading}
      title={<Title />}
      content={<DialogBody />}
    />
  );
};

const ProfileSettings = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [get] = useFetch();
  const homeUrl = configs?.homeUrl;

  const { experiences, contractTypes, skillLevels, skills, industryData } =
    useSelector((state) => state.misc);

  const [skillIDToNameMapper, setSkillIDToNameMapper] = useState();
  // {
  //   'id':'name'
  // }
  const [skillLevelIDToRatingMapper, setSkillLevelIDToRatingMapper] =
    useState();
  // {
  //   'levelID':{
  //     rating:"",
  //     ratingText:""
  //   }
  // }
  const [industries, setIndustries] = useState([]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [initialProfileData, setInitialProfileData] = useState({});
  const [profileData, setProfileData] = useState({});
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [isViewMode, setIsViewMode] = useState(true);
  const [activateSave, setActivateSave] = useState(true);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [saveActionLoading, setSaveActionLoading] = useState(false);
  const [saveActionCompleted, setSaveActionCompleted] = useState(false);
  const [saveActionError, setSaveActionError] = useState(false);
  const [failedS3UploadValues, setFailedS3UploadValues] = useState();

  const onToggleViewMode = () => {
    setIsViewMode((prevState) => !prevState);
  };

  const onEditClick = () => {
    onToggleViewMode();
  };

  const onCancelClick = () => {
    const deepCopyIPData = JSON.parse(JSON.stringify(initialProfileData)); // IP- initial profile
    setProfileData(deepCopyIPData);
    onToggleViewMode();
  };

  const onFileUploadToS3 = async (file) => {
    let s3UploadedFileDetails;
    let errorInUpload = false;
    const formData = new FormData();
    formData.append("file", file);
    try {
      s3UploadedFileDetails = await get(s3Upload(formData));
    } catch (err) {
      errorInUpload = true;
    }

    return { uploadedUrl: s3UploadedFileDetails?.[0]?.location, errorInUpload };
  };

  const onSaveClick = async () => {
    let displayPictureUploadedUrlInfo,
      resumeDocUploadedUrlInfo,
      videoResumeUploadedUrlInfo,
      displayPictureUrl,
      resumeDocUrl,
      videoResumeUrl;

    // the below 3 elements can be the url or an object(if upload of any of those were attempted, structure- {file,localFileUrl,prevFileUrl}.
    const displayPictureUrlOrDetails = profileData?.overview?.displayPicture;
    const resumeDocUrlOrDetails = profileData?.resumeDoc;
    const videoResumeUrlOrDetails = profileData?.videoResume;

    const displayPictureFile = displayPictureUrlOrDetails?.file;
    const resumeDocFile = resumeDocUrlOrDetails?.file;
    const videoResumeFile = videoResumeUrlOrDetails?.file;

    setSaveDialogOpen(true);
    setSaveActionLoading(true);

    // If at all upload was attempted for them we try to access the file part of the object(done above) and upload it to s3 and get the updated url of its location in s3 on successful upload.If s3 upload fails we show the message in the dialog and update the prev file itself in the profileData.
    //  Else i.e, upload was not attempted, file value wont exist and it will be the url instead(prev/current url).

    if (displayPictureFile) {
      displayPictureUploadedUrlInfo = await onFileUploadToS3(
        displayPictureFile
      );
      displayPictureUrl = displayPictureUploadedUrlInfo?.errorInUpload
        ? displayPictureUrlOrDetails?.prevFileUrl
        : displayPictureUploadedUrlInfo?.uploadedUrl;
    } else displayPictureUrl = displayPictureUrlOrDetails;

    if (resumeDocFile) {
      resumeDocUploadedUrlInfo = await onFileUploadToS3(resumeDocFile);
      resumeDocUrl = resumeDocUploadedUrlInfo?.errorInUpload
        ? resumeDocUrlOrDetails?.prevFileUrl
        : resumeDocUploadedUrlInfo?.uploadedUrl;
    } else resumeDocUrl = resumeDocUrlOrDetails;

    if (videoResumeFile) {
      videoResumeUploadedUrlInfo = await onFileUploadToS3(videoResumeFile);
      videoResumeUrl = videoResumeUploadedUrlInfo?.errorInUpload
        ? videoResumeUrlOrDetails?.prevFileUrl
        : videoResumeUploadedUrlInfo?.uploadedUrl;
    } else videoResumeUrl = videoResumeUrlOrDetails;

    const updatedProfileData = { ...profileData };
    updatedProfileData.overview.displayPicture = displayPictureUrl;
    updatedProfileData.resumeDoc = resumeDocUrl;
    updatedProfileData.videoResume = videoResumeUrl;

    const updatedProfileDataCopy = JSON.parse(
      JSON.stringify(updatedProfileData)
    );
    const updatedProfileDataFormat = {
      consultantDetails: { ...updatedProfileData },
      _id: updatedProfileData._id,
    };
    const displayPictureFailedUpload =
      displayPictureUploadedUrlInfo?.errorInUpload;
    const resumeDocFailedUpload = resumeDocUploadedUrlInfo?.errorInUpload;
    const videoResumeFailedUpload = videoResumeUploadedUrlInfo?.errorInUpload;

    if (
      displayPictureFailedUpload ||
      resumeDocFailedUpload ||
      videoResumeFailedUpload
    ) {
      const failedUploadValues = [];
      if (displayPictureFailedUpload)
        failedUploadValues.push("display picture");
      if (resumeDocFailedUpload) failedUploadValues.push("resume document");
      if (videoResumeFailedUpload) failedUploadValues.push("video resume");
      setFailedS3UploadValues(failedUploadValues);
    }

    dispatch(updateCandidateInfoThunk(updatedProfileDataFormat))
      .unwrap()
      .then((res) => {
        setSaveActionLoading(false);
        setSaveActionCompleted(true);

        setProfileData(updatedProfileData); // setting profileData state as well with uploaded urls received for the above 3(if upload was attempted).
        setInitialProfileData(updatedProfileDataCopy); // setting initial profile data with latest profile data, so that for further cancellation it works as expected.
        onToggleViewMode();
      })
      .catch((err) => {
        setSaveActionLoading(false);
        setSaveActionError(true);

        console.error(err);
      });
  };

  const areSalaryDetailsEntered = (salaryDetails) => {
    const salaryRange = salaryDetails?.range;
    const fixedSalary = salaryDetails?.fixedRate;
    let isSalarySelected = isValidObject(salaryDetails);
    if (isSalarySelected) {
      const isValidFixedSalary = !isNaN(fixedSalary);
      const isValidSalaryRange =
        !isNaN(salaryRange?.min) &&
        !isNaN(salaryRange?.max) &&
        salaryRange?.min < salaryRange?.max;
      isSalarySelected &&= isValidFixedSalary || isValidSalaryRange;
    }
    return isSalarySelected;
  };

  const validateEntries = () => {
    // No validation for certification/language/awards
    const overviewData = profileData?.overview;
    const socialLinks = profileData?.socialLinks;
    const skills = profileData?.skills;
    const workHistory = profileData?.workHistory;
    const educationData = profileData?.education;

    const isValidFirstName = overviewData?.first_name?.length > 0;
    const isValidLastName = overviewData?.last_name?.length > 0;
    const isValidJobTitle = overviewData?.jobTitle?.length > 0;
    const isValidDisplayPicture =
      overviewData?.displayPicture?.localFileUrl?.length > 0 ||
      overviewData?.displayPicture?.length > 0;
    const isValidSalary = areSalaryDetailsEntered(overviewData?.salaryDetails);
    const experienceSelected =
      isValidArrayIndex(overviewData?.experienceLevel, experiences) ||
      isValidAlphaNumericID(overviewData?.experienceLevel, experiences); // array based index or entry's alphanumeric id.
    const availabilitySelected =
      isValidArrayIndex(overviewData?.availability, contractTypes) ||
      isValidAlphaNumericID(overviewData?.availability, contractTypes); // array based index or entry's alphanumeric id.;
    const countrySelected = Boolean(overviewData?.country);
    const stateSelected = Boolean(overviewData?.state);
    const cityEntered = overviewData?.city?.length > 0;
    const timezoneSelected = Boolean(overviewData?.timezone);
    const summaryEntered = overviewData?.summary?.length > 0;
    const iswebsiteLinkValid = URL_PATTERN.test(socialLinks?.website);
    const isfacebookLinkValid = URL_PATTERN.test(socialLinks?.facebook);
    const islinkedinLinkValid = URL_PATTERN.test(socialLinks?.linkedin);

    const validSocialLinks =
      iswebsiteLinkValid && isfacebookLinkValid && islinkedinLinkValid;

    const validOverviewDetails =
      isValidFirstName &&
      isValidLastName &&
      isValidJobTitle &&
      // isValidDisplayPicture &&
      isValidSalary &&
      experienceSelected &&
      availabilitySelected &&
      countrySelected &&
      stateSelected &&
      cityEntered &&
      timezoneSelected &&
      summaryEntered;

      // console.log(validOverviewDetails? "over view details passed":"over view details fail")
    const skillsSelected = skills?.length > 0;
    const workExperienceAdded = workHistory?.length > 0;
    const educationAdded = educationData?.length > 0;
    const resumeAvailable =
      (profileData?.resumeDoc?.localFileUrl?.length > 0 ||
        profileData?.resumeDoc?.length > 0)
        //  &&
      // (profileData?.videoResume?.localFileUrl?.length > 0 ||
      //   profileData?.videoResume?.length > 0);

    

    setActivateSave(
      validOverviewDetails &&
        workExperienceAdded &&
        educationAdded &&
        resumeAvailable
    );
  };

  useEffect(() => {
    const levelIDMapper = {};
    skillLevels?.forEach((skillLevelEntry) => {
      levelIDMapper[skillLevelEntry?._id] = {
        rating: skillLevelEntry?.rating,
        ratingText: skillLevelEntry?.ratingText,
      };
    });
    setSkillLevelIDToRatingMapper(levelIDMapper);
  }, [skillLevels]);

  useEffect(() => {
    if (isValidArray(skills)) {
      let skillIDToNameMapper = {};
      skills?.forEach((skillEntry) => {
        skillIDToNameMapper[skillEntry?._id] = skillEntry?.name;
      });
      setSkillIDToNameMapper(skillIDToNameMapper);
    }
  }, [skills]);

  useEffect(() => {
    if (isValidArray(industryData)) {
      let industryNames = [];
      industryData?.forEach((industryDetail) => {
        if (industryDetail?.name) industryNames.push(industryDetail?.name);
      });
      setIndustries(industryNames);
    }
  }, [industryData]);

  useEffect(() => {
    if (!skillLevels?.length) dispatch(fetchSkillLevelsThunk());
    if (!skills?.length) dispatch(fetchSkillsThunk());
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
    if (!industryData?.length) dispatch(fetchIndustryThunk());
  }, [dispatch]);

  useEffect(() => {
    // Using dummy profile data.
    // const userProfileDummyDataCopy=JSON.parse(
    //   JSON.stringify(userprofileDummyData)
    // );
    // setInitialProfileData(userprofileDummyData);
    // setProfileData(userProfileDummyDataCopy);

    // Comment above and uncomment below logic when data structure is updated in backend for the api.
    dispatch(candidateInfoThunk())
      .unwrap()
      .then((res) => {
        if (res) {
          // console.log({ res });
          let copyConsultantDetails = { ...res?.consultant?.consultantDetails };
          // let receivedProfileData = { ...userprofileDummyData };
          let receivedProfileData = {};
          receivedProfileData.overview = { ...copyConsultantDetails.overview };

          receivedProfileData.skills = [...copyConsultantDetails.skills];
          receivedProfileData.socialLinks = {
            ...copyConsultantDetails.socialLinks,
          };
          receivedProfileData.activity = { ...copyConsultantDetails.activity };
          receivedProfileData.certification = [
            ...copyConsultantDetails.certification,
          ];
          receivedProfileData.languages = [...copyConsultantDetails.languages];
          receivedProfileData.testimonials = [
            ...copyConsultantDetails.testimonials,
          ];
          receivedProfileData.workHistory = [
            ...copyConsultantDetails.workHistory,
          ];
          receivedProfileData.education = [...copyConsultantDetails.education];
          receivedProfileData.awards = [...copyConsultantDetails.awards];
          receivedProfileData.consultantPreferences = {
            ...copyConsultantDetails.consultantPreferences,
          };
          receivedProfileData.resumeDoc = copyConsultantDetails?.resumeDoc;
          receivedProfileData.videoResume = copyConsultantDetails?.videoResume;
          receivedProfileData._id = res.consultant?._id;

          const receivedProfileDataCopy = JSON.parse(
            JSON.stringify(receivedProfileData)
          );

          setDataLoading(false);
          setProfileData(receivedProfileData);
          setInitialProfileData(receivedProfileDataCopy);
        }
      })
      .catch((err) => {
        if (err.message) {
          setDataLoading(false);
          setDataError(true);
          // enqueueSnackbar(err.message, { variant: "error" });
          const key = enqueueSnackbar(err.message, {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        }
        console.error(err);
      });
  }, []);

  useEffect(() => {
    // console.log({ profileData });
    validateEntries();
  }, [profileData]);

  useEffect(() => {
    if (!isViewMode) {
      let receivedProfileData = JSON.parse(JSON.stringify(profileData));
      /** 
          START- Settings defaults for certain elements in the object as well- for edit mode.
          For elements that shows default values to the user(if value does not exist in the object- which could mean that consultant is entering details for the first time), 
          in the below logic we set those default values in the object as well).
          */

      // if (!receivedProfileData.overview?.experienceLevel)
      // receivedProfileData.overview.experienceLevel = DEFAULT_EXPERIENCE_ID;  // add this instead once the change is done from update settings api end as well(to receive this as a string).
      if (!receivedProfileData.overview?.experienceLevel)
        receivedProfileData.overview.experienceLevel = 0;
      // if (!receivedProfileData.overview?.availability)
      //   receivedProfileData.overview.availability = DEFAULT_CONTRACT_ID;  // add this instead once the change is done from update settings api end as well(to receive this as a string).
      if (!receivedProfileData.overview?.availability)
        receivedProfileData.overview.availability = 0;
      if (!isValidArray(receivedProfileData?.skills))
        receivedProfileData.skills = defaultSkillList;
      setProfileData({ ...receivedProfileData });
      /** END - Settings defaults for certain elements in the object as well. */
    }
  }, [isViewMode]);

  useEffect(() => {
    if (!saveDialogOpen) {
      // On closing save dialog we set the required states back to their default state.
      setSaveActionLoading(false);
      setSaveActionCompleted(false);
      setSaveActionError(false);
      setFailedS3UploadValues(null);
    }
  }, [saveDialogOpen]);

  return (
    <div
      className={clsx([
        classes.container,
        dataLoading && classes.containerLoading,
      ])}
    >
      <>
        {dataLoading ? (
          <CircularProgress className="mt-[48px]" />
        ) : (
          <>
            {dataError ? (
              <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
            ) : (
              <>
                <div className={classes.profileTextActionsDiv}>
                  <Typography className={classes.yourProfileText}>
                    Your Profile
                  </Typography>
                  <div className="flex gap-x-[16px]">
                    {isViewMode ? (
                      <>
                        <CustomTooltip
                          arrow
                          title={
                            <SocialShare
                              shareLink={
                                profileData?._id
                                  ? homeUrl +
                                    `consultant-detail/${profileData?._id}`
                                  : null
                              }
                            />
                          }
                          bg="white"
                        >
                          <div>
                            <CustomButton
                              customClasses={clsx([
                                classes.actionButton,
                                classes.shareProfileButton,
                              ])}
                              type="button2"
                            >
                              Share Profile
                              <img src="/share.svg" />
                            </CustomButton>
                          </div>
                        </CustomTooltip>
                        <CustomButton
                          customClasses={clsx([
                            classes.actionButton,
                            classes.editorCancelButton,
                          ])}
                          onClick={onEditClick}
                        >
                          Edit
                        </CustomButton>
                      </>
                    ) : (
                      <>
                        <CustomButton
                          customClasses={clsx([
                            classes.actionButton,
                            classes.editorCancelButton,
                          ])}
                          onClick={onCancelClick}
                        >
                          Cancel
                        </CustomButton>
                        <CustomTooltip
                          title={
                            !activateSave &&
                            "Please enter the mandatory details."
                          }
                        >
                          <div>
                            <CustomButton
                              type="button1"
                              customClasses={classes.actionButton}
                              onClick={onSaveClick}
                              disabled={!activateSave}
                            >
                              Save Changes
                            </CustomButton>
                          </div>
                        </CustomTooltip>
                      </>
                    )}
                  </div>
                </div>
                <div className="pt-[15px]">
                  {isViewMode ? (
                    <ProfileView
                      data={profileData}
                      experiences={experiences}
                      contractTypes={contractTypes}
                      industries={industries}
                      skillIDToNameMapper={skillIDToNameMapper}
                      skillLevelIDToRatingMapper={skillLevelIDToRatingMapper}
                    />
                  ) : (
                    <ProfileEdit
                      data={profileData}
                      experiences={experiences}
                      contractTypes={contractTypes}
                      industries={industries}
                      skillIDToNameMapper={skillIDToNameMapper}
                      skillLevelIDToRatingMapper={skillLevelIDToRatingMapper}
                      setProfileData={setProfileData}
                    />
                  )}
                </div>
                <SaveDialog
                  saveDialogOpen={saveDialogOpen}
                  setSaveDialogOpen={setSaveDialogOpen}
                  saveActionLoading={saveActionLoading}
                  saveActionCompleted={saveActionCompleted}
                  saveActionError={saveActionError}
                  failedS3UploadValues={failedS3UploadValues}
                />
              </>
            )}
          </>
        )}
      </>
    </div>
  );
};

ProfileSettings.propTypes = {};

export default ProfileSettings;
