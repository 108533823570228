import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import ContentLoader from "react-content-loader";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import {
  Alert,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Pagination,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import GroupsIcon from "@mui/icons-material/Groups";

import Title from "../../common/Title";
import ClientListCard from "../common/Card/Client/List";
import CustomRadioGroup from "app/v2/components/CustomRadioGroup";
import CustomCheckboxGroup from "app/v2/components/CustomCheckboxGroup";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomSelect from "app/v2/components/filters/CustomSelect";
import CustomTooltip from "app/v2/components/CustomTooltip";

import { isValidArray } from "../../Utils/utilFunctions";
import { messages } from "../../Utils/constants";
import CountryList from "app/markup/country.json";

import { getAllClients } from "app/v2/services/admin/manageClients.service";
import { fetchIndustryThunk } from "app/v2/store/features/misc/miscThunk";

const useStyles = makeStyles((theme) => ({
  leftView: {
    padding: "24px 48px",
    width: "291px",
    boxSizing: "content-box",
    overflow: "auto",
    height: "600px",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  rightView: {
    borderLeft: "1px solid #EDEDED",
    padding: "24px",
    width: "1004px",
    boxSizing: "content-box",
    display: "flex",
    flexDirection: "column",
    rowGap: "40px",

    [theme.breakpoints.down("1440")]: {
      width: "980px",
    },
    [theme.breakpoints.down("1385")]: {
      width: "850px",
    },
    [theme.breakpoints.down("1285")]: {
      width: "750px",
    },
    [theme.breakpoints.down("1185")]: {
      width: "700px",
    },
  },

  sortOrFilterText: {
    fontWeight: 600,
    fontSize: "16px",
  },
  sortRadioGroupDiv: {
    margin: "12px 24px 0 24px",
  },
  sortTextAndIconDiv: {
    display: "flex",
    alignItems: "center",
    columnGap: "8px",
  },
  accordionDiv: {
    margin: "14px 0",
  },
  accordion: {
    boxShadow: "none",
    borderBottom: "1px solid #EDEDED",
  },
  accordionTitle: {
    fontWeight: 600,
    fontSize: "16px",
  },
  checkboxGroupDIv: {
    margin: "12px 24px",
    height: "200px",
    overflow: "scroll",
  },
  companySizeChipsContainer: {
    display: "flex",
    gap: "8px",
    flexWrap: "wrap",
    padding: "8px",
  },
  chip: {
    borderRadius: "8px !important",
  },
  paginationContainer: {
    float: "right",
  },
  disabledLeftView: {
    pointerEvents: "none",
    opacity: 0.5,
  },
}));

const ManageClients = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { industryData } = useSelector((state) => state.misc);

  const countries = CountryList.map(
    (countryStatesInfo) => countryStatesInfo?.country
  );
  const clientsPerScroll = 8;
  const sortData = [
    {
      label: "Newest",
      value: "latest", // api value
    },
    {
      label: "Alphabetical (A-Z)",
      value: "asc",
    },
    {
      label: "Alphabetical (Z-A)",
      value: "desc",
    },
    {
      label: "Oldest",
      value: "oldest",
    },
  ];
  const companySizeData = [
    {
      label: "15-30",
      range: [1, 10],
    },
    {
      label: "30-50",
      range: [10, 20],
    },
    {
      label: "50-100",
      range: [20, 50],
    }
  ];

  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [initialRender, setInitialRender] = useState(true);

  // #region Filters states
  const [searchText, setSearchText] = useState("");
  const [sortSelectionValue, setSortSelectionValue] = useState(
    sortData[0]?.value
  );
  const [industriesCheckboxData, setIndustriesCheckboxData] = useState([]);
  const [countriesCheckboxData, setCountriesCheckboxData] = useState(
    countries.map((countryName) => ({
      label: countryName,
      value: countryName,
      checked: false,
    }))
  );
  const [checkedCountryNames, setCheckedCountryNames] = useState([]);
  const [checkedIndustryNames, setCheckedIndustryNames] = useState([]);
  const [selectedCSChipIndex, setSelectedCSChipIndex] = useState();
  const [selectedCSChipRange, setSelectedCSChipRange] = useState([]);
  // #endregion Filters states

  // #region pagination states
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  // #endregion pagination states

  const onSortChange = (id, currentValue) => {
    setSortSelectionValue(currentValue);
  };

  const onCheckboxGroupChange = (checkboxValue, checkedState, elementID) => {
    if (elementID === "country") {
      setCountriesCheckboxData((prevItems) => {
        const updatedItems = prevItems?.map((item) => {
          if (item.value === checkboxValue) {
            return { ...item, checked: checkedState };
          }
          return item;
        });

        return updatedItems;
      });
      setCheckedCountryNames((prevState) =>
        checkedState
          ? [...prevState, checkboxValue]
          : prevState.filter((countryName) => countryName !== checkboxValue)
      );
    } else {
      setIndustriesCheckboxData((prevItems) => {
        const updatedItems = prevItems?.map((item) => {
          if (item.value === checkboxValue) {
            return { ...item, checked: checkedState };
          }
          return item;
        });

        return updatedItems;
      });
      setCheckedIndustryNames((prevState) =>
        checkedState
          ? [...prevState, checkboxValue]
          : prevState.filter((industryName) => industryName !== checkboxValue)
      );
    }
  };

  const fetchClients = async (action) => {
    const isPageSelectionAction = action === "page";
    const pageNumberForAPI = isPageSelectionAction ? currentPage - 1 : 0; // Going back to first page if sort/filter was updated.

    setDataLoading(true);
    setCurrentPage(pageNumberForAPI + 1);

    let data = {
      limit: clientsPerScroll,
      offset: pageNumberForAPI,
      order: sortSelectionValue,
      country: checkedCountryNames.join(","),
      industry: checkedIndustryNames.join(","),
      name:searchText
    };

    if (isValidArray(selectedCSChipRange))
      data.companySize = selectedCSChipIndex;

    getAllClients(data)
      .then((response) => {
        let clientsReceived = response?.data;
        let pageCount;
        if (isValidArray(clientsReceived)) {
          const totalClientCount = response?.count?? response?.data?.length;
          pageCount =
            totalClientCount > 0
              ? Math.ceil(totalClientCount / clientsPerScroll)
              : 0;
        }

        setData(clientsReceived);
        setTotalPagesCount(pageCount);
        setInitialRender(false);
        setDataLoading(false);
      })
      .catch((error) => {
        setDataLoading(false);
        setDataError(true);
      });
  };

  const onPageChange = (event, value) => {
    setCurrentPage(value);
  };
  const handleReload = () => {
    //setReloadParent(!reloadParent);
    fetchClients("page");
  };
  useEffect(() => {
    fetchClients("page");
  }, []);

  useEffect(() => {
    if (!initialRender) {
      // // console.log({ currentPage });
      fetchClients("page");
    }
  }, [currentPage]);

  useEffect(() => {
    if (!initialRender) {
      // // console.log({
      //   sortSelectionValue,
      //   selectedCSChipRange,
      //   checkedCountryNames,
      //   checkedIndustryNames,
      // });
      fetchClients("sort-filter");
    }
  }, [
    sortSelectionValue,
    selectedCSChipRange,
    checkedCountryNames,
    checkedIndustryNames,
  ]);

  useEffect(() => {
    if (!industryData?.length) dispatch(fetchIndustryThunk());
  }, [dispatch]);

  useEffect(() => {
    if (isValidArray(industryData)) {
      let industryCheckboxData = [];
      industryData?.forEach((industryDetail) => {
        if (industryDetail?.name)
          industryCheckboxData.push({
            label: industryDetail?.name,
            value: industryDetail?.name,
            checked: false,
          });
      });
      setIndustriesCheckboxData(industryCheckboxData);
    }
  }, [industryData]);

  return (
    <>
      <Title title="Manage Clients" />
      <>
        {dataError ? (
          <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
        ) : (
          <Grid container>
            <CustomTooltip
              title={
                initialRender && !isValidArray(data)
                  ? "Since no data is available,the sort and filter options are disabled."
                  : null
              }
            >
              <div>
                <Grid
                  item
                  className={clsx([
                    classes.leftView,
                    initialRender && !isValidArray(data)
                      ? classes.disabledLeftView
                      : "",
                  ])}
                >
                  <div className="flex items-center">
                    <CustomTextfield
                      id="searchText"
                      value={searchText}
                      placeholder="Search by client name"
                      onChange={(id, value) => {
                        setSearchText(value);
                      }}
                      noErrorMessage={true}
                    />
                    <SearchIcon
                      onClick={() => {
                        fetchClients("sort-filter");
                      }}
                      className="cursor-pointer ml-[8px]"
                    />
                  </div>

                  <div>
                    <Typography className={classes.sortOrFilterText}>
                      Sort{" "}
                    </Typography>
                    <div className={classes.sortRadioGroupDiv}>
                      <CustomRadioGroup
                        id="sort-radio-group"
                        name="sort-radio-group"
                        defaultValue={sortSelectionValue}
                        data={sortData}
                        onChange={onSortChange}
                      />
                    </div>
                  </div>

                  <div>
                    <Typography className={classes.sortOrFilterText}>
                      Filter
                    </Typography>
                    <div className={classes.accordionDiv}>
                      <Accordion className={classes.accordion}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.accordionTitle}>
                            Country
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {" "}
                          <div className={classes.checkboxGroupDIv}>
                            <CustomCheckboxGroup
                              id="country"
                              data={countriesCheckboxData}
                              onChange={onCheckboxGroupChange}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div className={classes.accordionDiv}>
                      <Accordion className={classes.accordion}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.accordionTitle}>
                            Industry
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className={classes.checkboxGroupDIv}>
                            <CustomCheckboxGroup
                              id="industry"
                              data={industriesCheckboxData}
                              onChange={onCheckboxGroupChange}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div className={classes.accordionDiv}>
                      <Accordion className={classes.accordion}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.accordionTitle}>
                            Company Size
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className={classes.companySizeChipsContainer}>
                            {companySizeData.map((sizeEntry, index) => (
                              <Chip
                                color="primary"
                                variant={
                                  index === selectedCSChipIndex
                                    ? "contained"
                                    : "outlined"
                                }
                                icon={<GroupsIcon />}
                                label={sizeEntry.label}
                                className={classes.chip}
                                size="small"
                                onClick={() => {
                                  setSelectedCSChipIndex(index);
                                  setSelectedCSChipRange(sizeEntry.range);
                                }}
                              />
                            ))}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </Grid>
              </div>
            </CustomTooltip>
            <Grid item className={classes.rightView}>
              {dataLoading ? (
                <ContentLoader
                  speed={2}
                  width={1400}
                  height={800}
                  viewBox="0 0 1400 800"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="3" ry="3" width="1000" height="180" />
                  <rect x="0" y="200" rx="3" ry="3" width="1000" height="180" />
                  <rect x="0" y="400" rx="3" ry="3" width="1000" height="180" />
                  <rect x="0" y="600" rx="3" ry="3" width="1000" height="180" />
                </ContentLoader>
              ) : (
                <>
                  {isValidArray(data) ? (
                    <>
                      {data?.map((entry, index) => (
                        <>
                          <ClientListCard data={entry} reloadParent={handleReload} />
                          {index !== data?.length - 1 ? (
                            <hr className="m-0" />
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                    </>
                  ) : (
                    <Alert severity="info"> {messages?.NO_DATA_FOUND} </Alert>
                  )}
                  <Pagination
                    color="primary"
                    count={totalPagesCount}
                    page={currentPage}
                    onChange={onPageChange}
                    classes={{ ul: classes.paginationContainer }}
                  />
                </>
              )}
            </Grid>
          </Grid>
        )}
      </>
    </>
  );
};

ManageClients.propTypes = {};

export default ManageClients;
