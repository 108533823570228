import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useSnackbar } from "notistack";

import { Typography } from "@mui/material";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomLabel from "app/v2/components/filters/CustomLabel";
import SectionTitleAndIcon from "./SectionTitleAndIcon";
import LocationSelector from "app/v2/components/filters/LocationSelector";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  sectionSubHeading: {
    fontSize: "18px",
    fontWeight: 600,
    marginBottom: "16px",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  uploadOrAddButton: {
    columnGap: "8px",
    border: "1px solid #EDEDED",
    padding: "6px 8px",
    fontSize: "14px",
    fontWeight: 600,
  },
  summary: {
    "& .MuiInputBase-inputMultiline": {
      fontSize: "14px !important",
      color: "#717171 !important",
    },
  },
}));

const Overview = ({
  overviewData,
  socialLinks,
  experienceTypes,
  contractTypes,
  onOverviewEntryChange,
  onSocialLinksEntryChange,
  selectedEmployee,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const uploadImageRef = React.createRef();

  //// console.log("selected ep,oyee", selectedEmployee);
  return (
    <div>
      <SectionTitleAndIcon title={selectedEmployee?"Edit Employee" :"Add Employee"} iconPath="/file.svg" />
      <div className="p-[16px] flex flex-col gap-y-[36px]">
        <div className="flex gap-x-[24px]">
          <div className="flex-1">

            <CustomTextfield
              id="firstName"
              label="First Name"
              placeholder="Kyla"
              value={
                selectedEmployee
                  ? selectedEmployee.firstName
                  : overviewData?.firstName
              }
              onChange={onOverviewEntryChange}
              showMandatorySymbol={true}
              disabled={!!selectedEmployee} 
            />
          </div>
          <div className="flex-1">
            <CustomTextfield
              // id="lastName"
              id="lastName"
              label="Last Name"
              placeholder="Andrews"
              value={selectedEmployee? selectedEmployee.lastName:overviewData?.lastName}
              onChange={onOverviewEntryChange}
              showMandatorySymbol={true}
              disabled={!!selectedEmployee} 

            />
          </div>
        </div>
{/* 
        <CustomTextfield
          id="companyTitle"
          label="Company Title"
          placeholder="Project Manager "
          value={overviewData?.jobTitle}
          onChange={onOverviewEntryChange}
          showMandatorySymbol={true}
        /> */}

        {/* <div>
          <Typography className={classes.sectionSubHeading}>
            Location *
          </Typography>
          <div className="p-[16px]">
            <LocationSelector
              defaultCountry={overviewData?.country}
              defaultState={overviewData?.state}
              defaultCity={overviewData?.city}
              defaultTimezone={overviewData?.timezone}
              onChange={onOverviewEntryChange}
              showMandatorySymbol={true}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

Overview.propTypes = {};

export default Overview;