import { useState } from "react";
import PropTypes from "prop-types";
import { Typography, Tabs, Tab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Montserrat !important",
    
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    

  },
  tabRoot: {
    width:"max-content",
    overflow:"scroll",

    fontSize: "16px !important",
    fontWeight: "600 !important",
    margin: "26px 36px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px",
    },
    padding: 0,
    color: theme.palette.common.black,
  },
  indicator: {
    backgroundColor: theme.palette.common.black,
  },
  selectedTab: {
    color: `${theme.palette.common.black} !important`,
  },
  marginLeftZero: {
    marginLeft: 0,
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CustomTabs({
  tabsData,
  currentTabIndex,
  setCurrentTabIndex,
  alignTabNameToLeft,
}) {
  const classes = useStyles();
  const smDown = useMediaQuery("(max-width:600px)");
  const xxsDown = useMediaQuery("(max-width:300px)");

  const handleChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
  };

  const CustomMUITabs = styled(Tabs)(({ theme }) => ({
    "& .MuiTabs-flexContainer": {
      justifyContent: "start",
    },
    "& .MuiButtonBase-root": {
      fontSize: xxsDown ? "0.5rem" : smDown ? "1rem" : "1.5rem",
      fontWeight: 600,
    },
  }));

  return (
    <Box className={classes.root}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <CustomMUITabs
          value={currentTabIndex}
          onChange={handleChange}
          aria-label="tabs component"
          classes={{ indicator: classes.indicator }}
        >
          {tabsData?.map((tab, index) => (
            <Tab
              label={tab.label}
              {...a11yProps(index)}
              classes={{
                root: clsx([
                  classes.tabRoot,
                  alignTabNameToLeft && classes.marginLeftZero,
                ]),
                selected: classes.selectedTab,
              }}
            />
          ))}
        </CustomMUITabs>
      </Box>
      {tabsData?.map((tab, index) => (
        <TabPanel value={currentTabIndex} index={index}>
          {tab.component}
        </TabPanel>
      ))}
    </Box>
  );
}
