import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, styled } from "@mui/styles";
import clsx from "clsx";
import moment from "moment";
import QRCode from "qrcode.react";

import { Typography, Chip, Alert, Tooltip } from "@mui/material";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";

import CustomTooltip from "app/v2/components/CustomTooltip";
import ConsultantStatus from "app/v2/Pages/common/Status/Consultant";
import SocialShare from "app/v2/Pages/common/SocialShare";
import CustomButton from "app/v2/components/CustomButton";
import InviteJobDialog from "./InviteJobDialog";
import MatchRate from "app/v2/Pages/common/MatchRate";
import FilePreview from "app/v2/Pages/common/FilePreview";
import CloseIcon from "@mui/icons-material/Close";

import { IconDot } from "app/v2/Pages/SVGs/IconDot";
import img_iconlightcheckmark from "app/v2/Pages/images/img_iconlightcheckmark.svg";
import img_volume_blue_gray_900 from "../../../images/img_volume_blue_gray_900.svg";
import img_iconlightbookmark from "../../../images/img_iconlightbookmark.svg";
import { NoProfileImageSVG } from "app/v2/Pages/SVGs/NoProfile";

import { isValidArray } from "app/v2/Pages/Utils/utilFunctions";
import { messages, URL_PATTERN } from "app/v2/Pages/Utils/constants";
import configs from "app/v2/Pages/Utils/configs";
import { favUnfavSelection } from "app/v2/services/client/common.service";
import { useSnackbar } from "notistack";
import { putConnectionsData } from "app/v2/services/consultant/connections.service";
import {
  getContractTypeLevel,
  getExperience,
  getIndustryName,
} from "app/v2/Pages/common/getIdTypeLevel";

const useStyles = makeStyles((theme) => ({
  qrCodeDiv: {
    padding: "16px",
    border: "1px solid #E0E0E0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  workDetailType: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#717171",
  },
  chipRoot: {
    padding: "0px 8px",
    fontSize: "12px",
    fontWeight: 700,
    border: 0,
    backgroundColor: "#EDEDED !important",
  },
  chipLabel: {
    padding: 0,
  },
  sectionSubHeading: {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "16px",
  },
  skillDiv: {
    // flex: "0 0 25%",
    flex: "0 0 44%",
    display: "flex",
    columnGap: "8px",
    // margin: "12px 0",
    alignItems: "center",
    height: "fit-content",
  },
  actionButton: {
    boxSizing: "content-box",
    fontSize: "16px",
    fontWeight: 600,
  },
  inviteButton: {
    width: "136px",
    height: "20px",
    padding: "12px 28px !important",
    display: "flex",
    columnGap: "10px",
    whiteSpace: "nowrap",
  },
  connectButton: {
    width: "68px",
    height: "20px",
    padding: "12px 28px !important",
    border: "1px solid #EDEDED",
  },
  bookmarkShareIcon: {
    border: "1px solid #EDEDED",
    padding: "10px",
    width: "20px",
    height: "20px",
    boxSizing: "content-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  userImage: {
    width: "200px",
    height: "200px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
    marginBottom: "24px",
  },
  socialIcon: {
    minWidth: "24px",
    width: "24px !important",
    height: "24px !important",
    cursor: "pointer",
  },
  disabledLink: {
    pointerEvents: "none",
    cursor: "default",
  },
}));

const DetailText = ({ type, value }) => {
  const classes = useStyles();
  return (
    <div className="flex-1">
      <div className="text-[12px] font-semibold text-[#717171] uppercase">
        {type ?? ""}
      </div>
      <div className="text-[16px]">{value ?? "-"}</div>
    </div>
  );
};

export const SectionTitleIcon = ({ title, icon }) => (
  <div className="text-[24px] font-semibold mb-[24px] flex items-center gap-x-[8px]">
    {icon} {title}
  </div>
);

export const Overview = ({
  currentJob,
  activeJobs,
  data,
  socialLinks,
  contractTypes,
  experienceTypes,
}) => {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isFavorite, setIsFavorite] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const subscriptionPlan = localStorage.getItem("planName"); //here we need to add the free plan Id from info api
  const isTrialByadmin = localStorage.getItem("isSubscribedByAdmin"); //here we need to add the free plan Id from info api
  const [isTrial, setIsTrial] = useState(subscriptionPlan === "free");
  const [isSubscribedByAdmin, setIsSubscribedByAdmin] = useState(isTrialByadmin === "true");

  const homeUrl = configs?.homeUrl;
  const publicConsultantDetailURL = data?._id
    ? homeUrl + `consultant-detail/${data?._id}`
    : null;
  const salaryDetails = data?.salaryDetails ?? data?.salary;
  const salaryType = salaryDetails?.type;
  const salaryRange = salaryDetails?.range;
  const salaryValue = salaryRange
    ? `Min- $ ${salaryRange?.min ?? "-"}, Max - $ ${salaryRange?.max ?? "-"}`
    : `$ ${salaryDetails?.fixedRate ?? "-"}`;

  const [inviteJobDialogOpen, setInviteJobDialogOpen] = useState(false);
  let linkedinURL = "https://", facebookURL = "https://", websiteURL = "https://";
  let activeTime;
  activeTime = data?.activeTime ? moment(data?.activeTime).fromNow() : "-";
  activeTime = activeTime === "Invalid date" ? "-" : activeTime;

  if (socialLinks?.linkedin) {
    linkedinURL = URL_PATTERN.test(socialLinks?.linkedin)
      ? socialLinks?.linkedin
      : "https://" + socialLinks?.linkedin;
  }
  if (socialLinks?.facebook) {
    facebookURL = URL_PATTERN.test(socialLinks?.facebook)
      ? socialLinks?.facebook
      : "https://" + socialLinks?.facebook;
  }
  if (socialLinks?.website) {
    websiteURL = URL_PATTERN.test(socialLinks?.website)
      ? socialLinks?.website
      : "https://" + socialLinks?.website;
  }

  useEffect(() => {
    setIsFavorite(data?.isFavorite);
    setIsConnected(data?.isConnected);

  }, [data]);

  const handleBookMarkClick = async (consultantId, favouriteType) => {
    try {
      const requestData = {
        favoriteId: consultantId,
        favoriteModel: favouriteType,
      };
      const response = await favUnfavSelection(requestData);

      if (response?.message) {
        setIsFavorite(response?.data?.status);
        // enqueueSnackbar(`Successfull`, { variant: "success" });
        const key = enqueueSnackbar(`Successfull`, {
          variant: "success",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
      }
    } catch (error) {
      // enqueueSnackbar(error, { variant: "error" });
      const key = enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 3000,
        action: (key) => (
          <CloseIcon
            onClick={() => closeSnackbar(key)}
            style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
          />
        ),
      });
    }
  };
  const handleConnection = async (consultantId, favouriteType) => {
    try {
      const requestData = {
        // fromId: "65593a25b957af8508a65bbe", // edward consultant
        fromUserType: "Client", // Consultants | Client | Agency
        toId: consultantId, // "6557e3f09b7363581b1bc9dc", // edward client
        toUserType: "Consultants", // Consultants | Client | Agency
      };
      const response = await putConnectionsData(requestData);

      if (response?.message) {
        // setIsConnected( response?.data?.status );
        setIsConnected(true);
        // enqueueSnackbar(`Successfull`, { variant: "success" });
        const key = enqueueSnackbar("Successfull", {
          variant: "success",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
      }
    } catch (error) {
      // enqueueSnackbar(error.message, { variant: "error" });
      const key = enqueueSnackbar(error.message, {
        variant: "error",
        autoHideDuration: 3000,
        action: (key) => (
          <CloseIcon
            onClick={() => closeSnackbar(key)}
            style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
          />
        ),
      });
    }
  };

  return (
    <>
      <div className="flex gap-x-[48px]">
        <div>
          <img
            className={classes.userImage}
            alt={`"user_${data?._id}_image"`}
            src={
              data?.displayPicture
                ? data.displayPicture
                : "/user_placeholder.svg"
            }
          />
          <CustomTooltip
            title={
              (isTrial && !isSubscribedByAdmin)
                ? "Access to this feature is exclusive to our premium subscription plans. Upgrade now to enjoy all the benefits!"
                : ""
            }
            arrow
          >
            <div className="flex gap-x-[12px] items-center mb-[16px]">
              <div className="text-[12px] font-semibold text-[#717171]">
                SOCIAL PROOF
              </div>
              {linkedinURL || facebookURL || websiteURL ? (
                <div className="flex gap-x-[12px]">
                  {linkedinURL && (
                    <a
                      href={linkedinURL ?? ""}
                      target="_blank"
                      className={isTrial && !isSubscribedByAdmin ? classes.disabledLink : ""}
                    >
                      <img
                        src="/linkedin-logo-black.png"
                        className={classes.socialIcon}
                      />
                    </a>
                  )}
                  {facebookURL && (
                    <a
                      href={facebookURL}
                      target="_blank"
                      className={(isTrial && !isSubscribedByAdmin) ? classes.disabledLink : ""}
                    >
                      <img
                        src="/facebook-logo-black.svg"
                        className={classes.socialIcon}
                      />
                    </a>
                  )}
                  {websiteURL && (
                    <a
                      href={websiteURL}
                      target="_blank"
                      className={(isTrial && !isSubscribedByAdmin) ? classes.disabledLink : ""}
                    >
                      <TravelExploreIcon className={classes.socialIcon} />
                    </a>
                  )}
                </div>
              ) : (
                "-"
              )}
            </div>
          </CustomTooltip>
          <hr />
          <div className={classes.qrCodeDiv}>
            <div className="text-[16px] font-medium mb-[12px]">QR SHARE</div>
            {publicConsultantDetailURL ? (
              <QRCode
                value={publicConsultantDetailURL}
                className="h-[89px] w-[89px]"
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="w-full flex flex-col gap-y-[24px]">
          <div className="flex justify-between">
            <div>
              <div className="text-[32px] font-semibold">
                {data?.first_name ?? "-"}{" "}
                {(!isTrial && !isSubscribedByAdmin) ? ` ${data?.last_name ?? "-"}` : ""}
              </div>
              <div className="text-[12px] text-[#717171]">
                Active {activeTime}
              </div>
            </div>
            <div className="h-[18px] px-[8px] flex gap-x-[5px] items-center bg-green-50">
              <ConsultantStatus type={data?.status} />
            </div>
          </div>
          <div className="flex md:flex-col flex-row gap-[19px] items-center justify-start w-full">
            {data?.matchRate ? (
              <MatchRate matchType={data?.matchRate} withGreyBg={true} />
            ) : (
              <></>
            )}

            <CustomButton
              type="button1"
              customClasses={clsx([classes.inviteButton, classes.actionButton])}
              onClick={() => {
                setInviteJobDialogOpen(true);
              }}
            >
              Invite to Apply
              <img
                src="/iconlightup-right.svg"
                className=" w-[15px] h-[15px]"
              />
            </CustomButton>
            <CustomButton
              customClasses={clsx([
                classes.actionButton,
                classes.connectButton,
              ])}
              onClick={() => handleConnection(data._id, "Consultants")}
            >
              {/* Connect */}
              {isConnected ? "Requested" : "Connect"}
            </CustomButton>
            <button
              // className={clsx([
              //   classes.actionButton,
              //   classes.bookmarkShareIcon,
              // ])}
              className={`cursor-pointer ${
                isFavorite
                  ? "bg-neutral-900 border-none h-10"
                  : "border-[1px] border-solid border-neutral-50"
              } p-2.5 flex flex-row box-border items-center justify-center`}
              onClick={() => handleBookMarkClick(data._id, "Consultants")}
            >
              <img
                src={
                  isFavorite
                    ? "/iconlightbookmark.svg"
                    : "/icondarkbookmark.svg"
                }
                alt="icon"
              />
            </button>
            <CustomTooltip
              arrow
              title={
                (isTrial && !isSubscribedByAdmin) ? (
                  "Access to this feature is exclusive to our premium subscription plans. Upgrade now to enjoy all the benefits!"
                ) : (
                  <SocialShare shareLink={publicConsultantDetailURL} />
                )
              }
              bg={(isTrial && !isSubscribedByAdmin) ? "black" : "white"}
            >
              <button
                className={clsx([
                  classes.actionButton,
                  classes.bookmarkShareIcon,
                ])}
                disabled={(isTrial && !isSubscribedByAdmin)}
              >
                <img src={"/share.svg"} alt="icon" />
              </button>
            </CustomTooltip>
          </div>
          <div className="flex gap-x-[16px]">
            <DetailText type={`${salaryType ?? ""} RATE`} value={salaryValue} />
            <DetailText
              type="LEVEL"
              value={getExperience(data?.experienceLevel, experienceTypes)}
            />
            <DetailText
              type="AVAILABILITY"
              value={getContractTypeLevel(data?.availability, contractTypes)}
            />
          </div>
          <div className="flex gap-x-[16px]">
            <DetailText
              type="LOCATION"
              value={`${data?.country},${data?.state}, ${data?.city}`}
            />
            <DetailText type="TIMEZONE" value={data?.timezone} />
          </div>
          <div className="text-[14px] text-[#717171] ">{data?.summary}</div>
          {(data?.videoResumeAvailable || data?.backgroundCheck) && (
            <div className="flex gap-x-[24px]">
              {data?.videoResumeAvailable && (
                <div className="flex gap-x-[10px]">
                  <img src={img_iconlightcheckmark} />
                  <span className="font-semibold text-xs">
                    Video Resume Available
                  </span>
                </div>
              )}
              {data?.backgroundCheck && (
                <div className="flex gap-x-[10px]">
                  <img src={img_iconlightcheckmark} />
                  <span className="font-semibold text-xs">
                    Background Checked
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {inviteJobDialogOpen && (
        <InviteJobDialog
          open={inviteJobDialogOpen}
          setOpen={setInviteJobDialogOpen}
          consultantData={data}
          currentJob={currentJob}
          activeJobs={activeJobs}
        />
      )}
    </>
  );
};

export const WorkHistoryCard = ({
  data,
  skillIDToNameMapper,
  contractTypes,
  industryData,
}) => {
  const classes = useStyles();

  const duration = data?.duration;
  const startTime = duration?.start;
  const endTime = duration?.end;
  const isCurrentlyEmployed = duration?.isCurrentlyEmployed;
  const skills = data?.skills;
  return (
    <div className="min-w-[452px] flex flex-col gap-y-[24px] p-[24px] border-[1px] border-solid border-[#717171] box-content">
      {data?.projectName && (
        <div className="text-[18px] font-semibold">{data?.projectName}</div>
      )}
      <div>
        <Typography className={classes.workDetailType}>CLIENT</Typography>
        <div className="text-[16px] font-bold">{data?.clientName ?? "-"}</div>
      </div>
      <div>
        <Typography className={classes.workDetailType}>
          PROJECT BRIEF
        </Typography>
        <div className="text-[16px] text-[#717171] h-[44px] overflow-y-auto">
          {data?.projectBrief ?? "-"}
        </div>
      </div>
      <div className="flex gap-x-[8px]">
        <div className="flex-1">
          <Typography className={classes.workDetailType}>POSITION</Typography>
          <div className="text-[16px]">{data?.position ?? "-"}</div>
        </div>
        <div className="flex-1">
          <Typography className={classes.workDetailType}>
            EMPLOYMENT TYPE
          </Typography>
          <div className="text-[16px]">
            {getContractTypeLevel(data?.employmentType, contractTypes)}
          </div>
        </div>
      </div>
      <div className="flex gap-x-[8px]">
        <div className="flex-1">
          <Typography className={classes.workDetailType}>
            PROJECT DURATION
          </Typography>
          <div className="text-[16px]">
            {`${startTime?.month} ${startTime?.year}`} -{" "}
            {isCurrentlyEmployed
              ? "Present"
              : `${endTime?.month} ${endTime?.year}`}
          </div>
        </div>
        <div className="flex-1">
          <Typography className={classes.workDetailType}>INDUSTRY</Typography>
          <div className="text-[16px]">
            {getIndustryName(data?.industry, industryData)}
          </div>
        </div>
      </div>
      <div className="flex gap-x-[8px]">
        <div className="flex-1">
          <Typography className={classes.workDetailType}>LOCATION</Typography>
          <div className="text-[16px]">{`${data?.country},${data?.state}, ${data?.city}`}</div>
        </div>
        <div className="flex-1">
          <Typography className={classes.workDetailType}>TIMEZONE</Typography>
          <div className="text-[16px]">{data?.timezone}</div>
        </div>
      </div>
      <div>
        <Typography className={classes.workDetailType}>SKILLS</Typography>
        <div className="flex gap-x-[12px] w-full overflow-x-auto pb-[16px]">
          {isValidArray(skills) ? (
            <>
              {" "}
              {skills?.map((skill) => (
                <Chip
                  label={skillIDToNameMapper?.[skill?.skillID]}
                  variant="outlined"
                  classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                />
              ))}
            </>
          ) : (
            "-"
          )}
        </div>
      </div>
    </div>
  );
};

export const WorkHistory = ({
  data,
  skillIDToNameMapper,
  contractTypes,
  industryData,
}) => {
  const classes = useStyles();

  const subscriptionPlan = localStorage.getItem("planName"); //here we need to add the free plan Id from info api
  const [isTrial, setIsTrial] = useState(subscriptionPlan === "free");
  // console.log("isTrial from profile", isTrial);
  const isTrialByadmin = localStorage.getItem("isSubscribedByAdmin"); //here we need to add the free plan Id from info api
  const [isSubscribedByAdmin, setIsSubscribedByAdmin] = useState(isTrialByadmin === "true");

  // console.log("isTrial subscriptionPlan", subscriptionPlan);

  return (
    <>
      {(isTrial && !isSubscribedByAdmin) ? (
        <Alert severity="info">
          "Access to this feature is exclusive to our premium subscription
          plans. Upgrade now to enjoy all the benefits!"{" "}
        </Alert>
      ) : (
        <div className="max-w-[983px] overflow-x-auto p-[16px]">
          {isValidArray(data) ? (
            <div className="flex gap-x-[24px] ">
              {data.map((card, index) => (
                <WorkHistoryCard
                  data={card}
                  skillIDToNameMapper={skillIDToNameMapper}
                  contractTypes={contractTypes}
                  industryData={industryData}
                />
              ))}
            </div>
          ) : (
            <Alert severity="info">{messages?.NO_DATA_FOUND}</Alert>
          )}
        </div>
      )}
    </>
  );
};

const DataTile = ({ data }) => {
  const duration = data?.duration; // for education.
  const isCurrentlyPursuing = duration?.isCurrentlyPursuing; // for education.
  const startTime = duration?.start;
  const endTime = duration?.end;
  const issuedOn = data?.issuedOn; // for certification or language or award.

  return (
    <div className="flex flex-col gap-y-[8px] pl-[24px] border-l-[1px] border-solid border-l-[#717171]">
      <div className="text-[16px] font-bold">
        {data?.certificateTitle ??
          data?.languageCertificateTitle ??
          data?.awardTitle}
      </div>
      <div className="flex gap-x-[8px]">
        <div className="text-[14px] font-semibold uppercase text-[#717171]">
          {data?.universityName ?? data?.issuedBy}
        </div>
        {data?.courseName && (
          <div className="flex items-center gap-x-[8px] text-[14px] font-semibold uppercase text-[#717171]">
            <IconDot fillColor="#555" /> {data?.courseName}{" "}
          </div>
        )}
      </div>
      {(duration || issuedOn) && (
        <div className="text-[12px] text-[#717171]">
          {duration ? (
            <>
              {`${startTime?.month} ${startTime?.year}`} -{" "}
              {isCurrentlyPursuing
                ? "Present"
                : `${endTime?.month} ${endTime?.year}`}
            </>
          ) : (
            <>{`${issuedOn?.month} ${issuedOn?.year}`}</>
          )}
        </div>
      )}

      {/* description value is available in certification */}
      {data?.description && (
        <div className="text-[14px] text-[#717171]">{data?.description}</div>
      )}
    </div>
  );
};

export const EducationAndCertification = ({
  educationData,
  certificationData,
}) => {
  const classes = useStyles();
  return (
    <div className="p-[16px]">
      <Typography className={classes.sectionSubHeading}>
        Educational History
      </Typography>
      <div className="flex flex-col gap-y-[16px] mb-[24px] max-h-[269px] overflow-y-auto">
        {isValidArray(educationData) ? (
          <>
            {educationData?.map((entry) => (
              <DataTile data={entry} />
            ))}
          </>
        ) : (
          <Alert severity="info">{messages?.NO_DATA_FOUND}</Alert>
        )}
      </div>
      <Typography className={classes.sectionSubHeading}>
        Certification
      </Typography>
      <div className="flex flex-col gap-y-[16px] max-h-[269px] overflow-y-auto">
        {isValidArray(certificationData) ? (
          <>
            {certificationData?.map((entry) => (
              <DataTile data={entry} />
            ))}
          </>
        ) : (
          <Alert severity="info">{messages?.NO_DATA_FOUND}</Alert>
        )}
      </div>
    </div>
  );
};

export const LanguageProficiency = ({ data }) => {
  return (
    <div className="flex flex-col gap-y-[16px] p-[16px] max-h-[269px] overflow-y-auto">
      {isValidArray(data) ? (
        <>
          {data?.map((entry) => (
            <DataTile data={entry} />
          ))}
        </>
      ) : (
        <Alert severity="info">{messages?.NO_DATA_FOUND}</Alert>
      )}
    </div>
  );
};

export const Awards = ({ data }) => {
  const classes = useStyles();
  return (
    <div className="flex flex-col gap-y-[16px] p-[16px] max-h-[269px] overflow-y-auto">
      {isValidArray(data) ? (
        <>
          {data?.map((entry) => (
            <DataTile data={entry} />
          ))}
        </>
      ) : (
        <Alert severity="info">{messages?.NO_DATA_FOUND}</Alert>
      )}
    </div>
  );
};

const TestimonyCard = ({ data }) => {
  const classes = useStyles();
  return (
    <div className="min-w-[452px] flex flex-col gap-y-[8px] p-[24px] border-[1px] border-solid border-[#717171] box-content">
      <div className="text-[16px] text-[#717171] ">{data?.testimony}</div>
      <div className="text-[18px] font-semibold">{data?.givenBy}</div>
      <div className="text-[14px] font-semibold">{data?.role}</div>
      <div className="text-[14px] font-semibold uppercase text-[#ABABAB]">
        {data?.projectName}
      </div>
    </div>
  );
};

export const Testimonials = ({ data }) => {
  const classes = useStyles();

  return (
    <div className="max-w-[983px] overflow-x-auto p-[16px]">
      {isValidArray(data) ? (
        <div className="flex gap-x-[24px] ">
          {data.map((card, index) => (
            <TestimonyCard data={card} />
          ))}
        </div>
      ) : (
        <Alert severity="info">{messages?.NO_DATA_FOUND}</Alert>
      )}
    </div>
  );
};

export const Resumes = ({ doc, video }) => {
  const classes = useStyles();

  const subscriptionPlan = localStorage.getItem("planName"); //here we need to add the free plan Id from info api

  const [isTrial, setIsTrial] = useState(subscriptionPlan === "free");

  const isTrialByadmin = localStorage.getItem("isSubscribedByAdmin"); //here we need to add the free plan Id from info api
  const [isSubscribedByAdmin, setIsSubscribedByAdmin] = useState(isTrialByadmin === "true");

  return (
    <div className="p-[16px]">
      <Typography className={classes.sectionSubHeading}>
        Resume Document
      </Typography>
      {(isTrial && !isSubscribedByAdmin) ? (
        <Alert severity="info">
          "Access to this feature is exclusive to our premium subscription
          plans. Upgrade now to enjoy all the benefits!"{" "}
        </Alert>
      ) : (
        <div className="mb-[24px]">
          {doc ? (
            <FilePreview fileUrlOrContent={doc} />
          ) : (
            <Alert severity="info">{messages.NO_PREVIEW_AVAILABLE}</Alert>
          )}
        </div>
      )}

      <Typography className={classes.sectionSubHeading}>
        Video Resume
      </Typography>
      {(isTrial && !isSubscribedByAdmin) ? (
        <Alert severity="info">
          "Access to this feature is exclusive to our premium subscription
          plans. Upgrade now to enjoy all the benefits!"{" "}
        </Alert>
      ) : (
        <>
          {video ? (
            <video className="video-player w-[100%]  h-[433px]" controls>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <Alert severity="info">{messages.NO_PREVIEW_AVAILABLE}</Alert>
          )}
        </>
      )}
    </div>
  );
};
