import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Link, Grid,Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import ManageAgency from "./ManageAgency";
import Documents from "./Documents";
import HelpSupport from "./HelpSupport";
import OrganizationDetails from "./OrganizationDetails";
import PersonalDetails from "./PersonalDetails";
import Title from "../../common/Title";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import SubscriptionBody from "./SubscriptionBody";

const useStyles = makeStyles((theme) => ({
  titleDiv: {
    position: "fixed",
    width: "1440px",
    borderBottom: "1px solid #EDEDED",
    backgroundColor: "white",
    zIndex: 1,
  },
  settingsContainer: {
    paddingTop: "102px", // titleDiv's total hieght is 102px.
  },
  leftView: {
    padding: "24px 48px",
    width: "291px",
    boxSizing: "content-box",
  },
  navItemsDiv: {
    display: "flex",
    flexDirection: "column",
    rowGap: "32px",
    margin: "16px 0",
  },
  navItemLink: {
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "18px",
  },
  navItemTitle: {
    fontSize: "16px",
    fontWeight: 600,
  },
  selectedNavItem: {
    color: `${theme.palette.primary.main} !important`,
  },
  rightArrowIcon: {
    width: "21px",
    height: "21px",
    float: "right",
  },
  subMenuDiv: {
    display: "flex",
    flexDirection: "column",
    margin: "16px 12px 0",
    rowGap: "12px",
  },
  rightView: {
    borderLeft: "1px solid #EDEDED",
    padding: "48px",
    width: "auto",
    boxSizing: "content-box",
    [theme.breakpoints.down("1440")]: {
      width: "900px",
    },
    [theme.breakpoints.down("1385")]: {
      width: "800px",
    },
    [theme.breakpoints.down("1285")]: {
      width: "700px",
    },
    [theme.breakpoints.down("1185")]: {
      width: "600px",
    },
  },

  rightView2: {
    borderLeft: "1px solid #EDEDED",
    // padding: "48px",
    width: "956px",
    boxSizing: "content-box",
    margin:"0px",
    padding:"0px",
    [theme.breakpoints.down("1440")]: {
      width: "900px",
    },
    [theme.breakpoints.down("1385")]: {
      width: "800px",
    },
    [theme.breakpoints.down("1285")]: {
      width: "700px",
    },
    [theme.breakpoints.down("1185")]: {
      width: "600px",
    },
  },
}));

const Settings = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const classes = useStyles();
  const leftNavData = [
    {
      title: "Subscription",
      component: <SubscriptionBody />,
    },
    {
      title: "Manage Agency",
      component: <ManageAgency />,
    },
    {
      title: "DocuCenter",
      component: <Documents />,
    },
    {
      title: "Profile Settings",
      subMenu: [
        {
          title: "Organization Details ",
          component: <OrganizationDetails />,
        },
        {
          title: "Personal Details",
          component: <PersonalDetails />,
        },
      ],
    },
    {
      title: "Help & Support",
      component: <HelpSupport />,
    },
  ];
  const [selectedNavItemIndex, setSelectedNavItemIndex] = useState(0);
  const [selectedPSMenuIndex, setSelectedPSMenuIndex] = useState(0); // PS - Profile Setting.

  useEffect(() => {
    const locationState = location?.state;
    const parentSettingIndex = locationState?.parentSettingIndex;
    const childSettingIndex = locationState?.childSettingIndex;
    if (parentSettingIndex) setSelectedNavItemIndex(parentSettingIndex);
    if (childSettingIndex) setSelectedPSMenuIndex(childSettingIndex); // currently child settings is only available for profle settings.
  }, []);

  const isNavItemProfileSetting = (index) => {
    return index === 2;
  };
  const isProfileSettingSelected = () => {
    return selectedNavItemIndex === 2;
  };

  const onNavItemClick = (navItemIndex) => {
    setSelectedNavItemIndex(navItemIndex);
    if (isProfileSettingSelected()) setSelectedPSMenuIndex(0); // setting to first item in menu.
  };

  const isCurrentSelectedNavItem = (navItemIndex) => {
    return selectedNavItemIndex === navItemIndex;
  };

  const onPSItemClick = (navItemIndex) => {
    setSelectedNavItemIndex(2); // setting to profile setting nav item.
    setSelectedPSMenuIndex(navItemIndex);
  };

  const isCurrentSelectedPSItem = (navItemIndex) => {
    return selectedPSMenuIndex === navItemIndex;
  };

  const SelectedNavItemComponent = () => {
    return <>{leftNavData?.[selectedNavItemIndex]?.component}</>;
  };

  const PSSelectedItemComponent = () => {
    const subMenuDetails = leftNavData?.[selectedNavItemIndex]?.subMenu;
    const selectedItemComponent =
      subMenuDetails?.[selectedPSMenuIndex]?.component;
    return selectedItemComponent;
  };
  // console.log("selectbavotem inde",selectedNavItemIndex)

  useEffect(()=>{

    window.scrollTo(0, 0)
  },[selectedNavItemIndex])

  return (
    <>
      <div className={classes.titleDiv}>
        <Title
          title={leftNavData?.[selectedNavItemIndex]?.title ?? "Settings"}
        />
      </div>

      <Grid container className={classes.settingsContainer}>
        <Grid item className={classes.leftView}>
          <div className={classes.navItemsDiv}>
            {leftNavData?.map((navItemDetails, navItemIndex) => (
              <div>
                <Link
                  className={clsx([
                    classes.navItemLink,
                    isCurrentSelectedNavItem(navItemIndex) &&
                      classes.selectedNavItem,
                  ])}
                  onClick={() => {
                    onNavItemClick(navItemIndex);
                  }}
                >
                  <Typography className={classes.navItemTitle}>
                    {navItemDetails?.title}{" "}
                    {isNavItemProfileSetting(navItemIndex) && (
                      <ArrowForwardIosIcon className={classes.rightArrowIcon} />
                    )}
                  </Typography>
                </Link>
                {isNavItemProfileSetting(navItemIndex) && (
                  <div className={classes.subMenuDiv}>
                    {navItemDetails?.subMenu?.map(
                      (subMenuItem, subMenuIndex) => (
                        <Link
                          className={clsx([
                            classes.navItemLink,
                            isProfileSettingSelected() &&
                              isCurrentSelectedPSItem(subMenuIndex) &&
                              classes.selectedNavItem,
                          ])}
                          onClick={() => {
                            onPSItemClick(subMenuIndex);
                          }}
                        >
                          <Typography className={classes.navItemTitle}>
                            {subMenuItem?.title}
                          </Typography>
                        </Link>
                      )
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </Grid>

        <Grid item className={classes.rightView}>
          {isProfileSettingSelected() ? (
            <PSSelectedItemComponent />
          ) : (
            <SelectedNavItemComponent />
          )}

{selectedNavItemIndex === 1  && (
            <div >

<Grid
                container
                spacing={2}
                sx={{ borderBottom: "1px solid lightgray", marginTop: "10px" }}
              >
                <Grid item xs={2.5}>
                  <Typography sx={{ fontWeight: "600" }} variant="subtitle1">
                    Membership
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: "12px",
                      marginBottom: "10px",
                      marginRight: "10px",
                    }}
                  >
                    AVAILABILITY
                    <br />
                    120
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "12px", color: "lightgray" }}
                  ></Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "12px", color: "lightgray" }}
                  ></Typography>
                </Grid>
                <Grid item xs={2.5}>
                  <Typography
                    variant="subtitle2"
                    sx={{ width: "53px", height: "13px", fontSize: "10px" }}
                  ></Typography>
                  <Typography variant="subtitle2"></Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "12px", color: "gray" }}
                  ></Typography>
                  <Typography
                    sx={{ color: "green", fontWeight: 50, fontheight: 21 }}
                  ></Typography>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ borderBottom: "4px" }}>
                    <Typography
                      variant="subtitle2"
                      sx={{ width: "55px", height: "13px", fontSize: "10px" }}
                    ></Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ width: "71px", height: "21px" }}
                    >
                      {" "}
                    </Typography>
                  </div>
                  <Box
                    sx={{
                      height: "40px",
                      p: 2,
                      width: "90%",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2"></Typography>
                        <Typography variant="subtitle2"></Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2"></Typography>
                        <Typography variant="subtitle2"></Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <button
                    style={{
                      border: "1px solid gray",
                      padding: "6px",
                      background: "black",
                      color: "white",
                      marginRight: "3px",
                      marginTop: "10px",
                    }}
                  >
                    BuyConnects
                  </button>

                  <Typography
                    sx={{
                      p: 1,

                      width: "100%",
                      marginTop: "30px",
                      alignContent: "center",
                      textAlign: "center",
                    }}
                    variant="subtitle2"
                  ></Typography>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </div>
          )}
        </Grid>

       
        
      </Grid>
    </>
  );
};

Settings.propTypes = {};

export default Settings;
