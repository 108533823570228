import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import "./styles.scss";
import clsx from "clsx";
import { PASSWORD_PATTERN, EMAIL_PATTERN } from "app/v2/Pages/Utils/constants";

import {
  FormControlLabel,
  Switch,
  Typography,
  Button,
  Checkbox,
  Tooltip,
  CircularProgress,
  Alert,
} from "@mui/material";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomCheckbox from "app/v2/components/CustomCheckbox";
import CustomSwitch from "app/v2/components/filters/CustomSwitch";
import CustomDialog from "app/v2/components/CustomDialog";
import CustomSelect from "app/v2/components/filters/CustomSelect";
import DialogSuccessBody from "app/v2/Pages/common/DialogSuccessBody";
import DialogErrorBody from "app/v2/Pages/common/DialogErrorBody";
import DialogLoadingBody from "app/v2/Pages/common/DialogLoadingBody";
import PhoneInput from "react-phone-input-2";
import { getCandidateInfo } from "app/v2/services/consultant/profileSettings.service";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  candidateInfoThunk,
  updateCandidateInfoThunk,
} from "app/v2/store/features/consultant/consultantProfile.thunk";
import { Link, useLocation } from "react-router-dom";
import { messages } from "app/v2/Pages/Utils/constants";
import CustomTooltip from "app/v2/components/CustomTooltip";

const useStyles = makeStyles((theme) => ({
  actionsDiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    paddingBottom: "36px",

    position: "fixed",
    width: "inherit",
    paddingTop: "48px",
    backgroundColor: theme.palette.common.white,
    zIndex: 2,
  },
  saveButton: {
    "&:disabled": {
      backgroundColor: `${theme.palette.common.black} !important`,
    },
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "center",
  },
  activationDialog: {
    width: "795px",
    padding: "48px",
    display: "flex",
    flexDirection: "column",
    rowGap: "33px",
  },
  activationTitle: {
    fontSize: "24px",
    fontWeight: 600,
  },
  activationNote: {
    fontSize: "14px",
    fontStyle: "italic",
  },
  verificationDiv: {
    padding: "36px",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
    backgroundColor: "#F4F8FF",
  },
  verificationText: {
    fontSize: "18px",
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  verificationDesc: {
    fontSize: "14px",
  },
  submitButton: {
    padding: "16px 28px",
    backgroundColor: `${theme.palette.common.black} !important`,
    color: theme.palette.common.white,
  },
  deactivateButton: {
    padding: "16px 28px",
    backgroundColor: "#FFECEA !important",
    color: "#D80027",
  },
  reactivationDetails: {},
  reactivateButton: {
    padding: "16px 28px",
    backgroundColor: `${theme.palette.common.black} !important`,
    color: theme.palette.common.white,
  },
}));

const personalDummyData = {
  firstName: "Princy",
  lastName: "Ajit",
  email: "princy@findpro.ai",
  password: "Dummypassword@123",
  mobileNo: "+919959845375",
  notificationTypes: {
    nT1: true,
    nT2: true,
    nT3: true,
    nT4: true,
  },
  notifications: {
    whatsapp: true,
    email: false,
    webApp: true,
  },
};

const SaveDialog = ({
  dialogOpen,
  setDialogOpen,
  actionLoading,
  actionCompleted,
  actionError,
  errorMessage,
}) => {
  const Title = () => {
    return (
      <div className="text-[16px] font-semibold text-center">
        {actionLoading && "Action in progress"}
        {actionError && "Error"}
        {actionCompleted && "Personal Details Saved"}
      </div>
    );
  };

  const DialogBody = () => {
    if (actionLoading) return <DialogLoadingBody />;
    else if (actionCompleted)
      return (
        <DialogSuccessBody
          content={{
            title: "Your details have been saved successfully.",
          }}
        />
      );
    else if (actionError)
      return (
        <DialogErrorBody
          content={{
            title: messages.GENERIC_ERROR_MESSAGE,
            desc: errorMessage ? errorMessage : "",
          }}
        />
      );
    else return <></>;
  };

  return (
    <CustomDialog
      open={dialogOpen}
      setOpen={setDialogOpen}
      noCloseAction={actionLoading}
      title={<Title />}
      content={<DialogBody />}
    />
  );
};

const AccountSettings = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const location = useLocation();
  const pathName = location?.pathname?.split("/");
  const accountType = pathName[1];

  const [initialPersonalData, setInitialPersonalData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobileNo: "",
    phone_number: "",
    notificationTypes: { nT1: false, nT2: false, nT3: false, nT4: false, nT5: false }, // here 1,2,3,4 corresponds to the values of the checkboxes.
    notifications: {
      whatsapp: true,
      email: true,
      web: true,
    },
  });

  const [personalData, setPersonalData] = useState(initialPersonalData);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [resetTextfield, setResetTextField] = useState(false);
  const [allEntriesValid, setAllEntriesValid] = useState(false);
  const [isDeactivateMode, setIsDeActivateMode] = useState(true);
  const [openActivationDialog, setOpenActivationDialog] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [saveActionLoading, setSaveActionLoading] = useState(false);
  const [saveActionCompleted, setSaveActionCompleted] = useState(false);
  const [saveActionError, setSaveActionError] = useState(false);
  const [saveActionErrorMessage, setSaveActionErrorMessage] = useState("");

  const toggleEditMode = () => {
    setIsEditMode((prevState) => !prevState);
  };

  const onCancelClick = () => {
    setPersonalData(initialPersonalData);
    setResetTextField((prevState) => !prevState);
    toggleEditMode();
  };

  const onSaveClick = () => {
    setSaveActionLoading(true);
    setSaveDialogOpen(true);

    const newPersonalDataObject = { ...personalData }; // Copy the original JSON object

    // Remove the "subscription" key from the new objects
    if (newPersonalDataObject.hasOwnProperty("subscription")) {
      delete newPersonalDataObject.subscription;
    }
    if (newPersonalDataObject.hasOwnProperty("verified")) {
      delete newPersonalDataObject.verified;
    }
    if (newPersonalDataObject.hasOwnProperty("verificationToken")) {
      delete newPersonalDataObject.verificationToken;
    }

    dispatch(updateCandidateInfoThunk(newPersonalDataObject))
      .unwrap()
      .then((res) => {
        setIsEditMode(false);
        setSaveActionLoading(false);
        setSaveActionCompleted(true);
        setInitialPersonalData(personalData);
      })
      .catch((err) => {
        setSaveActionLoading(false);
        setSaveActionError(true);
        setSaveActionCompleted(false);
        if (err?.message) setSaveActionErrorMessage(err.message);
      });
  };

  const onPersonalDataChange = (id, value) => {
    setPersonalData((prevPersonalData) => ({
      ...prevPersonalData,
      [id]: value,
    }));
  };

  const onNotificationTypeCBChange = (id, checkboxState) => {
    setPersonalData((prevPersonalData) => {
      let updatedPersonalData = { ...prevPersonalData };
      updatedPersonalData.notificationTypes = {
        ...updatedPersonalData.notificationTypes,
        [id]: checkboxState,
      };
      return updatedPersonalData;
    });
  }; // CB - checkbox

  const onNotificationRadioChange = (id, value) => {
    setPersonalData((prevPersonalData) => {
      let updatedPersonalData = { ...prevPersonalData };
      updatedPersonalData.notifications = {
        ...updatedPersonalData.notifications,
        [id]: value,
      };
      return updatedPersonalData;
    });
  };

  const validateEntries = () => {
    // data validation (No condition for notification checkboxes and radio buttons since they will have a state).
    // const firstNameEntered = personalData?.firstName?.length > 0;
    // const firstNameEntered = personalData?.first_name?.length > 0;
    // const lastNameEntered = personalData?.lastName?.length > 0;
    // const lastNameEntered = personalData?.last_name?.length > 0;
    // const validEmail = EMAIL_PATTERN.test(personalData?.email);
    // const validPassword = PASSWORD_PATTERN.test(personalData?.password);
    // const mobileNoEntered = personalData?.mobileNo?.length > 0;
    // const mobileNoEntered = personalData?.personal_info?.phone_number?.length > 0;
    const mobileNoEntered = personalData?.phone_number?.length > 0;

    setAllEntriesValid(
      // firstNameEntered &&
      //   lastNameEntered &&
      // validEmail &&
      // validPassword &&
      mobileNoEntered
    );
  };
  useEffect(() => {
    // setInitialPersonalData(personalDummyData);
    // setPersonalData(personalDummyData);

    setDataLoading(true);
    dispatch(candidateInfoThunk())
      .unwrap()
      .then((res) => {
        if (res?.consultant) {
          setPersonalData(res.consultant);
          setInitialPersonalData(res.consultant);
        } else setDataError(true);
      })
      .catch((err) => {
        setDataError(true);
      });
    setDataLoading(false);
  }, []);

  useEffect(() => {
    // console.log({ personalData });
    validateEntries();
  }, [personalData]);

  const ActivationDialogTitle = () => {
    return (
      <Typography className={classes.dialogTitle}>
        {isDeactivateMode ? "Deactivate" : "Reactivate"} Account
      </Typography>
    );
  };

  const ActivationDialogContent = () => {
    const reactivationReasons = ["A", "B", "C"];
    const deactivationNote =
      "Please note: Deactivation will be effective immediately Upon deactivation, you may only reactivate your account by getting in touch with the Findpro Team.";
    const reactivationNote =
      "Please note: Reactivation can only be effective once the Findpro.ai Team has investivated and approved the request. Upon submission of request, standby for further directives from the Findpro.ai Team via your account email’s inbox.";

    const [password, setPassword] = useState();
    const [reactivationReason, setReactivationReason] = useState();
    const [reactivationDetails, setReactivationDetails] = useState();

    const onPasswordChange = (id, value) => {
      setPassword(value);
    };

    const onReactivationReasonChange = (id, value) => {
      setReactivationReason(value);
    };

    const onActivationClick = () => {
      setOpenActivationDialog(false);
      setIsDeActivateMode((prevState) => !prevState);
    };

    return (
      <div className={classes.activationDialog}>
        {isDeactivateMode ? (
          <>
            <Typography className={classes.activationTitle}>
              Deactivate Account
            </Typography>
            <div className={classes.verificationDiv}>
              <Typography className={classes.verificationText}>
                Verification
              </Typography>
              <Typography className={classes.verificationDesc}>
                Verify your identity by logging in your account details again.{" "}
              </Typography>
              <CustomTextfield
                id="email"
                type="email"
                label="email"
                value={personalData?.email}
                // onChange={onPersonalDataChange}
                disabled={true}
              />
              <CustomTextfield
                id="password"
                type="password"
                label="password"
                value={password}
                onChange={onPasswordChange}
              />
              <Button className={classes.submitButton}>Submit</Button>
            </div>
            <Typography className={classes.activationNote}>
              {deactivationNote}
            </Typography>
            <Button
              onClick={onActivationClick}
              className={classes.deactivateButton}
            >
              Deactivate Account
            </Button>
          </>
        ) : (
          <>
            <Typography className={classes.activationTitle}>
              Reactivate Account
            </Typography>

            <CustomSelect
              id="reactivationReason"
              data={reactivationReasons}
              label="Reason for reactivation"
              placeholder={"Select"}
              onChange={onReactivationReasonChange}
              defaultSelection={reactivationReason}
            />

            <textarea
              value={reactivationDetails}
              onChange={setReactivationDetails}
              className={classes.reactivationDetails}
            ></textarea>
            <Typography className={classes.activationNote}>
              {reactivationNote}
            </Typography>
            <Button
              onClick={onActivationClick}
              className={classes.reactivateButton}
            >
              Request Reactivation
            </Button>
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (!saveDialogOpen) {
      // Setting back to default states- used when the dialog is closed.
      setSaveActionLoading(false);
      setSaveActionCompleted(false);
      setSaveActionError(false);
    }
  }, [saveDialogOpen]);
  return (
    <>
      {dataLoading ? (
        <CircularProgress />
      ) : (
        <>
          {dataError ? (
            <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
          ) : (
            <>
              <div className={classes.actionsDiv}>
                {isEditMode ? (
                  <div className="flex gap-x-[12px]">
                    <button
                      onClick={onCancelClick}
                      className="cursor-pointer [border:none] py-[12px] px-[28px] bg-neutral-50 flex  items-center justify-center"
                    >
                      <div className="relative text-base font-semibold font-button-text-button-14 text-neutral-900 text-center">
                        Cancel
                      </div>
                    </button>
                    <Tooltip
                      arrow
                      title={
                        !allEntriesValid && "Please enter all the details."
                      }
                    >
                      <button
                        onClick={onSaveClick}
                        className={clsx([
                          classes.saveButton,
                          "cursor-pointer [border:none] py-[12px] px-[28px] bg-neutral-50 flex  items-center justify-center bg-black ",
                        ])}
                        disabled={!allEntriesValid}
                      >
                        <div className="relative text-base font-semibold font-button-text-button-14 text-white text-center">
                          Save Changes
                        </div>
                      </button>
                    </Tooltip>
                  </div>
                ) : (
                  <button
                    onClick={toggleEditMode}
                    className="cursor-pointer [border:none] py-[12px] px-[28px] bg-neutral-50 flex  items-center justify-center"
                  >
                    <div className="relative text-base font-semibold font-button-text-button-14 text-neutral-900 text-center">
                      Edit
                    </div>
                  </button>
                )}
              </div>

              <div className="pt-[135px] flex flex-col gap-y-[36px]">
                {/* Personal Details- START */}
                <div className="flex flex-col py-0 px-4  gap-[24px] text-xs">
                  {/* <div className="flex gap-[12px] w-full">
                    <CustomTextfield
                      // id="firstName"
                      id="first_name"
                      label="First name"
                      // value={personalData?.firstName}
                      value={personalData?.first_name}
                      onChange={onPersonalDataChange}
                      disabled={!isEditMode}
                      reset={resetTextfield}
                    />

                    <CustomTextfield
                      // id="lastName"
                      id="last_name"
                      label="Last name"
                      // value={personalData?.lastName}
                      value={personalData?.last_name}
                      onChange={onPersonalDataChange}
                      disabled={!isEditMode}
                      reset={resetTextfield}
                    />
                  </div> */}
                  <div className="flex flex-col gap-[6px]">
                    <CustomTextfield
                      id="email"
                      type="email"
                      label="email"
                      value={personalData?.email}
                      disabled={true}
                    />
                    <div className="relative leading-[180%]">
                      Connected email address cannot be changed.
                    </div>
                  </div>
                  <div className="flex flex-col gap-[12px]">
                    {/* <CustomTextfield
                      id="password"
                      type="password"
                      label="password"
                      value={personalData?.password}
                      onChange={onPersonalDataChange}
                      disabled={!isEditMode}
                      reset={resetTextfield}
                    /> */}
                    <Link
                      className="text-[14px] font-semibold underline"
                      to={`/${accountType}/forgot-password`}
                    >
                      If you’d like to change your password please follow
                      through.
                    </Link>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
                    <div className="relative leading-[120%] uppercase font-semibold">
                      mobile number
                    </div>
                    <div className="self-stretch flex  items-start justify-start gap-[12px]">
                      <PhoneInput
                        placeholder=""
                        value={personalData?.phone_number}
                        onChange={(val, e) => {
                          setPersonalData((prevPersonalData) => ({
                            ...prevPersonalData,

                            phone_number: val,
                          }));
                        }}
                        disabled={!isEditMode}
                      />
                    </div>
                  </div>
                </div>
                {/* Personal Details- END */}
                <hr className="mb-0" />

                {/* Notifications - START */}
                <div className=" flex flex-col  gap-[36px]">
                  <div className="text-3xl font-semibold">
                    Notification details
                  </div>
                  <div className="flex flex-col py-0 px-4  gap-[24px] text-sm text-neutral-800">
                    <div className="text-5xl font-semibold text-neutral-900">
                      Notification Type
                    </div>
                    <div className="flex flex-col gap-y-[24px]">
                      <CustomCheckbox
                        label="Application status change"
                        id="nT1"
                        checked={personalData?.notificationTypes?.nT1}
                        disabled={!isEditMode}
                        onChange={onNotificationTypeCBChange}
                      />
                      <CustomCheckbox
                        label="New job"
                        id="nT2"
                        checked={personalData?.notificationTypes?.nT2}
                        disabled={!isEditMode}
                        onChange={onNotificationTypeCBChange}
                      />
                      <CustomCheckbox
                        label="Timesheet ( Approved or Reject )"
                        id="nT3"
                        checked={personalData?.notificationTypes?.nT3}
                        disabled={!isEditMode}
                        onChange={onNotificationTypeCBChange}
                      />
                      <CustomCheckbox
                        label="New Timesheet"
                        id="nT4"
                        checked={personalData?.notificationTypes?.nT4}
                        disabled={!isEditMode}
                        onChange={onNotificationTypeCBChange}
                      />
                       <CustomCheckbox
                        label="Connection request"
                        id="nT5"
                        checked={personalData?.notificationTypes?.nT5}
                        disabled={!isEditMode}
                        onChange={onNotificationTypeCBChange}
                      />
                      
                    </div>
                  </div>
                  <div className="flex flex-col py-0 px-4 gap-[24px] text-5xl">
                    <div className="font-semibold">Notification Platform</div>
                    <div className="flex gap-[24px] text-sm text-neutral-800">
                      <CustomTooltip title="This feature will be available in future release.">
                        <div>
                          <CustomSwitch
                            id="whatsapp"
                            label="Whatsapp Notifications"
                            onChange={onNotificationRadioChange}
                            defaultSelection={
                              personalData?.notifications?.whatsapp
                            }
                            disabled={true}
                          />
                        </div>
                      </CustomTooltip>
                      <CustomSwitch
                        id="email"
                        label="Email Notifications"
                        onChange={onNotificationRadioChange}
                        defaultSelection={personalData?.notifications?.email}
                        disabled={!isEditMode}
                      />

                      <CustomSwitch
                        id="web"
                        label=" Web App Notifications"
                        onChange={onNotificationRadioChange}
                        defaultSelection={personalData?.notifications?.web}
                        disabled={!isEditMode}
                      />
                    </div>
                  </div>
                </div>
                {/* Notifications - END */}
                {/* Activate/Reactivate Account - Hidden for MVP */}
                {/* <div className="self-stretch flex flex-col items-start justify-start gap-[36px]">
                  <div className="text-3xl relative leading-[120%] font-semibold">
                    Account Activation
                  </div>
                  <div className="self-stretch flex flex-col py-0 px-4 items-start justify-start text-base">
                    <div className="flex flex-col items-start justify-start gap-[12px]">
                      <div className=" text-2xl relative leading-[130%] font-semibold inline-block w-[943px]">
                        Deactivate Account
                      </div>
                      <div className="self-stretch relative text-sm leading-[160%] text-neutral-500">
                        Once your account is deactivated, your listings and
                        current data won’t be available publicly on this app.
                        Only you will be able to access them. Upon deactivation,
                        you may only reactivate your account by getting in touch
                        with the Findpro Team. To proceed with reactivation
                        click the button below.
                      </div>
                      {isDeactivateMode ? (
                        <button
                          className="cursor-pointer py-3 px-7 bg-[transparent] flex  items-center justify-center border-[1px] border-solid border-ui-red-100"
                          onClick={() => {
                            setOpenActivationDialog(true);
                          }}
                        >
                          <div className="relative text-base font-semibold font-button-text-button-14 text-ui-red-500 text-center">
                            Deactivate Account
                          </div>
                        </button>
                      ) : (
                        <button
                          className="cursor-pointer py-3 px-7 bg-black flex  items-center justify-center border-[1px] border-solid border-ui-red-100"
                          onClick={() => {
                            setOpenActivationDialog(true);
                          }}
                        >
                          <div className="relative text-base font-semibold font-button-text-button-14 text-white text-center">
                            Reactivate Account
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                </div> */}
              </div>
              {/* <CustomDialog
                open={openActivationDialog}
                setOpen={setOpenActivationDialog}
                title={<ActivationDialogTitle />}
                content={<ActivationDialogContent />}
              /> */}
              {/* Activate/Reactivate Account - Hidden for MVP */}

              <SaveDialog
                dialogOpen={saveDialogOpen}
                setDialogOpen={setSaveDialogOpen}
                actionLoading={saveActionLoading}
                actionCompleted={saveActionCompleted}
                actionError={saveActionError}
                errorMessage={saveActionErrorMessage}
              />

              {/* **** */}
            </>
          )}
        </>
      )}
    </>
  );
};

export default AccountSettings;
