import React, { useEffect } from "react";
import SectionTitleAndIcon from "../Settings/SectionTitleAndIcon";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomSelect from "app/v2/components/filters/CustomSelect";
import { useState } from "react";
import CustomLabel from "app/v2/components/filters/CustomLabel";
import { FormControl, FormHelperText, OutlinedInput, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { makeStyles } from "@mui/styles";

import {
  isUndefined,
  isDateStringValidFormat,
  isTimeStringValidFormat,
  isValidString,
} from "app/v2/Pages/Utils/utilFunctions";
import dayjs from "dayjs";
import clsx from "clsx";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomTimeRangePicker from "../../common/CustomTimeRangePicker";
import { TWELVE_HOUR_TIME_FORMAT, messages } from "../../Utils/constants";
import CustomButton from "app/v2/components/CustomButton";
import SalaryPicker from "../../common/SalaryPicker";

const useStyles = makeStyles((theme) => ({
  dateOrTimePicker: {
    width: "100% !important",
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        fontSize: "16px",
      },
    },
  },
  dateOrTimePickerError: {
    "& .MuiInputBase-root": {
      border: "1px solid red",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        border: "1px solid red",
      },
    },
  },

  sendInviteButton: {
    width: "92%",
    margin: "20px",
    padding: "16px 28px",
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
    columnGap: "10px",
    fontSize: "16px",
    fontWeight: 600,
  },
  salaryText: {
    fontSize: "18px",
    fontWeight: 700,
    marginLeft: "24px",
  },
  salaryOtherElement: {
    position: "relative",
    top: "17px",
  },
  numberPickerAdornedStart: {
    fontSize: "24px",
    fontWeight: 600,
  },
  numberPickerText: {
    fontWeight: 400,
    fontSize: "18px",
    marginLeft: "10px",
    boxShadow: "none !important",
  },
}));

const AddTask = () => {
  const classes = useStyles();

  const [contractTypeValue, setContractTypeValue] = useState();
  const ContractType = ["Pay-as-you-go", "Milestone", "Fixed Rate"];
  const [timeExceeded, setTimeExceeded] = useState();

  //   const [startDate, setStartDate] = useState("");

  const [createTaskData, setCreateTaskData] = useState({
    Title: "",
    Description: "",
    ContractTypeValue: "",
    startDate: new Date(),
    dueDate: new Date(),
    startTime: "",
    endTime: "",
    salaryHourly:{},
    salaryMilestone: {},
    salaryFixedRate:{},
  });

  const salaryTypesDefaultRates = [
    {
      type: "hourly",
      fixedRate: 24,
      range: {
        min: 24,
        max: 32,
      },
    },
    {
      type: "monthly",
      fixedRate: 3600,
      range: {
        min: 2600,
        max: 3600,
      },
    },
    {
      type: "annual",
      fixedRate: 80000,
      range: {
        min: 70000,
        max: 90000,
      },
    },
  ];

  const onChangeOfCreateTask = (id, value) => {
    setCreateTaskData((prevValues) => {
      let newTaskData = {
        ...prevValues,
        [id]: value,
      };
    //  // console.log({ id, value, newTaskData });
      return newTaskData;
    });
  };

  const handleDateChange = (date) => {
    let formattedDateInput = "";
    try {
      formattedDateInput = dayjs(date).format("YYYY-MM-DD"); // sets date in ISO format specifically with EOD timestamp eg: "2023-12-12T23:59:59.999Z"

      if (formattedDateInput === "Invalid Date") formattedDateInput = "";
      else {
        const currentDate = dayjs().format("YYYY-MM-DD");

        // Check if the entered date is in the past. (the user can type in the date as well(through selection it is disabled by the time picker)).

        if (formattedDateInput < currentDate) formattedDateInput = "";
      }
    } catch (error) {
      formattedDateInput = "";
    }

    // setStartDate(formattedDateInput);
    onChangeOfCreateTask("startDate", formattedDateInput);
  };

  const isDateInvalid = () => {
    const currentDate = dayjs().format("YYYY-MM-DD");

    /**
     * Condition 1-> (!isUndefined(startDate) && startDate === "")
     * 1. While initially setting the start date it will be undefined,
     * and we dont have to show error message immediately,only when there's a change in date and it is invalid
     * or an older date is typed(both invalid and typing older date is handled-check date picker's onChange logic,
     * selecting older date isa disabled in the picker itself),then we have to show the message.
     * 2. But for edit case we specifically set it to null if its not a valid truthy value(since we dont show error if value is undefined in point 1).
     * Condition 2 -> startDate < currentDate
     * 1. This has been added as when the component receives an older date it shows the older date (which is a possible scenario,
     * For eg: I set the date for a future dat.And when the future date has passed when i try to view the component it should show the
     * older date but for updating it should be the current or future date,hence in this case also we show of the error message) but for updating
     * it should be current or a future date hence we added the past check to show the error message.
     */
    return (
      (!isUndefined(createTaskData?.startDate) &&
        createTaskData?.startDate === "") ||
      createTaskData.startDate < currentDate
    );
  };

  const hasTimeExceeded = () => {
    const date = isDateStringValidFormat(createTaskData.startDate, "YYYY-MM-DD")
      ? dayjs(createTaskData.startDate).format("YYYY-MM-DD")
      : null; // For edit case we get the date initially in complete ISO format instead of "YYYY-MM-DD" format. But the onInvite fn works fine if we pass the date in "YYYY-MM-DD" format. Same for the date element in Test Invite dialog.
    const startTime = createTaskData?.startTime;
    const today = dayjs().format("YYYY-MM-DD");
    const currentTime = dayjs().format(TWELVE_HOUR_TIME_FORMAT);

    if (Boolean(date) === false || date !== today) {
      return false; // No date entered or not today's date
    }

    return currentTime > startTime;
  };

  const onSalaryChange = (salaryDetailsReceived) => {
    onChangeOfCreateTask("salary", salaryDetailsReceived);
  };

  const onPayAsYouGoInputChange = (value) => {
    // let updatedSalaryTypeSelectedInfo = { ...createTaskData };
    // let fixedRateMessage;
    // value = parseInt(value);
    // updatedSalaryTypeSelectedInfo.salary = value;
    // fixedRateMessage =
    //   isNaN(value) || value <= 0 ? messages.FIXED_RATE_ERROR : "";
    // // setFixedRateErrorMessage(fixedRateMessage);
    // // setSalaryTypeSelectedInfo(updatedSalaryTypeSelectedInfo);
    onChangeOfCreateTask("salaryHourly", value);
  };

  const onMilestoneInputChange = (value) => {
    // let updatedSalaryTypeSelectedInfo = { ...createTaskData };
    // let fixedRateMessage;
    // value = parseInt(value);
    // updatedSalaryTypeSelectedInfo.salary = value;
    // fixedRateMessage =
    //   isNaN(value) || value <= 0 ? messages.FIXED_RATE_ERROR : "";
    // // setFixedRateErrorMessage(fixedRateMessage);
    // // setSalaryTypeSelectedInfo(updatedSalaryTypeSelectedInfo);
    onChangeOfCreateTask("salaryMilestone", value);
  };

  const onFixedRateInputChange = (value) => {
    // let updatedSalaryTypeSelectedInfo = { ...createTaskData };
    // let fixedRateMessage;
    // value = parseInt(value);
    // updatedSalaryTypeSelectedInfo.salary = value;
    // fixedRateMessage =
    //   isNaN(value) || value <= 0 ? messages.FIXED_RATE_ERROR : "";
    // // setFixedRateErrorMessage(fixedRateMessage);
    // // setSalaryTypeSelectedInfo(updatedSalaryTypeSelectedInfo);
    onChangeOfCreateTask("salaryFixedRate", value);
  };

  //// console.log("new task valules", createTaskData);

  useEffect(() => {
    const isTimeExceeded = hasTimeExceeded();
    setTimeExceeded(isTimeExceeded);
    // validateEntries(isTimeExceeded);
  }, [createTaskData]);

  return (
    <div className="w-full m-[15px] ">
      <SectionTitleAndIcon title={"Add Task"} />
      <div className="p-[16px] flex flex-col gap-y-[36px] w-[800px]">
        <div className="flex-1 w-full">
          <CustomTextfield
            id="Title"
            label="Title"
            placeholder="Enter the title"
            //   value={
            //     selectedEmployee
            //       ? selectedEmployee.firstName
            //       : overviewData?.firstName
            //   }
            onChange={onChangeOfCreateTask}
            //   showMandatorySymbol={true}
            //   disabled={!!selectedEmployee}
          />
        </div>
        <div className="flex-1 w-full">
          <CustomTextfield
            id="Description"
            label="Description"
            placeholder="Enter the task description"
            multiline={true}
            type="text"
            minRows={3}
            //   value={
            //     selectedEmployee
            //       ? selectedEmployee.firstName
            //       : overviewData?.firstName
            //   }
            onChange={onChangeOfCreateTask}
            //   showMandatorySymbol={true}
            //   disabled={!!selectedEmployee}
          />
        </div>

        <div className="flex flex-col">
          <CustomLabel label="start date" />
          <FormControl error={isDateInvalid()}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disablePast={true}
                value={
                  isDateStringValidFormat(
                    createTaskData.startDate,
                    "YYYY-MM-DD"
                  )
                    ? dayjs(createTaskData.startDate)
                    : null
                }
                onChange={(date) => {
                  handleDateChange(date);
                }}
                className={clsx([
                  classes.dateOrTimePicker,
                  isDateInvalid() && classes.dateOrTimePickerError,
                ])}
              />
            </LocalizationProvider>
            {isDateInvalid() && (
              <FormHelperText className="ml-0">
                Please enter a valid date. You can enter today's date or any
                date in the future.
              </FormHelperText>
            )}
          </FormControl>
        </div>
        <div className="flex flex-col">
          <CustomLabel label="Due date" />
          <FormControl error={isDateInvalid()}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disablePast={true}
                value={
                  isDateStringValidFormat(createTaskData.dueDate, "YYYY-MM-DD")
                    ? dayjs(createTaskData.dueDate)
                    : null
                }
                onChange={(date) => {
                  handleDateChange(date);
                }}
                className={clsx([
                  classes.dateOrTimePicker,
                  isDateInvalid() && classes.dateOrTimePickerError,
                ])}
              />
            </LocalizationProvider>
            {isDateInvalid() && (
              <FormHelperText className="ml-0">
                Please enter a valid date. You can enter today's date or any
                date in the future.
              </FormHelperText>
            )}
          </FormControl>
        </div>

        {/* <CustomTimeRangePicker
        label="work time"
        ampm={true}
        start={createTaskData?.startTime}
        end={createTaskData?.endTime}
        noStartEndComparison={true}
        // onChange={(formattedStartTime, formattedEndTime) => {
        //   // // console.log({
        //   //   formattedStartTime,
        //   //   formattedEndTime,
        //   // });
        //   setWorkTime({
        //     start: formattedStartTime,
        //     end: formattedEndTime,
        //   });
        // }}
        onChange={(formattedStartTime,formattedEndTime)=>{onChangeOfCreateTask("startTime",formattedStartTime) ; onChangeOfCreateTask("endTime",formattedEndTime) }}
      />

      {timeExceeded && (
        <div className="text-[12px] text-red-500">
          Selected time has already passed. Please choose a future time.
        </div>
      )} */}
        <CustomSelect
          id="ContractTypeValue"
          data={ContractType}
          label="Contract Type"
          placeholder={"Select"}
          onChange={onChangeOfCreateTask}
          // defaultSelection={onboardingStatus}
        />

        {createTaskData.ContractTypeValue === "Pay-as-you-go" && (
          <div className="flex flex-row ">
          <FormControl
                sx={{ width: "25ch" }}
                // error={Boolean(fixedRateErrorMessage)}
                // className={classes.fixedSalaryDiv}
                variant="outlined"
              >
                <OutlinedInput
                  key={`${"hourly"}-fixed-rate`}
                  id={`${"hourly"}-fixed-rate`}
                  type="number"
                  startAdornment={"$"}
                  value={createTaskData?.salaryHourly}
                  onChange={(event) => {
                    onPayAsYouGoInputChange(event.target.value);
                  }}
                  inputProps={{
                    min: 0,
                  }}
                  classes={{
                    adornedStart: classes.numberPickerAdornedStart,
                    input: classes.numberPickerText,
                  }}
                />
                {/* {fixedRateErrorMessage && (
                  <FormHelperText id="component-error-text">
                    {fixedRateErrorMessage}
                  </FormHelperText>
                )} */}
              </FormControl>     
              <Typography
              className={clsx([classes.salaryText, classes.salaryOtherElement])}
            >
              per hour
            </Typography>
              </div>  )}
        {createTaskData.ContractTypeValue === "Milestone" && (
          <div className="flex flex-row ">
          <FormControl
                sx={{ width: "25ch" }}
                // error={Boolean(fixedRateErrorMessage)}
                // className={classes.fixedSalaryDiv}
                variant="outlined"
              >
                <OutlinedInput
                  key={`${"Milestone"}-fixed-rate`}
                  id={`${"Milestone"}-fixed-rate`}
                  type="number"
                  startAdornment={"$"}
                  value={createTaskData?.salaryMilestone}
                  onChange={(event) => {
                    onMilestoneInputChange(event.target.value);
                  }}
                  inputProps={{
                    min: 0,
                  }}
                  classes={{
                    adornedStart: classes.numberPickerAdornedStart,
                    input: classes.numberPickerText,
                  }}
                />
                {/* {fixedRateErrorMessage && (
                  <FormHelperText id="component-error-text">
                    {fixedRateErrorMessage}
                  </FormHelperText>
                )} */}
              </FormControl>     
              <Typography
              className={clsx([classes.salaryText, classes.salaryOtherElement])}
            >
              per Milestone
            </Typography>
              </div>           
        )}
        {createTaskData.ContractTypeValue === "Fixed Rate" && (
          <div className="flex flex-row ">
          <FormControl
                sx={{ width: "25ch" }}
                // error={Boolean(fixedRateErrorMessage)}
                // className={classes.fixedSalaryDiv}
                variant="outlined"
              >
                <OutlinedInput
                  key={`${"Fixed Rate"}-fixed-rate`}
                  id={`${"Fixed Rate"}-fixed-rate`}
                  type="number"
                  startAdornment={"$"}
                  value={createTaskData?.salaryFixedRate}
                  onChange={(event) => {
                    onFixedRateInputChange(event.target.value);
                  }}
                  inputProps={{
                    min: 0,
                  }}
                  classes={{
                    adornedStart: classes.numberPickerAdornedStart,
                    input: classes.numberPickerText,
                  }}
                />
                {/* {fixedRateErrorMessage && (
                  <FormHelperText id="component-error-text">
                    {fixedRateErrorMessage}
                  </FormHelperText>
                )} */}
              </FormControl>     
              <Typography
              className={clsx([classes.salaryText, classes.salaryOtherElement])}
            >
              per Month
            </Typography>
              </div>          )}
        
      </div>

      <div>
        <CustomButton
          type="button1"
          customClasses={classes.sendInviteButton}
          // disabled={!activateSendInvite}
          onClick={() => {}}
        >
          Send Task <img src="/iconfilledsend.svg" />
        </CustomButton>
      </div>
    </div>
  );
};

export default AddTask;
