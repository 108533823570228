import React, { useEffect, useState } from "react";
import { FaTimes, FaPlus, FaEdit } from "react-icons/fa";
import { makeStyles } from "@mui/styles";
import CustomDialog from "app/v2/components/CustomDialog";
import AddCategoryForm2 from "./AddCategoryForm2";

const useStyles = makeStyles(() => ({
  app: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  category: {
    marginBottom: "20px",
    width: "100%",
    height: "150px",
    overflowY: "auto",
  },
  values: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    justifyContent: "flex-start",
  },
  valueBox: {
    position: "relative",
    padding: "10px 20px",
    backgroundColor: "#f0f0f0",
    color: "#333",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight:600,
    
    "&:hover $closeIcon": {
      opacity: 1,
    },
  },
  closeIcon: {
    position: "absolute",
    top: "5px",
    right: "5px",
    cursor: "pointer",
    opacity: 0,
    transition: "opacity 0.2s",
  },
  horizontalLine: {
    margin: "20px 0",
    border: "none",
    borderTop: "1px solid #ccc",
    width: "100%",
  },
  addIconContainer: {
    display: "flex",
    alignItems: "center",
  },
  addIcon: {
    fontSize: "24px",
    cursor: "pointer",
    borderRadius: "50%",
    backgroundColor: "#f0f0f0",
    padding: "10px",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  },
}));

const CategoriesList = ({ data }) => {
  const classes = useStyles();
  const [categories, setCategories] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("");
  const [currentName, setCurrentName] = useState("");
  const [currentId, setCurrentId] = useState("");

  const handleRemove = (category, value) => {
    setCategories((prevCategories) => ({
      ...prevCategories,
      [category]: prevCategories[category].filter(
        (item) => item.name !== value
      ),
    }));
  };

  const handleAdd = (category, newValue) => {
    setCategories((prevCategories) => ({
      ...prevCategories,
      [category]: [
        ...prevCategories[category],
        { _id: new Date().toISOString(), name: newValue },
      ],
    }));
  };

  const handleDialogOpen = (category, name, id) => {
    setCurrentCategory(category);
    setCurrentName(name);
    setCurrentId(id);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setCurrentCategory("");
  };

  useEffect(() => {
    setCategories(data);
  }, [data]);

  return (
    <div className={classes.app}>
      {Object.keys(categories).map((category, index) => (
        <React.Fragment key={category}>
          <div className="flex w-full justify-between items-center">
            <h2>{category}</h2>
            <div className={classes.addIconContainer}>
              <FaPlus
                className={classes.addIcon}
                onClick={() => handleDialogOpen(category)}
              />
            </div>
          </div>

          <div className={classes.category}>
            <div className={classes.values}>
              {categories[category].map((item) => (
                <div key={item._id} className={classes.valueBox}>
                  {item.name}
                  <FaEdit
                    className={classes.closeIcon}
                    onClick={() =>
                      handleDialogOpen(category, item?.name, item?._id)
                    }
                  />
                </div>
              ))}
            </div>
          </div>
          {index !== Object.keys(categories).length - 1 && (
            <hr className={classes.horizontalLine} />
          )}
        </React.Fragment>
      ))}
      <CustomDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        title={
          <div className="text-[18px] font-semibold text-center">
            Add Category
          </div>
        }
        content={
          <AddCategoryForm2
            category={currentCategory}
            currentName={currentName}
            currentId={currentId}
            handleDialogClose={handleDialogClose}
          />
        }
        onDialogClose={handleDialogClose}
      />
    </div>
  );
};

export default CategoriesList;
