import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ContentLoader from "react-content-loader";
import { Alert, IconButton, Menu, MenuItem, Fade } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useSnackbar } from "notistack";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CloseIcon from "@mui/icons-material/Close";

import Title from "../../common/Title";
import CustomButton from "app/v2/components/CustomButton";
import CustomTabs from "app/v2/components/CustomTabs";
import ActionDialog from "./ActionDialog";

import {
  getPlans,
  getFeatures,
} from "app/v2/services/admin/subscriptions.service";

import { messages } from "../../Utils/constants";
import { isValidArray, isValidObject } from "../../Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    display: "flex",
    border: "1px solid #EDEDED",
    margin: "24px 0",
    maxHeight: "600px",
    overflow: "auto",
  },
  featureColumn: {
    position: "sticky",
    left: 0,
    zIndex: 2, //  Ensure it appears above the scrollable content(setting it as 2 since the plans row is also sticky and has a zIndex of 1 so for that this column should appear above when we scroll down).
    backgroundColor: "white", // Set background color if needed
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    height: "fit-content",
  },
  planColumnsContainer: {
    display: "flex",
    height: "fit-content",
    width: "100%",
    minWidth: "872px",
  },
  planColumn: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    borderRight: "1px solid #EDEDED",
    "&:hover": {
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
      transform: "scale(1)",
      transition: "transform 0.3s ease-in-out",
    },
  },
  cell: {
    padding: "36px",
    whiteSpace: "nowrap",
    borderBottom: "1px solid #EDEDED",
    textAlign: "center",
    fontWeight: 600,
  },
  headingCell: {
    fontSize: "18px",
    position: "sticky",
    top: 0,
    zIndex: 1, // Ensure it appears above the scrollable content
    backgroundColor: "white", // Set background color if needed
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  }, // top row cells
  lastRowCell: {
    borderBottom: 0,
  },
  hoveredPlanNameCell: {
    backgroundColor: theme.palette.primary.main,
  },
  planNameCell: {
    display: "flex",
    columnGap: "8px",
    justifyContent: "center",
  },
  featureNameCell: {
    display: "flex",
    columnGap: "8px",
    justifyContent: "flex-end",
  },
  actionsButton: {
    fontWeight: 600,
    padding: "8px",
  },
  checkCell: {
    color: theme.palette.success.main,
  },
  crossCell: {
    color: "red",
  },
  actionMenu: {
    "& .MuiPaper-root": {
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    },
  },
  actionMenuItem: {
    fontWeight: 600,
    fontSize: "14px",
  },
  arrowIcon: {
    width: "20px",
    height: "20px",
  },
}));

const TabComponent = ({
  userType,
  data,
  dataLoading,
  dataError,
  onActionSuccess,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { features, featureIDs, plans } = data;

  const [hoveredColumnIndex, setHoveredColumnIndex] = useState();

  const [planMenuAnchor, setPlanMenuAnchor] = useState({});
  const [selectedPlanData, setSelectedPlanData] = useState();

  const [featureMenuAnchor, setFeatureMenuAnchor] = useState({});
  const [selectedFeatureData, setSelectedFeatureData] = useState();

  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [actionType, setActionType] = useState(""); // viewUpdateFeature,deleteFeature,viewUpdatePlan,deletePlan
  const [actionEntryData, setActionEntryData] = useState();

  const handleMenuOpen = (event, menuType, data) => {
    const actionEntryData = JSON.parse(JSON.stringify(data));
    if (menuType === "plan") {
      setPlanMenuAnchor({ [actionEntryData._id]: event.currentTarget });
      setSelectedPlanData(actionEntryData);
    } else {
      setFeatureMenuAnchor({ [actionEntryData._id]: event.currentTarget });
      setSelectedFeatureData(actionEntryData);
    }
  };
  const handleMenuClose = () => {
    setFeatureMenuAnchor({});
    setPlanMenuAnchor({});
    setHoveredColumnIndex(null); // to get rid of box shadow effect on hovered column once menu is closed(by clicking away from the table).
  };
  const getPlanFeatureIDs = (planFeatures) => {
    if (isValidArray(planFeatures)) {
      return planFeatures
        .map((planFeature) => planFeature._id)
        .filter((id) => id); // filtering truthy ids.
    }
    return [];
  };

  const onActionClick = (actionType, data) => {
    setActionType(actionType);
    setActionEntryData(data);
    setActionDialogOpen(true);
    handleMenuClose(); // closing the menu if any menu item action is  clicked.
  };

  return (
    <>
      {dataLoading ? (
        <ContentLoader
          speed={2}
          width={1390}
          height={800}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="40" rx="3" ry="3" width="1390" height="700" />
        </ContentLoader>
      ) : (
        <>
          {dataError ? (
            <Alert severity="error" className="my-[24px]">
              {messages?.GENERIC_ERROR_MESSAGE}{" "}
            </Alert>
          ) : (
            <>
              <>
                {isValidArray(features) || isValidArray(plans) ? (
                  <div className={classes.tableContainer}>
                    <div className={classes.featureColumn}>
                      <div
                        className={clsx([classes.cell, classes.headingCell])}
                      >
                        Features{" "}
                        <ArrowDownwardIcon className={classes.arrowIcon} /> /
                        Plans <ArrowForwardIcon className={classes.arrowIcon} />
                        {/* Feature names column */}
                      </div>
                      {isValidArray(features) ? (
                        <>
                          {features.map((featureInfo, index) => (
                            <>
                              {isValidObject(featureInfo) ? (
                                <div
                                  className={clsx([
                                    classes.cell,
                                    classes.featureNameCell,
                                    index === features.length - 1 &&
                                      classes.lastRowCell,
                                  ])}
                                  title={featureInfo.name}
                                >
                                  {featureInfo.name}
                                  <IconButton
                                    id={`${featureInfo.name}-action-button`}
                                    onClick={(event) => {
                                      handleMenuOpen(
                                        event,
                                        "feature",
                                        featureInfo
                                      );
                                    }}
                                    className="p-0"
                                  >
                                    <MoreVertIcon fontSize="small" />
                                  </IconButton>
                                  <Menu
                                    id={`${featureInfo.name}-menu`}
                                    MenuListProps={{
                                      "aria-labelledby": `${featureInfo.name}-menu`,
                                    }}
                                    anchorEl={
                                      featureMenuAnchor[featureInfo._id]
                                    }
                                    open={Boolean(
                                      featureMenuAnchor[featureInfo._id]
                                    )}
                                    onClose={() => {
                                      handleMenuClose();
                                    }}
                                    TransitionComponent={Fade}
                                    className={classes.actionMenu}
                                  >
                                    <MenuItem
                                      className={classes.actionMenuItem}
                                      onClick={() => {
                                        onActionClick(
                                          "viewUpdateFeature",
                                          selectedFeatureData
                                        );
                                      }}
                                    >
                                      View & Update
                                    </MenuItem>
                                    <MenuItem
                                      className={classes.actionMenuItem}
                                      onClick={() => {
                                        onActionClick(
                                          "deleteFeature",
                                          selectedFeatureData
                                        );
                                      }}
                                    >
                                      Delete
                                    </MenuItem>
                                  </Menu>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </>
                      ) : (
                        <Alert severity="info" className="m-[24px]">
                          No Features Found.You can add a new feature for a{" "}
                          {userType} through the actions.
                        </Alert>
                      )}
                    </div>
                    {/* Plans columns */}
                    <div className={classes.planColumnsContainer}>
                      {isValidArray(plans) ? (
                        <>
                          {plans.map((planInfo, index) => (
                            <>
                              {isValidObject(planInfo) ? (
                                <div
                                  className={classes.planColumn}
                                  onMouseEnter={() =>
                                    setHoveredColumnIndex(index)
                                  }
                                  onMouseLeave={() =>
                                    setHoveredColumnIndex(null)
                                  }
                                >
                                  <div
                                    className={clsx([
                                      classes.cell,
                                      classes.headingCell,
                                      classes.planNameCell,
                                      hoveredColumnIndex === index && [
                                        classes.hoveredPlanNameCell,
                                      ],
                                    ])}
                                  >
                                    {planInfo.name}
                                    <IconButton
                                      id={`${planInfo.name}-action-button`}
                                      onClick={(event) => {
                                        handleMenuOpen(event, "plan", planInfo);
                                      }}
                                      className="p-0"
                                    >
                                      <MoreVertIcon fontSize="small" />
                                    </IconButton>
                                    <Menu
                                      id={`${planInfo.name}-menu`}
                                      MenuListProps={{
                                        "aria-labelledby": `${planInfo.name}-menu`,
                                      }}
                                      anchorEl={planMenuAnchor[planInfo._id]}
                                      open={Boolean(
                                        planMenuAnchor[planInfo._id]
                                      )}
                                      onClose={() => {
                                        handleMenuClose();
                                      }}
                                      TransitionComponent={Fade}
                                      className={classes.actionMenu}
                                    >
                                      <MenuItem
                                        className={classes.actionMenuItem}
                                        onClick={() => {
                                          onActionClick(
                                            "viewUpdatePlan",
                                            selectedPlanData
                                          );
                                        }}
                                      >
                                        View & Update
                                      </MenuItem>
                                      <MenuItem
                                        className={classes.actionMenuItem}
                                        onClick={() => {
                                          onActionClick(
                                            "deletePlan",
                                            selectedPlanData
                                          );
                                        }}
                                      >
                                        Delete
                                      </MenuItem>
                                    </Menu>
                                  </div>
                                  {featureIDs.map((featureID) => (
                                    <div className={classes.cell}>
                                      {getPlanFeatureIDs(
                                        planInfo.features
                                      ).includes(featureID) ? (
                                        <span className={classes.checkCell}>
                                          ✔
                                        </span>
                                      ) : (
                                        <span className={classes.crossCell}>
                                          X
                                        </span>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </>
                      ) : (
                        <Alert severity="info" className="m-[24px] w-full">
                          No Plans Found.You can add a new plan for a {userType}{" "}
                          through the actions.
                        </Alert>
                      )}
                    </div>
                  </div>
                ) : (
                  <Alert severity="info" className="my-[24px]">
                    {messages?.NO_DATA_FOUND}{" "}
                  </Alert>
                )}
              </>
              {actionDialogOpen && (
                <ActionDialog
                  actionDialogOpen={actionDialogOpen}
                  setActionDialogOpen={setActionDialogOpen}
                  actionType={actionType}
                  actionEntryData={actionEntryData}
                  otherDetails={{
                    userType,
                    planNames: plans.map((plan) => plan.name),
                    features,
                  }}
                  onActionSuccess={(actionType, successMessage) => {
                    /**  
                     Showing snackbar since this dialog is within the tab component,
                     So the next onActionSuccess refetches information in the parent page and the parent page goes into 
                     loading state while refetching(which closes all child elements i.e, this tab component as well,so the completed UI of 
                     the dialog cant be shown,hence the snackbar is added). 
                    */
                    enqueueSnackbar(successMessage, {
                      variant: "success",
                      autoHideDuration: 3000,
                      action: (key) => (
                        <CloseIcon
                          onClick={() => closeSnackbar(key)}
                          style={{
                            cursor: "pointer",
                            fontSize: "15",
                            marginTop: "-1px",
                          }}
                        />
                      ),
                    });

                    onActionSuccess(actionType);
                    setActionDialogOpen(false);
                  }}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

const SubscriptionPlans = (props) => {
  const classes = useStyles();

  const [plans, setPlans] = useState([]);
  const [features, setFeatures] = useState([]);
  const [featureIDs, setFeatureIDs] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [userType, setUserType] = useState("client"); // client or consultant

  const [actionsMenuAnchor, setActionsMenuAnchor] = useState(null);
  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [actionType, setActionType] = useState(""); // addPlan,addFeature,viewFeatureItems


  const handleMenuClose = () => {
    setActionsMenuAnchor(null);
  };

  const onActionClick = (actionType) => {
    setActionType(actionType);
    setActionDialogOpen(true);
    handleMenuClose(); // closing the menu if any menu item action is  clicked.
  };

  const fetchPlans = () => {
    getPlans(userType)
      .then((response) => {
        let plansData = [];
        if (isValidArray(response?.data)) {
          response.data.forEach((entry) => {
            if (entry?._id && entry.name) {
              plansData.push(entry);
            }
          }); // show entries with a truthy _id and name.
        }
        setPlans(plansData);
        setDataLoading(false);
      })
      .catch((error) => {
        setDataError(true);
        setDataLoading(false);
      });
  };

  const fetchFeatures = () => {
    setDataLoading(true);

    getFeatures(userType)
      .then((response) => {
        let featuresData = [];
        let featureIDs = [];
        if (isValidArray(response?.data)) {
          response.data.forEach((entry) => {
            if (entry?._id && entry.name) {
              featuresData.push(entry);
              featureIDs.push(entry._id);
            }
          }); // filter entries with a truthy _id and name.
        }
        setFeatures(featuresData);
        setFeatureIDs(featuresData.map((featureEntry) => featureEntry._id));

        setDataLoading(false);
      })
      .catch((error) => {
        setDataError(true);
        setDataLoading(false);
      });
  };

  const fetchPlansAndFeatures = (currentUserType) => {
    setDataLoading(true);

    getPlans(currentUserType)
      .then((response) => {
        let plansData = [];
        if (isValidArray(response?.data)) {
          response.data.forEach((entry) => {
            if (entry?._id && entry.name) {
              plansData.push(entry);
            }
          }); // show entries with a truthy _id and name.
        }
        setPlans(plansData);

        return getFeatures(currentUserType);
      })
      .then((response) => {
        let featuresData = [];
        let featureIDs = [];
        if (isValidArray(response?.data)) {
          response.data.forEach((entry) => {
            if (entry?._id && entry.name) {
              featuresData.push(entry);
              featureIDs.push(entry._id);
            }
          }); // filter entries with a truthy _id and name.
        }
        setFeatures(featuresData);
        setFeatureIDs(featureIDs);
        setDataLoading(false);
      })
      .catch((error) => {
        setDataError(true);
        setDataLoading(false);
      });
  };

  const onActionSuccess = (type) =>
    type === "plan" ? fetchPlans() : fetchFeatures(); // refetch plans or features data since there was an update in either of those based on action type.

  const tabsData = [
    {
      label: "Client",
      component: (
        <TabComponent
          userType={userType}
          data={{ features, featureIDs, plans }}
          dataLoading={dataLoading}
          dataError={dataError}
          onActionSuccess={onActionSuccess}
        />
      ),
    },
    {
      label: "Consultant",
      component: (
        <TabComponent
          userType={userType}
          data={{ features, featureIDs, plans }}
          dataLoading={dataLoading}
          dataError={dataError}
          onActionSuccess={onActionSuccess}
        />
      ),
    },
    {
      label: "Agency",
      component: (
        <TabComponent
          userType={userType}
          data={{ features, featureIDs, plans }}
          dataLoading={dataLoading}
          dataError={dataError}
          onActionSuccess={onActionSuccess}
        />
      ),
    },
  ];

useEffect(() => {
    const userTypes = ["client", "consultant", "agency"];
    let currentUserType = userTypes[currentTabIndex] ; 

    setUserType(currentUserType);
    fetchPlansAndFeatures(currentUserType);
}, [currentTabIndex]);


  return (
    <div>
      <Title title="Subscription Plans" />
      <div className="flex justify-end my-[24px] mr-[24px]">
      <CustomButton
        type="button1"
        customClasses={classes.actionsButton}
        onClick={(event) => {
          setActionsMenuAnchor(event.currentTarget); 
        }}
      >
        Actions
        {actionsMenuAnchor ? (
          <KeyboardArrowUpIcon />
        ) : (
          <KeyboardArrowDownRoundedIcon />
        )}
      </CustomButton>
      <Menu
        id={`actions-menu`}
        MenuListProps={{
          "aria-labelledby": `actions-menu`,
        }}
        anchorEl={actionsMenuAnchor}
        open={Boolean(actionsMenuAnchor)}
        onClose={() => {
          handleMenuClose();
        }}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null} 
        className={classes.actionMenu}
      >
        <MenuItem
          className={classes.actionMenuItem}
          onClick={() => {
            onActionClick("addPlan");
          }}
        >
          Add Plan
        </MenuItem>
        <MenuItem
          className={classes.actionMenuItem}
          onClick={() => {
            onActionClick("addFeature");
          }}
        >
          Add Feature
        </MenuItem>
        <MenuItem
          className={classes.actionMenuItem}
          onClick={() => {
            onActionClick("viewFeatureItems");
          }}
        >
          View Feature Items
        </MenuItem>
      </Menu>
    </div>
      <div className="px-[24px]">
        <CustomTabs
          tabsData={tabsData}
          currentTabIndex={currentTabIndex}
          setCurrentTabIndex={setCurrentTabIndex}
          alignTabNameToLeft={true}
        />
      </div>
      {actionDialogOpen && (
        <ActionDialog
          actionDialogOpen={actionDialogOpen}
          setActionDialogOpen={setActionDialogOpen}
          actionType={actionType}
          otherDetails={{
            userType,
            planNames: plans.map((plan) => plan.name).filter(Boolean),
            features,
          }}
          onActionSuccess={onActionSuccess}
        />
      )}
    </div>
  );
};

SubscriptionPlans.propTypes = {};

export default SubscriptionPlans;
