import fetchWrapper from "../../Utils/fetchWrapper";
import configs from "../../Utils/configs";




export const addDocuments = async (bodyRequest) => {
   // // console.log({ bodyRequest });
    return fetchWrapper(`${configs.APIUrl}/client/addDocument`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: bodyRequest,
    });
  };
  
  export const updateDocuments = async (bodyRequest,documentId) => {
    //// console.log({ bodyRequest });
    return fetchWrapper(`${configs.APIUrl}/client/updateDocument/${documentId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: bodyRequest,
    });
  };
  
  export const deleteDocument = async (documentId) => {
    return fetchWrapper(`${configs.APIUrl}/client/deleteDocument/${documentId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
  
  export const getDocuments = async () => {
    return fetchWrapper(`${configs.APIUrl}/client/getDocuments`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
  
  export const getDocumentTypes = async () => {
    return fetchWrapper(`${configs.APIUrl}/misc/documentTypes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
  
  
  