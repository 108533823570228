import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import clsx from "clsx";

import { CircularProgress, Alert } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

import LeftRightSelect from "app/v2/components/filters/LeftRightSelect";
import CustomSelect from "app/v2/components/filters/CustomSelect";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomLabel from "app/v2/components/filters/CustomLabel";
import TinyMceEditor from "../../common/Editor/TinyMceEditor";
import LocationSelector from "app/v2/components/filters/LocationSelector";
import CustomDialog from "app/v2/components/CustomDialog";
import DialogSuccessBody from "app/v2/Pages/common/DialogSuccessBody";
import DialogErrorBody from "app/v2/Pages/common/DialogErrorBody";
import DialogLoadingBody from "app/v2/Pages/common/DialogLoadingBody";
import CustomTooltip from "app/v2/components/CustomTooltip";

import { messages } from "app/v2/Pages/Utils/constants";
import { s3Upload } from "app/v2/services/miscService";
import useFetch from "app/common/utils/useFetch";
import { fetchIndustryThunk } from "app/v2/store/features/misc/miscThunk";
import {
  getEmployerInfoThunk,
  updateClientOrganizationInfoThunk, // updateEmployerInfoThunk,
} from "../../../store/features/employer/profile-settings/profile-settings.thunk";
import { URL_PATTERN } from "app/v2/Pages/Utils/constants";
import { isValidArray, isValidObject } from "app/v2/Pages/Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  headingAndActionsDiv: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "36px",
    // position: "fixed",
    // width: "inherit",
    paddingTop: "48px",
    backgroundColor: theme.palette.common.white,
    zIndex: 100,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  actionButton: {
    padding: "12px 28px",
    fontSize: "16px",
    fontWeight: 600,
    "&:disabled":{
      color:"black !important"
    }
  },
  actionButton2: {
    padding: "12px 28px",
    fontSize: "16px",
    fontWeight: 600,
    
  },
  saveButton: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    "&:disabled": {
      backgroundColor: `${theme.palette.common.black} !important`,
    },
  },
  cSSelectContainer: {
    width: "455px",
    maxHeight: "50px !important",
  }, // cS- company size
  csSelectText: {
    fontSize: "16px",
  },
  fileUploadInput: {
    display: "none",
  },
  uploadButton: {
    columnGap: "8px",
    border: "1px solid #EDEDED",
    padding: "6px 8px",
    color: theme.palette.secondary.light,
    alignSelf: "center",
  },
  iframe: {
    width: "100%",
    height: "223px",
    border: "1px solid #EDEDED",
  },
  secondaryLocationsContainer: {
    marginTop: "24px",
    width: "100%",
    maxHeight: "720px",
    overflowY: "auto",
    paddingRight: "16px",
  },
  addLocationButton: {
    columnGap: "8px",
    border: "1px solid #EDEDED",
    padding: "16px",
    width: "100%",
    fontSize: "14px",
    fontWeight: 600,
  },
  socialLinkRoot: {
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #EDEDED !important",
    },
  },
  socialLinkInput: {
    fontSize: "16px !important",
    height: "24px",
    padding: "12px",
  },
  line: {
    backgroundColor: "#E0E0E0",
    width: "100%",
  },
  orgImage: {
    width: "120px",
    height: "120px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
  infoIcon: {
    width: "20px",
    height: "20px",
    position: "relative",
    left: "4px",
    bottom: "1px",
  },
}));

const organizationDummyData = {
  company: "Apple Inc",
  profilePhoto: "/company_image.png",
  industry: "Finance",
  companySize: 0, // index as per company size array data. (we fetch that data below).
  profileDesc: {
    htmlString: "",
    text: "",
  },
  culture: {
    htmlString: "",
    text: "",
  },
  // discuss with edward about above two(in consultant profile settings we receive only a html string).
  personal_info: {
    country: "Australia",
    state: "New South Wales",
    city: "Sydney",
    timezone: "Australia Central Time (GMT+9:30)",
  },
  companyLocation: [
    {
      country: "India",
      state: "Telangana",
      city: "Hyderabad",
      timezone: "India Standard Time (GMT+5:30)",
    },
    {
      country: "India",
      state: "Karnataka",
      city: "Bangalore",
      timezone: "India Standard Time (GMT+5:30)",
    },
  ],
  socialLinks: {
    website: "www.apple.com",
    facebook: "www.facebook.com",
    linkedIn: "www.linkedin.com",
    twitter: "www.twitter.com",
    instagram: "www.instagram.com",
  },
};

const SaveDialog = ({
  dialogOpen,
  setDialogOpen,
  actionLoading,
  actionCompleted,
  actionError,
  profilePhotoUploadFailed,
}) => {
  const Title = () => {
    return (
      <div className="text-[16px] font-semibold text-center">
        {actionLoading && "Action in progress"}
        {actionError && "Error"}
        {actionCompleted && "Personal Details Saved"}
      </div>
    );
  };

  const DialogBody = () => {
    if (actionLoading) return <DialogLoadingBody />;
    else if (actionCompleted)
      return (
        <DialogSuccessBody
          content={{
            title: "Your details have been saved successfully.",
            desc: (
              <b>
                {`${
                  profilePhotoUploadFailed
                    ? `However, there was an issue with uploading your display picture.Please try again later.`
                    : ""
                }
                `}
              </b>
            ),
          }}
        />
      );
    else if (actionError)
      return (
        <DialogErrorBody
          content={{ title: messages.GENERIC_ERROR_MESSAGE, desc: "" }}
        />
      );
    else return <></>;
  };

  return (
    <CustomDialog
      open={dialogOpen}
      setOpen={setDialogOpen}
      noCloseAction={actionLoading}
      title={<Title />}
      content={<DialogBody />}
    />
  );
};

const OrganizationDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [get] = useFetch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { industryData } = useSelector((state) => state.misc);
  const personalDataBuf = useSelector(
    (state) => state.clientProfileInfo.clientInfo.employer
  );

  const companySizeData = [
    "15-30 Employees",
    "30-50 Employees",
    "50-100 Employees",
  ]; // Need to get api for this.
  const [industries, setIndustries] = useState([]);
  const [clientID, setClientID] = useState();
  const [initialCompanyData, setInitialCompanyData] = useState(
    !personalDataBuf?.length
      ? {
          company: "",
          profilePhoto: "",
          industry: "",
          companySize: 0, // index as per company size array data. (we fetch that data below).
          profileDesc: { htmlString: "", text: "" },
          culture: { htmlString: "", text: "" },
          personal_info: {
            country: "",
            city: "",
            state: "",
            timezone: "",
          },
          companyLocation: [], // array of objects- object structure is similar to primary location above.
          socialLinks: {
            website: "",
            facebook: "",
            linkedIn: "",
            twitter: "",
            instagram: "",
          },
        }
      : personalDataBuf
  );
  const [companyData, setCompanyData] = useState(initialCompanyData);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [resetTextfield, setResetTextField] = useState(false);
  const [allEntriesValid, setAllEntriesValid] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState();
  const [saveActionLoading, setSaveActionLoading] = useState(false);
  const [saveActionCompleted, setSaveActionCompleted] = useState(false);
  const [saveActionError, setSaveActionError] = useState(false);
  const [profilePhotoUploadFailed, setProfilePhotoUploadFailed] =
    useState(false);
  const uploadRef = React.createRef();
  const role_Id = localStorage.getItem("roleId");
  const roleName=localStorage.getItem("roleName")


  const [isAdmin, setIsAdmin] = useState(
    roleName?.includes("Admin")
  );
  const toggleEditMode = () => {
    setIsEditMode((prevState) => !prevState);
  };

  const onFileUploadToS3 = async (file) => {
    let s3UploadedFileDetails;
    let errorInUpload = false;
    const formData = new FormData();
    formData.append("file", file);
    try {
      s3UploadedFileDetails = await get(s3Upload(formData));
    } catch (err) {
      errorInUpload = true;
    }
    return { uploadedUrl: s3UploadedFileDetails?.[0]?.location, errorInUpload };
  };

  const onCancelClick = () => {
    const deepCopyICData = JSON.parse(JSON.stringify(initialCompanyData)); // IC- initial company.
    setCompanyData(deepCopyICData); // setting back to previous state.
    setResetTextField((prevState) => !prevState);
    toggleEditMode();
  };

  const onSaveClick = async () => {
    let profilePhotoUrlInfo, profilePhotoUrl;
    const updatedCompanyData = { ...companyData };

    // the below element can be the url or an object(if upload of any of those were attempted, structure- {file,localFileUrl,prevFileUrl}).
    const profilePhotoUrlOrDetails = companyData?.profilePhoto;
    const profilePhotoFile = profilePhotoUrlOrDetails?.file;

    setSaveDialogOpen(true);
    setSaveActionLoading(true);

    // If at all upload was attempted for them we try to access the file part of the object(done above) and upload it to s3 and get the updated url of its location in s3 on successful upload.If s3 upload fails we we show the message in snackbar and update the prev file itself in the profileData.
    //  Else i.e, upload was not attempted, file value wont exist and it will be the url instead(prev/current url).

    if (profilePhotoFile) {
      profilePhotoUrlInfo = await onFileUploadToS3(profilePhotoFile);
      profilePhotoUrl = profilePhotoUrlInfo?.errorInUpload
        ? profilePhotoUrlOrDetails?.prevFileUrl
        : profilePhotoUrlInfo?.uploadedUrl;
    } else profilePhotoUrl = profilePhotoUrlOrDetails;

    updatedCompanyData.profilePhoto = profilePhotoUrl;
    const updatedCompanyDataCopy = JSON.parse(
      JSON.stringify(updatedCompanyData)
    );

    // passing only htmlString and no text value before passing to the api.
    // updatedCompanyData.profileDesc =
    //   updatedCompanyData?.profileDesc?.htmlString;
    // updatedCompanyData.culture = updatedCompanyData?.culture?.htmlString;

    // // console.log({ updatedCompanyData });
    // setTimeout(() => {
    //   /** Confirm case */
    //   setSaveActionLoading(false);
    //   setSaveActionCompleted(true);
    //   setIsEditMode(false);
    //  setCompanyData(updatedCompanyData);
    //  setInitialCompanyData(updatedCompanyDataCopy);
    //   /** Error case */
    //   // setSaveActionLoading(false);
    //   // setSaveActionError(true);
    //   // setSaveActionCompleted(false);
    // }, 2000);

    if (profilePhotoUrlInfo?.errorInUpload) setProfilePhotoUploadFailed(true);

    dispatch(updateClientOrganizationInfoThunk(updatedCompanyData))
      .unwrap()
      .then((res) => {
        if (res.message) {
          setIsEditMode(false);
          setSaveActionLoading(false);
          setSaveActionCompleted(true);
          setCompanyData(updatedCompanyData);
          setInitialCompanyData(updatedCompanyDataCopy);
        }
      })
      .catch((err) => {
        setSaveActionLoading(false);
        setSaveActionError(true);
        setSaveActionCompleted(false);
      });
  };

  const onCompanyDataChange = (id, value) => {
    setCompanyData((prevCompanyData) => ({ ...prevCompanyData, [id]: value }));
  }; // used for updates in name,profile photo,industry,company size,profile description,culture description elements. Other elements have their own function handlers.

  const onPrimaryLocationChange = (id, value) => {
    setCompanyData((prevCompanyData) => {
      let updatedData = { ...prevCompanyData };
      updatedData.personal_info = {
        ...updatedData.personal_info,
        [id]: value,
      };
      return updatedData;
    });
  };

  const onSecondaryLocationChange = (id, value, locationIndex) => {
    setCompanyData((prevCompanyData) => {
      let updatedData = { ...prevCompanyData };
      let updatedSecondaryLocationData = [...updatedData?.companyLocation];
      updatedSecondaryLocationData[locationIndex] = {
        ...updatedSecondaryLocationData[locationIndex],
        [id]: value,
      };
      updatedData.companyLocation = updatedSecondaryLocationData;
      return updatedData;
    });
  };

  const onDeleteSecondaryLocation = (locationIndex) => {
    setCompanyData((prevCompanyData) => {
      let updatedData = { ...prevCompanyData };
      let updatedSecondaryLocationData = [...updatedData?.companyLocation];
      updatedSecondaryLocationData.splice(locationIndex, 1);
      updatedData.companyLocation = updatedSecondaryLocationData;
      return updatedData;
    });
  };

  const onProfilePhotoUpload = (event) => {
    const file = event?.target?.files?.[0];

    if (file) {
      const localFileUrl = URL.createObjectURL(file);
      const higherFileSize = file.size > 1048576 * 1; // 1 mb
      const isAnImage = file.type.startsWith("image/");
      //// console.log({ higherFileSize, isAnImage });
      // First we check if the uploaded element is actually an image and does not exceed the expected file size.
      if (higherFileSize || !isAnImage) {
        // enqueueSnackbar(
        // higherFileSize
        //   ? messages.EXPECTED_IMG_FILE_SIZE_WARNING
        //   : messages.UPLOAD_VALID_IMG_WARNING,
        //   {
        //     variant: "error",
        //   }
        // );

        const key = enqueueSnackbar(
          higherFileSize
            ? messages.EXPECTED_IMG_FILE_SIZE_WARNING
            : messages.UPLOAD_VALID_IMG_WARNING,
          {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
        return;
      }

      // Next we check if the image meets the expected dimensions,the logic below is specifically for images hence we had to add the isAnImage check above first.
      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = function () {
          const width = this.width;
          const height = this.height;
          //// console.log({ width, height });

          // Check if the image meets your criteria
          if (width >= 200 || height >= 200) {
            onCompanyDataChange("profilePhoto", {
              file,
              localFileUrl,
              prevFileUrl: companyData?.profilePhoto,
            });
          } else {
            // enqueueSnackbar(messages.IMG_UPLOAD_INCORRECT_RESOLUTION, {
            //   variant: "error",
            // });
            const key = enqueueSnackbar(
              messages.IMG_UPLOAD_INCORRECT_RESOLUTION,
              {
                variant: "error",
                autoHideDuration: 3000,
                action: (key) => (
                  <CloseIcon
                    onClick={() => closeSnackbar(key)}
                    style={{
                      cursor: "pointer",
                      fontSize: "15",
                      marginTop: "-1px",
                    }}
                  />
                ),
              }
            );
          }
        };
        // The onload event handler for the img element is not explicitly called. It will be automatically triggered when the image has finished loading(i.e, when the src attribute is set).
      };
      reader.readAsDataURL(file);
      // This method instructs the FileReader to read the contents of the specified File object (file) as a data URL.
      // When the reading operation is complete, the onload event handler (defined as reader.onload = function(e) { ... }) will be triggered, allowing you to access and work with the data URL.
    }
  };

  const onAddLocationClick = () => {
    setCompanyData((prevCompanyData) => {
      let updatedData = { ...prevCompanyData };
      let updatedSecondaryLocationData = [
        ...(updatedData?.companyLocation ?? []),
      ];

      updatedSecondaryLocationData.push({
        country: "",
        city: "",
        timezone: "",
      });
      updatedData.companyLocation = updatedSecondaryLocationData;
      return updatedData;
    });
  };

  const onSocialLinkChange = (id, value) => {
    setCompanyData((prevCompanyData) => {
      let updatedData = { ...prevCompanyData };
      updatedData.socialLinks = { ...updatedData?.socialLinks, [id]: value };

      return updatedData;
    });
  };

  const areAllSecondaryLocationsFilled = (secondaryLocationData) => {
    const secondaryLocationEntryCount = secondaryLocationData?.length;

    let secondaryLocationFilled;
    let country, state, city, timezone;
    for (let index = 0; index < secondaryLocationEntryCount; index++) {
      country = secondaryLocationData[index]?.country;
      state = secondaryLocationData[index]?.state;
      city = secondaryLocationData[index]?.city;
      timezone = secondaryLocationData[index]?.timezone;
      secondaryLocationFilled =
        Boolean(country) &&
        Boolean(state) &&
        Boolean(timezone) &&
        city?.length > 0;
      if (!secondaryLocationFilled) break;
    }
    return secondaryLocationFilled;
  };

  const validateEntries = () => {
    // const primaryLocationData = companyData?.personal_info;
    const secondaryLocationData = companyData?.companyLocation;
    const socialLinksData = companyData?.socialLinks;

    const companyNameEntered = companyData?.company?.length > 0;
    const profilePhotoSelected =
      Boolean(companyData?.profilePhoto) ||
      isValidObject(companyData?.profilePhoto); // second condition is when a new upload is attempted from the user's end(since profilePhoto is an object-{file,localFileUrl,prevFileUrl} in this case).
    const industrySelected = companyData?.industry?.length >= 0;
    const sizeSelected =
      companyData?.companySize >= 0 &&
      companyData?.companySize < companySizeData?.length;
    // const isProfileDescValid = companyData?.profileDesc?.text?.length > 100;
    const isProfileDescValid = companyData?.companyDesc?.text?.length > 100;
    const isCultureDescValid = companyData?.culture?.text?.length > 100;
    // const primaryLocationDetailsEntered =
    //   Boolean(primaryLocationData?.country) &&
    //   Boolean(primaryLocationData?.state) &&
    //   Boolean(primaryLocationData?.timezone) &&
    //   primaryLocationData?.city?.length > 0;

    const secondaryLocationsValidation = isValidArray(secondaryLocationData)
      ? areAllSecondaryLocationsFilled(secondaryLocationData)
      : true; // if any secondary location was added all its elements needs to be filled else no validation required and we consider it as valid.

    const iswebsiteLinkValid = URL_PATTERN.test(socialLinksData?.website);
    const isfacebookLinkValid = URL_PATTERN.test(socialLinksData?.facebook);
    const islinkedinLinkValid = URL_PATTERN.test(socialLinksData?.linkedIn);
    const istwitterLinkValid = URL_PATTERN.test(socialLinksData?.twitter);
    const isinstagramLinkValid = URL_PATTERN.test(socialLinksData?.instagram);

    //     const iswebsiteLinkValid = WEBSITE_PATTERN.test(socialLinksData?.website);
    //     const isfacebookLinkValid = WEBSITE_PATTERN.test(socialLinksData?.facebook);
    //     const islinkedinLinkValid = WEBSITE_PATTERN.test(socialLinksData?.linkedIn);
    //     const istwitterLinkValid = WEBSITE_PATTERN.test(socialLinksData?.twitter);
    //     const isinstagramLinkValid = WEBSITE_PATTERN.test(
    //       socialLinksData?.instagram
    //     );

    setAllEntriesValid(
      companyNameEntered &&
        profilePhotoSelected &&
        industrySelected &&
        // sizeSelected &&
        isProfileDescValid &&
        isCultureDescValid &&
        // primaryLocationDetailsEntered &&
        // secondaryLocationsValidation &&
        iswebsiteLinkValid &&
        isfacebookLinkValid &&
        islinkedinLinkValid &&
        istwitterLinkValid &&
        isinstagramLinkValid
    );
  };

  useEffect(() => {
    if (isValidArray(industryData)) {
      let industryNames = [];
      industryData?.forEach((industryDetail) => {
        if (industryDetail?.name) industryNames.push(industryDetail?.name);
      });
      setIndustries(industryNames);
    }
  }, [industryData]);

  useEffect(() => {
    if (!industryData?.length) dispatch(fetchIndustryThunk());

    // const organizationDummyDataCopy=JSON.parse(
    //   JSON.stringify(organizationDummyData)
    // );
    // setDataLoading(false);
    // setInitialCompanyData(organizationDummyData);
    // setCompanyData(organizationDummyDataCopy);

    if (!personalDataBuf?.length) {
      dispatch(getEmployerInfoThunk())
        .unwrap()
        .then((res) => {
          //// console.log({ res });

          // ##################
          // "socialLinks": {
          //   "website": "www.google.com"
          // },
          // "companyDesc": "",
          // "orgType": "client",
          // "profilePhoto": "",
          // "isCompanyVerified": false,
          // ##################

          if (res?.data.clientDetail) {
            // let receivedCompanyData = { ...res.employer };
            let receivedCompanyData = { ...res.data.clientDetail.orgDetails };
            delete receivedCompanyData.isCompanyVerified;
            delete receivedCompanyData.orgType;
            delete receivedCompanyData.createdAt;
            delete receivedCompanyData.updatedAt;
            // delete receivedCompanyData._id;
            // Setting defaults
            receivedCompanyData.company = receivedCompanyData?.company ?? "";
            receivedCompanyData.profilePhoto =
              receivedCompanyData?.profilePhoto ?? "";
            receivedCompanyData.industry = receivedCompanyData?.industry ?? "";
            receivedCompanyData.companySize =
              receivedCompanyData?.companySize ?? 0;
            // receivedCompanyData.profileDesc = {
            //   htmlString: receivedCompanyData?.profileDesc ?? "",
            // };
            // receivedCompanyData.culture = {
            //   htmlString: receivedCompanyData?.culture ?? "",
            // };
            // receivedCompanyData.profileDesc = {
            //   htmlString: receivedCompanyData?.profileDesc?.htmlString ?? "",
            //   text: receivedCompanyData?.profileDesc?.text ?? "",
            // };
            receivedCompanyData.companyDesc = {
              htmlString: receivedCompanyData?.companyDesc?.htmlString ?? "",
              text: receivedCompanyData?.companyDesc?.text ?? "",
            };
            receivedCompanyData.culture = {
              htmlString: receivedCompanyData?.culture?.htmlString ?? "",
              text: receivedCompanyData?.culture?.text ?? "",
            };
            // receivedCompanyData.personal_info = {
            //   ...receivedCompanyData?.personal_info,
            // };
            receivedCompanyData.companyLocation =
              receivedCompanyData?.companyLocation ?? [];
            receivedCompanyData.socialLinks =
              receivedCompanyData?.socialLinks ?? {};
            const receivedCompanyDataCopy = JSON.parse(
              JSON.stringify(receivedCompanyData)
            );
            setInitialCompanyData(receivedCompanyData);
            setCompanyData(receivedCompanyDataCopy);
            setClientID(res?.data?.clientDetail?._id);
          } else setDataError(true);
          setDataLoading(false);
        })
        .catch((err) => {
          setDataLoading(false);
          setDataError(true);
        });
    }
  }, [dispatch]);

  useEffect(() => {
    //// console.log({ companyData });
    validateEntries();
  }, [companyData]);

  useEffect(() => {
    if (!saveDialogOpen) {
      // Setting back to default states- used when the dialog is closed.
      setProfilePhotoUploadFailed(false);
      setSaveActionLoading(false);
      setSaveActionCompleted(false);
      setSaveActionError(false);
    }
  }, [saveDialogOpen]);

  return (
    <>
      {dataLoading ? (
        <CircularProgress className="mt-[48px]" />
      ) : (
        <>
          {dataError ? (
            <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
          ) : (
            <>
              <div className={classes.headingAndActionsDiv}>
                <div className="text-[32px] font-semibold">
                  Organization Details
                </div>
                <div className="flex gap-x-[12px]">
                  {isEditMode ? (
                    <>
                      <button
                        onClick={onCancelClick}
                        className={clsx([
                          classes.button,
                          classes.actionButton,
                          "bg-[#EDEDED]",
                        ])}
                      >
                        Cancel
                      </button>
                      <CustomTooltip
                        arrow
                        title={
                          !allEntriesValid && "Please enter all the details."
                        }
                      >
                        <button
                          onClick={onSaveClick}
                          className={clsx([
                            classes.button,
                            classes.actionButton2,
                            classes.saveButton,
                          ])}
                          disabled={!allEntriesValid}
                        >
                          <div>Save</div>
                        </button>
                      </CustomTooltip>
                    </>
                  ) : (
                    <>
                      <CustomTooltip
                        title={isAdmin ? "": "Access Restricted" }
                        arrow
                      >
                        <div>
                          <button
                            onClick={toggleEditMode}
                            className={clsx([
                              classes.button,
                              classes.actionButton,
                              "bg-[#EDEDED]",
                            ])}
                            disabled={!isAdmin}
                          >
                            Edit
                          </button>
                        </div>
                      </CustomTooltip>
                      <button
                        onClick={() => {
                          window.open(`/client-detail/${clientID}`, "_blank");
                        }}
                        className={clsx([
                          classes.button,
                          classes.actionButton,
                          "bg-[#EDEDED]",
                        ])}
                      >
                        View Public Profile
                      </button>
                    </>
                  )}
                </div>
              </div>

              <div
                className="flex flex-col gap-[24px] px-[16px] pt-[15px]"
                key={isValidObject(companyData)}
              >
                <div className="text-[24px] font-semibold">Profile Display</div>
                <CustomTextfield
                  id="company"
                  label="Company name"
                  placeholder="Company ABC"
                  value={companyData?.company}
                  onChange={onCompanyDataChange}
                  disabled={!isEditMode}
                  reset={resetTextfield}
                />
                <div className="flex flex-col gap-[12px]">
                  <div>
                    <CustomLabel label="Company profile photo" />
                    {isEditMode ? (
                      <CustomTooltip
                        title={
                          <>
                            <div>1. {messages.IMG_UPLOAD_RESOLUTION_INFO}</div>
                            <div>2. {messages.IMG_UPLOAD_EXTENSION_INFO}</div>
                          </>
                        }
                        arrow
                        placement="top"
                      >
                        <InfoIcon className={classes.infoIcon} />
                      </CustomTooltip>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="flex gap-x-[36px]">
                    <img
                      className={classes.orgImage}
                      alt={`org_${companyData?.company}_image`}
                      src={
                        companyData?.profilePhoto?.localFileUrl
                          ? companyData?.profilePhoto?.localFileUrl
                          : companyData?.profilePhoto
                          ? companyData?.profilePhoto
                          : "/org_placeholder.svg"
                      }
                    />
                    {isEditMode && (
                      <>
                        <input
                          type="file"
                          accept="image/*"
                          className={classes.fileUploadInput}
                          onChange={onProfilePhotoUpload}
                          ref={uploadRef}
                        />
                        <button
                          className={clsx([
                            classes.button,
                            classes.uploadButton,
                          ])}
                          onClick={() => uploadRef.current.click()}
                        >
                          <div>Upload new</div>
                          <FileUploadOutlinedIcon fontSize="small" />
                        </button>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex gap-[33px] w-full">
                  <div className="flex-1">
                    <CustomSelect
                      id="industry"
                      data={industries}
                      label="Company industry"
                      placeholder={"Select"}
                      disabled={!isEditMode}
                      onChange={onCompanyDataChange}
                      defaultSelection={companyData?.industry}
                    />
                  </div>
                  <div className="flex-1">
                    <LeftRightSelect
                      id="companySize"
                      label="company size"
                      data={companySizeData}
                      selectedIndex={companyData?.companySize}
                      onChange={onCompanyDataChange}
                      disabled={!isEditMode}
                      customClasses={{
                        container: classes.cSSelectContainer,
                        text: classes.csSelectText,
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-[12px] w-full">
                  <CustomLabel label="company profile" />
                  {isEditMode ? (
                    <TinyMceEditor
                      id="companyDesc"
                      data={companyData?.companyDesc?.htmlString}
                      setData={onCompanyDataChange}
                      placeholder="Enter company profile"
                      requiredCharCount={100}
                      insufficientCharCountMessage="Company profile should be atleast 100 characters."
                    />
                  ) : (
                    <iframe
                      srcDoc={companyData?.companyDesc?.htmlString}
                      className={classes.iframe}
                    ></iframe>
                  )}
                </div>
                <div className="flex flex-col gap-[12px] w-full">
                  <CustomLabel label="company culture" />
                  {isEditMode ? (
                    <TinyMceEditor
                      id="culture"
                      data={companyData?.culture?.htmlString}
                      setData={onCompanyDataChange}
                      placeholder="Enter company culture"
                      requiredCharCount={100}
                      insufficientCharCountMessage="Company culture should be atleast 100 characters."
                    />
                  ) : (
                    <iframe
                      srcDoc={companyData?.culture?.htmlString}
                      className={classes.iframe}
                    ></iframe>
                  )}
                </div>
                <hr className={classes.line} />

                <div className="text-[24px] font-semibold">Locations</div>
                {/* <div className="flex flex-col gap-[12px] w-full">
                    <CustomLabel label="Primary company location" />
                    <div className="w-full">
                      <LocationSelector
                        defaultCountry={companyData?.personal_info?.country}
                        defaultState={companyData?.personal_info?.state}
                        defaultCity={companyData?.personal_info?.city}
                        defaultTimezone={companyData?.personal_info?.timezone}
                        disabled={!isEditMode}
                        onChange={onPrimaryLocationChange}
                        reset={resetTextfield}
                      />
                    </div>
                  </div> */}
                <div className={classes.secondaryLocationsContainer}>
                  {companyData?.companyLocation?.map((locationInfo, index) => (
                    <div className="self-stretch flex flex-col items-start justify-start gap-[12px] mb-6">
                      <div className=" w-full flex justify-between items-center">
                        <CustomLabel
                          // label={`Secondary company location - ${
                          label={`Company location - ${index + 1}`}
                        />
                        <span className="leading-[120%] uppercase font-semibold"></span>
                        {isEditMode && (
                          <DeleteOutlineIcon
                            onClick={() => {
                              onDeleteSecondaryLocation(index);
                            }}
                            className="cursor-pointer"
                          />
                        )}
                      </div>

                      <div className="w-full">
                        <LocationSelector
                          index={index}
                          defaultCountry={locationInfo?.country}
                          defaultState={locationInfo?.state}
                          defaultCity={locationInfo?.city}
                          defaultTimezone={locationInfo?.timezone}
                          disabled={!isEditMode}
                          onChange={onSecondaryLocationChange}
                          reset={resetTextfield}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                {isEditMode && (
                  <button
                    className={clsx([
                      classes.button,
                      classes.addLocationButton,
                    ])}
                    onClick={onAddLocationClick}
                  >
                    <div>Add Location </div>
                    <AddIcon fontSize="small" className="w-[15px] h-[15px]" />
                  </button>
                )}

                <hr className={classes.line} />
                <div className="text-[24px] font-semibold">Social Proof</div>

                <CustomTextfield
                  label="company website"
                  id="website"
                  type="url"
                  startAdornment={<img src="/link.svg" alt="social-link" />}
                  value={companyData?.socialLinks?.website}
                  onChange={onSocialLinkChange}
                  disabled={!isEditMode}
                  customClasses={{
                    root: classes.socialLinkRoot,
                    input: classes.socialLinkInput,
                  }}
                  reset={resetTextfield}
                />

                <div className="flex gap-[33px] w-full">
                  <CustomTextfield
                    label="facebook profile link"
                    id="facebook"
                    type="url"
                    startAdornment={<img src="/link.svg" alt="social-link" />}
                    value={companyData?.socialLinks?.facebook}
                    onChange={onSocialLinkChange}
                    disabled={!isEditMode}
                    customClasses={{
                      root: classes.socialLinkRoot,
                      input: classes.socialLinkInput,
                    }}
                    reset={resetTextfield}
                  />
                  <CustomTextfield
                    label="LinkedIn profile link"
                    id="linkedIn"
                    type="url"
                    startAdornment={<img src="/link.svg" alt="social-link" />}
                    value={companyData?.socialLinks?.linkedIn}
                    onChange={onSocialLinkChange}
                    disabled={!isEditMode}
                    customClasses={{
                      root: classes.socialLinkRoot,
                      input: classes.socialLinkInput,
                    }}
                    reset={resetTextfield}
                  />
                </div>
                <div className="flex gap-[33px] w-full">
                  <CustomTextfield
                    label="twitter profile link"
                    id="twitter"
                    type="url"
                    startAdornment={<img src="/link.svg" alt="social-link" />}
                    value={companyData?.socialLinks?.twitter}
                    onChange={onSocialLinkChange}
                    disabled={!isEditMode}
                    customClasses={{
                      root: classes.socialLinkRoot,
                      input: classes.socialLinkInput,
                    }}
                    reset={resetTextfield}
                  />
                  <CustomTextfield
                    label="instagram profile link"
                    id="instagram"
                    type="url"
                    startAdornment={<img src="/link.svg" alt="social-link" />}
                    value={companyData?.socialLinks?.instagram}
                    onChange={onSocialLinkChange}
                    disabled={!isEditMode}
                    customClasses={{
                      root: classes.socialLinkRoot,
                      input: classes.socialLinkInput,
                    }}
                    reset={resetTextfield}
                  />
                </div>
              </div>

              <SaveDialog
                dialogOpen={saveDialogOpen}
                setDialogOpen={setSaveDialogOpen}
                actionLoading={saveActionLoading}
                actionCompleted={saveActionCompleted}
                actionError={saveActionError}
                profilePhotoUploadFailed={profilePhotoUploadFailed}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default OrganizationDetails;
