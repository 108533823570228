import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import JobList from "../common/Cards/job/List";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomRadioGroup from "app/v2/components/CustomRadioGroup";
import CustomCheckboxGroup from "app/v2/components/CustomCheckboxGroup";
import SalaryFilter from "app/v2/components/filters/SalaryFilter";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { messages } from "app/v2/Pages/Utils/constants";
import Title from "../../common/Title";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { useLocation } from "react-router-dom";
import ContentLoader from "react-content-loader";
import {
  getAlljobs,
  getAppliedjobs,
  getMySavedjobs,
  getInvitedjobs,
} from "app/v2/services/consultant/myJobs.service";
import { useSnackbar } from "notistack";
import { isValidArray, isValidObject } from "../../Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  headerDiv: {
    position: "fixed",
    width: "100%",
    borderBottom: "1px solid #EDEDED",
    backgroundColor: "white",
    zIndex: 1,
  },
  tabs: {
    marginTop: "38px",
    marginLeft: "24px",
    display: "flex",
    columnGap: "60px",
    [theme.breakpoints.down("sm")]: {
      columnGap: "22px",
    }
  },
  tab: {
    fontWeight: 600,
    fontSize: "18px",
    paddingBottom: "24px",
    color: "#ABABAB",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
    }
  },
  selectedTabName: {
    color: theme.palette.common.black,
    borderBottom: `1px solid ${theme.palette.common.black}`,
  },
  jobFeedBodyContainer: {
    paddingTop: "171px",
  },
  leftView: {
    padding: "24px 48px",
    width: "291px",
    boxSizing: "content-box",
    overflow: "auto",
    // height: "615px",
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  rightView: {
    borderLeft: "1px solid #EDEDED",
    // padding: "48px",
    padding: "24px", // setting it as 24 instead of 48 since cards already have 24px padding.- temporary change.
    // width: "956px",
    width: "1004px", // updated width and breakpoints below wrt padding above.
    boxSizing: "content-box",
    // [theme.breakpoints.down("1440")]: {
    //   width: "900px",
    // },
    // [theme.breakpoints.down("1385")]: {
    //   width: "800px",
    // },
    // [theme.breakpoints.down("1285")]: {
    //   width: "700px",
    // },
    // [theme.breakpoints.down("1185")]: {
    //   width: "600px",
    // },

    
    [theme.breakpoints.down("1440")]: {
      width: "980px",
    },
    [theme.breakpoints.down("1385")]: {
      width: "850px",
    },
    [theme.breakpoints.down("1285")]: {
      width: "750px",
    },
    [theme.breakpoints.down("1185")]: {
      width: "700px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    }
   
    
  },
  searchTextDiv: {
    marginBottom: "26px",
  },
  seacriconstyle: {
    cursor: "pointer",
  },
  sortOrFilterText: {
    fontWeight: 600,
    fontSize: "16px",
  },
  sortRadioGroupDiv: {
    margin: "12px 24px",
  },
  sortTextAndIconDiv: {
    display: "flex",
    alignItems: "center",
    columnGap: "8px",
  },
  accordionDiv: {
    margin: "14px 0",
  },
  accordion: {
    boxShadow: "none",
    borderBottom: "1px solid #EDEDED",
  },
  accordionTitle: {
    fontWeight: 600,
    fontSize: "16px",
  },
  workTypeCheckboxDiv: {
    margin: "12px 24px",
  },
  moreFilterDiv: {
    border: "1px solid #EDEDED",
    padding: "7.545px 13.204px",
    display: "flex",
    justifyContent: "space-between",
    columnGap: "10px",
    cursor: "pointer",
  },
  moreFilterText: {
    fontWeight: 600,
    fontSize: "14px",
  },
  filterImgAndCountDiv: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
  },
  moreFilterCount: {
    fontWeight: 600,
    fontSize: "10px",
    color: theme.palette.primary.main,
    position: "relative",
    top: "1px",
  },
  loadMoreDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "76px",
    rowGap: "12px",
  },
  moreIcon: {
    color: theme.palette.secondary.light,
    padding: "8px",
    border: "1px solid #EDEDED",
    borderRadius: "4px",
  },
  loadMoreText: {
    fontSize: "14px",
  },
}));

const dummyJobData = [
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 1",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    status: "viewed",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 2",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    status: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 3",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    status: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 4",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    status: "notSelected",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 5",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    status: "testSent",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 6",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    status: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 7",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    status: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 8",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    status: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 9",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    status: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 10",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    status: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 11",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    status: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 12",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    status: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 13",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    status: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 14",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    status: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 15",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    status: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 16",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    status: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 17",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    status: "invited",
  },
];

const MyJobs = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const locationState = location?.state;
  const { enqueueSnackbar } = useSnackbar();

  const sortData = [
    {
      label: "Newest",
      value: "Newest",
    },
    // {
    //   label: "Relevance",
    //   value: "Relevance",
    // },
    {
      label: "Alphabetical (A-Z)",
      value: "Alphabetical (A-Z)",
    },
    {
      label: "Alphabetical (Z-A)",
      value: "Alphabetical (Z-A)",
    },
    {
      label: "Oldest",
      value: "Oldest",
    },
    // {
    //   label: (
    //     <div className={classes.sortTextAndIconDiv}>
    //       # of Open Positions <img src="/iconlightarrow-top.svg" />
    //     </div>
    //   ),
    //   value: "# of Open Positions",
    // },
    // {
    //   label: (
    //     <div className={classes.sortTextAndIconDiv}>
    //       # of Applicants <img src="/iconlightarrow-top.svg" />
    //     </div>
    //   ),
    //   value: "# of Applicants",
    // },
  ];

  const tabs = ["All Jobs", "Applied", "Saved", "Invited"];
  const receivedTabName = locationState?.jobTab;

  const [jobData, setJobData] = useState([]);
  const [filteredJobData, setFilteredJobData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [selectedTabName, setSelectedTabName] = useState(
    tabs.includes(receivedTabName) ? receivedTabName : tabs[0]
  );
  const [searchText, setSearchText] = useState("");
  const [sortSelectionValue, setSortSelectionValue] = useState(
    sortData[0]?.value
  );
  const [workTypeData, setWorkTypeData] = useState([]);
  const [salaryDetails, setSalaryDetails] = useState({});
  const [jobCountries, setJobCountries] = useState([]);

  const filterBySortValue = (jobDataReceived) => {
    switch (sortSelectionValue) {
      case "Newest":
        // Sort the jobData based on the "createdAt" date in descending order
        jobDataReceived.sort((jobEntry1, jobEntry2) => {
          const date1 = jobEntry1.createdAt
            ? new Date(jobEntry1.createdAt)
            : new Date(0); // Treats undefined createdAt dates as oldest possible date
          const date2 = jobEntry2.createdAt
            ? new Date(jobEntry2.createdAt)
            : new Date(0);
          return date2 - date1;
        });
        break;
      // case "Relevance":
      //   break;
      case "Alphabetical (A-Z)":
        jobDataReceived.sort((jobEntry1, jobEntry2) => {
          const title1 = jobEntry1?.jobTitle || "ZZZZZ"; // Treats undefined jobTitle as greater than any other jobTitle and shows that entry at the end of the list.
          const title2 = jobEntry2?.jobTitle || "ZZZZZ";

          return title1.localeCompare(title2);
        });
        break;
      case "Alphabetical (Z-A)":
        jobDataReceived.sort((jobEntry1, jobEntry2) => {
          const title1 = jobEntry1?.jobTitle || "ZZZZZ"; // Treats undefined jobTitle as greater than any other jobTitle and shows that entry at the end of the list.
          const title2 = jobEntry2?.jobTitle || "ZZZZZ";

          return title2.localeCompare(title1);
        });
        break;
      case "Oldest":
        // Sort the jobDataReceived based on the "createdAt" date in descending order
        jobDataReceived.sort((jobEntry1, jobEntry2) => {
          const date1 = jobEntry1.createdAt
            ? new Date(jobEntry1.createdAt)
            : new Date(0); // Treats undefined createdAt dates as oldest possible date
          const date2 = jobEntry2.createdAt
            ? new Date(jobEntry2.createdAt)
            : new Date(0);
          return date1 - date2;
        });
        break;
      default:
        break;
    }

    return jobDataReceived;
  };

  const filterByWorkType = (jobDataReceived) => {
    const selectedWorkTypeValues = workTypeData
      .filter((workTypeEntry) => workTypeEntry.checked === true)
      .map((workTypeEntry) => workTypeEntry.value);

    if (isValidArray(selectedWorkTypeValues)) {
      jobDataReceived = jobDataReceived.filter((jobEntry) =>
        selectedWorkTypeValues.includes(jobEntry?.workType)
      );
    }

    return jobDataReceived;
  };

  const filterByCountry = (jobDataReceived) => {
    const selectedCountryValues = jobCountries
      .filter((country) => country.checked === true)
      .map((country) => country.value);

    if (isValidArray(selectedCountryValues)) {
      jobDataReceived = jobDataReceived.filter((jobEntry) =>
        selectedCountryValues.includes(jobEntry?.country)
      );
    }

    return jobDataReceived;
  };

  const filterBySalary = (jobDataReceived) => {
    const filterSalaryType = salaryDetails?.type?.toLowerCase();
    const filterRange = salaryDetails?.range;
    const filterMin = filterRange?.min > 0 ? filterRange?.min : 0; // Even if min implies false(We get NaN value when the min input is entered and cleared) the logic works
    const filterMax = filterRange?.max;
    const filterMaxExists = Boolean(filterMax);
    if (filterSalaryType) {
      jobDataReceived = jobDataReceived?.filter((jobEntry) => {
        const jobSalaryDetails = jobEntry?.salary;
        const jobSalaryType = jobSalaryDetails?.type?.toLowerCase();
        if (jobSalaryType === filterSalaryType) {
          const fixedRate = jobSalaryDetails?.fixedRate;
          const range = jobSalaryDetails?.range;
          const min = range?.min;
          const max = range?.max;
          let fixedRateSatisfyCondition = fixedRate >= filterMin;
          let rangeNotSatisfyCondition1 = filterMin < min;
          let rangeNotSatisfyCondition2 = filterMin > max;
          if (filterMaxExists) {
            fixedRateSatisfyCondition &&= fixedRate <= filterMax;
            rangeNotSatisfyCondition1 &&= filterMax < min;
          } else {
            if (rangeNotSatisfyCondition1) rangeNotSatisfyCondition1 = false;
          }

          const rangeSatisfyCondition = !(
            rangeNotSatisfyCondition1 || rangeNotSatisfyCondition2
          );

          if (fixedRate) {
            if (fixedRateSatisfyCondition) return jobEntry;
          } else {
            if (rangeSatisfyCondition) return jobEntry;
          }
        }
      });
    }
    return jobDataReceived;
  };

  const updateFilteredJobData = () => {
    if (isValidArray(jobData)) {
      let filteredJobData = JSON.parse(JSON.stringify(jobData));
      if (searchText?.length > 0) {
        const searchTerm = searchText.toLowerCase();
        filteredJobData = filteredJobData.filter((jobEntry) =>
          jobEntry?.jobTitle?.toLowerCase()?.includes(searchTerm)
        );
      }
      filteredJobData = filterBySortValue(filteredJobData);
      filteredJobData = filterByWorkType(filteredJobData);
      filteredJobData = filterByCountry(filteredJobData);
      filteredJobData = filterBySalary(filteredJobData);
      setFilteredJobData(filteredJobData);
    }
  };

  const onSortChange = (id, currentValue) => {
    setSortSelectionValue(currentValue);
  };

  const onCheckboxGroupChange = (checkboxValue, checkedState, elementID) => {
    if (elementID === "workType") {
      setWorkTypeData((prevItems) => {
        const updatedItems = prevItems?.map((item) => {
          if (item.value === checkboxValue) {
            return { ...item, checked: checkedState };
          }
          return item;
        });

        return updatedItems;
      });
    } else {
      setJobCountries((prevItems) => {
        const updatedItems = prevItems?.map((item) => {
          if (item.value === checkboxValue) {
            return { ...item, checked: checkedState };
          }
          return item;
        });

        return updatedItems;
      });
    }
  };

  const onTabClick = (tabName) => {
    setSelectedTabName(tabName);
  };

  const removeJob = (jobID) => {
    setFilteredJobData((prevState) =>
      prevState.filter((jobEntry) => jobEntry?._id !== jobID)
    );
    setJobData((prevState) =>
      prevState.filter((jobEntry) => jobEntry?._id !== jobID)
    );
  };

  const getWorkTypeFilters = (jobData) => {
    let filters = [];
    let workTypeAndCountMap = new Map();
    jobData?.forEach((jobEntry) => {
      const workType = jobEntry?.workType;
      if (workType?.length > 0) {
        const workTypeCountInMap = workTypeAndCountMap.get(workType);
        workTypeAndCountMap.set(
          workType,
          workTypeCountInMap ? workTypeCountInMap + 1 : 1
        );
      }
    });
    workTypeAndCountMap.forEach((workTypeEntryCount, workTypeEntry) => {
      filters.push({
        label: `${workTypeEntry} (${workTypeEntryCount})`,
        value: `${workTypeEntry}`,
        checked: false,
      });
    });
    return filters;
  };

  const getCountryFilters = (jobData) => {
    let filters = [];
    let countryAndCountMap = new Map();
    jobData?.forEach((jobEntry) => {
      const country = jobEntry?.country;
      const countryCountInMap = countryAndCountMap.get(country);
      countryAndCountMap.set(
        country,
        countryCountInMap ? countryCountInMap + 1 : 1
      );
    });
    countryAndCountMap.forEach((countryEntryCount, country) => {
      filters.push({
        label: `${country} (${countryEntryCount})`,
        value: `${country}`,
        checked: false,
      });
    });
    return filters;
  };

  useEffect(() => {
    let apiToCall;

    setDataLoading(true);
    setDataError(false);
    setSearchText("");
    setSortSelectionValue(sortData[0]?.value);
    setSalaryDetails({});

    if (selectedTabName === "All Jobs") apiToCall = getAlljobs;
    else if (selectedTabName === "Applied") apiToCall = getAppliedjobs;
    else if (selectedTabName === "Saved") apiToCall = getMySavedjobs;
    else if (selectedTabName === "Invited") apiToCall = getInvitedjobs;

    apiToCall()
      .then((res) => {
        if (res) {
          let receivedJobData = JSON.parse(JSON.stringify(res?.result));
          receivedJobData = filterBySortValue(receivedJobData);
          setJobData(receivedJobData);
          setFilteredJobData(receivedJobData);
          const workTypeFilters = getWorkTypeFilters(receivedJobData);
          const countryFilters = getCountryFilters(receivedJobData);
          setWorkTypeData(workTypeFilters);
          setJobCountries(countryFilters);
        } else {
          setJobData([]);
          setFilteredJobData([]);
          setWorkTypeData([]);
          setJobCountries([]);
        }
        setDataLoading(false);
      })
      .catch((err) => {
        setDataLoading(false);
        setDataError(true);
      });
  }, [selectedTabName]);

  useEffect(() => {
    updateFilteredJobData();
  }, [
    searchText,
    sortSelectionValue,
    workTypeData,
    salaryDetails,
    jobCountries,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedTabName]);

  return (
    <div>
      <div className={classes.headerDiv}>
        <Title title="My Jobs"  classNameTitle={"sm:text-lg"}/>
        <div className={classes.tabs}>
          {tabs?.map((tabName) => (
            <div
              className={clsx([
                classes.tab,
                tabName === selectedTabName && classes.selectedTabName,
              ])}
              onClick={() => {
                onTabClick(tabName);
              }}
            >
              {tabName}
            </div>
          ))}
        </div>
      </div>

      {dataLoading ? (
        // <CircularProgress />
        <ContentLoader
          speed={2}
          width={1400}
          height={800}
          viewBox="0 0 1400 800"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="180" rx="3" ry="3" width="280" height="605" />
          <rect x="335" y="180" rx="3" ry="3" width="1200" height="605" />
        </ContentLoader>
      ) : (
        <>
          {dataError ? (
            <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
          ) : (
            <div className={classes.jobFeedBodyContainer}>
              <Grid container>
                <Grid item className={classes.leftView}>
                  <div className={classes.searchTextDiv}>
                    <CustomTextfield
                      id="searchText"
                      value={searchText}
                      placeholder="Search by name"
                      onChange={(id, value) => {
                        setSearchText(value);
                      }}
                      noErrorMessage={true}
                      // endAdornment={
                      //   <SearchRoundedIcon
                      //     onClick={updateFilteredJobData}
                      //     className={classes.seacriconstyle}
                      //   />
                      // }
                    />
                  </div>
                  <div>
                    <Typography className={classes.sortOrFilterText}>
                      Sort{" "}
                    </Typography>
                    <div className={classes.sortRadioGroupDiv}>
                      <CustomRadioGroup
                        id="sort-radio-group"
                        name="sort-radio-group"
                        defaultValue={sortSelectionValue}
                        data={sortData}
                        onChange={onSortChange}
                      />
                    </div>
                  </div>

                  <div>
                    <Typography className={classes.sortOrFilterText}>
                      Filter
                    </Typography>
                    <div className={classes.accordionDiv}>
                      <Accordion className={classes.accordion}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.accordionTitle}>
                            Work Type
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {" "}
                          <div className={classes.workTypeCheckboxDiv}>
                            <CustomCheckboxGroup
                              id="workType"
                              data={workTypeData}
                              onChange={onCheckboxGroupChange}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div className={classes.accordionDiv}>
                      <Accordion className={classes.accordion}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.accordionTitle}>
                            Salary
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <SalaryFilter
                            data={salaryDetails}
                            setData={setSalaryDetails}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div className={classes.accordionDiv}>
                      <Accordion className={classes.accordion}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.accordionTitle}>
                            Country
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {" "}
                          <div className={classes.workTypeCheckboxDiv}>
                            <CustomCheckboxGroup
                              id="country"
                              data={jobCountries}
                              onChange={onCheckboxGroupChange}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>

                  {/* <div className={classes.moreFilterDiv}>
                        <Typography className={classes.moreFilterText}>
                          More Filters
                        </Typography>
                        <div className={classes.filterImgAndCountDiv}>
                          <img src="/filter-list.svg" />
                          <span className={classes.moreFilterCount}>2</span>
                        </div>
                      </div> */}
                </Grid>
                <Grid item className={classes.rightView}>
                  {filteredJobData?.length > 0 ? (
                    <>
                      {" "}
                      {filteredJobData.map((jobPostData) => (
                        <JobList
                          data={jobPostData}
                          removeJob={
                            selectedTabName === "Saved" ? removeJob : null
                          }
                          key={jobPostData?._id}
                        />
                      ))}
                    </>
                  ) : (
                    <Alert severity="info"> {messages?.NO_DATA_FOUND} </Alert>
                  )}

                  {/* <div className={classes.loadMoreDiv}>
                        <MoreHorizIcon
                          className={classes.moreIcon}
                          fontSize="large"
                        />
                        <Typography className={classes.loadMoreText}>
                          Load more
                        </Typography>
                      </div> */}
                </Grid>
              </Grid>
            </div>
          )}
        </>
      )}
    </div>
  );
};

MyJobs.propTypes = {};

export default MyJobs;
