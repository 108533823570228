import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import JobsAssignedCards from "./JobsAssignedCards";
import CustomDialog from "app/v2/components/CustomDialog";
import AddEmployee from "./AddEmployee";
import moment from "moment";

const dummyProposalsData = [
  {
    _id: "1",
    // companyImage: "/company_image.png",
    jobTitle: "Software Developer 1",
    jobNo: "FP23147",
    companyName: "ABC Company",
    createdAt: "2023-04-06T05:17:11.955Z",
    updatedAt: "2023-04-06T05:17:11.955Z",
    teamCount: 4,
    experienceLevel: "Junior",
    contractType: "Part Time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    matchRate: "90%",
    location: "Singapore City, Singapore",
    timezone: "SGST (GMT + 8.0)",
    skills: ["React", "NodeJS", "AngularJS", "React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    proposalStatus: "invited",
    teamMembersProfilePics: [
      "/person1.png",
      "/person2.png",
      "/person3.png",
      "/person4.png",
    ],
  },
  {
    _id: "2",
    companyImage: "/company_image.png",
    jobTitle: "Software Developer 1",
    jobNo: "FP23147",
    companyName: "ABC Company",
    createdAt: "2023-04-06T05:17:11.955Z",
    updatedAt: "2023-04-06T05:17:11.955Z",
    teamCount: 4,
    experienceLevel: "Junior",
    contractType: "Part Time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    matchRate: "90%",
    location: "Singapore City, Singapore",
    timezone: "SGST (GMT + 8.0)",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    proposalStatus: "invited",
    teamMembersProfilePics: [
      "/person1.png",
      "/person2.png",
      "/person3.png",
      "/person4.png",
    ],
  },
  {
    _id: "3",
    companyImage: "/company_image.png",
    jobTitle: "Software Developer 1",
    jobNo: "FP23147",
    companyName: "ABC Company",
    createdAt: "2023-04-06T05:17:11.955Z",
    updatedAt: "2023-04-06T05:17:11.955Z",
    teamCount: 4,
    experienceLevel: "Junior",
    contractType: "Part Time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    matchRate: "90%",
    location: "Singapore City, Singapore",
    timezone: "SGST (GMT + 8.0)",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    proposalStatus: "invited",
    teamMembersProfilePics: [
      "/person1.png",
      "/person2.png",
      "/person3.png",
      "/person4.png",
    ],
  },
  {
    _id: "4",
    companyImage: "/company_image.png",
    jobTitle: "Software Developer 1",
    jobNo: "FP23147",
    companyName: "ABC Company",
    createdAt: "2023-04-06T05:17:11.955Z",
    updatedAt: "2023-04-06T05:17:11.955Z",
    teamCount: 4,
    experienceLevel: "Junior",
    contractType: "Part Time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    matchRate: "90%",
    location: "Singapore City, Singapore",
    timezone: "SGST (GMT + 8.0)",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    proposalStatus: "invited",
    teamMembersProfilePics: [
      "/person1.png",
      "/person2.png",
      "/person3.png",
      "/person4.png",
    ],
  },
];

const EmployeeDetailssection = ({ selectedEmployee, handleBackClick }) => {
  const [proposalsData, setProposalsData] = useState([]);
  const [editEmployeeDialogOpen, setEditEmployeeDialogOpen] = useState(false);
//// console.log("selectedEmployee", selectedEmployee)
  useEffect(() => {
    // add right api later.
    // getEmployerInfo()
    //   .then((res) => {
    //     // console.log({ res });
    //        setDataLoading(false);
    //         setProposalsData(res);
    //   })
    //   .catch((error) => {
    // setDataLoading(false);
    //      setDataError(true);
    // });
    // setDataLoading(false);
    // setProposalsData(dummyProposalsData);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Grid
        sx={{
          marginBottom: "36px",
          color: "#0B0E1E",
          fontWeight: 600,
        }}
      >
        <button onClick={handleBackClick}>
          <ArrowBackIcon sx={{ marginRight: "10px", fontSize: "16px" }} />
          Back to employees
        </button>
      </Grid>

      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "24px",
        }}
      >
        <Grid sx={{ paddingLeft: "24px", display: "flex" }}>
          <Grid sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 600,
                fontSize: "32px",
                maxWidth: " 208px",
                minWidth: "150px",
              }}
            >
              {selectedEmployee.firstName + " " + selectedEmployee.lastName}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ fontSize: "12px", color: "#717171", fontWeight: 600 }}
            >
              {/* PROJECT MANAGER */}
              {selectedEmployee.companyTitle}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ fontSize: "12px", color: "#717171", fontWeight: 400 }}
            >
              {/* Added April2, 2023 */}
              Added 
              <p>
                { selectedEmployee?.createdAt ? moment(selectedEmployee?.createdAt).format('MMMM D, YYYY, h:mm A') : "-"}
              </p>
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ fontSize: "12px", color: "#717171", fontWeight: 400 }}
            >
              {/* Last logged in April26, 2023 */}
              Last logged in
              <p>
                { selectedEmployee?.updatedAt ? moment(selectedEmployee?.updatedAt).format('MMMM D, YYYY, h:mm A') : "-"}
              </p>

            </Typography>
          </Grid>
          <Grid
            container
            sx={{
              height: "25px",
              border: "1px solid #EDEDED",
              padding: "0px 20px 0px 20px ",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: "12px",
                color: "#0B0E1E",
                fontWeight: 600,
              }}
            >
              {/* Pending Invite */}
              {selectedEmployee?.status}

            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            height: "44px",
            width: "87px",
            backgroundColor: "#EDEDED",
            padding: "12px 28px 12px 28px",
          }}
        >
          <button
            onClick={() => {
              setEditEmployeeDialogOpen(true);
            }}
            style={{ color: "#0B0E1E", fontSize: "16px", fontWeight: 600 }}
          >
            Edit
          </button>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          marginLeft: "24px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={3}>
          <Typography
            variant="subtitle2"
            sx={{ fontSize: "12px", color: "#717171", fontWeight: 400 }}
          >
            LOCATION
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: "#0B0E1E", fontSize: "16px", fontWeight: 600 }}
          >
            {/* Cairo, Egypt */}
            {selectedEmployee?.country + ", " + selectedEmployee?.state}

          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="subtitle2"
            sx={{ fontSize: "12px", color: "#717171", fontWeight: 400 }}
          >
            USER ROLE
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: "#0B0E1E", fontSize: "16px", fontWeight: 600 }}
          >
            {/* Recruiter */}
            {selectedEmployee?.role}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="subtitle2"
            sx={{ fontSize: "12px", color: "#717171", fontWeight: 400 }}
          >
            STATUS
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: "#0B0E1E", fontSize: "16px", fontWeight: 600 }}
          >
            {/* Active */}
            {selectedEmployee?.status}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="subtitle2"
            sx={{ fontSize: "12px", color: "#717171", fontWeight: 400 }}
          >
            TIMEZONE
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: "#0B0E1E", fontSize: "16px", fontWeight: 600 }}
          >
            {/* SGST(GMT+8.0) */}
            {selectedEmployee?.timezone}

          </Typography>
        </Grid>
      </Grid>
      <Typography
        variant="subtitle1"
        sx={{
          marginLeft: "24px",
          marginTop: "36px",
          marginBottom: "24px",
          color: "#0B0E1E",
          fontSize: "24px",
          fontWeight: 600,
        }}
      >
        {" "}
        Jobs Assigned
      </Typography>
      <Grid sx={{ marginLeft: "24px", width: "100%" }}>
        {proposalsData?.length>0 ? proposalsData?.map((proposalData) => (
          <JobsAssignedCards data={proposalData} />
        )) :
        (
          <p>No Jobs</p>
        )}
      </Grid>

      <CustomDialog
        open={editEmployeeDialogOpen}
        setOpen={setEditEmployeeDialogOpen}
        title={
          <div className="text-[16px] font-semibold text-center">
            Edit Employee
          </div>
        }
        // title={<AddConsultantDialogTitle title="Add Consultant" />}
        content={<AddEmployee  selectedEmployee={selectedEmployee}/>}
      />
    </div>
  );
};

export default EmployeeDetailssection;
