import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import CustomAutocomplete from "app/v2/components/filters/CustomAutocomplete";
import CustomSlider from "app/v2/components/filters/CustomSlider";
import { Alert, Typography } from "@mui/material";
import { isValidArray } from "../Utils/utilFunctions";
import { messages } from "app/v2/Pages/Utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSkillsThunk,
  fetchSkillLevelsThunk,
} from "app/v2/store/features/misc/miscThunk";

const useStyles = makeStyles((theme) => ({
  ratingSliderRoot: {
    minWidth: "283px",
    width: "100%",
  },
  skillDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  skillName: {
    flex: 0.5,
    fontSize: "12px",
    fontWeight: 700,
    textAlign: "left",
  },
  ratingDiv: {
    flex: 1,
    display: "flex",
    columnGap: "15px",
    // alignItems: "center",
  },
  ratingText: {
    fontSize: "14px",
    color: theme.palette.secondary.light,
    position: "relative",
    top: "4px",
  },
  deleteIconDiv: {
    flex: 0.5,
  },
  deleteIcon: {
    cursor: "pointer",
    width: "30px",
    height: "30px",
    marginLeft: "auto",
    position: "relative",
    bottom: "4px",
  },
  skillMessage: {
    color: "red",
    fontSize: "14px",
  },
  customSkillsHeading: {
    fontSize: "12px",
    fontWeight: 600,
    color: theme.palette.secondary.light,
    marginBottom: "28px",
  },
  autoCompleteRoot: {
    width: "240px",
  },
}));

// const ratingTextMapper = {
//   1: "Novice",
//   2: "Beginner",
//   3: "Intermediate",
//   4: "Advanced",
//   5: "Expert",
// };

const SkillsPicker = ({ id, data, setData, readOnly = false }) => {
  console.log("skillsData",data)
  const classes = useStyles();
  const dispatch = useDispatch();
  const { skillLevels, skills } = useSelector((state) => state.misc);

  const [allSkillNames, setAllSkillNames] = useState();
  //  [skill1Name,skill2Name,..]
  const [skillIDToNameMapper, setSkillIDToNameMapper] = useState();
  // {
  //   'id':'name'
  // }
  const [skillNameToIDMapper, setSkillNameToIDMapper] = useState();
  // {
  //   'name':'id'
  // }
  const [selectedSkills, setSelectedSkills] = useState();
  // [
  //   { skillID: "646f1c2f96656ed53aa3231b",
  //     skillLevelID:"",
  //   }
  // ];
  const [skillLevelIDToRatingMapper, setSkillLevelIDToRatingMapper] =
    useState();
  // {
  //   'levelID':{
  //     rating:"",
  //     ratingText:""
  //   }
  // }
  const [skillRatingToLevelIDMapper, setSkillRatingToLevelIDMapper] =
    useState();
  // {
  //   'rating':levelID',
  // }

  const filterSelectedFromAll = (allSkillsReceived, selectedSkillsReceived) => {
    if (isValidArray(selectedSkillsReceived)) {
      const selectedSkillIds = selectedSkillsReceived?.map(
        (skillEntry) => skillEntry?.skillID
      );
      allSkillsReceived = allSkillsReceived?.filter(
        (skillEntry) => !selectedSkillIds.includes(skillEntry?._id)
      );
    }

    const skillNames = allSkillsReceived?.map((skillInfo) => skillInfo?.name);
    setAllSkillNames(skillNames);
  };

  const onNewSkillAddition = (componentId, newSkillName) => {
    const newSkillEntry = {};
    newSkillEntry.skillID = skillNameToIDMapper?.[newSkillName];
    newSkillEntry.skillLevelID = skillRatingToLevelIDMapper?.[1];

    const updatedSelectedSkills = [...selectedSkills, newSkillEntry];

    setSelectedSkills(updatedSelectedSkills);
    setData(id, updatedSelectedSkills);
    setAllSkillNames((allSkillNames) =>
      allSkillNames?.filter((skillName) => skillName !== newSkillName)
    ); // removing newly added skill from list of allSkillNames to not show in autocomplete and avoid duplicate skill addition.
  };

  const onSkillRatingChange = (sliderID, value) => {
    const skillIndex = sliderID.slice(-1); // last char is the skill index.
    let updatedSelectedSkills = [...selectedSkills];
    updatedSelectedSkills[skillIndex] = {
      ...updatedSelectedSkills[skillIndex],
      skillLevelID: skillRatingToLevelIDMapper?.[value],
    };
    setSelectedSkills(updatedSelectedSkills);
    setData(id, updatedSelectedSkills);
  };

  const onSkillDelete = (skillID) => {
    const deletedSkillName = skillIDToNameMapper?.[skillID];
    const updatedSelectedSkills = [...selectedSkills]?.filter(
      (skill) => skill?.skillID !== skillID
    );
    setSelectedSkills(updatedSelectedSkills);
    setData(id, updatedSelectedSkills);
    setAllSkillNames((allSkillNames) => [...allSkillNames, deletedSkillName]); // adding back deleted skill name into all skills, to show in the autocomplete.
  };

  useEffect(() => {
    if (!skillLevels?.length) dispatch(fetchSkillLevelsThunk());
    if (!skills?.length) dispatch(fetchSkillsThunk());
  }, [dispatch]);

  useEffect(() => {
    const ratingMapper = {};
    const levelIDMapper = {};
    skillLevels?.forEach((skillLevelEntry) => {
      ratingMapper[skillLevelEntry?.rating] = skillLevelEntry?._id;
      levelIDMapper[skillLevelEntry?._id] = {
        rating: skillLevelEntry?.rating,
        ratingText: skillLevelEntry?.ratingText,
      };
    });
    setSkillLevelIDToRatingMapper(levelIDMapper);
    setSkillRatingToLevelIDMapper(ratingMapper);
  }, [skillLevels]);

  useEffect(() => {
    if (isValidArray(skills) && !skillIDToNameMapper && !skillNameToIDMapper) {
      let skillNameToIDMapper = {};
      let skillIDToNameMapper = {};
      skills?.forEach((skillEntry) => {
        skillNameToIDMapper[skillEntry?.name] = skillEntry?._id;
        skillIDToNameMapper[skillEntry?._id] = skillEntry?.name;
      });
      setSkillIDToNameMapper(skillIDToNameMapper);
      setSkillNameToIDMapper(skillNameToIDMapper);
    }

    if (isValidArray(data)) {
      setSelectedSkills(data);
      filterSelectedFromAll(skills, data);
    }
  }, [skills, data]);

  return (
    <>
      {allSkillNames?.length > 0 ? (
        <>
          {selectedSkills?.length === 0 && (
            <Alert severity="warning">{messages.ATLEAST_ONE_SKILL}</Alert>
          )}
          <div className="mb-[34px]">
            {selectedSkills?.map((skillInfo, idx) => (
              <div className={classes.skillDiv} key={idx}>
                <Typography className={classes.skillName}>
                  {skillIDToNameMapper?.[skillInfo?.skillID]}
                </Typography>
                <div className={classes.ratingDiv}>
                  <CustomSlider
                    id={`${id}-slider-${idx}`}
                    min={1}
                    max={5}
                    selectedValue={
                      skillLevelIDToRatingMapper?.[skillInfo?.skillLevelID]
                        ?.rating
                    }
                    onChange={onSkillRatingChange}
                    customClasses={classes.ratingSliderRoot}
                    disabled={readOnly}
                  />
                  <Typography className={classes.ratingText}>
                    {
                      skillLevelIDToRatingMapper?.[skillInfo?.skillLevelID]
                        ?.ratingText
                    }
                  </Typography>
                </div>
                {!readOnly && (
                  <div className={classes.deleteIconDiv}>
                    <img
                      alt="delete-icon"
                      src="/delete-gray.svg"
                      className={classes.deleteIcon}
                      onClick={() => {
                        onSkillDelete(skillInfo?.skillID);
                      }}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          {!readOnly && (
            <CustomAutocomplete
              id={"custom-skill-autocomplete"}
              data={allSkillNames}
              placeholder={"Add new skill"}
              onChange={onNewSkillAddition}
              customClasses={classes.autoCompleteRoot}
              defaultSelection={""}
              alwaysShowDefaultSelection={true}
              key={allSkillNames}
            />
          )}
        </>
      ) : (
        <Alert severity="info">{messages?.NO_DATA_FOUND}</Alert>
      )}
    </>
  );
};

SkillsPicker.propTypes = {};

export default SkillsPicker;
