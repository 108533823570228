import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import ContentLoader from "react-content-loader";
import { Alert } from "@mui/material";

import CustomTabs from "app/v2/components/CustomTabs";
import AnalyticsTabView from "./AnalyticsTabView";

import { getDashboardInfo } from "app/v2/services/admin/dashboard.service";

import {
  isValidObject,
  camelCaseToTitleCase,
  isValidArray,
} from "../../Utils/utilFunctions";
import { messages } from "../../Utils/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "18px 130px 0 130px",
    position: "relative",
  },
  bgImage: {
    position: "absolute",
    top: 0,
    right: 0,
    maxWidth: "687px",
    width: "100%",
    maxHeight: "704px",
  },
}));

const Dashboard = (props) => {
  const classes = useStyles();

  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [tabsData, setTabsData] = useState([]);

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  useEffect(() => {
    getDashboardInfo()
      .then((response) => {
        //// console.log({ response });
        const dataReceived = response?.data;
        if (isValidObject(dataReceived)) {
          const tabLabels = Object.keys(dataReceived);
          let tabDataReceived = [];
          tabLabels.forEach((label) => {
            const tabTitle = camelCaseToTitleCase(label);
            const tabEntry = {
              label: tabTitle,
              component: (
                <AnalyticsTabView
                  data={dataReceived[label]}
                  tabTitle={tabTitle}
                />
              ),
            };
            tabDataReceived.push(tabEntry);
          });

          setTabsData(tabDataReceived);
        }

        setDataLoading(false);
      })
      .catch((error) => {
        setDataLoading(false);
        setDataError(true);
      });
  }, []);
  return (
    <>
      {dataLoading ? (
        <ContentLoader
          speed={2}
          width={1400}
          height={800}
          viewBox="0 0 1400 800"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="135" y="40" rx="3" ry="3" width="240" height="80" />
          <rect x="135" y="140" rx="3" ry="3" width="280" height="20" />
          <rect x="135" y="200" rx="3" ry="3" width="1180" height="150" />
          <rect x="135" y="370" rx="3" ry="3" width="1180" height="150" />
          <rect x="135" y="550" rx="3" ry="3" width="1180" height="150" />
        </ContentLoader>
      ) : (
        <>
          {dataError ? (
            <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
          ) : (
            <div className={classes.container}>
              <div className="text-[48px] font-semibold">Hello Admin,</div>
              <div className="text-[16px] font-semibold pb-[51px]">
                See your product analytics
              </div>
              {isValidArray(tabsData) ? (
                <CustomTabs
                  tabsData={tabsData}
                  currentTabIndex={currentTabIndex}
                  setCurrentTabIndex={setCurrentTabIndex}
                  alignTabNameToLeft={true}
                />
              ) : (
                <Alert severity="info"> {messages?.NO_DATA_FOUND} </Alert>
              )}
              <img
                src="/_img_hero_arrow.png"
                alt="bg-image"
                className={classes.bgImage}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
