import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Select, InputLabel, MenuItem, FormControl, Box, Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import CustomLabel from "./CustomLabel";

const useStyles = makeStyles((theme) => ({
  box: {
    width: "100%",
  },
  formControl: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 0,
      border: "1px solid #EDEDED",
    },
  },
  selectRoot: {
    height: "40px",
    fontSize: "16px",
    width: "100%", 
  },
  noLeftPadding: {
    "& .MuiSelect-select": {
      paddingLeft: 0,
    },
  },
  selectLabel: {
    fontSize: "16px",
    color: theme.palette.secondary.light,
  },
  menuItem: {
    fontSize: "16px",
    display: "inline-block", // Display as inline block
    justifyContent: "center",
    padding: "8px",
    backgroundColor: "#E0E0E0",
    maxWidth:"80%",
    borderRadius: "4px",
    margin: "4px",
    '&:hover': {
      backgroundColor: "#d0d0d0",
    },
    whiteSpace: "nowrap", // Prevent wrapping within items
  }
    ,
  noBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
  chip: {
    margin: "4px",
    backgroundColor: "#E0E0E0",
  },
  chipsContainer: {
    display: "flex",
  },
  menuItemsContainer: {
    display: "flex",
    flexWrap: "wrap",
    padding: "8px",
    maxHeight: 300,
    overflowY: "auto",
    width: "100%", // Default to full width
    maxWidth: "80%", // Adjust to 80% of select component width by default
    boxSizing: "border-box", // Ensure it fits within the select component width
    [theme.breakpoints.down("sm")]: {
      maxWidth: "70%", // Adjust for small devices
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "60%", // Adjust for extra small devices
    },    // Adjust to fit within the select component width
  },
}));

const CustomSelectwithTags = (props) => {
  const classes = useStyles();
  const {
    id,
    data,
    defaultSelection,
    disabled,
    label,
    placeholder,
    onChange,
    customClasses,
    showMandatorySymbol,
    noOutline,
  } = props;

  const [selectedValue, setSelectedValue] = useState("");
  const noDefaultSelection = Boolean(defaultSelection) === false;

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    onChange(id, event.target.value);
  };

  useEffect(() => {
    setSelectedValue(defaultSelection);
  }, [defaultSelection]);

  return (
    <Box className={classes.box} key={id}>
      {label && (
        <CustomLabel label={`${label} ${showMandatorySymbol ? "*" : ""}`} />
      )}
      <FormControl
        fullWidth
        className={noOutline ? classes.noBorder : classes.formControl}
      >
        <InputLabel id={`${id}-select-label`} className={classes.selectLabel}>
          {placeholder}
        </InputLabel>
        <Select
          labelId={`${id}-select-label`}
          id={`${id}-select`}
          value={selectedValue}
          label={placeholder}
          onChange={handleSelectChange}
          className={clsx([
            customClasses,
            classes.selectRoot,
            noOutline && classes.noLeftPadding,
          ])}
          disabled={disabled}
          renderValue={(selected) => (
            <Chip key={selected} label={selected} className={classes.chip} />
          )}
          MenuProps={{
            PaperProps: {
              className: classes.menuItemsContainer,
            },
          }}
        >
          {data.map((menuItemValue, idx) => (
            <MenuItem
              key={idx}
              value={menuItemValue}
              className={classes.menuItem}
            >
              {menuItemValue}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

CustomSelectwithTags.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  defaultSelection: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  customClasses: PropTypes.string,
  showMandatorySymbol: PropTypes.bool,
  noOutline: PropTypes.bool,
};

export default CustomSelectwithTags;