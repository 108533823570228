// @ts-nocheck
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
// import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
// import { Grid } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CustomDialog from "app/v2/components/CustomDialog";
import AddConsultant from "../ManageConsultants/addconsultant";

import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import CustomSelect from "app/v2/components/filters/CustomSelect";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CloseIcon from "@mui/icons-material/Close";
import BarChart from "./BarChart";
import AddTeamMembersDialogueTittle from "../ManageConsultants/addconsultant/DialogTitle";
import AddTeamMembers from "./AddTeamMembers";
import {
  deleteAgencyEmployeeByIdThunk,
  getAllAgencyEmployeeInfoThunk,
} from "app/v2/store/features/agency/employee.thunk";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import DeleteEmployeeDialog from "../../Employer/common/DeleteEmployeeDialog";
import moment from "moment";

const members = [
  {
    name: "Safi Romano",
    userRole: "Project Manager",
    location: "Cario Egypt",
    status: "Active",
    roleType: "Recruiter",
    jobsManaging: "20",
    totalJobsManaged: "123",
    createdDate: "April 2 2023",
    lastLoggedIn: "April 26,2023",
  },
  {
    name: "Julia Porter",
    userRole: "Project Manager",
    location: "Cario Egypt",
    status: "Active",
    roleType: "Recruiter",
    jobsManaging: "20",
    totalJobsManaged: "123",
    createdDate: "April 2 2023",
    lastLoggedIn: "April 26,2023",
  },
  {
    name: "Mark King",
    userRole: "Project Manager",
    location: "Cario Egypt",
    status: "Active",
    roleType: "Recruiter",
    jobsManaging: "20",
    totalJobsManaged: "123",
    createdDate: "April 2 2023",
    lastLoggedIn: "April 26,2023",
  },
  {
    name: "Larry Samberg",
    userRole: "Project Manager",
    location: "Cario Egypt",
    status: "Deactivated",
    roleType: "Recruiter",

    totalJobsManaged: "32",
    createdDate: "April 2 2023",
    lastLoggedIn: "April 26,2023",
  },
  {
    name: "Troy Montero",
    userRole: "Project Manager",
    location: "Cario Egypt",
    status: "Deactivated",
    roleType: "Recruiter",

    totalJobsManaged: "32",
    createdDate: "April 2 2023",
    lastLoggedIn: "April 26,2023",
  },
  {
    name: "Klair Valdez",
    userRole: "Project Manager",
    location: "Cario Egypt",
    roleType: "Recruiter",
  },
];

const consultants = ["a", "b"];

const useStyles = makeStyles((theme) => ({
  alEmailFormControl: {
    width: "100%",
  },
  alEmailInput: {
    width: "100%",
    height: "40px",
    backgroundColor: "#F7F7F7",
    borderRadius: "0",
    "& .MuiInputBase-input": {
      padding: "9px 12px",
      fontSize: "1px",
      [theme.breakpoints.down("1250")]: {
        fontSize: "10px",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0",
    },
  },
  box: {
    // textAlign: "center",
  },
  formControl: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 0,
      border: "1px solid #EDEDED",
    },
  },
  selectRoot: {
    height: "50px",
    fontSize: "16px",
  },
  selectLabel: {
    fontSize: "16px",
    color: theme.palette.secondary.light,
  },
  menuItem: {
    fontSize: "16px",
  },

  customDialog: {
    width: "60%", // Default width
    height: "auto", // Allow height to adjust to content

    [theme.breakpoints.down("sm")]: {
      width: "90%", // Adjust width for smaller screens (e.g., width < 600px)
    },

    // Define more styles for other screen sizes as needed
  },
  orgImage: {
    width: "50px",
    height: "50px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
    marginRight: "10px",
  },
}));

const ManageAgency = ({
  title,
  name,
  position,
  location,
  addedDate,
  lastLoginDate,
  status,
  jobsManaging,
  totalJobsManaged,
}) => {
  const [teamMembers, setTeamMembers] = useState(members);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [addConsultantDialogOpen, setAddConsultantDialogOpen] = useState(false);
  const [editEmployeeDialogOpen, setEditEmployeeDialogOpen] = useState(false);
  const [deleteJobDialogOpen, setDeleteJobDialogOpen] = useState(false);
  const [deleteActionLoading, setDeleteActionLoading] = useState(false);
  const [deleteActionCompleted, setDeleteActionCompleted] = useState(false);
  const [deleteActionError, setDeleteActionError] = useState(false);
  const [updateGetAllEmployeeInfoApi, setUpdateGetAllEmployeeInfoApi] =
    useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState([]);

  const [addEmployeeDialogOpen, setAddEmployeeDialogOpen] = useState(false);

  const [isAdmin, setIsAdmin] = useState(false);
  const [isRecruiter, setIsRecruiter] = useState(false);
  const [isinterviwer, setIsinterviwer] = useState(false);
  const [isHr, setIsHr] = useState(false);
  const empIdOrLoginId = localStorage.getItem("empIdOrLoginId");

  const handleDialoguesAndApi = () => {
    setUpdateGetAllEmployeeInfoApi((prev) => !prev);
    setAddEmployeeDialogOpen(false);
    setEditEmployeeDialogOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCardClick = (employee) => {
    // setSelectedEmployeeIsClicked(true); donot delete this it will be used in futureversions
    //// console.log("emploee to be deleted", employee)
    setSelectedEmployee(employee);
    setEditEmployeeDialogOpen(true);
  };

  const onDeleteClick = () => {
    setDeleteActionLoading(false); // later on after API integration change it to true
    //// console.log("selectedEmployee--", selectedEmployee);
    setTimeout(() => {
      /** Confirm case */
      setDeleteActionLoading(false);
      setDeleteActionCompleted(true);
      /** Error case */
      // setDeleteActionLoading(false);
      // setDeleteActionError(true);
    }, 2000);
    // above setTimeout is just for testing, delete it once we integrate API

    // call the below API when API is ready to delete employee
    const email = selectedEmployee?.email;
    if (email) {
      dispatch(
        deleteAgencyEmployeeByIdThunk({
          email: email,
          deactivated: selectedEmployee?.status === "Inactive" ? false : true,
        })
      )
        .unwrap()
        .then((result) => {
          //// console.log("Job deleted successfully:", result);
          setDeleteActionLoading(false);
          setDeleteActionCompleted(true);
          // isFunction(onJobDelete) && onJobDelete(jobData?._id);
          setUpdateGetAllEmployeeInfoApi((prev) => !prev);
        })
        .catch((error) => {
          setDeleteActionLoading(false);
          setDeleteActionError(true);
          console.error("Error deleting job:", error);
        });
    } else {
      console.error("No selected employee to delete.");
    }
  };

  const getAllAgencyEmployeeList = () => {
    dispatch(getAllAgencyEmployeeInfoThunk(empIdOrLoginId))
      .unwrap()
      .then((res) => {
        if (res) {
          setTeamMembers(res?.data?.employees);
        }
      })
      .catch((err) => {
        if (err.message) {
          const key = enqueueSnackbar(err.message, {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        } else {
          const key = enqueueSnackbar("Problem in fetching data!", {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        }
        console.error(err);
      });
  };

  useEffect(() => {
    getAllAgencyEmployeeList();
  }, [updateGetAllEmployeeInfoApi]);
  useEffect(() => {
    if (!deleteJobDialogOpen) {
      // Setting back to default states- used when the dialog is closed.
      setDeleteActionLoading(false);
      setDeleteActionCompleted(false);
      setDeleteActionError(false);
    }
  }, [deleteJobDialogOpen]);

  const role_Id = localStorage.getItem("roleId");
  const roleName = localStorage.getItem("roleName");
  console.log("amadminRole", roleName);
  useEffect(() => {
    const isUserAdmin = roleName?.includes("Admin");
    const isRecruiter = roleName?.includes("Recuriter");
    const isinterviwer = roleName?.includes("Interviewer");
    const isHr = roleName?.includes("HR");
    setIsAdmin(isUserAdmin);
    setIsRecruiter(isRecruiter);
    setIsinterviwer(isinterviwer);
    setIsHr(isHr);
  }, [role_Id, roleName]);

  console.log("amadmin2", isAdmin);

  return (
    <>
      <Grid>
        <Grid sx={{ padding: "0px" }}>
          <Grid>
            <Toolbar style={{ padding: "0px", margin: "0px" }}>
              <img
                src={"/company_image.png" ?? "/org_placeholder.svg"} // Replace with the actual path to your logo image
                alt="Logo"
                className={classes.orgImage}
              />
              <Typography sx={{ fontSize: "40px", fontWeight: 600 }}>
                ABC Company
              </Typography>
            </Toolbar>
            <Divider light sx={{ margin: "20px 0px" }} />
            {/* <div style={{ justifyContent: "flex-end", }}>
              <Box
                style={{
                  display: 'flex',
                  backgroundColor: "black",
                  color: "white",
                  // padding: "100px",
                  width: "890px",
                  height: "379px",

                }}
              >
                <Typography variant="h6">{title}</Typography>
              </Box>
            </div> */}

            <div className="mx-auto z-[1]  ">
              <BarChart />
            </div>
            <Box
              sx={{
                width: "100%",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                display: "flex",
                justifyContent: "center",
                marginBottom: "30px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "white",
                  padding: "36px",

                  "&:": {},
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: "14px", color: "black", fontWeight: "bold" }}
                >
                  24
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "10px", color: "gray" }}
                >
                  <br />
                  {" Total Interviews"}
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: "white",
                  padding: "36px",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: "14px", color: "black", fontWeight: "bold" }}
                >
                  123
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "10px", color: "gray" }}
                >
                  <br />
                  {" Total Job Offers"}
                </Typography>
              </Box>
            </Box>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20 px",
              }}
            >
              <Box
                sx={{
                  width: 222,
                  height: 176,
                  backgroundColor: "white",
                  padding: "30px",
                  marginRight: "15px",
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  "&:": {},
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography
                  // variant="body2"
                  sx={{ fontSize: "24px", color: "#0B0E1E", fontWeight: 600 }}
                >
                  254
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "14px", color: "#717171", fontWeight: 700 }}
                >
                  <br />
                  {" Profile view"}
                </Typography>
              </Box>

              <Box
                sx={{
                  width: 222,
                  height: 176,
                  backgroundColor: "white",
                  padding: "30px",
                  marginRight: "15px",
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  "&:": {},
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: "24px", fontWeight: 600, color: "#0B0E1E" }}
                >
                  98%
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "14px", color: "#717171", fontWeight: 700 }}
                >
                  <br />
                  {"Profile Completeness"}
                </Typography>
              </Box>

              <Box
                sx={{
                  width: 222,
                  height: 176,
                  backgroundColor: "white",
                  padding: "30px",
                  marginRight: "15px",
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  "&:": {},
                  backgroundColor: "white",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "24px",
                    color: "#0B0E1E",
                    fontWeight: 600,
                    display: "flex",
                  }}
                >
                  <span>4.6</span>
                  <img
                    src="/material-symbols_star-rate-half-rounded.svg" // Replace with the actual path to your logo image
                    alt="Logo"
                    style={{ height: "28px", width: "28px" }} // Adjust width and margin as needed
                  />
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "14px", color: "#717171", fontWeight: 700 }}
                >
                  <br />
                  {" Consultant Satisfaction Rating"}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: 222,
                  height: 176,
                  backgroundColor: "white",
                  padding: "30px",
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  "&:": {},
                  backgroundColor: "white",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: "24px", color: "#0B0E1E", fontWeight: 600 }}
                >
                  80%
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "14px", color: "#717171", fontWeight: 700 }}
                >
                  <br />
                  {" Job Success Score"}
                </Typography>
              </Box>
            </div>
            <div style={{ height: "500px", overflow: "auto" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "42px",
                  marginBottom: "42px",
                }}
              >
                <Typography
                  style={{
                    fontSize: "32px",
                    padding: "4px",
                    fontWeight: 600,
                    color: "#0B0E1E",
                  }}
                >
                  {" "}
                  Team Members
                </Typography>

                <button
                  style={{
                    background: "#0B0E1E",
                    color: "white",
                    width: "181px",
                    height: "38px",
                    fontWeight: 600,
                    fontsize: "14px",
                    alignItems: "center",
                    wordWrap: "break-word",
                  }}
                  onClick={() => {
                    setAddEmployeeDialogOpen(true);
                  }}
                >
                  Add Team Members
                </button>
              </div>
              <div>
                {teamMembers.map((team) => {
                  return (
                    <>
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          borderBottom: "1px solid lightgray",
                          // marginTop: "10px",
                          fontSize: 24,
                          fontWeight: 600,
                          marginBottom: "24px",
                          paddingBottom: "16px",
                        }}
                      >
                        <Grid item xs={2.8}>
                          <Typography
                            sx={{ fontWeight: "600" }}
                            variant="subtitle1"
                          >
                            {team?.firstName} {team?.lastName}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontSize: "10px", marginBottom: "10px" }}
                          >
                            {/* {team.userRole} */}
                            {team?.companyTitle}
                          </Typography>

                          <Typography
                            variant="subtitle2"
                            sx={{ fontSize: "12px", color: "lightgray" }}
                          >
                            Added{" "}
                            {team?.createdAt
                              ? moment(team?.createdAt).format(
                                  "MMMM D, YYYY, h:mm A"
                                )
                              : "-"}
                          </Typography>

                          <Typography
                            variant="subtitle2"
                            sx={{ fontSize: "12px", color: "lightgray" }}
                          >
                            Last logged in 
                            <p>
                        {team?.updatedAt
                          ? moment(team?.updatedAt).format(
                              "MMMM D, YYYY, h:mm A"
                            )
                          : "-"}
                      </p>
                          </Typography>
                        </Grid>
                        <Grid item xs={2.4} style={{ paddingTop: "5px" }}>
                          <Grid style={{ marginBottom: "27px" }}>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                // width: "100%",
                                // height: "13px",
                                fontSize: "10px",
                                color: "#717171",
                                fontWeight: 700,
                              }}
                            >
                              Location
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              style={{
                                color: "#0B0E1E",
                                fontSize: "16px",
                                fontWeight: 600,
                              }}
                            >
                              {team?.country}
                            </Typography>
                          </Grid>
                          {team.status && (
                            <Grid>
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  fontSize: "10px",
                                  color: "#717171",
                                  fontWeight: 700,
                                }}
                              >
                                Status
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{
                                  color:
                                    team.status === "Active"
                                      ? "#14C042"
                                      : "#ABABAB",
                                  fontWeight: 600,
                                  fontSize: "16px",
                                }}
                              >
                                {team?.status ?? "-"}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                        <Grid item xs={3.6} style={{ paddingTop: "5px" }}>
                          <div style={{ marginBottom: "27px" }}>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                // width: "100%",
                                // height: "13px",
                                fontSize: "10px",
                                color: "#717171",
                                fontWeight: 700,
                              }}
                            >
                              UserRole
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              style={{
                                color: "#0B0E1E",
                                fontSize: "16px",
                                fontWeight: 600,
                              }}
                            >
                              {team?.role ?.roleType?? "-"}
                            </Typography>
                          </div>
                          <Grid
                            sx={
                              {
                                // height: "50px",
                                // width: "70%",
                                // background: "#f5f5f5",
                                // fontSize: "5px",
                              }
                            }
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    // width: "100%",
                                    // height: "13px",
                                    fontSize: "10px",
                                    color: "#717171",
                                    fontWeight: 700,
                                  }}
                                >
                                  JOBS MANAGING
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    color: "#0B0E1E",
                                    fontSize: "16px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {team?.analitics?.jobsManaged ?? "-"}
                                </Typography>
                              </Grid>

                              <Grid item xs={6}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{
                                    // width: "100%",
                                    // height: "13px",
                                    fontSize: "10px",
                                    color: "#717171",
                                    fontWeight: 700,
                                  }}
                                >
                                  TOTAL JOBS MANAGED
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    color: "#0B0E1E",
                                    fontSize: "16px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {team?.analitics?.totalJobsManaged}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={1.3}
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {" "}
                          <div>
                            <Button
                              disabled={!isAdmin} // Disable the button if user is not an admin
                              sx={{
                                p: 1,
                                border: "1px solid lightgray",
                                width: "100%",
                                backgroundColor: "white !important",
                                textAlign: "center",
                                color: "#0B0E1E",
                                fontSize: "12px",
                                fontWeight: 600,
                                marginBottom: "8px", // Adjust spacing between buttons if needed
                              }}
                              onClick={() => handleCardClick(team)}
                            >
                              Manage
                            </Button>
                          </div>
                          <div>
                            <Button
                              disabled={!isAdmin} // Disable the button if user is not an admin
                              sx={{
                                p: 1,
                                border: "1px solid lightgray",
                                width: "100%",
                                backgroundColor: "white !important",
                                textAlign: "center",
                                color: "#0B0E1E",
                                fontSize: "12px",
                                fontWeight: 600,
                              }}
                              onClick={() => {
                                if (isAdmin) {
                                  setSelectedEmployee(team);
                                  setDeleteJobDialogOpen(true);
                                }
                              }}
                            >
                              {team?.status === "Inactive"
                                ? "Activate"
                                : "Deactivate"}
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </div>
            </div>
          </Grid>
        </Grid>
        {/* <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography
            variant="subtitle2"
            sx={{ fontSize: "14px", color: "black", textAlign: "center" }}
          >
            Manage Team Member
          </Typography>
          <Divider light sx={{ margin: "15px 0" }} /> 
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent >
          <Typography
            variant="subtitle2"
            sx={{ fontSize: "18px", color: "black" }}
          >
            AddTeamMember
          </Typography>
          <Box sx={{ margin: "0px 15px" }}>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel
                id="demo-simple-select-label"
                className={classes.selectLabel}
              >
                Choose Consultant
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size="small"
                label="Choose Consultant"
                className={classes.selectRoot}
              >
                <MenuItem value={10}>
                  <div style={{ display: "flex" }}>
                    <Avatar
                      alt="Remy Sharp"
                      src="/avatar.png"
                      sx={{ width: 20, height: 20 }}
                      variant="square"
                    />

                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "12px", color: "black" }}
                    >
                      {" "}
                      Lacy Clark
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "12px", color: "lightgray" }}
                    >
                      lacy.clark@gmail.com
                    </Typography>
                  </div>
                </MenuItem>

                <MenuItem value={20}>
                  <div style={{ display: "flex" }}>
                    <Avatar
                      alt="Remy Sharp"
                      src="/avatar.png"
                      sx={{ width: 20, height: 20 }}
                      variant="square"
                    />

                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "12px", color: "black" }}
                    >
                      Mark Smit
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "12px", color: "lightgray" }}
                    >
                      m.smit@gmail.com
                    </Typography>
                  </div>
                </MenuItem>
                <MenuItem value={30}>
                  <div style={{ display: "flex" }}>
                    <Avatar
                      alt="Remy Sharp"
                      src="/avatar.png"
                      sx={{ width: 20, height: 20 }}
                      variant="square"
                    />

                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "12px", color: "black" }}
                    >
                      Lacy Clark
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: "12px", color: "lightgray" }}
                    >
                      lacy.clark@gmail.com
                    </Typography>
                  </div>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Typography
            variant="subtitle2"
            sx={{ fontSize: "18px", color: "black" }}
          >
            Manage Employee
          </Typography>
          <Box
            sx={{
              border: "1px solid lightgray",
              margin: "5px 15px",
              padding: "15px",
              backgroundColor: "#F4F8FF",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ fontSize: "14px", color: "#1E5DFF" }}
            >
              User Role
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ fontSize: "12px", color: "lightgray" }}
            >
              Select the role that applies to the duties of this employee within
              the Findpro App. This will dictate the parameters by which they
              can make changes in the company
            </Typography>
            <Box sx={{ margin: "5px 0px" }}>
              <CustomSelect data={consultants} placeholder={"Recruiter"} />
            </Box>
          </Box>
          <Divider light sx={{ margin: "15px 0px" }} />
          <Typography
            variant="subtitle2"
            sx={{ fontSize: "18px", color: "black" }}
          >
            Login Details
          </Typography>
          <Box sx={{ margin: "15px 15px" }}>
            <Typography
              variant="subtitle2"
              sx={{ fontSize: "14px", color: "black" }}
            >
              Email
            </Typography>
            <FormControl
              variant="outlined"
              className={classes.alEmailFormControl}
            >
              <OutlinedInput
                id="outlined-adornment-password"
                className={classes.alEmailInput}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      //   onClick={handleClickShowPassword}
                      //   onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontSize: "12px", color: "#ABABAB" }}
                      >
                        PENDING INVITE
                      </Typography>
                      <CheckIcon />
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="eg:yourmail@mail.com"
              />
            </FormControl>
            <Link href="#" variant="body2" sx={{ fontSize: "14px" }}>
              Resend invite
            </Link>
          </Box>
          <button
            style={{
              border: "1px solid gray",
              padding: "4px",
              background: "black",
              color: "white",
              width: "100%",
            }}
          >
            Invite Team Member
          </button>
        </DialogContent>
      </Dialog> */}

        <CustomDialog
          open={addEmployeeDialogOpen}
          setOpen={setAddEmployeeDialogOpen}
          title={<AddTeamMembersDialogueTittle title="Manage Team Members" />}
          content={
            <AddTeamMembers
              isAdmin={isAdmin}
              isRecruiter={isRecruiter}
              isinterviwer={isinterviwer}
              isHr={isHr}
              handleDialoguesAndApi={handleDialoguesAndApi}
            />
          }
          className={classes.customDialog}
        />

        <CustomDialog
          open={editEmployeeDialogOpen}
          setOpen={setEditEmployeeDialogOpen}
          title={
            <div className="text-[16px] font-semibold text-center">
              Edit Employee
            </div>
          }
          // title={<AddConsultantDialogTitle title="Add Consultant" />}
          content={
            <AddTeamMembers
              handleDialoguesAndApi={handleDialoguesAndApi}
              selectedEmployee={selectedEmployee}
              isAdmin={isAdmin}
              isRecruiter={isRecruiter}
              isinterviwer={isinterviwer}
              isHr={isHr}
            />
          }
        />

        {deleteJobDialogOpen && (
          <DeleteEmployeeDialog
            open={deleteJobDialogOpen}
            setOpen={setDeleteJobDialogOpen}
            employeeDetails={{
              employeeId: selectedEmployee?._id,
              employeeName: selectedEmployee?.firstName,
              status: selectedEmployee?.status,
            }}
            onDeleteClick={(event) => onDeleteClick(selectedEmployee)}
            deleteLoading={deleteActionLoading}
            deleteCompleted={deleteActionCompleted}
            deleteError={deleteActionError}
          />
        )}
      </Grid>
    </>
  );
};

export default ManageAgency;
