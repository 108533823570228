const prodOrigins = ["https://findpros.ai", "https://www.findpros.ai"];

export const prodConfigs = {
  /** This is production */
  NeAPIUrl: "https://ne.findpros.ai",
  APIUrl: "/api",
  homeUrl: "https://app.findpros.ai/",
  linkedinAuthHome: prodOrigins.includes(window.location.origin) ?  `https://app.findpros.ai/api` : `${window.location.origin}/api`,// for ex: https://dev.findpro.ai/api"
  paymentConfig: {
    // clientKey: "Ae02CQXRyCpBXHDr3MDORbIiofE-f6DD9s5blhbGJv4EoygdgAC4og00-NirrSQBQpYj2yEXjrPm-p9T"
    clientKey: "AYLLD26Pw4FRzuHY4_9fm4nHXplGnHSKUSizF1AEY09NoHLVLdZ3UgAFoUrk0cNjuVmRIqxvZAFyx0zz"
  },
  dd:{
    applicationId: '4fa50b9f-7e35-4402-9456-84c89968266d',
    clientToken: 'pub598af69fbd82106835626bac835e0158'
  }
};
