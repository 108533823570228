import {
  Button,
  CircularProgress,
  DialogContent,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import CustomDialog from "app/common/modal-wrapper/modal.wrapper.component";
import classnames from "classnames";
import "../../common/bulkUpload/bulkUpload.scss";
import { useSnackbar } from "notistack";
import Trash from "../../../images/trash.svg";
import {bulkUpload} from "./manage-employers.service"

const XLSX = require("sheetjs-style");

export default function BulkUploadEmployer(props) {
  const inputRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  const [isBulkUploadInProgress, setIsBulkUploadInProgress] = useState(false);

    let initialFormState = {
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
      personalInfo: {
        state: "",
        country: "",
      },
    };

    const [initialValues, setInitialValues] = useState({
      ...initialFormState,
    });

   const isBulkRequestDisabled = () => {
     const ul = uploadedData.slice(1).filter((d) => !d.includes(""));
     return ul.length !== uploadedData.slice(1).length;
   };


  const getTableBody = () => {
    return (uploadedData?.length > 1 && uploadedData.slice(1)) || [];
  };

  const onFileUpload = async (event) => {
    setUploadedData([]);
    const file = event.target.files[0];
    if (file.name.split(".")[file.name.split(".")?.length - 1] !== "xlsx") {
      enqueueSnackbar(
        "Invalid file format. Please use the provided template.",
        {
          variant: "error",
        }
      );
      return;
    }
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

      const validHeaders = [
        "First Name",
        "Last Name",
        "Email",
        "Company Name",
        "State",
        "Country",
      ];
      let isValidHeaders = true;

      dataParse[0]?.forEach((data) => {
        if (!validHeaders.includes(data)) isValidHeaders = false;
      });

      // @ts-ignore
      if (!dataParse[1]) {
        enqueueSnackbar("Please upload template wih atleast one record.", {
          variant: "error",
        });
        return;
      }

      if (!isValidHeaders) {
        enqueueSnackbar(
          "Column header mismatch. Please use the provided template.",
          {
            variant: "error",
          }
        );
        return;
      }

      // // console.log("dataParse ", dataParse);
      setUploadedData(dataParse.filter((dp) => dp.length === 6));
    };
    reader.readAsBinaryString(file);
    event.currentTarget.value = null;
  };

   const getFormattedData = (data) => {
     let formattedData = [];
     [...data.slice(1)].forEach((d) => {
       formattedData.push({
         ...initialValues,
         firstName: d[0],
         lastName: d[1],
         email: d[2],
         email_id: d[2],
         personalInfo: {
           state: d[5],
           country: d[4],
         },
         companyName: d[3].toString(),
       });
     });
     return formattedData;
   };

   const handleBulkUpload = (payload) => {
    // // console.log("payload", payload)
    setIsBulkUploadInProgress(true)
    bulkUpload(payload)
      .then((res) => {
        // // console.log("** res", res);
        if (res.success) {
          enqueueSnackbar(
            `Employers added successfully!`,
            { variant: "success" }
          );
        } else
          enqueueSnackbar("Problem adding employers!", {
            variant: "error",
          });
      })
      .catch((err) => {
        enqueueSnackbar("Problem adding employers!", {
          variant: "error",
        });
        console.error(err);
      })
      .finally(() => {
setIsBulkUploadInProgress(false);
setShowBulkModal(false)
props.createdNewEmployer();
      });
    //  setIsBulkUploadInProgress(true);
    //  bulkUploadCandidates(payload)
    //    .then((res) => {
    //      if (res) {
    //        const { existedUsers = [] } = res;
    //        if (!showBulkModal && existedUsers.length === 1) {
    //          enqueueSnackbar(`Candidate with ${existedUsers[0]} exists!`, {
    //            variant: "error",
    //          });
    //        }
    //        if (showBulkModal && existedUsers.length > 0) {
    //          const eu = uploadedData
    //            .splice(1)
    //            .filter((ud) => existedUsers.includes(ud[2]));
    //          setUploadedData([uploadedData[0], ...eu]);
    //          enqueueSnackbar(
    //            `${existedUsers.length} user${
    //              existedUsers.length > 1 ? "s" : ""
    //            } already exists. Change email or remove the user from the bulk upload list`,
    //            {
    //              variant: "error",
    //            }
    //          );
    //          fetchCandidatesList();
    //          return;
    //        } else {
    //          enqueueSnackbar("Candidates List Added Successfully!", {
    //            variant: "success",
    //          });
    //        }

    //        setShowBulkModal(false);
    //        setUploadedData([]);
    //        setShowModal(false);
    //        fetchCandidatesList();
    //      } else {
    //        enqueueSnackbar("Problem in adding Candidates list!", {
    //          variant: "error",
    //        });
    //      }
    //    })
    //    .catch((err) => {
    //      console.error(err);
    //      enqueueSnackbar("Problem in adding Candidates list!", {
    //        variant: "error",
    //      });
    //    })
    //    .finally(() => setIsBulkUploadInProgress(false));
   };

  return (
    <>
      <Button
        sx={{ bottom: "10px" }}
        onClick={() => setShowBulkModal(true)}
        variant="contained"
        type="button"
        className="pull-right"
      >
        Bulk Upload
      </Button>

      <CustomDialog
        open={showBulkModal}
        setOpen={(isOpen, reason) => {
          if (reason && reason === "backdropClick") return;
          setShowBulkModal(isOpen);
          setUploadedData([]);
        }}
        title={uploadedData?.length > 0 ? "Review Bulk Upload" : "Bulk Upload"}
        className={classnames("bulk-upload-modal", {
          "bulk-file-uploded": uploadedData?.length > 0,
        })}
      >
        <DialogContent
          dividers
          style={{
            padding: "1.5rem 3rem  3rem 3rem",
          }}
        >
          {isBulkUploadInProgress && (
            <div
              style={{
                position: "absolute",
                display: "flex",
                width: "90%",
                height: "100%",
                zIndex: "1",
              }}
            >
              <CircularProgress
                sx={{ display: "flex", margin: "auto", height: "100%" }}
              />
            </div>
          )}
          <div className="d-flex justify-content-between w-100">
            <span>Upload excel</span>
            <a target="_blank" href="/Employer_Bulk_Upload_Template.xlsx">
              Download Template
            </a>
          </div>
          <div className="intro-container d-flex justify-content-center w-100">
            <div className="mt-3 w-100">
              <span style={{ opacity: "0.7" }}>
                Attach the given template with data filled
              </span>
              <button
                type="button"
                className="rewmp-btn btn btn-light"
                style={{
                  padding: "1rem",
                  fontSize: "14px",
                  boxShadow: "0px 4px 19px rgba(147, 114, 65, 0.3)",
                }}
                // @ts-ignore
                onClick={() => inputRef?.current?.click()}
              >
                Browse
              </button>
            </div>
            <input
              type="file"
              ref={inputRef}
              className="site-button form-control"
              id="customFile"
              accept=".xlsx"
              onChange={onFileUpload}
              style={{
                display: "none",
              }}
            />
          </div>
          {uploadedData?.length > 0 && (
            <>
              <p
                style={{
                  margin: "2rem 0",
                  fontSize: "14px",
                  opacity: "0.7",
                }}
              >
                {uploadedData?.length - 1} candidates added
              </p>
              <div className="revamp-table mt-4">
                <div className="revamp-table__header">
                  {uploadedData[0]?.map((d) => (
                    <span>{d}</span>
                  ))}
                  <span> </span>
                </div>
                <div className="revamp-table__body">
                  {getTableBody().map((body, idx) => (
                    <div className="revamp-table__row">
                      {body.map((d, i) => {
                        if (i === 0 || i === 1 || i === 2 || i === 3) {
                          return (
                            <input
                              type="text"
                              value={d}
                              onChange={({ target }) => {
                                const cl = [...uploadedData];
                                cl[idx + 1][i] = target.value;
                                setUploadedData(cl);
                              }}
                            />
                          );
                        } else return <span>{d}</span>;
                      })}
                      <span>
                        <img
                          src={Trash}
                          alt="delete"
                          onClick={() => {
                            let pf = [...uploadedData];
                            pf.splice(idx + 1, 1);
                            pf = pf?.length === 1 ? [] : pf;
                            setUploadedData([...pf]);
                          }}
                        />
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <button
                type="button"
                className="rewmp-btn btn btn-light pull-right"
                style={{
                  padding: "1rem",
                  margin: "1rem 2rem",
                  fontSize: "14px",
                  boxShadow: "0px 4px 19px rgba(147, 114, 65, 0.3)",
                }}
                // @ts-ignore
                onClick={() => {
                  const payload = getFormattedData(uploadedData);
                  handleBulkUpload(payload);
                }}
                // disabled={isBulkRequestDisabled()}
              >
                Send Bulk Request
              </button>
            </>
          )}
        </DialogContent>
      </CustomDialog>
    </>
  );
}
