import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Typography,
  Link,
  Grid,
  CircularProgress,
  Alert,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Line } from "app/v2/Pages/common";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SearchIcon from "@mui/icons-material/Search";
import { Pagination } from "@mui/material";

import {
  Overview,
  WorkHistory,
  EducationAndCertification,
  LanguageProficiency,
  Awards,
  Testimonials,
  Resumes,
  SectionTitleIcon,
  ContactPage,
} from "./components";
import SkillsPicker from "app/v2/Pages/common/SkillsPicker";

import { IconLightUser } from "app/v2/Pages/SVGs/IconLightUser";
import { IconLightFile } from "app/v2/Pages/SVGs/IconLightFile";
import img_icon_light_arrow_top from "../../images/img_icon_light_arrow_top.svg";

import { getCandidateInfo } from "app/common/candidate-info/candidate-info.service";
import storageUtil from "app/common/utils/storageUtil";
import {
  isValidArray,
  scrollToTop,
  isValidString,
  isValidObject,
} from "app/v2/Pages/Utils/utilFunctions";
import { messages } from "app/v2/Pages/Utils/constants";
import {
  fetchContractTypesThunk,
  fetchExperiencesThunk,
  fetchSkillsThunk,
  fetchIndustryThunk,
} from "app/v2/store/features/misc/miscThunk";
import {
  getPostedJobsThunk,
  getMyJobsThunk,
} from "app/v2/store/features/employer/jobFeed/jobFeed.thunk";
import { useSnackbar } from "notistack";
import {
  deleteUserApi,
  getAllAcceptedJobs,
  getAllJobsApplied,
  getConsultantDetail,
  restrictUserApi,
} from "app/v2/services/admin/manageConsultants.service";
import SocialShare from "../../common/SocialShare";
import CustomButton from "app/v2/components/CustomButton";
import configs from "../../Utils/configs";
import CustomTooltip from "app/v2/components/CustomTooltip";
import JobsAppliedComponent from "./JobsAppliedComponent";
import AcceptedJobsComponent from "./AcceptedJobsComponent";
import CustomAutocomplete from "app/v2/components/filters/CustomAutocomplete";
import CustomDialog from "app/v2/components/CustomDialog";
import Title from "../../common/Title";
import DeleteEmployeeDialog from "../../Employer/common/DeleteEmployeeDialog";
const useStyles = makeStyles((theme) => ({
  leftView: {
    padding: "24px 48px",
    width: "291px",
    boxSizing: "content-box",
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  backDiv: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "36px",
  },
  backIcon: {
    cursor: "pointer",
    marginRight: "9px",
    position: "relative",
    right: "5px",
  },

  jobPostPreviewText: {
    fontWeight: 600,
    fontSize: "12px",
    color: theme.palette.secondary.main,
    marginBottom: "7px",
  },
  jobPreviewDiv: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    columnGap: "12px",
    marginTop: "7px",
    marginBottom: "36px",
  },
  navItemsDiv: {
    display: "flex",
    flexDirection: "column",
    rowGap: "26px",
    marginTop: "36px",
    marginLeft: "20px",
  },
  navItemLinkDiv: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    columnGap: "12px",
  },
  navItemTitle: {
    fontSize: "16px",
    fontWeight: 600,
  },
  selectedNavItem: {
    color: `${theme.palette.common.black} !important`,
  },
  unselectedNavItem: {
    color: `${theme.palette.secondary.light} !important`,
  },
  selectedNavItemForTeamIcon: {
    color: `${theme.palette.primary.main} !important`,
  },
  unselectedNavItemForTeamIcon: {
    color: `${theme.palette.success.main} !important`,
  },
  rightView: {
    borderLeft: "1px solid #EDEDED",
    marginLeft: "387px", // left view's total width since its fixed.
    padding: "48px",
    display: "flex",
    flexDirection: "column",
    rowGap: "32px",
    [theme.breakpoints.down("1440")]: {
      width: "900px",
    },
    [theme.breakpoints.down("1385")]: {
      width: "800px",
    },
    [theme.breakpoints.down("1285")]: {
      width: "700px",
    },
    [theme.breakpoints.down("1185")]: {
      width: "600px",
    },
  },
  rightArrowIcon: {
    marginLeft: "10px",
  },

  primaryButton: {
    width: "100%",
    padding: "12px",
    fontSize: "16px",
    fontWeight: 600,
  },
  restrictButton: {
    width: "100%",
    padding: "12px",
    fontSize: "16px",
    fontWeight: 600,
    border: "1px solid #EDEDED",
  },
  secondaryButton: {
    width: "100%",
    maxWidth: "138px",
    padding: "12px",
    fontSize: "14px",
    fontWeight: 600,
    border: "1px solid #EDEDED",
  },
  secondaryActionsContainer: {
    display: "flex",
    justifyContent: "space-between",
    columnGap: "16px",
  },
  buttonIcon: {
    marginLeft: "10px",
  },

  tabs: {
    display: "flex",
    marginTop: "20px",
  },
  tabButton: {
    padding: "10px 20px",
    marginRight: "10px",
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: 400,
    position: "relative",
  },
  activeTab: {
    fontWeight: "bold",
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: "-5px",
      left: 0,
      width: "100%",
      height: "2px",
      backgroundColor: "#000",
    },
  },
  sortBySelect: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #EDEDED",
    },
    "& .MuiSelect-select": {
      fontSize: "12px",
      display: "flex",
      columnGap: "8px",
    },
  },
  sortByMenuItem: {
    fontSize: "12px",
    display: "flex",
    columnGap: "8px",
  },
  sortMenuItemIcon: {
    width: "17px",
    height: "17px",
  },

  searchBoxOuterDiv: {
    maxWidth: "477px",
    width: "60%",
    height: "60px",

    display: "flex",
    columnGap: "12px",
    alignItems: "center",
    // margin: "0 24px 16px auto",
  },
  searchBox: {
    width: "100%",
  },
  paginationContainer: {
    float: "right",
    marginBottom: "16px",
    paddingRight: "38px",
  },
}));

const userprofileDummyData = {
  overview: {
    _id: "642cf1e53adee0d5d6c2c1d6", // consultant/_id
    image: "", // not available
    first_name: "Lacy", // consultant/first_name
    last_name: "Clark", // consultant/last_name
    jobTitle: "FrontEnd Developer", // consultant/profile_info/profile_summary/headline
    linkedinUrl: "https://linkedin.com", // not available
    facebookUrl: "https://facebook.com", // not available
    qrCode: "https://dev.findpro.ai/consultant/", // not available
    activeTime: "3 hours ago", // consultant/profile_info/updatedAt or consultant/personal_info/updatedAt ?
    status: "available", // not available
    salaryDetails: {
      type: "Hourly",
      fixedRate: 24,
    }, // consultant/profile_info/profile_summary/rate -> but incorrect structure
    experienceLevel: "Entry Level (1 - 2 years)", // not available
    availability: "Full Time", // consultant/profile_info/profile_summary/availability -> But discuss with edward about how at few places we receive an id for this(eg contractType id).
    country: "Singapore", // consultant/personal_info/country
    city: "Singapore City", // consultant/personal_info/city
    state: "Central Singapore", // consultant/personal_info/state
    timezone: "Singapore Standard Time (GMT + 8.0)", // not available
    // not available
    summary:
      "Nullam neque donec quam auctor porttitor. Tortor aliquet sit aliquet vel morbi commodo adipiscing. Enim turpis nisl rhoncus lectus cursus justo placerat magna. Morbi elementum scelerisque felis eros amet vel pellentesque et. Urna curabitur nisi consequat mollis arcu eu nunc dictum. Dignissim id aliquam justo ut ultrices pharetra. Sit est suspendisse laoreet sed. Tristique ut pulvinar sagittis ultricies. Diam ut vestibulum egestas ipsum elementum mi in posuere neque felis risus in egestas rhoncus.",
    videoResumeAvailable: true, // consultant/profile_info/self_intro ?
    backgroundCheck: true, // consultant/verified ?
  },
  skills: [
    {
      skillID: "646f1c2f96656ed53aa3250d",
      skillLevelID: "649a7fce75ed0920604c595d",
    },
    {
      skillID: "646f1c2f96656ed53aa3238b",
      skillLevelID: "649a7fa875ed0920604c595b",
    },
    {
      skillID: "646f1c2f96656ed53aa324a3",
      skillLevelID: "649a7fbd75ed0920604c595c",
    },
  ], // consultant/profile_info/skillsets or consultant/skills?
  workHistory: [
    {
      projectName: "Navigation App UI and Development 1",
      clientName: "Fiat Australia", // client or company name
      projectBrief:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque.",
      position: "Frontend Web Developer",
      employmentType: "Full Time",
      country: "Singapore",
      city: "Singapore City",
      state: "Central Singapore",
      timezone: "Singapore Standard Time (GMT + 8.0)",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "March",
          year: "2023",
        },
        isCurrentlyEmployed: false, // if true,end key will contain empty values for month and year.for month and year.
      },
      industry: "Automotive",
      skills: [
        {
          skillID: "646f1c2f96656ed53aa3250d",
          skillLevelID: "649a7fce75ed0920604c595d",
        },
        {
          skillID: "646f1c2f96656ed53aa3238b",
          skillLevelID: "649a7fa875ed0920604c595b",
        },
        {
          skillID: "646f1c2f96656ed53aa324a3",
          skillLevelID: "649a7fbd75ed0920604c595c",
        },
      ],
    },
    {
      projectName: "Navigation App UI and Development 2",
      clientName: "Fiat Australia", // client or company name
      projectBrief:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque.",
      position: "Frontend Web Developer",
      employmentType: "Full Time",
      country: "Singapore",
      city: "Singapore City",
      state: "Central Singapore",
      timezone: "Singapore Standard Time (GMT + 8.0)",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "March",
          year: "2023",
        },
        isCurrentlyEmployed: false, // if true,end key will contain empty values for month and year.
      },
      industry: "Automotive",
      skills: [
        {
          skillID: "646f1c2f96656ed53aa3250d",
          skillLevelID: "649a7fce75ed0920604c595d",
        },
        {
          skillID: "646f1c2f96656ed53aa3238b",
          skillLevelID: "649a7fa875ed0920604c595b",
        },
        {
          skillID: "646f1c2f96656ed53aa324a3",
          skillLevelID: "649a7fbd75ed0920604c595c",
        },
      ],
    },
    {
      projectName: "Navigation App UI and Development 3",
      clientName: "Fiat Australia", // client or company name
      projectBrief:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque.",
      position: "Frontend Web Developer",
      employmentType: "Full Time",
      country: "Singapore",
      city: "Singapore City",
      state: "Central Singapore",
      timezone: "Singapore Standard Time (GMT + 8.0)",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "",
          year: "",
        },
        isCurrentlyEmployed: true, // if true,end key will contain empty values for month and year.
      },
      industry: "Automotive",
      skills: [
        {
          skillID: "646f1c2f96656ed53aa3250d",
          skillLevelID: "649a7fce75ed0920604c595d",
        },
        {
          skillID: "646f1c2f96656ed53aa3238b",
          skillLevelID: "649a7fa875ed0920604c595b",
        },
        {
          skillID: "646f1c2f96656ed53aa324a3",
          skillLevelID: "649a7fbd75ed0920604c595c",
        },
      ],
    },
    {
      projectName: "Navigation App UI and Development 4",
      clientName: "Fiat Australia", // client or company name
      projectBrief:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque.",
      position: "Frontend Web Developer",
      employmentType: "Full Time",
      country: "Singapore",
      city: "Singapore City",
      state: "Central Singapore",
      timezone: "Singapore Standard Time (GMT + 8.0)",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "March",
          year: "2023",
        },
        isCurrentlyEmployed: false, // if true,end key will contain empty values for month and year.
      },
      industry: "Automotive",
      skills: [
        {
          skillID: "646f1c2f96656ed53aa3250d",
          skillLevelID: "649a7fce75ed0920604c595d",
        },
        {
          skillID: "646f1c2f96656ed53aa3238b",
          skillLevelID: "649a7fa875ed0920604c595b",
        },
        {
          skillID: "646f1c2f96656ed53aa324a3",
          skillLevelID: "649a7fbd75ed0920604c595c",
        },
      ],
    },
    {
      projectName: "Navigation App UI and Development 5",
      clientName: "Fiat Australia", // client or company name
      projectBrief:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque.",
      position: "Frontend Web Developer",
      employmentType: "Full Time",
      country: "Singapore",
      city: "Singapore City",
      state: "Central Singapore",
      timezone: "Singapore Standard Time (GMT + 8.0)",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "March",
          year: "2023",
        },
        isCurrentlyEmployed: false, // if true,end key will contain empty values for month and year.
      },
      industry: "Automotive",
      skills: [
        {
          skillID: "646f1c2f96656ed53aa3250d",
          skillLevelID: "649a7fce75ed0920604c595d",
        },
        {
          skillID: "646f1c2f96656ed53aa3238b",
          skillLevelID: "649a7fa875ed0920604c595b",
        },
        {
          skillID: "646f1c2f96656ed53aa324a3",
          skillLevelID: "649a7fbd75ed0920604c595c",
        },
      ],
    },
  ], // consultant/profile_info/work_history or consultant/workHistory?
  education: [
    {
      certificateTitle: "Post-Graduate Certificate",
      universityName: "Mt. Albany University",
      courseName: "Information & Human Design",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "March",
          year: "2023",
        },
        isCurrentlyPursuing: false, // if true,end key will contain empty values for month and year.
      },
    },
    {
      certificateTitle: "Bachelor’s Diploma",
      universityName: "Mt. Albany University",
      courseName: "Information & Technology",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "",
          year: "",
        },
        isCurrentlyPursuing: true, // if true,end key will contain empty values for month and year.
      },
    },
    {
      certificateTitle: "High School Diploma",
      universityName: "Mt. Albany University",
      courseName: "Information & Technology",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "March",
          year: "2023",
        },
        isCurrentlyPursuing: false, // if true,end key will contain empty values for month and year.
      },
    },
  ], // consultant/profile_info/education
  certification: [
    {
      certificateTitle: "CISCO Computer Component Build Certification",
      issuedBy: "Mt. Albany University",
      courseName: "Information & Human Design",
      issuedOn: { month: "May", year: "2022" },
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vulputate, nunc quis bibendum blandit, mi lacus consequat arcu, ut scelerisque ex velit ac sapien. Integer facilisis quam et massa dignissim, in blandit tortor aliquam. Sed at libero a nunc tincidunt gravida. Ut at scelerisque ipsum. Duis sit amet quam vel mauris aliquet.",
    },
  ], // not available
  languages: [
    {
      languageCertificateTitle: "English Level9",
      issuedBy: "Princeton English Examination",
      issuedOn: {
        month: "April",
        year: "2022",
      },
    },
    {
      languageCertificateTitle: "English Level9",
      issuedBy: "Princeton English Examination",
      issuedOn: {
        month: "April",
        year: "2022",
      },
    },
  ], // consultant/languages
  awards: [
    {
      awardTitle: "English Level9",
      issuedBy: "Princeton English Examination",
      issuedOn: {
        month: "April",
        year: "2022",
      },
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vulputate, nunc quis bibendum blandit, mi lacus consequat arcu, ut scelerisque ex velit ac sapien. Integer facilisis quam et massa dignissim, in blandit tortor aliquam. Sed at libero a nunc tincidunt gravida. Ut at scelerisque ipsum. Duis sit amet quam vel mauris aliquet.",
    },
    {
      awardTitle: "English Level9",
      issuedBy: "Princeton English Examination",
      issuedOn: {
        month: "April",
        year: "2022",
      },
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vulputate, nunc quis bibendum blandit, mi lacus consequat arcu, ut scelerisque ex velit ac sapien. Integer facilisis quam et massa dignissim, in blandit tortor aliquam. Sed at libero a nunc tincidunt gravida. Ut at scelerisque ipsum. Duis sit amet quam vel mauris aliquet.",
    },
  ], // consultant/awards
  testimonials: [
    {
      testimony:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque. Pretium habitant sollicitudin semper eu laoreet nulla diam. Urna massa ultrices tortor et pulvinar egestas nibh.",
      givenBy: "Ericka Santiago",
      role: "Supervisor",
      projectName: "Project Alpha - Telecommunications Inc.",
    },
    {
      testimony:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque. Pretium habitant sollicitudin semper eu laoreet nulla diam. Urna massa ultrices tortor et pulvinar egestas nibh.",
      givenBy: "Ericka Santiago",
      role: "Supervisor",
      projectName: "Project Alpha - Telecommunications Inc.",
    },
    {
      testimony:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque. Pretium habitant sollicitudin semper eu laoreet nulla diam. Urna massa ultrices tortor et pulvinar egestas nibh.",
      givenBy: "Ericka Santiago",
      role: "Supervisor",
      projectName: "Project Alpha - Telecommunications Inc.",
    },
    {
      testimony:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque. Pretium habitant sollicitudin semper eu laoreet nulla diam. Urna massa ultrices tortor et pulvinar egestas nibh.",
      givenBy: "Ericka Santiago",
      role: "Supervisor",
      projectName: "Project Alpha - Telecommunications Inc.",
    },
  ], // consultant/profile_info/testimonials or consultant/testimonials?
  // consultant/profile_info/resume
  resumeDoc:
    "https://findpro-s3.s3.us-east-2.amazonaws.com/Metkel%20Isak%20Resume_1656535496051.docx", // https://findpro-s3.s3.us-east-2.amazonaws.com/1641827500408.pdf
  // consultant/profile_info/self_intro
  videoResume:
    "https://findpro-s3.s3.us-east-2.amazonaws.com/1641827327575.mp4",
};

const leftNavData = [
  {
    icon: <IconLightUser className="w-[29px] h-[29px]" />,
    title: "Consultant Overview",
    component: ({
      consultantData,
      activeJobs,
      currentJob,
      experiences,
      contractTypes,
    }) => (
      <Overview
        currentJob={currentJob}
        activeJobs={activeJobs}
        data={{
          ...consultantData?.overview,
          matchRate: consultantData?.matchRate,
          videoResumeAvailable: isValidString(consultantData?.videoResume),
        }}
        socialLinks={consultantData?.socialLinks}
        experienceTypes={experiences}
        contractTypes={contractTypes}
      />
    ),
  },
  {
    icon: <IconLightFile className="w-[29px] h-[29px]" />,
    title: "Skills",
    component: ({ consultantData }) => (
      <SkillsPicker
        id="clientConsultantDetailsSP" // SP - Skills Picker
        data={consultantData?.consultantDetails?.skills}
        readOnly={true}
      />
    ),
  },
  {
    icon: <img src="/dashboard.svg" width="29" height="29" />,
    title: "Work History & Industry",
    component: ({
      consultantData,
      skillIDToNameMapper,
      contractTypes,
      industryData,
    }) => (
      <WorkHistory
        data={consultantData?.consultantDetails?.workHistory}
        skillIDToNameMapper={skillIDToNameMapper}
        contractTypes={contractTypes}
        industryData={industryData}
      />
    ),
  },
  {
    icon: <img src="/award.svg" width="29" height="29" />,
    title: "Education & Certification",
    component: ({ consultantData }) => (
      <EducationAndCertification
        educationData={consultantData?.consultantDetails?.education}
        certificationData={consultantData?.consultantDetails?.certification}
      />
    ),
  },
  // {
  //   icon: <img src="/comment.svg" width="29" height="29" />,
  //   title: "Testimonials",
  //   component: ({ consultantData }) => (
  //     <Testimonials data={consultantData?.testimonials} />
  //   ),
  // },
  // Hidden for MVP
  {
    icon: <img src="/award.svg" width="29" height="29" />,
    title: "Language Proficiency",
    component: ({ consultantData }) => (
      <LanguageProficiency
        data={consultantData?.consultantDetails?.languages}
      />
    ),
  },
  {
    icon: <img src="/award.svg" width="29" height="29" />,
    title: "Awards",
    component: ({ consultantData }) => (
      <Awards data={consultantData?.consultantDetails?.awards} />
    ),
  },
  {
    icon: <img src="/project.svg" width="29" height="29" />,
    title: "Resume",
    component: ({ consultantData }) => (
      <Resumes
        doc={consultantData?.consultantDetails?.resumeDoc}
        video={consultantData?.consultantDetails?.videoResume}
      />
    ),
  },
];

const ConsultantDetail = ({ props }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const candidateId = props?.match?.params?.consultantID;
  const allJobsPerScroll = 8;
  console.log("candidateId", candidateId);

  const homeUrl = configs?.homeUrl;
  const publicClientDetailURL = candidateId
    ? homeUrl + `consultant-detail/${candidateId}`
    : null;

  const { experiences, contractTypes, skills, industryData } = useSelector(
    (state) => state.misc
  );
  const [postedJobs, setPostedJobs] = useState([]);
  const [activeJobs, setActiveJobs] = useState([]);
  const [currentJob, setCurrentJob] = useState();
  const postedJobsBuf = useSelector((state) => state.jobs.jobs.data);

  const [consultantData, setConsultantData] = useState(userprofileDummyData);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [jobID, setJobID] = useState();
  const [activeTab, setActiveTab] = useState("Overview"); // default to "Details" or whatever logic you have
  const sectionRefs = useRef([]);
  const [selectedNavItemIndex, setSelectedNavItemIndex] = useState(
    "nav-item-component-0"
  );
  const [sortByValue, setSortByValue] = useState("newest");

  const [skillIDToNameMapper, setSkillIDToNameMapper] = useState();
  const [initialRender, setInitialRender] = useState(true);

  const [acceptedJobsData, setAcceptedJobsData] = useState([]);
  const [acceptedjobsMessage, setAcceptedJobsMessage] = useState("");
  const [appliedJobsMessage, setAppliedJobsMessage] = useState("");
  const [jobsAppliedData, setJobsAppliedData] = useState([]);
  const [searchTextTags, setSearchTextTags] = useState([]);
  const [totalPagesCount, setTotalPagesCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState(consultantData?.status);
  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [deleteJobDialogOpen, setDeleteJobDialogOpen] = useState(false);
  const [deleteActionLoading, setDeleteActionLoading] = useState(false);
  const [deleteActionCompleted, setDeleteActionCompleted] = useState(false);
  const [deleteActionError, setDeleteActionError] = useState(false);
  const [action, setAction] = useState("");

  const onNavItemClick = (navItemIndex) => {
    const element = sectionRefs.current[navItemIndex];
    const elementTop = element.getBoundingClientRect().top + window.pageYOffset;
    const container = window;
    const offset = -180;
    container.scrollTo({ top: elementTop + offset, behavior: "smooth" });
  };

  const isLastNavItemComponent = (index) => {
    return index === leftNavData?.length - 1;
  };

  ///////// scroll
  const handleScroll = useCallback(() => {
    const pageYOffset = window.pageYOffset + 180;

    sectionRefs.current.forEach((section) => {
      const sectionOffsetTop = section?.offsetTop;
      const sectionHeight = section?.offsetHeight;

      if (
        pageYOffset >= sectionOffsetTop &&
        pageYOffset < sectionOffsetTop + sectionHeight
      )
        setSelectedNavItemIndex(section.id);
    });
  }, [selectedNavItemIndex]);

  const fetchAppliedJobs = async (currentPage) => {
    setDataLoading(true);

    const data = {
      limit: allJobsPerScroll,
      offset: currentPage - 1,
      order: sortByValue,
      searchTags: searchTextTags,
      iD: candidateId,
    };

    getAllJobsApplied(data)
      .then((response) => {
        let allJobsReieved = response?.data?.result;
        if (isValidArray(allJobsReieved)) {
          const totalClientCount = response?.data?.count;
          const pageCount =
            totalClientCount > 0
              ? Math.ceil(totalClientCount / allJobsPerScroll)
              : 0;
          setJobsAppliedData(allJobsReieved);
          setTotalPagesCount(pageCount);
          setInitialRender(false);
        } else {
          setAppliedJobsMessage(response?.data?.message);
        }
        setDataLoading(false);
      })
      .catch((error) => {
        setDataLoading(false);
        // setDataError(true);
      });
  };

  const fetchAcceptdJobs = async (currentPage) => {
    setDataLoading(true);

    const data = {
      limit: allJobsPerScroll,
      offset: currentPage - 1,
      order: sortByValue,
      searchTags: searchTextTags,
      iD: candidateId,
    };

    getAllAcceptedJobs(data)
      .then((response) => {
        let allJobsReieved = response?.data?.result;
        if (isValidArray(allJobsReieved)) {
          const totalClientCount = response?.data?.count;
          const pageCount =
            totalClientCount > 0
              ? Math.ceil(totalClientCount / allJobsPerScroll)
              : 0;
          setAcceptedJobsData(allJobsReieved);

          setTotalPagesCount(pageCount);
          setInitialRender(false);
        } else {
          setAcceptedJobsMessage(response?.data?.message);
        }
        setDataLoading(false);
      })
      .catch((error) => {
        setDataLoading(false);
        // setDataError(true);
      });
  };
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    fetchAcceptdJobs(value);
  };

  useEffect(() => {
    fetchAppliedJobs(currentPage);
  }, [sortByValue]);

  useEffect(() => {
    if (!initialRender) {
      setCurrentPage(1);
      fetchAppliedJobs(1);
    }
  }, [sortByValue]);

  const handlePageChange2 = (event, value) => {
    setCurrentPage(value);
    fetchAppliedJobs(value);
  };

  const restrictConsultant = (consultantId) => {
    let status = consultantData.status === "Active" ? "false" : "true";
    restrictUserApi(consultantId, status)
      .then((res) => {
        if (res.message) {
          const key = enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        }
      })
      .catch((err) => {
        if (err.message) {
          enqueueSnackbar(err.message, {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        }
      });
  };

  const deleteConsultant = (consultantId) => {
    deleteUserApi(consultantId)
      .then((res) => {
        if (res?.message) {
          const key = enqueueSnackbar(res?.message, {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
          history.push(`/admin/manage-consultants`);
        }
      })
      .catch((err) => {
        if (err.message) {
          enqueueSnackbar(err.message, {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        }
      });
  };

  useEffect(() => {
    fetchAcceptdJobs(currentPage);
  }, []);

  useEffect(() => {
    if (!initialRender) {
      setCurrentPage(1);
      fetchAcceptdJobs(1);
    }
  }, [sortByValue]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  ///////// scroll

  useEffect(() => {
    if (isValidArray(skills)) {
      let skillIDToNameMapper = {};
      skills?.forEach((skillEntry) => {
        skillIDToNameMapper[skillEntry?._id] = skillEntry?.name;
      });
      setSkillIDToNameMapper(skillIDToNameMapper);
    }
  }, [skills]);

  useEffect(() => {
    if (!skills?.length) dispatch(fetchSkillsThunk());
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
    if (!industryData?.length) dispatch(fetchIndustryThunk());
  }, [dispatch]);

  useEffect(() => {
    getConsultantDetail(candidateId)
      .then((res) => {
        const clientDataReceived = res?.consultant;
        // // console.log({ clientDataReceived });
        if (isValidObject(clientDataReceived)) {
          setConsultantData(clientDataReceived);
          setStatus(clientDataReceived?.status);
        } else setDataError(true);
        setDataLoading(false);
      })
      .catch((error) => {
        setDataLoading(false);
        setDataError(false);
        setConsultantData(userprofileDummyData);
      });
  }, []);

  useEffect(() => {
    if (!deleteJobDialogOpen) {
      // Setting back to default states- used when the dialog is closed.
      setDeleteActionLoading(false);
      setDeleteActionCompleted(false);
      setDeleteActionError(false);
      setAction("");
    }
  }, [deleteJobDialogOpen]);

  const SortBySelect = ({ selectedValue, onChange }) => {
    const classes = useStyles();

    const handleChange = (event) => {
      onChange(event.target.value);
    };

    return (
      <div className="flex gap-y-[8px] items-center text-[14px] text-[#8C8C8C] mb-[25px]">
        <div>Sort By:</div>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={selectedValue}
            onChange={handleChange}
            className={classes.sortBySelect}
          >
            <MenuItem value="newest" className={classes.sortByMenuItem}>
              Newest
            </MenuItem>
            <MenuItem value="ascending" className={classes.sortByMenuItem}>
              Ascending{" "}
              <ArrowDownwardIcon className={classes.sortMenuItemIcon} />
            </MenuItem>
            <MenuItem value="descending" className={classes.sortByMenuItem}>
              Descending{" "}
              <ArrowDownwardIcon className={classes.sortMenuItemIcon} />
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  };

  const renderTabContent = () => {
    if (activeTab === "Overview") {
      return leftNavData
        .filter((item) => item.title !== "Consultant Overview")
        .map((navItem, index) => (
          <div
            ref={(el) => (sectionRefs.current[index] = el)}
            className={`${isNotOverviewSection(navItem.title) && "px-[24px]"}`}
            id={`nav-item-component-${index}`}
            key={`nav-item-component-${index}`}
          >
            {isNotOverviewSection(navItem.title) ? (
              <SectionTitleIcon title={navItem.title} icon={navItem.icon} />
            ) : null}

            {navItem.component({
              consultantData,
              experiences,
              contractTypes,
              skillIDToNameMapper,
              industryData,
            })}
          </div>
        ));
    } else if (activeTab === "JobsApplied") {
      return (
        <div className="w-[full}">
          {" "}
          <h2>Jobs Applied</h2>
          <div className="flex justify-between">
            <SortBySelect
              selectedValue={sortByValue}
              onChange={(value) => setSortByValue(value)}
            />
            {/* <div className={classes.searchBoxOuterDiv}>
              <CustomAutocomplete
                id="searchTags"
                data={searchTextTags}
                placeholder="Search by invoice number and more"
                defaultSelection={searchTextTags}
                allowMultipleSelection={true}
                allowCustomUserInput={true}
                onChange={(e, val) => {
                  setSearchTextTags(val);
                }}
                customClasses={classes.searchBox}
                // showSearchIcon={true}
              />
              <SearchIcon
                onClick={() => {
                  setCurrentPage(1);
                  fetchAcceptdJobs(1);
                }}
                className="cursor-pointer ml-[8px]"
              />
              <CustomTooltip title="Note: For the search field, press the 'Enter' key to create the tag after entering it.">
                <div>
                  <InfoIcon />
                </div>
              </CustomTooltip>
            </div> */}
          </div>
          {isValidArray(jobsAppliedData) ? (
            jobsAppliedData.map((job, index) => (
              <>
                <JobsAppliedComponent
                  key={index}
                  currentPage={currentPage}
                  totalPagesCount={totalPagesCount}
                  handlePageChange2={handlePageChange2}
                  data={job}
                />
              </>
            ))
          ) : (
            <Alert severity="info">{appliedJobsMessage}</Alert>
          )}
          <Pagination
            color="primary"
            count={totalPagesCount}
            page={currentPage}
            onChange={handlePageChange2}
            classes={{ ul: classes.paginationContainer }}
          />
        </div>
      );
    } else if (activeTab === "AcceptedJobs") {
      return (
        <div className="w-full">
          {" "}
          <h2>Accepted Jobs</h2>
          <div className="flex justify-between">
            <SortBySelect
              selectedValue={sortByValue}
              onChange={(value) => setSortByValue(value)}
            />
            {/* <div className={classes.searchBoxOuterDiv}>
              <CustomAutocomplete
                id="searchTags"
                data={searchTextTags}
                placeholder="Search by invoice number and more"
                defaultSelection={searchTextTags}
                allowMultipleSelection={true}
                allowCustomUserInput={true}
                onChange={(e, val) => {
                  setSearchTextTags(val);
                }}
                customClasses={classes.searchBox}
                // showSearchIcon={true}
              />
              <SearchIcon
                onClick={() => {
                  setCurrentPage(1);
                  fetchAppliedJobs(1);
                }}
                className="cursor-pointer ml-[8px]"
              />
              <CustomTooltip title="Note: For the search field, press the 'Enter' key to create the tag after entering it.">
                <div>
                  <InfoIcon />
                </div>
              </CustomTooltip>
            </div> */}
          </div>
          {isValidArray(acceptedJobsData) ? (
            acceptedJobsData.map((job, index) => (
              <>
                <AcceptedJobsComponent
                  key={index}
                  currentPage={currentPage}
                  totalPagesCount={totalPagesCount}
                  handlePageChange={handlePageChange}
                  data={job?.jobDetails}
                  createdat={job?.createdAt}
                  updatedat={job?.updatedAt}
                />
              </>
            ))
          ) : (
            <Alert severity="info">{acceptedjobsMessage} </Alert>
          )}
          <Pagination
            color="primary"
            count={totalPagesCount}
            page={currentPage}
            onChange={handlePageChange}
            classes={{ ul: classes.paginationContainer }}
          />
        </div>
      );
    }
    return null;
  };

  const isNotOverviewSection = (title) => title !== "Consultant Overview";

  return (
    <>
      {dataLoading ? (
        <div className="flex justify-center items-center flex-1">
          <CircularProgress />
        </div>
      ) : (
        <>
          {dataError ? (
            <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
          ) : (
            <Grid container>
              <Grid item XS={3} className={classes.leftView}>
                <div className={classes.backDiv}>
                  <ArrowBackIcon
                    onClick={() => {}}
                    className={classes.backIcon}
                    onClick={() => {
                      history.goBack();
                    }}
                  />
                  <span>All Consultants</span>
                </div>

                <CustomButton
                  type="button1"
                  customClasses={classes.primaryButton}
                  onClick={() => {
                    setActionDialogOpen(true);
                  }}
                >
                  Contact
                  <img
                    src="/mail.png"
                    alt="mail-icon"
                    className={classes.buttonIcon}
                  />
                </CustomButton>

                <CustomButton
                  customClasses={classes.restrictButton}
                  onClick={() => {
                    setDeleteJobDialogOpen(true);
                    setAction("active");
                  }}
                >
                  {consultantData?.status === "Inactive"
                    ? "Activate"
                    : "Deactivate"}{" "}
                  <img
                    src="/deactivate.svg"
                    alt="deactivate-icon"
                    className={classes.buttonIcon}
                  />
                </CustomButton>
                <div className={classes.secondaryActionsContainer}>
                  <div className="flex-1">
                    {" "}
                    <CustomButton
                      customClasses={classes.secondaryButton}
                      onClick={() => {
                        // deleteConsultant(candidateId);
                        setDeleteJobDialogOpen(true);
                        setAction("delete");
                      }}
                    >
                      Delete
                      <img
                        src="/delete.svg"
                        alt="delete-icon"
                        className={classes.buttonIcon}
                      />
                    </CustomButton>
                  </div>
                  <CustomTooltip
                    arrow
                    title={<SocialShare shareLink={publicClientDetailURL} />}
                    bg="white"
                  >
                    <div className="flex-1">
                      <CustomButton
                        customClasses={classes.secondaryButton}
                        onClick={() => {}}
                      >
                        Share
                        <img
                          src="/share.svg"
                          alt="share-icon"
                          className={classes.buttonIcon}
                        />
                      </CustomButton>
                    </div>
                  </CustomTooltip>
                </div>

                {/* {jobID ? (
                  <div>
                    <Typography className={classes.jobPostPreviewText}>
                      JOB POST TO PREVIEW
                    </Typography>
                    <div className="text-[14px] font-semibold">
                      {currentJob?.jobNoAndTitle ?? "-"}
                    </div>
                    <button
                      className={classes.jobPreviewDiv}
                      onClick={() => {
                        history.push("/client/job-detail" + "/" + jobID);
                      }}
                    >
                      <VisibilityIcon fontSize="small" />
                      View job
                    </button>
                  </div>
                ) : (
                  <></>
                )} */}

                <hr />

                {/* <div className={classes.navItemsDiv}>
                  {leftNavData?.map((navItemDetails, index) => (
                    <Link
                      key={"l" + index}
                      className={clsx([
                        classes.navItemLinkDiv,
                        selectedNavItemIndex === `nav-item-component-${index}`
                          ? classes.selectedNavItem
                          : classes.unselectedNavItem,
                      ])}
                      onClick={() => {
                        // onNavItemClick(`nav-item-component-${index}`);
                        onNavItemClick(index);
                      }}
                    >
                      {navItemDetails?.icon}

                      <Typography className={classes.navItemTitle}>
                        {navItemDetails?.title}
                      </Typography>
                    </Link>
                  ))}
                </div> */}
              </Grid>
              <Grid item id="sectionContent" className={classes.rightView}>
                {/* {leftNavData?.map((navItemDetails, index) => (
                  <>
                    <div
                      ref={(el) => (sectionRefs.current[index] = el)}
                      className={`${
                        isNotOverviewSection(navItemDetails?.title) &&
                        "px-[24px]"
                      }`}
                      id={`nav-item-component-${index}`}
                      key={`nav-item-component-${index}`}
                    >
                      {isNotOverviewSection(navItemDetails?.title) ? (
                        <SectionTitleIcon
                          title={navItemDetails?.title}
                          icon={navItemDetails?.icon}
                        />
                      ) : (
                        <></>
                      )}

                      {navItemDetails?.component({
                        // currentJob,
                        // activeJobs,
                        consultantData,
                        experiences,
                        contractTypes,
                        skillIDToNameMapper,
                        industryData,
                      })}
                    </div>
                    
                  </>
                ))} */}

                <Overview
                  currentJob={currentJob}
                  activeJobs={activeJobs}
                  data={{
                    ...consultantData?.consultantDetails?.overview,
                    matchRate:
                      consultantData?.profile_info?.profile_summary?.match_rate,
                    videoResumeAvailable: isValidString(
                      consultantData?.videoResume
                    ),
                    createdAt: consultantData?.createdAt,
                    activeTime: consultantData?.lastLoggedIn,
                    status: consultantData?.status,
                    email: consultantData?.email,
                    _id: consultantData?._id,
                  }}
                  socialLinks={consultantData?.socialLinks}
                  experienceTypes={experiences}
                  contractTypes={contractTypes}
                />

                {/* Tab Navigation */}
                <div className={classes.tabs}>
                  <button
                    className={`${classes.tabButton} ${
                      activeTab === "Overview" ? classes.activeTab : ""
                    }`}
                    onClick={() => setActiveTab("Overview")}
                  >
                    Overview
                  </button>
                  <button
                    className={`${classes.tabButton} ${
                      activeTab === "JobsApplied" ? classes.activeTab : ""
                    }`}
                    onClick={() => {
                      setActiveTab("JobsApplied");
                      setSortByValue("latest");
                      setSearchTextTags([]);
                    }}
                  >
                    Jobs Applied
                  </button>
                  <button
                    className={`${classes.tabButton} ${
                      activeTab === "AcceptedJobs" ? classes.activeTab : ""
                    }`}
                    onClick={() => {
                      setActiveTab("AcceptedJobs");
                      setSortByValue("latest");
                      setSearchTextTags([]);
                    }}
                  >
                    Accepted Jobs
                  </button>
                </div>

                {/* Render the selected tab content */}
                <div>{renderTabContent()}</div>
                <div
                  className="flex items-center gap-x-[10px] pl-[12px] pt-[24px] cursor-pointer"
                  onClick={() => {
                    scrollToTop();
                  }}
                >
                  <img src={img_icon_light_arrow_top} />
                  <div className="font-semibold leading-[normal] text-base text-center text-gray-900">
                    Back to top
                  </div>
                </div>
              </Grid>
              <CustomDialog
                open={actionDialogOpen}
                setOpen={setActionDialogOpen}
                // noCloseAction={actionLoading}
                title={
                  <div className="text-[16px] font-semibold text-center">
                    Contact details
                  </div>
                }
                content={
                  <ContactPage
                    currentJob={currentJob}
                    activeJobs={activeJobs}
                    data={{
                      ...consultantData?.consultantDetails?.overview,
                      matchRate:
                        consultantData?.profile_info?.profile_summary
                          ?.match_rate,
                      videoResumeAvailable: isValidString(
                        consultantData?.videoResume
                      ),
                      email: consultantData?.email,
                      _id: consultantData._id,
                    }}
                    socialLinks={consultantData?.socialLinks}
                    experienceTypes={experiences}
                    contractTypes={contractTypes}
                  />
                }
              />

              {deleteJobDialogOpen && (
                <DeleteEmployeeDialog
                  open={deleteJobDialogOpen}
                  setOpen={setDeleteJobDialogOpen}
                  action={action}
                  employeeDetails={{
                    employeeId: candidateId,
                    employeeName:
                      consultantData?.consultantDetails?.overview?.first_name,
                    status: consultantData?.status,
                  }}
                  onDeleteClick={(event) =>
                    action === "delete"
                      ? deleteConsultant(candidateId)
                      : restrictConsultant(candidateId)
                  }
                  deleteLoading={deleteActionLoading}
                  deleteCompleted={deleteActionCompleted}
                  deleteError={deleteActionError}
                />
              )}
            </Grid>
          )}
        </>
      )}
    </>
  );
};

ConsultantDetail.propTypes = {};

export default ConsultantDetail;
