import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Pagination,
  Menu,
  MenuItem,
  IconButton,
  Fade,
  FormControl,
  Select,
} from "@mui/material";
import ContentLoader from "react-content-loader";

import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InfoIcon from "@mui/icons-material/Info";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import Title from "../../common/Title";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomButton from "app/v2/components/CustomButton";
import CustomDialog from "app/v2/components/CustomDialog";
import CustomAutocomplete from "app/v2/components/filters/CustomAutocomplete";
import CustomTooltip from "app/v2/components/CustomTooltip";
import DialogSuccessBody from "../../common/DialogSuccessBody";
import DialogLoadingBody from "../../common/DialogLoadingBody";
import DialogErrorBody from "../../common/DialogErrorBody";

import {
  isValidArray,
  isValidObject,
  isValidString,
} from "../../Utils/utilFunctions";
import { messages } from "../../Utils/constants";

import {
  getAllInvoices,
  recreateInvoice,
  cancelInvoice,
} from "app/v2/services/admin/invoices.service";
import FilePreview from "../../common/FilePreview";
import FilePreview2 from "../../common/FilePreview2";


const useStyles = makeStyles((theme) => ({
  sortAndSearchDiv: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 24px 16px 24px",
  },
  sortBySelect: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #EDEDED",
    },
    "& .MuiSelect-select": {
      fontSize: "12px",
      display: "flex",
      columnGap: "8px",
    },
  },
  sortByMenuItem: {
    fontSize: "12px",
    display: "flex",
    columnGap: "8px",
  },
  sortMenuItemIcon: {
    width: "17px",
    height: "17px",
  },
  searchBoxOuterDiv: {
    maxWidth: "477px",
    width: "100%",
    display: "flex",
    columnGap: "12px",
    alignItems: "center",
    // margin: "0 24px 16px auto",
  },
  searchBox: {
    width: "100%",
  },
  userImage: {
    width: "36px",
    height: "36px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
  consultantCell: {
    display: "flex !important",
    columnGap: "8px",
    alignItems: "center",
  },
  viewDetailsButton: {
    fontWeight: 600,
    fontSize: "14px",
  },
  paginationContainer: {
    float: "right",
    marginBottom: "16px",
    paddingRight: "38px",
  },
  actionMenu: {
    "& .MuiPaper-root": {
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
  },
  actionMenuItem: {
    fontWeight: 600,
    fontSize: "14px",
  },
  dialogBody: {
    boxSizing: "content-box",
    minWidth: "486px",
    padding: "48px",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  dialogActionButton: {
    fontWeight: 600,
    paddingLeft: "28px",
    paddingRight: "28px",
  },
}));

const dummyData = [
  {
    consultantDetails: {
      firstName: "Julia",
      lastName: "Miller 1",
      profilePhoto: "",
      ID: "12345",
    },
    invoiceNo: "INV-2001",
    jobName: "ReactJS Developer-1",
    jobID: "1234",
    orgName: "FindPRO INC",
    amount: "500",
    startDate: "",
    endDate: "",
    status: "Paid",
  },
  {
    consultantDetails: {
      firstName: "Julia",
      lastName: "Miller 2",
      profilePhoto: "",
    },
    invoiceNo: "INV-2002",
    jobName: "ReactJS Developer-2",
    jobID: "1235",
    orgName: "FindPRO INC",
    amount: "600",
    startDate: "",
    endDate: "",
    status: "Pending",
  },
  {
    consultantDetails: {
      firstName: "Julia",
      lastName: "Miller 3",
      profilePhoto: "",
    },
    invoiceNo: "INV-2003",
    jobName: "ReactJS Developer-3",
    jobID: "1236",
    orgName: "FindPRO INC",
    amount: "",
    startDate: "",
    endDate: "",
    status: "Paid",
  },
  {
    consultantDetails: {
      firstName: "Julia",
      lastName: "Miller 4",
      profilePhoto: "",
    },
    invoiceNo: "INV-2004",
    jobName: "ReactJS Developer-4",
    jobID: "1237",
    orgName: "FindPRO INC",
    amount: "",
    startDate: "",
    endDate: "",
    status: "Paid",
  },
  {
    consultantDetails: {
      firstName: "Julia",
      lastName: "Miller 5",
      profilePhoto: "",
    },
    invoiceNo: "INV-2005",
    jobName: "ReactJS Developer-5",
    jobID: "1238",
    orgName: "FindPRO INC",
    amount: "",
    startDate: "",
    endDate: "",
    status: "Paid",
  },
  {
    consultantDetails: {
      firstName: "Julia",
      lastName: "Miller 6",
      profilePhoto: "",
    },
    invoiceNo: "INV-2006",
    jobID: "1239",
    orgName: "FindPRO INC",
    amount: "",
    startDate: "",
    endDate: "",
    status: "Paid",
  },
  {
    consultantDetails: {
      firstName: "Julia",
      lastName: "Miller 7",
      profilePhoto: "",
    },
    invoiceNo: "INV-2007",
    jobID: "1240",
    orgName: "FindPRO INC",
    amount: "",
    startDate: "",
    endDate: "",
    status: "Paid",
  },
];

const getFullName = (consultantDetails) => {
  const firstName = consultantDetails?.firstName;
  const lastName = consultantDetails?.lastName;
  let fullName = "";
  if (firstName) fullName += firstName + " ";
  if (lastName) fullName += lastName;
  return fullName;
};

const getDateOutput = (dateString) => {
  if (isValidString(dateString)) {
    let localDateString = new Date(dateString).toLocaleDateString();
    localDateString = localDateString === "Invalid Date" ? "" : localDateString;
    return localDateString;
  }
  return "-";
};

const RecreateActionBody = ({ actionEntryData, onYesClick, onNoClick }) => {
  const classes = useStyles();

  const jobData = actionEntryData.jobs;
  const consultantData = actionEntryData.consultants;
  const [amount, setAmount] = useState(actionEntryData.netInvoiceAmount);
  const [discount, setDiscount] = useState();
  const [notes, setNotes] = useState();

  return (
    <>
      <div className="flex gap-x-[16px]">
        <CustomTextfield
          id="invoiceNo"
          label="Invoice No"
          value={actionEntryData.invoiceNumber}
          disabled={true}
        />
        <CustomTextfield
          id="orgName"
          label="
  Org Name"
          value={actionEntryData.organizations?.company}
          disabled={true}
        />
      </div>
      <div className="flex gap-x-[16px]">
        <CustomTextfield
          id="jobNo"
          label="Job No"
          value={jobData?.jobNo}
          disabled={true}
        />
        <CustomTextfield
          id="jobName"
          label="Job Name"
          value={jobData?.jobTitle}
          disabled={true}
        />
      </div>
      <div className="flex gap-x-[16px]">
        <CustomTextfield
          id="consultantID"
          label="consultant id"
          value={consultantData?._id}
          disabled={true}
        />
        <CustomTextfield
          id="consultantName"
          label="consultant name"
          value={consultantData?.name}
          disabled={true}
        />
      </div>
      <CustomTextfield
        id="projectDescription"
        label="project description"
        value={actionEntryData?.projectDescription || "-"}
        multiline={true}
        minRows={2}
        disabled={true}
      />
      <div className="flex gap-x-[16px]">
        <CustomTextfield
          id="startDate"
          label="start date"
          value={actionEntryData.startDate}
          disabled={true}
        />
        <CustomTextfield
          id="endDate"
          label="end date"
          value={actionEntryData.endDate}
          disabled={true}
        />
      </div>
      <CustomTextfield
        id="dueDate"
        label="payment due date"
        value={actionEntryData.paymentDueDate}
        disabled={true}
      />
      <div className="flex gap-x-[16px]">
        <CustomTextfield
          id="amount"
          label="amount"
          type="number"
          value={amount}
          inputProps={{ min: 0 }}
          onChange={(id, value) => {
            setAmount(value);
          }}
          showMandatorySymbol={true}
        />
        <CustomTextfield
          id="discount"
          label="Discount"
          type="number"
          value={discount}
          inputProps={{ min: 1 }}
          onChange={(id, value) => setDiscount(value)}
          noErrorMessage={true}
        />
      </div>
      <CustomTextfield
        id="notes"
        label="notes"
        multiline={true}
        minRows={3}
        value={notes}
        onChange={(id, value) => setNotes(value)}
        noErrorMessage={true}
      />
      <div className="flex gap-y-[8px] justify-end mt-[16px]">
        <CustomButton
          customClasses={classes.dialogActionButton}
          onClick={() => {
            onNoClick(actionEntryData._id);
          }}
        >
          No
        </CustomButton>
        <CustomTooltip
          title={
            !Boolean(amount) && amount <= 0
              ? "Please fill the mandatory details."
              : null
          }
        >
          <div>
            <CustomButton
              type="button1"
              customClasses={classes.dialogActionButton}
              disabled={!Boolean(amount)}
              onClick={() => {
                onYesClick({
                  jobId: jobData._id,
                  userId: consultantData._id,
                  userType: "consultant",
                  refId: actionEntryData._id,
                  amount: Number(amount),
                  discounts: { amount: Number(discount) || 0 },
                  notes: notes || "",
                });
              }}
            >
              Yes
            </CustomButton>
          </div>
        </CustomTooltip>
      </div>
    </>
  );
};

const CancelActionBody = ({ actionEntryData, onYesClick, onNoClick }) => {
  const classes = useStyles();
  return (
    <>
      <div className="text-[18px]">
        Are you sure you want to cancel the invoice{" "}
        {actionEntryData.invoiceNumber || ""} ?
      </div>
      <div className="flex gap-y-[8px] justify-end mt-[16px]">
        <CustomButton
          customClasses={classes.dialogActionButton}
          onClick={onNoClick}
        >
          No
        </CustomButton>

        <CustomButton
          type="button1"
          customClasses={classes.dialogActionButton}
          onClick={() => {
            onYesClick(actionEntryData._id);
          }}
        >
          Yes
        </CustomButton>
      </div>
    </>
  );
};

const ViewInvoiceActionBody = ({ actionEntryData }) => {
  return (
    <div className="h-[440px] w-[650px] p-2 ">
      <FilePreview2 fileUrlOrContent={actionEntryData?.file} />
    </div>
  );
};

const ActionDialog = ({
  actionDialogOpen,
  setActionDialogOpen,
  actionType,
  actionEntryData,
  onActionSuccess,
}) => {
  const classes = useStyles();
  const dialogTitle = {
    recreateInvoice: "Recreate Invoice",
    cancelInvoice: "Cancel Invoice",
    viewInvoice: "Invoice",
  };
  const isRecreateAction =
    actionType === "recreateInvoice"
      ? "recreateInvoice"
      : actionType === "cancelInvoice"
      ? "cancelInvoice"
      : "viewInvoice";

  const [actionLoading, setActionLoading] = useState(false);
  const [actionError, setActionError] = useState(false);
  const [actionCompleted, setActionCompleted] = useState(false);

  const onCloseDialog = () => {
    setActionDialogOpen(false);
  };

  const onRecreateInvoice = (data) => {
    // {
    //   jobId: "6583be203ae5caf12a8161b7",
    //   userId: "6583012e3ae5caf12a81551b", // consultant id or agency id
    //   userType: "consultant",
    //   refId: "65cfaee520de3fb37b421b45",
    //   amount,
    //   discount,
    //   notes,
    // }
    setActionLoading(true);
    recreateInvoice({ ...data })
      .then(() => {
        setActionCompleted(true);
        setActionLoading(false);
      })
      .catch(() => {
        setActionError(true);
        setActionLoading(false);
      });
  };
  const onCancelInvoice = (invoiceID) => {
    setActionLoading(true);
    cancelInvoice(invoiceID)
      .then(() => {
        setActionCompleted(true);
        setActionLoading(false);
      })
      .catch(() => {
        setActionError(true);
        setActionLoading(false);
      });
  };
  const Title = () => {
    return (
      <div className="text-[16px] font-semibold text-center">
        {actionLoading || actionError || actionCompleted ? (
          <>
            {actionLoading && "Action in progress"}
            {actionError && "Error"}
            {actionCompleted && `${dialogTitle[actionType]}`}
          </>
        ) : (
          <>{dialogTitle[actionType]}</>
        )}
      </div>
    );
  };

  const DialogBody = () => {
    if (actionLoading) {
      return <DialogLoadingBody />;
    } else if (actionCompleted) {
      return (
        <DialogSuccessBody
          content={{
            title: `Your invoice has been ${
              isRecreateAction === "recreateInvoice"
                ? "recreated"
                : actionType === "cancelInvoice"
                ? "cancelled"
                : "viewed"
            } successfully.`,
          }}
        />
      );
    } else if (actionError) {
      return (
        <DialogErrorBody
          content={{ title: messages.GENERIC_ERROR_MESSAGE, desc: "" }}
        />
      );
    } else {
      return (
        <div className={classes.dialogBody}>
          {isRecreateAction === "recreateInvoice" && (
            <RecreateActionBody
              actionEntryData={actionEntryData}
              onYesClick={onRecreateInvoice}
              onNoClick={onCloseDialog}
            />
          )}
          {isRecreateAction === "cancelInvoice" && (
            <CancelActionBody
              actionEntryData={actionEntryData}
              onYesClick={onCancelInvoice}
              onNoClick={onCloseDialog}
            />
          )}
          {isRecreateAction === "viewInvoice" && (
            <ViewInvoiceActionBody actionEntryData={actionEntryData} />
          )}
        </div>
      );
    }
  };

  useEffect(() => {
    if (actionCompleted) onActionSuccess();
  }, [actionCompleted]);

  return (
    <CustomDialog
      open={actionDialogOpen}
      setOpen={setActionDialogOpen}
      noCloseAction={actionLoading}
      title={<Title />}
      content={<DialogBody />}
    />
  );
};

const SortBySelect = ({ selectedValue, onChange }) => {
  const classes = useStyles();

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <div className="flex gap-y-[8px] items-center text-[14px] text-[#8C8C8C]">
      <div>Sort By:</div>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={selectedValue}
          onChange={handleChange}
          className={classes.sortBySelect}
        >
          <MenuItem value="latest" className={classes.sortByMenuItem}>
            <CustomTooltip title={"Latest by invoice date"}>
              Latest
            </CustomTooltip>
          </MenuItem>
          <MenuItem value="date" className={classes.sortByMenuItem}>
            <CustomTooltip title={"Latest to oldest"}>
              Payment due date{" "}
              <ArrowDownwardIcon className={classes.sortMenuItemIcon} />
            </CustomTooltip>
          </MenuItem>
          <MenuItem value="status" className={classes.sortByMenuItem}>
            <CustomTooltip
              title={
                <div>
                  <div>Order:</div>
                  <div>1. In progress</div>
                  <div>2. Cancelled</div>
                  <div>3. Paid</div>
                </div>
              }
            >
              Status
            </CustomTooltip>
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

const Invoices = (props) => {
  const classes = useStyles();

  const columns = [
    "Invoice No",
    "Consultant",
    "Job",
    "Org",
    "Start-End Date",
    "Payment Due Date",
    "Amount",
    "Net Amount",
    "Status",
    "Action",
  ];
  const statusColor = {
    pending: "#FBBC05",
    paid: "#14C042",
    inprogress: "#FBBC05",
    cancelled: "red",
  };

  const pendingStatusValues = ["pending", "due"];

  const invoicesPerScroll = 8;

  const [invoicesData, setInvoicesData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [dataError, setDataError] = useState(false);
  const [initialRender, setInitialRender] = useState(true);

  const [sortByValue, setSortByValue] = useState("latest");
  const [searchTextTags, setSearchTextTags] = useState([]);

  const [totalPagesCount, setTotalPagesCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  // #region action states
  const [actionMenuAnchor, setActionMenuAnchor] = useState({});
  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [actionType, setActionType] = useState("");
  const [actionEntryData, setActionEntryData] = useState({});
  // #endregion action states

  const fetchInvoices = async (currentPage) => {
    setDataLoading(true);

    const data = {
      limit: invoicesPerScroll,
      offset: currentPage - 1,
      searchTags: searchTextTags,
      sortBy: sortByValue,
    };

    getAllInvoices(data)
      .then((response) => {
        let invoicesReceived = response?.data;
        if (isValidArray(invoicesReceived)) {
          const totalClientCount = response?.count;
          const pageCount =
            totalClientCount > 0
              ? Math.ceil(totalClientCount / invoicesPerScroll)
              : 0;
          setInvoicesData(invoicesReceived);
          setTotalPagesCount(pageCount);
          setInitialRender(false);
        }
        setDataLoading(false);
      })
      .catch((error) => {
        setDataLoading(false);
        setDataError(true);
      });
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    fetchInvoices(value);
  };
  const handleColumnMenuOpen = (event, data) => {
    const actionEntryData = JSON.parse(JSON.stringify(data));
    setActionMenuAnchor({ [actionEntryData.invoiceNo]: event.currentTarget });
    setActionEntryData(actionEntryData);
  };
  const handleColumnMenuClose = () => {
    setActionMenuAnchor({});
  };

  const onActionMenuItemClick = (action) => {
    setActionDialogOpen(true);
    setActionType(action);
    handleColumnMenuClose();
  };

  const onActionSuccess = () => {
    setTimeout(() => fetchInvoices(), 1000);
  };

  useEffect(() => {
    fetchInvoices(currentPage);
  }, []);

  useEffect(() => {
    if (!initialRender) {
      setCurrentPage(1);
      fetchInvoices(1);
    }
  }, [sortByValue]);

  useEffect(() => {
    if (!actionDialogOpen) handleColumnMenuClose();
  }, [actionDialogOpen]);

  return (
    <div>
      <Title title="Invoices" />
      <>
        {dataError ? (
          <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
        ) : (
          <>
            <div className={classes.sortAndSearchDiv}>
              <SortBySelect
                selectedValue={sortByValue}
                onChange={(value) => setSortByValue(value)}
              />
              <div className={classes.searchBoxOuterDiv}>
                <CustomAutocomplete
                  id="searchTags"
                  data={searchTextTags}
                  placeholder="Search by invoice number and more"
                  defaultSelection={searchTextTags}
                  allowMultipleSelection={true}
                  allowCustomUserInput={true}
                  onChange={(e, val) => {
                    setSearchTextTags(val);
                  }}
                  customClasses={classes.searchBox}
                  // showSearchIcon={true}
                />
                <SearchIcon
                  onClick={() => {
                    setCurrentPage(1);
                    fetchInvoices(1);
                  }}
                  className="cursor-pointer ml-[8px]"
                />
                <CustomTooltip title="Note: For the search field, press the 'Enter' key to create the tag after entering it.">
                  <div>
                    <InfoIcon />
                  </div>
                </CustomTooltip>
              </div>
            </div>
            {dataLoading ? (
              <ContentLoader
                speed={2}
                width={1440}
                height={800}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="20" y="0" rx="3" ry="3" width="1440" height="700" />
              </ContentLoader>
            ) : (
              <>
                {" "}
                {isValidArray(invoicesData) ? (
                  <>
                    <TableContainer
                      sx={{
                        width: "auto",
                        maxHeight: 440,
                        margin: "0 24px 24px 24px",
                      }}
                    >
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map((column, index) => (
                              <TableCell
                                key={`column-${index}`}
                                // align={column.align}
                                sx={{
                                  backgroundColor: "#F7F7F7",
                                  fontWeight: 600,
                                }}
                              >
                                {column}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {invoicesData && invoicesData.map((invoiceData) => (
                            <>
                              {isValidObject(invoiceData) ? (
                                <TableRow sx={{ backgroundColor: "#F7F7F7" }}>
                                  <TableCell>
                                    {" "}
                                    {invoiceData?.invoiceNumber || "-"}
                                  </TableCell>
                                  <TableCell>
                                    <div className={classes.consultantCell}>
                                      <img
                                        src={
                                          invoiceData?.consultants?.image
                                            ?.profilePhoto ||
                                          "/user_placeholder.svg"
                                        }
                                        alt="user-profile"
                                        className={classes.userImage}
                                      />
                                      <div className="text-[14px] font-semibold">
                                        {invoiceData?.consultants?.name}
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell className="text-[12px]">
                                    <div className="font-semibold">
                                      {invoiceData?.jobs?.jobTitle || "-"}
                                    </div>
                                    <div>
                                      Job No- {invoiceData?.jobs?.jobNo || "-"}
                                    </div>
                                  </TableCell>
                                  <TableCell className="text-[14px]">
                                    {invoiceData?.organizations?.company || "-"}
                                  </TableCell>
                                  <TableCell className="text-[14px]">
                                    {getDateOutput(invoiceData?.startDate)} -{" "}
                                    {getDateOutput(invoiceData?.endDate)}
                                  </TableCell>
                                  <TableCell className="text-[14px]">
                                    {getDateOutput(invoiceData?.paymentDueDate)}
                                  </TableCell>
                                  <TableCell className="text-[14px] text-nowrap">
                                    {invoiceData?.amount
                                      ? `${invoiceData?.currency || ""} ${
                                          invoiceData?.amount
                                        }`
                                      : "-"}
                                  </TableCell>
                                  <TableCell className="text-[14px] text-nowrap">
                                    {invoiceData?.netInvoiceAmount
                                      ? `${invoiceData?.currency || ""} ${
                                          invoiceData?.netInvoiceAmount
                                        }`
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    className="uppercase"
                                    style={{
                                      color:
                                        statusColor[
                                          isValidString(invoiceData.status)
                                            ? invoiceData.status?.toLowerCase()
                                            : ""
                                        ],
                                      fontWeight: 600,
                                    }}
                                  >
                                    {invoiceData.status}
                                  </TableCell>
                                  <TableCell>
                                    <IconButton
                                      id={`${invoiceData.invoiceNo}-action-button`}
                                      onClick={(event) => {
                                        handleColumnMenuOpen(
                                          event,
                                          invoiceData
                                        );
                                      }}
                                      className="p-0"
                                    >
                                      <MoreVertIcon fontSize="small" />
                                    </IconButton>
                                    <Menu
                                      id={`${invoiceData.invoiceNo}-menu`}
                                      MenuListProps={{
                                        "aria-labelledby": `${invoiceData.invoiceNo}-menu`,
                                      }}
                                      anchorEl={
                                        actionMenuAnchor[invoiceData.invoiceNo]
                                      }
                                      open={Boolean(
                                        actionMenuAnchor[invoiceData.invoiceNo]
                                      )}
                                      onClose={() => {
                                        handleColumnMenuClose();
                                      }}
                                      TransitionComponent={Fade}
                                      className={classes.actionMenu}
                                    >
                                      <MenuItem
                                        className={classes.actionMenuItem}
                                        onClick={() => {
                                          onActionMenuItemClick(
                                            "recreateInvoice"
                                          );
                                        }}
                                      >
                                        Recreate Invoice
                                      </MenuItem>
                                      {isValidString(
                                        actionEntryData?.status
                                      ) ? (
                                        <CustomTooltip
                                          title={
                                            !pendingStatusValues.includes(
                                              actionEntryData?.status.toLowerCase()
                                            )
                                              ? "This action is only applicable when the invoice is in progress or pending state."
                                              : null
                                          }
                                        >
                                          <div>
                                            <MenuItem
                                              className={classes.actionMenuItem}
                                              onClick={() => {
                                                onActionMenuItemClick(
                                                  "cancelInvoice"
                                                );
                                              }}
                                              disabled={
                                                !pendingStatusValues.includes(
                                                  actionEntryData?.status.toLowerCase()
                                                )
                                              }
                                            >
                                              Cancel Invoice
                                            </MenuItem>
                                          </div>
                                        </CustomTooltip>
                                      ) : (
                                        <></>
                                      )}

                                      {isValidString(actionEntryData?.file) ? (
                                        <MenuItem
                                          className={classes.actionMenuItem}
                                          onClick={() => {
                                            onActionMenuItemClick(
                                              "viewInvoice"
                                            );
                                          }}
                                        >
                                          View Invoice
                                        </MenuItem>
                                      ) : (
                                        <></>
                                      )}
                                    </Menu>
                                  </TableCell>
                                </TableRow>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Pagination
                      color="primary"
                      count={totalPagesCount}
                      page={currentPage}
                      onChange={handlePageChange}
                      classes={{ ul: classes.paginationContainer }}
                    />
                    <CustomDialog />
                  </>
                ) : (
                  <Alert severity="info">{messages.NO_DATA_FOUND}</Alert>
                )}
              </>
            )}
          </>
        )}
      </>

      {actionDialogOpen && (
        <ActionDialog
          actionDialogOpen={actionDialogOpen}
          setActionDialogOpen={setActionDialogOpen}
          actionType={actionType}
          actionEntryData={actionEntryData}
          onActionSuccess={onActionSuccess}
        />
      )}
    </div>
  );
};

Invoices.propTypes = {};

export default Invoices;
