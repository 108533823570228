// Services
import { useSnackbar } from "notistack";
// import {
//   candidatesFilter,
//   getCandidateList,
//   getJobList,
//   jobRequestForCandidate,
// } from "../../../services/employerDashboard.service";
// Services
import { CircularProgress } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  getAdvanceConsultantSearch,
  getConsultantsThunk,
} from "../../../store/features/employer/employerDashboard.thunk";
// import { getConsultantsThunk } from "../../../store/features/employer/consultants/consultants.thunk";
import {
  getMyJobsThunk,
  getPostedJobsThunk,
} from "../../../store/features/employer/jobFeed/jobFeed.thunk";

import PropTypes from "prop-types";
import { Typography, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";

import clsx from "clsx";
import Title from "../../common/Title";
import CustomSelect from "app/v2/components/filters/CustomSelect";
import ConsultantGridCard from "app/v2/Pages/Employer/common/Cards/Grid/Consultant";
import ConsultantListCard from "app/v2/Pages/Employer/common/Cards/List/Consultant";
import CustomButton from "app/v2/components/CustomButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { getConsultantsForFeaturedThunk } from "app/v2/store/features/employer/employerDashboard.thunk";
import storageUtil from "../../Utils/storageUtil";
import { useLocation } from "react-router-dom";
import ContentLoader from "react-content-loader";
import SearchLoaderModal from "../../common/SearchLoaderModal";
import { isValidArray, isValidString } from "../../Utils/utilFunctions";
const useStyles = makeStyles((theme) => ({
  consultantFeedContainer: {
    minHeight: "calc(100vh - 382px)", // total viewport height minus navbar and footer's heights, minHeight:100% did not work,hence had to set it as pre parent div defined in EmployerRoute.
  },

  title: {
    fontWeight: 600,
    fontSize: "36px",
    marginBottom: "16px",
  },
  actions: {
    display: "flex",
    columnGap: "16px",
    margin: "0px 52px 22px 52px",
    [theme.breakpoints.down('sm')]: {
      margin: "0",
    },
    alignItems: "center",
  },
  jobPostsAutocomplete: {
    width: "683px",
  },
  filterSortButton: {
    width: "131.86px",
    height: "40px",
    padding: "7.54532px 13.2043px",
    border: "1px solid #EDEDED",
    fontWeight: 600,
    fontSize: "14px",
    dispaly: "flex",
    alignItems: "center",
    columnGap: "10px",
    whiteSpace: "nowrap",
  },
  consultantCountAndViewsDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0px 52px 55px 30px",
  },
  consultantCountText: {
    fontWeight: 500,
    fontSize: "12px",
    color: theme.palette.secondary.light,
  },
  viewButtonsDiv: { display: "flex", columnGap: "16px" },
  viewButton: {
    padding: "10px 20px 10px 15px",
    width: "126px",
    height: "38px",
    border: "1px solid #EDEDED",
    fontWeight: 600,
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  selectedViewButton: {
    backgroundColor: "#EDEDED !important",
    border: 0,
    color: theme.palette.secondary.light,
  },
  consultantsDiv: {
    // height: "1699px",
    overflowY: "auto",
    display: "flex",
    flexWrap: "wrap", //need to check this card again.
    alignItems: "start",
    // justifyContent: "center",
    gap: "24px",
    margin: "0px 0px 124px 30px",
    paddingTop: "40px",
    paddingBottom: "40px",
  },
  loadMoreDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "76px",
    rowGap: "12px",
  },
  moreIcon: {
    color: theme.palette.secondary.light,
    padding: "8px",
    border: "1px solid #EDEDED",
    borderRadius: "4px",
  },
  loadMoreText: {
    fontSize: "14px",
  },
}));
const JobSelector = ({ activeJobs, selectedJob, onJobChange }) => {
  const classes = useStyles();

  const [jobSelectorData, setJobSelectorData] = useState([]); // array of jobNoAndTile values.
  const [selectedJobNoAndTitle, setSelectedJobNoAndTitle] = useState();

  const getActiveJobEntry = (jobNoAndTitle) => {
    return activeJobs?.find(
      (activeJobEntry) => activeJobEntry?.jobNoAndTitle === jobNoAndTitle
    );
  };

  const onChange = (id, value) => {
    setSelectedJobNoAndTitle(value);
    const selectedActiveJob = getActiveJobEntry(value);
    onJobChange(selectedActiveJob);
  };

  useEffect(() => {
    // No valid array checks required here as we show this component only if activeJobs array is valid.
    const activeJobsNoAndTitleData = activeJobs?.map(
      (job) => job?.jobNoAndTitle
    ); // active jobs- job number and title string data.
    setJobSelectorData(activeJobsNoAndTitleData);
    setSelectedJobNoAndTitle(selectedJob?.jobNoAndTitle);
  }, [activeJobs, selectedJob]);

  return (
    <CustomSelect
      id={"job-posts-autocomplete"}
      data={jobSelectorData}
      defaultSelection={selectedJobNoAndTitle}
      placeholder={"Select Job"}
      onChange={onChange}
      customClasses={"w-[524px]"}
      // disabled={disableRoleAutocomplete}
    />
  );
};
const ConsultantFeed = ({ props }) => {
  const history = useHistory();
  const classes = useStyles();
  const dummyGridData = new Array(16).fill(
    <ConsultantGridCard props={undefined} />
  );
  const dummyListData = new Array(10).fill(<ConsultantListCard />);
  const [viewType, setViewType] = useState("grid");
  const dummyJobPostsData = [
    "Job 27: Frontend Developer for Contractual Project",
    "Job 28: Backend Developer for Contractual Project",
    "Job 29: Backend Developer for Full time Project",
  ]; // will get this at global level context or redux store.
  ////////
  const [isLoading, setIsLoading] = useState(false);
  // const [candidates, setCandidates] = useState([]);
  const [candidatesToShow, setCandidatesToShow] = useState(0);
  const candidatesPerScroll = 8;
  const [reachedLastItem, setReachedLastItem] = useState(false);
  const [totalCount, setTotalCount] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const selectedFiltersString = localStorage.getItem("selectedFilters");
  const selectedFilters = JSON.parse(
    selectedFiltersString
  ); /* this is used for further data filter*/
  console.log("filters --", selectedFilters)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const hideComponent = searchParams.get("hideComponent") === "true";
  const jobTitle = searchParams.get("jobTitle");
  let questionnaireData = storageUtil.getItem("consultant_questionnaire");
  const [onBoardingId, setOnBoardingId] = useState(
    questionnaireData?.onBoardingId
  );

  const dispatch = useDispatch();
  const [postedJobs, setPostedJobs] = useState([]);
  const [activeJobs, setActiveJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState();
  const postedJobsBuf = useSelector((state) => state.jobs.jobs.data);
  const consultantsBuf = useSelector((state) => state.consultants);
  const [candidates, setCandidates] = useState(
    consultantsBuf.consultants ? consultantsBuf.consultants : []
  );

  const currentJobID = props?.match?.params?.jobID;

  useEffect(() => {
    if (isValidArray(postedJobs)) {
      const activeJobsWithJobNoAndTitle = [];
      let selectedJob;
      postedJobs.forEach((jobEntry) => {
        if (jobEntry?.status === "active") {
          activeJobsWithJobNoAndTitle.push({
            ...jobEntry,
            jobNoAndTitle: `Job ${jobEntry?.jobNo}: ${jobEntry?.jobTitle}`,
          });
        }
      });

      if (isValidArray(activeJobsWithJobNoAndTitle)) {
        selectedJob = activeJobsWithJobNoAndTitle.find(
          (jobEntry) => jobEntry?._id === currentJobID
        );
        setActiveJobs(activeJobsWithJobNoAndTitle);
        setSelectedJob(selectedJob);
      }
    }
  }, [postedJobs]);
  const fetchMoreCandidates = async () => {
    setIsLoading(true);
    // await new Promise((r) => setTimeout(r, 1000));
    // await getCandidateList(candidatesPerScroll, candidatesToShow)
    //   .then((res) => {
    //     if (res) {
    //       // setCandidates(res);
    //       if (res.length === 0) {
    //         setReachedLastItem(true);
    //       } else {
    //         setCandidates( prevCandidates => [...prevCandidates, ...res] );
    //         // enqueueSnackbar("Fetched!",{variant:"success"});
    //         setTotalCount(res.totalCount);
    //         // console.log( res.totalCount );
    //       }
    //     }
    //     setIsLoading(false);
    //   })
    //   .catch((err) => {
    //     enqueueSnackbar("Problem in fetching candidates!", {
    //       variant: "error",
    //     });
    //     console.error(err);
    //     setIsLoading(false);
    //   });

    const advanceSearchParams = new URLSearchParams(location.search);
    const selectedJob = advanceSearchParams.get("jobTitle") || "";
    console.log("selectedJob",selectedJob)
    const searchTypeAgencies =
      advanceSearchParams.get("searchTypeAgencies") || false;
    const country = advanceSearchParams.get("country") || "";
    const state = advanceSearchParams.get("state") || "";
    const city = advanceSearchParams.get("city") || "";
    const timezone = advanceSearchParams.get("timezone") || "";
    const experienceID = advanceSearchParams.get("experience") || "";
    const contractTypeID = advanceSearchParams.get("contractType") || "";
    let sortBy = advanceSearchParams.get("sortBy") || "ztoa";
    let skillIDs = advanceSearchParams.get("skills") || "";
    let priceRange = advanceSearchParams.get("priceRange") || "";

    sortBy = sortBy.toLowerCase().replace(/ /g, "");
    skillIDs = isValidString(skillIDs) ? skillIDs.split(",") : [];
    priceRange = isValidString(priceRange) ? priceRange.split(",") : [];

    if (currentJobID !== undefined && currentJobID) {
      dispatch(
        // getConsultantsThunk({
        getConsultantsForFeaturedThunk({
          jobId: currentJobID,
          limit: candidatesPerScroll,
          offset: candidatesToShow,
        })
      )
        .unwrap()
        .then((res) => {
          if (res) {
            if (res.length === 0) {
              setReachedLastItem(true);
            } else {
              setCandidates((prevCandidates) => [...prevCandidates, ...res]);
              // enqueueSnackbar("Fetched!",{variant:"success"});
              setTotalCount(res.count);
            }
          }
          setIsLoading(false);
        })
        .catch((err) => {
          // if (err.message) { }
          // enqueueSnackbar("Problem in fetching candidates!", {
          //   variant: "error",
          // });

          const key = enqueueSnackbar("Problem in fetching candidates!", {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
          console.error(err);
          setIsLoading(false);
        });
    } else if (
      activeJobs?.length === 0 &&
      postedJobs?.length === 0 &&
      !advanceSearchParams
    ) {
      // check onboarding
      // dispatch(getConsultantsForFeaturedThunk({onBoardingId: "64ce800162383c9ff793d163", "limit":candidatesPerScroll, "offset":candidatesToShow}))
      // if it's regular search
console.log("safasfasf")
      if (!onBoardingId) {
        dispatch(
          // getConsultantsThunk({
          // getConsultantsForFeaturedThunk({
          getAdvanceConsultantSearch({
            // "name":"edward",
            jobTitle: selectedJob,
            sortBy: sortBy,
            // "sortBy": "highrate", // atoz, ztoa,latest,lowrate,highrate,
            type: "consultant", //agency
            country,
            state,
            city,
            timezone,
            skills: skillIDs, // ["646f1c2f96656ed53aa32478"]
            jobType: contractTypeID, // jobType = availability
            workHistory: experienceID,
            priceRange: priceRange,
            limit: candidatesPerScroll,
            offset: candidatesToShow,
          })
        )
          .unwrap()
          .then((res) => {
            if (res?.data) {
              if (res?.data.length === 0) {
                setReachedLastItem(true);
              } else {
                // setCandidates(res);
                setCandidates((prevCandidates) => [
                  ...prevCandidates,
                  ...res.data,
                ]);
                setTotalCount(res?.count);
              }
            }
            setIsLoading(false);
          })
          .catch((err) => {
            // if (err.message) { }
            // enqueueSnackbar("Problem in fetching candidates!", {
            //   variant: "error",
            // });
            const key = enqueueSnackbar("Problem in fetching candidates!", {
              variant: "error",
              autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon
                  onClick={() => closeSnackbar(key)}
                  style={{
                    cursor: "pointer",
                    fontSize: "15",
                    marginTop: "-1px",
                  }}
                />
              ),
            });
            console.error(err);
            setIsLoading(false);
          });
      } else {
        // if client don't complete the profile / has onboarding
        dispatch(
          getConsultantsForFeaturedThunk({
            onBoardingId,
            limit: candidatesPerScroll,
            offset: candidatesToShow,
          })
        )
          .unwrap()
          .then((res) => {
            if (res) {
              if (res?.length === 0) {
                setReachedLastItem(true);
              } else {
                // setCandidates(res);
                setCandidates((prevCandidates) => [...prevCandidates, ...res]);
              }
            }
            setIsLoading(false);
          })
          .catch((err) => {
            // if (err.message) { }
            // enqueueSnackbar("Problem in fetching candidates!", {
            //   variant: "error",
            // });
            const key = enqueueSnackbar("Problem in fetching candidates!", {
              variant: "error",
              autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon
                  onClick={() => closeSnackbar(key)}
                  style={{
                    cursor: "pointer",
                    fontSize: "15",
                    marginTop: "-1px",
                  }}
                />
              ),
            });
            console.error(err);
            setIsLoading(false);
          });
      }
    } else {
      // if (!consultantsBuf?.length) {
      // getConsultantsForFeaturedThunk({
      //   advanveSearch:{
      //     sortBy,
      //     selectedOption,
      //     searchTypeAgencies,
      //     country,
      //     workType,
      //     experience,
      //   },
      //   jobId:currentJobID,
      //   limit: candidatesPerScroll,
      //   offset: candidatesToShow,

      const name = (selectedJob && selectedJob.trim()) 
      ? selectedJob 
      : (selectedFilters.tags && selectedFilters.tags.length > 0 && selectedFilters.tags[0].trim()) 
        ? selectedFilters.tags[0] 
        : '';
    
    console.log("Selected Job: ", selectedJob);
    console.log("Selected Filters: ", selectedFilters);
    console.log("Name for searchParams: ", name);

      dispatch(
        // getConsultantsThunk({
        // getConsultantsForFeaturedThunk({
        getAdvanceConsultantSearch({
          name: name,
          sortBy: sortBy,
          // "sortBy": "highrate", // atoz, ztoa,latest,lowrate,highrate,
          type: "consultant", //agency
          country,
          state,
          city,
          timezone,
          skills: skillIDs, // ["646f1c2f96656ed53aa32478"]
          jobType: contractTypeID, // jobType = availability
          workHistory: experienceID,
          priceRange: priceRange,
          limit: candidatesPerScroll,
          offset: candidatesToShow,

          limit: candidatesPerScroll,
          offset: candidatesToShow,
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.data) {
            if (res?.data?.length === 0) {
              setReachedLastItem(true);
            } else {
              // setCandidates(res);
              setCandidates((prevCandidates) => [
                ...prevCandidates,
                ...res?.data,
              ]);
              setTotalCount(res?.count);
            }
          }
          setIsLoading(false);
        })
        .catch((err) => {
          // if (err.message) { }
          // enqueueSnackbar("Problem in fetching candidates!", {
          //   variant: "error",
          // });

          const key = enqueueSnackbar("Problem in fetching candidates!", {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
          console.error(err);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchMoreCandidates();
    // }, [candidatesToShow, candidates]);
  }, [candidatesToShow]);
  useEffect(() => {
    // if ( !postedJobs?.length ) return false;
    if (!postedJobsBuf?.length) {
      // dispatch(getPostedJobsThunk())
      dispatch(getMyJobsThunk())
        .unwrap()
        .then((res) => {
          if (res) {
            setPostedJobs(res?.data);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          // if (err.message) { }
          // enqueueSnackbar("Problem in fetching candidates!", {
          //   variant: "error",
          // });
          const key = enqueueSnackbar("Problem in fetching candidates!", {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
          console.error(err);
          setIsLoading(false);
        });
    } else {
      setPostedJobs(postedJobsBuf);
    }
  }, []);
  // }, [postedJobs, postedJobsBuf]);
  useEffect(() => {
    const handleScrollFetch = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const documentHeight = document.documentElement.scrollHeight;
      if (
        scrollTop + windowHeight >= documentHeight - 0 &&
        !reachedLastItem &&
        !isLoading
      ) {
        setCandidatesToShow(
          (prevCandidatesToShow) => prevCandidatesToShow + candidatesPerScroll
        );
      }
    };
    if (!reachedLastItem) {
      window.addEventListener("scroll", handleScrollFetch);
      return () => {
        window.removeEventListener("scroll", handleScrollFetch);
      };
    }
  }, [reachedLastItem, isLoading]);
  ////////
  const isGridView = () => {
    return viewType === "grid";
  };
  const onViewChange = (updatedView) => {
    setViewType(updatedView);
  };
  const onJobChange = (selectedActiveJob) => {
    history.replace(`/client/${selectedActiveJob?._id}/consultants`);
  };
  return (
    <div className={classes.consultantFeedContainer}>
      {activeJobs?.length ? (
        <>
          {hideComponent ? (
            <Title topText="Search Results for All" title={jobTitle} />
          ) : (
            <Title topText="Result for All" title="Consultants" />
          )}

          <div className={classes.actions}>
            {hideComponent ? null : (
              <>
                {isValidArray(activeJobs) ? (
                  <JobSelector
                    activeJobs={activeJobs}
                    selectedJob={selectedJob}
                    onJobChange={onJobChange}
                  />
                ) : (
                  <></>
                )}
                {/* <CustomButton customClasses={classes.filterSortButton}>
                  Filter & Sort
                  <img
                    className="w-[15px] h-[15px]"
                    alt=""
                    src="/materialsymbolsfilterlist.svg"
                  />                  
                </CustomButton> */}
                {/* Hidden for MVP */}
              </>
            )}
          </div>
        </>
      ) : (
        <Title text="Consultants by Onboarding" />
      )}
      <div className={classes.consultantCountAndViewsDiv}>
        <Typography className={classes.consultantCountText}>
          {totalCount} consultants found
        </Typography>
        <div className={classes.viewButtonsDiv}>
          <CustomButton
            customClasses={clsx([
              classes.viewButton,
              isGridView() && classes.selectedViewButton,
            ])}
            onClick={() => {
              onViewChange("grid");
            }}
          >
            <img src="/grid-view.svg" className="mt-px mb-0.5 mr-2.5" />
            Grid View
          </CustomButton>
          <CustomButton
            customClasses={clsx([
              classes.viewButton,
              !isGridView() && classes.selectedViewButton,
            ])}
            onClick={() => {
              onViewChange("list");
            }}
          >
            <img src="/list-view.svg" className="mt-px mb-0.5 mr-2.5" />
            List View
          </CustomButton>
        </div>
      </div>
      <div className={classes.consultantsDiv}>
        {!candidates || isLoading ? (
          // {!candidates ? (
          <div>
            <SearchLoaderModal open={isLoading} />
            <ContentLoader
              speed={2}
              width={1400}
              height={800}
              viewBox="0 0 1400 800"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="150" y="0" rx="3" ry="3" width="400" height="400" />
              <rect x="600" y="0" rx="3" ry="3" width="400" height="400" />
              <rect x="1050" y="0" rx="3" ry="3" width="400" height="400" />
            </ContentLoader>
          </div>
        ) : (
          <>
            {isGridView()
              ? candidates?.map((candidate, index) => (
                  <ConsultantGridCard
                    key={index}
                    data={candidate}
                    jobID={currentJobID}
                  />
                ))
              : candidates?.map((candidate, index) => (
                  <ConsultantListCard
                    key={index}
                    data={candidate}
                    jobID={currentJobID}
                  />
                ))}
          </>
        )}
      </div>
      {isLoading && (
        <div className={classes.loadMoreDiv}>
          {/* <MoreHorizIcon className={classes.moreIcon} fontSize="large" /> */}
          {/* <CircularProgress
            sx={{ display: "flex", margin: "auto", height: "100%" }}
          /> */}
          {/* <Typography className={classes.loadMoreText}>Loading...</Typography> */}
          <ContentLoader
            speed={2}
            width={1400}
            height={800}
            viewBox="0 0 1400 800"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            {/* <rect x="5" y="0" rx="3" ry="3" width="250" height="340" /> */}
            {/* <rect x="0" y="0" rx="3" ry="3" width="1300" height="500" /> */}
            <rect x="45" y="340" rx="3" ry="3" width="208" height="190" />
            <rect x="275" y="340" rx="3" ry="3" width="208" height="190" />
            <rect x="507" y="340" rx="3" ry="3" width="208" height="190" />
          </ContentLoader>
        </div>
      )}
    </div>
  );
};
ConsultantFeed.propTypes = {};
export default ConsultantFeed;
