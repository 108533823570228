import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Alert, IconButton, Menu, MenuItem, Fade } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomButton from "app/v2/components/CustomButton";
import { isValidArray } from "app/v2/Pages/Utils/utilFunctions";
import { updatePlan } from "app/v2/services/admin/manageClients.service";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  container: {
    border: "1px solid #EDEDED",
    padding: "32px",
    zIndex: 2,
    backgroundColor: "white",
    display: "flex",
    columnGap: "40px",
    justifyContent: "space-between",
    // alignItems: "center",
  },
  hoveredContainer: {
    boxShadow: "0px 4px 22px 0px #0000000F",
    border: 0,
  },
  orgImage: {
    width: "100px",
    height: "100px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
  companyName: {
    fontSize: "18px",
    fontWeight: 600,
  },
  registeredAtText: {
    fontSize: "14px",
    color: "#717171",
  },
  infoElementTitle: {
    fontSize: "10px",
    fontWeight: 700,
    color: "#717171",
  },
  infoElementValue: {
    fontSize: "14px",
    fontWeight: 700,
  },
  sectionDiv: {
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
  },
  section2Div: {
    maxWidth: "363px",
    width: "100%",
  },
  section2SubDiv: {
    display: "flex",
    columnGap: "24px",
  },
  section3Div: {
    maxWidth: "363px",
    width: "100%",
  },
  section3SubDiv: {
    display: "flex",
    columnGap: "48px",
  },
  viewButton: {
    fontSize: "14px",
    fontWeight: 600,
    padding: "10px 15px 10px 20px",
    columnGap: "8px",
    maxWidth: "247px",
    whiteSpace: "nowrap",
  },
  actionMenuItem: {
    fontWeight: 600,
    fontSize: "14px",
  },
}));

const InfoElement = ({ title, value }) => {
  const classes = useStyles();
  return (
    <>
      {title ? (
        <div className="flex-1">
          <div className={classes.infoElementTitle}>{title}</div>
          <div className={classes.infoElementValue}>{value ? value : "-"}</div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const List = ({ data, reloadParent }) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const companyLocations = data?.companyLocation;
  let firstLocation, firstLocationArray, firstLocationString, registeredAt;

  if (isValidArray(companyLocations)) {
    firstLocation = companyLocations?.[0];
    firstLocationArray = [
      firstLocation?.country,
      firstLocation?.state,
      firstLocation?.city,
    ].filter(Boolean);
    firstLocationString = firstLocationArray.join(",");
  }

  registeredAt = data?.registeratedAt
    ? moment(data?.registeratedAt).fromNow()
    : "";
  registeredAt = registeredAt === "Invalid date" ? "" : registeredAt;

  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState("");

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (menuItem) => {
    // Call your API here
    console.log("API called for:", data);
    setSelectedMenuItem(menuItem);
    // Close the menu
    handleMenuClose();
    updatePlan({ orgId: data["_id"], duration: menuItem })
      .then((response) => {
        if (response) {
          let clientsReceived = response?.data;
          let pageCount;

          reloadParent();
          const key = enqueueSnackbar(`${response.message} for ${menuItem} month` , {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{
                  cursor: "pointer",
                  fontSize: "15",
                  marginTop: "-1px",
                }}
              />
            ),
          });
          // if (isValidArray(clientsReceived)) {
          //   const totalClientCount = response?.count?? response?.data?.length;
          //   pageCount =
          //     totalClientCount > 0
          //       ? Math.ceil(totalClientCount / clientsPerScroll)
          //       : 0;
          // }

          // setData(clientsReceived);
          // setTotalPagesCount(pageCount);
          // setInitialRender(false);
          // setDataLoading(false);
        }
      })
      .catch((error) => {
        // setDataLoading(false);
        // setDataError(true);
        const key = enqueueSnackbar(error.message, {
          variant: "error",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
      });
  };

  return (
    <div
      className={clsx([
        classes.container,
        isHovered && classes.hoveredContainer,
      ])}
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseEnter}
    >
      <img
        className={classes.orgImage}
        alt={`org_${data?._id}_image`}
        src={data?.profilePhoto ? data.profilePhoto : "/org_placeholder.svg"}
      />
      {/* Section 2 */}
      <div className={clsx([classes.sectionDiv, classes.section2Div])}>
        <div>
          <div className={classes.companyName}>{data?.company}</div>
          {registeredAt ? (
            <div className={classes.registeredAtText}>
              <b>Registered</b> - {registeredAt}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={classes.section2SubDiv}>
          <InfoElement title="HIRED APPLICANTS" value="" />
          <InfoElement title="ACTIVE JOBS POSTED" value="" />
        </div>
      </div>
      {/* Section 3 */}
      <div className={clsx([classes.sectionDiv, classes.section3Div])}>
        <div className={classes.section3SubDiv}>
          <InfoElement
            title="COMPANY SIZE"
            value={data?.companySize.toString() ?? data?.employees?.length}
          />
          <InfoElement title="LOCATION" value={firstLocationString} />
        </div>
        <div className={classes.section3SubDiv}>
          <InfoElement title="INDUSTRY" value={data?.industry} />
          <InfoElement
            title="Subscribed"
            value={data?.issubscribed ? "True" : "False"}
          />
        </div>
      </div>
      {/* Actions */}
      <div className="self-center">
        <div style={{ display: "flex", alignItems: "center" }}>
          <CustomButton
            type="button1"
            customClasses={classes.viewButton}
            onClick={() => {
              history.push(`/admin/client-detail/${data?._id}`);
            }}
          >
            View Company <img src="/iconlightup-right.svg" alt="arrow-icon" />
          </CustomButton>
          {!data?.isSubscribe && (
            <IconButton id={`asd`} onClick={handleMenuOpen} className="p-0">
              <MoreVertIcon fontSize="small" />
            </IconButton>
          )}
          <Menu
            id={`feature-menu`}
            MenuListProps={{
              "aria-labelledby": `feature-menu`,
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            TransitionComponent={Fade}
          >
            <MenuItem
              className={classes.actionMenuItem}
              onClick={() => handleMenuItemClick("1")}
            >
              1 Month
            </MenuItem>
            <MenuItem
              className={classes.actionMenuItem}
              onClick={() => handleMenuItemClick("3")}
            >
              3 Months
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

List.propTypes = {};

export default List;
