import { Box, Chip, Grid } from "@mui/material";
import { checkIsTodaysDate } from "app/common/utils/stringUtil";
import dateFormat from "dateformat";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import "../candidate/job-detail/job-details.scss";
import "../common/job-view/job-view.scss";
import DefaultImg from "../../images/default-job.svg";
import SampleImg from "../../images/sample-job.svg";
import ReactRoundedImage from "react-rounded-image";
import { AnimationOnScroll } from "react-animation-on-scroll";

const settings = {
  dots: false,
  infinite: false,
  speed: 2000,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 1175,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 975,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 786,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const mockJob = [
  {
    title: "Solution Architect",
    company: "Buchanan Technologies",
    skills: ["React Js", "Java", "html", "css3"],
    date: new Date(),
    country: "New York",
    proficiency: "Intermediate",
    src: SampleImg,
    bid: 20,
  },
  {
    title: "UX Designer",
    company: "Buchanan Technologies",
    skills: ["React Js", "Java", "html", "css3"],
    date: new Date(),
    country: "India",
    proficiency: "Expert",
    src: DefaultImg,
    bid: 60,
  },
  {
    title: "Java Professional",
    company: "ABC PVT LTD",
    skills: ["Java", "Spring Boot"],
    date: new Date(),
    country: "London",
    proficiency: "Intermediate",
    src: SampleImg,
    bid: 45,
  },
  {
    title: "UI Tech Lead",
    company: "Infosys Technologies",
    skills: ["React Js", "Java", "html", "css3"],
    date: new Date(),
    country: "USA",
    proficiency: "Intermediate",
    src: DefaultImg,
    bid: 60,
  },
  {
    title: "Automation Tester",
    company: "Buchanan Technologies",
    skills: ["Selenium", "Java", "JMeter"],
    date: new Date(),
    country: "New York",
    proficiency: "Intermediate",
    src: DefaultImg,
    bid: 50,
  },
  {
    title: "Business Analyst",
    company: "Chan Tech",
    skills: ["React Js", "Java", "html", "css3"],
    date: new Date(),
    country: "China",
    proficiency: "Intermediate",
    src: SampleImg,
    bid: 30,
  },
];

export default function AvailableJobs({ availableJobs, jobCount }) {
  const history = useHistory();
  const [jobsList, setJobsList] = useState(mockJob);
  const [jc, setJc] = useState(null);

  useEffect(() => {
    //// console.log("jobs list from comp -> ", availableJobs);
    if (availableJobs.length > 0) {
      setJobsList(availableJobs);
    }
  }, [availableJobs]);

  useEffect(() => {
    if (jobCount) {
      setJc(jobCount);
    }
  }, [jobCount]);

  return (
    <div className="available-jobs">
      <AnimationOnScroll
        animateOnce={true}
        animateIn="animate__fadeInRight"
        duration={1.5}
      >
        <h1 className="w-50">
          Find <span className="count">1000+</span> available job postings
        </h1>
      </AnimationOnScroll>
      <AnimationOnScroll
        animateOnce={true}
        animateIn="animate__fadeInLeft"
        duration={1.5}
      >
        <div className="jobs-container">
          <div className="s-1 w-25">
            <p className="sub-desc">
              Check out the recent Jobs from top tech companies around the
              world.
            </p>
            <p>
              <p className="title t-a-c">To check and apply these jobs</p>
              <p className="t-a-c">
                <Button
                  variant="light"
                  className="rewmp-btn t-a-c"
                  style={{
                    fontSize: "14px",
                    padding: "0.75rem 2rem",
                    marginTop: "2rem",
                  }}
                  onClick={() => history.push("/candidates/sign-up")}
                >
                  Register Now
                </Button>
              </p>

              <br />
              <br />
              <Button
                variant="light"
                className="rewmp-btn d-none"
                style={{
                  fontSize: "14px",
                  padding: "0.75rem 2rem",
                  background: "#FBE4DC",
                  color: "#000000",
                }}
                onClick={() => history.push("/candidates/sign-up")}
              >
                View All Jobs
              </Button>
            </p>
          </div>
          <div className="s-2 w-75 job-list">
            <Slider {...settings}>
              {jobsList.map((job) => (
                <div className="job">
                  <div
                    style={{
                      wordBreak: "break-word",
                    }}
                  >
                    <div
                      className="mb-4"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="round-image avlble-jobs">
                        <ReactRoundedImage
                          image={job.src}
                          roundedSize="0"
                          imageWidth="60"
                          imageHeight="60"
                        />
                      </div>
                      <div>
                        <span className="bid-value">${job.bid}</span>
                        <br />
                        <span className="bid-rate">per hr</span>
                      </div>
                    </div>
                    <h3 className="m-b0">
                      <span
                        style={{
                          maxWidth: "10.4rem",
                          overflow: "hidden",
                          display: "inline-block",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {job.title}
                      </span>
                      &nbsp; <span className="badge-new">New</span>
                    </h3>
                    <h6 className="m-b10">{job.company}</h6>
                    <div
                      style={{
                        paddingTop: 0,
                        paddingBottom: 1,
                        display: "flex",
                      }}
                      className="job-chip"
                    >
                      {job.skills?.slice(0, 2).map((skillItem, index) => {
                        return (
                          <Chip
                            size="medium"
                            sx={{
                              m: "2px",
                              width: "84px",
                            }}
                            label={skillItem}
                            key={index}
                          />
                        );
                      })}
                      {job.skills?.length > 2 && (
                        <Chip
                          size="medium"
                          sx={{
                            m: "2px",
                          }}
                          label={`+${
                            job.skills?.slice(2, job.skills.length).length
                          }`}
                        />
                      )}
                    </div>

                    <div
                      className="xs-font-size job-date-loc"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: 1,
                        fontSize: "10px",
                      }}
                    >
                      {checkIsTodaysDate(job.date)
                        ? "Today"
                        : dateFormat(job.date, "shortDate")}
                      <span className="dot-split">&#x2022;</span>
                      {!job.country && "Worldwide"}
                      {job.country && (
                        <span className="wrap-text">{job.country}</span>
                      )}
                      <span className="dot-split">&#x2022;</span>
                      <span className="wrap-text">{job.proficiency}</span>
                    </div>
                  </div>
                  <Button
                    variant="light"
                    className="rewmp-btn"
                    style={{
                      fontSize: "12px",
                      padding: "0.5rem 1rem",
                      background: "#FBE4DC",
                      color: "#000000",
                    }}
                    onClick={() => history.push("/candidates/sign-up")}
                  >
                    Apply Now
                  </Button>
                </div>
              ))}
              <div className="job job-view-more">
                <Button
                  variant="light"
                  className="rewmp-btn"
                  style={{
                    fontSize: "14px",
                    padding: "0.75rem 2rem",
                    background: "#FBE4DC",
                    color: "#000000",
                  }}
                  onClick={() => history.push("/candidates/sign-up")}
                >
                  View More
                </Button>
              </div>
            </Slider>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}
