import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { logout } from "app/v2/Pages/common/login/login.service";

import { Avatar, Typography, Link } from "@mui/material";

import { UserContext } from "../../index";

import { useSnackbar } from "notistack";
import storageUtil from "../Utils/storageUtil";
import CloseIcon from "@mui/icons-material/Close";
import CustomDialog from "app/v2/components/CustomDialog";
import CustomButton from "app/v2/components/CustomButton";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import { addUpdateAppSettings, getAppSettings } from "./AdminSettings.services";

const useStyles = makeStyles((theme) => ({
  profileRoot: {
    margin: "24px",
    width: "417px",
  },
  name: {
    fontSize: "24px",
    fontWeight: 600,
  },
  designation: {
    fontWeight: 700,
    color: "#8C8C8C",
  },
  divider: {
    marginBottom: "16px",
  },
  settingLinksDiv: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "6px",
  },
  settingsDiv: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "6px",
  },
  settingLink: {
    fontSize: "18px",
    fontWeight: 600,
    color: theme.palette.common.black,
    margin: "16px 0",
    cursor: "pointer",
  },
  settingTitle: {
    fontSize: "12px",
    fontWeight: 600,
  },

  dialogBody: {
    // boxSizing: "content-box",
    // minWidth: "300px",
    maxWidth: "700px",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
  },
  logoutLink: {
    fontSize: "18px",
    fontWeight: 600,
    color: "#A8C1FF !important",
    padding: "16px 0",
    cursor: "pointer",
  },
  userImage: {
    width: "56px",
    height: "56px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
    marginBotom: "12px",
    "& .MuiAvatar-img": {
      objectFit: "scale-down",
    },
  },
  line: {
    margin: "24px 0 16px 0",
  },
}));

const dummyPersonalData = {
  profilePhoto: "/user_image.png",
  first_name: "Kyla",
  last_name: "Andrews",
  designation: "Admin",
};
const ProfileMenu = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //   const dispatch = useDispatch();
  const history = useHistory();
  const [drawerDetails, setDrawerDetails] = useContext(UserContext);

  // const personalDataBuf = useSelector(
  //   (state) => state.clientProfileInfo.clientInfo.employer
  // ); // add correct admin values when added in redux.

  const [personalData, setPersonalData] = useState(dummyPersonalData);
  const [dialogueOpen, setDialogOpen] = useState(false);

  const handleDefaultSettingsClick = () => {
    setDialogOpen(true);
  };

  const settingsLinksData = [
    {
      label: "Employees",
      // url: "/admin/employees",
    },
    {
      label: "Privacy Policy",
      //  url: "/agency/connections",
    },
    {
      label: "Terms and Conditions",
      //  url: "/agency/connections",
    },
    {
      label: "Cookie Policy",
      //  url: "/agency/connections",
    },
    {
      label: "Default Settings ",
      onClick: handleDefaultSettingsClick,
    },
  ];

  const closeDrawer = () => {
    setDrawerDetails({
      showDrawer: false,
      componentType: "",
      showHeading: true,
    });
  };

  const onLinkClick = (url) => {
    closeDrawer();
    history.push(url);
  };

  const onLogout = () => {
    logout()
      .then((res) => {
        if (res.message) {
          const key = enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        }
        storageUtil.removeItem("user");
        closeDrawer();
        history.push("/admin/login");
      })
      .catch((err) => {
        if (err.message) {
          enqueueSnackbar(err.message, {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        }
        console.error(err);
      });
  };

  const AdminPriceSettings = () => {
    const [adminSettingsData, setAdminSettingsData] = useState({});
    const [updateButtonDisabled, setUpdateButtonDisabled] = useState(true);
    const [defaultAdminSettings, setDefaultAdminSettings] = useState({});

    useEffect(() => {
      const initialSettings = {
        commissionRate: adminSettingsData?.commissionRate??"",
        paymentTermsInDays: adminSettingsData?.paymentTermsInDays??"",
        taxRate: adminSettingsData?.taxRate??"",
      };
      setDefaultAdminSettings(initialSettings);
      setUpdateButtonDisabled(true);
    }, [adminSettingsData]);

    const hanndleAdminDefaultSettings = (name, value) => {
      setDefaultAdminSettings((prevSettings) => ({
        ...prevSettings,
        [name]: value,
      }));
      setUpdateButtonDisabled(false);
    };

    const handleSubmit = () => {
      const request ={ settings: {
        commissionRate:parseInt(defaultAdminSettings?.commissionRate),
        paymentTermsInDays:Number(defaultAdminSettings?.paymentTermsInDays),
        taxRate:Number(defaultAdminSettings?.taxRate),
      }};

      //// console.log("request", request);

      const key = enqueueSnackbar(`Your action in progress. Please wait.`, {
        variant: "info",
        autoHideDuration: 3000,
        action: (key) => (
          <CloseIcon
            onClick={() => closeSnackbar(key)}
            style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
          />
        ),
      });

      addUpdateAppSettings(request)
        .then((res) => {
          if (res?.success) {
            const key = enqueueSnackbar(res?.message, {
              variant: "success",
              autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon
                  onClick={() => closeSnackbar(key)}
                  style={{
                    cursor: "pointer",
                    fontSize: "15",
                    marginTop: "-1px",
                  }}
                />
              ),
            });
            setUpdateButtonDisabled(true);
          } else {
            // Handle error cases
          }
        })
        .catch(() => {
          const key = enqueueSnackbar(
            `Your action was not successful. Please try again later.`,
            {
              variant: "error",
              autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon
                  onClick={() => closeSnackbar(key)}
                  style={{
                    cursor: "pointer",
                    fontSize: "15",
                    marginTop: "-1px",
                  }}
                />
              ),
            }
          );
        });
    };

    useEffect(() => {
      getAppSettings()
        .then((res) => {
          setAdminSettingsData(res?.data?.settings);
        })
        .catch((err) => {
          if(err?.message){
            const key = enqueueSnackbar(
              err?.message,
              {
                variant: "error",
                autoHideDuration: 3000,
                action: (key) => (
                  <CloseIcon
                    onClick={() => closeSnackbar(key)}
                    style={{
                      cursor: "pointer",
                      fontSize: "15",
                      marginTop: "-1px",
                    }}
                  />
                ),
              }
            );
          }else{

            const key = enqueueSnackbar(
              `Error in retrieving default settings. Please try again later.`,
              {
                variant: "error",
                autoHideDuration: 3000,
                action: (key) => (
                  <CloseIcon
                    onClick={() => closeSnackbar(key)}
                    style={{
                      cursor: "pointer",
                      fontSize: "15",
                      marginTop: "-1px",
                    }}
                  />
                ),
              }
            );
          }
         
        });
    }, []);

    return (
      <div>
        <div className="flex space-x-2">
          <div className="">
            <CustomTextfield
              id="commissionRate"
              label="COMMISSION RATE"
              name="commissionRate"
              type="number"
              value={defaultAdminSettings?.commissionRate}
              onChange={hanndleAdminDefaultSettings}
            />
          </div>
          <div className=" ">
            <CustomTextfield
              id="paymentTermsInDays"
              label="PAYMENT TERMS"
              name="paymentTermsInDays"
              type="number"
              value={defaultAdminSettings?.paymentTermsInDays}
              onChange={hanndleAdminDefaultSettings}
            />
          </div>
          <div className=" ">
            <CustomTextfield
              id="taxRate"
              label="TAX RATE"
              name="taxRate"
              type="number"
              value={defaultAdminSettings?.taxRate}
              onChange={hanndleAdminDefaultSettings}
            />
          </div>
        </div>
        <div className="flex justify-center mt-3">
          <CustomButton
            type="button1"
            customClasses={`text-[16px] font-semibold py-3 px-7 flex gap-x-[8px] ${
              updateButtonDisabled
                ? "cursor-not-allowed opacity-50"
                : "cursor-pointer"
            }`}
            onClick={handleSubmit}
            disabled={updateButtonDisabled}
          >
            Update Settings
          </CustomButton>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.profileRoot}>
      <Avatar
        alt="Profile icon"
        src={
          personalData?.profilePhoto
            ? personalData.profilePhoto
            : "/user_placeholder.svg"
        }
        className={classes.userImage}
        variant="square"
      />
      <div>
        <Typography className={classes.name}>
          {(personalData?.first_name ?? "-") +
            " " +
            (personalData?.last_name ?? "-")}
        </Typography>
        <Typography variant="caption" className={classes.designation}>
          {personalData?.designation ?? "-"}
        </Typography>
      </div>
      <hr className={classes.line} />
      <div className={classes.settingsDiv}>
        <Typography className={classes.settingTitle}>SETTINGS</Typography>
        <div className={classes.settingLinksDiv}>
          {settingsLinksData.map((settingLink) => (
            <Link
              key={`link-${settingLink?.label}`}
              onClick={() => {
                if (settingLink.onClick) {
                  settingLink.onClick();
                } else {
                  onLinkClick(settingLink.url);
                }
              }}
              className={classes.settingLink}
            >
              {settingLink.label}
            </Link>
          ))}
        </div>
        <Link onClick={onLogout} className={classes.logoutLink}>
          Logout
        </Link>
      </div>
      <CustomDialog
        open={dialogueOpen}
        setOpen={setDialogOpen}
        // noCloseAction={contractActionLoading}
        title={
          <div className="text-[16px] font-semibold text-center">
            Admin Settings
          </div>
        }
        content={
          <div className={classes.dialogBody}>
            <AdminPriceSettings />
          </div>
        }
      />{" "}
    </div>
  );
};

ProfileMenu.propTypes = {};

export default ProfileMenu;
