export const devConfigs = {
  // APIUrl: "https://localhost:8080",
  // APIUrl: "https://localhost:8080"+"/api/v2",
  APIUrl: "https://localhost:8080",
  // APIUrl: "https://stage.findpro.ai/api",
  homeUrl: "https://findpros.ai/",
  linkedinAuthHome: "https://localhost:8080",
  paymentConfig: {
    clientKey:
      "AYSgvI3MEjK2-Jfo6Hd5ujkN4tFskXmAfmjH9Q2QHDJNbWbqToLZaLPyB4ML6NhKc8ZiORAdFreWT2LN",
  },
};
