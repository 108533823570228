import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { Alert, FormControl, FormHelperText, Tooltip } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomLabel from "app/v2/components/filters/CustomLabel";
import CustomDialog from "app/v2/components/CustomDialog";
import CustomButton from "app/v2/components/CustomButton";
import SectionTitleAndIcon from "./SectionTitleAndIcon";
import AddIcon from "@mui/icons-material/Add";
import DialogTitle from "./DialogTitle";
import DataTile from "./DataTile";
import {
  isValidArray,
  capitalizeFirstLetter,
  isUndefined,
} from "app/v2/Pages/Utils/utilFunctions";
import { messages, monthNames } from "app/v2/Pages/Utils/constants";

const useStyles = makeStyles((theme) => ({
  addButton: {
    columnGap: "10px",
    border: "1px solid #EDEDED",
    padding: "12px 28px",
    fontSize: "14px",
    fontWeight: 600,
  },
  editOrDeleteIconButton: {
    padding: "10px",
    border: "1px solid #EDEDED",
  },
  dialogActionButton: {
    padding: "16px 28px",
    fontSize: "16px",
    fontWeight: 600,
    width: "100%",
  },
  datePicker: {
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        fontSize: "16px",
      },
    },
  },
  datePickerError: {
    "& .MuiInputBase-root": {
      border: "1px solid red",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        border: "1px solid red",
      },
    },
  },
  actionButton: {
    fontSize: "14px",
    fontWeight: 600,
    padding: "12px 28px",
  },
  cancelButton: {
    backgroundColor: `#EDEDED !important`,
    color: `${theme.palette.common.black} !important`,
  },
}));

const LanguageProficiency = ({ data, onOtherInfoChange }) => {
  const classes = useStyles();

  const [actionType, setActionType] = useState(""); // add,edit, or delete.
  const [selectedTileIndex, setSelectedTileIndex] = useState();
  const [actionDialogOpen, setActionDialogOpen] = useState(false);

  const isEditActionSelected = () => actionType === "edit";
  const isDeleteActionSelected = () => actionType === "delete";

  const onCloseActionDialog = () => {
    setActionDialogOpen(false);
  };

  const onActionClick = (actionType, cardIndex) => {
    // console.log({ actionType, cardIndex });
    setActionType(actionType);
    setActionDialogOpen(true);
    setSelectedTileIndex(cardIndex);
  };

  const DialogContent = () => {
    const [tileData, setTileData] = useState({
      languageCertificateTitle: "",
      issuedBy: "",
      issuedOn: {
        month: "",
        year: "",
      },
    });
    const [issuedDate, setIssuedDate] = useState();
    const [validIssuedDate, setValidIssuedDate] = useState();
    const [disableEditOrDelete, setDisableEditOrDelete] = useState();

    const onTileDataChange = (id, value) => {
      setTileData((prevTileData) => ({ ...prevTileData, [id]: value }));
    };

    const isValidDate = (dateDetails) => {
      return Boolean(dateDetails?.month) && Boolean(dateDetails?.year);
    };

    const handleDateChange = (date) => {
      const monthIndex = date?.month();
      const year = date?.year();
      const updatedDateDetails = { month: monthNames?.[monthIndex], year };
      setIssuedDate(date);
      setValidIssuedDate(isValidDate(updatedDateDetails));
      onTileDataChange("issuedOn", updatedDateDetails);
    };

    const onActivateEditOrDeleteButton = () => {
      const isValidCertificateTitle =
        tileData?.languageCertificateTitle?.length > 0;
      const isValidIssuingOrg = tileData?.issuedBy?.length > 0;

      const disableButton = !(
        isValidCertificateTitle &&
        isValidIssuingOrg &&
        validIssuedDate
      );

      // // console.log({
      //   isValidCertificateTitle ,
      //   isValidIssuingOrg ,
      //   isValidCourseName ,
      //   validIssuedDate
      //   disableButton,
      // });

      setDisableEditOrDelete(disableButton);
    };

    const isDateInvalid = () => {
      return !isUndefined(validIssuedDate) && !validIssuedDate; // on initial load it'll be undefined,and we dont have to show error message immediately,only when there's a change in date and it is invalid,then we have to show the message.
    };

    const onActionButtonClick = () => {
      let updatedLanguagesData = data;
      if (isDeleteActionSelected()) {
        updatedLanguagesData = updatedLanguagesData?.filter(
          (tileData, index) => index !== selectedTileIndex
        );
      } else if (isEditActionSelected()) {
        updatedLanguagesData[selectedTileIndex] = tileData;
      } else updatedLanguagesData.push(tileData);
      // console.log({
      //   selectedTileIndex,
      //   updatedLanguagesData,
      // });
      onOtherInfoChange("languages", updatedLanguagesData);
      onCloseActionDialog();
    };

    useEffect(() => {
      if (isEditActionSelected()) {
        const selectedCardData = data?.[selectedTileIndex];
        const issuedDateDetails = selectedCardData?.issuedOn;

        setTileData(selectedCardData);
        setIssuedDate((dateState) => {
          const year = issuedDateDetails?.year;
          const monthIndex = monthNames?.indexOf(issuedDateDetails?.month);
          dateState = dayjs().year(year).month(monthIndex);
          return dateState;
        });
        setValidIssuedDate(isValidDate(issuedDateDetails));
      }
    }, []);

    useEffect(() => {
      // console.log({ tileData });
      onActivateEditOrDeleteButton();
    }, [tileData]);

    return (
      // Setting a fixed width for now,later responsiveness will be checked
      <div className="p-[48px] w-[739px] box-content">
        {isDeleteActionSelected() ? (
          <>
            Are you sure you want to delete the selected language?
            <div className="flex justify-end gap-x-[16px] pt-[24px]">
              <CustomButton
                customClasses={clsx([
                  classes.actionButton,
                  classes.cancelButton,
                ])}
                onClick={onCloseActionDialog}
              >
                Cancel
              </CustomButton>
              <CustomButton
                type="button1"
                customClasses={classes.actionButton}
                onClick={onActionButtonClick}
              >
                Delete
              </CustomButton>
            </div>
          </>
        ) : (
          <>
            <div className="text-[24px] font-semibold mb-[33px]">
              {capitalizeFirstLetter(actionType)} Language
            </div>
            <div className="px-[16px] flex flex-col gap-y-[24px]">
              <CustomTextfield
                id="languageCertificateTitle"
                label="certificate title"
                value={tileData?.languageCertificateTitle}
                onChange={onTileDataChange}
              />

              <CustomTextfield
                id="issuedBy"
                label="issuing organization name"
                value={tileData?.issuedBy}
                onChange={onTileDataChange}
              />

              <div className="flex flex-col">
                <CustomLabel label="issued on" />
                <FormControl error={isDateInvalid()}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["year", "month"]}
                      disableFuture={true}
                      className={
                        isDateInvalid()
                          ? classes.datePickerError
                          : classes.datePicker
                      }
                      value={issuedDate}
                      onChange={(date) => {
                        handleDateChange(date);
                      }}
                    />
                  </LocalizationProvider>
                  {isDateInvalid() && (
                    <FormHelperText className="ml-0">
                      Please enter a valid date.
                    </FormHelperText>
                  )}
                </FormControl>
              </div>

              <Tooltip
                arrow
                title={disableEditOrDelete && "Please enter all the details."}
              >
                <div className="w-full">
                  <CustomButton
                    type="button1"
                    customClasses={classes.dialogActionButton}
                    disabled={disableEditOrDelete}
                    onClick={onActionButtonClick}
                  >
                    {capitalizeFirstLetter(actionType)}
                  </CustomButton>
                </div>
              </Tooltip>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="py-[36px] border-b-[1px] border-solid border-[#EDEDED]">
        <div className="flex justify-between items-start mb-[12px]">
          <SectionTitleAndIcon
            title="Language Proficiency"
            iconPath="/award.svg"
          />
          <CustomButton
            customClasses={classes.addButton}
            onClick={() => {
              onActionClick("add");
            }}
          >
            <AddIcon fontSize="small" />
            <div>Add Language Proficiency</div>
          </CustomButton>
        </div>
        <div className="flex flex-col gap-y-[16px] p-[16px] max-h-[269px] overflow-y-auto">
          {isValidArray(data) ? (
            <>
              {data?.map((entry, index) => (
                <div className="flex justify-between items-center">
                  <DataTile data={entry} />
                  <div className="flex gap-x-[24px]">
                    <button
                      className={classes.editOrDeleteIconButton}
                      onClick={() => {
                        onActionClick("edit", index);
                      }}
                    >
                      <img src="/edit.svg" />
                    </button>
                    <button
                      className={classes.editOrDeleteIconButton}
                      onClick={() => {
                        onActionClick("delete", index);
                      }}
                    >
                      <img src="/delete.svg" />
                    </button>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <Alert severity="info">{messages?.NO_DATA_FOUND}</Alert>
          )}
        </div>
      </div>
      <CustomDialog
        open={actionDialogOpen}
        setOpen={setActionDialogOpen}
        title={<DialogTitle title="Language" />}
        content={<DialogContent />}
      />
    </>
  );
};

LanguageProficiency.propTypes = {};

export default LanguageProficiency;
