import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAgencyInfo,changeStatusOfEmployee,getRoles, getEmployeeList, inviteEmployee, resendInvitation, updateEmployee, deleteAgencyEmployeeById } from "app/v2/services/agency/employee.service";




export const getRolesThunk = createAsyncThunk(
    "getRoles",
    async (request, thunkAPI) => {
      return getRoles().then((res)=>res);
    }
  );



export const getAllAgencyEmployeeInfoThunk = createAsyncThunk(
  "allAgencyEmployeeInfo",
  async (request, thunkAPI) => {
    return getEmployeeList(request);
  }
);


export const sendAgnecyEmployeeInviteThunk = createAsyncThunk(
    "sendAgencyEmployeeInvitation",
    async (request, thunkAPI) => {
      return inviteEmployee(request).then((res) => {
        // thunkAPI.dispatch(profileComplete(res.isProfileComplete));
        // updateProfileComplete(res.isProfileComplete);
        return res;
      });
    }
  );


  export const reSendAgencyEmployeeInvitationThunk = createAsyncThunk(
    "reSendAgencyEmployeeInvitation",
    async (request, thunkAPI) => {
      return resendInvitation(request).then((res) => {
        // thunkAPI.dispatch(profileComplete(res.isProfileComplete));
        // updateProfileComplete(res.isProfileComplete);
        return res;
      });
    }
  );


  export const UpdateAgencyEmployeeDetailsThunk = createAsyncThunk(
    "updateAgencyEmployeeDetails",
    async (request, thunkAPI) => {
      return updateEmployee(request).then((res) => {
        // thunkAPI.dispatch(profileComplete(res.isProfileComplete));
        // updateProfileComplete(res.isProfileComplete);
        return res;
      });
    }
  );

  export const ChangeOfStatusOfEmployeeThunk = createAsyncThunk(
    "changeStatusOfEmployee",
    async (request, thunkAPI) => {
      return changeStatusOfEmployee(request).then((res) => {
        // thunkAPI.dispatch(profileComplete(res.isProfileComplete));
        // updateProfileComplete(res.isProfileComplete);
        return res;
      });
    }
  );


  export const deleteAgencyEmployeeByIdThunk = createAsyncThunk(
    "deleteEmployeeById/delete",
    async (payload, thunkAPI) => {
      try {
        //const { emailId } = payload;
        const result = await deleteAgencyEmployeeById(payload);
        return { result };
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  );

  export const getAgencyInfoThunk = createAsyncThunk(
    "agencyInfo",
    async (request, thunkAPI) => {
      return getAgencyInfo();
    }
  );
  