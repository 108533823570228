import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";

export const getAllAgencies = async (bodyRequest) => {
  return fetchWrapper(`${configs.APIUrl}/admin/allAgencies`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: bodyRequest,

    //   {
    //     "limit": 100,
    //     "offset": 0,
    //     "country": "United State", // If don't want to filter companySize then don't this
    //     "industry": "Technology", // If don't want to filter companySize then don't this
    //     "companySize": 10, // [10,50] -> this will be a range for companySize // If don't want to filter companySize then don't this
    //     "order": "oldest" //asc, desc, oldest, latest /// asc order result by company name from atoz, desc order result by company name from ztoa
    // }
  });
};

export const updatePlan = async (bodyRequest) => {
  return fetchWrapper(`${configs.APIUrl}/admin/subscribeOrganization`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: bodyRequest,
  });
};
export const getAgencyDetail = async (agencyID) => {
  return fetchWrapper(`${configs.APIUrl}/admin/agencyInfo/${agencyID}`);
};
export const getAgencyJobList = async (empID) => {
  return fetchWrapper(`${configs.APIUrl}/admin/agencyInfo/jobs/${empID}`);
};


export const restrictAgencyApi = async (agencyID, status) => {
  return fetchWrapper(
    `${configs.APIUrl}/admin/agency/${agencyID}/${status}`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteAgencyApi = async (agencyID) => {
  return fetchWrapper(
    `${configs.APIUrl}/admin/agency/${agencyID}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
