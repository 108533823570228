import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getMyJobsThunk, getPostedJobsThunk } from "../../../store/features/employer/jobFeed/jobFeed.thunk";

import PropTypes from "prop-types";
import { Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Title from "../../common/Title";
import CustomButton from "app/v2/components/CustomButton";
import JobFeedBody from "./JobFeedBody";
import { useHistory } from "react-router-dom";

import { useSnackbar } from "notistack";
import { messages } from "../../Utils/constants";
import ContentLoader from "react-content-loader";

const useStyles = makeStyles((theme) => ({
  jobFeedContainer: {
    minHeight: "calc(100vh - 382px)", // total viewport height minus navbar and footer's heights, minHeight:100% did not work,hence had to set it as pre parent div defined in EmployerRoute.
  },
  titleAndActionDiv: {
    display: "flex",
    position: "fixed",
    width: "100%",
    maxWidth: "1440px",
    borderBottom: "1px solid #EDEDED",
    backgroundColor: "white",
    zIndex: 1000,
    justifyContent: "space-between",
    alignItems: "center",
  },
  jobFeedBody: {
    paddingTop: "102px", // titleAndActionDiv's total hieght is 102.
  },
  createJobDiv: {
    display: "flex",
    justifyContent: "flex-end",
  },
  createJobButton: {
    marginRight: "24px",
    padding: "12px 28px",
    marginBottom: "21px",
    margin: "10px",
    fontSize: "16px",
    boxSizing: "content-box",
  },
}));

const JobFeed = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [postedJobs, setPostedJobs] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);

  const dispatch = useDispatch();
  const personalDataBuf = useSelector((state) => state.jobs.jobs.data);

  useEffect(() => {
    if (!personalDataBuf) {
      // dispatch(getPostedJobsThunk())
      dispatch(getMyJobsThunk())
        .unwrap()
        .then((res) => {
          setDataLoading(false);
          setPostedJobs(res?.data);
        })
        .catch((err) => {
          setDataLoading(false);
          setDataError(true);
        });
    } else {
      setDataLoading(false);
      setPostedJobs(personalDataBuf);
    }
  }, [dispatch]);

  return (
    <div className={classes.jobFeedContainer}>
      <div className={classes.titleAndActionDiv}>
        <Title title="Manage Jobs" classNameTitle={"sm:my-[4px] sm:ml-[4px] sm:text-[20px]"} />
        <div className={classes.createJobDiv}>
          <CustomButton
            type="button1"
            customClasses={classes.createJobButton}
            onClick={() => {
              history.push("/client/create-job");
            }}
          >
            Create Job
          </CustomButton>
        </div>
      </div>
      <div className={classes.jobFeedBody}>
        {dataLoading ? (
          <div className="flex-1 flex justify-center items-center ">
            {/* <CircularProgress /> */}

            <ContentLoader
              speed={2}
              width={1400}
              height={800}
              viewBox="0 0 1400 800"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="5" y="50" rx="3" ry="40" width="250" height="700" />
              <rect x="280" y="50" rx="3" ry="40" width="1300" height="700" />
            </ContentLoader>
          </div>
        ) : (
          <>
            {dataError ? (
              <Alert severity="error" className="w-full h-fit mt-[16px]">
                {messages?.GENERIC_ERROR_MESSAGE}{" "}
              </Alert>
            ) : (
              <>
                {postedJobs?.length > 0 ? (
                  <JobFeedBody data={postedJobs} setData={setPostedJobs} />
                ) : (
                  <Alert severity="info" className="w-full h-fit mt-[16px]">
                    {" "}
                    {messages?.NO_DATA_FOUND}{" "}
                  </Alert>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

JobFeed.propTypes = {};

export default JobFeed;
