import configs from "../Pages/Utils/configs";
import fetchWrapper from "../Pages/Utils/fetchWrapper";
import { isValidArray } from "../Pages/Utils/utilFunctions";

export const miscService = {
  async fetchRoles() {
    const { data } = await fetchWrapper(`${configs.APIUrl}/misc/roles`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return data;
  },
  async fetchSkillLevels() {
    const { data } = await fetchWrapper(`${configs.APIUrl}/misc/skillLevels`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return data;
  },
  async fetchSkills() {
    const { data } = await fetchWrapper(`${configs.APIUrl}/misc/skills`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return data;
  },
  async fetchExperiences() {
    const { data } = await fetchWrapper(`${configs.APIUrl}/misc/experiences`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return data;
  },
  async fetchContractTypes() {
    const { data } = await fetchWrapper(
      `${configs.APIUrl}/misc/contractTypes`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  },
  async getFAQs() {
    const { data } = await fetchWrapper(
      // `${configs.APIUrl}/misc/faqs?userType=consultant`,
      `${configs.APIUrl}/misc/faqs`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  },
  async fetchIndustryData() {
    let { data } = await fetchWrapper(`${configs.APIUrl}/industry`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (isValidArray(data)) {
      data.sort((entry1, entry2) => {
        const industryName1 = entry1?.name || "ZZZZZ"; // Treats undefined name as greater than any other name and shows that entry at the end of the list.
        const industryName2 = entry2?.name || "ZZZZZ";

        return industryName1.localeCompare(industryName2);
      });
    }
    return data;
  },
};

export const s3Upload = (formdata) =>
  fetchWrapper(`${configs.APIUrl}/misc/s3-upload/`, {
    method: "POST",
    body: formdata,
  });

  export const updateAgencyOrganizationInfo = async (request) => {
    // return fetchWrapper(`${configs.APIUrl}/employers/update-info`, {
    return fetchWrapper(`${configs.APIUrl}/agency/update-info`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: request,
    });
  };
  
  export const updateAgencyEmployeeInfo = async (request) => {
    return fetchWrapper(`${configs.APIUrl}/agency/updateEmployee`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: request,
    });
  };