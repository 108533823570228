import React, { useState, useEffect } from "react";
import { getConsultantInvoiceByJob, updateInvoiceStatus } from "app/v2/services/client/timesheet.service";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Alert, Button, CircularProgress } from "@mui/material";
import CustomButton from "app/v2/components/CustomButton";
import FilePreview from "../../common/FilePreview";
import CustomDialog from "app/v2/components/CustomDialog";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import configs from "../../Utils/configs";
import FilePreview2 from "../../common/FilePreview2";

const Invoice = (details) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [dataError, setDataError] = useState(false);
  const [pdfLink, setPdfLink] = useState("");
  const [invoiceDialogueOpen, setInvoiceDialogueOpen] = useState(false);
  const [payDialogueOpen, setPayDialogueOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [invoiceDetailsData, setInvoiceDetailsData] = useState({});

  const consultantId =
    details?.details?.drawerDetails?.contractActivity?.consultantDetails?._id;
  const jobId =
    details?.details?.drawerDetails?.contractActivity?.jobDetails?._id;

  const orgId = localStorage.getItem("orgId");
  const planId = localStorage.getItem("planId");

  const handleStartDateChange = (date) => {
    setStartDate(date);

    if (date && endDate && date > endDate) {
      setEndDate(null);
    }
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const fetchInvoiceData = (start, end) => {
    setDataLoading(true);
    const request = {
      jobId: jobId,
      consultantId: consultantId,
      limit: 10,
      offset: 0,
      startDate: start,
      endDate: end,
    };

    getConsultantInvoiceByJob(request)
      .then((res) => {
        if (res?.success) {
          setDataLoading(false);
          setInvoiceDetailsData(res?.data);
        } else {
          setInvoiceDetailsData([]);
        }
      })
      .catch(() => {
        setDataLoading(false);
        setDataError(true);
      });
  };
  const updateInvoiceData = () => {

    //// console.log("invoiceData---", invoiceData);
    setDataLoading(true);
 

    updateInvoiceStatus(invoiceData["_id"])
      .then((res) => {
        if (res?.success) {
          setDataLoading(false);
        //  setInvoiceDetailsData(res?.data);
        } else {
         // setInvoiceDetailsData([]);
        }
      })
      .catch(() => {
        setDataLoading(false);
        setDataError(true);
      });
  };

  // useEffect(() => {
  //   // Calculate the start date (Monday) and end date (Sunday) of the current week
  //   const currentDate = new Date();
  //   const currentDayOfWeek = currentDate.getDay();
  //   const diff =
  //     currentDate.getDate() -
  //     currentDayOfWeek +
  //     (currentDayOfWeek === 0 ? -6 : 1);
  //   const startOfWeek = new Date(currentDate.setDate(diff));
  //   const endOfWeek = new Date(startOfWeek);
  //   endOfWeek.setDate(startOfWeek.getDate() + 6);

  //   setStartDate(startOfWeek);
  //   setEndDate(endOfWeek);

  //   // Fetch data for the current week
  //   fetchInvoiceData(startOfWeek, endOfWeek);
  // }, [updateInvoiceDetailsApi]);

  useEffect(() => {
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const startOfWeek = new Date(startOfMonth);
    startOfWeek.setDate(startOfWeek.getDate() - 7);

    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    const endOfWeek = new Date(endOfMonth);
    endOfWeek.setDate(endOfWeek.getDate() + 7);

    setStartDate(startOfWeek);
    setEndDate(endOfWeek);

    fetchInvoiceData(startOfWeek, endOfWeek);
  }, []);

  const handleSubmit = () => {
    fetchInvoiceData(startDate, endDate);
  };
  const handlePayment = (invoice) => {
    setPayDialogueOpen(true);
    setInvoiceData(invoice);
  };
  const handleViewInvoice = (pdflink) => {
    setPdfLink(pdflink);
    setInvoiceDialogueOpen(true);
  };

  const PaymentDialogBody = () => {
    const [paymentStatusDialog, setPaymentStatusDialog] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");
    return (
      <div className="box-border min-w-[486px] p-12 flex flex-col gap-4">
        <div>
          Are you sure you want to pay the invoice no.{" "}
          <b>{invoiceData?.invoiceNumber}</b> of amount{" "}
          <b>${invoiceData?.netInvoiceAmount}</b>?
        </div>
        <PayPalScriptProvider
          options={{
            "client-id": configs.paymentConfig.clientKey,
            vault: true,
            intent: "capture",
          }}
        >
          {/* client-id will be stored in the config for now */}
          <PayPalButtons
            style={{
              layout: "horizontal",
              color: "silver",
              shape: "pill",
              label: "pay",
            }}
            // createSubscription={(data, actions) => {
            //   return actions.subscription.create({
            //     plan_id: "P-14Y611770K0857420MWVLYQQ", // 14 days trial after monlty subsc
            //     custom_id: "656152700dbaa67a3bf5ca62", // pass the userId
            //   });
            // }}

            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: invoiceData?.netInvoiceAmount.toString(),
                    },
                    custom_id: `${invoiceData?._id}`,
                  },
                ],
              });
            }}
            onApprove={(data, actions) => {
              //// console.log("Subscription approved:", data);
              // localStorage.clear();//
              // window.location.href = "/client/subscription-payment-success";
              setPayDialogueOpen(false);
              setPaymentStatusDialog(true);

              setTimeout(() => {
                setPayDialogueOpen(false);
              }, 500);

              setStatusMessage(
                `Your payment of amount  $${invoiceData?.netInvoiceAmount} was successful`
              );

              const key = enqueueSnackbar(
                `Your payment of amount $${invoiceData?.netInvoiceAmount} was successful`,
                {
                  variant: "success",
                  autoHideDuration: 3000,
                  action: (key) => (
                    <CloseIcon
                      onClick={() => closeSnackbar(key)}
                      style={{
                        cursor: "pointer",
                        fontSize: "15",
                        marginTop: "-1px",
                      }}
                    />
                  ),
                }
              );

// Assuming you have a function to handle payment success
  // Find the index of the entry in the invoices array
  //// console.log("invoiceData", invoiceData);
  const index = invoiceDetailsData.invoices.findIndex(entry => entry._id === invoiceData._id);
  if (index !== -1) {
      // Update the disabled property for the particular record
      const updatedInvoices = [...invoiceDetailsData.invoices];
      updatedInvoices[index] = {
          ...updatedInvoices[index],
          disabled: true // or set to false based on your logic
      };
      // Update the state with the modified invoices array
      setInvoiceDetailsData({
          ...invoiceDetailsData,
          invoices: updatedInvoices
      });
  }

  updateInvoiceData();
            }}
            onError={(err) => {
              console.error("Subscription error:", err);
              // window.location.href = "/client/subscription-payment-error";

              setPayDialogueOpen(false);
              setPaymentStatusDialog(true);
              if (invoiceData?.netInvoiceAmount === 0) {
                const key = enqueueSnackbar(
                  "Error: The purchase amount cannot be zero.",
                  {
                    variant: "error",
                    autoHideDuration: 5000,
                    action: (key) => (
                      <CloseIcon
                        onClick={() => closeSnackbar(key)}
                        style={{
                          cursor: "pointer",
                          fontSize: "15",
                          marginTop: "-1px",
                        }}
                      />
                    ),
                  }
                );
              } else {
                const key = enqueueSnackbar(
                  "Error occurred while processing payment!",
                  {
                    variant: "error",
                    autoHideDuration: 5000,
                    action: (key) => (
                      <CloseIcon
                        onClick={() => closeSnackbar(key)}
                        style={{
                          cursor: "pointer",
                          fontSize: "15",
                          marginTop: "-1px",
                        }}
                      />
                    ),
                  }
                );
              }
            }}
          />
        </PayPalScriptProvider>
        <CustomDialog
          open={paymentStatusDialog}
          setOpen={setPaymentStatusDialog}
          title={
            <div className="text-[16px] font-semibold text-center">
              Payment Status
            </div>
          }
          content={
            <div className="box-border min-w-[486px] p-12 flex flex-col gap-4">
              <b>{statusMessage}</b>
            </div>
          }
        />
      </div>
    );
  };

  return (
    <div className="p-8  w-full">
      <h3>Invoice</h3>
      <div className="flex space-x-6 mb-4 mt-4">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className="flex space-x-2">
            <DatePicker
              value={startDate}
              onChange={handleStartDateChange}
              placeholderText={`Select start date`}
              selected={startDate}
            />
            <DatePicker
              // label="End Date"
              value={endDate}
              onChange={handleEndDateChange}
              placeholderText={`Select end date`}
              selected={endDate}
              minDate={startDate}
            />
          </div>
        </LocalizationProvider>
        <CustomButton
          onClick={handleSubmit}
          type="button1"
          customClasses=" py-2 w-[130px] h-full "
          // disabled={isSubmitDisabled}
        >
          Submit
        </CustomButton>
      </div>

      {dataLoading ? (
        <CircularProgress />
      ) : (
        <>
          {dataError ? (
            <Alert severity="error">
              {" No record found for the selected criteria"}
            </Alert>
          ) : (
            <>
              {invoiceDetailsData &&
              invoiceDetailsData?.invoices?.length > 0 ? (
                <div className="overflow-x-auto mt-5">
                  <table className="min-w-full bg-white border border-gray-300">
                    <thead>
                      <tr>
                        <th className="py-2 px-4 border-b">INV NO</th>
                        <th className="py-2 px-4 border-b">Start - End Date</th>
                        <th className="py-2 px-4 border-b">Due Date</th>
                        <th className="py-2 px-4 border-b">Amount</th>
                        <th className="py-2 px-4 border-b">Status</th>
                        <th className="py-2 px-4 border-b">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceDetailsData && invoiceDetailsData?.invoices?.map((entry, index) => (
                        <tr key={index}>
                          <td className="py-2 px-4 border-r border-b">
                            {entry?.invoiceNumber}
                          </td>
                          <td className="py-2 px-4 border-r border-b">
                            {entry?.startDate
                              ? new Date(entry?.startDate).toLocaleDateString()
                              : "-"}{" "}
                            -{" "}
                            {entry?.endDate
                              ? new Date(entry?.endDate).toLocaleDateString()
                              : "-"}
                          </td>
                          <td className="py-2 px-4 border-r border-b">
                            {entry?.paymentDueDate
                              ? new Date(entry?.paymentDueDate).toLocaleDateString()
                              : "-"}
                          </td>
                          <td className="py-2 px-4 border-r border-b">
                            ${entry?.netInvoiceAmount}
                          </td>
                          <td className="py-2 px-4 border-r border-b">
                            {entry?.status}
                          </td>
                          <td className="py-2 px-2 border-r border-b">
                            <div className="flex flex-row space-x-2">
                              <CustomButton
                                onClick={() => handlePayment(entry)}
                                type="button1"
                                customClasses=" py-2 mb-2 mx-auto w-full !important "
                                disabled={
                                  entry?.status === "PAID" ||
                                  entry?.status === "CANCELLED" ||
                                  entry?.status === "IN PROGRESS" ||
                                  entry?.disabled
                                }
                              >
                              {entry?.status==="PAID"? "PAID" :"PAY"} 
                              </CustomButton>
                              <CustomButton
                                onClick={() =>{if (entry?.status == "PAID") {
                                  // If entry.status is "paid", call handleViewInvoice with entry.reciept
                                  handleViewInvoice(entry?.receipt);
                              } else {
                                  handleViewInvoice(entry?.file);
                              }}}
                                type="button1"
                                customClasses=" py-2 mb-2 mx-auto w-full !important "
                                // disabled={isSubmitDisabled}
                              >
                                View
                              </CustomButton>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <CustomDialog
                    open={invoiceDialogueOpen}
                    setOpen={setInvoiceDialogueOpen}
                    title={
                      <div className="text-[16px] font-semibold text-center">
                        Invoice
                      </div>
                    }
                    // content={<div className="h-[440px] w-[400px] p-2  "><FilePreview fileUrlOrContent={pdfLink} /></div>}
                    content={
                      <div className="h-[440px] w-[650px] p-2 ">
                        <FilePreview2 fileUrlOrContent={pdfLink} />
                      </div>
                    }
                  />
                  <CustomDialog
                    open={payDialogueOpen}
                    setOpen={setPayDialogueOpen}
                    title={
                      <div className="text-[16px] font-semibold text-center">
                        Pay Invoice
                      </div>
                    }
                    // content={<div className="h-[440px] w-[400px] p-2  "><FilePreview fileUrlOrContent={pdfLink} /></div>}
                    content={<PaymentDialogBody />}
                  />
                </div>
              ) : (
                <Alert severity="info">
                  {"No record found for the selected criteria"}
                </Alert>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Invoice;
