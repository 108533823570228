import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import PandaDocDetails from "./PandaDocDetails";
import { makeStyles } from "@mui/styles";
import { UserContext } from "app/v2";
import { Alert } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  contractDetailContainer: {
    padding: "12px 36px 36px",
  },
}));
const ContractDetail = (props) => {

  const [drawerDetails, setDrawerDetails] = useContext(UserContext);

const[docsData,setDocsDate]=useState(drawerDetails?.contractActivity?.contractsDocuments )

  //// console.log("contract details",drawerDetails)
  const classes = useStyles();
  // const docsData = [
  //   {
  //     docName:
  //       "CarolHemmings.CompanyABC.EmploymentAgreement - Open in Pandadoc.13241",
  //     createdDate: "March 2, 2023",
  //     docNo: "FP23147",
  //     docType: "CONTRACT",
  //   },
  //   {
  //     docName:
  //       "CarolHemmings.CompanyABC.EmploymentAgreement - Open in Pandadoc.13241",
  //     createdDate: "March 2, 2023",
  //     docNo: "FP23147",
  //     docType: "NON-DISCLOSURE AGREEMENT",
  //   },
  // ];
  const isLastDoc = (index) => index === docsData?.length - 1;

  const clientDocs = docsData?.filter((doc) =>
  doc.visibility.includes("client")
);

 // // console.log("docsData",docsData)
  return (
    <div className={classes.contractDetailContainer}>
    {clientDocs.length === 0 ? (
      <Alert severity="info"  className="w-full">
         No  documents are visible for client at this time.{" "}
      </Alert>
    ) : (
      clientDocs.map((docData) => (
        <PandaDocDetails key={docData._id} data={docData} />
      ))
    )}
  </div>
  );
};

ContractDetail.propTypes = {};

export default ContractDetail;
