import { createSlice } from "@reduxjs/toolkit";
import { fetchNotificationsThunk } from "./notificationsThunk";

const initialState = {
  notifications: [],
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    appendNotification: (state, action) => {
      //// console.log({ state, action });
      state.notifications.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      fetchNotificationsThunk.fulfilled.match,
      (state, { payload: notifications }) => {
        state.notifications = notifications;
      }
    );
  },
});

export const { appendNotification } = notificationsSlice.actions;

export const notificationsReducer = notificationsSlice.reducer;
