import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ContentLoader from "react-content-loader";

import { Typography, Link, Grid, Alert } from "@mui/material";

import CustomButton from "app/v2/components/CustomButton";
import SkillsPicker from "app/v2/Pages/common/SkillsPicker";
// import {
  
//   IframeSection,
//   Locations,
//   Employees,
//   Subscription,
// } from "./components";
import CustomTooltip from "app/v2/components/CustomTooltip";
import SocialShare from "../../common/SocialShare";

import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import CallMadeOutlinedIcon from "@mui/icons-material/CallMadeOutlined";

import img_icon_light_arrow_top from "../../images/img_icon_light_arrow_top.svg";

import { messages } from "app/v2/Pages/Utils/constants";
import { scrollToTop, isValidObject } from "app/v2/Pages/Utils/utilFunctions";
import configs from "app/v2/Pages/Utils/configs";
import CloseIcon from "@mui/icons-material/Close";

import {
  deleteAgencyApi,
  getAgencyDetail,
  restrictAgencyApi,
} from "app/v2/services/admin/manageAgency.services";
import { ClientHeader,IframeSection,
    Locations,
    Employees,
    Subscription, } from "../ClientDetail/components";

// "subscription": {
//   "planId": null,
//   "status": "FREE_TRIAL",
//   "transactionId": "",
//   "expiry": null,
//   "subscriptionId": "",
//   "autoPay": false,
//   "billing_cycle": "",
//   "createdAt": null,
//   "updatedAt": null
const useStyles = makeStyles((theme) => ({
  leftView: {
    padding: "24px 48px",
    width: "291px",
    boxSizing: "content-box",
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  primaryButton: {
    width: "100%",
    padding: "12px",
    fontSize: "16px",
    fontWeight: 600,
  },
  restrictButton: {
    width: "100%",
    padding: "12px",
    fontSize: "16px",
    fontWeight: 600,
    border: "1px solid #EDEDED",
  },
  secondaryButton: {
    width: "100%",
    maxWidth: "138px",
    padding: "12px",
    fontSize: "14px",
    fontWeight: 600,
    border: "1px solid #EDEDED",
  },
  secondaryActionsContainer: {
    display: "flex",
    justifyContent: "space-between",
    columnGap: "16px",
  },
  rightView: {
    borderLeft: "1px solid #EDEDED",
    marginLeft: "387px", // left view's total width since its fixed.
    padding: "48px",
    display: "flex",
    flexDirection: "column",
    rowGap: "32px",
  },
  buttonIcon: {
    marginLeft: "10px",
  },
}));

const AgencyDetail = ({ agencyID }) => {
  const history = useHistory();
  const classes = useStyles();

  const homeUrl = configs?.homeUrl;
  const publicClientDetailURL = agencyID
    ? homeUrl + `client-detail/${agencyID}`
    : null;

  const [data, setData] = useState();
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [empId, setEmpId] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const restrictClient = (agencyID) => {
    let status = data?.status === "Active" ? "false" : "true";
    restrictAgencyApi(agencyID, status)
      .then((res) => {
        if (res.message) {
          const key = enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        }
      })
      .catch((err) => {
        if (err.message) {
          enqueueSnackbar(err.message, {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        }
      });
  };

  const deleteClient = (agencyID) => {
    deleteAgencyApi(agencyID)
      .then((res) => {
        if (res?.message) {
          const key = enqueueSnackbar(res?.message, {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
          history.push(`/admin/manage-consultants`);
        }
      })
      .catch((err) => {
        if (err.message) {
          enqueueSnackbar(err.message, {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        }
      });
  };

  useEffect(() => {
    getAgencyDetail(agencyID)
      .then((res) => {
        const clientDataReceived = res?.data;
        // // console.log({ clientDataReceived });
        if (isValidObject(clientDataReceived)) {
          setData(clientDataReceived);
          setEmpId(clientDataReceived?.employees?.[0]._id);
        } else setDataError(true);
        setDataLoading(false);
      })
      .catch((error) => {
        setDataLoading(false);
        setDataError(true);
      });
  }, []);

  return (
    <>
      <>
        {dataLoading ? (
          <ContentLoader
            speed={2}
            width={1400}
            height={800}
            viewBox="0 0 1400 800"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="100" rx="3" ry="3" width="280" height="605" />
            <rect x="335" y="100" rx="3" ry="3" width="1200" height="605" />
          </ContentLoader>
        ) : (
          <>
            {dataError ? (
              <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
            ) : (
              <>
                <Grid container>
                  <Grid item xs={3} className={classes.leftView}>
                    <CustomButton
                      type="button1"
                      customClasses={classes.primaryButton}
                      onClick={() => {
                        history.push(`/admin/client-jobs/${empId}`);
                      }}
                    >
                      View Jobs
                      <img
                        src="/iconlightup-right.svg"
                        alt="arrow-icon"
                        className={classes.buttonIcon}
                      />
                    </CustomButton>
                    <CustomButton
                      type="button1"
                      customClasses={classes.primaryButton}
                      onClick={() => {}}
                    >
                      Contact
                      <img
                        src="/mail.png"
                        alt="mail-icon"
                        className={classes.buttonIcon}
                      />
                    </CustomButton>

                    <CustomButton
                      customClasses={classes.restrictButton}
                      onClick={() => {
                        deleteClient(agencyID);
                      }}
                    >
                      Restrict
                      <img
                        src="/deactivate.svg"
                        alt="deactivate-icon"
                        className={classes.buttonIcon}
                      />
                    </CustomButton>
                    <div className={classes.secondaryActionsContainer}>
                      <div className="flex-1">
                        {" "}
                        <CustomButton
                          customClasses={classes.secondaryButton}
                          onClick={() => {
                            restrictClient(agencyID);
                          }}
                        >
                          Delete
                          <img
                            src="/delete.svg"
                            alt="delete-icon"
                            className={classes.buttonIcon}
                          />
                        </CustomButton>
                      </div>
                      <CustomTooltip
                        arrow
                        title={
                          <SocialShare shareLink={publicClientDetailURL} />
                        }
                        bg="white"
                      >
                        <div className="flex-1">
                          <CustomButton
                            customClasses={classes.secondaryButton}
                            onClick={() => {}}
                          >
                            Share
                            <img
                              src="/share.svg"
                              alt="share-icon"
                              className={classes.buttonIcon}
                            />
                          </CustomButton>
                        </div>
                      </CustomTooltip>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    id="sectionContent"
                    className={classes.rightView}
                  >
                    <ClientHeader data={data} />
                    <hr />
                    <Subscription data={data.subscription} />
                    <IframeSection
                      title="About Company"
                      htmlString={data?.companyDesc?.htmlString}
                    />
                    <IframeSection
                      title="Company Culture"
                      htmlString={data?.culture?.htmlString}
                    />
                    <Locations data={data?.companyLocation} />
                    <Employees data={data?.employees} />
                    <div
                      className="flex items-center gap-x-[10px] pt-[36px] cursor-pointer"
                      onClick={() => {
                        scrollToTop();
                      }}
                    >
                      <img src={img_icon_light_arrow_top} />
                      <div className="font-semibold leading-[normal] text-base text-center text-gray-900">
                        Back to top
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </>
    </>
  );
};

AgencyDetail.propTypes = {};

export default AgencyDetail;
