import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";

import CloseIcon from "@mui/icons-material/Close";

import { isValidString } from "../../Utils/utilFunctions";
import { setNotificationAsRead } from "app/v2/services/notificationsService";

import { NotificationContext } from "app/v2";
import { getAccountType } from "app/v2/utils/loginUtil";

const useStyles = makeStyles((theme) => ({
  userImage: {
    width: "48px",
    height: "48px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
}));

const NotificationCard = ({ data, onCloseDrawer }) => {
  const classes = useStyles();
  const history = useHistory();
  const accountType = getAccountType();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [notificationDetails, setNotificationDetails] =
    useContext(NotificationContext);

  const actionsData = {
    a: [{ buttonText: "View profile", buttonOnClick: () => {} }],
    b: [{ buttonText: "Action 1", buttonOnClick: () => {} }],
    c: [{ buttonText: "Action 3", buttonOnClick: () => {} }],
  };

  const notificationType = data?.type;
  const actionsToRender = actionsData[notificationType];

  const onClick = () => {
    // console.log({ data });
    if (!data?.is_read) {
      setNotificationAsRead({ notificationIds: [data?.id] })
        .then(() => {
          setNotificationDetails((prevNotificationDetails) => {
            const readNotificationIDS = [
              ...(prevNotificationDetails?.readNotificationIDS ?? []),
            ];
            readNotificationIDS.push(data?.id);
            return { ...prevNotificationDetails, readNotificationIDS };
          });
        })
        .catch(() => {
          enqueueSnackbar(
            "Failed to mark notification as read. Please try again later.",
            {
              variant: "error",
              autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon
                  onClick={() => closeSnackbar(key)}
                  style={{
                    cursor: "pointer",
                    fontSize: "15",
                    marginTop: "-1px",
                  }}
                />
              ),
            }
          );
        });
    }

    if (isValidString(data?.link)) {
      onCloseDrawer();
      history.push(`/${accountType}/${data?.link}`);
    }
  };
  return (
    <div
      className={`cursor-pointer flex flex-col p-3 mt-2.5 mb-2.5 text-sm ${
        !data?.is_read && "bg-primary-100"
      }`}
      onClick={onClick}
    >
      <div className="flex items-center justify-between">
        <div
          className="relative leading-[130%]"
          dangerouslySetInnerHTML={{ __html: data?.message }}
        >
          {/* {data?.message} */}
        </div>
        <div className="relative text-3xs uppercase text-neutral-300 text-right">
          {data?.time}
        </div>
      </div>
    </div>
  );
};

NotificationCard.propTypes = {};

export default NotificationCard;
