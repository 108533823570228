import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";

export const getPostedJobs = async () => {
  // return fetchWrapper(`${configs.APIUrl}/client/posted-jobs`, {
  return fetchWrapper(`${configs.APIUrl}/jobs`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getMyJobs = async () => {
  // return fetchWrapper(`${configs.APIUrl}/client/posted-jobs`, {
  return fetchWrapper(`${configs.APIUrl}/client/my-jobs`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteJobById = async (jobID) => {
  return fetchWrapper(`${configs.APIUrl}/jobs/` + jobID, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const deleteEmployeeById = async (emailId) => {
  return fetchWrapper(`${configs.APIUrl}/client/deactivation/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: emailId,
  });
};

export const createJob = async (newJobData) => {
  return fetchWrapper(`${configs.APIUrl}/client/job`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: newJobData,
  });
};

export const updateJob = async (jobData) => {
  return fetchWrapper(`${configs.APIUrl}/client/job/${jobData?._id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: jobData,
  });
};

export const pinJob = async (jobID) => {
  return fetchWrapper(`${configs.APIUrl}/client/jobs/pinJob/` + jobID, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const setStatusJobById = async (jobID, status) => {
  return fetchWrapper(
    `${configs.APIUrl}/client/jobs/setStatus/` + jobID + "/" + status,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
