import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";

import { Text } from "../../common";
import DashboardHero from "../common/DashboardHero";
import FeatureJobListCard from "../common/Cards/FeatureJobs/FeatureJobListCard";
import FeatureJobGridCard from "../common/Cards/FeatureJobs/FeatureJobGridCard";
import CustomButton from "app/v2/components/CustomButton";
import { CircularProgress, Alert } from "@mui/material";
import { ArrowRightAlt } from "@mui/icons-material";
import { dashboardHeroObj } from "./DashboardHero.dummy";
import { messages } from "../../Utils/constants";
import { isValidArray } from "../../Utils/utilFunctions";
import { useDispatch, useSelector } from "react-redux";
import { getAgencyInfoThunk } from "app/v2/store/features/agency/employee.thunk";
import { getAdvanceJobSearch } from "../agencyApiServices/agency.services";
import storageUtil from "app/common/utils/storageUtil";

const useStyles = makeStyles((theme) => ({
  viewButton: {
    padding: "10px 20px 10px 15px",
    width: "126px",
    height: "38px",
    border: "1px solid #EDEDED",
    fontWeight: 600,
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  selectedViewButton: {
    backgroundColor: "#EDEDED !important",
    border: 0,
    color: theme.palette.secondary.light,
  },
  viewAllButton: {
    border: "1px solid #EDEDED",
    padding: "10px 15px 10px 20px",
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    color: `${theme.palette.primary.main} !important`,
    fontSize: "14px",
    fontWeight: 600,
  },
}));

const dummyJobData = [
  {
    _id: "1",
    image: "/company_image.png",
    jobTitle: "Software Developer 14",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    applicationStatus: "invited",
  },
  {
    _id: "2",
    image: "/company_image.png",
    jobTitle: "Software Developer 15",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    applicationStatus: "invited",
  },
  {
    _id: "3",
    image: "/company_image.png",
    jobTitle: "Software Developer 16",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    applicationStatus: "invited",
  },
  {
    _id: "4",
    image: "/company_image.png",
    jobTitle: "Software Developer 17",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    applicationStatus: "invited",
  },
];

const DashboardPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const profile = location?.state;

  const [dashboardHeroData, setDashboardHeroData] = useState();
  const [featuredJobs, setFeaturedJobs] = useState([]); // recommended jobs
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [jobSelectedView, setJobSelectedView] = useState("grid");
  const [personalData, setPersonalData] = useState(null);


  const onJobViewClick = (viewType) => {
    setJobSelectedView(viewType);
  };

  const AgencyDetail = useSelector(
    (state) => state?.agencyProfileInfo?.agencyInfo?.data
  );

  

  console.log("AgencyDetail", AgencyDetail);

  const isJobGridViewSelected = () => {
    return jobSelectedView === "grid";
  };

  const isProfileCompleteAndActiveJobsExist = () => {
    return (
      personalData?.agencyDetail.isProfileComplete 
      
    );
  };

  useEffect(() => {
    // add right api later.
    setDataLoading(true);

    dispatch(getAgencyInfoThunk())
      .unwrap()
      .then((res) => {
        setPersonalData(res?.data);
        var planName=  (res?.data?.agencyDetail?.orgDetails?.subscription?.planName) ?  (res?.data?.clientDetail?.orgDetails?.subscription?.planName.toLowerCase())  : "free";
          // setOnBoardingId(res?.data?.agencyDetail?.onBoardingId)
          storageUtil.setItem("planId", planName);

            console.log("agencyinfo",{ res });
            localStorage.setItem("roleName", res?.data?.agencyDetail?.name ?? "Admin");
  localStorage.setItem("orgId",res?.data?.agencyDetail?._id)
               setDataLoading(false);
        //         setFeaturedJobs(res);

      })
      .catch((error) => {
        setDataLoading(false);
        //      setDataError(true);
      });
    setDataLoading(false);
    setDashboardHeroData(dashboardHeroObj);
    // setFeaturedJobs(dummyJobData);
  }, []);


  useEffect(()=>{
    setDataLoading(true);

    getAdvanceJobSearch({

      jobTitle:[],
      sortBy:"atoz",
      limit: 4,
      offset: 0,

    })
    .then((res)=>{

      if(res){
        setDataLoading(false);

        setFeaturedJobs(res?.data)

      }
    })
    .catch((res)=>{

      setDataLoading(false);

    })

  },[])

  localStorage.setItem("orgId", personalData?.agencyDetail?.orgId);
  localStorage.setItem("roleId", personalData?.agencyDetail?.role?._id);
  localStorage.setItem("roleName", personalData?.agencyDetail?.role?.name ?? "Admin");

  localStorage.setItem("loginId", personalData?.agencyDetail?._id);
  localStorage.setItem(
    "planName",
    personalData?.agencyDetail?.orgDetails?.subscription?.planName?.toLowerCase() ??
      "free"
  );

  localStorage.setItem("duration", personalData?.agencyDetail?.orgDetails?.subscription?.duration);

  localStorage.setItem(
    "isSubscribedByAdmin",
    personalData?.agencyDetail?.orgDetails?.subscription?.isSubscribedByAdmin == true
    ? "true"  : "false");

  localStorage.setItem(
    "planId",
    personalData?.agencyDetail?.subscriptionData?.planId ?? " "
  );

  return (
    <>
      {dataLoading ? (
        <CircularProgress />
      ) : (
        <>
          {dataError ? (
            <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
          ) : (
            <div className=" mx-auto pt-0" style={{ width: "100%" }}>
              <div className="bg-white flex flex-col sm:gap-10 md:gap-10 gap-[101px] items-center justify-center mx-auto p-[45px] md:px-10 sm:px-5 ">
                <DashboardHero
                  // DashboardHeroObj={dashboardHeroData}
                  // Profile={profile}
                  props={personalData} //uncoment this code and comment the above two.samein dashboardHero commentout the whole code and uncomment the commente d code.

                />

                {/** Featured Jobs */}

                <div className="flex flex-col gap-[33px] items-start justify-center  mx-auto w-full xl:max-w-[1920px] ">
                  <Text className="text-[32px] font-semibold">
                    Featured Jobs
                  </Text>
                  {isValidArray(featuredJobs) ? (
                    <>
                      <div className="flex sm:flex-col flex-row md:gap-10 items-center justify-between  w-full">
                        <div className="flex flex-row gap-4 items-center justify-end w-auto">
                          <CustomButton
                            customClasses={clsx([
                              classes.viewButton,
                              isJobGridViewSelected() &&
                                classes.selectedViewButton,
                            ])}
                            onClick={() => {
                              onJobViewClick("grid");
                            }}
                          >
                            <img
                              src="/grid-view.svg"
                              className="mt-px mb-0.5 mr-2.5"
                            />
                            <div className="font-semibold leading-[normal] text-center text-gray-900 text-sm">
                              Grid View
                            </div>
                          </CustomButton>
                          <CustomButton
                            customClasses={clsx([
                              classes.viewButton,
                              !isJobGridViewSelected() &&
                                classes.selectedViewButton,
                            ])}
                            onClick={() => {
                              onJobViewClick("list");
                            }}
                          >
                            <img
                              src="/list-view.svg"
                              className="mt-px mb-0.5 mr-2.5"
                            />
                            <div className="font-semibold leading-[normal] text-center text-gray-900 text-sm">
                              List View
                            </div>
                          </CustomButton>
                        </div>
                        {/* <CustomButton
                          customClasses={classes.viewAllButton}
                          type="button2"
                          onClick={() => {
                            history.push("/agency/job-feed");
                          }}
                        >
                          View All
                          <ArrowRightAlt />
                        </CustomButton> */}
                      </div>
                      <div
                        className={`flex ${
                          isJobGridViewSelected()
                            ? "flex-row overflow-x-auto"
                            : "flex-col overflow-y-auto h-[450px]"
                        } gap-x-[16px] items-start justify-start w-full  pb-[12px]`}
                      >
                        {dataLoading ? (
                          <CircularProgress />
                        ) : (
                          <>
                            {dataError ? (
                              <Alert severity="error">
                                {messages?.GENERIC_ERROR_MESSAGE}{" "}
                              </Alert>
                            ) : (
                              <>
                                {isJobGridViewSelected()
                                  ? featuredJobs?.map((jobPostData, index) => (
                                      <FeatureJobGridCard data={jobPostData} />
                                    ))
                                  : featuredJobs?.map((jobPostData, index) => (
                                      <FeatureJobListCard data={jobPostData} />
                                    ))}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <Alert severity="info"> {messages?.NO_DATA_FOUND} </Alert>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

DashboardPage.propTypes = {};

export default DashboardPage;
