import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import { Typography } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import "./editor.scss";

import { isValidString } from "../../Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  editorCharCountMessage: {
    fontSize: "14px",
    color: "red",
  },
}));

const TinyMceEditor = ({
  id,
  data, // html string format
  setData,
  placeholder,
  disabled,
  requiredCharCount,
  insufficientCharCountMessage,
}) => {
  const classes = useStyles();
  placeholder = placeholder ?? "";
  const [editorData, setEditorData] = useState();
  const [isDataSufficient, setIsDataSufficient] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const onInitialLoad = (htmlString) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    let textContent = tempElement.textContent || tempElement.innerText || "";
    textContent = isValidString(textContent)
      ? textContent.replace(/[\n\r]+/g, "")
      : ""; // Remove new line occurences.
    // // console.log({ id, htmlString, text: textContent });
    if (requiredCharCount) {
      const dataSufficient = textContent?.length > requiredCharCount;
      setIsDataSufficient(dataSufficient);
    }
    setData(id, { htmlString, text: textContent });
  };

  const onEditorChange = (editor) => {
    const text = editor.getContent({ format: "text" }).replace(/[\n\r]+/g, ""); // Remove new line occurences.
    const htmlString = editor.getContent({ format: "html" });
    // // console.log({ id, htmlString, text });
    if (isInitialLoad) setIsInitialLoad(false); // Need to set it to false(if its still true) to avoid editor rerender issue in cases when the editor is used freshly for the first time(eg:Create job) and not update case.
    if (requiredCharCount) {
      const dataSufficient = text.length > requiredCharCount;
      setIsDataSufficient(dataSufficient);
    }
    setData(id, { htmlString, text });
  };

  useEffect(() => {
    if (data && isInitialLoad) {
      onInitialLoad(data);
      setIsInitialLoad(false);
      setEditorData(data);
    }
  }, [data]);

  return (
    <>
      <Editor
        // apiKey="6run41hpws7d4prh6x69qojfkl55mttg8kyibnhu6xajcdbk"
        apiKey="7y8t66vthdsyr73w7o3t18cbw1ffr70brhtduvnjnb67kw41"
        onEditorChange={(htmlString, editor) => {
          onEditorChange(editor);
        }}
        disabled={disabled}
        initialValue={editorData} // Even if the prop name is initialValue it still rerenders if editorData changes.
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic underline | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | ",
          content_style: "body { font-family:Montserrat,sans-serif;}",
          placeholder,
        }}
      />
      {!isDataSufficient && insufficientCharCountMessage && (
        <Typography className={classes.editorCharCountMessage}>
          {insufficientCharCountMessage}
        </Typography>
      )}
    </>
  );
};

TinyMceEditor.propTypes = {};

export default TinyMceEditor;
