import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import FilePreview from "app/v2/Pages/common/FilePreview";

import { Typography, Alert } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import SectionTitleAndIcon from "./SectionTitleAndIcon";
import CustomTooltip from "app/v2/components/CustomTooltip";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { messages, validDocFileMimeTypes } from "app/v2/Pages/Utils/constants";
import { s3Upload } from "app/v2/services/miscService";
import useFetch from "app/common/utils/useFetch";
import { isValidString } from "app/v2/Pages/Utils/utilFunctions";
import CloseIcon from "@mui/icons-material/Close";
import FilePreview2 from "app/v2/Pages/common/FilePreview2";

const useStyles = makeStyles((theme) => ({
  sectionSubHeading: {
    fontSize: "18px",
    fontWeight: 600,
    marginBottom: "16px",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  uploadOrAddButton: {
    columnGap: "8px",
    border: "1px solid #EDEDED",
    padding: "6px 8px",
    fontSize: "14px",
    fontWeight: 600,
  },
  infoIcon: {
    width: "20px",
    height: "20px",
    position: "relative",
    left: "4px",
    top: "3px",
  },
}));

const Resumes = ({ resumeDoc, videoResume, onOtherInfoChange }) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [get] = useFetch();

  const [resumeDocContent, setResumeDocContent] = useState();
  const [videoResumeContent, setVideoResumeContent] = useState();
  const uploadResumeDocRef = React.createRef();
  const uploadResumeVideoRef = React.createRef();


  const onFileUploadToS3 = async (file) => {
    let s3UploadedFileDetails;
    let errorInUpload = false;
    const formData = new FormData();
    formData.append("file", file);
    try {
      s3UploadedFileDetails = await get(s3Upload(formData));
    } catch (err) {
      errorInUpload = true;
    }

    return {
      uploadedUrl: s3UploadedFileDetails?.[0]?.location,
      errorInUpload,
    };
  };

  const onResumeDocUpload = async(event) => {
    const file = event.target.files[0];

    if (file) {
      const localFileUrl = URL.createObjectURL(file);
      const higherFileSize = file.size > 1048576 * 5; // 5 mb
      const isValidDoc = validDocFileMimeTypes.includes(file.type);
      if (higherFileSize || !isValidDoc) {
        // enqueueSnackbar(
        // higherFileSize
        //   ? messages.DOC_OR_VIDEO_FILE_SIZE_WARNING_OR_INFO
        //   : messages.RESUME_DOC_UPLOAD_EXTENSION_WARNING,
        //   {
        //     variant: "error",
        //   }
        // );

        const key = enqueueSnackbar(
          higherFileSize
            ? messages.DOC_OR_VIDEO_FILE_SIZE_WARNING_OR_INFO
            : messages.RESUME_DOC_UPLOAD_EXTENSION_WARNING,
          {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
        return;
      }else {
        let result = await onFileUploadToS3(file);
        setResumeDocContent(result?.uploadedUrl);

       
        const content = {
          file,
          localFileUrl,
          prevFileUrl: resumeDoc,
        };
        onOtherInfoChange("resumeDoc", content);
      }





      
     
    }
  };

  const onResumeVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const localFileUrl = URL.createObjectURL(file);
      const higherFileSize = file.size > 1048576 * 5; // 5 mb
      const IsAVideo = file.type.startsWith("video/");

      if (higherFileSize || !IsAVideo) {
        // enqueueSnackbar(
        // higherFileSize
        //   ? messages.DOC_OR_VIDEO_FILE_SIZE_WARNING_OR_INFO
        //   : messages.UPLOAD_VALID_VIDEO_WARNING,
        //   {
        //     variant: "error",
        //   }
        // );
        const key = enqueueSnackbar(
          higherFileSize
            ? messages.DOC_OR_VIDEO_FILE_SIZE_WARNING_OR_INFO
            : messages.UPLOAD_VALID_VIDEO_WARNING,
          {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
        return;
      }

      const content = {
        file,
        localFileUrl,
        prevFileUrl: videoResume,
      };
      setVideoResumeContent(content);
      onOtherInfoChange("videoResume", content);
    }
  };

  useEffect(() => {
    setResumeDocContent(resumeDoc);
  }, [resumeDoc]);

  useEffect(() => {
    setVideoResumeContent(videoResume);
  }, [videoResume]);

  return (
    <div className="py-[36px]">
      <SectionTitleAndIcon title="Resumes" iconPath="/comment.svg" />
      <div className="p-[16px]">
        <div className="flex justify-between mb-[12px]">
          <div className="flex">
            <Typography className={classes.sectionSubHeading}>
              Resume Document *
            </Typography>
            <CustomTooltip
              title={
                <>
                  <div>
                    1. {messages.DOC_OR_VIDEO_FILE_SIZE_WARNING_OR_INFO}
                  </div>
                  <div>2. {messages.RESUME_DOC_UPLOAD_EXTENSION_INFO}</div>
                </>
              }
              arrow
              placement="top"
            >
              <InfoIcon className={classes.infoIcon} />
            </CustomTooltip>
          </div>
          <div>
            <input
              type="file"
              accept=".doc, .docx, .pdf, .txt"
              className="hidden"
              onChange={onResumeDocUpload}
              ref={uploadResumeDocRef}
            />
            <button
              className={clsx([classes.button, classes.uploadOrAddButton])}
              onClick={() => uploadResumeDocRef.current.click()}
            >
              <div>Upload new</div>
              <FileUploadOutlinedIcon fontSize="small" />
            </button>
          </div>
        </div>
        <div className="mb-[24px]" key={resumeDoc || resumeDoc?.file}>
          <FilePreview
            fileUrlOrContent={resumeDocContent} // initially we receive a url in resumeDocContent(the pulic url), later when we attempt an upload we change it to an object.
          />
        </div>

        <div className="flex justify-between mb-[12px]">
          <div className="flex">
            <Typography className={classes.sectionSubHeading}>
              Video Resume 
            </Typography>
            <CustomTooltip
              title={
                <>
                  <div>
                    1. {messages.DOC_OR_VIDEO_FILE_SIZE_WARNING_OR_INFO}
                  </div>
                  <div>2. {messages.VIDEO_UPLOAD_EXTENSION_INFO}</div>
                </>
              }
              arrow
              placement="top"
            >
              <InfoIcon className={classes.infoIcon} />
            </CustomTooltip>
          </div>
          <div>
            <input
              type="file"
              accept="video/*"
              className="hidden"
              onChange={onResumeVideoUpload}
              ref={uploadResumeVideoRef}
            />
            <button
              className={clsx([classes.button, classes.uploadOrAddButton])}
              onClick={() => uploadResumeVideoRef.current.click()}
            >
              <div>Upload new</div>
              <FileUploadOutlinedIcon fontSize="small" />
            </button>
          </div>
        </div>
        {isValidString(videoResumeContent) ||
        videoResumeContent?.localFileUrl ? (
          <video
            className="video-player w-[100%] h-[433px]"
            controls
            key={videoResumeContent?.localFileUrl ?? videoResumeContent}
          >
            <source
              src={videoResumeContent?.localFileUrl ?? videoResumeContent}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        ) : (
          <Alert severity="info">{messages.NO_PREVIEW_AVAILABLE}</Alert>
        )}
      </div>
    </div>
  );
};

Resumes.propTypes = {};

export default Resumes;
