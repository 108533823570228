import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

import { Typography, Button, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import JobList from "../common/Cards/job/List";
import JobGrid from "../common/Cards/job/Grid";
import Title from "../../common/Title";
import SearchLoaderModal from "../../common/SearchLoaderModal";

import { jobAdvanceSearch, jobGlobalSearch } from "app/v2/services/consultant/common.service";

import { isValidArray, isValidString } from "../../Utils/utilFunctions";
import { messages } from "../../Utils/constants";
import { getJobsForConsultant } from "app/v2/services/consultant/common.service";

const useStyles = makeStyles((theme) => ({
  headerDiv: {
    position: "fixed",
    width: "1440px",
    borderBottom: "1px solid #EDEDED",
    backgroundColor: "white",
    zIndex: 1,
    paddingBottom: "44px",
  },
  jobCountAndViewTypesDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  jobPostsCountText: {
    fontWeight: 500,
    fontSize: "12px",
    color: "#717171",
    marginLeft: "25px",
    marginTop: "11px",
    marginBottom: "8px",
  },
  viewTypesDiv: {
    display: "flex",
    columnGap: "16px",
    marginRight: "24px",
  },
  viewButton: {
    padding: "10px 20px 10px 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "10px",
    border: "1px solid #EDEDED",
    backgroundColor: `${theme.palette.common.white} !important`,
    color: "#717171 !important",
    fontWeight: 600,
    fontSize: "14px",
  },
  selectedViewButton: {
    backgroundColor: `#EDEDED !important`,
    color: `${theme.palette.common.black} !important`,
  },
  jobFeedBodyContainer: {
    paddingTop: "234px",
    display: "flex",
    gap: "24px",
    flexWrap: "wrap",
  },
  loadMoreDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "76px",
    rowGap: "12px",
  },
  moreIcon: {
    color: theme.palette.secondary.light,
    padding: "8px",
    border: "1px solid #EDEDED",
    borderRadius: "4px",
  },
  loadMoreText: {
    fontSize: "14px",
  },
}));


// import React, { useState, useEffect, useRef } from 'react';
// import { Typography, Button, Alert } from '@material-ui/core';
// import clsx from 'clsx';
// import JobGrid from './JobGrid';
// import JobList from './JobList';
// import { useSnackbar } from 'notistack';
// import CloseIcon from '@material-ui/icons/Close';
// import SearchLoaderModal from './SearchLoaderModal'; // Assuming SearchLoaderModal is imported from the correct path

const JobFeed = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const advanceSearchParams = new URLSearchParams(location.search);
  const searchJobTitle = advanceSearchParams.get("jobTitle") || "";
  const jobsPerScroll = 12;

  const [jobsOffset, setJobsOffset] = useState(0);
  const [reachedLastItem, setReachedLastItem] = useState(false);
  const [totalCount, setTotalCount] = useState("");
  const [jobPostsData, setJobPostsData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [selectedViewType, setSelectedViewType] = useState("grid");
  const [loadingMore, setLoadingMore] = useState(false);
  const isGridViewSelected = () => selectedViewType === "grid";
  const [pageNumber, setPageNumber] = useState(0); // Add pageNumber state

  const loader = useRef(null);
  const selectedFiltersString = localStorage.getItem("selectedFilters");
  const selectedFilters = JSON.parse(
    selectedFiltersString
  )

  const onViewButtonClick = () => {
    setSelectedViewType(isGridViewSelected() ? "list" : "grid");
  };

  const fetchJobs = async () => {
    if (loadingMore) {
      return;
    }
    setLoadingMore(true); // Set loading flag to true to indicate API call is in progress

    // console.log("Current jobsOffset:", jobsOffset); // Add this line

    const country = advanceSearchParams.get("country") || "";
    const state = advanceSearchParams.get("state") || "";
    const city = advanceSearchParams.get("city") || "";
    const timezone = advanceSearchParams.get("timezone") || "";
    const experienceID = advanceSearchParams.get("experience") || "";
    const contractTypeID = advanceSearchParams.get("contractType") || "";
    const jobMode = advanceSearchParams.get("jobMode") || "";
    let sortBy = advanceSearchParams.get("sortBy") || "latest";
    let skillIDs = advanceSearchParams.get("skills") || "";
    let priceRange = advanceSearchParams.get("priceRange") || "";

    sortBy = sortBy.toLowerCase().replace(/ /g, "");
    skillIDs = isValidString(skillIDs) ? skillIDs.split(",") : [];
    priceRange = isValidString(priceRange) ? priceRange.split(",") : [];

    setDataLoading(true);

    if (props.location.state && props.location.state.isViewAll === true) {
      // console.log("from view all search")
      try {
        const res = await getJobsForConsultant(jobsPerScroll, jobsOffset);
        if (res) {
          setLoadingMore(false);

          const jobDataReceived = res?.data;
          let totalCount = res.count || 0;
          setDataLoading(false);
          if (isValidArray(jobDataReceived)) {
            setJobPostsData((prevJobs) => [...prevJobs, ...jobDataReceived]);
            setTotalCount(totalCount);
            setReachedLastItem(res?.count < jobsPerScroll);
          } else {
            setReachedLastItem(true);
          }
      } }catch (err) {
        setLoadingMore(false);

        enqueueSnackbar(
          "Something went wrong while fetching jobs, please try again later.",
          {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
      }

    }
    else {
      // console.log("from normal search11")
      const name = (searchJobTitle && searchJobTitle.trim()) 
    ? searchJobTitle 
    : (selectedFilters.tags && selectedFilters.tags.length > 0 && selectedFilters.tags[0].trim()) 
      ? selectedFilters.tags[0] 
      : '';

      console.log("name",name)
      jobGlobalSearch({
        searchTerm: [name],
        sortBy: sortBy, // atoz, ztoa,latest,lowrate,highrate,
        country,
        state,
        city,
        timezone,
        skills: skillIDs, // ["646f1c2f96656ed53aa32478"]
        jobType: contractTypeID, // jobType = contractType(db field), Need to pass the id.
        jobMode, // jobMode = workType( db field) // hybrid , remote , onsite
        workHistory: experienceID, // workHistory = experienceLevelId(db field),Need to pass the id.
        priceRange: priceRange,
        limit: jobsPerScroll,
        offset: jobsOffset,
      })
        .then((res) => {
          // console.log({ res });
          const jobDataReceived = res?.data;
          setDataLoading(false);
          if (isValidArray(jobDataReceived)) {
            setJobPostsData((prevJobs) => [...prevJobs, ...jobDataReceived]);
            setTotalCount(res.count);
          } else setReachedLastItem(true);
        })
        .catch((error) => {
          enqueueSnackbar(
            "Something went wrong while fetching jobs, please try again later.",
            {
              variant: "error",
              autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon
                  onClick={() => closeSnackbar(key)}
                  style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
                />
              ),
            }
          );
        });
    }
  };


  useEffect(() => {
    // console.log("jobsOffset changed:", jobsOffset); // Add this line

    fetchJobs();
  }, [jobsOffset]);

  useEffect(() => {
    const handleScrollFetch = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const documentHeight = document.documentElement.scrollHeight;
      if (
        scrollTop + windowHeight >= documentHeight - 0 &&
        !reachedLastItem
      ) {
        // Increment the jobsOffset state by 1
        setJobsOffset((prevOffset) => prevOffset + 1);
      }
    };
    
    if (!reachedLastItem

) {
      window.addEventListener("scroll", handleScrollFetch);
      return () => {
        window.removeEventListener("scroll", handleScrollFetch);
      };
    }
  }, [reachedLastItem, dataLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedViewType]);

  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && !reachedLastItem && !dataLoading) {
      fetchJobs();
    }
  };
  

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [reachedLastItem, dataLoading]);

  return (
    <div>
      {dataLoading ? (
        <SearchLoaderModal open={dataLoading} />
      ) : (
        <div>
          <div className={classes.headerDiv}>
            <Title
              title={searchJobTitle ? searchJobTitle : "SEARCH RESULTS"}
              topText={`${searchJobTitle ? "SEARCH RESULTS FOR" : ""}`}
            />
            <div className={classes.jobCountAndViewTypesDiv}>
              <Typography className={classes.jobPostsCountText}>
                {totalCount || 0} jobs found
              </Typography>
              <div className={classes.viewTypesDiv}>
                <Button
                  className={clsx([
                    classes.viewButton,
                    isGridViewSelected() && classes.selectedViewButton,
                  ])}
                  onClick={onViewButtonClick}
                >
                  <img src="/grid-view.svg" />
                  Grid View
                </Button>
                <Button
                  className={clsx([
                    classes.viewButton,
                    !isGridViewSelected() && classes.selectedViewButton,
                  ])}
                  onClick={onViewButtonClick}
                >
                  <img src="/list-view.svg" />
                  List View
                </Button>
              </div>
            </div>
          </div>

          <div className={classes.jobFeedBodyContainer}>
            {isValidArray(jobPostsData) ? (
              <>
                {jobPostsData?.map((jobPostData) => (
                  <>
                    {isGridViewSelected() ? (
                      <JobGrid data={jobPostData} />
                    ) : (
                      <JobList data={jobPostData} />
                    )}
                  </>
                ))}
              </>
            ) : (
              <Alert severity="info" className="w-full">
                {" "}
                {messages?.NO_DATA_FOUND}{" "}
              </Alert>
            )}
            <div ref={loader}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobFeed;

