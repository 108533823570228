import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPostedJobs,
  deleteJobById,
  createJob,
  updateJob,
  pinJob,
  setStatusJobById,
  getMyJobs,
  deleteEmployeeById
} from "../../../../services/client/jobFeed.service";

export const getPostedJobsThunk = createAsyncThunk(
  "postedJobs/get",
  async (request, thunkAPI) => {
    return getPostedJobs();
  }
);
export const getMyJobsThunk = createAsyncThunk(
  "myJobsJobs/get",
  async (request, thunkAPI) => {
    return getMyJobs();
  }
);

export const deleteJobByIdThunk = createAsyncThunk(
  "deleteJobById/delete",
  async (jobId, thunkAPI) => {
    try {
      const result = await deleteJobById(jobId);
      return { result, jobId };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const deleteEmployeeByIdThunk = createAsyncThunk(
  "deleteEmployeeById/delete",
  async (payload, thunkAPI) => {
    try {
      //const { emailId } = payload;
      const result = await deleteEmployeeById(payload);
      return { result };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);



export const createJobThunk = createAsyncThunk(
  "Job/create",
  async (newJobData, thunkAPI) => {
    try {
      const createdJob = await createJob(newJobData);

      return createdJob;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateJobThunk = createAsyncThunk(
  "Job/update",
  async (jobData, thunkAPI) => {
    try {
      const updatedJob = await updateJob(jobData);

      return updatedJob;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const pinJobThunk = createAsyncThunk(
  "jobs/pinJob",
  async (jobId, thunkAPI) => {
    try {
      const result = await pinJob(jobId);
      return { result, jobId };
      // return { jobId, isPinned: result.data.is_pinned };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const setStatusJobByIdThunk = createAsyncThunk(
  "jobs/setStatusJob",
  async ({ jobID, status }, thunkAPI) => {
    try {
      const result = await setStatusJobById(jobID, status);
      return { result, jobID, status };
      // return { jobId, isPinned: result.data.is_pinned };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
