import { createAsyncThunk } from "@reduxjs/toolkit";
import { profileComplete } from "app/common/login/login.slice";
import { getAllEmployeeInfo, getRoles, sendEmployeeInvitation,UpdateEmployeeDetails, reSendEmployeeInvitation } from "app/v2/services/client/employee.service";
import { getEmployeeDetail } from "app/v2/services/client/profileSettings.service";

export const getAllEmployeeInfoThunk = createAsyncThunk(
  "allEmployeeInfo",
  async (request, thunkAPI) => {
    return getAllEmployeeInfo();
  }
);
export const getEmployeeDetailThunk = createAsyncThunk(
  "employeeDetail",
  async (request, thunkAPI) => {
    return getEmployeeDetail(request).then((res)=>res);
  }
);

export const getRolesThunk = createAsyncThunk(
  "getRoles",
  async (request, thunkAPI) => {
    return getRoles().then((res)=>res);
  }
);



export const reSendEmployeeInvitationThunk = createAsyncThunk(
  "reSendEmployeeInvitation",
  async (request, thunkAPI) => {
    return reSendEmployeeInvitation(request).then((res) => {
      // thunkAPI.dispatch(profileComplete(res.isProfileComplete));
      // updateProfileComplete(res.isProfileComplete);
      return res;
    });
  }
);

export const sendEmployeeInvitationThunk = createAsyncThunk(
  "sendEmployeeInvitation",
  async (request, thunkAPI) => {
    return sendEmployeeInvitation(request).then((res) => {
      // thunkAPI.dispatch(profileComplete(res.isProfileComplete));
      // updateProfileComplete(res.isProfileComplete);
      return res;
    });
  }
);

export const UpdateEmployeeDetailsThunk = createAsyncThunk(
  "UpdateEmployeeDetails",
  async (request, thunkAPI) => {
    return UpdateEmployeeDetails(request).then((res) => {
      // thunkAPI.dispatch(profileComplete(res.isProfileComplete));
      // updateProfileComplete(res.isProfileComplete);
      return res;
    });
  }
);


// export const updateProfilePictureThunk = createAsyncThunk(
//   "profile-settings/profile-picture/put",
//   async (request, thunkAPI) => {
//     return updateEmployeeInfo(request).then((res) => {
//       thunkAPI.dispatch(profileComplete(res.isProfileComplete));
//       // updateProfileComplete(res.isProfileComplete);
//       return res;
//     });
//   }
// );

// export const updateClientOrganizationInfoThunk = createAsyncThunk(
//   "client-organization/put",
//   async (request, thunkAPI) => {
//     return updateClientOrganizationInfo(request).then((res) => {
//       return res;
//     });
//   }
// );
