import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";

export const getAdvanceConsultantSearch = async (request) => {
    return fetchWrapper(`${configs.APIUrl}/agency/advanceSearch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: request,
    });
  };

  export const getAdvanceJobSearch = async (request) => {
    return fetchWrapper(`${configs.APIUrl}/agency/job-search
`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: request,
    });
  };

  

  export const bookmarkApiForJobCard = async (request) => {
    return fetchWrapper(`${configs.APIUrl}/agency/save-unsave
`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: request,
    });
  };


 
export const getAgencyAllConsultants = async (bodyRequest) => {
  let queryString = "";

  if (bodyRequest) {
    const { limit, offset, sortBy, name, country, industry } = bodyRequest;
    queryString = `limit=${limit}&offset=${offset}&order=${sortBy || "newest"}`;
  } else {
    queryString = "order=newest";
  }
  return fetchWrapper(`${configs.APIUrl}/agency/allConsultants?${queryString}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    
  });
};


export const getAgencyMyJobs = async (bodyRequest) => {
  let queryString = "";

  if (bodyRequest) {
    const { limit, offset, sortBy, name, country, industry,status } = bodyRequest;
    // queryString = `status=${status}&limit=${limit}&offset=${offset}&order=${order || "newest"}&name=${name}`;
        queryString = `status=${status}limit=${limit}&offset=${offset}&order=${sortBy || "newest"}`;

  } else {
    queryString = `status= "" `;
  }
  return fetchWrapper(`${configs.APIUrl}/agency/my-jobs?${queryString}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    
  });
};

export const getJobdetailsViewApi = async (jobId) => {
  return fetchWrapper(`${configs.APIUrl}/jobs/${jobId}`
, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};






export const addDocuments = async (bodyRequest) => {
   // // console.log({ bodyRequest });
    return fetchWrapper(`${configs.APIUrl}/agency/addDocument`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: bodyRequest,
    });
  };
  
  export const updateDocuments = async (bodyRequest,documentId) => {
    //// console.log({ bodyRequest });
    return fetchWrapper(`${configs.APIUrl}/agency/updateDocument/${documentId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: bodyRequest,
    });
  };
  
  export const deleteDocument = async (documentId) => {
    return fetchWrapper(`${configs.APIUrl}/agency/deleteDocument/${documentId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
  
  export const getDocuments = async () => {
    return fetchWrapper(`${configs.APIUrl}/agency/getDocuments`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
  
  export const getDocumentTypes = async () => {
    return fetchWrapper(`${configs.APIUrl}/misc/documentTypes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
  export const getSubscriptionPlans = async () => {
    return fetchWrapper(`${configs.APIUrl}/agency/subscriptionPlan`);
  };
  
  