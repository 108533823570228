import { createSlice } from "@reduxjs/toolkit";
import { getPostedJobsThunk } from "./manage-jobs.thunk";

const initialState = {
  jobs: [],
  currentJob: {}
};

const manageJobsSlice = createSlice({
  name: "manage_jobs",
  initialState,
  reducers: {
    setCurrentJob: (state, action) => {
      //// console.log("current job ", action.payload);
      state.currentJob = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPostedJobsThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.jobs = action.payload;
        }
      })
  },
});

// Action creators are generated for each case reducer function
// export const { candidateInfoIn, candidateInfoOut } = resumeSettingsSlice.actions;

export const { setCurrentJob } = manageJobsSlice.actions;

export default manageJobsSlice.reducer;
