import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import ContentLoader from "react-content-loader";
import CheckIcon from "@mui/icons-material/Check";

import {
  Button,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

// import "bootstrap/dist/css/bootstrap.min.css";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import storageUtil from "../../../Utils/storageUtil";

import CustomDialog from "app/v2/components/CustomDialog";
import CustomButton from "app/v2/components/CustomButton";

import { messages } from "app/v2/Pages/Utils/constants";
import { isValidArray } from "app/v2/Pages/Utils/utilFunctions";

import { getSubscriptionPlans } from "app/v2/services/client/subscriptions.service";
import configs from "app/v2/Pages/Utils/configs";
import { getEmployerInfoThunk } from "app/v2/store/features/employer/profile-settings/profile-settings.thunk";
import { useDispatch } from "react-redux";
import { BsFillArrowLeftSquareFill } from "react-icons/bs";

const useStyles = makeStyles((theme) => ({
  yourPlanText: {
    fontSize: "32px",
    fontWeight: 600,
  },
  managePlanButton: {
    padding: "12px 28px",
    backgroundColor: "#EDEDED !important",
    fontSize: "16px",
    fontWeight: 600,
    color: theme.palette.common.black,
  },
  planNameText: {
    fontSize: "18px",
    fontWeight: 600,
    textAlign: "center",
  },
  planDetails: {
    border: "1px solid #EDEDED",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    rowGap: "19px",
  },
  includedPlanText: {
    fontSize: "16px",
    fontWeight: 500,
  },
  planFeatures: {
    listStyleType: "decimal",
    listStylePosition: "inside",
    display: "flex",
    flexDirection: "column",
    rowGap: "19px",
    marginLeft: "12px",
    marginBottom: 0,
    fontSize: "14px",
  },
  featureItems: {
    listStyleType: "disc",
    marginLeft: "36px",
  },
  featureItem: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontWeight: 600, // Set the font weight to semibold
  },
  checkIcon: {
    color: theme.palette.primary.main, // Set the color of the check icon to blue
  },
  dialogBody: {
    boxSizing: "content-box",
    // minWidth: "486px",
    padding: "48px",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  dialogBody2: {
    boxSizing: "content-box",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  dialogActionButton: {
    fontWeight: 600,
    paddingLeft: "28px",
    paddingRight: "28px",
  },

  plansDiv: {
    display: "flex",
    gap: "20px",
    flexWrap: "wrap",
    maxWidth: "795px",
    justifyContent: "center",
  },
  planCard: {
    padding: "28px",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
    border: "0.5px solid #717171",
    // maxHeight: "370px",
    maxWidth: "290px",
    flex: "1 1 50%",
    // position: "relative",
    overflowY: "auto",
  },
  currentPlanCard: {
    border: "0.5px solid #717171",
    backgroundColor: "#1c81ff",
  },
  planShowMoreAccordion: {
    width: "100% !important",
    boxShadow: "none",
    "&::before": {
      height: 0,
    },
  },
  currentPlanShowMoreAccordion: {
    backgroundColor: "#F7F7F7",
  },
  planShowMoreAccordionDetails: {
    padding: "8px 0 !important",
  },
  currentPlanDiv: {
    // position: "absolute",
    right: 0,
    top: 0,
    padding: "6px 12px",
    fontSize: "10px",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
  },
  selectPlanButton: {
    fontWeight: 600,
    paddingLeft: "15px",
    paddingRight: "15px",
    marginLeft: "85px",
    marginTop: "10px",
    fontSize: "16px",
    whiteSpace: "nowrap",
    textAlign: "center",
    backgroundColor: "black !important",
  },
  selectPlanButton2: {
    fontWeight: 600,
    paddingLeft: "15px",
    paddingRight: "15px",
    marginLeft: "85px",
    marginTop: "10px",
    fontSize: "16px",
    whiteSpace: "nowrap",
    textAlign: "center",
    backgroundColor: "black !important",
    "&:disabled": {
      display: "none", // Correct way to hide button when disabled
    },
  },

  planPriceDurationText: {
    fontSize: "30px",
    fontWeight: 800,
    textAlign: "center",
  },
  priceText: {
    fontSize: "12px",
    color: "#8C8C8C",
  },
  planDescription: {
    fontSize: "12px",
    marginTop: "8px",
  },
  divider: {
    marginBottom: 0,
  },
  showMoreText: {
    fontSize: "15px",
    fontWeight: "100px",
    padding: 0,
  },

  radio: {
    color: "darkgray",
    "&.Mui-checked": {
      color: "black",
    },
  },
  label: {
    fontWeight: "700",
    fontSize: "20px",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    flexWrap: "nowrap",
    textTransform: "capitalize",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  leftPanel: {
    flex: 1,
    paddingRight: "10px",
    borderRight: "2px solid gray",
  },
  rightPanel: {
    flex: 1,
    backgroundColor: "lightgray",
    padding: "10px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontSize: "15px",
    fontWeight: "600",
  },
  specialOffer: {
    backgroundColor: "lightgray",
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginLeft: theme.spacing(2),
    fontWeight: "bold",
    color: "dimgray",
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const PlanDetails = React.memo(() => {
  const classes = useStyles();
  const orgId = localStorage.getItem("orgId");
  const loginId = localStorage.getItem("loginId");
  const dispatch = useDispatch();

  //// console.log("loginId", loginId);
  // // console.log("orgId", orgId);
  const isTrialByadmin = localStorage.getItem("isSubscribedByAdmin"); //here we need to add the free plan Id from info api
 // const [isTrial, setIsTrial] = useState(subscriptionPlan === "free");
  const [isSubscribedByAdmin, setIsSubscribedByAdmin] = useState(isTrialByadmin === "true");

  const [managePlanDialogOpen, setManagePlanDialogOpen] = useState(false);
  const [currentPlanID, setCurrentPlanID] = useState("");
  const [currentPlanIdData, setCurrentPlanIdData] = useState([]);
  const [plans, setPlans] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [selectedPlanParentID, setSelectedPlanParentID] = useState("");
  const [selectedPlanDetails, setSelectedPlanDetails] = useState();
  const [cPConfirmDialogOpen, setCPConfirmDialogOpen] = useState(false);
  const [profileSubscriptionData, setProfileSubscritionData] = useState({});
  const [triggerCandidateIfoApi, setTriggerCandidateInfoApi] = useState(false);
  
  useEffect(() => {
    setDataLoading(true);

    const fetchData = async () => {
      try {
        // Fetch employer info
        const employerInfoResponse = await dispatch(
          getEmployerInfoThunk()
        ).unwrap();
        if (employerInfoResponse) {
          setProfileSubscritionData(
            employerInfoResponse?.data?.clientDetail?.orgDetails?.subscription
          );

          var planName=  (employerInfoResponse?.data?.clientDetail?.orgDetails?.subscription?.planName) ?  (employerInfoResponse?.data?.clientDetail?.orgDetails?.subscription?.planName.toLowerCase())  : "free";
          storageUtil.setItem("planName", planName);
          console.log("planName----", planName);
          // Immediately after setting profile data, fetch plans
          const plansResponse = await getSubscriptionPlans();
          if (isValidArray(plansResponse?.data)) {
            const plansReceived = plansResponse.data;
            const filteredPlans = filterPlansByPlanId(
              plansReceived,
              employerInfoResponse?.data?.clientDetail?.orgDetails?.subscription
                ?.planId
            );

            setPlans(plansReceived);
            setCurrentPlanIdData(filteredPlans);
            console.log("CurrentPlanIdData----", currentPlanIdData);

            setCurrentPlanID(
              employerInfoResponse?.data?.clientDetail?.orgDetails?.subscription
                ?.planId
            );
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setDataError(true);
      } finally {
        setDataLoading(false);
      }
    };

    fetchData();
  }, [dispatch, triggerCandidateIfoApi]);

  const reorderPlans = (plans) => {
    const selectedPlanIndex = plans.findIndex(
      (plan) => plan?.planId === currentPlanID
    );
    if (selectedPlanIndex !== -1) {
      const selectedPlan = plans.splice(selectedPlanIndex, 1)[0];
      plans.unshift(selectedPlan);
    }

    return plans;
  };
  // // console.log("profileSubscriptionData", profileSubscriptionData);
  function titleCase(input) {
    // Normalize the input by replacing all underscores with spaces
    const normalizedInput = input.replace(/_/g, " ");

    // Split the string into words based on spaces
    const words = normalizedInput.split(" ");

    // Capitalize the first letter of each word and join them back with spaces
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );

    return capitalizedWords.join(" ");
  }

  // function filterPlansByPlanId(plans, targetPlanId) {
  //   return plans.filter((plan) => {
  //     // Check top-level planId
  //     if (plan.planId === targetPlanId) {
  //       return true;
  //     }

  //     // Check nested planIds in the 'plan' array (if applicable)
  //     return (
  //       plan.plan &&
  //       plan.plan.some((subPlan) => subPlan.planId === targetPlanId)
  //     );
  //   });
  // }

  function filterPlansByPlanId(plans, targetPlanId) {
    return plans
      .filter((plan) => {
        // Check top-level planId or any nested planId
        return (
          plan.planId === targetPlanId ||
          (plan.plan &&
            plan.plan.some((subPlan) => subPlan.planId === targetPlanId))
        );
      })
      .map((plan) => {
        if (plan.planId === targetPlanId) {
          // If the main planId matches, return the entire plan
          return plan;
        } else {
          // If the main planId doesn't match, filter to keep only matching nested plans
          return {
            ...plan, // Spread the main plan object
            plan: plan.plan.filter(
              (subPlan) => subPlan.planId === targetPlanId
            ), // Filter only matching subPlans
          };
        }
      });
  }

  function capitalizeFirstLetter(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const freeplanData = plans.find(
    (plan) =>
      plan.name.toLowerCase() === "free" ||
      plan.price === "0" ||
      plan.price === "0.0"
  );

  //// console.log("freeplanData", freeplanData);

  // useEffect(() => {
  //   setDataLoading(true);
  //   getSubscriptionPlans()
  //     .then((response) => {
  //       // console.log("response", response);
  //       let plansReceived = response?.data;
  //       if (isValidArray(plansReceived)) {
  //         // plansReceived = reorderPlans(plansReceived);
  //         setPlans(plansReceived);
  //         // let filterCurrentPlanId = plansReceived?.filter(
  //         //   (plans) => plans?.planId === profileSubscriptionData?.planId
  //         // );

  //         let filterCurrentPlanId = filterPlansByPlanId(
  //           plansReceived,
  //           profileSubscriptionData?.planId
  //         );
  //         // console.log("filterCurrentPlanId", filterCurrentPlanId);
  //         // setSelectedPlanParentID()
  //         setCurrentPlanIdData(filterCurrentPlanId);
  //         setCurrentPlanID(profileSubscriptionData?.planId);
  //         setDataLoading(false);

  //         // console.log("filterCurrentPlanId", filterCurrentPlanId);
  //       }
  //     })
  //     .catch((error) => {
  //       setDataError(true);
  //       setDataLoading(false);
  //     });
  // }, [profileSubscriptionData, currentPlanID]);
  //// console.log("currentPlanIDData", currentPlanIdData);

  const PlanCard = ({
    planDetails,
    isCurrentPlan,
    onChangePlan,
    currentPlanID,
  }) => {
    const classes = useStyles();

    const subscriptionPlan = localStorage.getItem("planName"); //here we need to add the free plan Id from info api
    const [isTrial, setIsTrial] = useState(subscriptionPlan === "free");
    const isTrialByadmin = localStorage.getItem("isSubscribedByAdmin"); //here we need to add the free plan Id from info api
    const [isSubscribedByAdmin, setIsSubscribedByAdmin] = useState(isTrialByadmin == "true");
    const duration = localStorage.getItem("duration"); //here we need to add the free plan Id from info api

    const [selectedPlan, setSelectedPlan] = useState(
      planDetails?.plan?.[0]?.planId ?? ""
    );
    const [localSelectedPlan, setLocalSelectedPlan] = useState(selectedPlan || (isSubscribedByAdmin ? "trialPlan" : ""));

    const [isDisable, setIsDisable] = useState(false);

    const handleChange = (event) => {
      setSelectedPlan(event.target.value);
    };
    const handleLocalChange = (event) => {
      const { value } = event.target;
      setLocalSelectedPlan(value);
      handleChange(event);
    };
    const handleSelectPlan = () => {
      const plan = planDetails?.plan?.find(
        (plan) => plan?.planId === selectedPlan
      );
      onChangePlan(plan);
    };
    useEffect(() => {
      let disableButton = selectedPlan && selectedPlan === currentPlanID;
      setIsDisable(disableButton);
      //setIsSubscribedByAdmin(true)

    }, [selectedPlan]);
    useEffect(() => {
      if (isSubscribedByAdmin) {
        setLocalSelectedPlan("trialPlan");
      }
    }, [isSubscribedByAdmin]);
    // console.log("isDisable", isDisable);

    return (
      <div
        className={clsx([
          classes.planCard,
          isCurrentPlan && classes.currentPlanCard,
        ])}
      >
        {isCurrentPlan ? (
          <div className={classes.currentPlanDiv}>CURRENT PLAN</div>
        ) : (
          <></>
        )}
        <div className={classes.planNameText}>{planDetails?.name || "-"}</div>
        <div className={classes.container}>
          {planDetails && planDetails.plan && planDetails.plan?.length > 1 ? (
            <div className="flex-col items-center justify-between">
              <div className="flex">
                <div className={classes.leftPanel}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      name="plan"
                      value={localSelectedPlan}
                      onChange={handleLocalChange}
                    >
                      {planDetails?.plan?.map((detail, index) => (
                        <FormControlLabel
                          key={index}
                          value={detail?.planId}
                          control={<Radio className={classes.radio} />}
                          label={`$${detail?.price} /${
                            capitalizeFirstLetter(detail?.interval_unit) || "Month"
                          }`}
                          classes={{ label: classes.label }}
                        />
                      ))}
                      {isSubscribedByAdmin && (
                        <FormControlLabel
                          value="trialPlan"
                          control={<Radio className={classes.radio} />}
                          label={`Trial Plan: $0 / ${duration} Month`}
                          classes={{ label: classes.label }}
                        />
                      )}
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className={classes.rightPanel}>
                  <div>Save 33% by paying annually</div>
                </div>
              </div>
              <div>
                {!isCurrentPlan && localSelectedPlan !== "trialPlan" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.selectPlanButton}
                    onClick={handleSelectPlan}
                    disabled={isDisable}
                  >
                    Select Plan
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.selectPlanButton2}
                    onClick={handleSelectPlan}
                    disabled={isDisable}
                    style={{ display: localSelectedPlan === "trialPlan" ? "none" : "inline-block" }}
                  >
                    Select Plan
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <div className="flex-col justify-between items-center w-full">
              <div className="flex-1 text-center">
                <div className={classes.label}>
                  ${planDetails?.price || "-"}
                </div>
                <div className={classes.label}>
                  {/* /
                  {capitalizeFirstLetter(planDetails?.interval_unit) || "Month"} */}
                </div>
              </div>
  
              {!isCurrentPlan && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.selectPlanButton2}
                  onClick={() => onChangePlan(planDetails)}
                  disabled={
                    planDetails?.name === "Free" ||
                    planDetails?.planId === "P-4GV63933JY759354YMY2SMKY" ||
                    planDetails?.price === 0
                  }
                >
                  Select Plan
                </Button>
              )}
            </div>
          )}
        </div>
  
        <hr className={classes.divider} />
  
        <div className="flex flex-col gap-y-[16px]">
          {isValidArray(planDetails?.features) ? (
            planDetails?.features.map((feature, index) => (
              <div key={index} className={classes.featureItem}>
                <CheckIcon className={classes.checkIcon} />
                {feature?.name}
              </div>
            ))
          ) : (
            <Alert severity="info" className="w-full">
              No Features Found
            </Alert>
          )}
        </div>
      </div>
    );
  };
  

  const ChangePlanConfirmDialog = ({
    cPConfirmDialogOpen,
    setCPConfirmDialogOpen,
    selectedPlanDetails,
  }) => {
    // // console.log("selectedPlanDetails", selectedPlanDetails);
    // // console.log("plaId", selectedPlanDetails);

    const classes = useStyles();

    const onCloseDialog = () => {
      setCPConfirmDialogOpen(false);
    };

    const Title = () => {
      return (
        <div className="text-[16px] font-semibold text-center sm:w-full">
          Change Plan
        </div>
      );
    };

    const DialogBody = () => {
      let id;
      for (var i = 0; i < plans.length; i++) {
        var plan = plans[i];
        for (var j = 0; j < plan.plan.length; j++) {
          if (plan.plan[j]._id === selectedPlanDetails?._id) {
            // // console.log("Parent ID:", plan._id);
            id = plan._id;
            break;
          }
        }
      }
      //// console.log("got id----", id);
      return (
        <div className={classes.dialogBody}>
          <div>
            Are you sure you want to subscribe to plan{" "}
            <b>{selectedPlanDetails?.name}</b> ?
          </div>
          <PayPalScriptProvider
            options={{
              "client-id": configs.paymentConfig.clientKey,
              vault: true,
              intent: "capture",
            }}
          >
            {/* client-id will be stored in the config for now */}
            <PayPalButtons
              style={{
                layout: "horizontal",
                color: "silver",
                shape: "pill",
                label: "subscribe",
              }}
              createSubscription={(data, actions) => {
                // let id = findParentId(plans, selectedPlanDetails?._id)
                // // console.log("idis ------", id)
                return actions.subscription.create({
                  plan_id: `${selectedPlanDetails?.planId}`, // 14 days trial after monlty subsc
                  custom_id: `${orgId}|${loginId}|${id}`, // pass the userId
                });
              }}
              onApprove={(data, actions) => {
                // // console.log("Subscription approved:", data);
                // localStorage.clear();//
                window.location.href = "/client/subscription-payment-success";
                setTimeout(() => {
                  setTriggerCandidateInfoApi((prev) => !prev);
                }, 5000);
              }}
              onError={(err) => {
                console.error("Subscription error:", err);
                window.location.href = "/client/subscription-payment-error";
              }}
            />
          </PayPalScriptProvider>
        </div>
      );
    };

    return (
      <CustomDialog
        open={cPConfirmDialogOpen}
        setOpen={setCPConfirmDialogOpen}
        title={<Title />}
        content={<DialogBody />}
      />
    );
  };

  const PlanList = ({ isSubscribedByAdmin }) => {
    const classes = useStyles();

    const onChangePlan = (planDetails) => {
      setSelectedPlanDetails(planDetails);

      setCPConfirmDialogOpen(true);
    };

    // Shows current plan info first.

    return (
      <>
        {dataLoading ? (
          <ContentLoader
            speed={2}
            width={795}
            height={500}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="20" y="0" rx="3" ry="3" width="795" height="500" />
          </ContentLoader>
        ) : (
          <>
            {dataError ? (
              <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
            ) : (
              <>
                {isValidArray(plans) ? (
                  <div className={classes.plansDiv}>
                    {plans.map((planDetails) => (
                      <PlanCard
                        planDetails={planDetails}
                        isCurrentPlan={
                          planDetails.planId === currentPlanID ||
                          planDetails.plan.some(
                            (plan) => plan?.planId === currentPlanID
                          )
                        }
                        onChangePlan={onChangePlan}
                        currentPlanID={currentPlanID}
                      />
                    ))}
                  </div>
                ) : (
                  <Alert severity="info" className="w-full">
                    No Plans Found
                  </Alert>
                )}
                {cPConfirmDialogOpen && (
                  <ChangePlanConfirmDialog
                    cPConfirmDialogOpen={cPConfirmDialogOpen}
                    setCPConfirmDialogOpen={setCPConfirmDialogOpen}
                    selectedPlanDetails={selectedPlanDetails}
                  />
                )}
              </>
            )}
          </>
        )}
      </>
    );
  };

  const ManagePlanDialog = ({
    managePlanDialogOpen,
    setManagePlanDialogOpen,
    isSubscribedByAdmin
  }) => {
    const classes = useStyles();
   // const isTrialByadmin = localStorage.getItem("isSubscribedByAdmin"); //here we need to add the free plan Id from info api
    //const [isSubscribedByAdmin, setIsSubscribedByAdmin] = useState(isTrialByadmin == "true");

    const Title = () => {
      return (
        <div className="text-[16px] font-semibold text-center">Manage Plan2</div>
      );
    };

    const DialogBody = () => {
      return (
        <div className={classes.dialogBody2}>
        <PlanList isSubscribedByAdmin={isSubscribedByAdmin} />
        </div>
      );
    };

    return (
      <CustomDialog
        open={managePlanDialogOpen}
        setOpen={setManagePlanDialogOpen}
        title={<Title />}
        content={<DialogBody />}
      />
    );
  };

  return (
    <div className="flex flex-col gap-y-[36px]">
      <div className="flex justify-between">
        <div className={classes.yourPlanText}>Your Plan</div>
        <Button
          className={classes.managePlanButton}
          onClick={() => setManagePlanDialogOpen(true)}
        >
          Manage Plan
        </Button>
      </div>

      {dataLoading ? (
        <ContentLoader
          speed={2}
          width={795}
          height={500}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="20" y="0" rx="3" ry="3" width="795" height="500" />
        </ContentLoader>
      ) : (
        <>
          {currentPlanIdData && currentPlanIdData.length > 0 ? (
            currentPlanIdData?.map((feature) => (
              <div className="flex flex-col gap-y-[24px] px-[16px]">
                <div className={classes.planNameText}>
                  {titleCase(feature?.plan?.[0]?.name ?? "No_Name ")}
                </div>
                <div className={classes.planDetails}>
                  {/* <div>
            Habitasse urna proin at id nisl nam tellus. Ut id molestie vitae eu
            nisi fermentum. Ornare sed venenatis et libero semper sit. Mauris
            duis nam amet vel. Quis quis ut pretium mauris curabitur donec.
            Tortor id imperdiet morbi lectus sagittis eget. Pellentesque auctor
            ut quis varius. Tellus ante libero auctor turpis bibendum quis porta
            condimentum. Velit egestas quis in scelerisque porttitor amet.{" "}
          </div> */}
                  <div className={classes.includedPlanText}>
                    INCLUDED IN YOUR PLAN
                  </div>

                  <ol className={classes.planFeatures}>
                    {feature?.features?.map((feat, index) => (
                      <li key={index}>
                        {`${feat?.name ?? "Feature "}`}
                        {/* {feat?.featureitems?.map((items, idx) => (
                          <ul className={classes.featureItems}>
                            <li>{items?.name ?? "Feature item 1"}</li>
                          </ul>
                        ))} */}
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
            ))
          ) : (
            <div className="flex flex-col gap-y-[24px] px-[16px]">
              <div className={classes.planNameText}>
              {isSubscribedByAdmin ? "Trial Plan" : (freeplanData?.name ?? "No name")}
              </div>
              <div className={classes.planDetails}>
                {/* <div>
        Habitasse urna proin at id nisl nam tellus. Ut id molestie vitae eu
        nisi fermentum. Ornare sed venenatis et libero semper sit. Mauris
        duis nam amet vel. Quis quis ut pretium mauris curabitur donec.
        Tortor id imperdiet morbi lectus sagittis eget. Pellentesque auctor
        ut quis varius. Tellus ante libero auctor turpis bibendum quis porta
        condimentum. Velit egestas quis in scelerisque porttitor amet.{" "}
      </div> */}
                <div className={classes.includedPlanText}>
                  INCLUDED IN YOUR PLAN
                </div>

                <ol className={classes.planFeatures}>
                  {freeplanData?.features?.map((feat, index) => (
                    <li key={index}>
                      {`${feat?.name ?? "Feature 1"}`}
                      {/* {feat?.featureitems?.map((items, idx) => (
                        <ul className={classes.featureItems}>
                          <li>{items?.name ?? "Feature item 1"}</li>
                        </ul>
                      ))} */}
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          )}
          {managePlanDialogOpen && (
            <ManagePlanDialog
              managePlanDialogOpen={managePlanDialogOpen}
              setManagePlanDialogOpen={setManagePlanDialogOpen}
              isSubscribedByAdmin={isSubscribedByAdmin} // Pass the prop here

            />
          )}
        </>
      )}
    </div>
  );
});

export default PlanDetails;
