import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CustomSelect from "./CustomSelect";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import CountryList from "app/markup/country.json";
import TimezoneList from "app/markup/timezone.json";
import CustomTextfield from "./CustomTextfield";
import { messages } from "app/v2/Pages/Utils/constants";
import CustomTooltip from "../CustomTooltip";

const useStyles = makeStyles((theme) => ({
  locationDiv: {
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
  },
  section: {
    display: "flex",
    columnGap: "10px",
  },
  countrySelect: {
    marginBottom: "12px",
    flex: "1 0 0",
  },
  cityText: {
    flex: "1 0 0",
  },
}));
const LocationSelector = ({
  index = null, // used in Settings -> OrganizationDetails component(since there are multiple LocationSelector components),rest of the components its not used.
  defaultCountry,
  defaultState,
  defaultCity,
  defaultTimezone,
  onChange,
  disabled,
  reset,
  showMandatorySymbol = false,
  noErrorMessage = false, // If we dont want error message to be shown even if there is an error. (useful for page leftside filters where the field is not mandatory).
}) => {
  const classes = useStyles();
  const countries = CountryList.map(
    (countryStatesInfo) => countryStatesInfo?.country
  );
  const timezones = TimezoneList.map((timezoneInfo) => timezoneInfo?.name);

  const [states, setStates] = useState([]);
  const [isCountrySelected, setIsCountrySelected] = useState(true);
  const [isStateSelected, setIsStateSelected] = useState(true);

  const onElementChange = (id, value) => {
    if (id === "country") {
      setIsCountrySelected(true);
      onCountryChange(value);
      onChange("state", "", index); // updating state to empty value in the data object.
      onChange("city", "", index); // updating city to empty value in the data object.
    } else if (id === "state") setIsStateSelected(true);

    onChange(id, value, index);
  };

  const onCountryChange = (country) => {
    const countryData = CountryList.find((entry) => entry?.country === country);
    const statesOfCountry = countryData?.states ?? [];
    // // console.log({ country, statesOfCountry });
    setStates(statesOfCountry);
  };

  useEffect(() => {
    setIsCountrySelected(Boolean(defaultCountry));
    setIsStateSelected(Boolean(defaultState));
    if (defaultCountry) {
      onCountryChange(defaultCountry); // to populate states value.
    }
  }, [defaultCountry, defaultState]);

  return (
    <div className={classes.locationDiv}>
      <div className={classes.section}>
        <div className="flex-1">
          <CustomSelect
            id="country"
            data={countries}
            label={`country ${showMandatorySymbol ? "*" : ""}`}
            placeholder={"Select"}
            onChange={onElementChange}
            defaultSelection={defaultCountry}
            disabled={disabled}
          />
        </div>
        <div className="flex-1">
          <CustomTooltip
            title={
              disabled
                ? null
                : !isCountrySelected && "Please select the country first."
            }
          >
            <div>
              <CustomSelect
                id="state"
                data={states}
                label={`state ${showMandatorySymbol ? "*" : ""}`}
                placeholder={"Select"}
                onChange={onElementChange}
                defaultSelection={defaultState}
                disabled={disabled || !isCountrySelected}
              />
            </div>
          </CustomTooltip>
        </div>
      </div>
      {/* <div className={classes.section}>
        <div className="flex-1">
          <CustomTextfield
            id="state"
            label="State"
            placeholder="Enter your state"
            // onChange={onElementChange}
            onChange={onElementChange}
            errorMessage={messages.ENTER_STATE}
            value={defaultState}
            disabled={disabled}
            reset={reset}
          />
        </div>
      </div> */}
      <div className={classes.section}>
        <div className="flex-1">
          <CustomTooltip
            title={
              disabled
                ? null
                : !isStateSelected && "Please select the state first."
            }
          >
            <div>
              <CustomTextfield
                id="city"
                label={`city ${showMandatorySymbol ? "*" : ""}`}
                placeholder="Enter your city"
                onChange={onElementChange}
                errorMessage={messages.ENTER_CITY}
                noErrorMessage={noErrorMessage}
                value={defaultCity}
                disabled={disabled || !isStateSelected}
                reset={reset}
              />
            </div>
          </CustomTooltip>
        </div>
        <div className="flex-1">
          <CustomSelect
            id="timezone"
            data={timezones}
            label={`timezone ${showMandatorySymbol ? "*" : ""}`}
            placeholder={"Select"}
            onChange={onElementChange}
            defaultSelection={defaultTimezone}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

LocationSelector.propTypes = {};

export default LocationSelector;
