import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";


export const addSkill = async (bodyRequest,Id) => {
    //// console.log({ bodyRequest });
    
    return fetchWrapper(`${configs.APIUrl}/admin/skill${Id ? `/${Id}` : ''}`, {
      method: Id?"PUT":"POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: bodyRequest,
   
    });
  };

  export const addExperience = async (bodyRequest,Id) => {
    //// console.log({ bodyRequest });
    return fetchWrapper(`${configs.APIUrl}/admin/experience${Id ? `/${Id}` : ''}`, {
      method: Id?"PUT":"POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: bodyRequest,
   
    });
  };

  export const addIndustry = async (bodyRequest,Id) => {
    //// console.log({ bodyRequest });
    return fetchWrapper(`${configs.APIUrl}/admin/industry${Id ? `/${Id}` : ''}`, {
      method:Id?"PUT":"POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: bodyRequest,
   
    });
  };

  export const addContractType = async (bodyRequest,Id) => {
    //// console.log({ bodyRequest });
    return fetchWrapper(`${configs.APIUrl}/admin/contractType${Id ? `/${Id}` : ''}`, {
      method:Id?"PUT":"POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: bodyRequest,
   
    });
  };