import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ContentLoader from "react-content-loader";
import CloseIcon from "@mui/icons-material/Close";

import { Typography, Link, Grid, CircularProgress, Alert } from "@mui/material";
import CustomButton from "app/v2/components/CustomButton";
import { Line } from "../../../common";
import SkillsPicker from "app/v2/Pages/common/SkillsPicker";
import DeleteJobDialog from "../../common/DeleteJobDialog";
import {
  JobHeader,
  JobOverview,
  TextSection,
  SectionTitleIcon,
  AboutCompany,
} from "./components";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import CallMadeOutlinedIcon from "@mui/icons-material/CallMadeOutlined";
import { IconLightUser } from "app/v2/Pages/SVGs/IconLightUser";
import { IconLightFile } from "app/v2/Pages/SVGs/IconLightFile";
import { IconLightGrid } from "app/v2/Pages/SVGs/IconLightGrid";
import { IconLightAward } from "app/v2/Pages/SVGs/IconLightAward";
import { CheckSVG } from "app/v2/Pages/SVGs/Check";
import img_icon_light_arrow_top from "../../../images/img_icon_light_arrow_top.svg";
import SocialShare from "app/v2/Pages/common/SocialShare";
import CustomTooltip from "app/v2/components/CustomTooltip";
import { messages } from "app/v2/Pages/Utils/constants";
import {
  isValidString,
  scrollToTop,
  isValidObject,
} from "app/v2/Pages/Utils/utilFunctions";
import { JobDetailObj } from "./JobDetail.dummy";
import {
  deleteJobByIdThunk,
  setStatusJobByIdThunk,
} from "app/v2/store/features/employer/jobFeed/jobFeed.thunk";
import configs from "app/v2/Pages/Utils/configs";
import {
  fetchContractTypesThunk,
  fetchExperiencesThunk,
} from "app/v2/store/features/misc/miscThunk";
import { getJobDetail } from "app/v2/services/job/jobDetail.service";

const useStyles = makeStyles((theme) => ({
  leftView: {
    padding: "24px 48px",
    width: "291px",
    boxSizing: "content-box",
    // width: "100%",
    // [theme.breakpoints.down("1440")]: {
    //   maxWidth: "7.6rem",
    //   maxHeight: "2.1rem",
    // },
  },
  backDiv: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "36px",
  },
  backIcon: {
    cursor: "pointer",
    marginRight: "9px",
    position: "relative",
    right: "5px",
  },
  jobActions: {
    marginBottom: "36px",
    marginTop: "24px",
  },
  jobActionsSet1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    columnGap: "12px",
    marginBottom: "36px",
  },
  set1Action: {
    padding: "10px",
    border: "1px solid #ABABAB",
    cursor: "pointer",
    minWidth: "20px",
    height: "20px",
    boxSizing: "content-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  activateButton: {
    padding: "10px 20px 10px 15px",
    border: "1px solid #ABABAB",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    columnGap: "10px",
    fontSize: "14px",
    fontWeight: 600,
  },
  extraActionButtonText: {
    fontSize: "16px",
    fontWeight: 600,
  },
  consultantButton: {
    width: "100%",
    marginBottom: "24px",
    padding: "12px",
  },
  hiringBoardOrTeamButton: {
    border: "1px solid #EDEDED",
    width: "100%",
    padding: "12px",
  },
  navItemsDiv: {
    display: "flex",
    flexDirection: "column",
    rowGap: "26px",
    marginTop: "36px",
    marginLeft: "20px",
  },
  navItemLinkDiv: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    columnGap: "12px",
  },
  navItemTitle: {
    fontSize: "16px",
    fontWeight: 600,
  },
  selectedNavItem: {
    color: `${theme.palette.common.black} !important`,
  },
  unselectedNavItem: {
    color: `${theme.palette.secondary.light} !important`,
  },
  selectedNavItemForTeamIcon: {
    color: `${theme.palette.primary.main} !important`,
  },
  unselectedNavItemForTeamIcon: {
    color: `${theme.palette.success.main} !important`,
  },
  rightView: {
    borderLeft: "1px solid #EDEDED",
    padding: "48px",
    width: "956px",
    boxSizing: "content-box",
    [theme.breakpoints.down("1440")]: {
      width: "900px",
    },
    [theme.breakpoints.down("1385")]: {
      width: "800px",
    },
    [theme.breakpoints.down("1285")]: {
      width: "700px",
    },
    [theme.breakpoints.down("1185")]: {
      width: "600px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0",
      width: "380px",
    },
  },
  rightArrowIcon: {
    marginLeft: "10px",
  },
}));

const leftNavData = [
  {
    icon: <IconLightUser className="w-[29px] h-[29px]" />,
    title: "Job Overview",
    component: ({ jobData, experiences, contractTypes }) => (
      <JobOverview
        data={jobData}
        experiencesData={experiences}
        contractTypesData={contractTypes}
      />
    ),
  },
  {
    icon: <IconLightFile className="w-[29px] h-[29px]" />,
    title: "Job Description",
    component: ({ jobData }) => (
      <TextSection
        data={jobData?.jobDescription?.htmlString ?? jobData?.jobDescription}
      />
    ),
  },
  {
    icon: <IconLightGrid className="w-[29px] h-[29px]" />,
    title: "Qualifications",
    component: ({ jobData }) => (
      <SkillsPicker
        id="clientJobDetailSP" // SP - Skills picker
        data={jobData?.skillsRequired}
        readOnly={true}
      />
    ),
  },
  {
    icon: <IconLightAward className="w-[29px] h-[29px]" />,
    title: "Responsibilities",
    component: ({ jobData }) => (
      <TextSection
        data={
          jobData?.jobResponsibilities?.htmlString ??
          jobData?.jobResponsibilities
        }
      />
    ),
  },
  {
    icon: <VerifiedUserOutlinedIcon className="w-[29px] h-[29px]" />,
    title: "About Company",
    component: ({ jobData }) => (
      <AboutCompany
        profileData={
          jobData?.companyDetail?.companyDesc?.htmlString ??
          jobData?.companyDetail?.companyDesc
        }
        cultureData={
          jobData?.companyDetail?.culture?.htmlString ??
          jobData?.companyDetail?.culture
        }
      />
    ),
    withGrayBg: true,
  },
];

const JobDetail = ({ props }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { experiences, contractTypes } = useSelector((state) => state.misc);
  const backURL = history?.location?.state?.backURL;
  const jobID = props?.match?.params?.jobID;
  const homeUrl = configs?.homeUrl;
  const publicJobDetailURL = jobID ? homeUrl + `job-detail/${jobID}` : null;

  const [jobData, setJobData] = useState({});
  const [jobStatus, setJobStatus] = useState();
  const [deleteJobDialogOpen, setDeleteJobDialogOpen] = useState(false);
  const [deleteJobLoading, setDeleteJobLoading] = useState(false);
  const [deleteJobCompleted, setDeleteJobCompleted] = useState(false);
  const [deleteJobError, setDeleteJobError] = useState(false);
  const [selectedNavItemIndex, setSelectedNavItemIndex] = useState(
    "nav-item-component-0"
  );
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);

  const onNavItemClick = (navItemIndex) => {
    // sectionRefs.current[navItemIndex].scrollIntoView({ behavior: 'smooth' });

    const element = sectionRefs.current[navItemIndex];
    const elementTop = element.getBoundingClientRect().top + window.pageYOffset;
    const container = window;
    const offset = -180;
    container.scrollTo({ top: elementTop + offset, behavior: "smooth" });
  };

  const isLastNavItemComponent = (index) => {
    return index === leftNavData?.length - 1;
  };

  const onJobDeleteClick = () => {
    setDeleteJobLoading(true);

    // setTimeout(() => {
    //   /** Confirm case */
    //   setDeleteJobLoading(false);
    //   setDeleteJobCompleted(true);
    //   /** Error case */
    //   // setDeleteJobLoading(false);
    //   // setDeleteJobError(true);
    // }, 2000);

    dispatch(deleteJobByIdThunk(jobData?._id))
      .unwrap()
      .then((result) => {
        // console.log("Job deleted successfully:", result);
        setDeleteJobLoading(false);
        setDeleteJobCompleted(true);
      })
      .catch((error) => {
        setDeleteJobLoading(false);
        setDeleteJobError(true);
        console.error("Error deleting job:", error);
      });
  };
  const onJobEditClick = () => {
    history.push(`/client/edit-job/${jobData?._id}`);
    window.scrollTo(0, 0);
  };
  const onReactivateClick = () => {
    dispatch(
      setStatusJobByIdThunk({
        jobID: jobData?._id,
        status: "active",
      })
    )
      .unwrap()
      .then((result) => {
        setJobStatus("active");
        // enqueueSnackbar("Your job has been activated successfully!", {
        //   variant: "success",
        // });
        const key = enqueueSnackbar(
          "Your job has been activated successfully!",
          {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
      })
      .catch((error) => {
        enqueueSnackbar(messages?.GENERIC_ERROR_MESSAGE, {
          variant: "error",
        });
      });
  };

  const onDeactivateClick = () => {
    dispatch(
      setStatusJobByIdThunk({
        jobID: jobData?._id,
        status: "inactive",
      })
    )
      .unwrap()
      .then((result) => {
        setJobStatus("inactive");
        // enqueueSnackbar("Your job has been deactivated successfully!", {
        //   variant: "success",
        // });

        const key = enqueueSnackbar(
          "Your job has been deactivated successfully!",
          {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
      })
      .catch((error) => {
        // enqueueSnackbar(messages?.GENERIC_ERROR_MESSAGE, {
        //   variant: "error",
        // });
        const key = enqueueSnackbar(messages?.GENERIC_ERROR_MESSAGE, {
          variant: "error",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
      });
  };

  ///////// scroll
  const sectionRefs = useRef([]);
  const handleScroll = useCallback(() => {
    const pageYOffset = window.pageYOffset + 180;

    sectionRefs.current.forEach((section) => {
      const sectionOffsetTop = section?.offsetTop;
      const sectionHeight = section?.offsetHeight;

      if (
        pageYOffset >= sectionOffsetTop &&
        pageYOffset < sectionOffsetTop + sectionHeight
      )
        setSelectedNavItemIndex(section.id);
    });
  }, [selectedNavItemIndex]);

  useEffect(() => {
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  ///////// scroll

  useEffect(() => {
    // console.log({ props });
    // setJobData(JobDetailObj);

    getJobDetail(jobID)
      .then((res) => {
        const jobDataReceived = res?.data;
        // console.log({ jobDataReceived });
        if (isValidObject(jobDataReceived)) {
          setJobData(jobDataReceived);
          setJobStatus(jobDataReceived?.status);
        } else {
          setDataLoading(false);
          setDataError(true);
        }
        setDataLoading(false);
      })
      .catch((error) => {
        setDataLoading(false);
        setDataError(true);
      });
  }, []);

  useEffect(() => {
    if (!deleteJobDialogOpen) {
      // Setting back to default states- used when the dialog is closed.
      setDeleteJobLoading(false);
      setDeleteJobCompleted(false);
      setDeleteJobError(false);
    }
  }, [deleteJobDialogOpen]);

  return (
    <>
      {dataLoading ? (
        // <CircularProgress />
        <ContentLoader
          speed={2}
          width={1400}
          height={800}
          viewBox="0 0 1400 800"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="100" rx="3" ry="3" width="280" height="605" />
          <rect x="335" y="100" rx="3" ry="3" width="1200" height="605" />
        </ContentLoader>
      ) : (
        <>
          {dataError ? (
            <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
          ) : (
            <Grid container>
              <Grid item className={classes.leftView}>
                <div className={classes.backDiv}>
                  <ArrowBackIcon
                    className={classes.backIcon}
                    onClick={() => {
                      if (isValidString(backURL)) history.push(backURL);
                      else history.goBack();
                    }}
                  />
                  <span>Back</span>
                </div>
                <div>
                  <div className={classes.jobActions}>
                    <div className={classes.jobActionsSet1}>
                      <button
                        className={classes.set1Action}
                        onClick={onJobEditClick}
                      >
                        <img src="/edit.svg" />
                      </button>
                      {(jobStatus === "active" || jobStatus === "closed") && (
                        <button
                          className={classes.activateButton}
                          onClick={onDeactivateClick}
                        >
                          <img src="/deactivate.svg" />
                          Deactivate
                        </button>
                      )}

                      {jobStatus === "inactive" && (
                        <button
                          className={classes.activateButton}
                          onClick={onReactivateClick}
                        >
                          <CheckSVG fillColor="#000" />
                          Reactivate
                        </button>
                      )}

                      <button
                        className={classes.set1Action}
                        onClick={() => {
                          setDeleteJobDialogOpen(true);
                        }}
                      >
                        <img src="/delete.svg" />
                      </button>

                      {jobStatus !== "draft" && (
                        <CustomTooltip
                          arrow
                          title={<SocialShare shareLink={publicJobDetailURL} />}
                          bg="white"
                        >
                          <button className={classes.set1Action}>
                            <img src={"/share.svg"} alt="icon" />
                          </button>
                        </CustomTooltip>
                      )}
                    </div>

                    {jobStatus === "active" && (
                      <>
                        <CustomButton
                          type="button1"
                          customClasses={clsx([
                            classes.consultantButton,
                            classes.extraActionButtonText,
                          ])}
                          onClick={() => {
                            history.push(
                              "/client/" + jobData?._id + "/consultants"
                            );
                          }}
                        >
                          Find Consultants
                          <CallMadeOutlinedIcon
                            fontSize="small"
                            className={classes.rightArrowIcon}
                          />
                        </CustomButton>
                        <CustomButton
                          customClasses={clsx([
                            classes.hiringBoardOrTeamButton,
                            classes.extraActionButtonText,
                          ])}
                          onClick={() => {
                            history.push(
                              `/client/hiring-board/${jobData?._id}`
                            );
                          }}
                        >
                          Hiring Board
                          <CallMadeOutlinedIcon
                            fontSize="small"
                            className={classes.rightArrowIcon}
                          />
                        </CustomButton>
                      </>
                    )}

                    {jobStatus === "closed" && (
                      <CustomButton
                        type="button1"
                        customClasses={clsx([
                          classes.consultantButton,
                          classes.extraActionButtonText,
                        ])}
                        onClick={() => {
                          // history.push("/client/consultant-feed");
                        }}
                      >
                        Check Consultant Activity
                        <CallMadeOutlinedIcon
                          fontSize="small"
                          className={classes.rightArrowIcon}
                        />
                      </CustomButton>
                    )}
                  </div>
                  {deleteJobDialogOpen && (
                    <DeleteJobDialog
                      open={deleteJobDialogOpen}
                      setOpen={setDeleteJobDialogOpen}
                      jobDetails={{
                        jobNo: jobData?.jobNo,
                        jobName: jobData?.jobTitle,
                      }}
                      onDeleteClick={onJobDeleteClick}
                      deleteLoading={deleteJobLoading}
                      deleteCompleted={deleteJobCompleted}
                      deleteError={deleteJobError}
                      onSuccessRedirect={() => {
                        history.push("/client/job-feed");
                      }}
                    />
                  )}
                </div>
                <hr />
                <div className={classes.navItemsDiv}>
                  {leftNavData?.map((navItemDetails, index) => (
                    <Link
                      key={"l" + index}
                      className={clsx([
                        classes.navItemLinkDiv,
                        selectedNavItemIndex === `nav-item-component-${index}`
                          ? classes.selectedNavItem
                          : classes.unselectedNavItem,
                      ])}
                      onClick={() => {
                        onNavItemClick(index);
                      }}
                    >
                      {navItemDetails?.icon}

                      <Typography className={classes.navItemTitle}>
                        {navItemDetails?.title}
                      </Typography>
                    </Link>
                  ))}
                </div>
              </Grid>
              <Grid item id="sectionContent" className={classes.rightView}>
                <JobHeader data={jobData} />
                {leftNavData?.map((navItemDetails, index) => (
                  <>
                    <div
                      ref={(el) => (sectionRefs.current[index] = el)}
                      className={`${
                        navItemDetails?.withGrayBg
                          ? "bg-[#F7F7F7] p-[36px]"
                          : "px-[24px]"
                      }`}
                      id={`nav-item-component-${index}`}
                      key={`nav-item-component-${index}`}
                    >
                      <SectionTitleIcon
                        title={navItemDetails?.title}
                        icon={navItemDetails?.icon}
                      />
                      {navItemDetails?.component({
                        jobData,
                        experiences,
                        contractTypes,
                      })}
                    </div>
                    {!isLastNavItemComponent(index) && (
                      <Line className="bg-gray-300 h-px w-full my-[36px]" />
                    )}
                  </>
                ))}
                <div
                  className="flex items-center gap-x-[10px] pt-[36px] cursor-pointer"
                  onClick={() => {
                    scrollToTop();
                  }}
                >
                  <img src={img_icon_light_arrow_top} />
                  <div className="font-semibold leading-[normal] text-base text-center text-gray-900">
                    Back to top
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

JobDetail.propTypes = {};

export default JobDetail;
