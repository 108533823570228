import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";

export const getAllConsultants = async (bodyRequest) => {
  let queryString = "";

  if (bodyRequest) {
    const { limit, offset, order, searchTags, country, industry } = bodyRequest;
    queryString = `limit=${limit}&offset=${offset}&order=${order || "newest"}&name=${searchTags}`;
  } else {
    queryString = "order=newest";
  }
  return fetchWrapper(`${configs.APIUrl}/admin/allConsultants?${queryString}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    // body: bodyRequest,

    //   {
    //     "limit": 100,
    //     "offset": 0,
    //     "country": "United State", // If don't want to filter companySize then don't this
    //     "industry": "Technology", // If don't want to filter companySize then don't this
    //     "companySize": 10, // [10,50] -> this will be a range for companySize // If don't want to filter companySize then don't this
    //     "order": "oldest" //asc, desc, oldest, latest /// asc order result by company name from atoz, desc order result by company name from ztoa
    // }
  });
};

export const getConsultantDetail = async (consultantID) => {
  return fetchWrapper(
    `${configs.APIUrl}/admin/consultant-detail/${consultantID}`
  );
};

export const getAllJobsApplied = async (bodyRequest) => {
  let queryString = "";

  if (bodyRequest) {
    const { limit, offset, order, searchTags, country, industry ,iD} = bodyRequest;
    queryString = `${iD}?limit=${limit}&offset=${offset}&order=${order}`;
    // queryString = `${iD}`;

  } else {
    queryString = "order=newest";
  }

  return fetchWrapper(`${configs.APIUrl}/admin/consultant/appliedJobs/${queryString}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    // body: bodyRequest,
    //   body: {
    //       "searchTags": ["INV00005", "INV00002"],
    //     "sortBy": "date", //Sort By date( payment due date ) or status
    //     "limit": 100,
    //     "offset": 0
    // }
    // searchTags will be added later
  });
};

export const getAllAcceptedJobs = async (bodyRequest) => {
  let queryString = "";

  if (bodyRequest) {
    const { limit, offset, order, searchTags, country, industry,iD } = bodyRequest;
    queryString = `${iD}?limit=${limit}&offset=${offset}&order=${order }`;
    // queryString = `${iD}`;

  } else {
    queryString = "order=newest";
  }
  return fetchWrapper(
    `${configs.APIUrl}/admin/consultant/acceptedJobs/${queryString}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: bodyRequest,
      //   body: {
      //       "searchTags": ["INV00005", "INV00002"],
      //     "sortBy": "date", //Sort By date( payment due date ) or status
      //     "limit": 100,
      //     "offset": 0
      // }
      // searchTags will be added later
    }
  );
};

export const restrictUserApi = async (consultantID, status) => {
  let isBlocked=status
  return fetchWrapper(
    `${configs.APIUrl}/admin/consultant/${consultantID}/${status}`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteUserApi = async (consultantID) => {
  return fetchWrapper(
    `${configs.APIUrl}/admin/consultant/${consultantID}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
