import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Typography, Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import commonImages from "../../../../../images/common";
import { useHistory } from "react-router-dom";
import { ShareSVG } from "app/v2/Pages/SVGs/Share";
import { isValidArray } from "app/v2/Pages/Utils/utilFunctions";
import JobStatus from "app/v2/Pages/common/Status/Job";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchContractTypesThunk,
  fetchExperiencesThunk,
  fetchSkillLevelsThunk,
} from "app/v2/store/features/misc/miscThunk";
import {
  getContractTypeLevel,
  getExperience,
  getSkillName,
} from "app/v2/Pages/common/getIdTypeLevel";
import { DataArray } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { bookmarkApiForJobCard } from "../../../agencyApiServices/agency.services";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  listContainer: {
    width: "100%",
    // height: "396px",
    border: "1px solid #EDEDED",
    padding: "36px",
    marginBottom: "2px ",
    borderColor: "#ABABAB",
    "&:hover": {
      boxShadow: "0px 4px 36px rgba(0, 0, 0, 0.12)",
    },
  },
  skillsChipsDiv: {
    width: "100%",
    overflowX: "auto",
    paddingBottom: "10px",
  },
  sectionHeadingText: {
    fontSize: "10px",
    color: theme.palette.secondary.light,
  },
  hideActions: {
    display: "none",
  },
  orgImage: {
    width: "100px",
    height: "100px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
}));

const FeatureJobListCard = ({ data }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [cardData, setCardData] = useState();
  const [isHovered, setIsHovered] = useState(false);
  const [salaryType, setSalaryType] = useState();
  const [salaryValue, setSalaryValue] = useState();
  const { experiences, contractTypes, skills } = useSelector(
    (state) => state.misc
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function salaryRate(data) {
    const salaryDetails = data?.salary;
    const salaryTypeReceived = salaryDetails?.type;
    const salaryRangeReceived = salaryDetails?.range;
    const salaryValueReceived = salaryRangeReceived
      ? `Min- $ ${salaryRangeReceived?.min ?? "-"}, Max - $ ${
          salaryRangeReceived?.max ?? "-"
        }`
      : `$ ${salaryDetails?.fixedRate ?? "-"}`;
    setSalaryType(salaryTypeReceived);
    setSalaryValue(salaryValueReceived);
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    salaryRate(data);
  }, []);

  useEffect(() => {
    setCardData(data);
  }, [data]);
  useEffect(() => {
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
    if (!skills?.length) dispatch(fetchSkillLevelsThunk());
  }, [dispatch]);

  const [isBookMarkClicked, setIsBookMarkClicked] = useState(data?.isFavorite);
  const agencyId = localStorage.getItem("orgId");

  const handleBookMarkClick = () => {
    const request = {
      jobId: data?._id,
      agencyId: agencyId,
    };

    bookmarkApiForJobCard(request)
      .then((res) => {
        if (res) {
          setIsBookMarkClicked((prev) => !prev);

          const key = enqueueSnackbar(`Successfull`, {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        }
      })
      .catch((error) => {
        const key = enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
      });
  };
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={clsx([classes.listContainer])}
    >
      <div
        style={{
          height: "89px",
          width: "1299px",
          display: "flex",
          margin: "0px 24px 0px 0px",
        }}
      >
        <img
          className={classes.orgImage}
          alt={`org_${data?._id}_image`}
          src={
            data?.organizationDetails?.profilePhoto
              ? data?.organizationDetails?.profilePhoto
              : "/org_placeholder.svg"
          }
        />
        <div style={{ width: "988px", height: "79px" }}>
          <div
            style={{
              width: "auto",
              height: "29px",
              margin: "0px 0px 0px 19px ",
              fontSize: "24px",
              // fontFamily:"Plus Jakarta Sans",
              lineHeight: "28.80px",
              fontWeight: 600,
            }}
          >
            {data?.jobTitle}{" "}
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "19px",
              fontSize: "14px",
              color: "#717171",
              lineHeight: "19.60px",
            }}
          >
            <div style={{ marginRight: "12px" }}>
              {data?.createdAt ? moment(data?.createdAt).fromNow() : "-"}
            </div>
            <div>Job no: {data?.jobNo}</div>
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "19px",
              fontSize: "14px",
              color: "#717171",
              lineHeight: "19.60px",
            }}
          >
            <div style={{ marginRight: "12px" }}>Hiring Manager</div>
            <div style={{ marginRight: "12px" }}>
              {data?.hiringManager ?? "-"}
            </div>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div
            style={{
              margin: "0px 19px 0px 19px",
              width: "96px",
              height: "22px",
              // border: "1px solid #CCCCCC",
              textAlign: "center",
              fontFamily: "Plus Jakarta Sans",
              fontWeight: 600,
              // lineHeight: "19.2px",
              fontSize: "12px",
              // color: "#363636",
            }}
          >
            {/* {data?.status} */}
          </div>
          <div
            style={{
              display: "flex",
              width: "69px",
              height: "22px",
              // border: "1px solid #CCCCCC",
              textAlign: "center",
              backgroundColor: "#EBFFF0",
              justifyContent: "flex-end",
            }}
          >
            <JobStatus type={data?.status} />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "24px",
          fontSize: "14px",
          color: "#717171",
          lineHeight: "19.60px",
          fontWeight: 600,
        }}
      >
        <div
          style={{
            width: "247px",
            height: "39px",
            color: "#717171",
            fontSize: "12px",
            fontWeight: 600,
          }}
        >
          <div>OPENPOSITIONS</div>
          <div style={{ fontSize: "18px", fontWeight: 700, color: "#0B0E1E" }}>
            {data?.openPositions}
          </div>
        </div>

        <div
          style={{
            width: "247px",
            height: "39px",
            color: "#717171",
            fontSize: "12px",
            fontWeight: 600,
          }}
        >
          <div>EXPERIENCE LEVEL</div>
          <div style={{ fontSize: "18px", fontWeight: 700, color: "#0B0E1E" }}>
            {getExperience(data?.experienceLevelId, experiences)}
          </div>
        </div>
        <div
          style={{
            width: "247px",
            height: "39px",
            color: "#717171",
            fontSize: "12px",
            fontWeight: 600,
          }}
        >
          <div>CONTRACT TYPE</div>
          <div style={{ fontSize: "18px", fontWeight: 700, color: "#0B0E1E" }}>
            {getContractTypeLevel(data?.contractType, contractTypes)}
          </div>
        </div>
        <div
          style={{
            width: "247px",
            height: "39px",
            color: "#717171",
            fontSize: "12px",
            fontWeight: 600,
          }}
        >
          <div>WORK TYPE</div>
          <div style={{ fontSize: "18px", fontWeight: 700, color: "#0B0E1E" }}>
            {data?.workType}
          </div>
        </div>
        <div style={{ width: "247px", height: "39px" }}>
          <div>salaryRange</div>
          <div style={{ fontSize: "18px", fontWeight: 700, color: "#0B0E1E" }}>
            {salaryValue}{" "}
          </div>
        </div>
      </div>
      <div style={{ margin: "36px 36px 36px 0px", display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              marginRight: "79px",
              width: "127px",
              lineHeight: "14.40px",
              color: "#717171",
              fontSize: "12px",
              fontWeight: 600,
            }}
          >
            Salary Type
          </div>
          <div
            style={{
              marginRight: "79px",
              width: "127px",
              color: "#0B0E1E",
              fontSize: "16px",
              fontWeight: 700,
            }}
          >
            {salaryType}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              marginRight: "79px",
              width: "223px",
              color: "#717171",
              fontSize: "12px",
              fontWeight: 600,
            }}
          >
            LOCATION
          </div>
          <div
            style={{
              marginRight: "79px",
              width: "223px",
              color: "#0B0E1E",
              fontSize: "16px",
              fontWeight: 700,
              wordWrap: "break-word",
            }}
          >
            {`${data?.country || "-"}, ${data?.state || "-"}`},
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              marginRight: "79px",
              width: "159px",
              color: "#717171",
              fontSize: "12px",
              fontWeight: 600,
            }}
          >
            TIMEZONE
          </div>
          <div
            style={{
              marginRight: "79px",
              width: "159px",
              color: "#0B0E1E",
              fontSize: "16px",
              fontWeight: 700,
            }}
          >
            {data?.timezone}
          </div>
        </div>

        <div className="w-[422px] h-[120px] flex flex-col  items-start justify-center gap-[16px] text-3xs text-neutral-500">
          <div className="flex flex-col items-start justify-start gap-[8px]">
            <div
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              technology skills
            </div>
            <div
              className={clsx([
                classes.skillsChipsDiv,
                "flex flex-row items-start justify-start gap-[8px] text-xs font-poppins",
                "overflow-auto",
              ])}
            >
              {isValidArray(data?.skillsRequired) ? (
                <>
                  {data?.skillsRequired?.map((skillDetails) => (
                    <div className="rounded-md flex flex-row py-1.5 px-2 items-start justify-start border-[1px] border-solid border-neutral-50">
                      <div className="relative leading-[136%] font-medium">
                        {getSkillName(
                          skillDetails?.skillID ?? skillDetails?._id,
                          skills
                        )}
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className="self-stretch relative text-xs leading-[136%] font-poppins text-left [display:-webkit-inline-box] overflow-hidden overflow-y-scroll text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical]">
            {data?.summary}
          </div>
        </div>
        {/* <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              width: "245px",
              color: "#717171",
              fontSize: "12px",
              fontWeight: 600,
            }}
          >
            TECHNOLOGY SKILLS
          </div>
          <div
            style={{
              width: "245px",
              color: "#0B0E1E",
              fontSize: "16px",
              fontWeight: 700,
            }}
          >
            REACTJS NODEJS ANGULAR
          </div>
        </div> */}
      </div>
      <div className=" self-stretch flex  items-start justify-start   w-full">
        {isHovered && (
          <div className="flex space-x-3">
            <button
              // onClick={() => {
              //   history.push(`/client/consultant/${cardData?._id}`);
              //   window.scrollTo(0, 0);
              // }}
              onClick={() => {
                history.push({
                  pathname: `/agency/job-detail/${data?._id}`,
                  state: {
                    jobDetails: cardData,
                  },
                });
              }}
              className="cursor-pointer [border:none] py-2.5 pr-[15px] pl-5 bg-neutral-900 self-stretch flex flex-row items-center justify-center gap-[10px]"
            >
              <div className="w-full text-sm font-semibold font-button-text-button-14 text-neutral-white text-center">
                View Job Details
              </div>
              <img
                className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
                alt=""
                src="/iconlightup-right.svg"
              />
            </button>

            <button
              className={`cursor-pointer ${
                isBookMarkClicked
                  ? "bg-neutral-900 border-none h-10"
                  : "border-[1px] border-solid border-neutral-50"
              } p-2.5 flex flex-row box-border items-center justify-center`}
              onClick={handleBookMarkClick}
            >
              <img
                className="relative w-5 h-5 overflow-hidden shrink-0"
                alt=""
                src={
                  isBookMarkClicked
                    ? "/iconlightbookmark.svg"
                    : "/icondarkbookmark.svg"
                }
              />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

FeatureJobListCard.propTypes = {};

export default FeatureJobListCard;
