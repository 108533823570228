

import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCandidateList } from "../../../../services/client/consultants.service";

export const getConsultantsThunk = createAsyncThunk(
"consultants/get", 
async (page) => {
    const limit = page.limit, offset = page.offset;
    //// console.log("waedfwef ", page);
    return getCandidateList(limit, offset);
}
);
