import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import {
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Fade,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CustomDialog from "app/v2/components/CustomDialog";
import UserCard from "./UserCard";
import CustomButton from "app/v2/components/CustomButton";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { capitalizeFirstLetter, isValidArray } from "../../Utils/utilFunctions";
import CustomTooltip from "app/v2/components/CustomTooltip";

const useStyles = makeStyles((theme) => ({
  step: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #EDEDED",
    padding: "12px",
    minHeight: "39px",
    boxSizing: "content-box",
  },
  stepTitleorRenameText: {
    fontWeight: 600,
    fontSize: "14px",
  },
  stepCount: {
    fontSize: "12px",
  },
  stepCardContainer: {
    minWidth: "279px",
    width: "fit-content",
    height: "70vh",
    overflowY: "auto",
    padding: "10px",
    border: "1px solid #EDEDED",
    backgroundColor: "#F7F7F7",
  },
  renameActionButton: {
    fontSize: "12px !important",
    padding: "12px",
  },
  renameCancelButton: {
    border: "1px solid #EDEDED !important",
  },
  infoIcon: {
    width: "20px",
    height: "20px",
  },
}));

const EditDialogContent = ({
  columnTitle,
  columnInfo: columnInfoReceived,
  otherColumnNames,
  onEdit,
  handleEditDialog,
}) => {

  const classes = useStyles();
  const [columnName, setColumnName] = useState("");
  const [columnInfo, setColumnInfo] = useState("");
  const [dcNErrorMessage, setDCNErrorMessage] = useState(null);
  const [enableSaveButton, setEnableSaveButton] = useState(false);

  const columnNameChange = (id, value) => {
    if (otherColumnNames?.includes(value))
      setDCNErrorMessage(
        "Stage name already exists.Please enter a new stage name."
      );
    else setDCNErrorMessage(null);
    setColumnName(value);
  };

  useEffect(() => {
    setColumnName(columnTitle);
    setColumnInfo(columnInfoReceived);
  }, [columnTitle, columnInfoReceived]);

  useEffect(() => {
    const isValidColumnName =
      columnName?.length > 0 && dcNErrorMessage === null;
    const isValidColumnInfo = columnInfo?.length > 0;
    setEnableSaveButton(isValidColumnName && isValidColumnInfo);
  }, [columnName, columnInfo]);

  return (
    <div className="p-[36px] flex flex-col gap-y-[24px] w-[739px] m-auto">
      <CustomTextfield
        id="stageName"
        label="stage name"
        value={columnName}
        onChange={columnNameChange}
        customErrorMessage={dcNErrorMessage}
      />
      <CustomTextfield
        id="stageInfo"
        label="stage info"
        value={columnInfo}
        onChange={(id, value) => {
          setColumnInfo(value);
        }}
        multiline={true}
        minRows={2}
      />
      <div className="flex gap-x-[16px] justify-end">
        <CustomButton
          customClasses={clsx([
            classes.renameActionButton,
            classes.renameCancelButton,
          ])}
          onClick={handleEditDialog}
        >
          Cancel
        </CustomButton>
        <Tooltip
          title={!enableSaveButton ? "Please enter the above details." : null}
        >
          <div>
            <CustomButton
              type="button1"
              customClasses={classes.renameActionButton}
              onClick={() => {
                onEdit(columnName, columnInfo);
                handleEditDialog();
              }}
              disabled={!enableSaveButton}
            >
              Save
            </CustomButton>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

const DeleteDialogContent = ({ columnTitle, onDelete, handleDeleteDialog }) => {
  const classes = useStyles();
  return (
    <div className="p-[36px] flex flex-col gap-y-[24px] w-[739px]">
      <div>
        Are you sure you want to delete <b>{columnTitle}</b> stage?
      </div>
      <span className="text-[14px]">
        <b>Note:</b> Deleting this stage will result in the permanent removal of
        all associated information. This action cannot be undone.
      </span>
      <div className="flex gap-x-[16px] justify-end">
        <CustomButton
          customClasses={clsx([
            classes.renameActionButton,
            classes.renameCancelButton,
          ])}
          onClick={handleDeleteDialog}
        >
          No
        </CustomButton>

        <CustomButton
          type="button1"
          customClasses={classes.renameActionButton}
          onClick={() => {
            onDelete();
            handleDeleteDialog();
          }}
        >
          Yes
        </CustomButton>
      </div>
    </div>
  );
};

const KanbanColumn = ({
  columnData,
  columnIndex,
  onColumnEdit,
  onColumnDelete,
  onMoveColumn,
  onUserCardActionClick,
  otherColumnNames,
  isPreviousColumnMovable,
  isNextColumnMovable,
  nextColumnType,
  additionalData,
}) => {

  const classes = useStyles();

  const isColumnMovable = columnData?.movable;
  const [columnMenuAnchor, setColumnMenuAnchor] = useState(null);
  const [actionType, setActionType] = useState(); // edit,delete
  const [dialogOpen, setDialogOpen] = useState(false);

  /** dnd */
  const userCardIds = useMemo(
    () =>
      isValidArray(columnData?.users)
        ? columnData?.users?.map((userData) => {
            return userData?.consultantId;
          })
        : [],
    [columnData?.users]
  );

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    // transition,
    isDragging,
  } = useSortable({
    id: columnData?.type,
    data: {
      elementType: "column",
      columnData,
      columnIndex,
      nextColumnType,
      additionalData,
    }, // In addition to elementType, the component's props that are displayed on the column/stage and its cards and required at parent level(to update the data at parent level for column movement) are added here.
  });

  const style = {
    transition: "transform 0.6s ease",
    transform: CSS.Transform.toString(transform),
    cursor: "move",
  };

  /** dnd */

  const handleDialog = () => {
    setDialogOpen((prevState) => !prevState);
  };

  const handleColumnMenuOpen = (event) => {
    setColumnMenuAnchor(event.currentTarget);
  };

  const handleColumnMenuClose = () => {
    setColumnMenuAnchor(null);
  };

  const onMenuItemClick = (
    itemType,
    leftColumnIndex = null,
    rightColumnIndex = null
  ) => {
    setActionType(itemType);
    handleColumnMenuClose();
    if (itemType === "edit" || itemType === "delete") handleDialog();
    else if (itemType === "moveLeft" || itemType === "moveRight")
      onMoveColumn(
        leftColumnIndex,
        rightColumnIndex,
        itemType === "moveLeft" ? 2 : 1
      );
  };

  const getColumnUserCount = () => {
    return columnData?.users?.length ?? 0;
  };

  return (
    <>
      {isColumnMovable && isDragging ? (
        <div
          ref={setNodeRef}
          className={classes.stepCardContainer}
          style={style}
        ></div>
      ) : (
        <div
          ref={setNodeRef}
          style={isColumnMovable ? style : {}}
          {...(isColumnMovable ? attributes : [])}
          {...(isColumnMovable ? listeners : [])}
          key={columnData?.userData}
        >
          <div className={classes.step}>
            <div className="flex gap-x-[8px]">
              {columnData?.info && (
                <CustomTooltip title={columnData?.info} arrow placement="top">
                  <InfoIcon className={classes.infoIcon} />
                </CustomTooltip>
              )}

              <Typography className={classes.stepTitleorRenameText}>
                {columnData?.title} ({getColumnUserCount()})
              </Typography>
            </div>

            {columnData?.showMenu && (
              <>
                <IconButton
                  id={`fade-button-${columnData?.type}`}
                  onClick={(event) => {
                    handleColumnMenuOpen(event);
                  }}
                >
                  <MoreHorizIcon fontSize="small" />
                </IconButton>
                <Menu
                  id={`fade-menu-${columnData?.type}`}
                  MenuListProps={{
                    "aria-labelledby": `fade-button-${columnData?.type}`,
                  }}
                  anchorEl={columnMenuAnchor}
                  open={Boolean(columnMenuAnchor)}
                  onClose={() => {
                    handleColumnMenuClose();
                  }}
                  TransitionComponent={Fade}
                >
                  {isPreviousColumnMovable && (
                    <MenuItem
                      onClick={() => {
                        onMenuItemClick(
                          "moveLeft",
                          columnIndex - 1,
                          columnIndex
                        );
                      }}
                      className={classes.stepTitleorRenameText}
                    >
                      Move left
                    </MenuItem>
                  )}
                  {isNextColumnMovable && (
                    <MenuItem
                      onClick={() => {
                        onMenuItemClick(
                          "moveRight",
                          columnIndex,
                          columnIndex + 1
                        );
                      }}
                      className={classes.stepTitleorRenameText}
                    >
                      Move right
                    </MenuItem>
                  )}

                  <MenuItem
                    onClick={() => {
                      onMenuItemClick("edit");
                    }}
                    className={classes.stepTitleorRenameText}
                  >
                    Edit
                  </MenuItem>
                  <CustomTooltip
                    title={
                      getColumnUserCount() > 0
                        ? "You cannot delete this stage as there are active users assigned to it. Please reassign or remove the users from this stage before attempting to delete it."
                        : null
                    }
                  >
                    <div>
                      <MenuItem
                        onClick={() => {
                          onMenuItemClick("delete");
                        }}
                        className={classes.stepTitleorRenameText}
                        disabled={getColumnUserCount() > 0}
                      >
                        Delete
                      </MenuItem>
                    </div>
                  </CustomTooltip>
                </Menu>
              </>
            )}
          </div>
          <div className={classes.stepCardContainer}>
            <SortableContext items={userCardIds} id="kanbancolumn">
              {columnData?.users?.map((userData, index) => (
                <UserCard
                  key={index}
                  userData={userData}
                  userIndex={index}
                  columnType={columnData?.type}
                  nextColumnType={nextColumnType}
                  columnIndex={columnIndex}
                  defaultOrCustomStage={columnData?.defaultOrCustomStage} // default or custom
                  customStageVariant={columnData?.customStageVariant} // meeting or test if defaultOrCustomStage above is 'custom' else its ''(for default type this value does not matter,its specific to custom type).
                  onActionClick={onUserCardActionClick}
                  moveCardFromAndTo={columnData?.moveCardFromAndTo}
                  additionalData={additionalData}
                />
              ))}
            </SortableContext>
          </div>
        </div>
      )}

      <CustomDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        title={
          <div className="text-[16px] font-semibold text-center">
            {capitalizeFirstLetter(actionType)} Stage
          </div>
        }
        content={
          actionType === "delete" ? (
            <DeleteDialogContent
              columnTitle={columnData?.title}
              onDelete={() => {
                onColumnDelete(columnIndex);
              }}
              handleDeleteDialog={handleDialog}
            />
          ) : (
            <EditDialogContent
              columnTitle={columnData?.title}
              columnInfo={columnData?.info}
              otherColumnNames={otherColumnNames}
              onEdit={(newColumnName, newColumnInfo) => {
                onColumnEdit(columnIndex, newColumnName, newColumnInfo);
              }}
              handleEditDialog={handleDialog}
            />
          )
        }
      />
    </>
  );
};

KanbanColumn.propTypes = {};

export default KanbanColumn;
