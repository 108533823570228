import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import CustomAutocomplete from "app/v2/components/filters/CustomAutocomplete";
import CustomNumberPicker from "app/v2/components/filters/CustomNumberPicker";
import CustomLabel from "app/v2/components/filters/CustomLabel";
import CustomSelect from "app/v2/components/filters/CustomSelect";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
// import fetchWrapper from "../Utils/fetchWrapper";
// import configs from "../Utils/configs";
import { useDispatch, useSelector } from "react-redux";
import { fetchRolesThunk } from "app/v2/store/features/misc/miscThunk";

const useStyles = makeStyles((theme) => ({
  rolePickerRoot: {
    // display: "flex",
    // columnGap: "36px",
  },
  autoCompleteRoot: {
    // minWidth: "683px",
    width: "100%",
  },
  errorBoundary: {
    border: "1px solid red",
  },
  numberpickerAndDelete: {
    display: "flex",
    alignItems: "center",
    columnGap: "36px",
  },
  deleteIcon: {
    cursor: "pointer",
  },
}));

const RolePicker = ({
  id,
  viewType,
  roleSelection: roleSelectionReceived,
  openingCount: openingCountReceived,
  autocompleteLabel,
  numberPickerLabel,
  disableRoleAutocomplete,
  onChange,
  onDelete,
  error,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { roles } = useSelector((state) => state.misc);

  const [roleSelected, setRoleSelected] = useState(roleSelectionReceived);
  const [openingCount, setOpeningCount] = useState(openingCountReceived);

  const rolesData = useMemo(
    () => roles?.map((roleEntry) => roleEntry.name),
    [roles]
  );

  const onRoleChange = (id, value) => {
    const roleDetails = {
      id,
      roleSelection: value,
      prevRoleSelected: roleSelected,
    };
    if (viewType === "team") roleDetails.openingCount = openingCount;
    setRoleSelected(value);
    onChange(roleDetails);
  };

  const onNumberPickerChange = (id, value) => {
    const roleDetails = { id, openingCount: value };
    setOpeningCount(value);
    onChange(roleDetails);
  };

  useEffect(() => {
    dispatch(fetchRolesThunk());
  }, [dispatch]);

  return (
    <div className={classes.rolePickerRoot}>
      <div>
        {autocompleteLabel && <CustomLabel label={autocompleteLabel} />}
        <CustomSelect
          id={id}
          data={rolesData}
          defaultSelection={roleSelected}
          placeholder={"Enter Role"}
          onChange={onRoleChange}
          customClasses={clsx([
            classes.autoCompleteRoot,
            error && classes.errorBoundary,
          ])}
          disabled={disableRoleAutocomplete}
          showSearchIcon={true}
        />
      </div>
      {viewType === "team" && roleSelected && (
        <div>
          {numberPickerLabel && <CustomLabel label={numberPickerLabel} />}
          <div className={classes.numberpickerAndDelete}>
            <CustomNumberPicker
              id={id}
              defaultValue={openingCount}
              onChange={onNumberPickerChange}
              min={0}
              max={10}
            />
            <DeleteOutlineIcon
              className={classes.deleteIcon}
              onClick={() => {
                onDelete(id, roleSelected);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

// RolePicker.propTypes = {};
RolePicker.propTypes = {
  id: PropTypes.string.isRequired,
  viewType: PropTypes.string.isRequired,
  roleSelection: PropTypes.string.isRequired,
  openingCount: PropTypes.number.isRequired,
  autocompleteLabel: PropTypes.string,
  numberPickerLabel: PropTypes.string,
  disableRoleAutocomplete: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

export default RolePicker;
