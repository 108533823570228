import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import CustomButton from "app/v2/components/CustomButton";
import CustomDialog from "app/v2/components/CustomDialog";
import DialogSuccessBody from "app/v2/Pages/common/DialogSuccessBody";
import DialogErrorBody from "app/v2/Pages/common/DialogErrorBody";
import DialogLoadingBody from "app/v2/Pages/common/DialogLoadingBody";

import { isFunction } from "app/v2/Pages/Utils/utilFunctions";
import { messages } from "app/v2/Pages/Utils/constants";

import { acceptOrWithdrawApplication } from "app/v2/services/consultant/common.service";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: "16px 28px",
    width: "197px",
    boxSizing: "content-box",
    fontSize: "16px",
    fontWeight: 600,
  },
  cancelButton: {
    border: "1px solid #EDEDED",
  },
}));

const ActionDialog = ({
  actionDialogOpen,
  setActionDialogOpen,
  actionLoading,
  actionCompleted,
  actionError,
  actionType,
}) => {
  const dialogTitle = {
    accept: "Accepted",
    decline: "Declined",
    withdraw: "Withdrawn",
  };
  const Title = () => {
    return (
      <div className="text-[16px] font-semibold text-center">
        {actionLoading && "Action in progress"}
        {actionError && "Error"}
        {actionCompleted &&
          `${
            dialogTitle[actionType]
              ? `Application ${dialogTitle[actionType]}`
              : "Action Completed"
          }`}
      </div>
    );
  };

  const DialogBody = () => {
    if (actionLoading) return <DialogLoadingBody />;
    else if (actionCompleted)
      return (
        <DialogSuccessBody
          content={{
            title: dialogTitle[actionType]
              ? `You have ${dialogTitle[
                  actionType
                ].toLowerCase()} the application.`
              : "",
          }}
        />
      );
    else if (actionError)
      return (
        <DialogErrorBody
          content={{ title: messages.GENERIC_ERROR_MESSAGE, desc: "" }}
        />
      );
    else return <></>;
  };

  return (
    <CustomDialog
      open={actionDialogOpen}
      setOpen={setActionDialogOpen}
      noCloseAction={actionLoading}
      title={<Title />}
      content={<DialogBody />}
    />
  );
};

/**
 * @component ProposalAction- Content/Body- of the proposal's action(accept or decline or withdraw) dialog.
 * @param {function} closeParentDialog - Function to close the parent dialog(dialog in which this component is shown).
 */

const ProposalAction = ({
  jobData,
  closeParentDialog,
  proposalActionType,
  onAccept,
  onWithdrawOrDecline,
  closeOverviewGPDialog,
}) => {
  const classes = useStyles();

  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [actionCompleted, setActionCompleted] = useState(false);
  const [actionError, setActionError] = useState(false);

  const onActionClick = () => {
    setActionDialogOpen(true);
    setActionLoading(true);

    // setTimeout(() => {
    //   /** Confirm case */
    //   setActionLoading(false);
    //   setActionCompleted(true);
    //   /** Error case */
    //   // setActionLoading(false);
    //   // setActionError(true);
    //   // setActionCompleted(false);
    // }, 2000);

    acceptOrWithdrawApplication({
      jobId: jobData?._id,
      stage: proposalActionType === "accept" ? "accepted" : "withdrew",
    })
      .then((response) => {
        setActionLoading(false);
        setActionCompleted(true);
      })
      .catch((error) => {
        setActionLoading(false);
        setActionError(true);
        // setActionCompleted(false);
      });
  };

  useEffect(() => {
    // console.log({ actionDialogOpen, actionCompleted, actionError });
    if (!actionDialogOpen && (actionCompleted || actionError)) {
      // close the parent proposal action and overview  dialog when the expected action is done(completed/error) and that dialog(ActionDialog defined in this component) is closed.
      if (actionCompleted) {
        if (proposalActionType === "accept") onAccept();
        else onWithdrawOrDecline();
        closeParentDialog();
        closeOverviewGPDialog();
      } else if (actionError) {
        closeParentDialog();
      }
    }
  }, [actionDialogOpen]);
  return (
    <>
      <div className="p-[48px] max-w-[771px] box-content">
        {proposalActionType === "withdraw" ? (
          <>
            <div className="text-[24px] font-semibold mb-[24px] text-center">
              Are you sure you want to withdraw your application?
            </div>
            <div className="text-[16px] text-[#717171] mb-[56px] text-center">
              Withdrawing the application will put the selection process on
              hold. The client will be informed of this action.
            </div>
          </>
        ) : (
          // Accept or decline
          <div className="text-[24px] font-semibold mb-[24px] text-center">
            Are you sure you want to <b>{proposalActionType}</b> the offer?
          </div>
        )}

        <div className="flex justify-center gap-x-[24px]">
          <CustomButton
            customClasses={clsx([classes.button, classes.cancelButton])}
            onClick={() => {
              isFunction(closeParentDialog) && closeParentDialog();
            }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            type="button1"
            customClasses={classes.button}
            onClick={onActionClick}
          >
            Confirm
          </CustomButton>
        </div>
      </div>
      <ActionDialog
        actionDialogOpen={actionDialogOpen}
        setActionDialogOpen={setActionDialogOpen}
        actionLoading={actionLoading}
        actionCompleted={actionCompleted}
        actionError={actionError}
        actionType={proposalActionType}
      />
    </>
  );
};

ProposalAction.propTypes = {};

export default ProposalAction;
