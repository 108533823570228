import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import HireConsultantOrTeam from "./HireConsultantOrTeam";
import ConsultantDetailsNew from "./ConsultantDetailsNew";
import { makeStyles } from "@mui/styles";
import commonImages from "../../../images/common";
import { removeQDataFromLocalStorage } from "../../Utils/utilFunctions";
import { hasValidConsultantData } from "app/v2/utils/onboardingUtils";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  questionnaireContainer: {
    fontFamily: "Montserrat !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    maxWidth: "12.3rem",
    maxHeight: "3.5rem",
    height: "auto",
    width: "auto",
    cursor: "pointer",
    marginBottom: "60px",
  },
  leftView: {
    borderRight: "1px solid #EDEDED",
  },
  loginDiv: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "235px",
    },
    width: "100%",
    margin: "71px auto 0 auto",
  },
  continueText: {
    fontWeight: 600,
    fontSize: "14px",
    marginBottom: "12px",
    cursor: "pointer",
  },
  continueDesc: {
    fontSize: "14px",
    color: theme.palette.secondary.light,
    marginBottom: "46px",
  },
  loginInitialText: {
    fontSize: "14px",
  },
  loginLink: {
    fontWeight: 600,
    color: `${theme.palette.primary.main} !important`,
    cursor: "pointer",
  },
  rightView: {
    padding: "60px",
    [theme.breakpoints.down("sm")]: {
      padding: "6px",
    }
  },
}));

const Questionnaire = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const hireOptions = ["consultant", "team"];

  const [isInitialStep, setIsInitialStep] = useState(true);
  const [hireSelection, setHireSelection] = useState(hireOptions[0]);

  const onInitialStepClick = () => {
    setIsInitialStep(false);
  };

  const { inviteeID } = props.match.params;


  return (
    <Grid container className={classes.questionnaireContainer}>
      {isInitialStep ? (
        <HireConsultantOrTeam
          hireOptions={hireOptions}
          hireSelection={hireSelection}
          setHireSelection={setHireSelection}
          onSearchClick={onInitialStepClick}
        />
      ) : (
        <div className="flex sm:flex-col w-[100%]">
          {/* <div className="md:grid md:grid-cols-2 md:gap-1"> */}
            <div className={`${classes.leftView}  sm:col-12 md:col-3  w-full`}>
              <div className={classes.loginDiv}>
                <div
                  className={classes.logo}
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  <img src={commonImages.logo_v2} alt="Findpro logo" />
                </div>
                {/* <Typography
                  className={classes.continueText}
                  onClick={() => {
                    if (hasValidConsultantData()) {
                      history.push("/client/sign-up");
                    } else {
                      enqueueSnackbar(
                        "Please select Job Title at least to continue later.",
                        {
                          variant: "error",
                        }
                      );
                    }
                  }}
                >
                  Continue later?
                </Typography>
                <Typography className={classes.continueDesc}>
                  We will still ask you to complete this before you’re able to
                  list a job.
                </Typography> */}
                <Typography className={classes.loginInitialText}>
                  Have an account already?{" "}
                  {/* <Link to="/client/login" className={classes.loginLink}> */}
                  <Link to="/client/login" className={classes.loginLink}>
                    Login
                  </Link>
                </Typography>
              </div>
            </div>
            <div className={`${classes.rightView} sm:col-12 md:col-9  w-full `}>
              <ConsultantDetailsNew setIsInitialStep={setIsInitialStep} inviteeID={inviteeID} />
            </div>
          {/* </div> */}
        </div>
      )}
    </Grid>
  );
};

Questionnaire.propTypes = {};

export default Questionnaire;
