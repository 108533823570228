import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import ContentLoader from "react-content-loader";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Grid } from "@mui/material";

import Title from "../../common/Title";
import { messages } from "../../Utils/constants";
import {
  fetchContractTypesThunk,
  fetchExperiencesThunk,
  fetchIndustryThunk,
  fetchSkillsThunk,
} from "app/v2/store/features/misc/miscThunk";
import CategoriesList from "./CategoriesList";

const useStyles = makeStyles((theme) => ({
  rightView: {
    padding: "24px",
    // width: "1004px",
    width:"100%",
    boxSizing: "content-box",
    display: "flex",
    flexDirection: "column",
    rowGap: "40px",

    [theme.breakpoints.down("1440")]: {
      width: "980px",
    },
    [theme.breakpoints.down("1385")]: {
      width: "850px",
    },
    [theme.breakpoints.down("1285")]: {
      width: "750px",
    },
    [theme.breakpoints.down("1185")]: {
      width: "700px",
    },
  },
}));

const ManageCategories = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { industryData, experiences, skills, contractTypes,SkillLevels } = useSelector(
    (state) => state.misc
  );

  const [dataError, setDataError] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setDataLoading(true);
      try {
        if (!industryData?.length) await dispatch(fetchIndustryThunk());
        if (!experiences?.length) await dispatch(fetchExperiencesThunk());
        if (!skills?.length) await dispatch(fetchSkillsThunk());
        if (!contractTypes?.length) await dispatch(fetchContractTypesThunk());
      } catch (error) {
        setDataError(true);
      } finally {
        setDataLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const data = {
    Skills: skills.map(({ _id, name }) => ({ _id, name })),
    Industries: industryData.map(({ _id, name }) => ({ _id, name })),
   
    ContractTypes:contractTypes.map(({_id,level})=>({_id, name: level})),
    Experiences: experiences.map(({ _id, level }) => ({ _id, name:level })),
  };

  return (
    <>
      <Title title="Manage Categories" />
      {dataError ? (
        <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE}</Alert>
      ) : (
        <Grid container>
          <Grid item className={classes.rightView}>
            {dataLoading ? (
              <ContentLoader
                speed={2}
                width={1400}
                height={800}
                viewBox="0 0 1400 800"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="3" ry="3" width="1000" height="180" />
                <rect x="0" y="200" rx="3" ry="3" width="1000" height="180" />
                <rect x="0" y="400" rx="3" ry="3" width="1000" height="180" />
                <rect x="0" y="600" rx="3" ry="3" width="1000" height="180" />
              </ContentLoader>
            ) : (
              <CategoriesList data={data} />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ManageCategories;
