import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import {
  Typography,
  Alert,
  FormControl,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomLabel from "app/v2/components/filters/CustomLabel";
import CustomDialog from "app/v2/components/CustomDialog";
import CustomCheckbox from "app/v2/components/CustomCheckbox";
import CustomButton from "app/v2/components/CustomButton";
import SectionTitleAndIcon from "./SectionTitleAndIcon";
import AddIcon from "@mui/icons-material/Add";
import {
  isValidArray,
  capitalizeFirstLetter,
  isUndefined,
} from "app/v2/Pages/Utils/utilFunctions";
import { messages, monthNames } from "app/v2/Pages/Utils/constants";

import DialogTitle from "./DialogTitle";
import DataTile from "./DataTile";

const useStyles = makeStyles((theme) => ({
  sectionSubHeading: {
    fontSize: "18px",
    fontWeight: 600,
    marginBottom: "16px",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  addButton: {
    columnGap: "10px",
    border: "1px solid #EDEDED",
    padding: "12px 28px",
    fontSize: "14px",
    fontWeight: 600,
  },
  editOrDeleteIconButton: {
    padding: "10px",
    border: "1px solid #EDEDED",
  },
  currentlyPursuingCBLabel: {
    fontSize: "10px",
    fontWeight: "700",
    color: "#717171",
  },
  dialogActionButton: {
    padding: "16px 28px",
    fontSize: "16px",
    fontWeight: 600,
    width: "100%",
  },
  descriptionRoot: {
    "& .MuiInputBase-inputMultiline": {
      fontSize: "14px !important",
      color: "#717171 !important",
    },
  },
  datePicker: {
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        fontSize: "16px",
      },
    },
  },
  datePickerError: {
    "& .MuiInputBase-root": {
      border: "1px solid red",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        border: "1px solid red",
      },
    },
  },
  actionButton: {
    fontSize: "14px",
    fontWeight: 600,
    padding: "12px 28px",
  },
  cancelButton: {
    backgroundColor: `#EDEDED !important`,
    color: `${theme.palette.common.black} !important`,
  },
}));

const EducationAndCertification = ({
  educationData,
  certificationData,
  onOtherInfoChange,
}) => {
  const classes = useStyles();

  const [sectionType, setSectionType] = useState(); // education or certification.
  const [actionType, setActionType] = useState(""); // add,edit, or delete.
  const [selectedTileIndex, setSelectedTileIndex] = useState();
  const [actionDialogOpen, setActionDialogOpen] = useState(false);

  const isEducationSectionSelected = () => sectionType === "education";

  const isEditActionSelected = () => actionType === "edit";
  const isDeleteActionSelected = () => actionType === "delete";

  const onCloseActionDialog = () => {
    setActionDialogOpen(false);
  };

  const onActionClick = (sectionType, actionType, tileIndex) => {
    setSectionType(sectionType);
    setActionType(actionType);
    setActionDialogOpen(true);
    setSelectedTileIndex(tileIndex);
  };

  const EducationDialogContent = () => {
    const [tileData, setTileData] = useState({
      certificateTitle: "",
      universityName: "",
      courseName: "",
      duration: {
        start: {
          month: "",
          year: "",
        },
        end: {
          month: "",
          year: "",
        },
        isCurrentlyPursuing: false, // if true,end key will contain empty values for month and year.
      },
    });

    const [issuedDate, setIssuedDate] = useState();
    const [endDate, setEndDate] = useState();
    const [validIssuedDate, setValidIssuedDate] = useState();
    const [validEndDate, setValidEndDate] = useState();
    const [disableEditOrDelete, setDisableEditOrDelete] = useState();

    const onTileDataChange = (id, value) => {
      setTileData((prevTileData) => ({ ...prevTileData, [id]: value }));
    };

    const onCurrentlyPursuingCBChange = (id, value) => {
      const updatedDuration = {
        ...tileData?.duration,
        isCurrentlyPursuing: value,
        end: {
          month: "",
          year: "",
        }, // setting default empty values for end.
      };

      setEndDate(undefined);
      onTileDataChange("duration", updatedDuration);
    };

    const isValidDate = (dateDetails) => {
      return Boolean(dateDetails?.month) && Boolean(dateDetails?.year);
    };

    const handleDateChange = (dateType, date) => {
      const monthIndex = date?.month();
      const year = date?.year();
      const updatedDateDetails = { month: monthNames?.[monthIndex], year };
      let updatedDuration = tileData?.duration;
      updatedDuration[dateType] = updatedDateDetails;

      if (dateType === "start") {
        setIssuedDate(date);
        setValidIssuedDate(isValidDate(updatedDateDetails));
      } else {
        setEndDate(date);
        setValidEndDate(isValidDate(updatedDateDetails));
      }
      onTileDataChange("duration", updatedDuration);
    };

    const onActivateEditOrDeleteButton = () => {
      const duration = tileData?.duration;
      const isValidCertificateTitle = tileData?.certificateTitle?.length > 0;
      const isValidUniversityName = tileData?.universityName?.length > 0;
      const isValidCourseName = tileData?.courseName?.length > 0;
      const validDuration = duration?.isCurrentlyPursuing
        ? validIssuedDate
        : validIssuedDate && validEndDate && issuedDate <= endDate;

      const disableButton = !(
        isValidCertificateTitle &&
        isValidUniversityName &&
        isValidCourseName &&
        validDuration
      );

      // // console.log({
      //   isValidCertificateTitle ,
      //   isValidUniversityName ,
      //   isValidCourseName ,
      //   validDuration,
      //   disableButton,
      // });

      setDisableEditOrDelete(disableButton);
    };

    const isDateInvalid = (dateType) => {
      const isStartDateType = dateType === "start";
      const dateValidityToConsider = isStartDateType
        ? validIssuedDate
        : validEndDate;
      return !isUndefined(dateValidityToConsider) && !dateValidityToConsider; // on initial load it'll be undefined,and we dont have to show error message immediately,only when there's a change in date and it is invalid,then we have to show the message.
    };

    const onActionButtonClick = () => {
      let updatedEducationData = educationData;
      if (isDeleteActionSelected()) {
        updatedEducationData = updatedEducationData?.filter(
          (tileData, index) => index !== selectedTileIndex
        );
      } else if (isEditActionSelected()) {
        updatedEducationData[selectedTileIndex] = tileData;
      } else updatedEducationData.push(tileData);
      onOtherInfoChange("education", updatedEducationData);
      onCloseActionDialog();
    };

    useEffect(() => {
      if (isEditActionSelected()) {
        const selectedCardData = educationData?.[selectedTileIndex];
        const duration = selectedCardData?.duration;
        const startDetails = duration?.start;
        const endDetails = duration?.end;

        setTileData(selectedCardData);
        setIssuedDate((dateState) => {
          const year = startDetails?.year;
          const monthIndex = monthNames?.indexOf(startDetails?.month);
          dateState = dayjs().year(year).month(monthIndex);
          return dateState;
        });
        setValidIssuedDate(isValidDate(startDetails));

        if (!duration?.isCurrentlyPursuing) {
          setEndDate((dateState) => {
            const year = endDetails?.year;
            const monthIndex = monthNames?.indexOf(endDetails?.month);
            dateState = dayjs().year(year).month(monthIndex);
            return dateState;
          });

          setValidEndDate(isValidDate(endDetails));
        }
      }
    }, []);

    useEffect(() => {
      // console.log({ tileData });
      onActivateEditOrDeleteButton();
    }, [tileData]);

    return (
      // Setting a fixed width for now,later responsiveness will be checked
      <div className="p-[48px]  w-[739px] box-content">
        {isDeleteActionSelected() ? (
          <>
            Are you sure you want to delete the selected education?
            <div className="flex justify-end gap-x-[16px] pt-[24px]">
              <CustomButton
                customClasses={clsx([
                  classes.actionButton,
                  classes.cancelButton,
                ])}
                onClick={onCloseActionDialog}
              >
                Cancel
              </CustomButton>
              <CustomButton
                type="button1"
                customClasses={classes.actionButton}
                onClick={onActionButtonClick}
              >
                Delete
              </CustomButton>
            </div>
          </>
        ) : (
          <>
            <div className="text-[24px] font-semibold mb-[33px]">
              {capitalizeFirstLetter(actionType)} Education
            </div>
            <div className="px-[16px] flex flex-col gap-y-[24px]">
              <CustomTextfield
                id="certificateTitle"
                label="certificate title"
                value={tileData?.certificateTitle}
                onChange={onTileDataChange}
              />

              <CustomTextfield
                id="universityName"
                label="university name"
                value={tileData?.universityName}
                onChange={onTileDataChange}
              />

              <CustomTextfield
                id="courseName"
                label="Course Name"
                value={tileData?.courseName}
                onChange={onTileDataChange}
              />
              <div className="flex gap-x-[24px]">
                <div className="flex-1 flex flex-col">
                  <CustomLabel label="start date" />
                  <FormControl
                    error={isDateInvalid("start") || issuedDate > endDate}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        views={["year", "month"]}
                        disableFuture={true}
                        className={
                          isDateInvalid("start") || issuedDate > endDate
                            ? classes.datePickerError
                            : classes.datePicker
                        }
                        value={issuedDate}
                        onChange={(date) => {
                          handleDateChange("start", date);
                        }}
                      />
                    </LocalizationProvider>
                    {(isDateInvalid("start") || issuedDate > endDate) && (
                      <FormHelperText className="ml-0">
                        {isDateInvalid("start")
                          ? "Please enter a valid date."
                          : "Start date should be on or before the end date."}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className="flex-1 flex flex-col">
                  {tileData?.duration?.isCurrentlyPursuing ? (
                    <div className="relative top-[46px] text-[16px] font-semibold">
                      - Present
                    </div>
                  ) : (
                    <>
                      {" "}
                      <CustomLabel label="end date" />
                      <FormControl
                        error={isDateInvalid("end") || endDate < issuedDate}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            views={["year", "month"]}
                            disableFuture={true}
                            className={
                              isDateInvalid("end") || endDate < issuedDate
                                ? classes.datePickerError
                                : classes.datePicker
                            }
                            value={endDate}
                            onChange={(date) => {
                              handleDateChange("end", date);
                            }}
                          />
                        </LocalizationProvider>
                        {(isDateInvalid("end") || endDate < issuedDate) && (
                          <FormHelperText className="ml-0">
                            {isDateInvalid("end")
                              ? "Please enter a valid date."
                              : "End date should be on or after the start date."}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </>
                  )}
                </div>
              </div>
              <CustomCheckbox
                label="CURRENTLY PURSUING"
                id="currentlyEmployed"
                checked={tileData?.duration?.isCurrentlyPursuing}
                onChange={onCurrentlyPursuingCBChange}
                customClasses={{ label: classes.currentlyPursuingCBLabel }}
                size="small"
              />

              <Tooltip
                arrow
                title={disableEditOrDelete && "Please enter all the details."}
              >
                <div className="w-full">
                  <CustomButton
                    type="button1"
                    customClasses={classes.dialogActionButton}
                    disabled={disableEditOrDelete}
                    onClick={onActionButtonClick}
                  >
                    {capitalizeFirstLetter(actionType)}
                  </CustomButton>
                </div>
              </Tooltip>
            </div>
          </>
        )}
      </div>
    );
  };

  const CertificationDialogContent = () => {
    const [tileData, setTileData] = useState({
      certificateTitle: "",
      issuedBy: "",
      courseName: "",
      issuedOn: {
        month: "",
        year: "",
      },
      description: "",
    });
    const [issuedDate, setIssuedDate] = useState();
    const [validIssuedDate, setValidIssuedDate] = useState();
    const [disableEditOrDelete, setDisableEditOrDelete] = useState();

    const onTileDataChange = (id, value) => {
      setTileData((prevTileData) => ({ ...prevTileData, [id]: value }));
    };

    const isValidDate = (dateDetails) => {
      return Boolean(dateDetails?.month) && Boolean(dateDetails?.year);
    };

    const handleDateChange = (date) => {
      const monthIndex = date?.month();
      const year = date?.year();
      const updatedDateDetails = { month: monthNames?.[monthIndex], year };
      setIssuedDate(date);
      setValidIssuedDate(isValidDate(updatedDateDetails));
      onTileDataChange("issuedOn", updatedDateDetails);
    };

    const onActivateEditOrDeleteButton = () => {
      const isValidCertificateTitle = tileData?.certificateTitle?.length > 0;
      const isValidIssuingOrg = tileData?.issuedBy?.length > 0;
      const isValidCourseName = tileData?.courseName?.length > 0;
      const isValidDescription = tileData?.description?.length > 0;

      const disableButton = !(
        isValidCertificateTitle &&
        isValidIssuingOrg &&
        isValidCourseName &&
        isValidDescription &&
        validIssuedDate
      );

      // // console.log({
      //   isValidCertificateTitle ,
      //   isValidIssuingOrg ,
      //   isValidCourseName ,
      //   validIssuedDate
      //   disableButton,
      // });

      setDisableEditOrDelete(disableButton);
    };

    const isDateInvalid = () => {
      return !isUndefined(validIssuedDate) && !validIssuedDate; // on initial load it'll be undefined,and we dont have to show error message immediately,only when there's a change in date and it is invalid,then we have to show the message.
    };

    const onActionButtonClick = () => {
      let updatedCertificationData = certificationData;
      if (isDeleteActionSelected()) {
        updatedCertificationData = updatedCertificationData?.filter(
          (tileData, index) => index !== selectedTileIndex
        );
      } else if (isEditActionSelected()) {
        updatedCertificationData[selectedTileIndex] = tileData;
      } else updatedCertificationData.push(tileData);

      onOtherInfoChange("certification", updatedCertificationData);
      onCloseActionDialog();
    };

    useEffect(() => {
      if (isEditActionSelected()) {
        const selectedCardData = certificationData?.[selectedTileIndex];
        const issuedDateDetails = selectedCardData?.issuedOn;

        setTileData(selectedCardData);
        setIssuedDate((dateState) => {
          const year = issuedDateDetails?.year;
          const monthIndex = monthNames?.indexOf(issuedDateDetails?.month);
          dateState = dayjs().year(year).month(monthIndex);
          return dateState;
        });
        setValidIssuedDate(isValidDate(issuedDateDetails));
      }
    }, []);

    useEffect(() => {
      // console.log({ tileData });
      onActivateEditOrDeleteButton();
    }, [tileData]);

    return (
      // The div it taking only 626px for edit case and not 789,look into this later.
      <div className="p-[48px]  max-w-[739px] box-content">
        {isDeleteActionSelected() ? (
          <>
            Are you sure you want to delete the selected certification?{" "}
            <div className="flex justify-end gap-x-[16px] pt-[24px]">
              <CustomButton
                customClasses={clsx([
                  classes.actionButton,
                  classes.cancelButton,
                ])}
                onClick={onCloseActionDialog}
              >
                Cancel
              </CustomButton>
              <CustomButton
                type="button1"
                customClasses={classes.actionButton}
                onClick={onActionButtonClick}
              >
                Delete
              </CustomButton>
            </div>
          </>
        ) : (
          <>
            <div className="text-[24px] font-semibold mb-[33px]">
              {capitalizeFirstLetter(actionType)} Certification
            </div>
            <div className="px-[16px] flex flex-col gap-y-[24px]">
              <CustomTextfield
                id="certificateTitle"
                label="certificate title"
                value={isEditActionSelected() ? tileData?.certificateTitle : ""}
                onChange={onTileDataChange}
              />

              <CustomTextfield
                id="issuedBy"
                label="issuing organization name"
                value={isEditActionSelected() ? tileData?.issuedBy : ""}
                onChange={onTileDataChange}
              />

              <CustomTextfield
                id="courseName"
                label="course name"
                value={isEditActionSelected() ? tileData?.courseName : ""}
                onChange={onTileDataChange}
              />

              <CustomTextfield
                id="description"
                label="description"
                value={isEditActionSelected() ? tileData?.description : ""}
                customClasses={{
                  root: classes.descriptionRoot,
                }}
                onChange={onTileDataChange}
                multiline={true}
                minRows={8}
              />

              <div className="flex flex-col">
                <CustomLabel label="issued on" />
                <FormControl error={isDateInvalid()}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["year", "month"]}
                      disableFuture={true}
                      className={
                        isDateInvalid()
                          ? classes.datePickerError
                          : classes.datePicker
                      }
                      value={issuedDate}
                      onChange={(date) => {
                        handleDateChange(date);
                      }}
                    />
                  </LocalizationProvider>
                  {isDateInvalid() && (
                    <FormHelperText className="ml-0">
                      Please enter a valid date.
                    </FormHelperText>
                  )}
                </FormControl>
              </div>

              <Tooltip
                arrow
                title={disableEditOrDelete && "Please enter all the details."}
              >
                <div className="w-full">
                  <CustomButton
                    type="button1"
                    customClasses={classes.dialogActionButton}
                    disabled={disableEditOrDelete}
                    onClick={onActionButtonClick}
                  >
                    {capitalizeFirstLetter(actionType)}
                  </CustomButton>
                </div>
              </Tooltip>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="py-[36px] border-b-[1px] border-solid border-[#EDEDED]">
        <SectionTitleAndIcon
          title="Education & Certification"
          iconPath="/award.svg"
        />

        <div className="p-[16px]">
          <div className="flex justify-between items-start mb-[12px]">
            <Typography className={classes.sectionSubHeading}>
              Educational History *
            </Typography>
            <CustomButton
              customClasses={classes.addButton}
              onClick={() => {
                onActionClick("education", "add");
              }}
            >
              <AddIcon fontSize="small" />
              <div>Add Education</div>
            </CustomButton>
          </div>
          <div className="flex flex-col gap-y-[16px] mb-[48px] max-h-[269px] overflow-y-auto pr-[16px]">
            {isValidArray(educationData) ? (
              <>
                {educationData?.map((entry, index) => (
                  <div className="flex justify-between items-center">
                    <DataTile data={entry} />
                    <div className="flex gap-x-[24px]">
                      <button
                        className={classes.editOrDeleteIconButton}
                        onClick={() => {
                          onActionClick("education", "edit", index);
                        }}
                      >
                        <img src="/edit.svg" />
                      </button>
                      <button
                        className={classes.editOrDeleteIconButton}
                        onClick={() => {
                          onActionClick("education", "delete", index);
                        }}
                      >
                        <img src="/delete.svg" />
                      </button>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <Alert severity="warning">
                Please add atleast one education entry.
              </Alert>
            )}
          </div>
          <div className="flex justify-between items-start mb-[12px]">
            <Typography className={classes.sectionSubHeading}>
              Certification
            </Typography>
            <CustomButton
              customClasses={classes.addButton}
              onClick={() => {
                onActionClick("certification", "add");
              }}
            >
              <AddIcon fontSize="small" />
              <div>Add Certification</div>
            </CustomButton>
          </div>

          <div className="flex flex-col gap-y-[16px] max-h-[269px] overflow-y-auto pr-[16px]">
            {isValidArray(certificationData) ? (
              <>
                {certificationData?.map((entry, index) => (
                  <div className="flex justify-between items-center gap-x-[16px]">
                    <DataTile data={entry} />
                    <div className="flex gap-x-[24px]">
                      <button
                        className={classes.editOrDeleteIconButton}
                        onClick={() => {
                          onActionClick("certification", "edit", index);
                        }}
                      >
                        <img src="/edit.svg" />
                      </button>
                      <button
                        className={classes.editOrDeleteIconButton}
                        onClick={() => {
                          onActionClick("certification", "delete", index);
                        }}
                      >
                        <img src="/delete.svg" />
                      </button>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <Alert severity="info">{messages?.NO_DATA_FOUND}</Alert>
            )}
          </div>
        </div>
      </div>
      <CustomDialog
        open={actionDialogOpen}
        setOpen={setActionDialogOpen}
        title={<DialogTitle title={capitalizeFirstLetter(sectionType)} />}
        content={
          isEducationSectionSelected() ? (
            <EducationDialogContent />
          ) : (
            <CertificationDialogContent />
          )
        }
      />
    </>
  );
};

EducationAndCertification.propTypes = {};

export default EducationAndCertification;
