import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import CustomLabel from "./CustomLabel";

import {
  isUndefined,
  isValidArray,
  isValidString,
  isFunction,
} from "app/v2/Pages/Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    width: "330px",
    maxHeight: "50px",
    height: "100%",
    border: "1px solid #EDEDED",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: 600,
    fontSize: "16px",
    padding: "2.5px 0",
    "& .Mui-disabled": {
      backgroundColor: "transparent !important",
    },
    [theme.breakpoints.down("sm")]:{

      width:"auto"

    },
  },
  icon: {
    color: theme.palette.common.black,
  },
  disabledText: {
    color: theme.palette.secondary.main,
  },
}));

/**
 * @component LeftRightSelect
 * @description The component is a cyclic element with left and right buttons. The data received is shonw in cyclic order as we navigate through the left and right buttons.
 * @param id id for the component as a whole. Passed back in the onChange.
 * @param label Label for the componentthat is shown on the top.
 * @param data Array of values that the component consists.
 * @param selectedIndex There are three cases when it comes to this prop:
 * 1. If the component is used at places where we fetch the info from the component for the first time we dont pass this prop(And by default the first entry is shown in the component for the user to select)(Eg: Onboarding view).
 * 2. If the component is used in edit cases,and  we need to render the specific value  we pass the relevant index in this prop. While passing the prop in this case make sure to pass null in case the data received from the api does not have this value(Eg:Edit jobs view),So that we show '-' instead of default first entry and when the click on left or right we show last or first entry-the component then behaves as usual.
 * 3. If the component is used in places where it could be either point 1 or 2 (Eg: Organization details) we pass the prop but follow the approach mentioned in point 1(show the first entry if this prop is not a valid index else if a valid index the entry is displayed correctly).
 * @param onChange If its a valid function,this function handler is called when there is a change in the component. We pass the id and updatedIndex.
 * @param disabled Disables the component if true.
 * @param customClasses Prop to pass custom classes for the container and the text element. eg: {container:{display:'flex'},text:{fontSize:'16px'}}.
 */

const LeftRightSelect = ({
  id,
  label,
  data,
  selectedIndex,
  onChange,
  disabled = false,
  customClasses,
}) => {
  const [index, setIndex] = useState(0);
  const classes = useStyles();

  const handleLeftClick = () => {
    let updatedIndex;
    if (index === null) updatedIndex = data.length - 1;
    // No value received to the component so we show a '-' initially and on clicking left we set the index to last value.
    else updatedIndex = index === 0 ? data.length - 1 : index - 1;
    setIndex(updatedIndex);
    if (isFunction(onChange)) onChange(id, updatedIndex);
  };

  const handleRightClick = () => {
    let updatedIndex;
    if (index === null) updatedIndex = 0;
    else updatedIndex = index === data.length - 1 ? 0 : index + 1;
    setIndex(updatedIndex);
    if (isFunction(onChange)) onChange(id, updatedIndex);
  };

  useEffect(() => {
    if (isValidString(selectedIndex)) selectedIndex = parseInt(selectedIndex);
    if (
      (selectedIndex >= 0 && selectedIndex <= data?.length) ||
      selectedIndex === null
    )
      setIndex(selectedIndex);
  }, [data, selectedIndex]);

  return (
    <div>
      {label && <CustomLabel label={label} />}
      {isValidArray(data) ? (
        <div
          className={clsx([classes.selectContainer, customClasses?.container])}
        >
          <IconButton
            onClick={handleLeftClick}
            disabled={disabled}
            data-testid={`${id}-left-button`}
          >
            <ArrowLeftIcon className={classes.icon} />
          </IconButton>
          <span
            className={clsx([
              customClasses?.text,
              disabled && classes.disabledText,
            ])}
            data-testid={`${id}-text-element`}
          >
            {index === null ? "-" : data?.[index]}
          </span>
          <IconButton
            onClick={handleRightClick}
            disabled={disabled}
            data-testid={`${id}-right-button`}
          >
            <ArrowRightIcon className={classes.icon} />
          </IconButton>
        </div>
      ) : (
        <div>-</div>
      )}
    </div>
  );
};

LeftRightSelect.propTypes = {};

export default LeftRightSelect;
