import { createAsyncThunk } from "@reduxjs/toolkit";
import { miscService, updateAgencyEmployeeInfo, updateAgencyOrganizationInfo } from "app/v2/services/miscService";

export const fetchRolesThunk = createAsyncThunk("misc/fetchRoles", async () => {
  return miscService.fetchRoles();
});

export const fetchSkillsThunk = createAsyncThunk(
  "misc/fetchSkills",
  async () => {
    return miscService.fetchSkills();
  }
);

export const fetchSkillLevelsThunk = createAsyncThunk(
  "misc/fetchSkillLevels",
  async () => {
    return miscService.fetchSkillLevels();
  }
);

export const fetchExperiencesThunk = createAsyncThunk(
  "misc/fetchExperiences",
  async () => {
    return miscService.fetchExperiences();
  }
);

export const fetchContractTypesThunk = createAsyncThunk(
  "misc/fetchContractTypes",
  async () => {
    return miscService.fetchContractTypes();
  }
);

export const fetchFAQsThunk = createAsyncThunk(
  "misc/fetchFAQsThunk",
  async () => {
    return miscService.getFAQs();
  }
);

export const fetchIndustryThunk = createAsyncThunk("industry", async () => {
  return miscService.fetchIndustryData();
});

export const updateAgencyEmployeeInfoThunk = createAsyncThunk(
  "profile-settings/put",
  async (request, thunkAPI) => {
    return updateAgencyEmployeeInfo(request).then((res) => {
      // thunkAPI.dispatch(profileComplete(res.isProfileComplete));
      // updateProfileComplete(res.isProfileComplete);
      return res;
    });
  }
);

export const updateAgencyOrganizationInfoThunk = createAsyncThunk(
  "agency-organization/put",
  async (request, thunkAPI) => {
    return updateAgencyOrganizationInfo(request).then((res) => {
      return res;
    });
  }
);