import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import Signup from "../Pages/common/signup/Signup";
import Login from "../Pages/common/login/Login";
import ForgotPassword from "../Pages/common/forgotpassword/ForgotPassword";
import ResetPassword from "../Pages/common/resetpassword/ResetPassword";
import Questionnaire from "../Pages/Consultant/onboarding/Questionnaire";
import DashboardPage from "../Pages/Consultant/dashboard/Dashboard.page";
import JobFeed from "../Pages/Consultant/searchresultsandfeed/JobFeed";
import Settings from "../Pages/Consultant/Settings";
import MyJobs from "../Pages/Consultant/searchresultsandfeed/MyJobs";
import Connections from "../Pages/Consultant/searchresultsandfeed/Connections";
import ActiveWork from "../Pages/Consultant/searchresultsandfeed/activework/ActiveWork";
import GoogleAuth from "app/common/login/google-auth.component";
import PrivateRoute from "./PrivateRoute";
import AccountActivation from "../Pages/common/AccountActivation";
import SignupEmailVerified from "../Pages/common/SignupEmailVerified";
import JobDetail from "../Pages/Consultant/details/job";
import AgencyDetail from "../Pages/Consultant/details/agency";
import PaymentErrorPage from "../Pages/Consultant/Settings/subscription/PaymentErrorPage";
import PaymentSuccessPage from "../Pages/Consultant/Settings/subscription/PaymentSuccessPage";

import withLocalStorageCheck from "./hoc/withConsultantDataCheck";
import LinkedinAuth from "app/common/login/linkedin-auth-component";

const useStyles = makeStyles((theme) => ({
  bannerAndNavbar: {
    position: "fixed",
    top: 0,
    width: "100%",
    maxWidth: "1440px", // setting max-width again since the element has fixed position and does not inherit it from parent.
    zIndex: 999,
  },
  body: {
    display: "flex",
    flexDirection: "column",
  },
  bodyWithF: {
    minHeight: "calc(100vh - 421px)", // total viewport height minus navbar(143) and footer's(278) heights.
  }, // F-Footer
  bodyWithBNF: {
    minHeight: "calc(100vh - 278px)", // total viewport height minus footer's heights.
    paddingTop: "134px", // fixed banner and navbar's height
  }, // BNF-Banner,Navbar,Footer
  bodyWithNF: {
    minHeight: "calc(100vh - 278px)", // total viewport height minus footer's heights.
    paddingTop: "100px", // fixed navbar's height
  }, // NF- Navbar,Footer
}));

const withBannerNavbarFooter = (WrappedComponent) => {
  const ComponentWithBNF = (props) => {
    const classes = useStyles();
    const [showBanner, setShowBanner] = useState(false); // Setting default as false -> Not showing banner for MVP.

    const onBannerClose = () => {
      setShowBanner(false);
    };

    return (
      <>
        <div className={classes.bannerAndNavbar}>
          <Banner showBanner={showBanner} onClose={onBannerClose} />
          <Navbar />
        </div>
        <div
          className={clsx([
            classes.body,
            showBanner ? classes.bodyWithBNF : classes.bodyWithNF,
          ])}
        >
          <WrappedComponent {...props} />
        </div>

        <Footer />
      </>
    );
  };

  return ComponentWithBNF;
};

const withFooter = (WrappedComponent) => {
  const ComponentWithFooter = (props) => {
    const classes = useStyles();
    return (
      <>
        <div className={clsx([classes.body, classes.bodyWithF])}>
          <WrappedComponent {...props} />
        </div>
        <Footer />
      </>
    );
  };

  return ComponentWithFooter;
};
const ConsultantRoute = () => {
  const AgencyDetailView = withBannerNavbarFooter(AgencyDetail);
  const JobDetailView = withBannerNavbarFooter(JobDetail);

  return (
    <Switch>
      <Route
        path="/consultant/sign-up"
        component={withLocalStorageCheck(Signup)}
      />
      <Route path="/consultant/sign-up" component={withFooter(Signup)} />
      <Route path={"/consultant/login"} component={withFooter(Login)} />
      {/* edss */}
      <Route
        path="/consultant/reset-password/:token"
        component={ResetPassword}
      />
      <Route
        path="/consultant/account-activation"
        component={withFooter(AccountActivation)}
      />
      <Route
        path="/consultant/verify-email/:token"
        component={SignupEmailVerified}
      />
      <Route path="/consultant/google-auth" component={GoogleAuth} />
      <Route path="/consultant/linkedin-auth" component={LinkedinAuth} />

      <Route
        path="/consultant/forgot-password"
        component={withFooter(ForgotPassword)}
      />
      <Route path={"/consultant/onboarding"} component={Questionnaire} />
      <PrivateRoute
          exact
          path={"/consultant"}
          component={withBannerNavbarFooter(DashboardPage)}
        />
      <PrivateRoute
        path={"/consultant/dashboard"}
        component={withBannerNavbarFooter(DashboardPage)}
      />
      <PrivateRoute
        path={"/consultant/job-detail/:jobID"}
        render={(props) => <JobDetailView {...props} />}
      />
      <PrivateRoute
        path={"/consultant/agency-detail"}
        render={(props) => <AgencyDetailView />}
      />
      <PrivateRoute
        path={"/consultant/job-feed"}
        component={withBannerNavbarFooter(JobFeed)}
      />
      <PrivateRoute
        path={"/consultant/settings"}
        component={withBannerNavbarFooter(Settings)}
      />
      <PrivateRoute
        path={"/consultant/my-jobs"}
        component={withBannerNavbarFooter(MyJobs)}
      />
      <PrivateRoute
        path={"/consultant/connections"}
        component={withBannerNavbarFooter(Connections)}
      />
      <PrivateRoute
        path={"/consultant/active-work"}
        component={withBannerNavbarFooter(ActiveWork)}
      />{" "}
      <PrivateRoute
        path="/consultant/subscription-payment-success"
        component={withBannerNavbarFooter(PaymentSuccessPage)}
      />
      <PrivateRoute
        path="/consultant/subscription-payment-error"
        component={withBannerNavbarFooter(PaymentErrorPage)}
      />
      <Redirect
        from="/consultant/help"
        to={{ pathname: "/consultant/settings", state: { settingIndex: 4 } }} // help & support view's index.
      />
    </Switch>
  );
};

export default ConsultantRoute;
