import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";

export const getAllClients = async (bodyRequest) => {
  return fetchWrapper(`${configs.APIUrl}/admin/allClients`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: bodyRequest,

    //   {
    //     "limit": 100,
    //     "offset": 0,
    //     "country": "United State", // If don't want to filter companySize then don't this
    //     "industry": "Technology", // If don't want to filter companySize then don't this
    //     "companySize": 10, // [10,50] -> this will be a range for companySize // If don't want to filter companySize then don't this
    //     "order": "oldest" //asc, desc, oldest, latest /// asc order result by company name from atoz, desc order result by company name from ztoa
    // }
  });
};

export const updatePlan = async (bodyRequest) => {
  return fetchWrapper(`${configs.APIUrl}/admin/subscribeOrganization`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: bodyRequest,
  });
};
export const getClientDetail = async (clientID) => {
  return fetchWrapper(`${configs.APIUrl}/admin/clientInfo/${clientID}`);
};
export const getClientJobList = async (empID) => {
  return fetchWrapper(`${configs.APIUrl}/admin/clientInfo/jobs/${empID}`);
};


export const restrictClientApi = async (clientID, status) => {
  return fetchWrapper(
    `${configs.APIUrl}/admin/client/${clientID}/${status}`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteClientApi = async (clientID) => {
  return fetchWrapper(
    `${configs.APIUrl}/admin/client/${clientID}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
