

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";

import {
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";


import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomButton from "app/v2/components/CustomButton";
import DialogSuccessBody from "app/v2/Pages/common/DialogSuccessBody";
import DialogErrorBody from "app/v2/Pages/common/DialogErrorBody";
import DialogLoadingBody from "app/v2/Pages/common/DialogLoadingBody";
import CustomDialog from "app/v2/components/CustomDialog";
import CustomTooltip from "app/v2/components/CustomTooltip";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import CustomSelect from "app/v2/components/filters/CustomSelect";

import { defaultSkillList, messages } from "app/v2/Pages/Utils/constants";
import { isValidArray, isValidObject } from "app/v2/Pages/Utils/utilFunctions";
import useFetch from "app/common/utils/useFetch";
import { s3Upload } from "app/v2/services/miscService";
import { EMAIL_PATTERN } from "app/v2/Pages/Utils/constants";
import {
  getRolesThunk,
  reSendAgencyEmployeeInvitationThunk,
  sendAgnecyEmployeeInviteThunk,
  UpdateAgencyEmployeeDetailsThunk,
} from "app/v2/store/features/agency/employee.thunk";
import CustomLabel from "app/v2/components/filters/CustomLabel";
import Overview from "./Overview";
const employeeData = ["a", "b"];

const useStyles = makeStyles((theme) => ({
  sendInviteButton: {
    width: "100%",
    padding: "16px 28px",
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
    columnGap: "10px",
    fontSize: "16px",
    fontWeight: 600,
  },
  line: {
    marginBottom: 0,
  },
  resendInviteText: {
    fontSize: "14px",
    fontWeight: 600,
    color: `${theme.palette.primary.main} !important`,
    marginTop: "12px",
    cursor: "pointer",
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "center",
  },
}));

const SendInviteDialog = ({
  sendInviteDialogOpen,
  setSendInviteDialogOpen,
  sendInviteActionLoading,
  sendInviteCompleted,
  selectedEmployee,
  sendInviteError,
}) => {
  const classes = useStyles();

  const Title = () => {
    return (
      <Typography className={classes.dialogTitle}>
        {sendInviteActionLoading && "Action in progress"}
        {sendInviteError && "Error"}
        {sendInviteCompleted && "Changes Saved"}
      </Typography>
    );
  };

  const DialogBody = (selectedEmployee) => {
    if (sendInviteActionLoading) return <DialogLoadingBody />;
    else if (sendInviteCompleted)
      return (
        <DialogSuccessBody
          content={{
            title: selectedEmployee
              ? "Employee Updated successfully."
              : "Employee added successfully.",
          }}
        />
      );
    else if (sendInviteError)
      return (
        <DialogErrorBody
          content={{ title: " Email is already exists ", desc: "" }}
        />
      );
    else return <></>;
  };

  return (
    <CustomDialog
      open={sendInviteDialogOpen}
      setOpen={setSendInviteDialogOpen}
      noCloseAction={sendInviteActionLoading}
      title={<Title />}
      content={<DialogBody selectedEmployee={selectedEmployee} />}
    />
  );
};

const AddEmployee = ({
  selectedEmployee,
  handleDialoguesAndApi,
  isAdmin,
  isRecruiter,
  isinterviwer,
  isHr,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [get] = useFetch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

 console.log("amadmin1",isAdmin)

  const AgencyDetail = useSelector(
    (state) => state?.agencyProfileInfo?.agencyInfo?.data
  );

  const [employeeRoles, setEmployeeRoles] = useState([]);
  const [employeeRolesData, setEmployeeRolesData] = useState([]);
  const [selectedEmployeeRole, setSelectedEmployeeRole] = useState("");
  console.log("employeeRoles",employeeRoles)
  
  useEffect(() => {
    // Set the default roleType based on selectedEmployee.role
    if (selectedEmployee?.role) {
      setSelectedEmployeeRole(selectedEmployee?.role);
    }
  }, []);
  useEffect(() => {
    setConsultantData({
      ...consultantData,
      ["orgId"]: AgencyDetail?.agencyDetail?.orgId,
    });
  }, [AgencyDetail]);

  useEffect(() => {
    dispatch(getRolesThunk())
      .unwrap()
      .then((res) => {
        if (res ) {
          setEmployeeRoles(res?.data);
        }
      })
      .catch((err) => {
        if (err.message) {
          // enqueueSnackbar(err.message, { variant: "error" });
          const key = enqueueSnackbar(err.message, {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        } else {
          // enqueueSnackbar("Problem in fetching data!", { variant: "error" });

          const key = enqueueSnackbar("Problem in fetching data!", {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        }
        console.error(err);
      });
  }, [dispatch]);

  const {
    experiences,
    contractTypes: contractTypesReceived,
    skills,
    industryData,
  } = useSelector((state) => state.misc);

  const [consultantData, setConsultantData] = useState(
    // {

    //   overview: {
    //     first_name: "",
    //     last_name: "",
    //     jobTitle: "",
    //     displayPicture: "",
    //     salaryDetails: {},
    //     experienceLevel: 0, // experienceLevel index as per experienceLevel data(we fetch that data below).
    //     availability: 0, // contractType index as per contractTypes data(we fetch that data below).
    //     country: "",
    //     city: "",
    //     state: "",
    //     timezone: "",
    //     summary: "",
    //   },
    //   socialLinks: {
    //     website: "",
    //     facebook: "",
    //     linkedin: "",
    //   },
    //   skills: defaultSkillList,
    //   workHistory: [],
    //   education: [],
    //   certification: [],
    //   languages: [],
    //   awards: [],
    //   testimonials: [],
    //   resumeDoc: "",
    //   videoResume: "",
    //   userRole:""
    // }
    {
      email: "",
      firstName: "",
      lastName: "",
      orgId: "",
      timezone: "",
      country: "",
      state: "",
      city: "",
      phoneNumber: "",
      roleId: "",
      companyTitle: "",
    }
  );

  const [consultantEmail, setConsultantEmail] = useState();
  const [experienceTypes, setExperienceTypes] = useState([]);
  const [contractTypes, setContractTypes] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [skillIDToNameMapper, setSkillIDToNameMapper] = useState();
  // {
  //   'id':'name'
  // }
  const [activateSendInvite, setActivateSendInvite] = useState(true);
  const [sendInviteDialogOpen, setSendInviteDialogOpen] = useState(false);
  const [sendInviteActionLoading, setSendInviteActionLoading] = useState(false);
  const [sendInviteCompleted, setSendInviteCompleted] = useState(false);
  const [sendInviteError, setSendInviteError] = useState(false);
  const roleName=localStorage.getItem("roleName")


  const onOverviewEntryChange = (id, value) => {
    setConsultantData((prevProfileData) => {
      let updatedProfileData = {
        ...prevProfileData,
        [id]: value,
      };
      return updatedProfileData;
    });
  };

  const onOtherInfoChange = (id, value) => {
    setConsultantData((prevProfileData) => {
      let updatedProfileData = { ...prevProfileData };
      updatedProfileData[id] = value;

      return updatedProfileData;
    });
  };

  const onSocialLinksEntryChange = (id, value) => {
    setConsultantData((prevProfileData) => {
      let updatedProfileData = { ...prevProfileData };
      let socialLinksData = { ...updatedProfileData?.socialLinks };
      socialLinksData[id] = value;
      updatedProfileData.socialLinks = socialLinksData;
      return updatedProfileData;
    });
  };

  const onFileUploadToS3 = async (file, fileId) => {
    let s3UploadedFileDetails;
    const formData = new FormData();
    formData.append("file", file);
    try {
      s3UploadedFileDetails = await get(s3Upload(formData));
    } catch (err) {
      // enqueueSnackbar(
      //   `Problem in uploading the ${fileId},please try again later!`,
      //   { variant: "error" }
      // );
      const key = enqueueSnackbar(
        `Problem in uploading the ${fileId},please try again later!`,
        {
          variant: "error",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        }
      );
    }
    return s3UploadedFileDetails?.[0]?.location;
  };

  const handleDialoguesAndApi2 = () => {
    handleDialoguesAndApi();
  };

  const onSendInviteClick = async (constData) => {
    setSendInviteError(false);
    //// console.log("consultantData--", consultantData)
    let displayPictureUrl, resumeDocUrl, videoResumeUrl;

    // the below 3 elements can be the url or an object(if upload of any of those were attempted, structure- {file,localFileUrl,prevFileUrl}).
    const displayPictureUrlOrDetails = consultantData?.overview?.displayPicture;
    const resumeDocUrlOrDetails = consultantData?.resumeDoc;
    const videoResumeUrlOrDetails = consultantData?.videoResume;

    const displayPictureFile = displayPictureUrlOrDetails?.file;
    const resumeDocFile = resumeDocUrlOrDetails?.file;
    const videoResumeFile = videoResumeUrlOrDetails?.file;

    setSendInviteDialogOpen(true);
    setSendInviteActionLoading(true);

    // If at all upload was attempted for them we try to access the file part of the object(done above) and upload it to s3 and get the updated url of its location in s3 on successful upload.If s3 upload fails we we show the message in snackbar and update the prev file itself in the profileData.
    //  Else i.e, upload was not attempted, file value wont exist and it will be the url instead(prev/current url).

    const updatedConsultantData = { ...consultantData };

    setTimeout(() => {
      dispatch(sendAgnecyEmployeeInviteThunk(constData || consultantData))
        .unwrap()
        .then((res) => {
          if (res) {
            if (res?.message) {
              // enqueueSnackbar(res.message, { variant: "success" });
              const key = enqueueSnackbar(res.message, {
                variant: "success",
                autoHideDuration: 3000,
                action: (key) => (
                  <CloseIcon
                    onClick={() => closeSnackbar(key)}
                    style={{
                      cursor: "pointer",
                      fontSize: "15",
                      marginTop: "-1px",
                    }}
                  />
                ),
              });
            }
            setSendInviteActionLoading(false);
            setSendInviteCompleted(true);
            setSendInviteDialogOpen(false);
            handleDialoguesAndApi2();
          }
        })
        .catch((err) => {
          setSendInviteActionLoading(false);
          setSendInviteError(true);
          // enqueueSnackbar(err.message, { variant: "error" });
          const key = enqueueSnackbar(err.message, {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });

          console.error(err);
        });

      /** Confirm case */
      // setSendInviteActionLoading(false);
      // setSendInviteCompleted(true);
      /** Error case */
      // setSendInviteActionLoading(false);
      // setSendInviteError(true);
    }, 1000);
  };

  const onresendInviteClick = async (constData) => {
    setSendInviteError(false);
    //// console.log("consultantData--", consultantData)
    let displayPictureUrl, resumeDocUrl, videoResumeUrl;

    setSendInviteDialogOpen(true);
    setSendInviteActionLoading(true);

    // If at all upload was attempted for them we try to access the file part of the object(done above) and upload it to s3 and get the updated url of its location in s3 on successful upload.If s3 upload fails we we show the message in snackbar and update the prev file itself in the profileData.
    //  Else i.e, upload was not attempted, file value wont exist and it will be the url instead(prev/current url).

    setTimeout(() => {
      dispatch(reSendAgencyEmployeeInvitationThunk(constData || consultantData))
        .unwrap()
        .then((res) => {
          if (res) {
            if (res?.message) {
              // enqueueSnackbar(res.message, { variant: "success" });
              const key = enqueueSnackbar(res.message, {
                variant: "success",
                autoHideDuration: 3000,
                action: (key) => (
                  <CloseIcon
                    onClick={() => closeSnackbar(key)}
                    style={{
                      cursor: "pointer",
                      fontSize: "15",
                      marginTop: "-1px",
                    }}
                  />
                ),
              });
            }
            setSendInviteActionLoading(false);
            // setSendInviteCompleted(true);
            setSendInviteDialogOpen(false);
            handleDialoguesAndApi2();
          }
        })
        .catch((err) => {
          setSendInviteActionLoading(false);
          setSendInviteError(true);
          // enqueueSnackbar(err.message, { variant: "error" });
          const key = enqueueSnackbar(err.message, {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });

          console.error(err);
        });

      /** Confirm case */
      // setSendInviteActionLoading(false);
      // setSendInviteCompleted(true);
      /** Error case */
      // setSendInviteActionLoading(false);
      // setSendInviteError(true);
    }, 1000);
  };
  const handleOnUpdate = () => {
    setSendInviteDialogOpen(true);
    setSendInviteActionLoading(true);
    const request = {
      id: selectedEmployee._id,
      roleId: employeeRoles?.find(
        (emp) => emp?.roleType === selectedEmployeeRole
      )?._id,
    };

    setTimeout(() => {
      dispatch(UpdateAgencyEmployeeDetailsThunk(request))
        .unwrap()
        .then((res) => {
          if (res && res?.message) {
            // enqueueSnackbar(res.message, { variant: "success" });
            const key = enqueueSnackbar(res.message, {
              variant: "success",
              autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon
                  onClick={() => closeSnackbar(key)}
                  style={{
                    cursor: "pointer",
                    fontSize: "15",
                    marginTop: "-1px",
                  }}
                />
              ),
            });

            setSendInviteActionLoading(false);
            // setSendInviteCompleted(true);
            setSendInviteDialogOpen(false);

            handleDialoguesAndApi2();
          }
        })
        .catch((err) => {
          setSendInviteActionLoading(false);
          setSendInviteError(true);
          // enqueueSnackbar(err.message, { variant: "error" });
          const key = enqueueSnackbar(err.message, {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        });

      /** Confirm case */
      // setSendInviteActionLoading(false);
      // setSendInviteCompleted(true);
      /** Error case */
      // setSendInviteActionLoading(false);
      // setSendInviteError(true);
    }, 1000);
  };

  const validateEntries = () => {
    // no validation for experience level, availability
    // const overviewData = consultantData?.overview;
    const overviewData = consultantData;

    const useRoleData = consultantData?.roleId;
    // const isValidFirstName = overviewData?.firstName?.length > 0;
    const isValidFirstName = overviewData?.firstName?.length > 0;
    // const isValidLastName = overviewData?.lastName?.length > 0;
    const isValidLastName = overviewData?.lastName?.length > 0;

    const isUserRoleData = useRoleData?.length > 0;
    const countrySelected = Boolean(overviewData?.country);
    const stateSelected = Boolean(overviewData?.state);
    const cityEntered = overviewData?.city?.length > 0;
    const timezoneSelected = Boolean(overviewData?.timezone);

    const validOverviewDetails =
      // isValidFirstName && isValidLastName && isUserRoleData;
      // countrySelected &&
      // stateSelected &&
      // cityEntered &&
      //   isUserRoleData &&
      // timezoneSelected
      (selectedEmployee || (isValidFirstName && isValidLastName)) &&
      isUserRoleData;

    // const validConsultantEmail = EMAIL_PATTERN.test(consultantEmail);
    const validConsultantEmail = EMAIL_PATTERN.test(consultantData.email);

    // // console.log({
    //   validOverviewDetails,
    //   skillsSelected,
    //   workExperienceAdded,
    //   educationAdded,
    //   resumeAvailable,
    //   validConsultantEmail,
    // });
    setActivateSendInvite(
      selectedEmployee
        ? validOverviewDetails
        : validOverviewDetails && validConsultantEmail
    );
  };

  const onEmailChange = (id, value) => {
    setConsultantEmail(value);
  };

  const handleOnChangeofRole = (id, value) => {
    setSelectedEmployeeRole(value);
    setConsultantData({
      ...consultantData,
      roleId: employeeRoles?.find((emp) => emp?.roleType === value)?._id,
    });
  };

  useEffect(() => {
    //// console.log({ consultantData });
    validateEntries();
  }, [consultantData]);


//   useEffect(() => {
//     if (!employeeRoles) return; 

//     let filteredRoles = [];

//     if (isAdmin) {
//         filteredRoles = employeeRoles.map(role => role.roleType);
//         console.log("filteredRoles",filteredRoles)

//     } else {
//         employeeRoles.forEach(role => {
//             if (isinterviwer && role?.roleType === "Interviewer") {
//                 filteredRoles.push(role?.roleType); 
//             } else if (isRecruiter && role?.roleType === "Recuriter" && role?.roleType === "Interviewer") {
//                 filteredRoles.push(role?.roleType);
//             } else if (isHr && (role?.roleType === "Recuriter" && role?.roleType === "Interviewer" &&  role?.roleType === "Interviewer")) {
//                 filteredRoles.push(role?.roleType);
//             }
//         });
//     }

//     setEmployeeRolesData(filteredRoles);
// }, [employeeRoles, isAdmin, isinterviwer, isRecruiter, isHr]);


useEffect(() => {
  if (!employeeRoles) return; 

  let filteredRoles = [];

  if (isAdmin) {
      filteredRoles = employeeRoles;
  } else {
      employeeRoles.forEach(role => {
          if (isinterviwer && role?.roleType === "Interviewer") {
              filteredRoles.push(role);
          }
          if (isRecruiter && (role?.roleType === "Recuriter" || role?.roleType === "Interviewer")) {
              filteredRoles.push(role);
          }
          if (isHr && (role?.roleType === "Recuriter" || role?.roleType === "Interviewer" || role?.roleType === "HR")) {
              filteredRoles.push(role);
          }
      });
  }

  setEmployeeRolesData(filteredRoles?.map(role => role?.roleType));
}, [employeeRoles, isAdmin, isinterviwer, isRecruiter, isHr]);
  return (
    <div className="p-[48px] flex flex-col gap-y-[24px]">
      <Overview
        // overviewData={consultantData?.overview}
        // socialLinks={consultantData?.socialLinks}
        // experienceTypes={experienceTypes}
        // contractTypes={contractTypes}
        onOverviewEntryChange={onOverviewEntryChange}
        // onSocialLinksEntryChange={onSocialLinksEntryChange}
        selectedEmployee={selectedEmployee}
      />

      <div className="text-[24px] font-semibold">Invitation Email</div>
      <div className="px-[16px]">
        <CustomTextfield
          id="email"
          type="email"
          label="email"
          placeholder="Add Email"
          // value={consultantEmail}
          // onChange={onEmailChange}
          value={
            selectedEmployee ? selectedEmployee.email : consultantData.email
          }
          onChange={(email, val) => {
            setConsultantData({
              ...consultantData,
              ["email"]: val,
            });
          }}
          disabled={!!selectedEmployee}
        />
        <Link
          className={classes.resendInviteText}
          onClick={() => {
            // setConsultantData({
            //   ...consultantData,
            //   "email": selectedEmployee ? selectedEmployee.email : consultantData.email,
            // });
            onresendInviteClick({
              ...consultantData,
              email: selectedEmployee
                ? selectedEmployee?.email
                : consultantData?.email,
              id: selectedEmployee?._id,
            });
          }}
        >
          Resend Invite
        </Link>
      </div>
      <div className="text-[24px] font-semibold">Role</div>

      <Box
        sx={{
          border: "1px solid lightgray",
          margin: "5px 15px",
          padding: "15px",
          backgroundColor: "#F4F8FF",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ fontSize: "14px", color: "#1E5DFF" }}
        >
          User Role
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: "12px", color: "lightgray" }}
        >
          Select the role that applies to the duties of this employee within the
          Findpro App. This will dictate the parameters by which they can make
          changes in the company
        </Typography>
        <Box sx={{ margin: "5px 0px" }}>
          {/* <CustomSelect data={employeeData} placeholder={""} onChange={onOverviewEntryChange} overviewData={consultantData.userRole} /> */}

          <Box>
            {/* <FormControl fullWidth>
              <Select
                labelId={`role-select-label`}
                id={`role-select`}
                value={selectedEmployeeRole?.roleType}
                // label={"placeholder"}
                onChange={(e) => {
                  // console.log(e.target.value);
                  setSelectedEmployeeRole(e.target.value);
                  setConsultantData({
                    ...consultantData,
                    ["roleId"]: e.target.value._id,
                  });
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                }}
              >
                {employeeRoles?.map((menuItemValue, idx) => (
                  <MenuItem
                    key={idx}
                    value={menuItemValue}
                  // className={classes.menuItem}
                  >
                    {menuItemValue?.roleType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            <CustomSelect
              id="roleType"
              name="roleType"
              data={employeeRolesData}
              // label="Time Sheet  Type"
              placeholder={"Role type"}
              defaultSelection={selectedEmployee?.role ?? ""}
              onChange={handleOnChangeofRole}
              // defaultSelection={onboardingStatus}
            />
          </Box>
        </Box>
      </Box>

      <CustomTooltip
        title={!activateSendInvite && "Please enter the mandatory details."}
      >
        <div>
          <CustomButton
            type="button1"
            customClasses={classes.sendInviteButton}
            disabled={!activateSendInvite}
            onClick={() => {
              if (selectedEmployee) {
                handleOnUpdate();
              } else {
                onSendInviteClick();
              }
            }}
          >
            {selectedEmployee ? "Update" : "Send Invite"}{" "}
            <img src="/iconfilledsend.svg" />
          </CustomButton>
        </div>
      </CustomTooltip>
      <SendInviteDialog
        sendInviteDialogOpen={sendInviteDialogOpen}
        setSendInviteDialogOpen={setSendInviteDialogOpen}
        sendInviteActionLoading={sendInviteActionLoading}
        sendInviteCompleted={sendInviteCompleted}
        sendInviteError={sendInviteError}
      />
    </div>
  );
};

AddEmployee.propTypes = {};

export default AddEmployee;


















// // import React, { useState,useEffect } from "react";
// // import {
// //   Avatar,
// //   Box,
// //   Dialog,
// //   DialogActions,
// //   DialogContent,
// //   DialogContentText,
// //   DialogTitle,
// //   Divider,
// //   FormControl,
// //   Grid,
// //   IconButton,
// //   InputAdornment,
// //   InputLabel,
// //   Link,
// //   MenuItem,
// //   OutlinedInput,
// //   Select,
// //   Stack,
// //   TextField,
// //   Typography,
// // } from "@mui/material";
// // import { makeStyles } from "@mui/styles";
// // import CustomSelect from "app/v2/components/filters/CustomSelect";
// // import CheckIcon from "@mui/icons-material/Check";
// // import CustomTextfield from "app/v2/components/filters/CustomTextfield";
// // import CustomTooltip from "app/v2/components/CustomTooltip";
// // import { EMAIL_PATTERN } from "../../Utils/constants";
// // import DialogSuccessBody from "../../common/DialogSuccessBody";
// // import DialogErrorBody from "../../common/DialogErrorBody";
// // import CustomDialog from "app/v2/components/CustomDialog";
// // import DialogLoadingBody from "../../common/DialogLoadingBody";
// // import { messages } from "../../Utils/constants";
// // const consultantss = ["a", "b"];

// // const useStyles = makeStyles((theme) => ({
// //   alEmailFormControl: {
// //     width: "100%",
// //   },
// //   alEmailInput: {
// //     width: "100%",
// //     height: "40px",
// //     backgroundColor: "#F7F7F7",
// //     borderRadius: "0",
// //     "& .MuiInputBase-input": {
// //       padding: "9px 12px",
// //       fontSize: "1px",
// //       [theme.breakpoints.down("1250")]: {
// //         fontSize: "10px",
// //       },
// //     },
// //     "& .MuiOutlinedInput-notchedOutline": {
// //       border: "0",
// //     },
// //   },
// //   box: {
// //     // textAlign: "center",
// //   },
// //   formControl: {
// //     "& .MuiOutlinedInput-notchedOutline": {
// //       borderRadius: 0,
// //       border: "1px solid #EDEDED",
// //     },
// //   },
// //   selectRoot: {
// //     height: "50px",
// //     fontSize: "16px",
// //   },
// //   selectLabel: {
// //     fontSize: "16px",
// //     color: theme.palette.secondary.light,
// //   },
// //   menuItem: {
// //     fontSize: "16px",
// //   },
// // }));

// // const SendInviteDialog = ({
// //     sendInviteDialogOpen,
// //     setSendInviteDialogOpen,
// //     sendInviteActionLoading,
// //     sendInviteCompleted,
// //     sendInviteError,
// //   }) => {
// //     const classes = useStyles();

// //     const Title = () => {
// //       return (
// //         <Typography className={classes.dialogTitle}>
// //           {sendInviteActionLoading && "Action in progress"}
// //           {sendInviteError && "Error"}
// //           {sendInviteCompleted && "Changes Saved"}
// //         </Typography>
// //       );
// //     };

// //     const DialogBody = () => {
// //       if (sendInviteActionLoading) return <DialogLoadingBody />;
// //       else if (sendInviteCompleted)
// //         return (
// //           <DialogSuccessBody
// //             content={{ title: "Your changes have been saved." }}
// //           />
// //         );
// //       else if (sendInviteError)
// //         return (
// //           <DialogErrorBody
// //             content={{ title: messages.GENERIC_ERROR_MESSAGE, desc: "" }}
// //           />
// //         );
// //       else return <></>;
// //     };

// //     return (
// //       <CustomDialog
// //         open={sendInviteDialogOpen}
// //         setOpen={setSendInviteDialogOpen}
// //         noCloseAction={sendInviteActionLoading}
// //         title={<Title />}
// //         content={<DialogBody />}
// //       />
// //     );
// //   };

// // const AddTeamMembers = () => {
// //   const classes = useStyles();
// //   const [consultantEmail, setConsultantEmail] = useState();
// //   const [activateSendInvite, setActivateSendInvite] = useState(true);
// //   const [isButtonDisabled, setIsButtonDisabled] = useState(true);
// //   const [selectedConsultant, setSelectedConsultant] = useState("");
// //   const [sendInviteDialogOpen, setSendInviteDialogOpen] = useState(false);
// //   const [sendInviteActionLoading, setSendInviteActionLoading] = useState(false);
// //   const [sendInviteCompleted, setSendInviteCompleted] = useState(false);
// //   const [sendInviteError, setSendInviteError] = useState(false);

// //   const consultants = [
// //     {
// //       name: "Lacy Clark",
// //       email: "lacy.clark@gmail.com",
// //       avatar: "/avatar.png",
// //     },
// //     {
// //       name: "Mark Smit",
// //       email: "m.smit@gmail.com",
// //       avatar: "/avatar.png",
// //     },
// //     {
// //       name: "John Doe",
// //       email: "john.doe@gmail.com",
// //       avatar: "/avatar.png",
// //     },
// //     // Add more consultant data as needed
// //   ];

// //   const onSendInviteClick = async () => {

// //     // the below 3 elements can be the url or an object(if upload of any of those were attempted, structure- {file,localFileUrl,prevFileUrl}).

// //     setSendInviteDialogOpen(true);
// //     setSendInviteActionLoading(true);

// //     // If at all upload was attempted for them we try to access the file part of the object(done above) and upload it to s3 and get the updated url of its location in s3 on successful upload.If s3 upload fails we we show the message in snackbar and update the prev file itself in the profileData.
// //     //  Else i.e, upload was not attempted, file value wont exist and it will be the url instead(prev/current url).

// //     setTimeout(() => {
// //       /** Confirm case */
// //       setSendInviteActionLoading(false);
// //       setSendInviteCompleted(true);
// //       /** Error case */
// //       // setSendInviteActionLoading(false);
// //       // setSendInviteError(true);
// //     }, 2000);

// //     // dispatch(updateCandidateInfoThunk(updatedProfileDataFormat))
// //     //   .unwrap()
// //     //   .then((res) => {
// //     //     setSendInviteActionLoading(false);
// //     //     setSendInviteCompleted(true);
// //     //   })
// //     //   .catch((err) => {
// //     //     setSendInviteActionLoading(false);
// //     //     setSendInviteError(true);

// //     //     console.error(err);
// //     //   });
// //   };

// //   const onEmailChange = (id, value) => {
// //     setConsultantEmail(value);
// //     validateEntries();
// //   };

// //   const onConsultantChange=(id,value)=>{

// //   }

// //   const validateEntries = () => {
// //     // Check if a consultant is selected
// //     const isConsultantSelected = selectedConsultant !== "";

// //     // Check if the email is valid using the EMAIL_PATTERN
// //     const validConsultantEmail = EMAIL_PATTERN.test(consultantEmail);

// //     // Enable the button only if both conditions are met
// //     setIsButtonDisabled(!(isConsultantSelected && validConsultantEmail));
// //   };

// //   return (
// //     <div style={{ padding: "48px" }}>
// //       <Typography
// //         variant="subtitle2"
// //         sx={{
// //           fontSize: "24px",
// //           color: "#0B0E1E",
// //           fontWeight: 600,
// //           marginBottom: "27px",
// //         }}
// //       >
// //         AddTeamMember
// //       </Typography>
// //       <Box sx={{ margin: "0px 15px" }}>
// //         <FormControl fullWidth className={classes.formControl}>
// //           <InputLabel
// //             id="demo-simple-select-label"
// //             className={classes.selectLabel}
// //           >
// //             Choose Consultant
// //           </InputLabel>
// //           <Select
// //             labelId="demo-simple-select-label"
// //             id="demo-simple-select"
// //             size="small"
// //             label="Choose Consultant"
// //             className={classes.selectRoot}
// //             value={selectedConsultant}
// //             onChange={(e) => {
// //                 setSelectedConsultant(e.target.value);
// //                 validateEntries();}}
// //           >
// //             {consultants.map((consultant, index) => (
// //               <MenuItem key={index} value={consultant.email}>
// //                 <div style={{ display: "flex" }}>
// //                   <Avatar
// //                     alt={consultant.name}
// //                     src={consultant.avatar}
// //                     sx={{ width: 20, height: 20 }}
// //                     variant="square"
// //                   />
// //                   <Typography
// //                     variant="subtitle2"
// //                     sx={{ fontSize: "12px", color: "black" }}
// //                   >
// //                     {consultant.name}
// //                   </Typography>
// //                   <Typography
// //                     variant="subtitle2"
// //                     sx={{ fontSize: "12px", color: "lightgray" }}
// //                   >
// //                     {consultant.email}
// //                   </Typography>
// //                 </div>
// //               </MenuItem>
// //             ))}
// //           </Select>
// //         </FormControl>
// //       </Box>
// //       <Typography
// //         variant="subtitle2"
// //         sx={{
// //           fontWeight: 600,
// //           fontSize: "24px",
// //           color: "#0B0E1E",
// //           margin: "33px 33px",
// //           marginLeft: "0px",
// //         }}
// //       >
// //         Manage Employee
// //       </Typography>
// //       <Box
// //         sx={{
// //           border: "1px solid lightgray",
// //           margin: "5px 15px",
// //           padding: "15px",
// //           backgroundColor: "#F4F8FF",
// //         }}
// //       >
// //         <Typography
// //           variant="subtitle2"
// //           sx={{ fontSize: "18px", color: "#1E5DFF", fontWeight: 600 }}
// //         >
// //           User Role
// //         </Typography>
// //         <Typography
// //           variant="subtitle2"
// //           sx={{ fontSize: "12px", color: "lightgray" }}
// //         >
// //           Select the role that applies to the duties of this employee within the
// //           Findpro App. This will dictate the parameters by which they can make
// //           changes in the company
// //         </Typography>
// //         <Box sx={{ margin: "5px 0px" }}>
// //           <CustomSelect data={consultantss} placeholder={"Recruiter"} />
// //         </Box>
// //       </Box>
// //       <Divider light sx={{ margin: "24px 13px 24px 13px" }} />
// //       <Typography
// //         variant="subtitle2"
// //         sx={{ fontWeight: 600, fontSize: "24px", color: "#0B0E1E" }}
// //       >
// //         Login Details
// //       </Typography>
// //       <Box sx={{ margin: "15px 15px" }}>
// //         {/* <FormControl variant="outlined" className={classes.alEmailFormControl}>
// //           <OutlinedInput
// //             id="outlined-adornment-password"
// //             className={classes.alEmailInput}
// //             endAdornment={
// //               <InputAdornment position="end">
// //                 <IconButton
// //                   aria-label="toggle password visibility"
// //                   //   onClick={handleClickShowPassword}
// //                   //   onMouseDown={handleMouseDownPassword}
// //                   edge="end"
// //                 >
// //                   <Typography
// //                     variant="subtitle2"
// //                     sx={{ fontSize: "12px", color: "#ABABAB" }}
// //                   >
// //                     PENDING INVITE
// //                   </Typography>
// //                   <CheckIcon />
// //                 </IconButton>
// //               </InputAdornment>
// //             }
// //             placeholder="eg:yourmail@mail.com"
// //           />
// //         </FormControl> */}

// //         <CustomTextfield
// //           id="email"
// //           type="email"
// //           label="EMAIL"
// //           placeholder="Add Email"
// //           value={consultantEmail}
// //           onChange={onEmailChange}
// //           onBlur={validateEntries}        />
// //         <Link
// //           href="#"
// //           variant="body2"
// //           sx={{ fontSize: "14px", marginTop: "12px" }}
// //         >
// //           Resend invite
// //         </Link>

// //       </Box>
// //       <button
// //         style={{
// //           border: "1px solid gray",
// //           padding: "4px",
// //           background: "black",
// //           color: "white",
// //           width: "100%",
// //         }}
// //         onClick={() => {
// //             onSendInviteClick();
// //           }}
// //         disabled={!isButtonDisabled} //check this later .Still need to implement the validations.
// //       >
// //         Invite Team Member
// //       </button>

// //       <SendInviteDialog
// //         sendInviteDialogOpen={sendInviteDialogOpen}
// //         setSendInviteDialogOpen={setSendInviteDialogOpen}
// //         sendInviteActionLoading={sendInviteActionLoading}
// //         sendInviteCompleted={sendInviteCompleted}
// //         sendInviteError={sendInviteError}
// //       />
// //     </div>
// //   );
// // };

// // export default AddTeamMembers;

// import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
// import { useSelector, useDispatch } from "react-redux";
// import { makeStyles } from "@mui/styles";
// import { useSnackbar } from "notistack";

// import { Link, Typography } from "@mui/material";

// import CustomTextfield from "app/v2/components/filters/CustomTextfield";
// import CustomButton from "app/v2/components/CustomButton";
// import DialogSuccessBody from "app/v2/Pages/common/DialogSuccessBody";
// import DialogErrorBody from "app/v2/Pages/common/DialogErrorBody";
// import DialogLoadingBody from "app/v2/Pages/common/DialogLoadingBody";
// import CustomDialog from "app/v2/components/CustomDialog";
// import CustomTooltip from "app/v2/components/CustomTooltip";
// import { Box } from "@mui/material";
// import CustomSelect from "app/v2/components/filters/CustomSelect";

// import { defaultSkillList, messages } from "app/v2/Pages/Utils/constants";
// import { isValidArray, isValidObject } from "app/v2/Pages/Utils/utilFunctions";
// import useFetch from "app/common/utils/useFetch";
// import { s3Upload } from "app/v2/services/miscService";
// import { EMAIL_PATTERN } from "app/v2/Pages/Utils/constants";
// import CloseIcon from "@mui/icons-material/Close";
// import { UpdateAgencyEmployeeDetailsThunk, reSendAgencyEmployeeInvitationThunk, sendAgnecyEmployeeInviteThunk } from "app/v2/store/features/agency/employee.thunk";
// import { getRolesThunk } from "app/v2/store/features/employer/employee.thunk";

// const employeeData = ["a", "b"];

// const useStyles = makeStyles((theme) => ({
//   sendInviteButton: {
//     width: "100%",
//     padding: "16px 28px",
//     display: "flex",
//     justifyContent: "center",
//     justifyItems: "center",
//     columnGap: "10px",
//     fontSize: "16px",
//     fontWeight: 600,
//   },
//   line: {
//     marginBottom: 0,
//   },
//   resendInviteText: {
//     fontSize: "14px",
//     fontWeight: 600,
//     color: `${theme.palette.primary.main} !important`,
//     marginTop: "12px",
//     cursor: "pointer",
//   },
//   dialogTitle: {
//     fontWeight: 600,
//     fontSize: "16px",
//     textAlign: "center",
//   },
// }));

// const SendInviteDialog = ({
//   sendInviteDialogOpen,
//   setSendInviteDialogOpen,
//   sendInviteActionLoading,
//   sendInviteCompleted,
//   sendInviteError,
// }) => {
//   const classes = useStyles();

//   const Title = () => {
//     return (
//       <Typography className={classes.dialogTitle}>
//         {sendInviteActionLoading && "Action in progress"}
//         {sendInviteError && "Error"}
//         {sendInviteCompleted && "Changes Saved"}
//       </Typography>
//     );
//   };

//   const DialogBody = () => {
//     if (sendInviteActionLoading) return <DialogLoadingBody />;
//     else if (sendInviteCompleted)
//       return (
//         <DialogSuccessBody
//           content={{ title: "Your changes have been saved." }}
//         />
//       );
//     else if (sendInviteError)
//       return (
//         <DialogErrorBody
//           content={{ title: messages.GENERIC_ERROR_MESSAGE, desc: "" }}
//         />
//       );
//     else return <></>;
//   };

//   return (
//     <CustomDialog
//       open={sendInviteDialogOpen}
//       setOpen={setSendInviteDialogOpen}
//       noCloseAction={sendInviteActionLoading}
//       title={<Title />}
//       content={<DialogBody />}
//     />
//   );
// };

// const AddEmployee = ({ selectedEmployee }) => {

//   console.log("selectedEmployee",selectedEmployee)
//   const classes = useStyles();
//   const dispatch = useDispatch();
//   const [get] = useFetch();
//   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
//   const {
//     experiences,
//     contractTypes: contractTypesReceived,
//     skills,
//     industryData,
//   } = useSelector((state) => state.misc);

//   // console.log("iamchecking", selectedEmployee);
//   // const [consultantData, setConsultantData] = useState(

//   //   {

//   //   overview: {
//   //     first_name: "",
//   //     last_name: "",
//   //     jobTitle: "",
//   //     displayPicture: "",
//   //     salaryDetails: {},
//   //     experienceLevel: 0, // experienceLevel index as per experienceLevel data(we fetch that data below).
//   //     availability: 0, // contractType index as per contractTypes data(we fetch that data below).
//   //     country: "",
//   //     city: "",
//   //     state: "",
//   //     timezone: "",
//   //     summary: "",
//   //   },
//   //   socialLinks: {
//   //     website: "",
//   //     facebook: "",
//   //     linkedin: "",
//   //   },
//   //   skills: defaultSkillList,
//   //   workHistory: [],
//   //   education: [],
//   //   certification: [],
//   //   languages: [],
//   //   awards: [],
//   //   testimonials: [],
//   //   resumeDoc: "",
//   //   videoResume: "",
//   //   userRole:""
//   // });
//   const orgIdOrLoginId = localStorage.getItem("orgIdOrLoginId");

//   const [consultantData, setConsultantData] = useState( {
//     email: "",
//     firstName: "",
//     lastName: "",
//     orgId: orgIdOrLoginId,
//     timezone: "",
//     country: "",
//     state: "",
//     city: "",
//     phoneNumber: "",
//     roleId: "",
//     companyTitle: "",
//   });

//   const [experienceTypes, setExperienceTypes] = useState([]);
//   const [contractTypes, setContractTypes] = useState([]);
//   const [industries, setIndustries] = useState([]);
//   const [skillIDToNameMapper, setSkillIDToNameMapper] = useState();
//   // {
//   //   'id':'name'
//   // }
//   const [activateSendInvite, setActivateSendInvite] = useState(true);
//   const [sendInviteDialogOpen, setSendInviteDialogOpen] = useState(false);
//   const [sendInviteActionLoading, setSendInviteActionLoading] = useState(false);
//   const [sendInviteCompleted, setSendInviteCompleted] = useState(false);
//   const [sendInviteError, setSendInviteError] = useState(false);
//   const [employeeRoles, setEmployeeRoles] = useState([]);


//   const onOverviewEntryChange = (id, value) => {
//     setConsultantData((prevProfileData) => {
//       let updatedProfileData = {
//         ...prevProfileData,
//         [id]: value,
//       };
//       return updatedProfileData;
//     });
//   };

//   const onOtherInfoChange = (id, value) => {
//     setConsultantData((prevProfileData) => {
//       let updatedProfileData = { ...prevProfileData };
//       updatedProfileData[id] = value;

//       return updatedProfileData;
//     });
//   };

//   const onSocialLinksEntryChange = (id, value) => {
//     setConsultantData((prevProfileData) => {
//       let updatedProfileData = { ...prevProfileData };
//       let socialLinksData = { ...updatedProfileData?.socialLinks };
//       socialLinksData[id] = value;
//       updatedProfileData.socialLinks = socialLinksData;
//       return updatedProfileData;
//     });
//   };

//   const onFileUploadToS3 = async (file, fileId) => {
//     let s3UploadedFileDetails;
//     const formData = new FormData();
//     formData.append("file", file);
//     try {
//       s3UploadedFileDetails = await get(s3Upload(formData));
//     } catch (err) {
//       enqueueSnackbar(
//         `Problem in uploading the ${fileId},please try again later!`,
//         { variant: "error" }
//       );
//     }
//     return s3UploadedFileDetails?.[0]?.location;
//   };

//   // const handleDialoguesAndApi2 = () => {
//   //   handleDialoguesAndApi();
//   // };

//   const onSendInviteClick = async (constData) => {
//     setSendInviteError(false);
//     //// console.log("consultantData--", consultantData)
//     let displayPictureUrl, resumeDocUrl, videoResumeUrl;

//     // the below 3 elements can be the url or an object(if upload of any of those were attempted, structure- {file,localFileUrl,prevFileUrl}).
//     const displayPictureUrlOrDetails = consultantData?.overview?.displayPicture;
//     const resumeDocUrlOrDetails = consultantData?.resumeDoc;
//     const videoResumeUrlOrDetails = consultantData?.videoResume;

//     const displayPictureFile = displayPictureUrlOrDetails?.file;
//     const resumeDocFile = resumeDocUrlOrDetails?.file;
//     const videoResumeFile = videoResumeUrlOrDetails?.file;

//     setSendInviteDialogOpen(true);
//     setSendInviteActionLoading(true);

//     // If at all upload was attempted for them we try to access the file part of the object(done above) and upload it to s3 and get the updated url of its location in s3 on successful upload.If s3 upload fails we we show the message in snackbar and update the prev file itself in the profileData.
//     //  Else i.e, upload was not attempted, file value wont exist and it will be the url instead(prev/current url).

//     const updatedConsultantData = { ...consultantData };

//     setTimeout(() => {
//       dispatch(sendAgnecyEmployeeInviteThunk(constData || consultantData))
//         .unwrap()
//         .then((res) => {
//           if (res) {
//             if (res?.message) {
//               // enqueueSnackbar(res.message, { variant: "success" });
//               const key = enqueueSnackbar(res.message, {
//                 variant: "success",
//                 autoHideDuration: 3000,
//                 action: (key) => (
//                   <CloseIcon
//                     onClick={() => closeSnackbar(key)}
//                     style={{
//                       cursor: "pointer",
//                       fontSize: "15",
//                       marginTop: "-1px",
//                     }}
//                   />
//                 ),
//               });
//             }
//             setSendInviteActionLoading(false);
//             // setSendInviteCompleted(true);
//             setSendInviteDialogOpen(false);
//             // handleDialoguesAndApi2();
//           }
//         })
//         .catch((err) => {
//           setSendInviteActionLoading(false);
//           setSendInviteError(true);
//           // enqueueSnackbar(err.message, { variant: "error" });
//           const key = enqueueSnackbar(err.message, {
//             variant: "error",
//             autoHideDuration: 3000,
//             action: (key) => (
//               <CloseIcon
//                 onClick={() => closeSnackbar(key)}
//                 style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
//               />
//             ),
//           });

//           console.error(err);
//         });

//       /** Confirm case */
//       // setSendInviteActionLoading(false);
//       // setSendInviteCompleted(true);
//       /** Error case */
//       // setSendInviteActionLoading(false);
//       // setSendInviteError(true);
//     }, 1000);
//   };
//   const onresendInviteClick = async (constData) => {
//     setSendInviteError(false);
//     //// console.log("consultantData--", consultantData)
//     let displayPictureUrl, resumeDocUrl, videoResumeUrl;

//     setSendInviteDialogOpen(true);
//     setSendInviteActionLoading(true);

//     // If at all upload was attempted for them we try to access the file part of the object(done above) and upload it to s3 and get the updated url of its location in s3 on successful upload.If s3 upload fails we we show the message in snackbar and update the prev file itself in the profileData.
//     //  Else i.e, upload was not attempted, file value wont exist and it will be the url instead(prev/current url).

//     setTimeout(() => {
//       dispatch(reSendAgencyEmployeeInvitationThunk(constData || consultantData))
//         .unwrap()
//         .then((res) => {
//           if (res) {
//             if (res?.message) {
//               // enqueueSnackbar(res.message, { variant: "success" });
//               const key = enqueueSnackbar(res.message, {
//                 variant: "success",
//                 autoHideDuration: 3000,
//                 action: (key) => (
//                   <CloseIcon
//                     onClick={() => closeSnackbar(key)}
//                     style={{
//                       cursor: "pointer",
//                       fontSize: "15",
//                       marginTop: "-1px",
//                     }}
//                   />
//                 ),
//               });
//             }
//             setSendInviteActionLoading(false);
//             // setSendInviteCompleted(true);
//             setSendInviteDialogOpen(false);
//             // handleDialoguesAndApi2();
//           }
//         })
//         .catch((err) => {
//           setSendInviteActionLoading(false);
//           setSendInviteError(true);
//           // enqueueSnackbar(err.message, { variant: "error" });
//           const key = enqueueSnackbar(err.message, {
//             variant: "error",
//             autoHideDuration: 3000,
//             action: (key) => (
//               <CloseIcon
//                 onClick={() => closeSnackbar(key)}
//                 style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
//               />
//             ),
//           });

//           console.error(err);
//         });

//       /** Confirm case */
//       // setSendInviteActionLoading(false);
//       // setSendInviteCompleted(true);
//       /** Error case */
//       // setSendInviteActionLoading(false);
//       // setSendInviteError(true);
//     }, 1000);
//   };

//   const handleOnUpdate = () => {
//     setSendInviteDialogOpen(true);
//     setSendInviteActionLoading(true);
//     const request = {
//       id: selectedEmployee._id,
//       // roleId: employeeRoles?.find(
//       //   (emp) => emp?.roleType === selectedEmployeeRole
//       // )?._id,
//     };

//     setTimeout(() => {
//       dispatch(UpdateAgencyEmployeeDetailsThunk(request))
//         .unwrap()
//         .then((res) => {
//           if (res && res?.message) {
//             // enqueueSnackbar(res.message, { variant: "success" });
//             const key = enqueueSnackbar(res.message, {
//               variant: "success",
//               autoHideDuration: 3000,
//               action: (key) => (
//                 <CloseIcon
//                   onClick={() => closeSnackbar(key)}
//                   style={{
//                     cursor: "pointer",
//                     fontSize: "15",
//                     marginTop: "-1px",
//                   }}
//                 />
//               ),
//             });

//             setSendInviteActionLoading(false);
//             // setSendInviteCompleted(true);
//             setSendInviteDialogOpen(false);

//             // handleDialoguesAndApi2();
//           }
//         })
//         .catch((err) => {
//           setSendInviteActionLoading(false);
//           setSendInviteError(true);
//           // enqueueSnackbar(err.message, { variant: "error" });
//           const key = enqueueSnackbar(err.message, {
//             variant: "error",
//             autoHideDuration: 3000,
//             action: (key) => (
//               <CloseIcon
//                 onClick={() => closeSnackbar(key)}
//                 style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
//               />
//             ),
//           });
//         });

//       /** Confirm case */
//       // setSendInviteActionLoading(false);
//       // setSendInviteCompleted(true);
//       /** Error case */
//       // setSendInviteActionLoading(false);
//       // setSendInviteError(true);
//     }, 1000);
//   };

//   const validateEntries = () => {
//     // no validation for experience level, availability
//     // const overviewData = consultantData?.overview;
//     const overviewData = consultantData;

//     const useRoleData = consultantData?.roleId;
//     // const isValidFirstName = overviewData?.firstName?.length > 0;
//     const isValidFirstName = consultantData?.firstName?.length > 0;
//     // const isValidLastName = overviewData?.lastName?.length > 0;
//     const isValidLastName = consultantData?.lastName?.length > 0;

//     const isUserRoleData = useRoleData?.length > 0;
//     const countrySelected = Boolean(overviewData?.country);
//     const stateSelected = Boolean(overviewData?.state);
//     const cityEntered = overviewData?.city?.length > 0;
//     const timezoneSelected = Boolean(overviewData?.timezone);

//     const validOverviewDetails =
//       // isValidFirstName && isValidLastName && isUserRoleData;
//       // countrySelected &&
//       // stateSelected &&
//       // cityEntered &&
//       //   isUserRoleData &&
//       // timezoneSelected
//       (selectedEmployee || (isValidFirstName && isValidLastName)) 
//       // &&
//       // isUserRoleData;

//     // const validConsultantEmail = EMAIL_PATTERN.test(consultantEmail);
//     const validConsultantEmail = EMAIL_PATTERN.test(consultantData.email);

//     console.log("validOverviewDetails",validOverviewDetails)

//     // // console.log({
//     //   validOverviewDetails,
//     //   skillsSelected,
//     //   workExperienceAdded,
//     //   educationAdded,
//     //   resumeAvailable,
//     //   validConsultantEmail,
//     // });
//     setActivateSendInvite(
//       selectedEmployee
//         ? validOverviewDetails
//         : validOverviewDetails && validConsultantEmail
//     );
//   };
//   const userOptions = [
//     {
//       name: "John Doe",
//       email: "john@example.com",
//       userRole: "Admin",
//     },
//     {
//       name: "Jane Smith",
//       email: "jane@example.com",
//       userRole: "Manager",
//     },
//     {
//       name: "Robert Johnson",
//       email: "robert@example.com",
//       userRole: "Employee",
//     },
//     {
//       name: "Sarah Brown",
//       email: "sarah@example.com",
//       userRole: "Supervisor",
//     },
//     {
//       name: "David Wilson",
//       email: "david@example.com",
//       userRole: "Team Lead",
//     },
//     {
//       name: "Michael Lee",
//       email: "michael@example.com",
//       userRole: "Consultant",
//     },
//   ];
//   // const onEmailChange = (id, value) => {
//   //   setConsultantEmail(value);
//   // };


//   // useEffect(() => {
//   //   dispatch(getRolesThunk())
//   //     .unwrap()
//   //     .then((res) => {
//   //       if (res && res.status === true) {
//   //         setEmployeeRoles(res?.data);
//   //       }
//   //     })
//   //     .catch((err) => {
//   //       if (err.message) {
//   //         // enqueueSnackbar(err.message, { variant: "error" });
//   //         const key = enqueueSnackbar(err.message, {
//   //           variant: "error",
//   //           autoHideDuration: 3000,
//   //           action: (key) => (
//   //             <CloseIcon
//   //               onClick={() => closeSnackbar(key)}
//   //               style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
//   //             />
//   //           ),
//   //         });
//   //       } else {
//   //         // enqueueSnackbar("Problem in fetching data!", { variant: "error" });

//   //         const key = enqueueSnackbar("Problem in fetching data!", {
//   //           variant: "error",
//   //           autoHideDuration: 3000,
//   //           action: (key) => (
//   //             <CloseIcon
//   //               onClick={() => closeSnackbar(key)}
//   //               style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
//   //             />
//   //           ),
//   //         });
//   //       }
//   //       console.error(err);
//   //     });
//   // }, [dispatch]);

//   useEffect(() => {
//     // console.log({ consultantData });
//     validateEntries();
//   }, [consultantData]);

//   console.log("consultantDate",consultantData)


//   return (
//     <div className="p-[48px] flex flex-col gap-y-[24px]">
//       <div className="flex gap-x-[24px]">
//           <div className="flex-1">

//             <CustomTextfield
//               id="firstName"
//               label="First Name"
//               placeholder="Kyla"
//               value={
//                 selectedEmployee
//                   ? selectedEmployee?.firstName
//                   : consultantData?.firstName
//               }
//               onChange={onOverviewEntryChange}
//               showMandatorySymbol={true}
//               disabled={!!selectedEmployee} 
//             />
//           </div>
//           <div className="flex-1">
//             <CustomTextfield
//               // id="lastName"
//               id="lastName"
//               label="Last Name"
//               placeholder="Andrews"
//               value={selectedEmployee? selectedEmployee?.lastName:consultantData?.lastName}
//               onChange={onOverviewEntryChange}
//               showMandatorySymbol={true}
//               disabled={!!selectedEmployee} 

//             />
//           </div>
//         </div>
//       <Box
//         sx={{
//           border: "1px solid lightgray",
//           margin: "5px 15px",
//           padding: "15px",
//           backgroundColor: "#F4F8FF",
//         }}
//       >
//         <Typography
//           variant="subtitle2"
//           sx={{ fontSize: "14px", color: "#1E5DFF" }}
//         >
//           User Role
//         </Typography>
//         <Typography
//           variant="subtitle2"
//           sx={{ fontSize: "12px", color: "lightgray" }}
//         >
//           Select the role that applies to the duties of this employee within the
//           Findpro App. This will dictate the parameters by which they can make
//           changes in the company
//         </Typography>
//         <Box sx={{ margin: "5px 0px" }}>
//           <CustomSelect
//             id="roleType"
//             name="roleType"
//             data={userOptions.map((user) => user?.userRole)}
//             // Replace with your user role data
//             placeholder=" Role type"
//             onChange={(id, value) =>
//               setConsultantData((prevData) => ({
//                 ...prevData,
//                 consultantUserRole: value,
//               }))
//             }
//           />
//         </Box>
//       </Box>

//       <div className="text-[24px] font-semibold">Invitation Email</div>
//       <div className="px-[16px]">
//         <CustomTextfield
//           id="email"
//           type="email"
//           label="email"
//           placeholder="Add Email"
//           // value={consultantEmail}
//           // onChange={onEmailChange}
//           value={
//             selectedEmployee ? selectedEmployee?.email : consultantData?.email
//           }
//           onChange={(email, val) => {
//             setConsultantData({
//               ...consultantData,
//               ["email"]: val,
//             });
//           }}
//           disabled={!!selectedEmployee}
//         />
//         <Link
//           className={classes.resendInviteText}
//           onClick={() => {
//             // setConsultantData({
//             //   ...consultantData,
//             //   "email": selectedEmployee ? selectedEmployee.email : consultantData.email,
//             // });
//             onresendInviteClick({
//               ...consultantData,
//               email: selectedEmployee
//                 ? selectedEmployee?.email
//                 : consultantData?.email,
//               id: selectedEmployee?._id,
//             });
//           }}
//         >
//           Resend Invite
//         </Link>
//       </div>
//       <CustomTooltip
//         title={!activateSendInvite && "Please enter the mandatory details."}
//       >
//         <div>
//           <CustomButton
//             type="button1"
//             customClasses={classes.sendInviteButton}
//             disabled={!activateSendInvite}
//             onClick={() => {
//               if (selectedEmployee) {
//                 handleOnUpdate();
//               } else {
//                 onSendInviteClick();
//               }
//             }}
//           >
//             {selectedEmployee ? "Update" : "Send Invite"}{" "}
//             <img src="/iconfilledsend.svg" />
//           </CustomButton>
//         </div>
//       </CustomTooltip>
//       <SendInviteDialog
//         sendInviteDialogOpen={sendInviteDialogOpen}
//         setSendInviteDialogOpen={setSendInviteDialogOpen}
//         sendInviteActionLoading={sendInviteActionLoading}
//         sendInviteCompleted={sendInviteCompleted}
//         sendInviteError={sendInviteError}
//       />
//     </div>
//   );
// };

// AddEmployee.propTypes = {};

// export default AddEmployee;
