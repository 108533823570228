import React, { useContext, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Typography, Drawer, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { UserContext } from "../index";
import { makeStyles } from "@mui/styles";
import Filters from "./filters/Filters";
import SideMenu from "./SideMenu";
import ClientConsultantContract from "../Pages/Employer/searchresultsandfeed/ConsultantContract";
import ConsultantActiveWorkContract from "../Pages/Consultant/searchresultsandfeed/activework/ConsultantContract";
import AgencyJobContract from "../Pages/Agency/MyJobs/JobConsultantContract";
import Notification from "../Pages/common/Notification/Notification";
import { getAccountType } from "../utils/loginUtil";

const useStyles = makeStyles((theme) => ({
  drawerRoot: {
    "& .MuiDrawer-paper": {
      width: "fit-content",
      minWidth: "500px",
      [theme.breakpoints.down('sm')]: {
        // minWidth: "90%"
        minWidth: "380px"
      }
    },
  },
  headingDiv: {
    width: "100%",
    height: "60px",
    padding: "16px",
    borderBottom: "1px solid #E0E0E0",
    fontSize: "16px",
    fontWeight: 600,
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    fontWeight: 600,
  },
  closeIcon: {
    cursor: "pointer",
  },
}));

const CustomDrawer = (props) => {
  const classes = useStyles();
  const [drawerDetails, setDrawerDetails] = useContext(UserContext);
  const [heading, setHeading] = useState();
  const [searchClose, SetsearchClose] = useState(true);
  const accountType = getAccountType();

  const onDrawerClose = useCallback(() => {
    setDrawerDetails({
      showDrawer: false,
      componentType: "",
      showHeading: true,
    });
  },[setDrawerDetails]);

  const componentHeadings = {
    filter: accountType === "client" ? "Search Consultants" : "Filter & Sort",
    sidemenu: "Profile",
    clientConsultantContract: "Contract",
    consultantActiveWorkContract: "Contract",
    agencyJobContract: "Contract",
    notification: "Notifications",
  };

  const Heading = () => {
    return (
      <Grid container className={classes.headingDiv}>
        <Grid item xs={10} sm={11} className={classes.gridItem}>
          <Typography variant="body2" className={classes.heading}>
            {/* {searchClose || (drawerDetails?.showHeading && heading)} */}
            {drawerDetails?.showHeading && heading}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={1} className={classes.gridItem}>
          <button
            onClick={onDrawerClose}
            className="bg-[transparent] relative w-[30px] h-[30px]"
          >
            <img
              className="absolute top-[0px] left-[-4px] w-[30px] h-[30px] overflow-hidden"
              alt=""
              src="/iconlightdropdown-right.svg"
            />
            <img
              className="absolute top-[0px] left-[4px] w-[30px] h-[30px] overflow-hidden"
              alt=""
              src="/iconlightdropdown-right.svg"
            />
          </button>
        </Grid>
      </Grid>
    );
  };

  const getComponent = (componentType) => {
    let Component;
    switch (componentType) {
      case "filter":
        Component = Filters;
        break;
      case "sidemenu":
        Component = SideMenu;
        break;
      case "clientConsultantContract":
        Component = ClientConsultantContract;
        break;
      case "consultantActiveWorkContract":
        Component = ConsultantActiveWorkContract;
        break;
      case "agencyJobContract":
        Component = AgencyJobContract;
        break;
      case "notification":
        Component = Notification;
        break;
      default:
      // code block
    }
    if (Component)
      Component = (
        <Component
          drawerDetails={drawerDetails}
          setDrawerDetails={setDrawerDetails}
        />
      );
    return Component;
  };

  useEffect(() => {
    // // console.log({ drawerDetails });
    if (drawerDetails?.showDrawer) {
      const componentHeading =
        componentHeadings[drawerDetails?.componentType] ?? "-";
      setHeading(componentHeading);
    }
  }, [drawerDetails]);

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={drawerDetails?.showDrawer}
        onClose={onDrawerClose}
        className={classes.drawerRoot}
      >
        {heading && <Heading />}
        {getComponent(drawerDetails?.componentType)}
      </Drawer>
    </div>
  );
};

CustomDrawer.propTypes = {};

export default CustomDrawer;
