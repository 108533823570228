import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import useFetch from "app/common/utils/useFetch";
import { s3Upload } from "app/v2/services/miscService";

import { Button } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import CustomLabel from "app/v2/components/filters/CustomLabel";
import CustomSelect from "app/v2/components/filters/CustomSelect";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import TinyMceEditor from "app/v2/Pages/common/Editor/TinyMceEditor";
import CustomButton from "app/v2/components/CustomButton";
import CustomTooltip from "app/v2/components/CustomTooltip";
import CustomDialog from "app/v2/components/CustomDialog";
import DialogSuccessBody from "app/v2/Pages/common/DialogSuccessBody";
import DialogErrorBody from "app/v2/Pages/common/DialogErrorBody";
import DialogLoadingBody from "app/v2/Pages/common/DialogLoadingBody";

import {
  messages,
  validDocFileMimeTypes,
  validPPTMimeTypes,
} from "app/v2/Pages/Utils/constants";
import { isValidObject, isValidString } from "app/v2/Pages/Utils/utilFunctions";
import FilePreview from "app/v2/Pages/common/FilePreview";
import { applyJob } from "app/v2/services/consultant/myJobs.service";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  projectDetailDiv: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  projectDetailValue: {
    fontSize: "18px",
    fontWeight: 700,
  },
  actionButton: {
    padding: "16px 28px",
    fontSize: "16px",
    fontWeight: 600,
    width: "100%",
    display: "flex",
    columnGap: "10px",
    alignItems: "center",
  },
  cancelButton: {
    border: "1px solid #EDEDED",
  },
  uploadFileDiv: {
    width: "100%",
    height: "101px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
  uploadFileButton: {
    padding: "6px 8px",
    border: "1px solid #EDEDED",
    fontSize: "12px",
    color: "#717171 !important",
    fontWeight: 400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "8px",
  },
  deleteButton: {
    padding: "10px",
    border: "1px solid #EDEDED",
  },
  infoIcon: {
    width: "20px",
    height: "20px",
    position: "relative",
    left: "4px",
    bottom: "2px",
  },
  orgImage: {
    width: "120px",
    height: "120px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
}));

const dummyConsultantData = [
  {
    id: 1,
    displayPic: "/user_image.png",
    name: "Lacy Clark 1",
    email: "lacy.clark@gmail.com",
  },
  {
    id: 2,
    displayPic: "/user_image.png",
    name: "Lacy Clark 2",
    email: "lacy.clark@gmail.com",
  },
  {
    id: 3,
    displayPic: "/user_image.png",
    name: "Lacy Clark 3",
    email: "lacy.clark@gmail.com",
  },
];

const SubmitActionDialog = ({
  submitDialogOpen,
  setSubmitDialogOpen,
  submitActionLoading,
  submitActionCompleted,
  submitActionError,
  customSuccessMessage,
  responseErroeMessage
}) => {

  console.log("soemthing",responseErroeMessage)
  const Title = () => {
    return (
      <div className="text-[16px] font-semibold text-center">
        {submitActionLoading && "Action in progress"}
        {submitActionError && "Error"}
        {submitActionCompleted && "Job Applied"}
      </div>
    );
  };

  const DialogBody = () => {
    if (submitActionLoading) return <DialogLoadingBody />;
    else if (submitActionCompleted)
      return (
        <DialogSuccessBody
          content={{
            title:
              customSuccessMessage ??
              "You have successfully applied for the job.",
          }}
        />
      );
    else if (submitActionError)
      return (
        <DialogErrorBody
          content={{ title: responseErroeMessage?? messages.GENERIC_ERROR_MESSAGE }}
        />
      );
    else return <></>;
  };

  return (
    <CustomDialog
      open={submitDialogOpen}
      setOpen={setSubmitDialogOpen}
      noCloseAction={submitActionLoading}
      title={<Title />}
      content={<DialogBody />}
    />
  );
};

/**
 * @component SubmitProposal- Content/Body-(contains the actions as well) of the submit/resubmit proposal dialog.
 * @param {object} jobData - data of the job for which we submit proposals(consultants and their bid/expected salary value).
 * @param {array} proposalData - data of the proposal for which a resubmit is attempted. In this case we show the elements with prefilled proposal data, else in a fresh submission all the elements are empty and to be filled.
 * Structure: 
 * {                
        coverLetter: 
          "<p>s simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>",        ,
        attachment:
          "https://findpro-s3.s3.us-east-2.amazonaws.com/Metkel%20Isak%20Resume_1656535496051.docx", // on file upload this key's structure will be {file:object,localFileUrl:String}.
        salaryType: "Hourly",
        salaryValue: 3000,       
        status: "pending",
  * },  
  * @param {function} onSuccess - function to be called when the submit/resubmit proposal was successful.
 * @param {function} closeParentDialog - Function to close the parent dialog(dialog in which this component is shown). 
 */

const SubmitProposal = ({
  jobData,
  proposalData,
  onSuccess,
  closeParentDialog,
  customSuccessMessage = null,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [get] = useFetch();

  const defaultProposalEntry = {
    coverLetter: { htmlString: "" },
    attachment: "", // on file upload this key's structure will be {file:object,localFileUrl:String}.
    salaryType: "Hourly",
    salaryValue: 0.0,
  };

  const fileRef = React.createRef();
  const salaryTypes = ["Hourly", "Monthly", "Annually"];
  const [proposalDetails, setProposalDetails] = useState({
    coverLetter: "",
    attachment: "", // on file upload this key's structure will be {file:object,localFileUrl:String}.
    salaryType: "Hourly",
    // salaryValue: 0,
  }); // Storing default values initially.
  const [activateSubmitButton, setActivateSubmitButton] = useState(false);
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false);
  const [submitActionLoading, setSubmitActionLoading] = useState(false);
  const [submitActionCompleted, setSubmitActionCompleted] = useState(false);
  const [submitActionError, setSubmitActionError] = useState(false);
  const[responseErroeMessage,setResponseErorMessage] =useState("")
const[validateCoverAndAttachement,setValidateCoverAndAttachement]=useState(false)
  const onProposalDetailsChange = (infoType, value) => {
    setProposalDetails((prevState) => {
      let updatedState = { ...prevState };
      updatedState[`${infoType}`] = value;
      return updatedState;
    });
  };

  const onFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const localFileUrl = URL.createObjectURL(file);
      const higherFileSize = file.size > 1048576 * 5; // 5 mb
      const isValidDoc = validDocFileMimeTypes.includes(file.type);
      const isValidPPT = validPPTMimeTypes.includes(file.type);
      console.log({
        higherFileSize,
        isValidDoc,
        isValidPPT,
        val: higherFileSize || (!isValidDoc && !isValidPPT),
      });
      if (higherFileSize || (!isValidDoc && !isValidPPT)) {
        // enqueueSnackbar(
        // higherFileSize
        //   ? messages.DOC_OR_VIDEO_FILE_SIZE_WARNING_OR_INFO
        //   : messages.UPLOAD_VALID_DOC_WARNING,
        // {
        //   variant: "error",
        // }
        // );

        const key = enqueueSnackbar(
          higherFileSize
            ? messages.DOC_OR_VIDEO_FILE_SIZE_WARNING_OR_INFO
            : messages.UPLOAD_VALID_DOC_WARNING,
          {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
        return;
      }
      onProposalDetailsChange("attachment", {
        file,
        localFileUrl,
      });
    }
  };

  const onFileUploadToS3 = async (file) => {
    let s3UploadedFileDetails;
    let errorInUpload = false;
    const formData = new FormData();
    formData.append("file", file);
    try {
      s3UploadedFileDetails = await get(s3Upload(formData));
    } catch (err) {
      errorInUpload = true;
    }

    return { uploadedUrl: s3UploadedFileDetails?.[0]?.location, errorInUpload };
  };



  const validateEntries = () => {
    const validCoverLetter = proposalDetails?.coverLetter?.text?.length > 100;
    const validAttachment =
      proposalDetails?.attachment?.localFileUrl?.length > 0 ||
      proposalDetails?.attachment?.length > 0;
    const validSalaryValue = proposalDetails?.salaryValue > 0;
    setValidateCoverAndAttachement(validCoverLetter || validAttachment)
    const validProposalDetails =
     ( validCoverLetter || validAttachment) && validSalaryValue;
    setActivateSubmitButton(validProposalDetails);
  };

  const onSubmitProposalClick = async () => {
    setSubmitDialogOpen(true);
    setSubmitActionLoading(true);

    let attachmentUrlInfo, attachmentUrl;
    // the below element can be the url or an object(if attachment upload  were attempted, structure- {file,localFileUrl,prevFileUrl}).
    const attachmentUrlOrDetails = proposalDetails?.attachment;
    const attachmentFile = attachmentUrlOrDetails?.file;

    // If at all upload was attempted for attachment we try to access the file part of the object(done above) and upload it to s3 and get the updated url of its location in s3 on successful upload.If s3 upload fails we show the message in the dialog and update the prev file itself in the profileData.
    //  Else i.e, upload was not attempted, file value wont exist and it will be the url instead(prev/current url).

    if (attachmentFile) {
      attachmentUrlInfo = await onFileUploadToS3(attachmentFile);
      attachmentUrl = attachmentUrlInfo?.errorInUpload
        ? attachmentUrlOrDetails?.prevFileUrl
        : attachmentUrlInfo?.uploadedUrl;
    } else attachmentUrl = attachmentUrlOrDetails;

    const proposalDetailsForApi = {};
    proposalDetailsForApi.jobId = jobData?._id;
    proposalDetailsForApi.attachment = attachmentUrl;
    proposalDetailsForApi.coverLetter = proposalDetails.coverLetter.htmlString;
    proposalDetailsForApi.salaryType = proposalDetails.salaryType;
    proposalDetailsForApi.salaryValue = proposalDetails.salaryValue;

    if (jobData?.appliedOrInvited === "invited")
      proposalDetailsForApi.status = "accepted";

    // setTimeout(() => {
    //   /** Confirm case */
    //   setSubmitActionLoading(false);
    //   setSubmitActionCompleted(true);
    //   /** Error case */
    //   // setSubmitActionLoading(false);
    //   // setSubmitActionError(true);
    //   // setSubmitActionCompleted(false);
    // }, 2000);

    applyJob(proposalDetailsForApi)
      .then((response) => {
        setProposalDetails({ ...proposalDetailsForApi });
        setSubmitActionLoading(false);
        setSubmitActionCompleted(true);
      })
      .catch((err) => {
        setSubmitActionLoading(false);
        setSubmitActionError(true);
        setResponseErorMessage(err?.message)
        setSubmitActionCompleted(false);
      });
  };

  useEffect(() => {
    console.log({ proposalDetails });
    validateEntries();
  }, [proposalDetails]);

  useEffect(() => {
    if (!submitDialogOpen && (submitActionCompleted || submitActionError)) {
      if (submitActionCompleted) onSuccess({ ...proposalDetails });
      closeParentDialog(); // close the parent submit proposal dialog after the SubmitActionDialog is closed only after the action is done-completed/error case.
    }
  }, [submitDialogOpen]);

  useEffect(() => {
    if (proposalData) {
      setProposalDetails(proposalData);
    }
  }, [proposalData]);

  useEffect(() => {
    console.log({ jobData });
  }, [jobData]);

  return (
    <>
      <div className="flex flex-col gap-y-[33px] px-[48px] pb-[48px]">
        {/* Job Overview - start */}
        <div className="text-[24px] font-semibold pt-[30px]">Job Overview</div>
        <div className="flex gap-x-[24px]">
          <img
            className={classes.orgImage}
            alt={`"org_${jobData?._id}_image"`}
            src={jobData?.image ? jobData.image : "/org_placeholder.svg"}
          />
          <div>
            <div className="text-[36px] font-semibold mb-[12px]">
              {jobData?.jobTitle}
            </div>
            <div>
              <span className="mr-[36px] text-[14px] text-[#8C8C8C] font-semibold">
                Job no.
                <span className="text-[#363636] ml-[8px]">
                  {jobData?.jobNo ?? "-"}
                </span>
              </span>
              <span className="text-[14px] text-[#8C8C8C] font-semibold">
                Hiring Manager
                <span className="text-[#363636] ml-[8px]">
                  {jobData?.hiringManager ?? "-"}
                </span>
              </span>
            </div>
          </div>
        </div>
        {/* Job Overview - end */}
        {/* Proposal Details - start */}
        <div className={clsx(["flex flex-col gap-y-[33px] "])}>
          <div className="flex flex-col gap-y-[33px]">
            <div className="pl-[24px]">
              <div className="text-[24px] font-semibold mb-[32px]">
                Additional Details
              </div>

              <div className="mb-[33px]">
                <CustomLabel label="cover letter" />
                <TinyMceEditor
                  id="coverLetter"
                  data={proposalDetails?.coverLetter}
                  setData={onProposalDetailsChange}
                  placeholder=""
                  requiredCharCount={100}
                  insufficientCharCountMessage={validateCoverAndAttachement?"":"Cover letter should have atleast 100 characters."}
                />
              </div>

              <div>
                <div className="flex">
                  <CustomLabel label="attachment" />
                  <CustomTooltip
                    title={
                      <>
                        <div>
                          1. {messages.DOC_OR_VIDEO_FILE_SIZE_WARNING_OR_INFO}
                        </div>
                        <div>2. {messages.DOC_FILE_UPLOAD_EXTENSION_INFO}</div>
                      </>
                    }
                    arrow
                    placement="top"
                  >
                    <InfoIcon className={classes.infoIcon} />
                  </CustomTooltip>
                </div>
                <div className={classes.uploadFileDiv}>
                  <input
                    type="file"
                    accept=".doc, .docx, .pdf, .txt, .ppt, .pptx"
                    className="hidden"
                    onChange={(event) => {
                      onFileUpload(event);
                    }}
                    ref={fileRef}
                  />
                  <CustomButton
                    type="button2"
                    customClasses={classes.uploadFileButton}
                    onClick={() => fileRef.current.click()}
                  >
                    Upload file <img src="/download.svg" />
                  </CustomButton>
                </div>
                <div className="mb-[24px] mt-[8px]">
                  {isValidString(proposalDetails?.attachment) ||
                  isValidObject(proposalDetails?.attachment) ? (
                    <FilePreview
                      fileUrlOrContent={proposalDetails?.attachment}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="pl-[24px]">
              <div className="text-[24px] font-semibold mb-[27px]">Terms</div>
              <div className="flex gap-x-[44px] mb-[32px]">
                <div className="flex-1">
                  <CustomSelect
                    id="salaryType"
                    data={salaryTypes}
                    label="salary type"
                    placeholder={"Select"}
                    onChange={onProposalDetailsChange}
                    defaultSelection={proposalDetails?.salaryType}
                  />
                </div>
                <div className="flex-1">
                  <CustomTextfield
                    id="salaryValue"
                    type="number"
                    label="salary value"
                    onChange={onProposalDetailsChange}
                    value={proposalDetails?.salaryValue}
                    customErrorMessage={
                      proposalDetails?.salaryValue <= 0
                        ? "Please enter a value greater than 0."
                        : ""
                    }
                    startAdornment={"$"}
                  />
                </div>
              </div>
              {/* <div className="flex">
                <div className={classes.projectDetailDiv}>
                  <CustomLabel label="total price of project" />{" "}
                  <span className={classes.projectDetailValue}>
                    $ {jobData?.totalPrice}
                  </span>
                </div>
                <div className={classes.projectDetailDiv}>
                  <CustomLabel label="service fee" />{" "}
                  <span className={classes.projectDetailValue}>
                    $ {jobData?.serviceFee}
                  </span>
                </div>
                <div className={classes.projectDetailDiv}>
                  <CustomLabel label="you'll receive" />{" "}
                  <span className={classes.projectDetailValue}>
                    $ {jobData?.candidateEarnings}
                  </span>
                </div>
              </div> */}
              {/* The business logic of above is not finalised yet so commenting it for now. */}
            </div>
          </div>
        </div>
        {/* Proposal Details - end */}

        {/* Actions- start */}
        <div className="flex gap-x-[24px] py-[12px] px-[131px]">
          <div className="flex-1">
            <CustomButton
              customClasses={clsx([classes.actionButton, classes.cancelButton])}
              onClick={closeParentDialog}
            >
              Cancel
            </CustomButton>
          </div>

          <CustomTooltip
            title={!activateSubmitButton && "Please enter all the details."}
          >
            <div className="flex-1">
              <CustomButton
                type="button1"
                customClasses={clsx([classes.actionButton])}
                disabled={!activateSubmitButton}
                onClick={onSubmitProposalClick}
              >
                Apply Job
                <img src="/iconfilledsend.svg" />
              </CustomButton>
            </div>
          </CustomTooltip>
        </div>
        {/* Actions- start */}
      </div>
      <SubmitActionDialog
        submitDialogOpen={submitDialogOpen}
        setSubmitDialogOpen={setSubmitDialogOpen}
        submitActionLoading={submitActionLoading}
        submitActionCompleted={submitActionCompleted}
        submitActionError={submitActionError}
        customSuccessMessage={customSuccessMessage}
        responseErroeMessage={responseErroeMessage}
      />
    </>
  );
};

SubmitProposal.propTypes = {};

export default SubmitProposal;
