import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Link, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import Documents from "./Documents";
import HelpSupport from "./HelpSupport";
import Title from "../../common/Title";
import ProfileSettings from "./ProfileSettings";
import AccountSettings from "./AccountSettings";
import SubscriptionBody from "./subscription/SubscriptionBody";

const useStyles = makeStyles((theme) => ({
  titleDiv: {
    position: "fixed",
    width: "1440px",
    borderBottom: "1px solid #EDEDED",
    backgroundColor: "white",
    zIndex: 3,
  },
  settingsContainer: {
    paddingTop: "102px", // titleDiv's total hieght is 102px.
  },
  leftView: {
    padding: "24px 48px",
    width: "291px",
    boxSizing: "content-box",
  },
  navItemsDiv: {
    display: "flex",
    flexDirection: "column",
    rowGap: "32px",
    margin: "16px 0",
  },
  navItemLink: {
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "18px",
  },
  navItemTitle: {
    fontSize: "16px",
    fontWeight: 600,
  },
  selectedNavItem: {
    color: `${theme.palette.primary.main} !important`,
  },
  rightArrowIcon: {
    width: "21px",
    height: "21px",
    float: "right",
  },
  rightView: {
    position: "relative",
    borderLeft: "1px solid #EDEDED",
    padding: "48px",
    width: "956px",
    boxSizing: "content-box",
    [theme.breakpoints.down("1440")]: {
      width: "900px",
    },
    [theme.breakpoints.down("1385")]: {
      width: "800px",
    },
    [theme.breakpoints.down("1285")]: {
      width: "700px",
    },
    [theme.breakpoints.down("1185")]: {
      width: "600px",
    },
  },
  rightViewWithFixedTitle: {
    padding: "0 48px 48px",
  },
}));

const Settings = (props) => {
  const location = useLocation();
  const classes = useStyles();
  const leftNavData = [
    // {
    //   title: "Subscription",
    //   component: <SubscriptionBody />,
    // },
    {
      title: "DocuCenter",
      component: <Documents />,
    },
    {
      title: "Profile Settings",
      component: <ProfileSettings />,
    },
    {
      title: "Account Settings",
      component: <AccountSettings />,
    },
    {
      title: "Help & Support",
      component: <HelpSupport />,
    },
  ];
  const componentIndicesWithFixedTitle = [2, 3]; // indices as per above leftNavData. 2,3-> Profile settings Account Settings.
  const [selectedNavItemIndex, setSelectedNavItemIndex] = useState(0);

  useEffect(() => {
    const locationState = location?.state;
    const settingIndex = locationState?.settingIndex;
    // console.log({ locationState });
    if (settingIndex) setSelectedNavItemIndex(settingIndex);
  }, []);

  const onNavItemClick = (navItemIndex) => {
    setSelectedNavItemIndex(navItemIndex);
  };

  const isCurrentSelectedNavItem = (navItemIndex) => {
    return selectedNavItemIndex === navItemIndex;
  };

  const SelectedNavItemComponent = () => {
    return <>{leftNavData?.[selectedNavItemIndex]?.component}</>;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedNavItemIndex]);

  return (
    <>
      <div className={classes.titleDiv}>
        <Title
          title={leftNavData?.[selectedNavItemIndex]?.title ?? "Settings"}
        />
      </div>

      <Grid container className={classes.settingsContainer}>
        <Grid item className={classes.leftView}>
          <div className={classes.navItemsDiv}>
            {leftNavData?.map((navItemDetails, navItemIndex) => (
              <div>
                <Link
                  className={clsx([
                    classes.navItemLink,
                    isCurrentSelectedNavItem(navItemIndex) &&
                      classes.selectedNavItem,
                  ])}
                  onClick={() => {
                    onNavItemClick(navItemIndex);
                  }}
                >
                  <Typography className={classes.navItemTitle}>
                    {navItemDetails?.title}{" "}
                    {isCurrentSelectedNavItem(navItemIndex) && (
                      <ArrowForwardIosIcon className={classes.rightArrowIcon} />
                    )}
                  </Typography>
                </Link>
              </div>
            ))}
          </div>
        </Grid>
        <Grid
          item
          className={clsx([
            classes.rightView,
            componentIndicesWithFixedTitle.includes(selectedNavItemIndex) &&
              classes.rightViewWithFixedTitle,
          ])}
        >
          <SelectedNavItemComponent />
        </Grid>
      </Grid>
    </>
  );
};

Settings.propTypes = {};

export default Settings;
