import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Autocomplete, Chip, TextField, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomLabel from "./CustomLabel";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles((theme) => ({
  textFieldRoot: {
    height: "40px",
    padding: 0,
    whiteSpace: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    textOverflow: "ellipsis",
    border: "1px solid #EDEDED",
    "& .MuiInputBase-root": {
      padding: "0 10px !important",
      backgroundColor: theme.palette.common.white,
      display: "flex",
      alignItems: "center",
      "& .MuiInputBase-input": {
        padding: 0,
        height: "100%",
        fontSize: "14px",
      },
      "&.Mui-focused": {
        border: "none",
        boxShadow: "none",
      },
      "&:before, &:after": {
        borderBottom: "none !important",
      },
      "& .MuiAutocomplete-endAdornment": {
        position: "static",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },
  autocompleteOption: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tagBox: {
    backgroundColor: "#EDEDED",
    padding: "5px 10px",
    borderRadius: "4px",
    display: "inline-block",
    textAlign: "center",
    margin: "5px",
    fontSize: "14px",
    fontWeight: "600",
  },
  chipRoot: {
    border: "1px solid #EDEDED",
    borderRadius: "2px",
    fontSize: "10px",
    fontWeight: 700,
    textTransform: "uppercase",
  },
  chipLabel: {
    position: "relative",
    top: "2px",
  },
  deleteIcon: {
    width: "16px !important",
    height: "16px !important",
    color: `${theme.palette.common.black} !important`,
  },
  searchContainer: {
    width: "100%",
    position: "relative",
  },
  customScrollBar: {
    "&::-webkit-scrollbar": {
      height: "4px", // Reduced height for the scrollbar
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "2px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#555",
    },
  },
  inputRoot: {
    "&:before, &:after": {
      display: "none",
    },
  },
  input: {
    "&:focus": {
      outline: "none",
      borderColor: "transparent",
      boxShadow: "none",
    },
  },
  noBorder: {
    border: "none",
  },
}));

const CustomAutoSearch = (props) => {
  const classes = useStyles();
  const {
    id,
    onChange,
    onSearch,
    label,
    placeholder,
    data,
    defaultSelection,
    alwaysShowDefaultSelection,
    allowMultipleSelection,
    allowCustomUserInput,
    customClasses,
    disabled,
    showSearchIcon,
  } = props;

  const [selectedValue, setSelectedValue] = useState(defaultSelection);
  const [searchInput, setSearchInput] = useState("");
  const [showEnterPrompt, setShowEnterPrompt] = useState(false);

  useEffect(() => {
    setSelectedValue(defaultSelection);
  }, [defaultSelection]);

  const handleOnChange = (event, value) => {
    if (!alwaysShowDefaultSelection) setSelectedValue(value);
    onChange(id, value);
  };

  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleFocus = () => {
    setIsInputFocused(true);
  };

  const handleBlur = () => {
    setIsInputFocused(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.target.value.trim() !== "") {
      event.preventDefault();
      const enteredValue = event.target.value.trim();
      const updatedTags = [...selectedValue, enteredValue];
      setSelectedValue(updatedTags);
      onChange(id, updatedTags);
      setSearchInput("");
      setShowEnterPrompt(false);
    } else if (event.key === "Enter" && !isInputFocused) {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleSearch = () => {
    if (searchInput.trim()) {
      const updatedTags = [...selectedValue, searchInput.trim()];
      setSelectedValue(updatedTags);
      onChange(id, updatedTags);
      setSearchInput("");
      setShowEnterPrompt(false);
    }
    onSearch();
  };

  return (
    <div className={`${classes.searchContainer} ${classes.customScrollBar}`}>
      {label && <CustomLabel label={label} />}
      <Autocomplete
        key={(selectedValue?.join(",") || data?.join(","))}
        multiple={allowMultipleSelection}
        id="tags-filled"
        limitTags={2}
        options={data}
        freeSolo={allowCustomUserInput}
        classes={{
          root: customClasses,
          option: classes.autocompleteOption,
        }}
        value={selectedValue}
        disabled={disabled}
        disableClearable={
          (allowMultipleSelection && selectedValue?.length === 0) || !selectedValue
        }
        onChange={handleOnChange}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={index}
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              classes={{
                root: classes.chipRoot,
                label: classes.chipLabel,
                deleteIcon: classes.deleteIcon,
              }}
              deleteIcon={<CloseIcon fontSize="small" />}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            placeholder={placeholder}
            className={`${classes.textFieldRoot} ${classes.inputRoot}`}
            value={searchInput}
            onChange={(event) => {
              setSearchInput(event.target.value);
              if (event.target.value !== "") {
                setShowEnterPrompt(true);
              }
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {showSearchIcon ? (
                    <IconButton onClick={handleSearch}>
                      <SearchIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
              classes: { root: classes.noBorder, input: classes.input },
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} className={classes.autocompleteOption}>
            <Box className={classes.tagBox}>{option}</Box>
          </li>
        )}
        ListboxProps={{
          style: { display: "flex", flexWrap: "wrap", gap: "5px", overflowX: "hidden" },
        }}
      />
    </div>
  );
};

CustomAutoSearch.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  data: PropTypes.array.isRequired,
  defaultSelection: PropTypes.array,
  alwaysShowDefaultSelection: PropTypes.bool,
  allowMultipleSelection: PropTypes.bool,
  allowCustomUserInput: PropTypes.bool,
  customClasses: PropTypes.string,
  disabled: PropTypes.bool,
  showSearchIcon: PropTypes.bool,
};

export default CustomAutoSearch;
