import React from 'react';
import { Bar } from 'react-chartjs-2';


const BarChart = ({props}) => {
  const name = props.name;
  const ddata = props.data;

  var oneData = [];
  var twoData = [];
  var labels = [];

  if (Array.isArray(ddata) && ddata.length > 0) {
    oneData = ddata.map((item) => item.ti);
    twoData = ddata.map((item) => item.tj);
    labels = ddata.map((item) => `${item.monthName} ${item.year}`);
  }
  // if (Array.isArray(ddata) && ddata.length > 0) {
  //   oneData = ddata.map((data) => data.ti);
  //   twoData = ddata.map((data) => data.tj);
  //   labels = ddata.map((data) => {
  //    const date = new Date(data.timestamp);
  //    const monthNames = [
  //      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  //      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  //    ];
  //    return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
  //  });
  // }


    const dataBar = {
        // labels: [  
        //   'Jan',
        //   'Feb',
        //   'Mar',
        //   'Apr',
        //   'May',
        //   'Jun',
        //   'Jul',
        //   'Aug',
        //   'Sep',
        //   'Oct',
        //   'Nov',
        //   'Dec'],
        labels: labels,
        datasets: [
          {
            label: 'Total Interviews',
            backgroundColor: '#ccc',
            borderColor: '#ddd',
            borderWidth: 1,
            hoverBackgroundColor: '#666',
            hoverBorderColor: '#777',
            // data: [55, 59, 80, 81, 56, 55, 40]
            data: oneData
          },
          {
            label: 'Total Job Offers',
            backgroundColor: '#eee',
            borderColor: '#ddd',
            borderWidth: 1,
            hoverBackgroundColor: '#777',
            hoverBorderColor: '#666',
            // data: [65, 59, 80, 81, 56, 55, 40]
            data: twoData
          }
        ]
      };

  // Configuration options for the chart
  const options = {
    scales: {
      x: {
        grid: {
          color: '#333', // Set the background color of the x-axis gridlines
        },
      },
      y: {
        grid: {
          color: '#333', // Set the background color of the y-axis gridlines
        },
      },
    },
    plugins: {
      legend: {
        display: true, // Hide the legend
      },
    },
  };

  return (
    <div>
      <h4 className='text-white' >{name}</h4>
      <Bar 
        data={dataBar} 
        options={options}
        width={40}
        height={20}
      />
    </div>
  );
};

export default BarChart;
