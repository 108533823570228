import { createSlice } from "@reduxjs/toolkit";
import { getAgencyInfoThunk } from "./employee.thunk";


const initialState = {
  agencyInfo: {
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    personal_info: {},
    profile_picture: ""
  },
};

const profileSettingsSlice = createSlice({
  name: "agencyInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(getAgencyInfoThunk.fulfilled, (state, action) => {
      state.agencyInfo = action.payload;
    })
    // .addCase(updateProfilePictureThunk.fulfilled, (state, action) => {
    //   state.clientInfo.profile_picture = action.meta.arg.profile_picture;
    // });
  },
});

// Action creators are generated for each case reducer function
export default profileSettingsSlice.reducer;