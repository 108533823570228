import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";

export const getConsultantTimesheet = async (request) => {
  return fetchWrapper(
    `${configs.APIUrl}/client/getTimesheet` , {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: request,
      }
  );
};
export const addConsultantTimesheet = async (request) => {
  return fetchWrapper(
    `${configs.APIUrl}/client/timesheet` , {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: request,
      }
  );
};

export const updateStatusOfConsultantTimesheet = async (request) => {
  return fetchWrapper(
    `${configs.APIUrl}/client/timesheet
    ` , {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: request,
      }
  );
};
export const updateTimesheetSettings = async (request) => {
  return fetchWrapper(
    `${configs.APIUrl}/client/timesheetSettings
    ` , {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: request,
      }
  );
};

export const updateInvoiceSettings = async (request) => {
  return fetchWrapper(
    `${configs.APIUrl}/client/invoiceSettings
    ` , {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: request,
      }
  );
};

export const getConsultantInvoiceByJob = async (request) => {
  return fetchWrapper(
    `${configs.APIUrl}/client/getInvoice` , {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: request,
      }
  );
};
export const updateInvoiceStatus = async (invoiceID) =>
  fetchWrapper(`${configs.APIUrl}/client/updateInvoice/${invoiceID}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: { "status": "IN PROGRESS" }
  });
