import CustomButton from 'app/v2/components/CustomButton'
import CustomTextfield from 'app/v2/components/filters/CustomTextfield'
import { addContractType, addExperience, addIndustry, addSkill } from 'app/v2/services/admin/category.service';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from 'react-redux';
import { fetchContractTypesThunk, fetchExperiencesThunk, fetchIndustryThunk, fetchSkillsThunk } from 'app/v2/store/features/misc/miscThunk';

const AddCategoryForm2 = ({ category,currentName,currentId ,handleDialogClose}) => {
    const [value, setValue] = useState("");
    const [minValue, setMinValue] = useState("");
    const [maxValue, setMaxValue] = useState("");
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();


    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleInputChange = (id,value) => {
        setValue(value);
    };

    const handleMinInputChange = (id,value) => {
        setMinValue(value);
        validateExperience(value, maxValue);

    };

    const handleMaxInputChange = (id,value) => {
        setMaxValue(value);
        validateExperience(minValue, value);

    };

    const validateExperience = (min, max) => {
        if (Number(max) < Number(min)) {
            setErrorMessage('Maximum experience must be greater  than or equal to minimum experience');
        } else {
            setErrorMessage('');
        }
    };

    // const handleAddCategory = () => {
    //     if(category==="Skills"){
    //         const bodyRequest={
    //             name:value
    //         }

    //         addSkill(bodyRequest)
    //         .then((res)=>{
    //             if (res.message) {
    //                 const key = enqueueSnackbar(res.message, {
    //                   variant: "success",
    //                   autoHideDuration: 3000,
    //                   action: (key) => (
    //                     <CloseIcon
    //                       onClick={() => closeSnackbar(key)}
    //                       style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
    //                     />
    //                   ),
    //                 });
    //               }


    //         })
    //         .catch((err)=>{
    //             if (err.message) {
    //                 enqueueSnackbar(err.message, {
    //                   variant: "error",
    //                   autoHideDuration: 3000,
    //                   action: (key) => (
    //                     <CloseIcon
    //                       onClick={() => closeSnackbar(key)}
    //                       style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
    //                     />
    //                   ),
    //                 });
    //               }

    //         })
    //     }
    //     else if(category==="Industries"){
    //         const bodyRequest={
    //             name:value
    //         }

    //         addIndustry(bodyRequest)
    //         .then((res)=>{
    //             if (res.message) {
    //                 const key = enqueueSnackbar(res.message, {
    //                   variant: "success",
    //                   autoHideDuration: 3000,
    //                   action: (key) => (
    //                     <CloseIcon
    //                       onClick={() => closeSnackbar(key)}
    //                       style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
    //                     />
    //                   ),
    //                 });
    //               }


    //         })
    //         .catch((err)=>{
    //             if (err.message) {
    //                 enqueueSnackbar(err.message, {
    //                   variant: "error",
    //                   autoHideDuration: 3000,
    //                   action: (key) => (
    //                     <CloseIcon
    //                       onClick={() => closeSnackbar(key)}
    //                       style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
    //                     />
    //                   ),
    //                 });
    //               }

    //         })
    //     }
    //     else if(category==="ContractTypes"){
    //         const bodyRequest={
    //             name:value
    //         }

    //         addContractType(bodyRequest)
    //         .then((res)=>{
    //             if (res.message) {
    //                 const key = enqueueSnackbar(res.message, {
    //                   variant: "success",
    //                   autoHideDuration: 3000,
    //                   action: (key) => (
    //                     <CloseIcon
    //                       onClick={() => closeSnackbar(key)}
    //                       style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
    //                     />
    //                   ),
    //                 });
    //               }


    //         })
    //         .catch((err)=>{
    //             if (err.message) {
    //                 enqueueSnackbar(err.message, {
    //                   variant: "error",
    //                   autoHideDuration: 3000,
    //                   action: (key) => (
    //                     <CloseIcon
    //                       onClick={() => closeSnackbar(key)}
    //                       style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
    //                     />
    //                   ),
    //                 });
    //               }

    //         })
    //     }
    //      else if (category === "Experience") {
    //         const bodyRequest = {
    //             level: value,
    //             minExp: minValue,
    //             maxExp: maxValue
    //         };
    //          addExperience(bodyRequest)
    //          .then((res)=>{

    //             if (res.message) {
    //                 const key = enqueueSnackbar(res.message, {
    //                   variant: "success",
    //                   autoHideDuration: 3000,
    //                   action: (key) => (
    //                     <CloseIcon
    //                       onClick={() => closeSnackbar(key)}
    //                       style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
    //                     />
    //                   ),
    //                 });
    //               }

    //          })
    //          .catch((err)=>{

    //             if (err.message) {
    //                 enqueueSnackbar(err.message, {
    //                   variant: "error",
    //                   autoHideDuration: 3000,
    //                   action: (key) => (
    //                     <CloseIcon
    //                       onClick={() => closeSnackbar(key)}
    //                       style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
    //                     />
    //                   ),
    //                 });
    //               }

    //          })
            
    //     }
    // };


    const handleAddCategory = () => {
        const bodyRequest = { name: value };
    
        const showSnackbar = (message, variant) => {
            enqueueSnackbar(message, {
                variant: variant,
                autoHideDuration: 3000,
                action: (key) => (
                    <CloseIcon
                        onClick={() => closeSnackbar(key)}
                        style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
                    />
                ),
            });
        };
    
        const handleResponse = (res, variant) => {
            if (res.message) {
                showSnackbar(res.message, variant);
                if(category==="Skills"){
                    dispatch(fetchSkillsThunk())
                    // handleApi()
                    handleDialogClose()

                }
                if(category==="Industries"){
                    dispatch(fetchIndustryThunk())
                }

                if(category==="ContractTypes"){
                    dispatch(fetchContractTypesThunk())
                }

                if(category==="Experiences"){
                    dispatch(fetchExperiencesThunk())
                }
            }
        };
    
        const handleError = (err) => {
            if (err.message) {
                showSnackbar(err.message, "error");
            }
        };
    
        const addCategory = (addFunction) => {
            addFunction(bodyRequest,currentId)
                .then((res) => handleResponse(res, "success"))
                .catch(handleError);
        };
    
        switch (category) {
            case "Skills":
                addCategory(addSkill);
                break;
            case "Industries":
                addCategory(addIndustry);
                break;
            case "ContractTypes":
                const contractRequest={
                    level:value
                }
                addContractType(contractRequest);
                break;
            case "Experiences":
                const experienceRequest = {
                    level: value,
                    minExp: minValue,
                    maxExp: maxValue
                };
                addExperience(experienceRequest)
                    .then((res) => handleResponse(res, "success"))
                    .catch(handleError);
                break;
            default:
                console.error("Unknown category:", category);
        }
    };
    
    return (
        <div className="flex flex-col w-[486px] p-[48px] gap-y-[10px]">
            <h3>{category}</h3>
            <CustomTextfield
                id="fileName"
                // label="Filename"
                value={currentName??value}
                onChange={handleInputChange} 
            />
            {category === "Experiences" && (
                <>
                <div className='flex gap-x-[50px]'>
                    <CustomTextfield
                        id="minExp"
                        label="Minimum Experience"
                        value={minValue}
                        onChange={handleMinInputChange}
                    />
                    <CustomTextfield
                        id="maxExp"
                        label="Maximum Experience"
                        value={maxValue}
                        onChange={handleMaxInputChange}
                    />
                     
                </div>
                 {errorMessage && (
                    <div style={{ color: 'red', marginTop: '5px',whiteSpace: 'pre-wrap' }}>
                        {errorMessage}
                    </div>
                )}
                </>
            )}
            <div className='w-full flex justify-center items-center'>
                <CustomButton type="button1" onClick={handleAddCategory}>{currentName?"Submit":"Add"}</CustomButton>
            </div>
        </div>
    );
};
export default AddCategoryForm2;

