import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

import PrivateRoute from "./PrivateRoute";
import Banner from "../components/Banner";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Login from "../Pages/Admin/Login";
import Dashboard from "../Pages/Admin/Dashboard";
import ManageClients from "../Pages/Admin/ManageClients";
import ManageConsultants from "../Pages/Admin/ManageConsultants";
import ClientDetail from "../Pages/Admin/ClientDetail";
import SubscriptionPlans from "../Pages/Admin/SubscriptionPlans";
import Invoices from "../Pages/Admin/Invoices";
import ClientJobs from "../Pages/Admin/ClientJobs";
import ActiveConsultants from "../Pages/Admin/ActiveConsultants";
import ConsultantDetail from "../Pages/Admin/ConsultantDetail";
import ManageCategories from "../Pages/Admin/Categories";
import AgencyDetail from "../Pages/Admin/AgencyDetail/component";
import ManageAgency from "../Pages/Admin/ManageAgency";

const useStyles = makeStyles((theme) => ({
  bannerAndNavbar: {
    position: "fixed",
    top: 0,
    maxWidth: "1440px", // setting max-width again since the element has fixed position and does not inherit it from parent.
    width: "100%",
    zIndex: 999,
  },
  body: {
    display: "flex",
    flexDirection: "column",
  },
  bodyWithF: {
    minHeight: "calc(100vh - 265px)", // total viewport height minus footer's height(265px).
  }, // F-Footer
  bodyWithBNF: {
    minHeight: "calc(100vh - 399px)", // total viewport height minus banner(34px),navbar(100px),footer's(265px) heights.
    paddingTop: "134px", // fixed banner and navbar's height
  }, // BNF-Banner,Navbar,Footer
  bodyWithNF: {
    minHeight: "calc(100vh - 365px)", // total viewport height minus navbar(100px),footer's(265px) heights.
    paddingTop: "100px", // fixed navbar's height
  }, // NF- Navbar,Footer

  /** In terms of viewport height - work on this later */
  // bodyWithF: {
  //   minHeight: "calc(100vh - 28.9vh)", // total viewport height minus footer's height(265px).
  // }, // F-Footer
  // bodyWithBNF: {
  //   minHeight: "calc(100vh - 3.6vh - 10.8vh - 28.9vh)", // total viewport height minus banner(34px),navbar(100px),footer's(265px) heights.
  //   paddingTop: "134px", // fixed banner and navbar's height
  // }, // BNF-Banner,Navbar,Footer
  // bodyWithNF: {
  //   minHeight: "calc(100vh - 10.8vh - 28.9vh)", // total viewport height minus navbar(100px),footer's(265px) heights.
  //   paddingTop: "100px", // fixed navbar's height
  // }, // NF- Navbar,Footer
}));

const withBannerNavbarFooter = (WrappedComponent) => {
  const ComponentWithBNF = (props) => {
    const classes = useStyles();
    const [showBanner, setShowBanner] = useState(false); // Setting default as false -> Not showing banner for MVP.

    const onBannerClose = () => {
      setShowBanner(false);
    };

    return (
      <>
        <div className={classes.bannerAndNavbar}>
          <Banner showBanner={showBanner} onClose={onBannerClose} />
          <Navbar />
        </div>
        <div
          className={clsx([
            classes.body,
            showBanner ? classes.bodyWithBNF : classes.bodyWithNF,
          ])}
        >
          <WrappedComponent {...props} />
        </div>

        <Footer />
      </>
    );
  };

  return ComponentWithBNF;
};

const withFooter = (WrappedComponent) => {
  const ComponentWithFooter = (props) => {
    const classes = useStyles();
    return (
      <>
        <div className={clsx([classes.body, classes.bodyWithF])}>
          <WrappedComponent {...props} />
        </div>
        <Footer />
      </>
    );
  };

  return ComponentWithFooter;
};

const AdminRoute = () => {
  const ClientDetailView = withBannerNavbarFooter(ClientDetail);
  const ConsultantDetailView = withBannerNavbarFooter(ConsultantDetail);
  const AgencyDetailView = withBannerNavbarFooter(AgencyDetail)

  const ClientJobsView = withBannerNavbarFooter(ClientJobs);
  return (
    <div>
      <Switch>
        <Route path={"/admin/login"} component={withFooter(Login)} />
        <PrivateRoute
          path={"/admin/dashboard"}
          component={withBannerNavbarFooter(Dashboard)}
        />
        <PrivateRoute
          path={"/admin/manage-clients"}
          component={withBannerNavbarFooter(ManageClients)}
        />
         <PrivateRoute
          path={"/admin/manage-agency"}
          component={withBannerNavbarFooter(ManageAgency)}
        />
        <PrivateRoute
          path={"/admin/manage-consultants"}
          component={withBannerNavbarFooter(ManageConsultants)}
        />
        <PrivateRoute
          path={"/admin/client-detail/:clientID"}
          render={(props) => (
            <ClientDetailView clientID={props?.match?.params?.clientID} />
          )}
        />
         <PrivateRoute
          path={"/admin/agency-detail/:agencyID"}
          render={(props) => (
            <AgencyDetailView agencyID={props?.match?.params?.agencyID} />
          )}
        />
          <PrivateRoute
          path={"/admin/consultant-detail/:consultantID"}
          render={(props) => <ConsultantDetailView  props={props} />}
        />
        <PrivateRoute
          path={"/admin/client-jobs/:empId"}
          render={(props) => (
            <ClientJobsView empId={props?.match?.params?.empId} />
          )}
        />
        <PrivateRoute
          path={"/admin/subscription-plans"}
          component={withBannerNavbarFooter(SubscriptionPlans)}
        />
        <PrivateRoute
          path={"/admin/invoices"}
          component={withBannerNavbarFooter(Invoices)}
        />
        <PrivateRoute
          path={"/admin/active-consultants"}
          component={withBannerNavbarFooter(ActiveConsultants)}
        />
        <PrivateRoute
          path={"/admin/manage-categories"}
          component={withBannerNavbarFooter(ManageCategories)}
        />
      </Switch>
    </div>
  );
};

export default AdminRoute;
