import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { Typography, Button } from "@mui/material";
import { IconDot } from "app/v2/Pages/SVGs/IconDot";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

import SubmitProposal from "../../Proposal/SubmitProposal";
import ProposalOverview from "../../Proposal/ProposalOverview";
import CustomDialog from "app/v2/components/CustomDialog";
import DialogSuccessBody from "app/v2/Pages/common/DialogSuccessBody";
import DialogErrorBody from "app/v2/Pages/common/DialogErrorBody";
import DialogLoadingBody from "app/v2/Pages/common/DialogLoadingBody";
import CustomButton from "app/v2/components/CustomButton";
import CloseIcon from "@mui/icons-material/Close";

import {
  fetchExperiencesThunk,
  fetchContractTypesThunk,
} from "app/v2/store/features/misc/miscThunk";
import {
  saveOrunsaveJob,
  acceptOrRejectJobInvitation,
} from "app/v2/services/consultant/myJobs.service";
import { messages, hiringBoardFixedStages } from "app/v2/Pages/Utils/constants";
import {
  isFunction,
  capitalizeFirstLetter,
  isValidObject,
} from "app/v2/Pages/Utils/utilFunctions";
import {
  getContractTypeLevel,
  getExperience,
} from "app/v2/Pages/common/getIdTypeLevel";

const useStyles = makeStyles((theme) => ({
  listCardContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
    padding: "24px",
    borderBottom: "1px solid #EDEDED",
    "&:hover": {
      boxShadow: "0px 4px 36px rgba(0, 0, 0, 0.12)",
    },
    width: "100%",

    [theme.breakpoints.down("sm")]: {
paddingBottom:"20px"  ,  }
  },

  jobTitle: {
    fontSize: "18px",
    fontWeight: 600,
  },
  companyName: {
    fontSize: "12px",
  },
  jobPostedDate: {
    fontSize: "14px",
  },
  jobDetailsSection: {
    display: "flex",
    columnGap: "16px",
  },
  jobDetailsSectionSegment: {
    flex: 1,
  },
  jdSegmentTitle: {
    fontSize: "10px",
    fontWeight: 600,
  },
  jdSegmentValue: {
    fontSize: "14px",
    fontWeight: 600,
  },
  jobDescription: {
    fontSize: "12px",
    // height: "100px",
    overflowY: "auto",
    paddingRight: "12px",
  },
  actionsDiv: {
    display: "flex",
    columnGap: "16px",
  },
  viewJobButton: {
    backgroundColor: `${theme.palette.common.black} !important`,
    color: `${theme.palette.common.white} !important`,
    padding: "10px 20px",
    fontSize: "14px",
    fontWeight: 600,
    display: "flex",
    columnGap: "10px",
  },
  applyOrAcceptOrRejectButton: {
    backgroundColor: `${theme.palette.common.white} !important`,
    color: `${theme.palette.common.black} !important`,
    padding: "10px 20px",
    border: "1px solid #ededed",
    fontSize: "14px",
    fontWeight: 600,
    "&:disabled": {
      // backgroundColor: `#EDEDED !important`,
      // color: "#ABABAB !important",
      backgroundColor: `#ABABAB !important`,
      color: "white !important",
    },
  },
  bookmarkDiv: {
    cursor: "pointer",
    padding: "10px 20px",
    border: "1px solid #ededed",
    display: "flex",
    alignItems: "center",
  },
  testSentIcon: {
    color: "#FBBC05",
    width: "10px",
    height: "10px",
  },
  orgImage: {
    width: "100px",
    height: "100px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
  yesOrNoButton: {
    fontSize: "12px !important",
    padding: "12px",
  },
  noButton: {
    border: "1px solid #EDEDED !important",
  },
}));

const JobStage = ({ type }) => {
  const classes = useStyles();

  const stageTypeInfo = {
    invited: {
      text: "Invited you to apply",
      textColor: "#1E5DFF",
      divColor: "#F4F8FF",
    }, // blue
    applied: {
      text: "Applied",
      textColor: "#1E5DFF",
      divColor: "#F4F8FF",
    }, // blue
    shortlisted: {
      text: "Application Shortlisted",
      textColor: "#FBBC05",
      divColor: "#FFF5D9",
    }, // yellow
    offered: {
      text: "Job Offered",
      textColor: "#14C042",
      divColor: "#EBFFF0",
    }, // green
    accepted: {
      text: "Job Offer Accepted",
      textColor: "#14C042",
      divColor: "#EBFFF0",
    }, // green
    withdrew: {
      text: "Job Offer Withdrawn",
      textColor: "#D80027",
      divColor: "#FFECEA",
    }, // red
    rejected: {
      text: "Job Offer Rejected",
      textColor: "#D80027",
      divColor: "#FFECEA",
    }, // red
    custom: {
      // text:"", Add text of the custom stage(done below).
      textColor: "#533A71",
      // divColor: "#B760E7",
    }, // purple

    // testSent: {
    //   text: "Assessment Test Sent",
    //   textColor: "#FBBC05",
    //   divColor: "#FFF5D9",
    //   icon: <ErrorOutlineRoundedIcon className={classes.testSentIcon} />,
    // }, // yellow

    // draft: {
    //   text: "Draft",
    //   textColor: "#717171",
    //   divColor: "#F4F8FF",
    //   borderColor: "#717171",
    // },
  };

  let stageInfo;

  if (hiringBoardFixedStages.includes(type)) stageInfo = stageTypeInfo[type];
  else {
    stageInfo = stageTypeInfo.custom;
    stageInfo.text = capitalizeFirstLetter(type);
  }
  return (
    <div
      className={`flex gap-x-[5px] items-center px-[8px] bg-[${
        stageInfo?.divColor
      }] ${
        stageInfo?.borderColor &&
        `border-[1px] border-solid border-[${stageInfo?.borderColor}]`
      }`}
    >
      {stageInfo?.icon ?? <IconDot fillColor={stageInfo?.textColor} />}
      <Typography
        style={{
          color: stageInfo?.textColor,
          fontSize: "12px",
          fontWeight: 700,
        }}
      >
        {stageInfo?.text}
      </Typography>
    </div>
  );
};

const AcceptJobDialogContent = ({ jobID, closeDialog, setJobStage }) => {
  const classes = useStyles();
  const [acceptJobLoading, setAcceptJobLoading] = useState(false);
  const [acceptJobCompleted, setAcceptJobCompleted] = useState(false);
  const [acceptJobError, setAcceptJobError] = useState(false);

  const onAcceptJob = () => {
    setAcceptJobLoading(true);
    acceptOrRejectJobInvitation({ jobId: jobID, status: "accepted" })
      .then((response) => {
        setAcceptJobLoading(false);
        setAcceptJobCompleted(true);
        setJobStage("invitationAccepted");
      })
      .catch((err) => {
        setAcceptJobLoading(false);
        setAcceptJobError(true);
      });
  };

  const Content = () => {
    if (acceptJobLoading) return <DialogLoadingBody />;
    else if (acceptJobCompleted)
      return (
        <DialogSuccessBody
          content={{
            title: `Congratulations! You've successfully accepted the job invitation.`,
            desc: (
              <b>
                Next, please click 'Apply Now' on the job card to proceed with
                the application.
              </b>
            ),
          }}
        />
      );
    else if (acceptJobError)
      return (
        <DialogErrorBody
          content={{ title: messages.GENERIC_ERROR_MESSAGE, desc: "" }}
        />
      );
    else
      return (
        <>
          Are you sure you want to accept the job invitation?
          <div className="flex gap-x-[16px] justify-end">
            <CustomButton
              customClasses={clsx([classes.yesOrNoButton, classes.noButton])}
              onClick={closeDialog}
            >
              No
            </CustomButton>

            <CustomButton
              type="button1"
              customClasses={classes.yesOrNoButton}
              onClick={() => {
                onAcceptJob();
              }}
            >
              Yes
            </CustomButton>
          </div>
        </>
      );
  };

  return (
    <div className="p-[36px] flex flex-col gap-y-[24px] max-w-[739px] m-auto">
      <Content />
    </div>
  );
};

const RejectJobDialogContent = ({
  jobID,
  closeDialog,
  setJobInvitationStatus,
}) => {
  const classes = useStyles();
  const [rejectJobLoading, setRejectJobLoading] = useState(false);
  const [rejectJobCompleted, setRejectJobCompleted] = useState(false);
  const [rejectJobError, setRejectJobError] = useState(false);

  const onRejectJob = () => {
    setRejectJobLoading(true);
    acceptOrRejectJobInvitation({ jobId: jobID, status: "rejected" })
      .then((response) => {
        setRejectJobLoading(false);
        setRejectJobCompleted(true);
        setJobInvitationStatus("rejected");
      })
      .catch((err) => {
        setRejectJobLoading(false);
        setRejectJobError(true);
      });
  };

  const Content = () => {
    if (rejectJobLoading) return <DialogLoadingBody />;
    else if (rejectJobCompleted)
      return (
        <DialogSuccessBody
          content={{
            title: `You've successfully rejected the job invitation.`,
          }}
        />
      );
    else if (rejectJobError)
      return (
        <DialogErrorBody
          content={{ title: messages.GENERIC_ERROR_MESSAGE, desc: "" }}
        />
      );
    else
      return (
        <>
          Are you sure you want to reject the job invitation?
          <div className="flex gap-x-[16px] justify-end">
            <CustomButton
              customClasses={clsx([classes.yesOrNoButton, classes.noButton])}
              onClick={closeDialog}
            >
              No
            </CustomButton>

            <CustomButton
              type="button1"
              customClasses={classes.yesOrNoButton}
              onClick={() => {
                onRejectJob();
              }}
            >
              Yes
            </CustomButton>
          </div>
        </>
      );
  };

  return (
    <div className="p-[36px] flex flex-col gap-y-[24px] max-w-[739px] m-auto">
      <Content />
    </div>
  );
};

const JobActionDialogContent = ({
  actionType,
  jobData,
  closeDialog,
  setJobInvitationStatus,
  setJobStage,
}) => {
  switch (actionType) {
    case "View Application":
      return (
        <ProposalOverview
          jobData={jobData}
          closeDialog={closeDialog}
          onAccept={() => {
            setJobStage("accepted");
          }}
          onWithdrawOrDecline={() => {
            setJobStage("withdrew");
          }}
        />
      );

    // case "Accept Job":
    //   return (
    //     <AcceptJobDialogContent
    //       jobID={jobData?._id}
    //       closeDialog={closeDialog}
    //       setJobInvitationStatus={setJobInvitationStatus}
    //     />
    //   );
    case "Reject Job":
      return (
        <RejectJobDialogContent
          jobID={jobData?._id}
          closeDialog={closeDialog}
          setJobInvitationStatus={setJobInvitationStatus}
        />
      );
    case "Accept and Apply Job":
      return (
        <SubmitProposal
          jobData={jobData}
          onSuccess={() => {
            setJobInvitationStatus("accepted");
            setJobStage("applied");
          }}
          closeParentDialog={closeDialog}
          customSuccessMessage="You have successfully accepted and applied for the job."
        />
      );

    default:
      return <></>;
  }
};

const List = ({
  data,
  setUpdateSaveJobData,
  removeJob, // Used in MyJobs view.
  isDashboardView = false,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //   const data={
  //     "_id": "64d33619c8124c7a8acbedc9",
  //     "emp_id": "644eee1d7812dcc3ce46f743",
  //     "jobTitle": "React Native Developer",
  //     "jobNo": "123",
  //     "openPositions": 2,
  //     "experienceLevelId": 1,
  //     "contractType": 0,
  //     "workType": "Part Time",
  //     "timezone": "string",
  //     "country": "India",
  //     "state": "Mumbai",
  //     "salary": {
  //         "range": {
  //             "min": 23,
  //             "max": 28
  //         },
  //         "type": "hourly",
  //         "_id": "651f8d3b51e297dd5987b7d3"
  //     },
  //     "published": "2022-12-11T05:00:00.000Z",
  //     status: "applied", // this value is specific to applied/invited jobs only. Possible values:applied/viewed/testSent/notSelected/invited/invitationAccepted. First 4 are for applied case,last two for invited case.
  //     savedStatus : true
  // }

  const { experiences, contractTypes } = useSelector((state) => state.misc);

  const [listCardData, setListCardData] = useState({});
  const [clientData, setClientData] = useState({});
  const [salaryType, setSalaryType] = useState();
  const [salaryValue, setSalaryValue] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [jobStage, setJobStage] = useState();
  const [isAppliedJob, setIsAppliedJob] = useState(); // A job can be either applied for by the consultant or invited to by the client.
  const [isSavedJob, setIsSavedJob] = useState();
  const [invitationStatus, setInvitationStatus] = useState(); // pending/accepted/rejected.
  const [isHovered, setIsHovered] = useState(false);
  const [jobActionType, setJobActionType] = useState(""); // Accept Job,Reject Job,Apply Job,View Application
  const [jobActionDialogOpen, setJobActionDialogOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleBookMarkClick = () => {
    const actionName = isSavedJob ? "unsave" : "save";
    // enqueueSnackbar(
    //   `Your action to ${actionName} the job is in progress.Please wait.  `,
    //   { variant: "info" }
    // );

    const key = enqueueSnackbar(
      `Your action to ${actionName} the job is in progress.Please wait.  `,
      {
        variant: "info",
        autoHideDuration: 3000,
        action: (key) => (
          <CloseIcon
            onClick={() => closeSnackbar(key)}
            style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
          />
        ),
      }
    );
    saveOrunsaveJob({ jobId: listCardData?._id })
      .then((response) => {
        if (isSavedJob && isFunction(removeJob)) {
          // We are unsaving a job(since its current state is saved and when the bookmark is clicked it needs to get unsaved).
          // The removeJob function removes the unsaved job card at the parent level(Use case is MyJobs view).
          removeJob(listCardData?._id);
        }

        setIsSavedJob((prevState) => !prevState);

        isFunction(setUpdateSaveJobData) &&
          setUpdateSaveJobData((prevState) => !prevState);
        //   enqueueSnackbar(`Your job has been ${actionName}d successfully.`, {
        //     variant: "success",
        //   });

        const key = enqueueSnackbar(
          `Your job has been ${actionName}d successfully.`,
          {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
      })

      .catch((err) => {
        // enqueueSnackbar(
        //   `Your job could not be ${actionName}d.Please try again later.`,
        //   { variant: "error" }
        // );

        const key = enqueueSnackbar(
          `Your job could not be ${actionName}d.Please try again later.`,
          {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
      });
  };

  const setClientInfoOfJob = (receivedClientData) => {
    if (isValidObject(receivedClientData)) {
      let reqClientData = {}; // required client data
      reqClientData.companyName = receivedClientData?.company ?? "";
      reqClientData.profilePhoto = receivedClientData?.profilePhoto ?? "";
      reqClientData.website = receivedClientData?.socialLinks?.website ?? "";
      reqClientData.profileDesc =
        receivedClientData.profileDesc?.htmlString ??
        receivedClientData.profileDesc ??
        "";
      reqClientData.culture =
        receivedClientData.profileDesc?.htmlString ??
        receivedClientData.profileDesc ??
        "";
      setClientData(reqClientData);
    }
  };

  useEffect(() => {
    if (data) {
      let createdAtReceived = data?.createdAt
        ? moment(data?.createdAt).fromNow()
        : "-";
      createdAtReceived =
        createdAtReceived === "Invalid date" ? "-" : createdAtReceived;
      const salaryDetails = data?.salary;
      const salaryTypeReceived = salaryDetails?.type;
      const salaryRangeReceived = salaryDetails?.range;
      const salaryValueReceived = salaryRangeReceived
        ? `Min- $ ${salaryRangeReceived?.min ?? "-"}, Max - $ ${
            salaryRangeReceived?.max ?? "-"
          }`
        : `$ ${salaryDetails?.fixedRate ?? "-"}`;

      // data.stage = "screening";
      setClientInfoOfJob(data?.companyDetail);
      setListCardData(data);
      setCreatedAt(createdAtReceived);
      setSalaryType(salaryTypeReceived);
      setSalaryValue(salaryValueReceived);
      setJobStage(data?.stage);
      setIsSavedJob(data?.isSaved);
      setInvitationStatus(data?.status); // pending/accepted/rejected
      setIsAppliedJob(data?.appliedOrInvited === "applied");
    }
  }, [data]);

  useEffect(() => {
    if (jobActionType) {
      setJobActionDialogOpen(true);
    }
  }, [jobActionType]);

  useEffect(() => {
    if (!jobActionDialogOpen) {
      // Setting necessary values to its default state when the job action dialog is closed.
      setJobActionType("");
    }
  }, [jobActionDialogOpen]);

  useEffect(() => {
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
  }, [dispatch]);

  return (
    <>
      <div
        className={classes.listCardContainer}
        onMouseLeave={handleMouseLeave}
        onMouseOver={handleMouseEnter}
      >
        <div className="flex gap-x-6">
          <img
            className={classes.orgImage}
            alt={`Company `}
            src={
              clientData?.profilePhoto
                ? clientData.profilePhoto
                : "/org_placeholder.svg"
            }
          />
          <div className=" w-full flex justify-between items-start gap-x-[16px]">
            <div>
              <Typography className={classes.jobTitle}>
                {listCardData?.jobTitle ?? "-"}
              </Typography>
              <div className="flex gap-x-3">
                {clientData?.companyName ? (
                  <span className={classes.companyName}>
                    {clientData?.companyName}
                  </span>
                ) : (
                  <></>
                )}
                <div className="text-[14px] text-[#717171] font-semibold">
                  JOB NO {listCardData?.jobNo ? listCardData?.jobNo : "-"}
                </div>
              </div>
              <span className={classes.jobPostedDate}>
                Posted {createdAt ?? "-"}
              </span>
            </div>
            {!isDashboardView && jobStage ? (
              <JobStage type={jobStage} />
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className={classes.jobDetailsSection}>
          <div className={classes.jobDetailsSectionSegment}>
            <Typography className={classes.jdSegmentTitle}>
              OPEN POSITIONS
            </Typography>
            <Typography className={classes.jdSegmentValue}>
              {listCardData?.openPositions}
            </Typography>
          </div>
          <div className={classes.jobDetailsSectionSegment}>
            <Typography className={classes.jdSegmentTitle}>
              EXPERIENCE LEVEL
            </Typography>
            <Typography className={classes.jdSegmentValue}>
              {getExperience(listCardData?.experienceLevelId, experiences)}
            </Typography>
          </div>
          <div className={classes.jobDetailsSectionSegment}>
            <Typography className={classes.jdSegmentTitle}>
              CONTRACT TYPE
            </Typography>
            <Typography className={classes.jdSegmentValue}>
              {getContractTypeLevel(listCardData?.contractType, contractTypes)}
            </Typography>
          </div>
          <div className={classes.jobDetailsSectionSegment}>
            <Typography className={classes.jdSegmentTitle}>
              WORK TYPE
            </Typography>
            <Typography className={classes.jdSegmentValue}>
              {listCardData?.workType}
            </Typography>
          </div>
          <div className={classes.jobDetailsSectionSegment}>
            <Typography className={classes.jdSegmentTitle}>
              {salaryType ? salaryType?.toUpperCase() : ""} RATE
            </Typography>
            <Typography className={classes.jdSegmentValue}>
              {salaryValue}
            </Typography>
          </div>
        </div>
        <div className={classes.jobDetailsSection}>
          <div className={classes.jobDetailsSectionSegment}>
            <Typography className={classes.jdSegmentTitle}>LOCATION</Typography>
            <Typography className={classes.jdSegmentValue}>
              {`${listCardData?.country ?? ""},${listCardData?.state ?? ""},${
                listCardData?.city ?? ""
              }`}
            </Typography>
          </div>
          <div className={classes.jobDetailsSectionSegment}>
            <Typography className={classes.jdSegmentTitle}>TIMEZONE</Typography>
            <Typography className={classes.jdSegmentValue}>
              {listCardData?.timezone}
            </Typography>
          </div>
        </div>
        {listCardData?.companyDetail && (
          <Typography className={classes.jobDescription}>
            {listCardData?.companyDetail?.companyDesc?.text}
          </Typography>
        )}

        {isHovered && (
          <div className={classes.actionsDiv}>
            <Button
              className={classes.viewJobButton}
              onClick={() => {
                history.push(`/consultant/job-detail/${listCardData?._id}`);
              }}
            >
              View Job Details
              <img src="/iconlightup-right.svg" />
            </Button>
            {!isDashboardView && (
              <>
                {/* If not an applied job it will be an invited job*/}
                {isAppliedJob ? (
                  <Button
                    className={classes.applyOrAcceptOrRejectButton}
                    onClick={() => {
                      setJobActionType("View Application");
                    }}
                  >
                    View Application
                  </Button>
                ) : (
                  <>
                    {invitationStatus === "pending" && (
                      <>
                        <Button
                          className={classes.applyOrAcceptOrRejectButton}
                          onClick={() => {
                            setJobActionType("Accept and Apply Job");
                          }}
                        >
                          Accept and apply
                        </Button>
                        <Button
                          className={classes.applyOrAcceptOrRejectButton}
                          onClick={() => {
                            setJobActionType("Reject Job");
                          }}
                        >
                          Reject
                        </Button>
                      </>
                    )}

                    {invitationStatus === "accepted" && (
                      <Button
                        className={classes.applyOrAcceptOrRejectButton}
                        onClick={() => {
                          setJobActionType("View Application");
                        }}
                      >
                        View Application
                      </Button>
                    )}

                    {invitationStatus === "rejected" && (
                      <Button
                        className={classes.applyOrAcceptOrRejectButton}
                        disabled={true}
                      >
                        Invitation Rejected
                      </Button>
                    )}
                  </>
                )}
              </>
            )}

            <button
              className={`cursor-pointer border-[1px] border-solid ${
                isSavedJob ? "bg-neutral-900" : "border-neutral-50"
              } p-2.5 flex flex-row box-border items-center justify-center`}
              onClick={handleBookMarkClick}
            >
              <img
                className="relative w-5 h-5 overflow-hidden shrink-0"
                alt="bookmark-icon"
                src={
                  isSavedJob
                    ? "/iconlightbookmark.svg"
                    : "/icondarkbookmark.svg"
                }
              />
            </button>
          </div>
        )}
      </div>
      <CustomDialog
        open={jobActionDialogOpen}
        setOpen={setJobActionDialogOpen}
        title={
          <div className="text-[16px] font-semibold text-center">
            {jobActionType}
          </div>
        }
        content={
          <JobActionDialogContent
            actionType={jobActionType}
            jobData={listCardData}
            closeDialog={() => setJobActionDialogOpen(false)}
            setJobInvitationStatus={setInvitationStatus}
            setJobStage={setJobStage}
          />
        }
      />
    </>
  );
};

List.propTypes = {};

export default List;
