const prodOrigins = ["https://findpro.ai", "https://www.findpro.ai"];

export const prodConfigs = {
  /** This is production */
  APIUrl: "/api",
  homeUrl: "https://findpro.ai/",
  linkedinAuthHome: prodOrigins.includes(window.location.origin) ?  `https://app.findpro.ai/api` : `${window.location.origin}/api`,// for ex: https://dev.findpro.ai/api"
  paymentConfig: {
    clientKey: "AYLLD26Pw4FRzuHY4_9fm4nHXplGnHSKUSizF1AEY09NoHLVLdZ3UgAFoUrk0cNjuVmRIqxvZAFyx0zz"
  },
};
